import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import {useHistory, useLocation} from "react-router-dom";
import APIHelper from "../../helpers/APIHelper";
import CommonLib from "../../libraries/CommonLib";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import $ from "jquery";

const ManufactureDetail = (props) => {
  const {t} = useTranslation();
  const result = (props?.location?.state?.result || props?.match?.params?.result) || [];
  let resultIdx = (props?.location?.state?.idx || props?.match?.params?.idx) || 0;
  // console.log(result[resultIdx]);

  const history = useHistory()

  const callback = (result) => {
    localStorage.setItem("manufacture.result", JSON.stringify(result));
    // window.location.replace('/manufacture');
    history.replace('/manufacture');
    // history.push({
    //   pathname: "/manufacture",
    //   state: {result},
    // });
  };

  const [isAllChecked, setIsAllChecked] = useState(false);
  const allAgreeHandler = (checked) => {
    setIsAllChecked(!isAllChecked);
  }

  const item = result[resultIdx];

  const [selectIdx, setSelectIdx] = useState(resultIdx);

  const [certs, setCerts] = useState([]);
  const [cds, setCds] = useState(item?.data?.cds || []);
  const [countries, setCountries] = useState([]);
  const [functionals, setFunctionals] = useState([]);
  const [targets, setTargets] = useState([]);
  const [selectedCert, setSelectedCert] = useState(
    item?.data?.selectedCert || {}
  );
  const [selectedCountry, setSelectedCountry] = useState(item?.data?.selectedCountry || {});
  const [selectedFunctional, setSelectedFunctional] = useState(item?.data?.cds || []);
  const [selectedTarget, setSelectedTarget] = useState(item?.data?.selectedTarget || {});
  const [etc, setEtc] = useState(item?.data?.etc || "");
  const [volume, setVolume] = useState(item?.data?.volume || "");
  const [volumeUnit, setVolumeUnit] = useState(item?.data?.volumeUnit || "ml");
  const [quantity, setQuantity] = useState(item?.data?.quantity || (result[resultIdx]?.selectProduct?.cd === "110400" ? "10000" : "3000"));
  const [unitPrice, setUnitPrice] = useState(item?.data?.unitPrice || "");
  const [unit, setUnit] = useState(item?.data?.unit || "USD");
  const [refUrl1, setRefUrl1] = useState(item?.data?.refUrl1 || "");
  const [refUrl2, setRefUrl2] = useState(item?.data?.refUrl2 || "");
  const [refUrl3, setRefUrl3] = useState(item?.data?.refUrl3 || "");
  const fileRef1 = useRef();
  const fileRef2 = useRef();
  const fileRef3 = useRef();
  const fileRef4 = useRef();
  const fileRef5 = useRef();
  const [file1, setFile1] = useState(item?.data?.file1 || {});
  const [file2, setFile2] = useState(item?.data?.file2 || {});
  const [file3, setFile3] = useState(item?.data?.file3 || {});
  const [file4, setFile4] = useState(item?.data?.file4 || {});
  const [file5, setFile5] = useState(item?.data?.file5 || {});

  useEffect(() => {
    const fetch = async () => {
      let certs, countries, functionals, targets;
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets/visible", {parentCd: "103000"});
        if (code === 200) setCerts(result);
        certs = _.clone(result);
      }
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets/visible", {parentCd: "106000"});
        if (code === 200) setCountries(result);
        countries = _.clone(result);
      }
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets/visible", {parentCd: "107000"});
        if (code === 200) setFunctionals(result);
        functionals = _.clone(result);
      }
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets/visible", {parentCd: "108000"});
        if (code === 200) setTargets(result);
        targets = _.clone(result);
      }
      if (item && item?.data?.selectedCountry?.length > 0) {
        setSelectedCountry(item?.data?.selectedCountry);

        setSelectedFunctional(item?.data?.cds);
        setCds(item?.data?.cds || []);

        setSelectedTarget(item?.data?.selectedTarget);
      }
      setSelectedCert(item?.data?.selectedCert || {});
      setSelectedCountry(item?.data?.selectedCountry || countries[0]?.cd);

      setSelectedFunctional(item?.data?.cds || {});
      setCds(item?.data?.cds || []);

      setSelectedTarget(item?.data?.selectedTarget || {});
      setEtc(item?.data?.etc || "");
      setVolume(item?.data?.volume || "");
      setVolumeUnit(item?.data?.volumeUnit || "");
      setQuantity(item?.data?.quantity || (result[resultIdx]?.selectProduct?.cd === "110400" ? "10000" : "3000"));
      setUnitPrice(item?.data?.unitPrice || "");
      setUnit(item?.data?.unit || "USD");
      setRefUrl1(item?.data?.refUrl1 || "");
      setRefUrl2(item?.data?.refUrl2 || "");
      setRefUrl3(item?.data?.refUrl3 || "");
      setFile1(item?.data?.file1 || {});
      setFile2(item?.data?.file2 || {});
      setFile3(item?.data?.file3 || {});
      setFile4(item?.data?.file4 || {});
      setFile5(item?.data?.file5 || {});

      window.scrollTo(0, 0);
    };
    fetch();
  }, [result, resultIdx]);

  const getCode = (cd) => {
    return new Promise(async (resolve, reject) => {
      const {
        data: {code, result, message},
      } = await APIHelper.getForm("code/get", {cd});
      return resolve(result);
    });
  };

  const changeItem = async (idx) => {
    if (parseInt(idx.toString(), 10) === -99) return;

    await _submit((result) => {
      localStorage.setItem("manufacture.result", JSON.stringify(result));
      console.log(idx);
      history.push({
        pathname: "/manufacture/detail",
        state: {result, idx},
      });
    });
  };

  const submit = async () => {
    if (unitPrice === "") {
      await CommonLib.jalert("예상단가를 적어주세요.");
      return;
    }
    if (volume === "") {
      await CommonLib.jalert("용량을 적어주세요.");
      return;
    }
    if (quantity === "") {
      await CommonLib.jalert("갯수를 적어주세요.1" + quantity);
      return;
    }
    if (!isAllChecked) {
      await CommonLib.jalert("Please check the box if you want to proceed.");
      return;
    }

    await _submit(callback);
  };

  const _submit = async (callback) => {
    const data = {
      selectedCert,
      selectedCertObj: await getCode(selectedCert),
      selectedCountry,
      selectedCountryObj: await getCode(selectedCountry),
      cds,
      selectedTarget,
      selectedTargetObj: await getCode(selectedTarget),
      etc,
      volume,
      volumeUnit,
      quantity,
      unitPrice,
      unit,
      refUrl1,
      refUrl2,
      refUrl3,
      file1,
      file2,
      file3,
      file4,
      file5,
    };

    if (CommonLib.parseInt(selectIdx) > -99) {
      resultIdx = CommonLib.parseInt(selectIdx);

      const _result = [...result];
      _result[resultIdx].data = data;
      _result[resultIdx].done = true;
      callback(_result);
    } else {
      const _result = [...result];
      for (const [key, item] of Object.entries(_result)) {
        item.data = _.clone(data);
        item.done = true;
      }
      callback(_result);
    }
  }

  const toggleFuntionalCd = (cd) => {
    if (cds.includes(cd)) {
      setCds(cds.filter((item) => item !== cd));
    } else {
      if (cds?.length >= 3) return;
      const _cds = _.clone(cds);
      _cds.push(cd);
      setCds(_cds);
    }
  }

  return (
    <div className="BODY MAX my-4">
      <div
        className="mt-3 d-flex justify-content-between w-100"
        style={{height: "100%"}}
      >
        <div className="box_round pb-5">
          <div className="mx-5 my-4 pb-5">
            <div className=" border-bottom py-4">
              <p className="h4 bold txt-point">상세정보 등록 및 수정</p>
              <p className="h6">
                세부정보는 선택한 제품별로 입력하거나, 한꺼번에 입력하실 수
                있습니다.
              </p>
            </div>

            <div className="my-3">
              <select className="form-select w-50"
                      onChange={async (e) => {
                        await setSelectIdx(e.target.value);
                        await changeItem(e.target.value);
                      }}
              >
                <option selected={parseInt(selectIdx.toString(), 10) === -99} value="-99">
                  선택한 상품 전체
                </option>
                {result.map((data, k) => (
                  <option value={k} selected={parseInt(selectIdx.toString(), 10) === parseInt(k.toString(), 10)}>
                    {data.selectProduct.name_en} | {data.selectType.name_en}
                  </option>
                ))}
              </select>
            </div>

            <table className="table basic w-100">
              <colgroup>
                <col width="22%"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th className="center notranslate no-transform">Certificate</th>
                <td>
                  <select
                    className="form-select form-select-sm w-50"
                    onChange={async (e) =>
                      await setSelectedCert(e.target.value)
                    }
                    value={selectedCert}
                  >
                    <option selected value="0">
                      인증서기준 없음
                    </option>
                    {certs.map((data, k) => (
                      <option
                        value={data.cd}
                        selected={data.cd === selectedCert.cd ? true : false}
                      >
                        {data.name_kr}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <th className="center notranslate no-transform">Target customer</th>
                <td>
                  <div className="d-flex flex-wrap">
                    <a
                      onClick={() => setSelectedTarget(null)}
                      className={`${
                        !selectedTarget?.cd ? "bg-purple" : "border"
                      } box_round_4_paddingNo py-1 px-3 m-1 txt-sm`}
                    >
                      상관없음
                    </a>
                    {targets.map((data, k) => (
                      <a
                        onClick={() => setSelectedTarget(data)}
                        className={`${
                          selectedTarget?.cd === data?.cd
                            ? "bg-purple"
                            : "border"
                        } box_round_4_paddingNo py-1 px-3 m-1 txt-sm`}
                      >
                        {data.name_kr}
                      </a>
                    ))}
                  </div>
                </td>
              </tr>
              <tr>
                <th className="center notranslate no-transform">Target country</th>
                <td>
                  <select
                    className="form-select form-select-sm w-50"
                    onChange={async (e) =>
                      await setSelectedCountry(e.target.value)
                    }
                    value={selectedCountry}
                  >
                    {countries.map((data, k) => (
                      <option
                        value={data.cd}
                        selected={data.cd === selectedCountry.cd ? true : false}
                      >
                        {data.name_en}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <th className="center notranslate no-transform">Target price</th>
                <td>
                  <div className="input-group w-65">
                    <input
                      type="text"
                      className="form-control form-control-sm w-50"
                      onChange={async (e) => await setUnitPrice(e.target.value)}
                      value={unitPrice}
                    />
                    <select
                      className="form-select form-select-sm"
                      style={{width: "70px"}}
                      onChange={async (e) => await setUnit(e.target.value)}
                      value={unit}
                    >
                      {/*<option value="원">원</option>*/}
                      <option value="USD">USD</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="center notranslate no-transform">Volume</th>
                <td>
                  <div className="input-group w-65">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder=""
                      onChange={async (e) => await setVolume(e.target.value)}
                      value={volume}
                      style={{maxWidth: "150px"}}
                    />
                    <select
                      className="form-select form-select-sm no-transform"
                      style={{maxWidth: "70px"}}
                      onChange={async (e) => await setVolumeUnit(e.target.value)}
                      value={volumeUnit}
                    >
                      <option value="ml">ml</option>
                      <option value="g">g</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="center notranslate no-transform">MOQ</th>
                <td>
                  <div className="input-group w-65">
                    {result[resultIdx]?.selectProduct?.cd === "110400" ?
                      <select className="form-select w-50"
                              onChange={async (e) => {
                                await setQuantity(e.target.value);
                              }}
                              style={{maxWidth: "175px"}}
                      >
                        <option selected={quantity === "10000"} value="10000">10000 - 20000</option>
                        <option selected={quantity === "20000"} value="20000">20001 - 30000</option>
                        <option selected={quantity === "30000"} value="30000">30001 - 40000</option>
                      </select>
                      :
                      <select className="form-select w-50"
                              onChange={async (e) => {
                                await setQuantity(e.target.value);
                              }}
                              style={{maxWidth: "175px"}}
                      >
                        <option selected={quantity === "3000"} value="3000">3000 - 5000</option>
                        <option selected={quantity === "5000"} value="5000">5001 - 10000</option>
                        <option selected={quantity === "10000"} value="10000">10001 - 20000</option>
                        <option selected={quantity === "20000"} value="20000">20001 - 30000</option>
                        <option selected={quantity === "30000"} value="30000">30001 - 40000</option>
                      </select>
                    }
                    {/*<input*/}
                    {/*  type="text"*/}
                    {/*  className="form-control form-control-sm w-35"*/}
                    {/*  placeholder=""*/}
                    {/*  onChange={async (e) => await setQuantity(e.target.value)}*/}
                    {/*  value={quantity}*/}
                    {/*  style={{maxWidth: "150px"}}*/}
                    {/*/>*/}
                    <span style={{marginTop: "5px", marginLeft: "5px"}} className="no-transform">
                      {t('manufacture.detail.pieces')}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="center notranslate no-transform">Concern</th>
                <td>
                  <div className="d-flex flex-wrap">
                    <a
                      // onClick={() => setSelectedFunctional(null)}
                      onClick={() => toggleFuntionalCd('')}
                      className={`${
                        cds.includes('') ? "bg-purple" : "border"
                      } box_round_4_paddingNo py-1 px-3 m-1 txt-sm`}
                    >
                      상관없음
                    </a>
                    {functionals.map((data, k) => (
                      <a
                        // onClick={() => setSelectedFunctional(data)}
                        onClick={() => toggleFuntionalCd(data?.cd)}
                        className={`${
                          cds.includes(data?.cd)
                            ? "bg-purple"
                            : "border"
                        } box_round_4_paddingNo py-1 px-3 m-1 txt-sm`}
                      >
                        {data.name_kr}
                      </a>
                    ))}
                  </div>
                </td>
              </tr>
              <tr style={{display: "none"}}>
                <th className="center notranslate no-transform">주요 성분</th>
                <td>
                  <select className="form-select form-select-sm">
                    <option value="0" selected>
                      알로에베라
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <th className="center notranslate no-transform">
                  Comments
                </th>
                <td>
                  <textarea
                    className="form-control mt-2"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    onChange={async (e) => await setEtc(e.target.value)}
                    value={etc}
                  ></textarea>
                </td>
              </tr>
              <tr>
                <th className="center notranslate no-transform">
                  Target product<br/>
                  <font style={{fontSize: "0.8rem"}}>(Please attach the image of the target product similar to the product you want)</font>
                </th>
                <td>
                  {file1 && file1?.fid?.length > 0 &&
                    <div
                      style={{background: "#f9edf8", color: "#ae6faf"}}
                      className="box_round_4_paddingNo px-2 p-1 m-2"
                    >
                      <span style={{paddingLeft: "0.5rem"}}>{file1?.filename}</span>{" "}
                      <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => setFile1(null)}>삭제</button>
                    </div>
                  }
                  <div className="input-group input-group-sm">
                    <input
                      type="file"
                      className="form-control"
                      aria-describedby="inputGroupFileAddon01"
                      aria-label="Upload"
                      id="file1"
                      ref={fileRef1}
                      onChange={async () => {
                        if (fileRef1?.current?.files?.length > 0) {
                          const {data: {code, result, message}} = await APIHelper.postForm(`project/file/upload`, {uploadFile: fileRef1?.current?.files[0]});
                          if (code === 200) setFile1({filename: fileRef1?.current?.files[0].name, fid: result});
                        }
                      }}
                    />
                  </div>
                  {file2 && file2?.fid?.length > 0 &&
                    <div
                      style={{background: "#f9edf8", color: "#ae6faf"}}
                      className="box_round_4_paddingNo px-2 p-1 m-2"
                    >
                      <span style={{paddingLeft: "0.5rem"}}>{file2?.filename}</span>{" "}
                      <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => setFile2(null)}>삭제</button>
                    </div>
                  }
                  <div className="input-group input-group-sm">
                    <input
                      type="file"
                      className="form-control"
                      aria-describedby="inputGroupFileAddon02"
                      aria-label="Upload"
                      id="file2"
                      ref={fileRef2}
                      onChange={async () => {
                        if (fileRef2?.current?.files?.length > 0) {
                          const {data: {code, result, message}} = await APIHelper.postForm(`project/file/upload`, {uploadFile: fileRef2?.current?.files[0]});
                          if (code === 200) setFile2({filename: fileRef2?.current?.files[0].name, fid: result});
                        }
                      }}
                    />
                  </div>
                  {file3 && file3?.fid?.length > 0 &&
                    <div
                      style={{background: "#f9edf8", color: "#ae6faf"}}
                      className="box_round_4_paddingNo px-2 p-1 m-2"
                    >
                      <span style={{paddingLeft: "0.5rem"}}>{file3?.filename}</span>{" "}
                      <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => setFile3(null)}>삭제</button>
                    </div>
                  }
                  <div className="input-group input-group-sm">
                    <input
                      type="file"
                      className="form-control"
                      aria-describedby="inputGroupFileAddon03"
                      aria-label="Upload"
                      id="file3"
                      ref={fileRef3}
                      onChange={async () => {
                        if (fileRef3?.current?.files?.length > 0) {
                          const {data: {code, result, message}} = await APIHelper.postForm(`project/file/upload`, {uploadFile: fileRef3?.current?.files[0]});
                          if (code === 200) setFile3({filename: fileRef3?.current?.files[0].name, fid: result});
                        }
                      }}
                    />
                  </div>
                  {file4 && file4?.fid?.length > 0 &&
                    <div
                      style={{background: "#f9edf8", color: "#ae6faf"}}
                      className="box_round_4_paddingNo px-2 p-1 m-2"
                    >
                      <span style={{paddingLeft: "0.5rem"}}>{file4?.filename}</span>{" "}
                      <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => setFile4(null)}>삭제</button>
                    </div>
                  }
                  <div className="input-group input-group-sm">
                    <input
                      type="file"
                      className="form-control"
                      aria-describedby="inputGroupFileAddon04"
                      aria-label="Upload"
                      id="file4"
                      ref={fileRef4}
                      onChange={async () => {
                        if (fileRef4?.current?.files?.length > 0) {
                          const {data: {code, result, message}} = await APIHelper.postForm(`project/file/upload`, {uploadFile: fileRef4?.current?.files[0]});
                          if (code === 200) setFile4({filename: fileRef4?.current?.files[0].name, fid: result});
                        }
                      }}
                    />
                  </div>
                  {file5 && file5?.fid?.length > 0 &&
                    <div
                      style={{background: "#f9edf8", color: "#ae6faf"}}
                      className="box_round_4_paddingNo px-2 p-1 m-2"
                    >
                      <span style={{paddingLeft: "0.5rem"}}>{file5?.filename}</span>{" "}
                      <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => setFile5(null)}>삭제</button>
                    </div>
                  }
                  <div className="input-group input-group-sm">
                    <input
                      type="file"
                      className="form-control"
                      aria-describedby="inputGroupFileAddon05"
                      aria-label="Upload"
                      id="file5"
                      ref={fileRef5}
                      onChange={async () => {
                        if (fileRef5?.current?.files?.length > 0) {
                          const {data: {code, result, message}} = await APIHelper.postForm(`project/file/upload`, {uploadFile: fileRef5?.current?.files[0]});
                          if (code === 200) setFile5({filename: fileRef5?.current?.files[0].name, fid: result});
                        }
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th className="center notranslate no-transform">
                  Target reference<br/>
                  <font style={{fontSize: "0.8rem"}}>(Please attach URLs of the target product similar to the product you want)</font>
                </th>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="http://"
                    onChange={async (e) => await setRefUrl1(e.target.value)}
                    value={refUrl1}
                  />
                  <input
                    type="text"
                    className="form-control form-control-sm my-1"
                    placeholder="http://"
                    onChange={async (e) => await setRefUrl2(e.target.value)}
                    value={refUrl2}
                  />
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="http://"
                    onChange={async (e) => await setRefUrl3(e.target.value)}
                    value={refUrl3}
                  />
                </td>
              </tr>
            </table>
            <div className="d-flex justify-content-between notranslate no-transform" style={{marginTop: "10px"}}>
              Our process is like:<br/>
              <br/>
              1) Sample production<br/>
              - make your sample including package<br/>
              - sample cost is $300 (This is deposit, so when you produce a serial production, this cost will be deducted from the serial production cost. If you stop att the sample procecss, this cost will be not refunded)<br/>
              - design cost is $300 (This is for package design fee, not refunded)<br/>
              <br/>
              2) Once sample is done, I will send the sample(one piece) to you, and then you can check the quality and test it.<br/>
              (If you want to change something, you can change once only)<br/>
              <br/>
              3) Pay 50% of serial production cost and the start the serial production<br/>
              <br/>
              4) Once all the production is finished, I will send them to shipping company.<br/>
              <br/>
              5) When the products are on shipping company, you need to pay 50%(the rest), and then once payment confirmation, they will be sent you directly<br/>
            </div>
            <div style={{marginTop: "20px", marginBottom: "30px"}}>
              <input
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox1"
                value="allcheck"
                onChange={(e) => allAgreeHandler(e.currentTarget.checked)}
                checked={isAllChecked}
              />
              <label className="form-check-label" htmlFor="inlineCheckbox1" style={{marginLeft: "8px"}}>
                I understand and agree with all of the above.
              </label>
            </div>


            <div className="d-flex justify-content-between">
              <p></p>
              <p>
                <button
                  className="btn btn_puple"
                  type="button"
                  style={{marginRight: "5px"}}
                  onClick={() => submit()}
                >
                  상세정보 등록 완료
                </button>
                <button
                  className="btn btn-outline-light"
                  type="button"
                  onClick={() => callback(result)}
                >
                  취소
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManufactureDetail;
