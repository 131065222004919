import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import './lang/i18n'

import "./index.scss";
import App from "./App";
import ReactGA from "react-ga";

import {Provider} from "react-redux";
import {createStore} from "redux";
import rootreducer from "./reducers/rootReducer";
import FirebaseMessaging from "./service/FirebaseMessaging";
import Constants from "./constants/constants";

const store = createStore(rootreducer);

ReactGA.initialize(Constants.GOOGLE_ANALYTICS_TRACKING_ID);

ReactDOM.render(
  <Provider store={store}>
    <FirebaseMessaging/>
    <App/>
  </Provider>,
  document.getElementById("root")
);
