import React, {useEffect} from "react";
import {Link, useHistory} from "react-router-dom";

import MypageCard from "./MypageCard";

import "../../sass/main.scss";
import close_w from "../../image/close_w.svg";
import Constants from "../../constants/constants";
import CommonLib from "../../libraries/CommonLib";
import APIHelper from "../../helpers/APIHelper";

const MypageOemDetail = (props) => {
  const history = useHistory();

  const item = props?.location?.state?.item || props?.match?.params?.item;
  const mode = props?.location?.state?.mode || props?.match?.params?.mode;

  if (item?.maker) {
    item.maker.area = '';
    if (item.maker.company_addr.length > 0)
      item.maker.area = item.maker.company_addr.split(' ')[0];
  }

  const remove = async () => {
    if (!await CommonLib.jconfirm('삭제하시겠습니까?')) return;
    const {data: {code, result, message}} = await APIHelper.deleteForm(`data/oem/proposal/delete?id=${item.id}&mode=${mode}`);
    history.push('/mypage/oem');
  }

  return (
    <MypageCard>
      <div className="box_round min_h cnt_area pb-5">
        <div className="mx-5 my-4">
          <p className="h3 bold border-bottom py-3">OEM 제작 제안</p>
          <br/>

          <table className="table basic my-3">
            <colgroup>
              <col width="20%"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th className="border-right">제안할 상품</th>
              <td>{item.product}</td>
            </tr>
            <tr>
              <th className="border-right">MOQ</th>
              <td>{item.moq}</td>
            </tr>

            <tr>
              <th className="border-right">Unit Price</th>
              <td>{mode === 'BUYER' ? item.darlibaba_unit_price : item.unit_price}</td>
            </tr>
            <tr>
              <th className="border-right">제작 기간</th>
              <td>{item.period}</td>
            </tr>
            <tr>
              <th className="border-right">유사 제작 사례</th>
              <td>
                <div className="d-flex">
                  {item.photos && item.photos.map((image, index) => (
                    <p key={index} className="thum_add p-0">
                      <img
                        src={
                          image?.photo_fid.length > 0
                            ? `${Constants.API_HOST}/common/download?fid=${image.photo_fid}&cd=inline&thumb=400`
                            : ""
                        }
                        style={{
                          borderWidth: 1,
                          borderStyle: "solid",
                          borderColor: "#cfcfcf",
                        }}
                      />
                    </p>
                  ))}
                </div>
              </td>
            </tr>

            <tr>
              <th className="border-right">기타</th>
              <td>{mode === 'BUYER' ? CommonLib.escapedNewLineToLineBreakTag(item.manager_etc) : CommonLib.escapedNewLineToLineBreakTag(item.etc)}</td>
            </tr>
          </table>

          {mode === 'BUYER' &&
            <table className="table basic my-3">
              <colgroup>
                <col width="20%"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th className="border-right">제작 회사 정보</th>
                <td>
                  <div className="d-flex">
                    <p>
                      {item.maker.rep_image_fid && item.maker.rep_image_fid.length > 0 ?
                        <img
                          src={`${Constants.API_HOST}/common/download?fid=${item.maker.rep_image_fid}&cd=inline&thumb=400`}
                          style={{width: "260px", height: "175px"}}
                        />
                        :
                        <div style={{width: "260px", height: "175px", backgroundColor: "#efefef"}}></div>
                      }
                    </p>
                    <div className="px-4">
                      <h5 className="txt-point">
                        <strong>{item.maker?.company_name}</strong>
                      </h5>

                      <ul className="txt-gray p-0 pt-2">
                        <li className="txt-sm">MOQ : {item.maker?.moq?.name_kr}</li>
                        <li className="txt-sm">
                          지역 : {item.maker?.area}
                        </li>
                        <li className="txt-sm">
                          거래 횟수 : 0회
                        </li>
                      </ul>
                      <div className="mb-0 float-left">
                        <Link to={`/maker/home/${item?.maker_user_id}`} target="_blank">
                          <button
                            type="button"
                            className="btn btn-sm btn_puple px-3"
                          >
                            제조사 상세페이지로 이동
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          }

          <div className="d-flex justify-content-between">
            <p>
              <Link to="/mypage/oem">
                <button type="button" className="btn btn_puple">
                  목록보기
                </button>
              </Link>
            </p>
            <p>
              <button type="button" className="btn btn-outline-light" onClick={remove}>
                삭제
              </button>
            </p>
          </div>
        </div>
      </div>
    </MypageCard>
  );
};

export default MypageOemDetail;
