import React, {useEffect, useState} from "react";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import ManagerList from "../common/ManagerList";
import APIHelper from "../../../helpers/APIHelper";
import moment from "moment";
import Paging from "../../common/Paging";

const AdminManagerActionLog = (props) => {
  //const [renew, setRenew] = useState(0);
  const [me, setMe] = useState({});
  const [results, setResults] = useState();
  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      await gets(me);
    }
    fetch();
  }, [me?.id, currPage]);

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "어드민 관리 > 관리자 액션 정보",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  // 현재 선택한 바이어 state
  //const [managerState, setManagerState] = useState();
  const managerClick = async (e) => {
    //setManagerState(e);
    setMe(e);
    setCurrPage(1);
  };

  // 액션기록 가져오기
  const gets = (item) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("admin/action/logs", {
        articlesPerPage: 15,
        adminId: item.id,
        paging: 1,
        currPage
      });
      if (pageInfo) setPageInfo(pageInfo);
      setResults(result);
      window.scrollTo(0, 0);
      return resolve(true);
    });
  };

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)", minHeight: 800}}
      >
        <section className="col-4" style={{height: "100%"}}>
          <ManagerList click={managerClick} renew={new Date().getTime()} type={"ord"}/>
        </section>
        <section className="col-8" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">액션 내역</p>
              <p className="m-1 mb-2"></p>
            </div>

            <p className="px-3 text-start bold my-3">총 {pageInfo?.totalArticles || 0}건</p>
            {results?.length > 0 &&
              <div
                className="wid_scroll scroll_y custom_scroll"
                style={{height: "auto"}}
              >
                <article className="wid_inline">
                  <table className="table basic_list2 w-100">
                    <colgroup>
                      <col width="10%"/>
                      <col width="*"/>
                      <col width="18%"/>
                    </colgroup>
                    <tr>
                      <th>No</th>
                      <th>메뉴 - 액션 내용</th>
                      <th>일시</th>
                    </tr>
                    {results && results?.map((item, i) => (
                      <tr>
                        <td className="center">{(pageInfo?.totalArticles - (pageInfo.currentPage - 1) * pageInfo.articlesPerPage) - i}</td>
                        <td className="center">{item.content}</td>
                        <td className="center">{moment(item.created_at).format("YYYY.MM.DD HH:mm")}</td>
                      </tr>
                    ))}
                  </table>
                </article>
              </div>
            }

            {(!results || results?.length === 0) &&
              <div className="col w-100">
                <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-lblue bold">
                  액션 기록이 없습니다.
                </div>
              </div>
            }
            <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminManagerActionLog;
