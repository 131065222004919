import React, { useState } from "react";
import icon_3d_rotate_w from "../../image/icon_3d_rotate_w.svg";
import arrowleft from "../../image/arrow-left_w.svg";
import arrowright from "../../image/arrow-right.svg";
import prod_img01 from "../../image/prod_img01.png";
import prod_img02 from "../../image/prod_img02.png";
import prod_img03 from "../../image/prod_img03.png";
import box_img06 from "../../image/box_img06.png";
import box_img15 from "../../image/box_img15.png";
import box_img16 from "../../image/box_img16.png";
import box_img17 from "../../image/box_img17.png";
import box_img18 from "../../image/box_img18.png";

const Oem3dBox = (props) => {
  return (
    <div className="MAX_wide my-4">
      <div className="mt-3 justify-content-center align-items-center w-100 d-flex flex-column">
        <section
          className="box_round min_h"
          style={{ width: "calc(50% - 10px)" }}
        >
          <div
            className="p-5 p_rative"
            style={{
              background: "#000",
              minHeight: "450px",
              textAlign: "center",
            }}
          >
            <p className="p_absolute" style={{ bottom: "30px", right: "30px" }}>
              <button type="button" className="btn p-0">
                <img src={icon_3d_rotate_w} style={{ width: "40px" }} />
              </button>
            </p>
            <img src={box_img06} style={{ width: "380px" }} />
          </div>
          <article className="px-4 py-1 mt-4">
            <div className="border p_rative">
              <div className="p-3 py-1 txt-gray border-bottom bg-lpurple">
                패키지 타입
              </div>

              <div className="row row-cols-6 g-2 text-center px-3 my-3">
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm_on">
                    기본형
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm">
                    창문형
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm">
                    날개형
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm">
                    싸바리
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm">
                    표지싸바
                  </button>
                </div>
              </div>
            </div>
          </article>

          <article className="px-4 d-flex py-1">
            <div className="border p_rative">
              <div className="p-3 py-1 txt-gray border-bottom bg-lpurple">
                형태
              </div>

              <div className="row row-cols-3 g-2 text-center px-3 my-3">
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm_on">
                    맞두껑
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm">
                    십자조립형
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm">
                    자석 철판
                  </button>
                </div>
              </div>
            </div>

            <div className="border p_rative mx-3">
              <div className="p-3 py-1 txt-gray border-bottom bg-lpurple">
                라미네이팅
              </div>

              <div className="row row-cols-3 g-2 text-center px-3 my-3">
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm_on">
                    무광
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm">
                    유광
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm">
                    엠보
                  </button>
                </div>
              </div>
            </div>
          </article>
          <article className="px-4 py-1">
            <div className="border p_rative">
              <div className="p-3 py-1 txt-gray border-bottom bg-lpurple">
                재질
              </div>

              <div className="row row-cols-6 g-2 text-center px-3 my-3">
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm_on">
                    SC
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm">
                    스노우지
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm">
                    스팩
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm">
                    아트지
                  </button>
                </div>
              </div>
            </div>
          </article>

          <article className="px-4 py-1 mb-4">
            <table className="table basic_sm">
              <colgroup>
                <col width="20%" />
                <col width="*" />
              </colgroup>
              <tr>
                <th className="center txt-sm">컬러 변경</th>
                <td>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input mt-1"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="option1"
                    />
                    <label className="form-check-label " for="inlineCheckbox1">
                      컬러 변경1
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input mt-1"
                      type="checkbox"
                      id="inlineCheckbox2"
                      value="option2"
                    />
                    <label className="form-check-label" for="inlineCheckbox2">
                      컬러변경2
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">표현 유무</th>
                <td>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input mt-1"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="option1"
                    />
                    <label className="form-check-label " for="inlineCheckbox1">
                      로고
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input mt-1"
                      type="checkbox"
                      id="inlineCheckbox2"
                      value="option2"
                    />
                    <label className="form-check-label" for="inlineCheckbox2">
                      문구
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input mt-1"
                      type="checkbox"
                      id="inlineCheckbox2"
                      value="option2"
                    />
                    <label className="form-check-label" for="inlineCheckbox2">
                      설명글
                    </label>
                  </div>
                </td>
              </tr>
            </table>
          </article>
        </section>

        <section
          className="box_round min_h px-4 mt-3"
          style={{ width: "calc(50% - 10px)" }}
        >
          <article className="py-4">
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold">관련 단상자</p>
              <div
                className="btn-group btn-group-sm"
                role="group"
                aria-label="Basic outlined example"
                style={{ marginBottom: "-1px" }}
              >
                <button
                  type="button"
                  className="btn btn-outline-light p-1 px-3"
                >
                  <img src={arrowleft} style={{ width: "10px" }} />
                </button>
                <button
                  type="button"
                  className="btn btn-outline-light p-1 px-3"
                >
                  <img src={arrowright} style={{ width: "10px" }} />
                </button>
              </div>
            </div>

            <div className="row row-cols-4 g-2 pt-3">
              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{ height: "150px", textAlign: "center" }}
                  >
                    <img
                      src={box_img15}
                      className="py-3"
                      style={{ width: "120px", objectFit: "cover" }}
                    />
                  </p>
                  <p className="p-3" style={{ height: "100px" }}>
                    <a href="">
                      <span className="txt-sm bold">기본형 단상자</span>
                      <br />
                      <span className="txt-sm txt-gray">
                        형태 : 맞뚜껑형
                        <br />
                        재질 : 아트지
                      </span>
                    </a>
                  </p>
                </div>
              </div>

              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{ height: "150px", textAlign: "center" }}
                  >
                    <img
                      src={box_img16}
                      className="py-4"
                      style={{ width: "120px", objectFit: "cover" }}
                    />
                  </p>
                  <p className="p-3" style={{ height: "100px" }}>
                    <a href="">
                      <span className="txt-sm bold">날개형/고리형</span>
                      <br />
                      <span className="txt-sm txt-gray">
                        형태 : 십자조립형
                        <br />
                        재질 : 스노우지
                      </span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{ height: "150px", textAlign: "center" }}
                  >
                    <img
                      src={box_img17}
                      className="py-3"
                      style={{ width: "100px", objectFit: "cover" }}
                    />
                  </p>
                  <p className="p-3" style={{ height: "100px" }}>
                    <a href="">
                      <span className="txt-sm bold">표지 싸바리</span>
                      <br />
                      <span className="txt-sm txt-gray">
                        형태 : 종이 패드 삽입
                        <br />
                        재질 : 라이트
                        <br />
                      </span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{ height: "150px", textAlign: "center" }}
                  >
                    <img
                      src={box_img18}
                      className="py-3"
                      style={{ width: "100px", objectFit: "cover" }}
                    />
                  </p>
                  <p className="p-3" style={{ height: "100px" }}>
                    <a href="">
                      <span className="txt-sm bold">
                        커스텀 단상자
                        <br />
                      </span>
                      <span className="txt-sm txt-gray">
                        형태 : 자석 철판
                        <br />
                        재질 : 레자크지
                        <br />
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </article>
          <article className="py-4">
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold">관련 제조사 제품</p>
              <div
                className="btn-group btn-group-sm"
                role="group"
                aria-label="Basic outlined example"
                style={{ marginBottom: "-1px" }}
              >
                <button
                  type="button"
                  className="btn btn-outline-light p-1 px-3"
                >
                  <img src={arrowleft} style={{ width: "10px" }} />
                </button>
                <button
                  type="button"
                  className="btn btn-outline-light p-1 px-3"
                >
                  <img src={arrowright} style={{ width: "10px" }} />
                </button>
              </div>
            </div>

            <div className="row row-cols-3 g-3 pt-3">
              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{ height: "150px", textAlign: "center" }}
                  >
                    <img
                      src={prod_img02}
                      style={{ height: "145px", objectFit: "cover" }}
                    />
                  </p>
                  <p className="p-3" style={{ height: "100px" }}>
                    <a href="">
                      <span className="txt-brown bold">제품분류-제품종류</span>
                      <br />
                      <span className="txt-gray">
                        Abib Jericho Rose Essence
                        <br />
                        Nutrition Pump 50mL
                      </span>
                    </a>
                  </p>
                </div>
              </div>

              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{ height: "150px", textAlign: "center" }}
                  >
                    <img
                      src={prod_img03}
                      style={{ height: "145px", objectFit: "cover" }}
                    />
                  </p>
                  <p className="p-3" style={{ height: "100px" }}>
                    <a href="">
                      <span className="txt-brown bold">제품분류-제품종류</span>
                      <br />
                      <span className="txt-gray">
                        Abib Jericho Rose Essence
                        <br />
                        Nutrition Pump 50mL
                      </span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{ height: "150px", textAlign: "center" }}
                  >
                    <img
                      src={prod_img01}
                      style={{ height: "145px", objectFit: "cover" }}
                    />
                  </p>
                  <p className="p-3" style={{ height: "100px" }}>
                    <a href="">
                      <span className="txt-brown bold">제품분류-제품종류</span>
                      <br />
                      <span className="txt-gray">
                        Abib Jericho Rose Essence
                        <br />
                        Nutrition Pump 50mL
                      </span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{ height: "150px", textAlign: "center" }}
                  >
                    <img
                      src={prod_img02}
                      style={{ height: "145px", objectFit: "cover" }}
                    />
                  </p>
                  <p className="p-3" style={{ height: "100px" }}>
                    <a href="">
                      <span className="txt-brown bold">제품분류-제품종류</span>
                      <br />
                      <span className="txt-gray">
                        Abib Jericho Rose Essence
                        <br />
                        Nutrition Pump 50mL
                      </span>
                    </a>
                  </p>
                </div>
              </div>

              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{ height: "150px", textAlign: "center" }}
                  >
                    <img
                      src={prod_img03}
                      style={{ height: "145px", objectFit: "cover" }}
                    />
                  </p>
                  <p className="p-3" style={{ height: "100px" }}>
                    <a href="">
                      <span className="txt-brown bold">제품분류-제품종류</span>
                      <br />
                      <span className="txt-gray">
                        Abib Jericho Rose Essence
                        <br />
                        Nutrition Pump 50mL
                      </span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{ height: "150px", textAlign: "center" }}
                  >
                    <img
                      src={prod_img01}
                      style={{ height: "145px", objectFit: "cover" }}
                    />
                  </p>
                  <p className="p-3" style={{ height: "100px" }}>
                    <a href="">
                      <span className="txt-brown bold">제품분류-제품종류</span>
                      <br />
                      <span className="txt-gray">
                        Abib Jericho Rose Essence
                        <br />
                        Nutrition Pump 50mL
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
    </div>
  );
};
export default Oem3dBox;
