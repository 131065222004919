import React, {useEffect, useRef, useState} from "react";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";
import Paging from "../../common/Paging";

const AdminDataOemStory = (props) => {
  const [selectedOemStory, setSelectedOemStory] = useState({});
  const [oemStories, setOemStories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);

  const [id, setId] = useState(0);

  const [makers, setMakers] = useState([]);
  const [categoryCd, setCategoryCd] = useState('');
  const [productCd, setProductCd] = useState('');
  const [makerUserId, setMakerUserId] = useState('');
  const repImageRef = useRef();
  const image1Ref = useRef();
  const image2Ref = useRef();
  const image3Ref = useRef();
  const image4Ref = useRef();
  const image5Ref = useRef();
  const [repImage, setRepImage] = useState();
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [image3, setImage3] = useState();
  const [image4, setImage4] = useState();
  const [image5, setImage5] = useState();
  const [name, setName] = useState('');
  const [salesCountry, setSalesCountry] = useState('');
  const [target, setTarget] = useState('');
  const [moq, setMoq] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [content, setContent] = useState('');
  const [volume, setVolume] = useState('');
  const [targetItem, setTargetItem] = useState('');
  const [scent, setScent] = useState('');
  const [concept, setConcept] = useState('');
  const [addIngredients, setAddIngredients] = useState('');
  const [blacklistIngredients, setBlacklistIngredients] = useState('');
  const [exRequests, setExRequests] = useState('');

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      await getCategories();
      await getMakers();
    }
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await load();
    }
    fetch();
  }, [currPage]);

   useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "홈노출/검색관리 > 홈 - OEM/ODM 스토리",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  // 제조사 리스트 가져오기
  const getMakers = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.get("user/maker/gets");
      setMakers(result);
      return resolve(true);
    });
  };

  const load = (autoSelect = true) => {
    return new Promise(async (resolve, reject) => {
      await gets(autoSelect);
      return resolve(true);
    });
  }

  const getCategories = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '110000'});
      if (code === 200) {
        setCategories(result);
        if (result.length > 0) {
          setCategoryCd(result[0].cd);
          await getProducts(result[0].cd);
        }
      }
      return resolve(true);
    });
  }

  const getProducts = (parentCd) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd});
      if (code === 200) {
        setProducts(result);
        if (result.length > 0) {
          setProductCd(result[0].cd);
        }
      }
      return resolve(true);
    });
  }

  const init = () => {
    setId(0);
    setCategoryCd('');
    setProductCd('');
    setMakerUserId('');
    setName('');
    setSalesCountry('');
    setTarget('');
    setMoq('');
    setUnitPrice('');
    setContent('');
    setVolume('');
    setTargetItem('');
    setScent('');
    setConcept('');
    setAddIngredients('');
    setBlacklistIngredients('');
    setExRequests('');
  };

  const gets = (autoSelect) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("data/oemstory/gets", {paging: 1, currPage});
      if (pageInfo) setPageInfo(pageInfo);
      setOemStories(result);
      if (autoSelect && result.length > 0) {
        setSelectedOemStory(result[0]);
        await get(result[0]?.id);
      }
      document.getElementById('scroll_div').scrollTop = 0;
      return resolve(true);
    });
  };

  const get = (id) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("data/oemstory/get", {id});
      setId(result?.id || 0);
      setCategoryCd(result?.category_cd || '');
      if ((result?.category_cd || '') !== '')
        await getProducts(result?.category_cd || '');
      setProductCd(result?.product_cd || '');
      setMakerUserId(result?.maker_user_id || '');
      setName(result?.name || '');
      setRepImage(result?.rep_image || '');
      setImage1(result?.image1 || '');
      setImage2(result?.image2 || '');
      setImage3(result?.image3 || '');
      setImage4(result?.image4 || '');
      setImage5(result?.image5 || '');
      setSalesCountry(result?.sales_country || '');
      setTarget(result?.target || '');
      setMoq(result?.moq || '');
      setUnitPrice(result?.unit_price || '');
      setContent(result?.content || '');
      setVolume(result?.volume || '');
      setTargetItem(result?.target_item || '');
      setScent(result?.scent || '');
      setConcept(result?.concept || '');
      setAddIngredients(result?.add_ingredients || '');
      setBlacklistIngredients(result?.blacklist_ingredients || '');
      setExRequests(result?.ex_requests || '');
      setSelectedOemStory(result);
      return resolve(true);
    });
  };

  const remove = async (id) => {
    if (!await CommonLib.jconfirm("삭제하시겠습니까?")) return;
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: {code: retCode, result, message},
        } = await APIHelper.deleteForm(`data/oemstory/delete?id=${id}`);
        await CommonLib.jalert(message);
        await load();

        try {
          await APIHelper.postForm("admin/action/log", {
            content: "홈노출/검색관리 > 홈 - OEM/ODM 스토리 > 삭제",
            param1 : id,
          });
        } catch (err) {
          console.log(err);
        }
      } catch (err) {
      }
      return resolve(true);
    });
  };

  const submit = (e) => {
    return new Promise(async (resolve, reject) => {
      try {
        const repImageFiles = repImageRef?.current?.files;
        const image1Files = image1Ref?.current?.files;
        const image2Files = image2Ref?.current?.files;
        const image3Files = image3Ref?.current?.files;
        const image4Files = image4Ref?.current?.files;
        const image5Files = image5Ref?.current?.files;

        const {data: {code: retCode, result, message}} = await APIHelper.postForm(`data/oemstory/submit`, {
          id,
          categoryCd: categoryCd || categories[0].cd,
          productCd: productCd || products[0].cd,
          makerUserId: makerUserId || '',
          name: name || '',
          repImage: repImageFiles ? repImageFiles[0] : null,
          image1: image1Files ? image1Files[0] : null,
          image2: image2Files ? image2Files[0] : null,
          image3: image3Files ? image3Files[0] : null,
          image4: image4Files ? image4Files[0] : null,
          image5: image5Files ? image5Files[0] : null,
          salesCountry: salesCountry || '',
          target: target || '',
          moq: moq || '',
          unitPrice: unitPrice || '',
          content: content || '',
          volume: volume || '',
          targetItem: targetItem || '',
          scent: scent || '',
          concept: concept || '',
          addIngredients: addIngredients || '',
          blacklistIngredients: blacklistIngredients || '',
          exRequests: exRequests || '',
        });
        if (!id) {
          await CommonLib.jalert('추가가 완료되었습니다.');

          try {
            await APIHelper.postForm("admin/action/log", {
              content: "홈노출/검색관리 > 홈 - OEM/ODM 스토리 > 추가",
              param1 : categoryCd,
              param2 : productCd,
              param3 : makerUserId,
              param4 : name,
              param5 : repImage,
              param6 : salesCountry,
              param7 : target,
              param8 : moq,
              param9 : unitPrice,
            });
          } catch (err) {
            console.log(err);
          }
        } else {
          await CommonLib.jalert('수정이 완료되었습니다.');

          try {
            await APIHelper.postForm("admin/action/log", {
              content: "홈노출/검색관리 > 홈 - OEM/ODM 스토리 > 수정",
              param1 : categoryCd,
              param2 : productCd,
              param3 : makerUserId,
              param4 : name,
              param5 : repImage,
              param6 : salesCountry,
              param7 : target,
              param8 : moq,
              param9 : unitPrice,
            });
          } catch (err) {
            console.log(err);
          }
        }
        await load(false);
        await get(result?.id);
      } catch (err) {
      }
      return resolve(true);
    });
  };

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-2 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <section className="col-4" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">OEM/ODM 스토리 리스트</p>
              <p className="m-1 mb-2"></p>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll mt-2"
              id="scroll_div"
              style={{height: "calc(100% - 60px)"}}
            >
              <div className="wid_inline">
                {oemStories.map((item, i) => (
                  <div
                    key={i}
                    className={`box_round_5 ${
                      item.id === selectedOemStory?.id
                        ? "border_purple"
                        : "border"
                    } my-2 p-3`}
                    onClick={async () => {
                      setSelectedOemStory(item);
                      await get(item?.id);
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <div className="row">
                        <p className="h6 mb-2">{item.name}</p>
                      </div>
                      <div className="colume">
                        <p className="txt-md" style={{textAlign: "right", fontSize: "0.9rem"}}>{item.category?.name_kr} / {item.product?.name_kr}</p>
                        <p className="txt-sm" style={{textAlign: "right"}}>MOQ : {item.moq} | Unit Price : {item.unit_price}</p>
                      </div>
                    </div>
                  </div>
                ))}
                <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
              </div>
            </div>
          </div>
        </section>

        <section className="col-8" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "calc(100% - 30px)"}}
          >
            <div className="d-flex justify-content-end border-bottom">
              <p className="m-1 mb-2">
                {!id &&
                  <button
                    onClick={async () => {
                      setId(0);
                      await submit();
                    }}
                    type="button"
                    className="btn btn-purple txt-sm py-1 px-3"
                    style={{marginLeft: "6px"}}
                  >
                    추가
                  </button>
                }
                {id > 0 &&
                  <>
                    <button
                      onClick={async () => {
                        setId(0);
                        init();
                        await getCategories();
                        setSelectedOemStory({});
                        await get(0);
                      }}
                      type="button"
                      className="btn btn-purple txt-sm py-1 px-3"
                      style={{marginLeft: "6px"}}
                    >
                      초기화
                    </button>
                    <button
                      onClick={async () => {
                        await submit();
                      }}
                      type="button"
                      className="btn btn-purple txt-sm py-1 px-3"
                      style={{marginLeft: "6px"}}
                    >
                      수정
                    </button>
                    <button
                      onClick={async () => {
                        await remove(id);
                      }}
                      type="button"
                      className="btn btn-purple txt-sm py-1 px-3"
                      style={{marginLeft: "6px"}}
                    >
                      삭제
                    </button>
                  </>
                }
              </p>
            </div>

            <div
              className="px-4 wid_scroll scroll_y custom_scroll"
              style={{height: "calc(100% - 60px)"}}
            >
              <article className="wid_inline">
                <div className="d-flex justify-content-between border-bottom pb-2 mt-4">
                  <p className="h5">제조사/제품분류</p>
                </div>

                <table className="table basic w-100">
                  <colgroup>
                    <col width="20%"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th className="center">제조사</th>
                    <td>
                      <div className="input-group input-group-sm">
                        <select
                          className="form-select w-85"
                          aria-label="Default select example"
                          onChange={async (e) => {
                            setMakerUserId(e.target.value);
                          }}
                          value={makerUserId}
                        >
                          {makers.map((item, i) => <option value={item?.user_id} selected={item.user_id === makerUserId}>{item?.company_name}</option>)}
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">제품분류/종류</th>
                    <td>
                      <div className="input-group input-group-sm">
                        <select
                          className="form-select w-85"
                          aria-label="Default select example"
                          onChange={async (e) => {
                            setCategoryCd(e.target.value);
                            await getProducts(e.target.value);
                          }}
                          value={categoryCd}
                        >
                          {categories.map((item, i) => <option value={item?.cd} selected={item.cd === categoryCd}>{item?.name_kr}</option>)}
                        </select>
                        <select
                          className="form-select w-85"
                          aria-label="Default select example"
                          onChange={async (e) => {
                            setProductCd(e.target.value);
                          }}
                          value={productCd}
                        >
                          {products.map((item, i) => <option value={item?.cd} selected={item.cd === productCd}>{item?.name_kr}</option>)}
                        </select>
                      </div>
                    </td>
                  </tr>
                </table>

                <div className="d-flex justify-content-between border-bottom pb-2 mt-4">
                  <p className="h5">이미지</p>
                </div>

                <table className="table basic w-100">
                  <colgroup>
                    <col width="20%"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th className="center">대표 이미지</th>
                    <td>
                      {repImage && <div>{repImage?.filename}</div>}
                      <div className="input-group input-group-sm ">
                        <input
                          type="file"
                          className="form-control"
                          id="inputGroupFile01"
                          aria-describedby="inputGroupFileAddon01"
                          aria-label="Upload"
                          ref={repImageRef}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">이미지 No.1</th>
                    <td>
                      {image1 && <div>{image1?.filename}</div>}
                      <div className="input-group input-group-sm ">
                        <input
                          type="file"
                          className="form-control"
                          id="inputGroupFile02"
                          aria-describedby="inputGroupFileAddon02"
                          aria-label="Upload"
                          ref={image1Ref}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">이미지 No.2</th>
                    <td>
                      {image2 && <div>{image2?.filename}</div>}
                      <div className="input-group input-group-sm ">
                        <input
                          type="file"
                          className="form-control"
                          id="inputGroupFile02"
                          aria-describedby="inputGroupFileAddon02"
                          aria-label="Upload"
                          ref={image2Ref}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">이미지 No.3</th>
                    <td>
                      {image3 && <div>{image3?.filename}</div>}
                      <div className="input-group input-group-sm ">
                        <input
                          type="file"
                          className="form-control"
                          id="inputGroupFile02"
                          aria-describedby="inputGroupFileAddon02"
                          aria-label="Upload"
                          ref={image3Ref}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">이미지 No.4</th>
                    <td>
                      {image4 && <div>{image4?.filename}</div>}
                      <div className="input-group input-group-sm ">
                        <input
                          type="file"
                          className="form-control"
                          id="inputGroupFile02"
                          aria-describedby="inputGroupFileAddon02"
                          aria-label="Upload"
                          ref={image4Ref}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">이미지 No.5</th>
                    <td>
                      {image5 && <div>{image5?.filename}</div>}
                      <div className="input-group input-group-sm ">
                        <input
                          type="file"
                          className="form-control"
                          id="inputGroupFile02"
                          aria-describedby="inputGroupFileAddon02"
                          aria-label="Upload"
                          ref={image5Ref}
                        />
                      </div>
                    </td>
                  </tr>
                </table>

                <div className="d-flex justify-content-between border-bottom pb-2 mt-4">
                  <p className="h5">상세 정보</p>
                </div>

                <table className="table basic w-100">
                  <colgroup>
                    <col width="20%"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th className="center">제품명</th>
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        aria-describedby="button-addon2"
                        onChange={async (e) => await setName(e.target.value)}
                        value={name}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">판매국가</th>
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        aria-describedby="button-addon2"
                        onChange={async (e) => await setSalesCountry(e.target.value)}
                        value={salesCountry}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">타겟층</th>
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        aria-describedby="button-addon2"
                        onChange={async (e) => await setTarget(e.target.value)}
                        value={target}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">MOQ</th>
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        aria-describedby="button-addon2"
                        onChange={async (e) => await setMoq(e.target.value)}
                        value={moq}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Unit Price</th>
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        aria-describedby="button-addon2"
                        onChange={async (e) => await setUnitPrice(e.target.value)}
                        value={unitPrice}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">제품내용</th>
                    <td>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        onChange={async (e) => {
                          await setContent(e.target.value);
                        }}
                        value={content}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">용량</th>
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        aria-describedby="button-addon2"
                        onChange={async (e) => await setVolume(e.target.value)}
                        value={volume}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">타겟제품</th>
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        aria-describedby="button-addon2"
                        onChange={async (e) => await setTargetItem(e.target.value)}
                        value={targetItem}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">향/색</th>
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        aria-describedby="button-addon2"
                        onChange={async (e) => await setScent(e.target.value)}
                        value={scent}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">제품컨셉</th>
                    <td>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        onChange={async (e) => {
                          await setConcept(e.target.value);
                        }}
                        value={concept}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">첨가요청성분</th>
                    <td>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        onChange={async (e) => {
                          await setAddIngredients(e.target.value);
                        }}
                        value={addIngredients}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">무첨가요청성분</th>
                    <td>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        onChange={async (e) => {
                          await setBlacklistIngredients(e.target.value);
                        }}
                        value={blacklistIngredients}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">그 외 개발유의사항</th>
                    <td>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        onChange={async (e) => {
                          await setExRequests(e.target.value);
                        }}
                        value={exRequests}
                      />
                    </td>
                  </tr>
                </table>
              </article>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminDataOemStory;
