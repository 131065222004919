import React, {useEffect, useState} from "react";
import APIHelper from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import {useHistory} from "react-router-dom";

const SearchIngredient = (props) => {
  const [keyword, setKeyword] = useState(props.searchKeyword || '');
  const [items, setItems] = useState([]);
  const history = useHistory();

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      await load();
    }
  }

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(props.page || 1);

  const load = (page) => {
    return new Promise(async (resolve, reject) => {
      if (props.setProps) props.setProps(keyword);
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("data/ingredient/gets", {keyword, paging: 1, currPage: page || 1});
      if (code === 200) {
        if (pageInfo) setPageInfo(pageInfo);
        setItems(result);
      }
      window.scrollTo(0, 0);
      return resolve(true);
    });
  }

  useEffect(() => {
    const fetch = async () => {
      await load(currPage);
    }
    fetch();
  }, [currPage]);

  return (
    <>
      <div className="box_round min_h cnt_area pb-5">
        <div className="mx-5 mt-4 pb-5">
          <p className="h3 bold border-bottom pb-3">성분 검색</p>

          <div className="mt-4 px-5 py-3 box_round_5 bg-lpurple">
            <div className="input-group input-group-lg mt-2">
              <input
                type="text"
                className="form-control bg-white border_none"
                placeholder="검색어를 입력하세요"
                style={{width: "510px", fontSize: "14px"}}
                onChange={async (e) => {
                  await setKeyword(e.target.value)
                }}
                value={keyword}
                onKeyDown={handleKeyDown}
              />
              <button
                className="btn btn_puple px-5"
                type="button"
                id="button-addon2"
                onClick={() => load(currPage)}
              >
                검색
              </button>
            </div>
            {/*TODO*/}
            {/*<ul className="d-flex mt-3 px-0">*/}
            {/*  <li className="px-1">*/}
            {/*    <a*/}
            {/*      href="#"*/}
            {/*      className="box_round_30 bg-lpurple2 p-1 px-3 txt-point txt-sm"*/}
            {/*    >*/}
            {/*      Mask*/}
            {/*    </a>*/}
            {/*  </li>*/}
            {/*  <li className="px-1">*/}
            {/*    <a*/}
            {/*      href="#"*/}
            {/*      className="box_round_30 bg-lpurple2 p-1 px-3 txt-point txt-sm"*/}
            {/*    >*/}
            {/*      Hair*/}
            {/*    </a>*/}
            {/*  </li>*/}
            {/*  <li className="px-1">*/}
            {/*    <a*/}
            {/*      href="#"*/}
            {/*      className="box_round_30 bg-lpurple2 p-1 px-3 txt-point txt-sm"*/}
            {/*    >*/}
            {/*      Skincare*/}
            {/*    </a>*/}
            {/*  </li>*/}
            {/*  <li className="px-1">*/}
            {/*    <a*/}
            {/*      href="#"*/}
            {/*      className="box_round_30 bg-lpurple2 p-1 px-3 txt-point txt-sm"*/}
            {/*    >*/}
            {/*      Face Oil*/}
            {/*    </a>*/}
            {/*  </li>*/}
            {/*</ul>*/}
          </div>

          <p className="border-bottom p-3 txt-gray">
            화장품 성분명, 영문명, 기원 및 정의 분자구조식 등 상세한 정보를
            검색할 수 있습니다.
            <br/>
            예)녹차, 마스크
          </p>

          {items && items.length > 0 && items.map((item, key) => (
            <div className="border-bottom p-3 txt-gray" onClick={() => props.click(item?.id, keyword, currPage)} style={{cursor: "pointer"}}>
              <p className="bold h6">
                {item.name}
              </p>
              {item.name_en?.length > 0 &&
                <>
                  {item.name_en}
                  <br/>
                </>
              }
              {item.definition}
              <br/>
            </div>
          ))}
          <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        </div>
      </div>
    </>
  );
};

export default SearchIngredient;
