import React, { useState } from "react";
import first_page_black_48dp from "../../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../../image/chevron_left_black_24dp.svg";
import navigate_next_black_48dp from "../../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../../image/last_page_black_48dp.svg";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import MakerList from "../common/MakerList";

const AdminMakerActionLog = (props) => {
  // 현재 선택한 제조사 state
  const [makerState, setMakerState] = useState();
  const makerClick = (e) => {
    setMakerState(e);
  };

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{ height: "calc(100% - 30px)" }}
      >
        <section className="col-4" style={{ height: "98%" }}>
          <MakerList click={makerClick} />
        </section>
        <section className="col-8" style={{ height: "98%" }}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{ height: "100%" }}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold p-2">액션 내역</p>
              <p className="m-1 mb-2"></p>
            </div>

            <p className="px-3 text-start bold my-3">
              총 {makerState && makerState.action.length}건
            </p>
            <div
              className="wid_scroll scroll_y custom_scroll px-2"
              style={{ height: "calc(100% - 180px)" }}
            >
              <article className="wid_inline">
                <table className="table basic w-100">
                  <colgroup>
                    <col width="10%" />
                    <col width="*" />
                    <col width="18%" />
                  </colgroup>
                  <tr>
                    <th className="center">No</th>
                    <th className="center">방문자</th>
                    <th className="center">일시</th>
                  </tr>
                  {makerState &&
                    makerState.action.map((item, i) => (
                      <tr>
                        <td className="center">{i}</td>
                        <td>{item.action}</td>
                        <td className="center">{item.date}</td>
                      </tr>
                    ))}
                </table>
              </article>
            </div>

            <div className="d-flex justify-content-center py-4 d-none">
              <ul className="d-flex my-3" style={{ margin: "0 auto" }}>
                <li className="PAGE">
                  <a href="#" aria-label="Previous">
                    <img
                      src={first_page_black_48dp}
                      style={{ width: "25px" }}
                    />
                  </a>
                </li>
                <li className="PAGE">
                  <a href="#" aria-label="Previous">
                    <img
                      src={chevron_left_black_24dp}
                      style={{ width: "25px" }}
                    />
                  </a>
                </li>
                <li className="PAGE">
                  <a href="#" className="ON">
                    1
                  </a>
                </li>
                <li className="PAGE">
                  <a href="#">2</a>
                </li>
                <li className="PAGE">
                  <a href="#">3</a>
                </li>
                <li className="PAGE">
                  <a href="#" aria-label="Previous">
                    <img
                      src={navigate_next_black_48dp}
                      style={{ width: "25px" }}
                    />
                  </a>
                </li>
                <li className="PAGE">
                  <a href="#" aria-label="Previous">
                    <img src={last_page_black_48dp} style={{ width: "25px" }} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminMakerActionLog;
