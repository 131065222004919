import React, {useEffect, useState} from "react";
import open_in_new from "../../../image/open_in_new.svg";
import icon_export from "../../../image/icon_export.svg";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import {Link, useHistory} from "react-router-dom";
import APIHelper from "../../../helpers/APIHelper";
import Constants from "../../../constants/constants";
import moment from "moment";
import Paging from "../../common/Paging";
import Loading from "../../../hooks/Loading";
import CommonLib from "../../../libraries/CommonLib";

const AdminProjectHome = (props) => {
  const status = props?.match?.params?.status;

  const [mounted, setMounted] = useState(false);
  const [statuses, setStatuses] = useState({});
  const [projects, setProjects] = useState(null);

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  const history = useHistory();

  const [renew, setRenew] = useState(new Date().getTime());

  useEffect(() => {
    const fetch = async () => {
      await getStatuses();
    }
    fetch();
  }, [renew]);

  useEffect(() => {
    const fetch = async () => {
      setMounted(false);
      await getProjects();
      setMounted(true);
    }
    fetch();
  }, [currPage, renew]);

  const getStatuses = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("project/statuses", {mode: "admin"});
      if (code === 200) {
        setStatuses(result);
      }
      return resolve(true);
    });
  };

  const getProjects = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("project/gets", {status, paging: 1, currPage, articlesPerPage: 4});
      if (code === 200) {
        if (pageInfo) setPageInfo(pageInfo);
        setProjects(result);
      }
      document.getElementById('scroll_div').scrollTop = 0;
      return resolve(true);
    });
  };

  const [checks, setChecks] = useState([]);

  const checkAll = () => {
    const tchecks = [];
    for (const [key, project] of Object.entries(projects))
      tchecks.push(project.id);
    setChecks(tchecks);
  };

  const uncheckAll = () => {
    setChecks([]);
  };

  const removeCheck = (id) => {
    const tchecks = [...checks];
    tchecks.splice(tchecks.indexOf(id), 1);
    setChecks(tchecks);
  }

  const deleteProject = async () => {
    if (checks.length <= 0) {
      await CommonLib.jalert('선택된 프로젝트가 없습니다.');
      return;
    }

    let result = await CommonLib.jconfirm("선택된 프로젝트를 삭제하시겠습니까?");
    if (result) {
      const {data: {code: retCode, result, message}} = await APIHelper.deleteForm(`project/delete?ids=${checks.join(',')}`);
      setRenew(new Date().getTime());
      setCurrPage(1);
      uncheckAll();
    }
  };

  const copyProject = async () => {
    if (checks.length <= 0) {
      await CommonLib.jalert('선택된 프로젝트가 없습니다.');
      return;
    }

    let result = await CommonLib.jconfirm("선택된 프로젝트를 복사하시겠습니까?");
    if (result) {
      const {data: {code: retCode, result, message}} = await APIHelper.postForm(`project/copy`, {ids: checks.join(',')});
      setRenew(new Date().getTime());
      uncheckAll();
    }
  };

  return (
    <AdminCard>
      <div className="d-flex mt-3">
        <div className="d-flex">
          <div
            className={`${window.location.pathname.includes('/new') ? 'bg-lpurple' : 'bg-lgray'} mt-4 shadow2 box_round_4 m-1`}
            style={{width: "140px", height: "100px", cursor: "pointer"}}
            onClick={() => history.push('/admin/project/status/new')}
          >
            <p className="p-2 px-4">신규</p>
            <p className="p-2 px-4 h3" style={{textAlign: "right"}}>
              {statuses.new}건
            </p>
          </div>

          <div
            className={`${window.location.pathname.includes('/progress') ? 'bg-lpurple' : 'bg-lgray'} mt-4 shadow2 box_round_4 m-1`}
            style={{width: "140px", height: "100px", cursor: "pointer"}}
            onClick={() => history.push('/admin/project/status/progress')}
          >
            <p className="p-2 px-4">진행중</p>
            <p className="p-2 px-4 h3" style={{textAlign: "right"}}>
              {statuses.progress}건
            </p>
          </div>

          <div
            className={`${window.location.pathname.includes('/done') ? 'bg-lpurple' : 'bg-lgray'} mt-4 shadow2 box_round_4 m-1`}
            style={{width: "140px", height: "100px", cursor: "pointer"}}
            onClick={() => history.push('/admin/project/status/done')}
          >
            <p className="p-2 px-4">완료</p>
            <p className="p-2 px-4 h3" style={{textAlign: "right"}}>
              {statuses.done}건
            </p>
          </div>

          <div
            className={`${window.location.pathname.includes('/all') ? 'bg-lpurple' : 'bg-lgray'} mt-4 shadow2 box_round_4 m-1`}
            style={{width: "140px", height: "100px", cursor: "pointer"}}
            onClick={() => history.push('/admin/project/status/all')}
          >
            <p className="p-2 px-4">전체</p>
            <p className="p-2 px-4 h3" style={{textAlign: "right"}}>
              {statuses.all}건
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <p className="mt-0 pt-0">
          {checks.length < 4 ?
            <button type="button" className="mt-3 btn btn-sm btn-ligray px-4" onClick={() => checkAll()}>
              전체선택
            </button>
            :
            <button type="button" className="mt-3 btn btn-sm btn-ligray px-4" onClick={() => uncheckAll()}>
              전체해제
            </button>
          }
        </p>
        <p className="ms-auto mt-0 pt-0">
          <button type="button" className="mt-3 btn btn-sm btn-blue px-4" onClick={() => copyProject()}>
            프로젝트 복사 +
          </button>
          &nbsp;&nbsp;&nbsp;
          <button type="button" className="mt-3 btn btn-sm btn-ligray px-4" onClick={() => deleteProject()}>
            프로젝트 삭제 -
          </button>
        </p>
      </div>
      <section style={{height: "calc(98% - 200px)"}}>
        <div
          className="content-container bg-white my-4 shadow2 box_round_4"
          style={{height: "98%"}}
        >
          <div
            className="p-4 wid_scroll scroll_y custom_scroll"
            id="scroll_div"
            style={{height: "calc(100% - 20px)"}}
          >
            {projects && projects.map((project, key) => {
              const progressCnt = project.products.filter((e) => !(e.status === '바이어 상담 문의 - 신청' || e.status === '거래 프로세스 완료' || e.status === '거래 프로세스 종료')).length;
              const cancelCnt = project.products.filter((e) => e.status === '거래 프로세스 종료').length;

              return (
                <article key={key} className="border d-flex mt-3">
                  <ul className="w-1 p_rative p-2 mb-0 border-right">
                    <input type="checkbox" onClick={() => checks.includes(project.id) ? removeCheck(project.id) : setChecks([project.id, ...checks])} checked={checks.includes(project.id)}/>
                  </ul>
                  <ul className="w-14 p_rative p-3 mb-0 bg-lpurple border-right">
                    {project?.name?.length > 0 ?
                      <li className="txt-dark py-1">
                        <strong>{project.name} ({project.prj_no})</strong>
                      </li>
                      :
                      <li className="txt-dark py-1">
                        <strong>프로젝트번호 :</strong> {project.prj_no}
                      </li>
                    }
                    <li className="txt-dark py-1">
                      <strong>진행상태 :</strong>{" "}
                      <span className="bold">{project.status.split('-')[0]}</span>
                    </li>
                    <li className="txt-dark py-1">
                      <strong>요청일자 :</strong> {moment(project.created_at).format("YYYY.MM.DD HH:mm")}
                    </li>
                    <li className="txt-dark py-1">
                      <strong>바이어 제작 문의</strong>
                    </li>
                  </ul>
                  <dl
                    className="p_rative p-3 mb-0 border-right"
                    style={{width: "16%"}}
                  >
                    <dt className="d-flex justify-content-between">
                      <p className="h6 bold">바이어</p>
                      <Link to={`/buyer/home/${project?.buyer_user_id}`} target="_blank">
                        <button type="button" className="btn p-0">
                          <img src={open_in_new} style={{width: "25px"}}/>
                        </button>
                      </Link>
                    </dt>

                    <dd>
                      <div className="d-flex mt-3 mb-2">
                        {project.buyer &&
                          <>
                            <p className="btn_oval_lg mb-1" style={{paddingLeft: "15px"}}>
                              {project.buyer.rep_image_fid && project.buyer.rep_image_fid.length > 0 ?
                                <img
                                  src={`${Constants.API_HOST}/common/download?fid=${project.buyer.rep_image_fid}&cd=inline&thumb=400`}
                                  style={{width: "65px", height: "65px", objectFit: "cover"}}
                                />
                                :
                                <div style={{width: "65px", height: "65px", backgroundColor: "#efefef"}}></div>
                              }
                            </p>
                            <div className="text-start" style={{paddingLeft: "45px"}}>
                              <p className="h6">{project.buyer && (project.buyer?.company?.length > 0 ? project.buyer.company : (project?.buyer?.first_name + " " + project?.buyer?.middle_name + " " + project?.buyer?.last_name))}</p>
                              <p>국적 : {project.buyer.country?.name_kr}</p>
                              <p>거래횟수 : 0회</p>
                            </div>
                          </>
                        }
                      </div>
                    </dd>
                  </dl>
                  {project?.manager_cnt > 0 ?
                    <dl className="w-14 p_rative p-3 mb-0 border-right">
                      <dt className="d-flex justify-content-between mb-2">
                        <p className="h6 bold">매니저</p>
                        <Link to={`/admin/project/manager/${project.id}`}>
                          <button type="button" className="btn p-0">
                            <img src={icon_export} style={{width: "30px"}}/>
                          </button>
                        </Link>
                      </dt>
                      <dd>
                        <p>
                          리더 <span className="h3">{project?.manager_leader?.name}</span>
                        </p>
                      </dd>
                      <dd className="px-3" style={{textAlign: "right"}}>
                        <p>
                          <span className="h3">{project?.manager_cnt}명</span>
                        </p>
                      </dd>
                    </dl>
                    :
                    <dl className="w-14 p_rative p-3 mb-0 border-right">
                      <dd className="h5 py-5 text-center txt-gray">
                        배당 매니저 없음
                      </dd>
                    </dl>
                  }
                  {project?.request_form_cnt > 0 ?
                    <dl className="w-14 p_rative p-3 mb-0 border-right">
                      <dt className="d-flex justify-content-between mb-2">
                        <p className="h6 bold">제작의뢰서</p>
                        <Link to={`/admin/project/request/form/${project.id}`}>
                          <button type="button" className="btn p-0">
                            <img src={icon_export} style={{width: "30px"}}/>
                          </button>
                        </Link>
                      </dt>
                      <dd className="px-3" style={{textAlign: "right"}}>
                        <p>
                          진행 <span className="h3">{project?.request_form_cnt}건</span>
                        </p>
                      </dd>
                      <dd className="px-3" style={{textAlign: "right"}}>
                        <p>
                          취소 <span className="h3">{cancelCnt}건</span>
                        </p>
                      </dd>
                    </dl>
                    :
                    <dl className="w-14 p_rative p-3 mb-0 border-right">
                      <dd className="h5 py-5 text-center txt-gray">
                        제작 의뢰서 없음
                      </dd>
                    </dl>
                  }
                  {project?.total_maker_request_cnt ?
                    <dl className="w-14 p_rative p-3 mb-0 border-right">
                      <dt className="d-flex justify-content-between mb-2">
                        <p className="h6 bold">제조사 연동</p>
                        <Link to={`/admin/project/connect/${project?.id}`}>
                          <button type="button" className="btn p-0">
                            <img src={icon_export} style={{width: "30px"}}/>
                          </button>
                        </Link>
                      </dt>

                      <dd className="px-3" style={{textAlign: "right"}}>
                        <p>
                          수락 <span className="h3">{project?.accepted_maker_request_cnt}건</span>
                        </p>
                      </dd>
                      <dd className="px-3" style={{textAlign: "right"}}>
                        <p>
                          대기 <span className="h3">{project?.ready_maker_request_cnt}건</span>
                        </p>
                      </dd>
                    </dl>
                    :
                    <dl className="w-14 p_rative p-3 mb-0 border-right">
                      <dd className="h5 py-5 text-center txt-gray">
                        제조사 연동 없음
                      </dd>
                    </dl>
                  }
                  {project?.formed_product_cnt > 0 ?
                    <dl className="w-14 p_rative p-3 mb-0 border-right">
                      <dt className="d-flex justify-content-between mb-2">
                        <p className="h6 bold">제품 설계</p>
                        <button type="button" className="btn p-0" onClick={() => {
                          localStorage.setItem("timeline.params.type", '');
                          localStorage.setItem("timeline.params.project_id", '');
                          localStorage.setItem("timeline.params.project_product_id", '');
                          localStorage.setItem("timeline.params.project_maker_request_id", '');
                          history.push(`/admin/project/formed/product/${project?.id}`);
                        }}>
                          <img src={icon_export} style={{width: "30px"}}/>
                        </button>
                      </dt>

                      <dd className="px-3" style={{textAlign: "right"}}>
                        <p>
                          진행 <span className="h3">{project?.formed_product_cnt}건</span>
                        </p>
                      </dd>
                      <dd className="px-3" style={{textAlign: "right"}}>
                        <p>
                          취소 <span className="h3">0건</span>
                        </p>
                      </dd>
                    </dl>
                    :
                    <dl className="w-14 p_rative p-3 mb-0 border-right">
                      <dd className="h5 py-5 text-center txt-gray">
                        생성된 제품 없음
                      </dd>
                    </dl>
                  }
                  <dl className="p-2 py-3 mb-0 bg-lblue" style={{width: "18%"}}>
                    <Link to={`/admin/project/detail/${project.id}`}>
                      <button
                        type="button"
                        className="w-48 btn btn-sm btn-blue m-1"
                        style={{minWidth: "95px", fontSize: "0.8rem"}}
                      >
                        제작 문의 상세
                      </button>
                    </Link>
                    <Link to={`/admin/project/connect/${project.id}`}>
                      <button
                        type="button"
                        className="w-48 btn btn-sm btn-dark m-1"
                        style={{minWidth: "95px", fontSize: "0.8rem"}}
                      >
                        제조사 연동 관리
                      </button>
                    </Link>
                    <Link to={`/admin/project/manager/${project.id}`}>
                      <button
                        type="button"
                        className="w-48 btn btn-sm btn-dark m-1"
                        style={{minWidth: "95px", fontSize: "0.8rem"}}
                      >
                        매니저 배당 관리
                      </button>
                    </Link>
                    <button
                      type="button"
                      className="w-48 btn btn-sm btn-dark m-1"
                      style={{minWidth: "95px", fontSize: "0.8rem"}}
                      onClick={() => {
                        localStorage.setItem("timeline.params.type", '');
                        localStorage.setItem("timeline.params.project_id", '');
                        localStorage.setItem("timeline.params.project_product_id", '');
                        localStorage.setItem("timeline.params.project_maker_request_id", '');
                        history.push(`/admin/project/formed/product/${project.id}`);
                      }}
                    >
                      제품 생성 관리
                    </button>
                    <Link to={`/admin/project/request/form/${project.id}`}>
                      <button
                        type="button"
                        className="w-48 btn btn-sm btn-dark m-1"
                        style={{minWidth: "95px", fontSize: "0.8rem"}}
                      >
                        제작 의뢰서 관리
                      </button>
                    </Link>
                    <Link to={`/admin/project/timeline/${project.id}`}>
                      <button
                        type="button"
                        className="w-48 btn btn-sm btn-ligray m-1"
                        style={{minWidth: "95px", fontSize: "0.8rem"}}
                      >
                        타임라인
                      </button>
                    </Link>
                  </dl>
                </article>
              )
            })}
            {projects?.length === 0 ?
              <article className="border d-flex mt-3">
                <ul className="w-100 p_rative p-3 mb-0 bg-lpurple border-right pt-5 pb-5">
                  <li className="txt-dark py-1 text-center">
                    <h5>{status === 'done' ? '완료된 ' : status === 'progress' ? '진행중인 ' : status === 'new' ? '신규 ' : ''}프로젝트가 없습니다.</h5>
                  </li>
                </ul>
              </article>
              :
              <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
            }
          </div>
          <Loading mounted={mounted} renew={new Date().getTime()}/>
        </div>
      </section>
    </AdminCard>
  );
};

export default AdminProjectHome;
