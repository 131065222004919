import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import open_in_new from "../../../image/open_in_new.svg";
import icon_export from "../../../image/icon_export.svg";
import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import moment from "moment";
import Constants from "../../../constants/constants";
import Paging from "../../common/Paging";

const AdminRequestOemList = (props) => {

  const [rets, setRets] = useState([]);
  const [selected, setSelected] = useState({});

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  const gets = async () => {
    const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("data/oem/proposal/gets", {paging: 1, currPage});
    if (pageInfo) setPageInfo(pageInfo);
    setRets(result);
    if (result && result.length > 0) {
      if (parseInt(selected?.id || 0, 10) > 0) props.click(result.filter(r => parseInt(r.id, 10) === parseInt(selected?.id, 10))?.[0]);
    } else {
      props.click({});
    }
    document.getElementById('scroll_div').scrollTop = 0;
  };

  useEffect(() => {
    const fetch = async () => {
      await gets();
    }
    fetch();
  }, [currPage, props.renew]);

  const openProject = (ret) => {
    window.event.stopPropagation();
    const win = window.open(`/admin/project/detail/${ret?.project_id}`, "_blank");
    win.focus();
  }

  const openUserDetail = (ret) => {
    window.event.stopPropagation();
    let win;
    if (ret.qn_user.user_type === 'BUYER') win = window.open(`/admin/buyer/list/${ret.qn_user?.id}`, "_blank");
    else win = window.open(`/admin/maker/list/${ret.qn_user?.id}`, "_blank");

    win.focus();
  }

  return (
    <section className="col-5" style={{height: "100%"}}>
      <div
        className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
        style={{height: "98%"}}
      >
        <div className="d-flex justify-content-between border-bottom">
          <p className="h5 bold px-2">OEM 제작제안 List</p>
        </div>

        <div
          className="wid_scroll scroll_y custom_scroll mt-2"
          id="scroll_div"
          style={{height: "calc(100% - 60px)"}}
        >
          <div className="wid_inline">
            {rets.map((ret, key) => {
              if (ret?.maker) {
                ret.maker.area = '';
                if (ret.maker.company_addr.length > 0)
                  ret.maker.area = ret.maker.company_addr.split(' ')[0];
              }

              return (
                <article key={key} className="border d-flex mt-3"
                         onClick={() => {
                           setSelected(ret);
                           props.click(ret)
                         }}
                >
                  <dl
                    className="p_rative p-3 mb-0 border-right"
                    style={{width: "33.3%"}}
                  >
                    <dt className="d-flex justify-content-between">
                      <p className="h6 bold">제조사</p>
                      <Link to={`/maker/home/${ret?.maker_user_id}`} target="_blank">
                        <button type="button" className="btn p-0">
                          <img src={open_in_new} style={{width: "25px"}}/>
                        </button>
                      </Link>
                    </dt>

                    <dd>
                      <div className="d-flex justify-content-around mt-3 mb-2">
                        {ret.maker &&
                          <>
                            <p className="btn_oval_lg mb-1">
                              {ret.maker.rep_image_fid && ret.maker.rep_image_fid.length > 0 ?
                                <img
                                  src={`${Constants.API_HOST}/common/download?fid=${ret.maker.rep_image_fid}&cd=inline&thumb=400`}
                                  style={{width: "65px", height: "65px", objectFit: "cover"}}
                                />
                                :
                                <div style={{width: "65px", height: "65px", backgroundColor: "#efefef"}}></div>
                              }
                            </p>
                            <div className="text-start">
                              <p className="h6">{ret?.maker?.company_name}</p>
                              <p>회사명 : {ret?.maker?.area}</p>
                              <p>거래횟수 : 0회</p>
                            </div>
                          </>
                        }
                      </div>
                    </dd>
                  </dl>
                  <dl
                    className="p_rative p-3 mb-0 border-right"
                    style={{width: "33.3%"}}
                  >
                    <dt className="d-flex justify-content-between">
                      <p className="h6 bold">바이어</p>
                      <Link to={`/buyer/home/${ret?.buyer_user_id}`} target="_blank">
                        <button type="button" className="btn p-0">
                          <img src={open_in_new} style={{width: "25px"}}/>
                        </button>
                      </Link>
                    </dt>

                    <dd>
                      <div className="d-flex justify-content-around mt-3 mb-2">
                        {ret.buyer &&
                          <>
                            <p className="btn_oval_lg mb-1">
                              {ret.buyer.rep_image_fid && ret.buyer.rep_image_fid.length > 0 ?
                                <img
                                  src={`${Constants.API_HOST}/common/download?fid=${ret.buyer.rep_image_fid}&cd=inline&thumb=400`}
                                  style={{width: "65px", height: "65px", objectFit: "cover"}}
                                />
                                :
                                <div style={{width: "65px", height: "65px", backgroundColor: "#efefef"}}></div>
                              }
                            </p>
                            <div className="text-start">
                              <p className="h6">{ret.buyer && (ret.buyer?.company?.length > 0 ? ret.buyer.company : (ret?.buyer?.first_name + " " + ret?.buyer?.middle_name + " " + ret?.buyer?.last_name))}</p>
                              <p>국적 : {ret.buyer.country?.name_kr}</p>
                              <p>거래횟수 : 0회</p>
                            </div>
                          </>
                        }
                      </div>
                    </dd>
                  </dl>
                  <ul className="w-14 p_rative p-3 mb-0 bg-lpurple border-right" style={{width: "33.4%"}}>
                    <li className="txt-dark py-1">
                      <strong>바이어 노출여부 :</strong> {ret.status_cd}
                    </li>
                    <li className="txt-dark py-1">
                      <strong>삭제여부 :</strong>{" "}
                      <span className="bold">{ret.delete_yn}</span>
                    </li>
                    <li className="txt-dark py-1">
                      {moment(ret.created_at).format("YYYY.MM.DD HH:mm")}
                    </li>
                  </ul>
                </article>)
            })}
            {rets?.length === 0 &&
              <div className="col w-100">
                <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-white">
                  등록된 제작 제안이 없습니다.
                </div>
              </div>
            }
            <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminRequestOemList;
