import React, {useEffect, useRef, useState} from "react";
import AdminMaker from "./AdminMaker";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import Constants from "../../../constants/constants";
import {useHistory} from "react-router-dom";
import CommonLib from "../../../libraries/CommonLib";
import AdminCard from "../AdminCard";

const AdminMakerProductAdd = (props) => {
  const history = useHistory();

  const [content, setContent] = useState('');
  const [imageFile, setImageFile] = useState('');
  const [image, setImage] = useState('');
  const imageFileRef = useRef();

  const [arrProducts, setArrProducts] = useState([]);
  const [arrTypes, setArrTypes] = useState([]);
  const [arrCountries, setArrCountries] = useState([]);

  const [makerProduct, setMakerProduct] = useState({});
  const [maker, setMaker] = useState({});

  const [pkId, setPkId] = useState(props.id || 0);
  const [productCd, setProductCd] = useState();
  const [typeCd, setTypeCd] = useState();
  const [countryCd, setCountryCd] = useState();
  const [name, setName] = useState('');
  const [moq, setMoq] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [volume, setVolume] = useState('');
  const [showYn, setShowYn] = useState('Y');

  let seq = 0;

  useEffect(() => {
    const fetch = async () => {
      const {data: {code, result: maker, message}} = await APIHelper.get(`user/maker/get?id=${props.userId}`);
      if (maker.maker_type === 'BX') {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '120000'});
        if (code === 200) {
          await setArrProducts(result);
          setProductCd(result[0].cd);
          setTypeCd('');
        }
      } else if (maker.maker_type === 'BT') {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '130000'});
        if (code === 200) {
          await setArrProducts(result);
          setProductCd(result[0].cd);
          setTypeCd('');
        }
      } else {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '110000'});
        if (code === 200) {
          await setArrProducts(result);
          if (result.length > 0) {
            setProductCd(result[0].cd);
            await getArrTypes(result[0].cd);
          }
        }
      }
      setMaker(maker);

      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '106000'});
        await setArrCountries(result);
        setCountryCd(result[0].cd);
      }
      await load(pkId);
    }
    fetch();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "제조사관리 > 제조사 제품관리 > 제품 추가",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const load = (pkId) => {
    return new Promise(async (resolve, reject) => {
      if (pkId > 0) {
        const {data: {code, result, message}} = await APIHelper.getForm(`user/maker/product/get`, {id: props.userId, pkId});
        if (code === 200) {
          setMakerProduct(result);

          setProductCd(result?.category_cd);

          if (maker.maker_type === 'BX' || maker.maker_type === 'BT')
            setTypeCd("");
          else {
            setTypeCd(result?.product_cd);
            await getArrTypes(result?.category_cd);
          }

          setCountryCd(result?.country_cd);
          setName(result?.name);
          setMoq(result?.moq);
          setUnitPrice(result?.unit_price);
          setVolume(result?.volume);
          setShowYn(result?.show_yn);
        }
      }
      return resolve(true);
    });
  }

  const getArrTypes = (parentCd) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd});
      if (code === 200) {
        setArrTypes(result);
        if (result.length > 0) {
          setTypeCd(result[0].cd);
        }
      }
      return resolve(true);
    });
  }

  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setImageFile(file);
    };
    reader.readAsDataURL(file);
  };

  const submit = () => {
    return new Promise(async (resolve, reject) => {
      let makerProductId = 0;
      try {
        {
          const {data: {code, result, message}} = await APIHelper.postForm(`user/maker/product/submit`, {
            id: props.userId,
            pkId,
            productCd,
            typeCd: (maker.maker_type === 'BX' || maker.maker_type === 'BT') ? '' : typeCd,
            countryCd,
            name,
            moq,
            unitPrice,
            volume,
            showYn,
          });
          if (code === 200) {
            setPkId(result?.id);
            makerProductId = result?.id;
            try {
              await APIHelper.postForm("admin/action/log", {
                content: pkId ? "제조사관리 > 제조사 제품관리 > 제품 추가 > 수정하기" : "제조사관리 > 제조사 제품관리 > 제품 추가 > 저장하기",
                param1 : props.userId,
                param2 : pkId,
                param3 : productCd,
                param4 : (maker.maker_type === 'BX' || maker.maker_type === 'BT') ? '' : typeCd,
                param5 : countryCd,
                param6 : moq,
                param7 : unitPrice,
                param8 : volume,
                param9 : showYn,
              });
            } catch (err) {
              console.log(err);
            }
          } else {
            await CommonLib.jalert(message);
          }
        }
        if (makerProductId > 0) {
          const {data: {code, result, message}} = await APIHelper.postForm(`user/maker/product/dtl/submit`, {
            id: props.userId,
            makerProductId,
            content,
            image: imageFileRef?.current?.files ? imageFileRef?.current?.files[0] : null,
          });
          await CommonLib.jalert(message);
          await load(makerProductId);

          setContent('');
          setImage('');

          if (code === 200) props.list();
        }
      } catch (err) {
      }
      return resolve(true);
    });
  }

  const remove = (makerProductId, makerProductDtlId) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.deleteForm(`user/maker/product/dtl/delete?makerProductId=${makerProductId}&makerProductDtlId=${makerProductDtlId}`);
      await load(makerProductId);
      return resolve(true);
    });
  }

  return (
    <div className="mt-3">
      <p className="txt-md mb-2">제품 등록</p>
      <div>
        <table className="table basic w-100">
          <colgroup>
            <col width="18%"/>
            <col width="*"/>
            <col width="18%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th className="center">제품 분류</th>
            <td colSpan={(maker.maker_type === 'MF' || maker.maker_type === 'DS') ? 1 : 3}>
              <select
                className="form-select form-select-sm w-100"
                aria-label="Default select example"
                style={{maxWidth: "220px"}}
                onChange={async (e) => {
                  await setProductCd(e.target.value);
                  if (maker.maker_type === 'MF' || maker.maker_type === 'DS') await getArrTypes(e.target.value);
                }}
              >
                {arrProducts && arrProducts.map((item, i) => <option key={i} selected={productCd === item.cd} value={item.cd}>{item.name_kr}</option>)}
              </select>
            </td>
            {(maker.maker_type === 'MF' || maker.maker_type === 'DS') &&
              <>
                <th className="center">제품 종류</th>
                <td>
                  <select
                    className="form-select form-select-sm w-100"
                    aria-label="Default select example"
                    style={{maxWidth: "220px"}}
                    onChange={async (e) => await setTypeCd(e.target.value)}
                  >
                    {arrTypes && arrTypes.map((item, i) => <option key={i} selected={typeCd === item.cd} value={item.cd}>{item.name_kr}</option>)}
                  </select>
                </td>
              </>
            }
          </tr>
          <tr>
            <th className="center">제목</th>
            <td colSpan="3">
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="최대 100자까지 가능합니다."
                aria-describedby="button-addon2"
                onChange={async (e) => await setName(e.target.value)}
                value={name}
              />
            </td>
          </tr>
          <tr>
            <th className="center">판매 국가</th>
            <td>
              <select
                className="form-select form-select-sm w-100"
                aria-label="Default select example"
                onChange={async (e) => await setCountryCd(e.target.value)}
              >
                {arrCountries && arrCountries.map((item, i) => <option key={i} selected={countryCd === item.cd} value={item.cd}>{item.name_kr}</option>)}
              </select>
            </td>
            <th className="center">MOQ</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm w-100"
                aria-label="Default select example"
                onChange={async (e) => await setMoq(e.target.value)}
                value={moq}
              />
            </td>
          </tr>
          <tr>
            <th className="center">Unit Price</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm w-100"
                aria-label="Default select example"
                onChange={async (e) => await setUnitPrice(e.target.value)}
                value={unitPrice}
              />
            </td>
            <th className="center">Volume</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm w-100"
                aria-label="Default select example"
                onChange={async (e) => await setVolume(e.target.value)}
                value={volume}
              />
            </td>
          </tr>
          <tr>
            <th className="center">노출 여부</th>
            <td colSpan="3">
              <select
                className="form-select form-select-sm w-25"
                aria-label="Default select example"
                onChange={async (e) => await setShowYn(e.target.value)}
              >
                <option selected={showYn === true} value="Y">노출</option>
                <option selected={showYn === false} value="N">노출안함</option>
              </select>
            </td>
          </tr>
        </table>
      </div>
      {/*<div className="d-flex justify-content-between">*/}
      {/*  <p></p>*/}
      {/*  <p className="text-center">*/}
      {/*    <button type="button" className="btn btn_puple px-3 mb-4">*/}
      {/*      상세 내용 추가*/}
      {/*    </button>*/}
      {/*  </p>*/}
      {/*</div>*/}

      {makerProduct?.dtls && makerProduct?.dtls?.length > 0 && makerProduct?.dtls.map((item, key) =>
        <div key={key} className="mb-3">
          {((item.content && item.content.length > 0) || (item.image_fid && item.image_fid.length > 0)) &&
            <div className="d-flex justify-content-between mt-5 border-bottom">
              <p className="h5 bold txt-point pb-2">No. {++seq}</p>
              <p className="text-right">
                <button type="button" className="btn btn_puple px-3 mb-2" onClick={() => remove(makerProduct?.id, item?.id)}>
                  삭제
                </button>
              </p>
            </div>
          }
          {item.content && item.content.length > 0 &&
            <div className="mt-3" dangerouslySetInnerHTML={{__html: item.content}}/>
          }
          {item.image_fid && item.image_fid.length > 0 &&
            <div className="row row-cols-3 mt-3">
              <img
                src={`${Constants.API_HOST}/common/download?fid=${item.image_fid}&cd=inline&thumb=1000`}
                style={{maxWidth: "100%"}}
              />
            </div>
          }
        </div>)
      }

      <br/>
      <p className="txt-md mb-2">
        제품 상세 내용{" "}
        <span className="txt-gray normal h7">
          {" "}
          ※ 둘중에 하나만 입력하셔도 됩니다. (내용만 등록 또는 이미지만 등록
          가능)
        </span>
      </p>
      <div>
        <table className="table basic w-100">
          <colgroup>
            <col width="22%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th className="center">내용</th>
            <td>
              <CKEditor editor={ClassicEditor} data=""
                        onReady={(editor) => {
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "250px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setContent(data);
                        }}
              />
            </td>
          </tr>
          <tr>
            <th className="center">이미지 등록</th>
            <td>
              <div className="input-group input-group-sm">
                <input
                  type="file"
                  className="form-control"
                  onChange={e => {
                    handleImageChange(e);
                    setImage(e.target.value);
                  }}
                  ref={imageFileRef}
                  value={image}
                />
              </div>
            </td>
          </tr>
        </table>
      </div>

      <p className="text-center">
        <button type="button" className="btn btn_puple px-5 my-3" onClick={submit} style={{marginRight: "10px"}}>
          저장하기
        </button>
        <button type="button" className="btn btn_puplelight px-5 my-3" onClick={() => props.list()}>
          돌아가기
        </button>
      </p>
    </div>
  );
};

export default AdminMakerProductAdd;
