import React, {useEffect, useState} from "react";
import "../../../sass/main.scss";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";
import Constants from "../../../constants/constants";

const ProductsComponent = (props) => {
  const projectId = props?.projectId;
  const productId = props?.productId;
  const [project, setProject] = useState({});
  const [showInfos, setShowInfos] = useState([]);

  const [certs, setCerts] = useState([]);
  const [targets, setTargets] = useState([]);
  const [countries, setCounties] = useState([]);
  const [functionals, setFunctionals] = useState([]);

  const ITEM = {MF: "내용물", BT: "용기", DS: "디자인", BX: "단상자"};

  useEffect(() => {
    const fetch = async () => {
      await getCerts();
      await getTargets();
      await getCountries();
      await getFunctionals();
      await getProject(projectId);
    }
    fetch();
  }, []);

  const getCerts = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '103000'});
      if (code === 200) {
        setCerts(result);
      }
      return resolve(true);
    });
  }

  const getTargets = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '108000'});
      if (code === 200) {
        setTargets(result);
      }
      return resolve(true);
    });
  }

  const getCountries = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '106000'});
      if (code === 200) {
        setCounties(result);
      }
      return resolve(true);
    });
  }

  const getFunctionals = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '107000'});
      if (code === 200) {
        setFunctionals(result);
      }
      return resolve(true);
    });
  }

  const getProject = (id) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("project/get", {id, productId});
      if (code === 200) {
        setProject(result);
        if (result?.products?.length > 0) {
          setSelectProduct(result?.products[0]);
          props.click(result?.products[0]);
        }
      }
      return resolve(true);
    });
  }

  const showDetail = (product) => {
    const data = [...showInfos];
    if (!data.includes(product.id))
      data.push(product.id);
    else
      data.splice(data.indexOf(product.id), 1);
    setShowInfos(data);
  }

  // 제품리스트 클릭 state
  const [selectProduct, setSelectProduct] = useState();

  return (
    <>
      {project.products && project.products.map((product, key) => {
        return (
          <article
            key={key}
            className="m-2 my-3"
            onClick={() => {
              setSelectProduct(product);
              props.click(product);
            }}
          >
            <div
              className={`border${
                selectProduct &&
                selectProduct.id === product.id
                  ? "_purple"
                  : ""
              } bg-white p-3 w-100 p_rative`}
            >
              {/*<p className="p_absolute_tr3" style={{top: "5px"}}>*/}
              {/*  <button type="button" className="btn btn-sm txt-sm p-0 px-2">*/}
              {/*    수정*/}
              {/*  </button>*/}
              {/*</p>*/}
              <div className="d-flex border-bottom pb-2">
                <p className="p-2">
                  {product?.product?.image_uri && product?.product?.image_uri.length > 0 ?
                    <img
                      src={process.env.PUBLIC_URL + product?.product?.image_uri.substring(0, product?.product?.image_uri.indexOf(".")) + '1.' + product?.product?.image_uri.substring(product?.product?.image_uri.indexOf(".") + 1)}
                      style={{width: `40px`, maxHeight: `40px`, marginBottom: "5px", borderRadius: "20px"}}
                    />
                    :
                    <div style={{width: "40px", height: "40px", marginBottom: "5px", borderRadius: "20px", backgroundColor: "#efefef"}}></div>
                  }
                </p>
                <div className="mt-1 m-2">
                  <p className="mx-1 txt-point bold mb-2">
                    {product?.category?.name_kr} | {product?.product?.name_kr}
                  </p>
                  <div className="d-flex">
                    {product?.items && product?.items.map((item, i) => (
                      <p className="border box_round_4_paddingNo bg-lgray txt-sm px-2 mx-1">
                        {ITEM[item.item_cd]}
                      </p>
                    ))}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between pt-2">
                <ul className="p-0 w-100 d-flex flex-wrap mb-2">
                  <li className="px-3 txt-sm txt-gray">
                    <span className="bold">인증서 :</span> {product?.cert?.name_kr || '없음'}
                  </li>
                  <li className="px-3 txt-sm txt-gray">
                    <span className="bold">판매희망국가 :</span> {product?.country?.name_kr || '없음'}
                  </li>
                  <li className="px-3 txt-sm txt-gray">
                    <span className="bold">주요기능 :</span> {product?.functional || '없음'}
                  </li>
                  <li className="px-3 txt-sm txt-gray">
                    <span className="bold">예상단가 :</span> {CommonLib.numberWithCommas(product?.unit_price)}{product?.unit || '원'}
                  </li>
                </ul>
              </div>
              <p style={{textAlign: "right"}}>
                <button
                  type="button"
                  className="bg-lpurple2 btn btn-ssm btn-outline-light py-0"
                  onClick={() => showDetail(product)}
                >
                  상세 정보 확인 +
                </button>
                {" "}
              </p>
            </div>

            {showInfos.includes(product.id) &&
              <div
                className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
                style={{height: "calc(98% - 385px)"}}
              >
                <p className="h5 bold p-2 border-bottom">
                  <span className="txt-point h5 bold">상세정보</span>
                </p>

                <div
                  className="wid_scroll scroll_y custom_scroll "
                  style={{height: "calc(100% - 60px)"}}
                >
                  <table className="table basic_sm wid_inline">
                    <tr>
                      <td style={{borderTop: "none"}}>
                        <p className="mb-2 px-1">인증서 기준</p>
                        <select
                          className="form-select form-select-sm"
                          aria-label="Default select example"
                        >
                          <option>없음</option>
                          {certs && certs.map((item, key) => (
                            <option selected={item.cd === product?.cert_cd}>{item.name_kr}</option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="mb-2 px-1">원하는 타깃층</p>
                        <div className="d-flex flex-wrap">
                          <a href="#">
                            <p className={`${targets.filter((e) => e.cd !== product?.target_cd).length === 0 ? "bg-purple" : "border"} box_round_5 w_f_95 m-1`}>
                              상관없음
                            </p>
                          </a>
                          {targets && targets.map((item, key) => (
                            <p className={`${item.cd === product?.target_cd ? "bg-purple" : "border"} box_round_5 w_f_95 m-1`}>{item.name_kr}</p>
                          ))}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="mb-2 px-1">판매 희망 국가</p>
                        <select
                          className="form-select form-select-sm w-70"
                          aria-label="Default select example"
                        >
                          <option selected>대한민국</option>
                          {countries && countries.map((item, key) => (
                            <option selected={item.cd === product?.country_cd}>{item.name_kr}</option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="mb-2 px-1">예상 단가</p>
                        <div className="input-group input-group-sm w-85">
                          <input
                            type="text"
                            className="form-control w-75"
                            placeholder=""
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            value={product?.unit_price}
                          />
                          <select
                            className="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>원</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="mb-2 px-1">희망 수량</p>
                        <div className="input-group input-group-sm">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder=""
                            value={product?.wish_volume}
                          />
                          <span
                            className="input-group-text notranslate no-transform"
                            id="inputGroup-sizing-sm"
                            style={{width: "50px"}}
                          >
                            {product?.wish_volume_unit}
                          </span>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder=""
                            value={product?.wish_quantity}
                          />
                          <span
                            className="input-group-text"
                            id="inputGroup-sizing-sm"
                          >
                            개
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="mb-2 px-1">주요 기능</p>
                        <div className="d-flex flex-wrap">
                          <a href="#">
                            <p className={`${product?.functional?.includes("상관없음") ? "bg-purple" : "border"} box_round_5 w_f_95 m-1`}>
                              상관없음
                            </p>
                          </a>
                          {functionals && functionals.map((item, key) => (
                            <p className={`${product?.functional?.includes(item.name_kr) ? "bg-purple" : "border"} box_round_5 w_f_95 m-1`}>{item.name_kr}</p>
                          ))}
                        </div>
                      </td>
                    </tr>
                    {/*<tr>*/}
                    {/*  <td>*/}
                    {/*    <p className="mb-2 px-1">주요 기능</p>*/}
                    {/*    <div className="d-flex flex-wrap">*/}
                    {/*      <a href="#">*/}
                    {/*        <p className={`${functionals.filter((e) => e.cd !== product?.functional_cd).length === 0 ? "bg-purple" : "border"} box_round_5 w_f_95 m-1`}>*/}
                    {/*          상관없음*/}
                    {/*        </p>*/}
                    {/*      </a>*/}
                    {/*      {functionals && functionals.map((item, key) => (*/}
                    {/*        <p className={`${item.cd === product?.functional_cd ? "bg-purple" : "border"} box_round_5 w_f_95 m-1`}>{item.name_kr}</p>*/}
                    {/*      ))}*/}
                    {/*    </div>*/}
                    {/*  </td>*/}
                    {/*</tr>*/}
                    <tr style={{display: "none"}}>
                      <td>
                        <p className="mb-2 px-1">주요 성분</p>
                        <select
                          className="form-select form-select-sm w-85"
                          aria-label="Default select example"
                        >
                          <option selected>알로에베라</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="mb-2 px-1">첨부 파일</p>
                        {product?.fid_inf_1?.id > 0 && <div><a href={`${Constants.API_HOST}/common/download?fid=${product?.fid_inf_1?.fid}`}>- {product?.fid_inf_1?.filename}</a></div>}
                        {product?.fid_inf_2?.id > 0 && <div><a href={`${Constants.API_HOST}/common/download?fid=${product?.fid_inf_2?.fid}`}>- {product?.fid_inf_2?.filename}</a></div>}
                        {product?.fid_inf_3?.id > 0 && <div><a href={`${Constants.API_HOST}/common/download?fid=${product?.fid_inf_3?.fid}`}>- {product?.fid_inf_3?.filename}</a></div>}
                        {product?.fid_inf_4?.id > 0 && <div><a href={`${Constants.API_HOST}/common/download?fid=${product?.fid_inf_4?.fid}`}>- {product?.fid_inf_4?.filename}</a></div>}
                        {product?.fid_inf_5?.id > 0 && <div><a href={`${Constants.API_HOST}/common/download?fid=${product?.fid_inf_5?.fid}`}>- {product?.fid_inf_5?.filename}</a></div>}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            }
          </article>
        )
      })}
    </>
  );
};

export default ProductsComponent;
