import React from "react";
import BuyerProjectSide from "./BuyerProjectSide";
import "../../../sass/main.scss";
import { useHistory } from 'react-router-dom';

const BuyerProjectRequestForm = (props) => {
  const dv = props?.location?.state?.dv || props?.match?.params?.dv;
  const id = props?.location?.state?.id || props?.match?.params?.id;

  const history = useHistory();

  return (
    <BuyerProjectSide dv={dv} id={id}>
      <div className=" border-bottom py-4">
        <p className="h4 bold txt-point">상세정보 등록 및 수정</p>
        <p className="h6">
          세부정보는 선택한 제품별로 입력하거나, 한꺼번에 입력하실 수 있습니다.
        </p>
      </div>
      <div className="my-3">
        <select className="form-select w-50">
          <option selected>선택한 상품 전체</option>
          <option value="1">스킨케어 | 로션</option>
          <option value="2">핸드케어 | 핸드크림</option>
          <option value="3">이스 메이크업 | 하이라이터</option>
        </select>
      </div>
      <table className="table basic w-100">
        <colgroup>
          <col width="22%" />
          <col width="*" />
        </colgroup>
        <tr>
          <th className="center">인증서 기준</th>
          <td>
            <select className="form-select form-select-sm w-50">
              <option selected>인증서기준 없음</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </td>
        </tr>
        <tr>
          <th className="center">원하는 타겟층 </th>
          <td>
            <div className="d-flex">
              <a
                href="#"
                className="bg-purple box_round_4_paddingNo py-1 px-3 m-1 txt-sm"
              >
                상관없음
              </a>
              <a
                href="#"
                className="border box_round_4_paddingNo py-1 px-3 m-1 txt-sm"
              >
                여성
              </a>
              <a
                href="#"
                className="border box_round_4_paddingNo py-1 px-3 m-1 txt-sm"
              >
                남성
              </a>
              <a
                href="#"
                className="border box_round_4_paddingNo py-1 px-3 m-1 txt-sm"
              >
                청소년
              </a>
              <a
                href="#"
                className="border box_round_4_paddingNo py-1 px-3 m-1 txt-sm"
              >
                유아
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <th className="center">판매 희망 국가</th>
          <td>
            <select className="form-select form-select-sm w-50">
              <option selected>대한민국</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </td>
        </tr>
        <tr>
          <th className="center">예상 단가</th>
          <td>
            <div className="input-group w-65">
              <input
                type="text"
                className="form-control form-control-sm w-50"
              />
              <select
                className="form-select form-select-sm"
                style={{ width: "70px" }}
              >
                <option value="원">원</option>
                <option value="USD">USD</option>
              </select>
            </div>
          </td>
        </tr>
        <tr>
          <th className="center">희망 수량</th>
          <td>
            <div className="input-group w-65">
              <input
                type="text"
                className="form-control form-control-sm w-50"
                placeholder="ml"
              />
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="갯수"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th className="center">주요 기능</th>
          <td>
            <div className="d-flex flex-wrap">
              <a
                href="#"
                className="bg-purple box_round_4_paddingNo py-1 px-3 m-1 txt-sm"
              >
                상관없음
              </a>
              <a
                href="#"
                className="border box_round_4_paddingNo py-1 px-3 m-1 txt-sm"
              >
                미백
              </a>
              <a
                href="#"
                className="border box_round_4_paddingNo py-1 px-3 m-1 txt-sm"
              >
                보습
              </a>
              <a
                href="#"
                className="border box_round_4_paddingNo py-1 px-3 m-1 txt-sm"
              >
                안티에이징
              </a>
              <a
                href="#"
                className="border box_round_4_paddingNo py-1 px-3 m-1 txt-sm"
              >
                자외선차단
              </a>
              <a
                href="#"
                className="border box_round_4_paddingNo py-1 px-3 m-1 txt-sm"
              >
                모공케어
              </a>
              <a
                href="#"
                className="border box_round_4_paddingNo py-1 px-3 m-1 txt-sm"
              >
                트러블케어
              </a>
              <a
                href="#"
                className="border box_round_4_paddingNo py-1 px-3 m-1 txt-sm"
              >
                민감케어
              </a>
            </div>
          </td>
        </tr>
        <tr style={{display: "none"}}>
          <th className="center">주요 성분</th>
          <td>
            <select className="form-select form-select-sm">
              <option selected>알로에베라</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </td>
        </tr>
        <tr>
          <th className="center">기타 문의 내용</th>
          <td>
            <textarea
              className="form-control mt-2"
              id="exampleFormControlTextarea1"
              rows="3"
            ></textarea>
          </td>
        </tr>
        <tr>
          <th className="center">파일첨부</th>
          <td>
            <div className="input-group input-group-sm ">
              <input
                type="file"
                className="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
              />
              <button
                className="btn btn_outline_puple"
                type="button"
                id="inputGroupFileAddon04"
              >
                파일첨부
              </button>
            </div>

            <textarea
              className="form-control mt-2"
              id="exampleFormControlTextarea1"
              rows="3"
            ></textarea>
          </td>
        </tr>
        <tr>
          <th className="center">참고할 URL입력</th>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="http://"
            />
            <input
              type="text"
              className="form-control form-control-sm my-1"
              placeholder="http://"
            />
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="http://"
            />
          </td>
        </tr>
      </table>
      <div className="d-flex justify-content-between">
        <p>
          <button className="btn btn_outline_puple" type="button" onClick={() => history.goBack()}>
            이전 페이지
          </button>
        </p>
        <p>
          <button
            className="btn btn_puple"
            type="button"
            style={{ marginRight: "5px" }}
          >
            상세정보 등록 완료
          </button>
          <button className="btn btn-outline-light" type="button">
            취소
          </button>
        </p>
      </div>
    </BuyerProjectSide>
  );
};

export default BuyerProjectRequestForm;
