import React, {useEffect, useRef, useState} from "react";
import DaumPostCode from "react-daum-postcode";
import l_comp_img01 from "../../../image/l_comp_img01.png";
import ProfileMaker from "./ProfileMaker";
import "../../../sass/main.scss";
import Constants from "../../../constants/constants";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";

const ProfileMakerHome = (props) => {
  const licenseFileRef = useRef();

  const [companyImageFile, setCompanyImageFile] = useState(null);
  const [companyImagePreviewUrl, setCompanyImagePreviewUrl] = useState(null);
  const companyImageFileRef = useRef();

  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [name, setName] = useState('');
  const [countryCd, setCountryCd] = useState('');
  const [makerType, setMakerType] = useState('MF');
  const [marketCountry, setMarketCountry] = useState('');
  const [introduce, setIntroduce] = useState('');
  const [summary, setSummary] = useState('');
  const [repImageFid, setRepImageFid] = useState(null);
  const [licenseAttach, setLicenseAttach] = useState(null);
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyTel1, setCompanyTel1] = useState('');
  const [companyTel2, setCompanyTel2] = useState('');
  const [companyTel3, setCompanyTel3] = useState('');

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '106000'});
        if (code === 200) setCountries(result);
      }
      await getMakerProfile();
    }
    fetch();
  }, []);

  // 제조사 프로필 가져오기
  const getMakerProfile = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.get("user/maker/get");
      if (code === 200) {
        setEmail(result.user?.email);
        setCompany(result.company_name);
        setName(result.name);
        setMarketCountry(result.market_country);
        setIntroduce(result.introduce);
        setSummary(result.summary);
        setRepImageFid(result.rep_image_fid);
        setCountryCd(result.country_cd);
        setMakerType(result.maker_type);
        setCompanyTel1(result.company_tel ? result.company_tel.split('-')[0] : '');
        setCompanyTel2(result.company_tel ? result.company_tel.split('-')[1] : '');
        setCompanyTel3(result.company_tel ? result.company_tel.split('-')[2] : '');
        setCompanyEmail(result.company_email);
        setCompanyWebsite(result.company_website);
        setAddress({postcode: result.company_zip, address: result.company_addr});
        setDetailAddress(result.company_addr_dtl);
        setLicenseAttach(result.license_attach);
      } else {
        await CommonLib.jalert(message);
      }
      return resolve(true);
    });
  };

  // 주소검색 열고 닫기
  const [addressOpen, setAddressOpen] = useState(false);
  // 주소 state
  const [address, setAddress] = useState({
    postcode: "",
    address: "",
  });
  // 상세주소 state
  const [detailAddress, setDetailAddress] = useState("");

  // 주소검색 클릭 핸들러
  const addressClick = (e) => {
    setAddressOpen(!addressOpen);
  };

  // 주소찾기
  const postcodeHandler = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? `(${extraAddress})` : "";
    }

    setAddress({ postcode: data.zonecode, address: data.address });
    setAddressOpen(false);
  };

  // 상세주소 핸들러
  const detailAddressHandler = (e) => {
    setDetailAddress(e.target.value);
  };

  const handleCompanyImageChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setCompanyImageFile(file);
      setCompanyImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const updateSubmit = (e) => {
    return new Promise(async (resolve, reject) => {
      const companyImageFiles = companyImageFileRef?.current?.files;
      const licenseFiles = licenseFileRef?.current?.files;

      try {
        const {
          data: {code: retCode, result, message},
        } = await APIHelper.putForm(`user/maker/update/basic`, {
          company,
          name,
          countryCd,
          makerType,
          marketCountry,
          introduce,
          summary,
          companyImage: companyImageFiles ? companyImageFiles[0] : null,
          license: licenseFiles ? licenseFiles[0] : null,
          companyTel1: companyTel1 || '',
          companyTel2: companyTel2 || '',
          companyTel3: companyTel3 || '',
          companyEmail: companyEmail || '',
          companyWebsite: companyWebsite || '',
          companyZip: address.postcode || '',
          companyAddr: address.address || '',
          companyAddrDtl: detailAddress || '',
        });
        await CommonLib.jalert(message);
      } catch (err) {
        console.error(err);
      }
      return resolve(true);
    });
  };

  return (
    <ProfileMaker>
      <div className="row">
        <p className="col col-4" style={{marginLeft: "13px", width: "220px", height: "344px", backgroundColor: "#efefef", padding: 0, margin: 0, cursor: "pointer"}} onClick={() => companyImageFileRef.current.click()}>
          {(repImageFid || companyImagePreviewUrl) &&
            <img
              src={repImageFid.length > 0 ? `${Constants.API_HOST}/common/download?fid=${repImageFid}&cd=inline&thumb=400` : companyImagePreviewUrl}
              style={{
                width: "220px",
                height: "344px",
                objectFit: "cover",
              }}
            />
          }
          <input
            type="file"
            ref={companyImageFileRef}
            style={{display: "none"}}
            onChange={handleCompanyImageChange}
          />
        </p>
        <div className="col col-8">
          <table className="table basic w-100">
            <colgroup>
              <col width="22%" />
              <col width="*" />
              <col width="22%" />
              <col width="*" />
            </colgroup>
            <tr>
              <th className="center">이메일</th>
              <td colSpan="3">
                <input
                  type="text"
                  className="form-control w-85"
                  placeholder="이메일"
                  aria-describedby="button-addon2"
                  value={email}
                  readOnly={true}
                />
              </td>
            </tr>
            <tr>
              <th className="center">이름</th>
              <td colSpan="3">
                <input
                  type="text"
                  className="form-control form-control-sm w-85"
                  placeholder="이름 입력"
                  aria-describedby="button-addon2"
                  onChange={async (e) => await setName(e.target.value)}
                  value={name}
                />
              </td>
            </tr>
            <tr>
              <th className="center">회사명</th>
              <td colSpan="3">
                <input
                  type="text"
                  className="form-control form-control-sm w-85"
                  placeholder="회사명 입력"
                  aria-describedby="button-addon2"
                  onChange={async (e) => await setCompany(e.target.value)}
                  value={company}
                />
              </td>
            </tr>
            <tr>
              <th className="center">국적</th>
              <td>
                <select
                  className="form-select form-select-sm w-50"
                  aria-label="Default select example"
                  onChange={async (e) => await setCountryCd(e.target.value)}
                  value={countryCd}
                >
                  {countries && countries.map((item, i) => {
                    return (
                      <option key={i} selected={countryCd === item.cd} value={item.cd}>{item.name_kr}</option>
                    );
                  })}
                </select>
              </td>
              <th className="center">유형</th>
              <td>
                <select
                  className="form-select form-select-sm w-50"
                  aria-label="Default select example"
                  onChange={async (e) => await setMakerType(e.target.value)}
                  value={makerType}
                >
                  <option key={0} selected={makerType === "MF"} value="MF">제형 제조사</option>
                  <option key={1} selected={makerType === "DS"} value="DS">디자인 제조사</option>
                  <option key={2} selected={makerType === "BT"} value="BT">용기 제조사</option>
                  <option key={3} selected={makerType === "BX"} value="BX">단상자 제조사</option>
                </select>
              </td>
            </tr>
            <tr>
              <th className="center">타겟 국가</th>
              <td colSpan="3">
                <input
                  type="text"
                  className="form-control form-control-sm w-85"
                  placeholder="타겟국가 입력"
                  aria-describedby="button-addon2"
                  onChange={async (e) => await setMarketCountry(e.target.value)}
                  value={marketCountry}
                />
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div className="d-flex justify-content-between border-bottom pb-2 mt-4">
        <p className="h5">기본정보</p>
      </div>

      <table className="table basic w-100">
        <colgroup>
          <col width="20%" />
          <col width="*" />
        </colgroup>
        <tr>
          <th className="center">회사 이메일</th>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="회사 이메일"
              aria-describedby="button-addon2"
              onChange={async (e) => await setCompanyEmail(e.target.value)}
              value={companyEmail}
            />
          </td>
        </tr>
        <tr>
          <th className="center">Homepage</th>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Homepage"
              aria-describedby="button-addon2"
              onChange={async (e) => await setCompanyWebsite(e.target.value)}
              value={companyWebsite}
            />
          </td>
        </tr>
        <tr>
          <th className="center">회사주소</th>
          <td>
            <div className="input-group input-group-sm w-50 mb-2">
              <input
                value={address.postcode}
                type="text"
                className="form-control "
                placeholder="우편번호"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
              />
              <button
                onClick={addressClick}
                className="btn btn_outline_puple"
                type="button"
                id="button-addon2"
              >
                주소 검색
              </button>
            </div>
            <div className="input-group input-group-sm">
              {addressOpen && (
                <DaumPostCode autoClose onComplete={postcodeHandler} />
              )}
              <input
                value={address.address}
                type="text"
                className="form-control "
                placeholder="주소"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
              />
              <input
                onChange={detailAddressHandler}
                value={detailAddress}
                type="text"
                className="form-control "
                placeholder="상세주소"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th className="center">회사전화</th>
          <td>
            <div className="input-group w-65">
              <input type="text" className="form-control form-control-sm" placeholder="02"
                     onChange={async (e) => await setCompanyTel1(e.target.value)}
                     value={companyTel1}
              />
              <input type="text" className="form-control form-control-sm"
                     onChange={async (e) => await setCompanyTel2(e.target.value)}
                     value={companyTel2}
              />
              <input type="text" className="form-control form-control-sm"
                     onChange={async (e) => await setCompanyTel3(e.target.value)}
                     value={companyTel3}
              />
            </div>
          </td>
        </tr>
        <tr>
          <th className="center">사업자 등록증</th>
          <td>
            {licenseAttach && <div>{licenseAttach?.filename}</div>}
            <div className="input-group input-group-sm ">
              <input
                type="file"
                className="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
                ref={licenseFileRef}
              />
            </div>
          </td>
        </tr>
        <tr>
          <th className="center">한줄 소개</th>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="한줄 소개 입력"
              aria-describedby="button-addon2"
              onChange={async (e) => await setIntroduce(e.target.value)}
              value={introduce}
            />
          </td>
        </tr>
        <tr>
          <th className="center">간단소개</th>
          <td>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="9"
              onChange={async (e) => await setSummary(e.target.value)}
              value={summary}
            />
          </td>
        </tr>
      </table>

      <p className="text-center">
        <button type="button" className="btn btn_puple px-5 my-3" onClick={async (e) => await updateSubmit(e)}>
          저장하기
        </button>
      </p>
    </ProfileMaker>
  );
};

export default ProfileMakerHome;
