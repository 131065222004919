import React, {useEffect, useState} from "react";
import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";

const RequestDesignComponent = (props) => {
  const projectId = props.projectId;
  const projectProductId = props.projectProductId;
  const requestId = props.requestId;
  const requestType = "DS";
  const getProject = props.getProject;
  const requestList = props.requestList;

  useEffect(() => {
    const fetch = async () => {
      await setFormState();
    }
    fetch();
  }, [requestId]);

  const setFormState = () => {
    return new Promise(async (resolve, reject) => {
      if (projectId && projectProductId && requestType) {
        const {data: {code, result, message}} = await APIHelper.getForm("project/request/form/get", {projectId, projectProductId, requestId, requestType});

        if (code === 200) {
          setRequestNo(result?.request_no);
          setRequestName(result?.request_name);
          setDsDesignBottleYn(result?.ds_design_bottle_yn);
          setDsDesignBoxYn(result?.ds_design_box_yn);
          setDsDesignBrandYn(result?.ds_design_brand_yn);
          setDsConcept(result?.ds_concept);
          setUrl1(result?.url1);
          setUrl2(result?.url2);
          setUrl3(result?.url3);
        }
      }

      return resolve(true);
    });
  };

  const submitForm = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.postForm("project/request/form/submit", {
        projectId,
        projectProductId,
        requestId,
        requestType,
        requestName,
        dsDesignBottleYn,
        dsDesignBoxYn,
        dsDesignBrandYn,
        dsConcept,
        url1,
        url2,
        url3,
      });
      if (code === 200) {
        await setFormState();
        {getProject && await getProject(projectId)};  //부모창 함수 호출 방법 GD
        await CommonLib.jalert('저장이 완료되었습니다.');
      } else {
        await CommonLib.jalert('저장이 실패하였습니다.');
      }
      return resolve(true);
    });
  }

  const selectForm = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.postForm("project/request/select", {
        requestId: requestId,
        requestSelect: '1'
      });
      if (code === 200) {
        await CommonLib.jalert('선택이 완료되었습니다.');
        //await selectReset();
      } else {
        await CommonLib.jalert('선택이 실패하였습니다.');
      }
      return resolve(true);
    });
  }




  const [requestNo, setRequestNo] = useState('');
  const [requestName, setRequestName] = useState('');
  const [dsDesignBottleYn, setDsDesignBottleYn] = useState('N');
  const [dsDesignBoxYn, setDsDesignBoxYn] = useState('N');
  const [dsDesignBrandYn, setDsDesignBrandYn] = useState('N');
  const [dsConcept, setDsConcept] = useState('');
  const [url1, setUrl1] = useState('');
  const [url2, setUrl2] = useState('');
  const [url3, setUrl3] = useState('');

  return (
    <>
      <table className="table basic_sm w-100">
        <colgroup>
          <col width="28%"/>
          <col width="*"/>
        </colgroup>
        <tr>
          <th className="center txt-sm">의뢰서 제목 및 의뢰번호</th>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="의뢰서 제목을 입력해 주세요"
              onChange={async (e) => await setRequestName(e.target.value)}
              value={requestName}
            />
            {requestNo && requestNo.length > 0 &&
              <p>제작 의뢰서 번호 : {requestNo}</p>
            }
          </td>
        </tr>
        <tr>
          <th className="center txt-sm">의뢰 항목</th>
          <td>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input mt-1"
                type="checkbox"
                id="inlineCheckbox1"
                value="Y"
                onChange={async (e) => await setDsDesignBottleYn(e.target.checked ? 'Y' : 'N')}
                checked={dsDesignBottleYn === 'Y'}
              />
              <label className="form-check-label " htmlFor="inlineCheckbox1">
                용기 디자인{" "}
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input mt-1"
                type="checkbox"
                id="inlineCheckbox2"
                value="Y"
                onChange={async (e) => await setDsDesignBoxYn(e.target.checked ? 'Y' : 'N')}
                checked={dsDesignBoxYn === 'Y'}
              />
              <label className="form-check-label" htmlFor="inlineCheckbox2">
                단상자 디자인
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input mt-1"
                type="checkbox"
                id="inlineCheckbox3"
                value="Y"
                onChange={async (e) => await setDsDesignBrandYn(e.target.checked ? 'Y' : 'N')}
                checked={dsDesignBrandYn === 'Y'}
              />
              <label className="form-check-label" htmlFor="inlineCheckbox3">
                로고/브랜드 디자인
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <th className="center txt-sm">디자인 고려 사항</th>
          <td>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="4"
                  onChange={async (e) => await setDsConcept(e.target.value)}
                  value={dsConcept}
                />
          </td>
        </tr>
        <tr>
          <th className="center txt-sm">참고 이미지</th>
          <td>
            <div className="input-group input-group-sm">
              <input
                type="file"
                className="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th className="center txt-sm">참고 URL</th>
          <td>
            <div className="input-group input-group-sm mb-1">
              <input
                type="text"
                className="form-control"
                placeholder="http://www.hi.com/asdf"
                aria-label="http://www.hi.com/asdf"
                aria-describedby="button-addon2"
                onChange={async (e) => await setUrl1(e.target.value)}
                value={url1}
              />
            </div>
            <div className="input-group input-group-sm mb-1">
              <input
                type="text"
                className="form-control"
                placeholder="http://www.hi.com/asdf"
                aria-label="http://www.hi.com/asdf"
                aria-describedby="button-addon2"
                onChange={async (e) => await setUrl2(e.target.value)}
                value={url2}
              />
            </div>
            <div className="input-group input-group-sm">
              <input
                type="text"
                className="form-control"
                placeholder="http://www.hi.com/asdf"
                aria-label="http://www.hi.com/asdf"
                aria-describedby="button-addon2"
                onChange={async (e) => await setUrl3(e.target.value)}
                value={url3}
              />
            </div>
          </td>
        </tr>
      </table>
      {!props.readOnly &&
        <div style={{
          height: "85px",
          textAlign: "center",
          marginTop: "14px",
          padding: "1.5em",
          backgroundColor: "#efefef",
        }}>
          <button className="btn btn_puple mb-2" type="button" onClick={submitForm}>
            저장하기
          </button>
          {requestList && requestList.length > 1 &&
              <button style={{marginLeft:"10px"}} className="btn btn_puple mb-2" type="button" onClick={selectForm}>
                선택하기
              </button>
          }
        </div>
      }
    </>
  );
};

export default RequestDesignComponent;
