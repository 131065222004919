import React from "react";
import "../../sass/main.scss";
import {Link, useHistory} from "react-router-dom"

const GuideCard = (props) => {
  const path = window.location.pathname;
  const history = useHistory();

  return (
    <div className="BODY MAX my-4">
      <div
        className="mt-3 d-flex justify-content-between w-100"
        style={{minHeight: "100%"}}
      >
        <div className="box_round min_h left_menu">
          <div className="left_menu_title">이용가이드</div>
          <ul className="left_menu_list p-0">
            <li className={`${path.includes("/guide/about") ? "on" : ""}`}>
              <Link to="/guide/about">
                달리바바 소개
              </Link>
            </li>
          </ul>
          <ul className="left_menu_list p-0">
            <li className={`${path.includes("/guide/buyer") ? "on" : ""}`} style={{lineHeight: '25px', paddingTop: path.includes("/guide/buyer") ? '15px' : '0px', paddingBottom: '15px'}}>
              <Link to="/guide/buyer/1">
                바이어 이용방법
              </Link>
              {path.includes("/guide/buyer") &&
                <>
                  <div style={{marginTop: '5px'}}>
                    <Link to="/guide/buyer/1" style={{margin: 0, padding: 0, fontSize: '14px', lineHeight: '25px', fontWeight: path.includes("/guide/buyer/1") ? "bold" : "normal"}}>
                      - 단가조사
                    </Link>
                  </div>
                  <div>
                    <Link to="/guide/buyer/2" style={{margin: 0, padding: 0, fontSize: '14px', lineHeight: '25px', fontWeight: path.includes("/guide/buyer/2") ? "bold" : "normal"}}>
                      - 샘플, 디자인
                    </Link>
                  </div>
                  <div>
                    <Link to="/guide/buyer/3" style={{margin: 0, padding: 0, fontSize: '14px', lineHeight: '25px', fontWeight: path.includes("/guide/buyer/3") ? "bold" : "normal"}}>
                      - 견적 및 계약
                    </Link>
                  </div>
                  <div>
                    <Link to="/guide/buyer/4" style={{margin: 0, padding: 0, fontSize: '14px', lineHeight: '25px', fontWeight: path.includes("/guide/buyer/4") ? "bold" : "normal"}}>
                      - 제품 제작
                    </Link>
                  </div>
                  <div>
                    <Link to="/guide/buyer/5" style={{margin: 0, padding: 0, fontSize: '14px', lineHeight: '25px', fontWeight: path.includes("/guide/buyer/5") ? "bold" : "normal"}}>
                      - 통관 및 선적
                    </Link>
                  </div>
                </>
              }
            </li>
          </ul>
          <ul className="left_menu_list p-0">
            <li className={`${path.includes("/guide/maker") ? "on" : ""}`} style={{lineHeight: '25px', paddingTop: path.includes("/guide/maker") ? '15px' : '0px', paddingBottom: '15px'}}>
              <Link to="/guide/maker/1">
                제조사 이용방법
              </Link>
              {path.includes("/guide/maker") &&
                <>
                  <div style={{marginTop: '5px'}}>
                    <Link to="/guide/maker/1" style={{margin: 0, padding: 0, fontSize: '14px', lineHeight: '25px', fontWeight: path.includes("/guide/maker/1") ? "bold" : "normal"}}>
                      - 단가조사
                    </Link>
                  </div>
                  <div>
                    <Link to="/guide/maker/2" style={{margin: 0, padding: 0, fontSize: '14px', lineHeight: '25px', fontWeight: path.includes("/guide/maker/2") ? "bold" : "normal"}}>
                      - 샘플, 디자인
                    </Link>
                  </div>
                  <div>
                    <Link to="/guide/maker/3" style={{margin: 0, padding: 0, fontSize: '14px', lineHeight: '25px', fontWeight: path.includes("/guide/maker/3") ? "bold" : "normal"}}>
                      - 견적 및 계약
                    </Link>
                  </div>
                  <div>
                    <Link to="/guide/maker/4" style={{margin: 0, padding: 0, fontSize: '14px', lineHeight: '25px', fontWeight: path.includes("/guide/maker/4") ? "bold" : "normal"}}>
                      - 제품 제작
                    </Link>
                  </div>
                  <div>
                    <Link to="/guide/maker/5" style={{margin: 0, padding: 0, fontSize: '14px', lineHeight: '25px', fontWeight: path.includes("/guide/maker/5") ? "bold" : "normal"}}>
                      - 통관 및 선적
                    </Link>
                  </div>
                </>
              }
            </li>
          </ul>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default GuideCard;
