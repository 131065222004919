import React, {useEffect, useRef, useState} from "react";
import add_circle from "../../../image/add_circle.svg";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import {Link, useHistory} from "react-router-dom";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";
import moment from "moment";
import Constants from "../../../constants/constants";
import ProductsComponent from "./ProductsComponent";
import modalStyle from "../../../actions/openModalStyle";
import first_page_black_48dp from "../../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../../image/chevron_left_black_24dp.svg";
import navigate_next_black_48dp from "../../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../../image/last_page_black_48dp.svg";
import {useDispatch, useSelector} from "react-redux";
import $ from "jquery";
import openTimelineAttach from "../../../actions/openTimelineAttach";
import openEstimate from "../../../actions/openEstimate";
import openRequestFormulation from "../../../actions/openRequestFormulation";
import openRequestDesign from "../../../actions/openRequestDesign";
import openRequestBottle from "../../../actions/openRequestBottle";
import openRequestBox from "../../../actions/openRequestBox";
import CanvasDraw from "react-canvas-draw";

const AdminProjectDetail = (props) => {
  const projectId = props?.match?.params?.id;
  const [project, setProject] = useState({});
  const [projectName, setProjectName] = useState('');
  const [buyer, setBuyer] = useState({});
  const [keyword, setKeyword] = useState('');
  const [renew, setRenew] = useState('');
  const [timelines, setTimelines] = useState([]);
  const [timelinesO, setTimelinesO] = useState([]);
  const canvasRef = useRef();

  // 제품리스트 클릭 state
  const [selectProduct, setSelectProduct] = useState({});

  const history = useHistory();

  useEffect(() => {
    const fetch = async () => {
      await getProject(projectId);
    }
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await getTimelines();
    }
    fetch();
  }, [selectProduct?.id]);

  const getTimelines = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/gets", {projectId, type: 'MANAGER', productId: selectProduct?.id});
      if (code === 200) {
        const {data: {result: data}} = await APIHelper.getForm("project/timeline/data", {projectId, productId: selectProduct?.id});
        setTimelines(processTimelines(result, data));
        console.log(processTimelines(result, data).length)
      }

      $('.btn-request-form').click(function () {
        openRequestForm($(this).attr('projectId'), $(this).attr('projectProductId'), $(this).attr('requestType'));
      });

      $('.btn-estimate-form').click(function () {
        if ($(this).attr('status').includes('단순') || $(this).attr('status') === '본 견적서') downloadTimelineAttachModal($(this).attr('projectId'), $(this).attr('projectProductId'), $(this).attr('projectMakerRequestId'), $(this).attr('status'), $(this).attr('timelineId'));
        else downloadEstimateModal($(this).attr('projectId'), $(this).attr('projectProductId'), $(this).attr('projectMakerRequestId'), $(this).attr('status'), $(this).attr('timelineId'));
      });

      $('.container').not(':last').each(function () {
        $(this).find('.btn').not('.btn-action').each(function () {
          $(this).attr('disabled', true);
          if ($(this).parent().is("a")) $(this).unwrap();
        });
        $(this).find('.btn-action').each(function () {
          $(this).attr('disabled', false);
        });
      });

      return resolve(true);
    });
  };

  const processTimelines = (timelines, data) => {
    let title = '';
    let subtitle = '';
    let content = '';
    let html = '';
    let buttons = [];

    const last = timelines[timelines.length - 1];

    if (
      last?.status === '간단 견적서 확인 완료' ||
      last?.status === '간단 견적서 확인'
    ) {
      if (parseInt(data.maker_request_cnt_accept, 10) > 0) {
        title = '간단 견적서 작성 상태';
        content = `${data.maker_request_cnt_accept}/${data.maker_request_cnt_total} 완료되었습니다.`;
        html = ``;
        buttons.push({
          name: '확인',
          click: async () => {
            {
              const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
                projectId: last.project_id,
                projectProductId: last.project_product_id,
                // projectMakerRequestId: last.project_maker_request_id,
                step: 1,
                type: 'MANAGER',
                status: '바이어용 견적서 작성'
              });
            }
            {
              const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
                projectId: last.project_id,
                projectProductId: last.project_product_id,
                // projectMakerRequestId: last.project_maker_request_id,
                step: 1,
                type: 'BUYER',
                status: '견적서 작성중'
              });
            }
          },
          disabled: parseInt(data.maker_request_cnt_accept, 10) < parseInt(data.maker_request_cnt_total, 10),
          prevent: timelines.filter(t => t?.project_id === last?.project_id && t?.project_product_id === last?.project_product_id && t?.type === 'MANAGER' && t?.status === '바이어용 견적서 작성').length > 0,
        });

        const obj = {
          translated: true,
          status: title,
          title,
          subtitle,
          content,
          html,
          dt: moment().format("YYYY.MM.DD HH:mm"),
          buttons
        };

        timelines.push(obj);
      }
    } else if (
      last?.status === '샘플 본사 수령 완료' ||
      last?.status === '샘플 수령 후 승인'
    ) {
      if (parseInt(data.sample_progress_cnt_accept, 10) > 0) {
        title = '샘플 수령 후 승인 상태';
        content = `${data.sample_progress_cnt_accept}/${data.sample_progress_cnt_total} 완료되었습니다.`;
        html = ``;
        buttons.push({
          name: '확인',
          click: async () => {
            const {data: {code, result, message}} = await APIHelper.postForm("flow/sample/done", {
              projectId: last.project_id,
              projectProductId: last.project_product_id,
            });
            await CommonLib.jalert('제조사 측에 본 견적서 작성 메세지를 발송하였습니다.');
          },
          disabled: parseInt(data.sample_progress_cnt_accept, 10) < parseInt(data.sample_progress_cnt_total, 10),
          prevent: timelines.filter(t => t?.project_id === last?.project_id && t?.project_product_id === last?.project_product_id && t?.type === 'MANAGER' && t?.status === '샘플 수령 후 승인 - DONE').length > 0,
        });

        const obj = {
          translated: true,
          status: title,
          title,
          subtitle,
          content,
          html,
          dt: moment().format("YYYY.MM.DD HH:mm"),
          buttons
        };

        timelines.push(obj);
      }
    } else if (
      last?.status === '본 견적서 확인 및 결정' ||
      last?.status === '본 견적서 확인 및 결정 완료'
    ) {
      if (parseInt(data.real_estimate_cnt_accept, 10) > 0) {
        title = '본 견적서 확인 및 결정 완료 상태';
        content = `${data.real_estimate_cnt_accept}/${data.real_estimate_cnt_total} 완료되었습니다.`;
        html = ``;
        buttons.push({
          name: '확인',
          click: async () => {
            {
              const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
                projectId: last.project_id,
                projectProductId: last.project_product_id,
                projectMakerRequestId: last.project_maker_request_id,
                step: 3,
                type: 'MANAGER',
                status: '바이어용 본 견적서 작성'
              });
            }
          },
          disabled: parseInt(data.real_estimate_cnt_accept, 10) < parseInt(data.real_estimate_cnt_total, 10),
          prevent: timelines.filter(t => t?.project_id === last?.project_id && t?.project_product_id === last?.project_product_id && t?.project_maker_request_id === last?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '바이어용 본 견적서 작성').length > 0,
        });

        const obj = {
          translated: true,
          status: title,
          title,
          subtitle,
          content,
          html,
          dt: moment().format("YYYY.MM.DD HH:mm"),
          buttons
        };

        timelines.push(obj);
      }
    } else if (
      last?.status === '계약서 확인 및 서명 완료' ||
      last?.status === '계약서 작성' ||
      last?.status === '계약서 전달 및 서명 요청' ||
      last?.status === '제조사 계약 입금 처리' ||
      last?.status === '계약 및 서명 완료 처리'
    ) {
      if (parseInt(data.signing_cnt_total, 10) > 0) {
        title = '';
        content = `${data.signing_cnt_accept}/${data.signing_cnt_total} 서명 완료되었습니다.\n${data.receipt_cnt_accept}/${data.receipt_cnt_total} 입금 완료되었습니다.`;
        html = ``;
        buttons.push({
          name: '확인',
          click: async () => {
            {
              const {data: {code, result, message}} = await APIHelper.postForm("flow/indicate/manufacture", {
                projectId: last.project_id,
                projectProductId: last.project_product_id,
              });
            }
          },
          disabled: parseInt(data.signing_cnt_accept, 10) < parseInt(data.signing_cnt_total, 10) || parseInt(data.receipt_cnt_accept, 10) < parseInt(data.receipt_cnt_total, 10),
          prevent: timelines.filter(t => t?.project_id === last?.project_id && t?.project_product_id === last?.project_product_id && t?.type === 'MANAGER' && t?.status === '제품 생산 지시').length > 0,
        });

        const obj = {
          translated: true,
          status: title,
          title,
          subtitle,
          content,
          html,
          dt: moment().format("YYYY.MM.DD HH:mm"),
          buttons
        };

        timelines.push(obj);
      }
    } else if (
      (last?.status === '잔금 입금' ||
        last?.status === '잔금 입금 완료' ||
        last?.status === '잔금 입금 확인' ||
        last?.status === '잔금 입금 확인 - DONE'
      )
      && last?.ref_ctnt_1 === '내용물'
    ) {
      if (parseInt(data.balance_cnt_accept, 10) >= 0) {
        title = '';
        content = `${data.balance_cnt_accept}/${data.balance_cnt_total} 완료되었습니다.`;
        html = ``;
        buttons.push({
          name: '확인',
          click: async () => {
            {
              const {data: {code, result, message}} = await APIHelper.postForm("flow/balance/payment/request", {
                projectId: last.project_id,
                projectProductId: last.project_product_id,
                projectMakerRequestId: last.project_maker_request_id,
              });
              await CommonLib.jalert('바이어에게 잔금 결제 요청하였습니다.');
            }
          },
          disabled: parseInt(data.balance_cnt_accept, 10) < parseInt(data.balance_cnt_total, 10),
          prevent: timelines.filter(t => t?.project_id === last?.project_id && t?.project_product_id === last?.project_product_id && t?.project_maker_request_id === last?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '잔금 입금 완료 - DONE').length > 0,
        });

        const obj = {
          translated: true,
          status: title,
          title,
          subtitle,
          content,
          html,
          dt: moment().format("YYYY.MM.DD HH:mm"),
          buttons
        };

        timelines.push(obj);
      }
    } else if (
      last?.status === '제품 배송지로 배송'
    ) {
      if (parseInt(data.delivery_cnt_accept, 10) >= 0) {
        title = '';
        content = `${data.delivery_cnt_accept}/${data.delivery_cnt_total} 완료되었습니다.`;
        html = ``;
        buttons.push({
          name: '확인',
          click: async () => {
            {
              const {data: {code, result, message}} = await APIHelper.postForm("flow/buyer/balance/request", {
                projectId: last.project_id,
                projectProductId: last.project_product_id,
              });
              await CommonLib.jalert('바이어에게 잔금 결제를 요청하였습니다.');
            }
          },
          disabled: parseInt(data.delivery_cnt_accept, 10) < parseInt(data.delivery_cnt_total, 10),
          prevent: timelines.filter(t => t?.project_id === last?.project_id && t?.project_product_id === last?.project_product_id && t?.type === 'MANAGER' && t?.status === '제품 배송지로 배송 - DONE').length > 0,
        });

        const obj = {
          translated: true,
          status: title,
          title,
          subtitle,
          content,
          html,
          dt: moment().format("YYYY.MM.DD HH:mm"),
          buttons
        };

        timelines.push(obj);
      }
    }

    const skips = ['간단 견적서 확인 완료', '패킹 리스트', '인보이스', '면장'];
    const ntimelines = [];
    for (const [key, timeline] of Object.entries(timelines)) {
      if (skips.indexOf(timeline.status) === -1 && !timeline.status.endsWith(' - DONE')) {
        ntimelines.push(timeline);
      }
    }

    setTimelinesO(timelines);
    return ntimelines;
  }

  const downloadTimelineAttachModal = (projectId, projectProductId, projectMakerRequestId, formType) => {
    const obj = {
      projectId,
      projectProductId,
      projectMakerRequestId,
      title: formType.replace(' 작성', ''),
      renew: new Date().getTime(),
      readOnly: true,
      formType
    };
    dispatch(openTimelineAttach("open", obj));
  }

  const downloadEstimateModal = (projectId, projectProductId, projectMakerRequestId, formType, timelineId) => {
    const obj = {
      projectId,
      projectProductId,
      projectMakerRequestId,
      title: formType.replace(' 작성', ''),
      renew: new Date().getTime(),
      readOnly: true,
      formType,
      timelineId
    };
    dispatch(openEstimate("open", obj));
  }

  const openRequestForm = (projectId, projectProductId, itemCd) => {
    const obj = {projectId, projectProductId, renew: new Date().getTime(), readOnly: true};
    if (itemCd === 'MF') dispatch(openRequestFormulation("open", obj));
    else if (itemCd === 'DS') dispatch(openRequestDesign("open", obj));
    else if (itemCd === 'BT') dispatch(openRequestBottle("open", obj));
    else if (itemCd === 'BX') dispatch(openRequestBox("open", obj));
  }

  const updateProjectName = async () => {
    const {data: {code, message}} = await APIHelper.putForm(`project/name/update`, {
      id: projectId,
      name: projectName
    });
    await CommonLib.jalert(message);
  }

  const getBuyer = (id) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("user/buyer/get", {id});
      if (code === 200) {
        setBuyer(result);
      }
      return resolve(true);
    });
  }

  const getProject = (id) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("project/get", {id});
      if (code === 200) {
        setProject(result);
        if (result?.products?.length > 0)
          setSelectProduct(result?.products[0]);
        if (result?.name?.length > 0)
          setProjectName(result?.name);
        await getBuyer(result.buyer_user_id);
      }
      return resolve(true);
    });
  }

  const onRequestDetail = () => {
    if (CommonLib.parseInt(selectProduct?.id) <= 0) return;
    history.push(`/admin/project/request/form/${selectProduct?.project_id}/${selectProduct?.id}`);
  }

  const submitMakerRequest = (projectId) => {
    const obj = {projectId};
    setRenew(new Date().getTime());
    dispatch(modalStyle("open", obj))
  }

  //---------------------------------------------------------------------------

  const [makers, setMakers] = useState(false);
  const [selectedMaker, setSelectedMaker] = useState({});

  useEffect(() => {
    const fetch = async () => {
      setKeyword('');
      await getMakers();
    }
    fetch();
  }, [renew]);

  // 제조사 리스트 가져오기
  const getMakers = (keyword = '') => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("user/maker/gets", {keyword});
      setMakers(result);
      return resolve(true);
    });
  };

  const handleSearchKeyDown = async (e) => {
    if (e.key === "Enter") {
      await getMakers(keyword);
    }
  };

  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modalStyle);

  const selectMakerSubmit = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.postForm("project/rep/maker/submit", {
        projectId,
        repMakerUserId: selectedMaker?.user_id,
      });
      await CommonLib.jalert(message);

      if (code === 200) await getProject(projectId);

      dispatch(modalStyle("closed", ""));
      return resolve(true);
    });
  }

  let modalInlineStyle;

  if (modal.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }

  //---------------------------------------------------------------------------

  return (
    <AdminCard>
      {/*대표 제조사 모달 S*/}
      <div style={{display: modalInlineStyle}}>
        <div
          className="pop_box02"
          style={{width: "900px", minHeight: "500px"}}
        >
          <div className="POP_title pb-3 border-bottom">
            <h4>
              <strong>대표 제조사 선택</strong>
            </h4>
            <p className="closed">
              <button
                type="button"
                className="btn"
                onClick={() => dispatch(modalStyle("closed", ""))}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box_lg" style={{marginBottom: "40px"}}>
            <div className="bg-lpurple box_round p-3 mt-3">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="제조사 명 또는 아래 키워드를 선택하세요"
                  onChange={async (e) => await setKeyword(e.target.value)}
                  value={keyword}
                  onKeyDown={handleSearchKeyDown}
                />
                <button
                  className="btn btn-blue px-5"
                  type="button"
                  id="button-addon2"
                  onClick={() => getMakers(keyword)}
                >
                  검색
                </button>
              </div>
              <ul className="d-flex pt-3 px-0 m-0">
                <li className="px-1">
                  <a href="#" className="Hash2 txt-point txt-sm">
                    Mask
                  </a>
                </li>
                <li className="px-1">
                  <a href="#" className="Hash2 txt-point txt-sm">
                    Hair
                  </a>
                </li>
                <li className="px-1">
                  <a href="#" className="Hash2 txt-point txt-sm">
                    Skincare
                  </a>
                </li>
                <li className="px-1">
                  <a href="#" className="Hash2 txt-point txt-sm">
                    Face Oil
                  </a>
                </li>
              </ul>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll"
              style={{height: "740px", maxWidth: "calc(100% + 4px)"}}
            >
              {makers && makers.map((maker, key) =>
                <div
                  key={key}
                  className={`d-flex p-4 p_rative border${
                    selectedMaker && selectedMaker === maker
                      ? "_purple"
                      : " border-bottom"
                  }`}
                  style={{clear: "both"}}
                  onClick={() => setSelectedMaker(maker)}
                >
                  <p>
                    {maker.rep_image_fid && maker.rep_image_fid.length > 0 ?
                      <img
                        src={`${Constants.API_HOST}/common/download?fid=${maker.rep_image_fid}&cd=inline&thumb=400`}
                        style={{width: "220px", height: "155px", objectFit: "cover"}}
                      />
                      :
                      <img
                        src={process.env.PUBLIC_URL + "/images/no_image.png"}
                        style={{width: "220px", height: "155px", objectFit: "cover"}}
                      />
                    }
                  </p>
                  <div className="px-4">
                    <h5 className="txt-point">
                      <strong>{maker.company_name}</strong>
                    </h5>
                    <p>
                      {maker?.introduce.length > 0 ? maker.introduce : "등록된 한줄 소개가 없습니다."}
                      <br/>
                      {maker?.functionals && maker?.functionals.length > 0 ? maker.functionals.map((item, key) => (
                        <>
                          {key > 0 ? ", " : ""} {item.name_kr}
                        </>
                      )) : " 등록된 기능성 제품 분류가 없습니다."}
                    </p>

                    <ul className="txt-gray p-0 pt-2" style={{marginBottom: "8px"}}>
                      <li className=" txt-sm">
                        인증서 :
                        {maker?.certs && maker?.certs?.length > 0 ? maker?.certs.map((item, key) => (
                          <>
                            {key > 0 ? " / " : ""} {item.name_en}
                          </>
                        )) : " 없음"}
                      </li>
                      <li className="d-flex txt-sm">
                        <p className="txt-sm">MOQ : {maker?.moq?.name_kr?.length > 0 ? maker?.moq?.name_kr : "정보없음"}</p> <p className="txt-sm" style={{marginLeft: "15px"}}>거래 횟수 : 0회</p>
                      </li>
                    </ul>
                    <div className="mb-0 float-left">
                      {maker?.mf_items && maker?.mf_items.map((item, key) => (
                        <span className="Hash txt-point">{item.name_kr}</span>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-center py-4 d-none">
                <ul className="d-flex my-3" style={{margin: "0 auto"}}>
                  <li className="PAGE">
                    <a href="#" aria-label="Previous">
                      <img
                        src={first_page_black_48dp}
                        style={{width: "25px"}}
                      />
                    </a>
                  </li>
                  <li className="PAGE">
                    <a href="#" aria-label="Previous">
                      <img
                        src={chevron_left_black_24dp}
                        style={{width: "25px"}}
                      />
                    </a>
                  </li>
                  <li className="PAGE">
                    <a href="#" className="ON">
                      1
                    </a>
                  </li>
                  <li className="PAGE">
                    <a href="#">2</a>
                  </li>
                  <li className="PAGE">
                    <a href="#">3</a>
                  </li>
                  <li className="PAGE">
                    <a href="#" aria-label="Previous">
                      <img
                        src={navigate_next_black_48dp}
                        style={{width: "25px"}}
                      />
                    </a>
                  </li>
                  <li className="PAGE">
                    <a href="#" aria-label="Previous">
                      <img src={last_page_black_48dp} style={{width: "25px"}}/>
                    </a>
                  </li>
                </ul>
              </div>

            </div>
          </div>
          <div className="POP_foot d-flex justify-content-between">
            <button
              className="btn btn-sm px-3 btn-blue"
              type="button"
              onClick={() => selectMakerSubmit()}
            >
              선택한 제조사를 대표 제조사로 지정합니다
            </button>
          </div>
        </div>
      </div>
      {/*대표 제조사 모달 E*/}

      <div
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <section className="col-4" style={{height: "98%"}}>
          <div
            className="bg-lpurple shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "100%"}}
          >
            <p className="h5 bold p-2 border-bottom">제품리스트</p>
            <div
              className="wid_scroll scroll_y custom_scroll_p"
              style={{height: "calc(100% - 60px)"}}
            >
              <ProductsComponent projectId={projectId} click={async (e) => {
                setSelectProduct(e);
              }}/>
            </div>
          </div>
        </section>
        <section className="col-5" style={{height: "98%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "100%"}}
          >
            <p className="h5 bold p-2 border-bottom">관리</p>
            <article className="border">
              <ul className="p-3 w-100 d-flex flex-wrap mb-2">
                <div className="p-3 d-flex justify-content-between align-items-center w-100">
                  <span className="center" style={{fontWeight: "bold"}}>프로젝트명</span>
                  <span style={{width: "calc(100% - 200px)"}}>
                    <input
                      type="text"
                      className="form-control form-control-sm w-100"
                      placeholder="프로젝트명 입력"
                      aria-describedby="button-addon2"
                      onChange={async (e) => {
                        await setProjectName(e.target.value);
                      }}
                      value={projectName}
                    />
                  </span>
                  <span>
                    <button
                      type="button"
                      className="btn btn-blue p-1 px-4"
                      style={{marginRight: "5px"}}
                      onClick={async () => await updateProjectName()}
                    >
                      변경
                    </button>
                  </span>
                </div>
                <li className="px-3 w-45 txt-sm txt-gray">
                  <span className="bold">요청번호 :</span> {selectProduct?.req_no}
                </li>
                <li className="px-3 w-45 txt-sm txt-gray">
                  <span className="bold">요청일자 :</span> {moment(project.created_at).format("YYYY.MM.DD")}
                </li>
                <li className="px-3 w-45 txt-sm txt-gray">
                  <span className="bold">진행상태 :</span>&nbsp;{selectProduct?.status?.name_kr}
                </li>
                <li className="px-3 w-45 txt-sm txt-gray">
                  <span className="bold">바이어 제작 문의</span>
                </li>
              </ul>

              <div className="row row-cols-2 mb-3 gx-3 px-3">
                <div className="col">
                  <div className="border box_round_5 p-2 px-3">
                    <div className="d-flex flex-wrap justify-content-between">
                      <p className="h6 mt-1">바이어</p>
                      <p>
                        {/*<button*/}
                        {/*  type="button"*/}
                        {/*  className="btn btn-sm p-0 px-2 btn-ligray"*/}
                        {/*>*/}
                        {/*  교체*/}
                        {/*</button>*/}
                        <Link to={`/buyer/home/${buyer.user_id}`} target="_blank">
                          <button
                            type="button"
                            className="btn btn-sm p-0 px-2 btn-ligray"
                          >
                            새창
                          </button>
                        </Link>
                      </p>
                    </div>

                    <div className="d-flex justify-content-center mt-3 mb-2">
                      <p className="btn_oval_lg mb-1 mx-1">
                        {buyer?.rep_image_fid?.length > 0 ?
                          <img src={`${Constants.API_HOST}/common/download?fid=${buyer?.rep_image_fid}&cd=inline&thumb=400`} style={{width: "65px", height: "65px", objectFit: "cover"}}/>
                          :
                          <div style={{width: "65px", height: "65px", borderRadius: "33.5px", backgroundColor: "#efefef"}}></div>
                        }
                      </p>
                      <div className="text-start mx-1">
                        <p className="h6">{buyer?.company}</p>
                        <p>국적 : {buyer?.country?.name_kr}</p>
                        <p>거래횟수 : 0회</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="border box_round_5 p-2"
                    style={{minHeight: "145px"}}
                  >
                    {project?.rep_maker_user_id > 0 ? (
                      <>
                        <div className="d-flex flex-wrap justify-content-between">
                          <p className="h6 mt-1">대표 제조사</p>
                          <p>
                            <button
                              type="button"
                              className="btn btn-sm p-0 px-2 btn-ligray"
                              onClick={() => submitMakerRequest(props?.product?.project_id)}
                            >
                              교체
                            </button>
                            <Link to={`/maker/home/${project?.rep_maker_user_id}`} target="_blank">
                              <button
                                type="button"
                                className="btn btn-sm p-0 px-2 btn-ligray"
                              >
                                새창
                              </button>
                            </Link>
                          </p>
                        </div>

                        <div className="d-flex justify-content-center mt-3 mb-2">
                          <p className="btn_oval_lg mb-1 mx-1">
                            {project?.rep_maker?.id > 0 ?
                              <img src={`${Constants.API_HOST}/common/download?fid=${project?.rep_maker?.rep_image_fid}&cd=inline&thumb=400`} style={{width: "65px", height: "65px", objectFit: "cover"}}/>
                              :
                              <div style={{width: "65px", height: "65px", borderRadius: "33.5px", backgroundColor: "#efefef"}}></div>
                            }
                          </p>
                          <div className="text-start mx-1">
                            <p className="h6">{project?.rep_maker?.company_name}</p>
                            <p>국적 : {project?.rep_maker?.country?.name_kr}</p>
                            <p>거래횟수 : 0회</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <Link onClick={() => submitMakerRequest(props?.product?.project_id)}>
                        <p className="text-center p-3 h6">
                          대표 제조사를 연동하세요.
                          <br/>
                          <button type="button" className="btn btn-sm mt-2">
                            <img src={add_circle} style={{width: "45px"}}/>
                          </button>
                        </p>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </article>

            <article className="p-3 my-2">
              {project && !project.accepted &&
                <p className="h6">
                  진행 하시려면 접수진행 및 프로젝트 생성 하세요
                </p>
              }
              <p className="mt-3">
                {project && !project.accepted &&
                  <button
                    type="button"
                    className="btn btn-blue p-1 px-4"
                    style={{marginRight: "5px"}}
                    onClick={async () => {
                      const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {projectId: project.id, step: 1, status: '바이어 상담 문의 - 접수'});
                      await CommonLib.jalert(message);
                      await getProject(projectId);
                    }}
                  >
                    접수진행
                  </button>
                }
                {project && !project.finished &&
                  <button type="button" className="btn btn_gray p-1 px-4"
                          onClick={async () => {
                            const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {projectId: project.id, step: 6, status: '거래 프로세스 종료'});
                            await CommonLib.jalert(message);
                          }}
                  >
                    프로젝트 종료
                  </button>
                }
              </p>
            </article>

            <div
              className="wid_scroll scroll_y custom_scroll"
              style={{height: "calc(100% - 430px)"}}
            >
              <article className="border wid_inline">
                <div className="p-3">
                  <div className="d-flex justify-content-between pb-2 border-bottom">
                    <p className="h5 mt-1 idx_tit">제작의뢰서</p>
                    <p>
                      <Link to={`/admin/project/connect/${selectProduct ? selectProduct?.project_id : ''}`}>
                        <button
                          type="button"
                          className="btn btn-dark txt-sm py-1 px-3"
                          style={{marginRight: "5px"}}
                        >
                          제조사 연동 관리 +
                        </button>
                      </Link>
                      <button
                        type="button"
                        className="btn btn-dark txt-sm py-1 px-3"
                        onClick={onRequestDetail}
                      >
                        제작 의뢰서 관리 +
                      </button>
                    </p>
                  </div>

                  {selectProduct?.request_forms?.length > 0 ? (
                    selectProduct?.request_forms.map((item, i) => (
                      <div className="mt-3" style={{textAlign: "left"}}>
                        <div className="m-2 d-flex">
                          <p className="btn_oval_30 bg-lgray0 mx-1 txt-sm pt-1 bold">
                            {i + 1}
                          </p>
                          <p className="border box_round_4_paddingNo bg-lgray txt-sm p-1 px-3 mx-1" style={{width: "80px", textAlign: "center"}}>
                            {item.request_type === 'MF' ? '제형' :
                              item.request_type === 'BT' ? '용기' :
                                item.request_type === 'BX' ? '단상자' :
                                  item.request_type === 'DS' ? '디자인' : ''}
                          </p>
                          <p className="p-1">
                            제작의뢰서 : {item.request_name}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="py-3 text-center">
                      작성된 제작 의뢰서가 없습니다.
                    </p>
                  )}
                </div>

                <div className="p-3">
                  <div className="d-flex justify-content-between pb-2 border-bottom">
                    <p className="h5 mt-1 idx_tit">담당 매니저</p>
                    <p>
                      <Link to={`/admin/project/manager/${project.id}`}>
                        <button
                          type="button"
                          className="btn btn-dark txt-sm py-1 px-3"
                        >
                          매니저 배당 관리 +
                        </button>
                      </Link>
                    </p>
                  </div>

                  <div className="row row-cols-2 gx-3 mt-4">
                    {project?.managers && project?.managers.map((item, i) => (
                      <div key={i} className="col">
                        <div className="box_round_5 border my-2 d-flex p-3">
                          <p className="btn_oval_sm mb-1 mx-1">
                            {item.image_fid && item.image_fid.length > 0 ?
                              <img
                                src={`${Constants.API_HOST}/common/download?fid=${item.image_fid}&cd=inline&thumb=400`}
                                style={{width: "45px", height: "45px"}}
                              />
                              :
                              <div style={{width: "45px", height: "45px", backgroundColor: "#efefef", borderRadius: "22.5px"}}></div>
                            }
                          </p>
                          <div className="text-start mx-2">
                            <p className="h6 mb-1">{item.name}</p>
                            <p className="txt-sm">{item.position} | {item.task}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                    {(!project?.managers || project?.managers?.length === 0) &&
                      <p className="py-3 text-center w-100">배정된 매니저가 없습니다.</p>
                    }
                  </div>
                </div>

                <div className="p-3">
                  <div className="d-flex justify-content-between pb-2 border-bottom">
                    <p className="h5 mt-1 idx_tit">생성된 제품</p>
                    <p>
                      <button
                        type="button"
                        className="btn btn-dark txt-sm py-1 px-3"
                        onClick={() => {
                          localStorage.setItem("timeline.params.type", '');
                          localStorage.setItem("timeline.params.project_id", '');
                          localStorage.setItem("timeline.params.project_product_id", '');
                          localStorage.setItem("timeline.params.project_maker_request_id", '');
                          history.push(`/admin/project/formed/product/${projectId}`);
                        }}
                      >
                        제품 생성 관리 +
                      </button>
                    </p>
                  </div>

                  {selectProduct?.formed_products?.length > 0 ? (
                    selectProduct?.formed_products.map((item, i) => (
                      <div className="mt-3" style={{textAlign: "left"}}>
                        <div className="m-2 d-flex">
                          <p className="btn_oval_30 bg-lgray0 mx-1 txt-sm pt-1 bold">
                            {i + 1}
                          </p>
                          <p className="p-1">
                            생성 제품 : {item.name}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="py-3 text-center">
                      제작을 위한 제품 구성이 없습니다.
                    </p>
                  )}
                </div>
              </article>
            </div>
          </div>
        </section>

        <section className="col-3" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <p className="h5 bold p-2 border-bottom">History</p>

            <div className="w-100" style={{overflowY: "auto", height: "calc(100% - 50px)"}}>
              <table className="w-100 basic mt-4">
                <colgroup>
                  <col width="#"/>
                  <col width="155px"/>
                </colgroup>
                {timelines.map((item, key) => {
                  item.canvasRef = canvasRef;
                  const obj = CommonLib.getTimelineObj(item, timelinesO);

                  if (obj?.status?.length) {
                    return (
                      <tr>
                        <td style={{borderTop: key === 0 ? "none" : ""}}>
                          {obj.status}
                          {/*/ {obj.subtitle}*/}
                        </td>
                        <td className="date" style={{borderTop: key === 0 ? "none" : ""}}>{obj.dt}</td>
                      </tr>
                    )
                  } else {
                    return null;
                  }
                })}
                {timelines.length === 0 &&
                  <tr>
                    <td style={{borderTop: "none", textAlign: "center", paddingBottom: "35px"}} colSpan={2}>히스토리가 없습니다.</td>
                  </tr>
                }
              </table>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminProjectDetail;
