export default (openClose, mode, projectId, subject, question, answer, date, type, status_cd, id, renew, callback) => {
  return {
    type: "OPEN_INQUIRY",
    payload: {
      openClose,
      content: {
        mode,
        projectId,
        subject,
        question,
        answer,
        date,
        type,
        status_cd,
        id,
        renew,
        callback
      },
    },
  };
};
