import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import translationEn from './lang.en.json'
import translationKo from './lang.ko.json'

const resource = {
  en: {
    translation: translationEn
  },
  ko: {
    translation: translationKo
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resource,
    lang: 'ko',
    fallbackLng: 'ko',
    debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
