import React, {useEffect, useState} from "react";
import last_page_black_48dp from "../../image/last_page_black_48dp.svg";
import navigate_next_black_48dp from "../../image/navigate_next_black_48dp.svg";
import chevron_left_black_24dp from "../../image/chevron_left_black_24dp.svg";
import first_page_black_48dp from "../../image/first_page_black_48dp.svg";
import "../../sass/main.scss";
import ChannelService from "../../helpers/ChannelService";
import CsCenterCard from "./CsCenterCard";
import InquiryItems from "./InquiryItems";
import APIHelper from "../../helpers/APIHelper";
import CommonLib from "../../libraries/CommonLib";

const Inquiry = (props) => {
  const ChannelTalk = () => {
    useEffect(() => {
      const path = window.location.pathname;
      if (path.includes("/cscenter/inquiry")) return "";

      ChannelService.boot({
        "pluginKey": "0f530fd7-6842-46d9-bb6c-0fac9e4c849e",
        "memberId": localStorage.getItem("user.session.email"),
        "profile": {
          "name": localStorage.getItem("user.session.name")
        },
      });
    }, []);

    return ""
  }

  const openBot = () => {
    ChannelService.openBot();
  }

  const [rets, setRets] = useState([]);

  const gets = async () => {
    const {data: {code, result, message}} = await APIHelper.getForm("cs/inquiry/mines", {projectId: 0});
    setRets(result);
  };

  const [gubunCds, setGubunCds] = useState([]);
  const [gubunCd, setGubunCd] = useState();
  const [subject, setSubject] = useState('');
  const [question, setQuestion] = useState('');

  const getGubunCds = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '140000'});
      if (code === 200) {
        setGubunCds(result);
        if (result?.length > 0) setGubunCd(result?.[0]?.cd);
      }
      return resolve(true);
    });
  };

  const submit = async () => {
    if (subject.trim() === '') {
      await CommonLib.jalert("제목을 입력해 주세요.");
      return;
    }

    if (question.trim() === '') {
      await CommonLib.jalert("내용을 입력해 주세요.");
      return;
    }

    const {data: {code, result, message}} = await APIHelper.postForm("cs/inquiry/register", {projectId: 0, gubunCd, subject, question});
    await CommonLib.jalert(message);
    await gets();
  };

  useEffect(() => {
    const fetch = async () => {
      setGubunCds([]);
      setGubunCd('');
      setSubject('');
      setQuestion('');
      await getGubunCds();
      await gets();
    }
    fetch();
  }, []);

  // 테스트 더미데이터
  const DUMMY_DATA = [
    {
      no: 1,
      title: "설문조사 이벤트 당첨자 안내 입니다.",
      category: "통관 관련",
      date: new Date(2020, 7, 14),
      state: "대기",
      text: "신축년 새해 첫 이벤트 당첨을 축하 드립니다! IT 전문직 구인구직 시장현황 조사에 참여해주셔서 감사드리며,아래와 같이 당첨자 및 경품 안내 드립니다. ▶경품 : 스타벅스 시그니처 핫 초콜릿(tall)  ▶발송 일시 : 01/09(금) 모바일 쿠폰으로 발송 예정감사합니다.",
    },
    {
      no: 2,
      title: "회원가입 관련 안내 입니다.",
      category: "프로젝트",
      date: new Date(2020, 11, 14),
      state: "대기",
      text: "신축년 새해 첫 이벤트 당첨을 축하 드립니다! IT 전문직 구인구직 시장현황 조사에 참여해주셔서 감사드리며,아래와 같이 당첨자 및 경품 안내 드립니다. ▶경품 : 스타벅스 시그니처 핫 초콜릿(tall)  ▶발송 일시 : 01/09(금) 모바일 쿠폰으로 발송 예정감사합니다.",
    },
    {
      no: 3,
      title: "프로젝트(결제) 관련 안내 입니다.",
      category: "프로젝트",
      date: new Date(2020, 12, 14),
      state: "완료",
      text: "신축년 새해 첫 이벤트 당첨을 축하 드립니다! IT 전문직 구인구직 시장현황 조사에 참여해주셔서 감사드리며,아래와 같이 당첨자 및 경품 안내 드립니다. ▶경품 : 스타벅스 시그니처 핫 초콜릿(tall)  ▶발송 일시 : 01/09(금) 모바일 쿠폰으로 발송 예정감사합니다.",
      response: "답변입니다.",
    },
  ];

  return (
    <CsCenterCard>
      <ChannelTalk/>
      <div className="box_round min_h cnt_area pb-5">
        <div className="mx-5 my-4">
          <p className="h3 bold border-bottom py-3">1:1문의</p>
          <br/>

          <div className="box_round bg-lred p-4 mb-5 mt-3 p_rative" onClick={() => openBot()}>
            <p className="h5 bold pt-1">채팅 문의</p>
            <p className="pb-3 txt-dark">
              실시간 답변이 필요하시다면 채팅 문의해 주세요.
              <br/>
              지금 바로 상담을 원하신다면 오른쪽의 채팅 상담하기 버튼을
              누르세요.
            </p>
            <p
              className="p_absolute_br2"
              style={{bottom: "30px", right: "30px"}}
            >
              <button
                type="button"
                className="btn btn-sm bg-red px-3"
                style={{paddingBottom: "5px"}}
              >
                실시간 채팅 상담하기
              </button>
            </p>
          </div>

          <div className="my-3">
            <p className="h5 pt-1 bold">1:1 문의하기</p>
            <p>
              아래 양식을 이용하여 문의를 주시면 최대한 빨리 답변해
              드리겠습니다.
            </p>

            <table className="table basic my-3 text-center">
              <colgroup>
                <col width="15%"/>
                <col width="*"/>
              </colgroup>

              <tr>
                <th>구분</th>
                <td>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={async (e) => await setGubunCd(e.target.value)}
                    value={gubunCd}
                  >
                    {gubunCds.map((item, k) => <option key={k} value={item.cd} selected={item.cd === gubunCd}>{item.name_kr}</option>)}
                  </select>
                </td>
              </tr>
              <tr>
                <th>제목</th>
                <td>
                  <input type="text" className="form-control form-control-sm"
                         placeholder="제목을 입력해 주세요."
                         onChange={async (e) => await setSubject(e.target.value)}
                         value={subject}
                  />
                </td>
              </tr>
              <tr>
                <th>문의내용</th>
                <td>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    placeholder="내용을 입력해 주세요."
                    onChange={async (e) => await setQuestion(e.target.value)}
                    value={question}
                  />
                </td>
              </tr>
            </table>

            <p className="text-center">
              <button type="button" className="btn btn_puple px-3 my-3" onClick={() => submit()}>
                문의하기
              </button>
            </p>
          </div>

          <div className="mt-5">
            <p className="h5 pt-1">나의 1:1 문의내역</p>

            <table className="table basic my-3" style={{textAlign: "center"}}>
              <colgroup>
                <col width="75px"/>
                <col width="180px"/>
                <col width="*"/>
                <col width="70px"/>
                <col width="120px"/>
              </colgroup>
              <tr>
                <th>No</th>
                <th>구분</th>
                <th>제목</th>
                <th></th>
                <th>작성일</th>
              </tr>

              <InquiryItems items={rets}/>
            </table>
            {rets?.length === 0 &&
              <div className="col w-100">
                <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-white">
                  문의한 내역이 없습니다.
                </div>
              </div>
            }
            {/*
            <div className="d-flex justify-content-center py-4 d-none">
              <ul className="d-flex my-3" style={{ margin: "0 auto" }}>
                <li className="PAGE">
                  <a href="src/components/cscenter/MypageInquiry#" aria-label="Previous">
                    <img
                      src={first_page_black_48dp}
                      style={{ width: "25px" }}
                    />
                  </a>
                </li>
                <li className="PAGE">
                  <a href="src/components/cscenter/MypageInquiry#" aria-label="Previous">
                    <img
                      src={chevron_left_black_24dp}
                      style={{ width: "25px" }}
                    />
                  </a>
                </li>
                <li className="PAGE">
                  <a href="src/components/cscenter/MypageInquiry#" className="ON">
                    1
                  </a>
                </li>
                <li className="PAGE">
                  <a href="src/components/cscenter/MypageInquiry#">2</a>
                </li>
                <li className="PAGE">
                  <a href="src/components/cscenter/MypageInquiry#">3</a>
                </li>
                <li className="PAGE">
                  <a href="src/components/cscenter/MypageInquiry#" aria-label="Previous">
                    <img
                      src={navigate_next_black_48dp}
                      style={{ width: "25px" }}
                    />
                  </a>
                </li>
                <li className="PAGE">
                  <a href="src/components/cscenter/MypageInquiry#" aria-label="Previous">
                    <img src={last_page_black_48dp} style={{ width: "25px" }} />
                  </a>
                </li>
              </ul>
            </div>
*/}
          </div>
        </div>
      </div>
    </CsCenterCard>
  );
};

export default Inquiry;
