import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import APIHelper from "../../../helpers/APIHelper";
import Constants from "../../../constants/constants";
import moment from "moment";
import Paging from "../../common/Paging";

const AdminBuyerMain = (props) => {
  const [buyers, setBuyers] = useState(false);

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      await getBuyers();
    }
    fetch();
  }, [currPage]);

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "바이어관리 > 바이어 현황",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const [keyword, setKeyword] = useState('');
  const search = async (_keyword) => {
    await setCurrPage(1);
    await getBuyers(_keyword);
  }
  const handleSearchKeyDown = async (e) => {
    if (e.key === 'Enter') {
      await search();
    }
  }

  // 바이어 리스트 가져오기
  const getBuyers = (_keyword) => {
    return new Promise(async (resolve, reject) => {
      if (_keyword === undefined) _keyword = keyword;
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("user/buyer/gets", {keyword: _keyword, paging: 1, currPage});
      if (pageInfo) setPageInfo(pageInfo);
      setBuyers(result);
      document.getElementById('scroll_div').scrollTop = 0;
      return resolve(true);
    });
  };

  return (
    <AdminCard>
      <section style={{height: "97%"}}>
        <div
          className="bg-white my-4 shadow2 box_round_4_paddingNo p-3"
          style={{height: "98%"}}
        >
          <div className="d-flex justify-content-between border-bottom">
            <p className="h4 bold p-2">바이어 현황</p>
            <p className="m-1 mb-2"></p>
          </div>
          <div className="p-4">
            <article className="d-flex w-100 border">
              <ul className="w-25 p_rative p-3 pt-4 bg-lpurple border-right mb-0">
                <p className="bold mb-1">
                  검색{" "}
                  <span className="txt-sm normal">
                    - 회사명(바이어명) 또는 키워드
                  </span>
                </p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="회사명(바이어명) 또는 키워드"
                  onChange={async (e) => await setKeyword(e.target.value)}
                  value={keyword}
                  onKeyDown={handleSearchKeyDown}
                />
              </ul>
              <dl className="w-25 p-2 py-3 mb-0">
                <dd className="text-left">
                  <button
                    type="button"
                    className="btn btn-sm btn-purple m-1"
                    style={{width: "100px"}}
                    onClick={() => search()}
                  >
                    조회
                  </button>
                  <br/>
                  <button
                    type="button"
                    className="btn btn-sm btn-dark m-1"
                    style={{width: "100px"}}
                    onClick={async () => {
                      setKeyword('');
                      await search('');
                    }}
                  >
                    검색 초기화
                  </button>
                </dd>
              </dl>
            </article>
          </div>

          <div className="d-flex justify-content-between border-bottom py-3 mx-3">
            <p className="bold">검색 : 전체 </p>
            <p className="bold h6">총 {pageInfo?.totalArticles}건</p>
          </div>
          <div
            className="px-4 wid_scroll scroll_y custom_scroll mt-3"
            id="scroll_div"
            style={{height: "calc(100% - 300px)"}}
          >
            {buyers && buyers.map((buyer, key) => (
              <article className="border d-flex justify-content-between">
                <dl className="p_rative p-3 mb-0 border-right w-25">
                  <dd>
                    <div className="d-flex justify-content-center mt-2 mb-2">
                      <p
                        className="btn_oval_lg mb-1"
                        style={{marginRight: "15px"}}
                      >
                        {buyer.rep_image_fid && buyer.rep_image_fid.length > 0 ?
                          <img
                            src={`${Constants.API_HOST}/common/download?fid=${buyer.rep_image_fid}&cd=inline&thumb=400`}
                            style={{width: "65px", height: "65px", objectFit: "cover"}}
                          />
                          :
                          <div style={{width: "65px", height: "65px", backgroundColor: "#efefef", borderRadius: "65px"}}></div>
                        }
                      </p>
                      <div className="text-start" style={{maxWidth: "200px"}}>
                        <p className="h6">{buyer && (buyer?.company?.length > 0 ? buyer.company : (buyer?.first_name + " " + buyer?.middle_name + " " + buyer?.last_name))}</p>
                        <p>국적 : {buyer.country?.name_kr}</p>
                        <p style={{width: "200px"}}>{buyer?.introduce.length > 0 ? buyer.introduce : "등록된 한줄 소개가 없습니다."}</p>
                      </div>
                    </div>
                  </dd>
                </dl>
                <dl className="p_rative p-3 mb-0 border-right w-25">
                  <dd>
                    <p>
                      회원구분 : <span className="h6">{buyer?.user?.grade?.name_kr}</span>
                    </p>
                    {/*<p>*/}
                    {/*  사업자 인증 : <span className="h6">인증됨</span>*/}
                    {/*</p>*/}
                    {/*<p>*/}
                    {/*  등급 : <span className="h6">1 등급</span>*/}
                    {/*</p>*/}
                    <p>
                      가입일 : <span className="h6">{moment(buyer.created_at).format("YYYY.MM.DD")}</span>
                    </p>
                  </dd>
                </dl>
                <dl className="p_rative p-3 mb-0 border-right w-25">
                  <dt className="mb-2">
                    <p className="h6 bold">선호하는 상품 분야</p>
                  </dt>

                  <dd className=" d-flex flex-wrap">
                    {buyer.favorites.length > 0 ? buyer.favorites.map((item, key) => (
                      <div className="Hash m-1" style={{height: "30px"}}>
                        <p className="p-1">{item.name_kr}</p>
                      </div>
                    )) : " 선호하는 상품 분야가 없습니다."}
                  </dd>
                </dl>
                {/*<dl className="p_rative p-3 mb-0 border-right w-19">*/}
                {/*  <dt className="mb-2">*/}
                {/*    <p className="h6 bold">프로젝트</p>*/}
                {/*  </dt>*/}

                {/*  <dd className="px-3" style={{textAlign: "right"}}>*/}
                {/*    <p>*/}
                {/*      진행 <span className="h3">5건</span>*/}
                {/*    </p>*/}
                {/*  </dd>*/}
                {/*  <dd className="px-3" style={{textAlign: "right"}}>*/}
                {/*    <p>*/}
                {/*      완료 <span className="h3">3건</span>*/}
                {/*    </p>*/}
                {/*  </dd>*/}
                {/*</dl>*/}
                <dl className="p-2 py-3 mb-0 bg-lblue w-25 text-center">
                  {/*<button*/}
                  {/*  type="button"*/}
                  {/*  className="w-48 btn btn-sm btn-purple m-1"*/}
                  {/*  style={{minWidth: "115px"}}*/}
                  {/*>*/}
                  {/*  관리*/}
                  {/*</button>*/}
                  <br/>
                  <br/>
                  <Link
                    key={key}
                    to={{
                      pathname: `/admin/buyer/list`,
                      state: {
                        user_id: buyer.user_id
                      }
                    }}>
                    <button
                      type="button"
                      className="w-48 btn btn-sm btn-dark m-1"
                      style={{minWidth: "115px"}}
                    >
                      상세 확인
                    </button>
                  </Link>
                </dl>
              </article>
            ))}
            <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          </div>
        </div>
      </section>
    </AdminCard>
  );
};

export default AdminBuyerMain;
