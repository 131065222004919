import React, {useEffect, useState} from "react";
import APIHelper from "../../helpers/APIHelper";
import Constants from "../../constants/constants";
import CommonLib from "../../libraries/CommonLib";

const OemDetail = (props) => {
  const id = props?.location?.state?.id || props?.match?.params?.id;
  const [oemStory, setOemStory] = useState({});

  const get = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("data/oemstory/get", {id});
      setOemStory(result);
      return resolve(true);
    });
  };

  useEffect(() => {
    const fetch = async () => {
      await get();
    };
    fetch();
  }, []);

  return (
    <div className="my-4">
      <div className="mt-3 justify-content-center align-items-center w-100 d-flex flex-column">
        <section
          className="box_round min_h"
          style={{width: "calc(50% - 10px)", minWidth: "1280px"}}
        >
          <article className="p-4 pb-0">
            <div className="d-flex border-bottom py-2">
              <p>
                OEM 제작정보
                <br/>
                <span className="h4 txt-point bold">{oemStory?.name}</span>
              </p>

              {/*2023.08.08*/}
              <p className="mt-4 mx-3">
                <span
                  className="box_round_30 bg-lpurple p-1 px-3"
                  style={{marginRight: "3px"}}
                >
                  {/*{oemStory?.category?.name_kr}*/}
                  {oemStory?.category?.name_en}
                </span>
                <span className="box_round_30 bg-lpurple p-1 px-3">
                  {/*{oemStory?.product?.name_kr}*/}
                  {oemStory?.product?.name_en}
                </span>
              </p>

              {/*<p className="mt-4 ms-auto">*/}
              {/*  <button type="button" className="btn btn-sm btn_outline_puple">*/}
              {/*    상세*/}
              {/*  </button>*/}
              {/*</p>*/}
            </div>

            {/*2023.08.08*/}
            <div className="row my-4">
              {/*<div className="col-7">*/}
              <div className="col-12">
                <table className="table basic_sm">
                  <colgroup>
                    <col width="30%"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th className="center">판매국가</th>
                    <td>{oemStory?.sales_country}</td>
                  </tr>
                  <tr>
                    <th className="center">타겟층</th>
                    <td>{oemStory?.target}</td>
                  </tr>
                  <tr>
                    <th className="center">MOQ</th>
                    <td>{oemStory?.moq}</td>
                  </tr>
                  <tr>
                    <th className="center">Unit Price</th>
                    <td>{oemStory?.unit_price}</td>
                  </tr>
                  <tr>
                    <th className="center" style={{height: "120px"}}>
                      제품내용
                    </th>
                    <td style={{lineHeight: "130%"}}>
                      {CommonLib.escapedNewLineToLineBreakTag(
                        oemStory?.content
                      )}
                    </td>
                  </tr>
                </table>
              </div>
              {/*<div className="col-5 p_rative">*/}
              {/*  <div className="border-bottom">*/}
              {/*    <div className="thum_buyer">*/}
              {/*      {oemStory?.maker?.rep_image_fid &&*/}
              {/*      oemStory?.maker?.rep_image_fid.length > 0 ? (*/}
              {/*        <img*/}
              {/*          src={`${Constants.API_HOST}/common/download?fid=${oemStory?.maker.rep_image_fid}&cd=inline&thumb=400`}*/}
              {/*          style={{width: "492px", height: "120px", objectFit: "cover"}}*/}
              {/*        />*/}
              {/*      ) : (*/}
              {/*        <div*/}
              {/*          style={{*/}
              {/*            width: "492px",*/}
              {/*            height: "120px",*/}
              {/*            backgroundColor: "#efefef",*/}
              {/*          }}*/}
              {/*        />*/}
              {/*      )}*/}
              {/*    </div>*/}
              {/*    <div className="px-3 py-2" style={{maxHeight: "170px"}}>*/}
              {/*      <p className="h5 bold mt-1">*/}
              {/*        {oemStory?.maker?.company_name}*/}
              {/*      </p>*/}
              {/*      <p>*/}
              {/*        <span className="txt-sm">*/}
              {/*          {oemStory?.maker?.introduce}*/}
              {/*        </span>*/}
              {/*        <div style={{marginTop: "5px"}}>*/}
              {/*          인증서 :{" "}*/}
              {/*          {oemStory?.maker?.certs.map((item, k) => (*/}
              {/*            <span*/}
              {/*              className="box_round_20 border bg-lgray p-0 px-3 txt-sm"*/}
              {/*              style={{marginRight: "3px"}}*/}
              {/*            >*/}
              {/*              {item.name_kr}*/}
              {/*            </span>*/}
              {/*          ))}*/}
              {/*        </div>*/}
              {/*      </p>*/}
              {/*      <div className="my-3">*/}
              {/*        {oemStory?.maker?.mf_items.map((item, k) => (*/}
              {/*          <span*/}
              {/*            className="box_round_30 border bg-lgray p-1 px-3 txt-sm"*/}
              {/*            style={{marginRight: "3px"}}*/}
              {/*          >*/}
              {/*            {item.name_kr}*/}
              {/*          </span>*/}
              {/*        ))}*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </article>
          <div className="p_rative pb-4">
            <p className="p-4 pt-0">
              {oemStory?.rep_image_fid && oemStory?.rep_image_fid.length > 0 ? (
                <img
                  src={`${Constants.API_HOST}/common/download?fid=${oemStory?.rep_image_fid}&cd=inline&thumb=1000`}
                  style={{
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <img
                  src={process.env.PUBLIC_URL + "/images/no_image.png"}
                  style={{width: "1210px", height: "1376px", objectFit: "cover"}}
                />
              )}
            </p>

            <div className="row row-cols-5 g-4 px-4">
              <p style={{padding: "0"}}>
                {oemStory?.image1_fid && oemStory?.image1_fid.length > 0 ? (
                  <img
                    src={`${Constants.API_HOST}/common/download?fid=${oemStory?.image1_fid}&cd=inline&thumb=1000`}
                    style={{
                      height: "186px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + "/images/no_image.png"}
                    style={{width: "224px", height: "186px", objectFit: "cover"}}
                  />
                )}
              </p>
              <p style={{padding: "0"}}>
                {oemStory?.image2_fid && oemStory?.image2_fid.length > 0 ? (
                  <img
                    src={`${Constants.API_HOST}/common/download?fid=${oemStory?.image2_fid}&cd=inline&thumb=1000`}
                    style={{
                      height: "186px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + "/images/no_image.png"}
                    style={{width: "224px", height: "186px", objectFit: "cover"}}
                  />
                )}
              </p>
              <p style={{padding: "0"}}>
                {oemStory?.image3_fid && oemStory?.image3_fid.length > 0 ? (
                  <img
                    src={`${Constants.API_HOST}/common/download?fid=${oemStory?.image3_fid}&cd=inline&thumb=1000`}
                    style={{
                      height: "186px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + "/images/no_image.png"}
                    style={{width: "224px", height: "186px", objectFit: "cover"}}
                  />
                )}
              </p>
              <p style={{padding: "0"}}>
                {oemStory?.image4_fid && oemStory?.image4_fid.length > 0 ? (
                  <img
                    src={`${Constants.API_HOST}/common/download?fid=${oemStory?.image4_fid}&cd=inline&thumb=1000`}
                    style={{
                      height: "186px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + "/images/no_image.png"}
                    style={{width: "224px", height: "186px", objectFit: "cover"}}
                  />
                )}
              </p>
              <p style={{padding: "0"}}>
                {oemStory?.image5_fid && oemStory?.image5_fid.length > 0 ? (
                  <img
                    src={`${Constants.API_HOST}/common/download?fid=${oemStory?.image5_fid}&cd=inline&thumb=1000`}
                    style={{
                      height: "186px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + "/images/no_image.png"}
                    style={{width: "224px", height: "186px", objectFit: "cover"}}
                  />
                )}
              </p>
            </div>
          </div>
        </section>
        <section
          className="box_round min_h px-4 mt-3"
          style={{width: "calc(50% - 10px)", minWidth: "1280px"}}
        >
          <article className="py-4">
            <div className="d-flex border-bottom py-2">
              <p className="h5 bold mt-1">OEM 제작정보</p>

              {/*<p className="ms-auto">*/}
              {/*  <button type="button" className="btn">*/}
              {/*    <img src={close_b} style={{width: "25px"}}/>*/}
              {/*  </button>*/}
              {/*</p>*/}
            </div>
            {/*<div className="row row-cols-2 g-3 mt-3">*/}
            {/*  <p>*/}
            {/*    <img src={box_img06}/>*/}
            {/*  </p>*/}
            {/*  <p>*/}
            {/*    <img src={box_img07}/>*/}
            {/*  </p>*/}
            {/*  <p>*/}
            {/*    <img src={box_img05}/>*/}
            {/*  </p>*/}
            {/*  <p>*/}
            {/*    <img src={box_img08}/>*/}
            {/*  </p>*/}
            {/*</div>*/}
          </article>
          <article>
            {/*2023.08.08*/}
            <table className="table basic_sm w-100">
              <colgroup>
                <col width="26%"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th className="center txt-sm">제품 분류</th>
                <td>
                  {/*{oemStory?.category?.name_kr} / {oemStory?.product?.name_kr}*/}
                  {oemStory?.category?.name_en} / {oemStory?.product?.name_en}
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">
                  {/*용량*/}
                  {/*<br/>*/}
                  Volume
                </th>
                <td>{oemStory?.volume}</td>
              </tr>
              <tr>
                <th className="center txt-sm">
                  {/*제형/타겟 제품*/}
                  {/*<br/>*/}
                  Target item
                </th>
                <td>{oemStory?.target_item}</td>
              </tr>
              <tr>
                <th className="center txt-sm">
                  {/*향 / 색<br/>*/}
                  Scent / Color
                </th>
                <td>{oemStory?.scent}</td>
              </tr>
              <tr>
                <th className="center txt-sm">
                  {/*제품 컨셉*/}
                  {/*<br/>*/}
                  Concept of line
                </th>
                <td>
                  {CommonLib.escapedNewLineToLineBreakTag(oemStory?.concept)}
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">
                  {/*첨가 요청 성분*/}
                  {/*<br/>*/}
                  Additional requesting ingredients
                </th>
                <td>
                  {CommonLib.escapedNewLineToLineBreakTag(
                    oemStory?.add_ingredients
                  )}
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">
                  {/*무첨가 요청 성분*/}
                  {/*<br/>*/}
                  Blacklist Ingredients
                  <br/>
                  Not to Be Added{" "}
                </th>
                <td>
                  {CommonLib.escapedNewLineToLineBreakTag(
                    oemStory?.blacklist_ingredients
                  )}
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">
                  {/*그 외 개발 유의 사항*/}
                  {/*<br/>*/}
                  Extra Specific requests
                </th>
                <td>
                  {CommonLib.escapedNewLineToLineBreakTag(
                    oemStory?.ex_requests
                  )}
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">Unit Price</th>
                <td>
                  {CommonLib.escapedNewLineToLineBreakTag(oemStory?.unit_price)}
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">
                  {/*최소 발주 수량*/}
                  {/*<br/>*/}
                  Minimum order quantity
                </th>
                <td>{CommonLib.escapedNewLineToLineBreakTag(oemStory?.moq)}</td>
              </tr>
            </table>
            {/*<div className="d-flex justify-content-between py-3 mb-5">*/}
            {/*  <p></p>*/}
            {/*  <p>*/}
            {/*    <button className="btn btn_puple" type="button">*/}
            {/*      OEM 제작 문의*/}
            {/*    </button>*/}
            {/*  </p>*/}
            {/*</div>*/}
          </article>
        </section>
      </div>
    </div>
  );
};

export default OemDetail;
