import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../../sass/main.scss";
import moment from "moment";
import CommonLib from "../../../libraries/CommonLib";
import Constants from "../../../constants/constants";
import {useDispatch} from "react-redux";
import openRequestFormulation from "../../../actions/openRequestFormulation";
import openRequestDesign from "../../../actions/openRequestDesign";
import openRequestBottle from "../../../actions/openRequestBottle";
import openRequestBox from "../../../actions/openRequestBox";
import APIHelper from "../../../helpers/APIHelper";
import ChannelService from "../../../helpers/ChannelService";

const ProjectCard = (props) => {
  const id = props?.id;
  const path = window.location.pathname;

  // modal액션으로 카테고리(단상자, 디자인...) 보내기
  const dispatch = useDispatch();
  const history = useHistory();

  const openRequestForm = (projectId, projectProductId, itemCd) => {
    const obj = {projectId, projectProductId, renew: new Date().getTime(), readOnly: true};
    if (itemCd === 'MF') dispatch(openRequestFormulation("open", obj));
    else if (itemCd === 'DS') dispatch(openRequestDesign("open", obj));
    else if (itemCd === 'BT') dispatch(openRequestBottle("open", obj));
    else if (itemCd === 'BX') dispatch(openRequestBox("open", obj));
  }

  const [request, setRequest] = useState({});
  const [project, setProject] = useState({});

  useEffect(() => {
    const fetch = async () => {
      await getRequest();
    }
    fetch();
  }, []);

  const getRequest = () => {
    return new Promise(async (resolve, reject) => {
      if (id) {
        const {data: {code, result, message}} = await APIHelper.getForm("project/maker/request/get", {id});
        if (code === 200) {
          setRequest(result);
          await getProject(result?.project_id);
        }
      }
      return resolve(true);
    });
  }

  const getProject = (projectId) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("project/get", {id: projectId});
      if (code === 200) {
        setProject(result);
      }
      return resolve(true);
    });
  }

  const openBot = () => {
    ChannelService.openBot();
  }


  return (
    <div className="BODY MAX my-4">
      <div
        className="mt-3 d-flex justify-content-between w-100"
        style={{height: "100%"}}
      >
        <div className="box_round left_menu">
          {id > 0 &&
            <div className="p_absolute project_dash" style={{left: "15px", top: "unset", bottom: "15px", borderBottomLeftRadius: "0px", borderTopLeftRadius: "0px", height: "auto"}}>
              <div className="bg-lpurple p-3 py-4">
                <div className="txt-sm txt-gray">
                  요청 No
                  <p className="h4 bold txt-point py-2">
                    {request?.req_no}
                  </p>
                  <p style={{marginTop: "5px"}}>제작 의뢰 요청일 : {moment(request.created_at).format("YYYY.MM.DD")}</p>
                </div>
              </div>

              <div className="p-3 border-bottom p_rative" style={{minWidth: "208px"}}>
                <div className="m-1 p_rative">
                  <div className="thum_buyer_info p_absolute p-3">
                    <p
                      className="box_round_30 bg-white px-1 txt-sm mt-1"
                      style={{width: "53px"}}
                    >
                      바이어
                    </p>
                    <p className="h6 bold my-2">{project?.buyer?.user?.id > 0 ? [project?.buyer?.first_name, project?.buyer?.middle_name, project?.buyer?.last_name].join(' ').substring(0, 4) + "***" : '미배정'}</p>
                  </div>
                  <div className="thum_buyer">
                    {project?.buyer?.id > 0 && project?.buyer.rep_image_fid && project?.buyer.rep_image_fid.length > 0 ?
                      <img
                        src={`${Constants.API_HOST}/common/download?fid=${project?.buyer.rep_image_fid}&cd=inline&thumb=400`}
                        style={{width: "208px", height: "120px", objectFit: "cover"}}
                      />
                      :
                      <div style={{width: "208px", height: "120px", objectFit: "cover", backgroundColor: "#efefef"}}></div>
                    }
                  </div>
                </div>

                {project?.buyer?.id > 0 && project?.buyer.rep_image_fid && project?.buyer.rep_image_fid.length > 0 &&
                  <div className="w-100 d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-outline-dark txt-sm hov_pink px-1 w-100 mt-2"
                      onClick={() => history.push(`/buyer/home/${project?.buyer?.user_id}`)}
                    >
                      바이어 상세
                    </button>
                  </div>
                }
              </div>

              {/*<div className="p-3 py-4">*/}
              {/*<div className="thum_manager" style={{margin: "0 auto"}}>*/}
              {/*  <button type="button" className="btn btn_go p-0 px-2">*/}
              {/*    <img*/}
              {/*      src={arrow_move}*/}
              {/*      style={{width: "12px", paddingBottom: "5px"}}*/}
              {/*    />*/}
              {/*  </button>*/}
              {/*  <img src={thum_manager}/>*/}
              {/*</div>*/}
              {/*<div className="d-flex justify-content-between mt-4">*/}
              {/*  <p className="bg-purple px-2">담당매니저</p>*/}
              {/*  <p>SH******</p>*/}
              {/*</div>*/}

              {/*<div*/}
              {/*  className="btn-group w-100 mt-4"*/}
              {/*  role="group"*/}
              {/*  aria-label="Basic outlined example"*/}
              {/*>*/}
              {/*<button*/}
              {/*  type="button"*/}
              {/*  className="btn btn-outline-light txt-sm hov_pink px-1"*/}
              {/*>*/}
              {/*  1:1문의*/}
              {/*</button>*/}
              {/*<button*/}
              {/*  type="button"*/}
              {/*  className="btn btn-outline-light txt-sm hov_pink px-1"*/}
              {/*  onClick={() => openBot()}*/}
              {/*>*/}
              {/*  채팅 문의*/}
              {/*</button>*/}
              {/*</div>*/}
              {/*</div>*/}
            </div>
          }

          <div className="left_menu_title">작업(Task) 관리</div>
          <ul className="left_menu_list p-0">
            <li
              className={`${path.includes("project/maker/home") ? "on" : ""}`}
            >
              <Link to="/project/maker/home">작업(Task) 홈</Link>
            </li>

            <li
              className={`${
                path.includes("/project/maker/request") ? "on" : ""
              }`}
            >
              <Link to="/project/maker/request">제작 의뢰</Link>
            </li>
            <li className={`${path.includes("progress") ? "on" : ""}`}>
              <Link to="/project/maker/task/progress">진행중 작업(Task)</Link>
            </li>
            <li className={`${path.includes("done") ? "on" : ""}`}>
              <Link to="/project/maker/task/done">완료된 작업(Task)</Link>
            </li>
          </ul>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default ProjectCard;
