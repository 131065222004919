import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {bindActionCreators} from "redux";
import openTimelineRequest from "../../actions/openTimelineRequest";
import "../../sass/main.scss";
import open_in_new from "../../image/open_in_new.svg";
import moment from "moment/moment";
import info_comp_01 from "../../image/info_comp_01.png";
import openTimelineAttach from "../../actions/openTimelineAttach";
import APIHelper from "../../helpers/APIHelper";
import CommonLib from "../../libraries/CommonLib";
import {Link, useHistory} from "react-router-dom";

const TimelineRequestModal = (props) => {
  const history = useHistory();
  const closeModal = () => {
    props.openTimelineRequest("closed", "");
  };

  let modalInlineStyle;

  if (props.timelineRequestPop.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }

  const projectId = props?.timelineRequestPop?.content?.projectId;

  const dispatch = useDispatch();
  const [readyRequests, setReadyRequests] = useState([]);
  const [acceptedRequests, setAcceptedRequests] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const result = await load();
    }
    fetch();
  }, [projectId]);

  const load = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("project/maker/request/gets", {projectId, mode: "admin"});
      setReadyRequests(result?.filter(i => i.status_cd !== 'Y') || []);
      setAcceptedRequests(result?.filter(i => i.status_cd === 'Y') || []);
      return resolve(true);
    });
  };

  const cancel = async (id) => {
    if (!await CommonLib.jconfirm('취소하시겠습니까?')) return;

    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.putForm("project/maker/request/cancel", {projectId, id});
      await load();
      return resolve(true);
    });
  };

  return (
    <div style={{display: modalInlineStyle}}>
      <div
        className="pop_box04"
        style={{
          width: "950px",
          minHeight: "500px",
          height: "calc(100% - 30px)",
          maxHeight: "850px",
        }}
      >
        <div className="POP_title pb-3">
          <p className="closed">
            <button type="button" className="btn" onClick={closeModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </button>
          </p>
        </div>
        <div className="row row-cols-2 gx-4 mt-4 POP_title pb-3" style={{height: "calc(100% - 30px)"}}>
          <section className="col" style={{height: "98%"}}>
            <div
              className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
              style={{height: "100%"}}
            >
              <div className="d-flex justify-content-between border-bottom">
                <p className="h5 bold px-2">제조사 요청</p>
              </div>

              <div
                className="wid_scroll scroll_y custom_scroll mt-2"
                style={{height: "calc(100% - 50px)"}}
              >
                {readyRequests.map((request, key) => {
                    const requestType =
                      request.request_type === 'MF' ? '제형' :
                        request.request_type === 'BT' ? '용기' :
                          request.request_type === 'BX' ? '단상자' :
                            request.request_type === 'DS' ? '디자인' : '';

                    let area = request.area;
                    if (request.maker.company_addr.length > 0)
                      area = request.maker.company_addr.split(' ')[0];

                    const requestFormObj = {projectId: request.project_id, projectProductId: request.project_product_id, item_cd: request.request_type};

                    return (
                      <article key={key} className="border wid_inline my-2">
                        <div className="bg-lgray0 d-flex border-bottom p-2">
                          <p>
                            <img
                              src={open_in_new}
                              style={{width: "25px", marginBottom: "2px"}}
                            />{" "}
                            <span className="h6 bold">{requestType}</span>
                          </p>
                          <p className="px-3 txt-point bold">{request?.category?.name_kr} - {request?.product?.name_kr}</p>
                          <p className="mt-1 txt-sm txt-gray ms-auto">
                            <span className="txt-sm txt-dark bold">요청일 :</span>{" "}
                            {moment(request.created_at).format("YYYY.MM.DD")}
                          </p>
                        </div>

                        <div className="d-flex p-2">
                          <p className="btn_oval_sm mb-1 mx-1">
                            <img src={info_comp_01}/>
                          </p>
                          <div className="text-start mx-2">
                            <p className="h6 mb-2">업체명 : {request.maker.company_name}</p>
                            <p className="txt-sm">
                              위치 : {area}
                              <br/>
                              의뢰서번호 :{" "}
                              <span className="txt-point txt-sm">{request.request_form?.request_no}</span>
                              <br/>
                            </p>
                          </div>
                          <p className="ms-auto">
                            {request.status_cd === 'C' ?
                              <button
                                type="button"
                                className="btn w-48 btn-sm btn-dark p-1 my-1"
                                style={{minWidth: "85px", pointerEvents: "none"}}
                              >
                                취소됨
                              </button>
                              :
                              request.status_cd === 'N' ?
                                <button
                                  type="button"
                                  className="btn w-48 btn-sm btn-ligray p-1 my-1"
                                  style={{minWidth: "85px", backgroundColor: "#afafaf", pointerEvents: "none"}}
                                >
                                  거절됨
                                </button>
                                :
                                <button
                                  type="button"
                                  className="btn w-48 btn-sm btn-ligray p-1 my-1"
                                  style={{minWidth: "85px"}}
                                  onClick={() => cancel(request.id)}
                                >
                                  취소
                                </button>
                            }
                            <br/>
                            <Link to={`/admin/maker/timeline/${request.id}`}>
                              <button
                                onClick={closeModal}
                                type="button"
                                className="btn w-48 btn-sm btn-blue p-1 my-1"
                                style={{minWidth: "85px"}}
                              >
                                타임라인
                              </button>
                            </Link>

                          </p>
                        </div>
                      </article>
                    )
                  }
                )}
                {readyRequests.length === 0 &&
                  <div className="col w-100">
                    <div className="box_round_5 border my-2 d-flex p-3 justify-content-center">
                      요청 내역이 없습니다.
                    </div>
                  </div>
                }
              </div>
            </div>
          </section>
          <section className="col" style={{height: "100%"}}>
            <div
              className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
              style={{height: "98%"}}
            >
              <div className="d-flex justify-content-between border-bottom">
                <p className="h5 bold px-2">제조사 수락</p>
              </div>

              <div
                className="wid_scroll scroll_y custom_scroll mt-2"
                style={{height: "calc(100% - 60px)"}}
              >
                {acceptedRequests.map((request, key) => {
                    const requestType =
                      request.request_type === 'MF' ? '제형' :
                        request.request_type === 'BT' ? '용기' :
                          request.request_type === 'BX' ? '단상자' :
                            request.request_type === 'DS' ? '디자인' : '';

                    let area = request.area;
                    if (request.maker.company_addr.length > 0)
                      area = request.maker.company_addr.split(' ')[0];

                    return (
                      <article key={key} className="border wid_inline my-2">
                        <div className="bg-lgray0 d-flex border-bottom p-2">
                          <p>
                            <img
                              src={open_in_new}
                              style={{width: "25px", marginBottom: "2px"}}
                            />{" "}
                            <span className="h6 bold">{requestType}</span>
                          </p>
                          <p className="px-3 txt-point bold">{request?.category?.name_kr} - {request?.product?.name_kr}</p>
                          <p className="mt-1 txt-sm txt-gray ms-auto">
                            <span className="txt-sm txt-dark bold">요청일 :</span>{" "}
                            {moment(request.created_at).format("YYYY.MM.DD")}
                          </p>
                        </div>

                        <div className="d-flex p-2">
                          <p className="btn_oval_sm mb-1 mx-1">
                            <img src={info_comp_01}/>
                          </p>
                          <div className="text-start mx-2">
                            <p className="h6 mb-2">업체명 : {request.maker.company_name}</p>
                            <p className="txt-sm">
                              위치 : {area}
                              <br/>
                              의뢰서번호 :{" "}
                              <span className="txt-point txt-sm">{request.request_form?.request_no}</span>
                              <br/>
                            </p>
                          </div>
                          <p className="ms-auto">
                            <div style={{display: "flex", flexDirection: "row"}}>
                              <div>
                                <button
                                  type="button"
                                  className="btn w-48 btn-sm btn-ligray p-1 my-1"
                                  style={{minWidth: "85px"}}
                                  onClick={() => {
                                    const obj = {
                                      projectId: request.project_id,
                                      projectProductId: request.project_product_id,
                                      projectMakerRequestId: request.id,
                                      title: '간단 견적서 확인',
                                      renew: new Date().getTime(),
                                      readOnly: true,
                                      formType: '간단 견적서 작성'
                                    };
                                    dispatch(openTimelineAttach("open", obj));
                                  }}
                                >
                                  견적서
                                </button>
                                <br/>
                                <Link to={`/admin/maker/timeline/${request.id}`}>
                                  <button
                                    onClick={closeModal}
                                    type="button"
                                    className="btn w-48 btn-sm btn-blue p-1 my-1"
                                    style={{minWidth: "85px"}}
                                  >
                                    타임라인
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </p>
                        </div>
                      </article>
                    )
                  }
                )}
                {acceptedRequests.length === 0 &&
                  <div className="col w-100">
                    <div className="box_round_5 border my-2 d-flex p-3 justify-content-center">
                      수락된 요청이 없습니다.
                    </div>
                  </div>
                }
              </div>
            </div>
          </section>
        </div>
        <div className="POP_cnt_box_lg" style={{marginBottom: "95px"}}>
        </div>
      </div>
      <div className="pop_bak" onClick={closeModal}></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    timelineRequestPop: state.timelineRequestPop,
  };
}

function mapDispatchToProps(dispatcher) {
  return bindActionCreators(
    {
      openTimelineRequest: openTimelineRequest,
    },
    dispatcher
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TimelineRequestModal);
