import React, {useEffect, useState} from "react";
import cancel_p from "../../../image/cancel_p.svg";
import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";

const AdminMakerManagement = (props) => {
  const makerState = props?.maker;
  const [maker, setMaker] = useState(makerState);

  const [gradeCd, setGradeCd] = useState('');
  const [grades, setGrades] = useState([]);

  const [licenseCertifiedYn, setLicenseCertifiedYn] = useState('N');

  useEffect(() => {
    const fetch = async () => {
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '151000'});
        if (code === 200) {
          setGrades(result);
        }
      }
      await getProfile();
    }
    fetch();
  }, [props.renew]);

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "제조사관리 > 제조사 관리 > 관리",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);


  // 제조사 프로필 가져오기
  const getProfile = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("user/maker/get", {id: makerState?.user_id});
      if (code === 200) {
        setMaker(result);
        setGradeCd(result?.user?.grade_cd);
        setLicenseCertifiedYn(result?.license_certified_yn);
      }
      return resolve(true);
    });
  };

  const updateSubmit = (e) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {data: {code: retCode, result, message}} = await APIHelper.putForm(`user/maker/update/management`, {
          id: maker?.user?.id,
          gradeCd: gradeCd || grades?.[0]?.cd,
          licenseCertifiedYn: licenseCertifiedYn || 'N',
        });
        await CommonLib.jalert(message);
        try {
          await APIHelper.postForm("admin/action/log", {
            content: "제조사관리 > 제조사 관리 > 관리 > 저장하기",
            param1 : maker?.user_id,
            param2 : gradeCd || grades?.[0]?.cd,
            param3 : licenseCertifiedYn || 'N',
          });
        } catch (err) {
          console.log(err);
        }
      } catch (err) {
        console.error(err);
      }
      return resolve(true);
    });
  };

  // open&close 버튼
  const [showAdd, setShowAdd] = useState(false);
  // TAG 종류
  const TAG_DATA = [
    "Hair",
    "Mask",
    "Mist",
    "Ample",
    "Baby",
    "Lotion",
    "Toner",
    "SunCare",
    "Body",
  ];

  // 사용자가 선택한 Tag State
  const [tagState, setTagState] = useState(["Hair", "Mask"]);

  // Tag 핸들러
  const clickTag = (e) => {
    if (tagState.includes(e)) {
      setTagState(tagState.filter((element) => element !== e));
    } else setTagState([...tagState, e]);
  };

  // 사용자가 선택한 Tag 보여주기
  const tage = tagState.map((item, i) => {
    return (
      <p className="box_round_4_paddingNo bg-lpurple px-2 p-1 m-2">
        <span className="px-2">{item}</span>{" "}
        <button
          onClick={() => clickTag(item)}
          type="button"
          className="btn btn-sm p-0"
        >
          <img src={cancel_p} style={{paddingBottom: "2px"}}/>
        </button>
      </p>
    );
  });

  // 사용자가 선택하지 않은 Tag들
  const canTagAdd = TAG_DATA.filter((item) => !tagState.includes(item));

  // 선택 가능한 Tag 보여주기
  const addTag = canTagAdd.map((item, i) => {
    return (
      <p
        onClick={() => clickTag(item)}
        style={{background: "#f9edf8", color: "#ae6faf", cursor: "pointer"}}
        className="box_round_4_paddingNo px-2 p-1 m-2"
      >
        <span style={{paddingLeft: "0.5rem"}}>{item}</span>{" "}
        <button type="button" className="btn btn-sm p-0"></button>
      </p>
    );
  });

  return (
    <>
      <div
        className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
        style={{height: "100%"}}
      >
        <div className="d-flex justify-content-between border-bottom">
          <p className="h5 bold p-2">관리</p>
          <p className="m-1 mb-2">
            <button type="button" className="btn btn-dark" onClick={async (e) => await updateSubmit(e)}>
              저장
            </button>
          </p>
        </div>

        <div
          className="wid_scroll scroll_y custom_scroll mt-3"
          style={{height: "calc(100% - 60px)"}}
        >
          <div className="wid_inline">
            <table className="table basic w-100">
              <colgroup>
                <col width="28%"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th className="center">회원구분</th>
                <td className="border-top">
                  <select
                    className="form-select form-select-sm w-50"
                    aria-label="Default select example"
                    onChange={async (e) => await setGradeCd(e.target.value)}
                    value={gradeCd}
                  >
                    {grades && grades.map((item, i) => {
                      return (
                        <option key={i} selected={gradeCd === item.cd} value={item.cd}>{item.name_kr}</option>
                      );
                    })}
                  </select>
                  <ul className="px-1 mt-2 ">
                    <li className="txt-sm">일반회원 : 회원가입시 (미승인상태)</li>
                    <li className="txt-sm">임시회원 : 임시 데이터 등록시</li>
                    <li className="txt-sm">정회원 : 매니저 검토 완료 후 변경</li>
                    <li className="txt-sm">유료회원 : 향후사용</li>
                    <li className="txt-sm">
                      ※ 정회원이면 매니저 인증 인증마크 표현 됨
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th className="center">사업자 인증</th>
                <td className="border-top">
                  <select
                    className="form-select form-select-sm w-50"
                    aria-label="Default select example"
                    onChange={async (e) => await setLicenseCertifiedYn(e.target.value)}
                    value={licenseCertifiedYn}
                  >
                    <option selected={licenseCertifiedYn === 'N'} value="N">미인증</option>
                    <option selected={licenseCertifiedYn === 'Y'} value="Y">인증됨</option>
                  </select>
                  <p className="txt-sm">
                    사업자 등록증 확인 및 입력 정보 확인이 되면 변경
                  </p>
                </td>
              </tr>
              {/*<tr>*/}
              {/*  <th className="center">제조사 회원 등급</th>*/}
              {/*  <td>*/}
              {/*    <select*/}
              {/*      className="form-select form-select-sm w-50"*/}
              {/*      aria-label="Default select example"*/}
              {/*    >*/}
              {/*      <option selected>1 등급</option>*/}
              {/*      <option value="1">One</option>*/}
              {/*      <option value="2">Two</option>*/}
              {/*      <option value="3">Three</option>*/}
              {/*    </select>*/}
              {/*    <p className="txt-sm">1등급(기본) ~ 5등급(높음)</p>*/}
              {/*  </td>*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <th className="center">검색 노출 가중치</th>*/}
              {/*  <td>*/}
              {/*    <select*/}
              {/*      className="form-select form-select-sm w-50"*/}
              {/*      aria-label="Default select example"*/}
              {/*    >*/}
              {/*      <option selected>80</option>*/}
              {/*      <option value="1">One</option>*/}
              {/*      <option value="2">Two</option>*/}
              {/*      <option value="3">Three</option>*/}
              {/*    </select>*/}
              {/*    <p className="txt-sm">1(기본) ~ 100(높음)</p>*/}
              {/*  </td>*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <th className="center">노출 유무</th>*/}
              {/*  <td>*/}
              {/*    <select*/}
              {/*      className="form-select form-select-sm w-50"*/}
              {/*      aria-label="Default select example"*/}
              {/*    >*/}
              {/*      <option selected>노출함</option>*/}
              {/*      <option value="1">One</option>*/}
              {/*      <option value="2">Two</option>*/}
              {/*      <option value="3">Three</option>*/}
              {/*    </select>*/}
              {/*    <p className="txt-sm">서비스쪽 제조사 찾기에 노출 유무 결정</p>*/}
              {/*  </td>*/}
              {/*</tr>*/}
            </table>

            {/*<div className="d-flex justify-content-between border-bottom pb-2 mt-4">*/}
            {/*  <p className="txt-md">제조사 관리용 태그</p>*/}
            {/*  <p>*/}
            {/*    <button*/}
            {/*      onClick={() => setShowAdd(!showAdd)}*/}
            {/*      type="button"*/}
            {/*      className="btn btn-sm btn_outline_puple px-3"*/}
            {/*    >*/}
            {/*      {showAdd ? "Close" : "Add Tag"}*/}
            {/*    </button>*/}
            {/*  </p>*/}
            {/*</div>*/}
            {/*{showAdd && (*/}
            {/*  <div className="d-flex flex-wrap border-bottom p-3">*/}
            {/*    {addTag}*/}
            {/*  </div>*/}
            {/*)}*/}
            {/*<div className="d-flex flex-wrap border-bottom p-3">*/}
            {/*  {tagState.length > 0 ? tage : <p>TAG를 추가해주세요</p>}*/}
            {/*</div>*/}
            {/*<p>※ 최대 10개 | 관리 목적의 제조사용 태그 등록</p>*/}
            {/*<div className="d-flex justify-content-between border-bottom pb-2 mt-4">*/}
            {/*  <p className="txt-md">매니저 Comment</p>*/}
            {/*  <p></p>*/}
            {/*</div>*/}
            {/*<table className="table basic w-100">*/}
            {/*  <tr>*/}
            {/*    <th className="center">*/}
            {/*        <textarea*/}
            {/*          className="form-control"*/}
            {/*          id="exampleFormControlTextarea1"*/}
            {/*          rows="5"*/}
            {/*        ></textarea>*/}
            {/*    </th>*/}
            {/*  </tr>*/}
            {/*</table>*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminMakerManagement;
