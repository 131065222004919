import React, {useEffect, useState} from "react";
import BuyerProjectCard from "./BuyerProjectCard";
import arrowright from "../../../image/arrow-right.svg";
import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import {useHistory} from "react-router-dom";

const BuyerProjectHome = (props) => {
  const [statuses, setStatuses] = useState({});
  const history = useHistory();

  useEffect(() => {
    const fetch = async () => {
      await getStatuses();
    }
    fetch();
  }, []);

  const getStatuses = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.get("project/statuses/buyer/me");
      if (code === 200) {
        setStatuses(result);
      }
      return resolve(true);
    });
  };

  return (
    <BuyerProjectCard>
      <div className="box_round min_h cnt_area pb-5">
        <div className="mx-5 my-4 pb-5">
          <p className="h3 bold border-bottom py-3">프로젝트 현황</p>
          <br />

          <div className="row justify-content-between">
            <div className="col">
              <div className="box_round bg-lblue p-3 text-center">
                <p
                  className="mt-2 box_round_sm bg-blue"
                  style={{ width: "135px", height: "28px", margin: "0 auto" }}
                >
                  신규 프로젝트
                </p>
                <p className="px-3">
                  <span className="display-4 bold">{statuses.new}</span>{" "}
                  <span className="h2 bold">건</span>
                </p>
              </div>
            </div>
            <div className="col">
              <div className="box_round bg-lred p-3 text-center" onClick={() => history.push('/project/buyer/task/progress')} style={{cursor: "pointer"}}>
                <p
                  className="mt-2 box_round_sm bg-red"
                  style={{ width: "135px", height: "28px", margin: "0 auto" }}
                >
                  진행중 프로젝트
                </p>
                <p className="px-3">
                  <span className="display-4 bold">{statuses.progress}</span>{" "}
                  <span className="h2 bold">건</span>
                </p>
              </div>
            </div>
            <div className="col ">
              <div className="box_round bg-lpurple p-3 text-center" onClick={() => history.push('/project/buyer/task/done')} style={{cursor: "pointer"}}>
                <p
                  className="mt-2 box_round_sm bg-purple2"
                  style={{ width: "135px", height: "28px", margin: "0 auto" }}
                >
                  완료 프로젝트
                </p>
                <p className="px-3">
                  <span className="display-4 bold">{statuses.done}</span>{" "}
                  <span className="h2 bold">건</span>
                </p>
              </div>
            </div>
          </div>
          <div
            className="my-5 px-3"
            style={{ borderLeft: "5px solid #615197" }}
          >
            <p className="h5 bold txt-purple2">
              궁금한점은 프로젝트 담당 매니저에 물어보세요.
            </p>
            <p>
              프로젝트 내부에 담당 매니저 정보가 있습니다.
              <br />
              채팅으로 상담이 가능 합니다.
            </p>
          </div>
          {/*<div className="d-flex justify-content-between mt-5 mb-4 pb-1">*/}
          {/*  <p className="txt-md">프로젝트 단계에 대한 설명 (흐름)</p>*/}
          {/*  <p></p>*/}
          {/*</div>*/}

          {/*<div className="border" style={{ minHeight: "450px" }}>*/}
          {/*  <div*/}
          {/*    className="Step_box"*/}
          {/*    style={{*/}
          {/*      marginTop: "-10px",*/}
          {/*      borderBottomRightRadius: "0px",*/}
          {/*      borderBottomLeftRadius: "0px",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <div className="Step_off">*/}
          {/*      <span className="txt-ssm bold">STEP01</span>*/}
          {/*      <br />*/}
          {/*      단가조사*/}
          {/*      <p className="data">02</p>*/}
          {/*    </div>*/}
          {/*    <div className="py-3">*/}
          {/*      <img src={arrowright} style={{ width: "10px" }} />*/}
          {/*    </div>*/}
          {/*    <div className="Step_off">*/}
          {/*      <span className="txt-ssm bold">STEP02</span>*/}
          {/*      <br />*/}
          {/*      샘플/디자인*/}
          {/*      <p className="data">02</p>*/}
          {/*    </div>*/}
          {/*    <div className="py-3">*/}
          {/*      <img src={arrowright} style={{ width: "10px" }} />*/}
          {/*    </div>*/}
          {/*    <div className="Step_off">*/}
          {/*      <span className="txt-ssm bold">STEP03</span>*/}
          {/*      <br />*/}
          {/*      견적 및 계약*/}
          {/*      <p className="data">02</p>*/}
          {/*    </div>*/}
          {/*    <div className="py-3">*/}
          {/*      <img src={arrowright} style={{ width: "10px" }} />*/}
          {/*    </div>*/}
          {/*    <div className="Step_on">*/}
          {/*      <span className="txt-ssm bold">STEP04</span>*/}
          {/*      <br />*/}
          {/*      제품 제작*/}
          {/*      <p className="data">02</p>*/}
          {/*    </div>*/}
          {/*    <div className="py-3">*/}
          {/*      <img src={arrowright} style={{ width: "10px" }} />*/}
          {/*    </div>*/}
          {/*    <div className="Step_off">*/}
          {/*      <span className="txt-ssm bold">STEP05</span>*/}
          {/*      <br />*/}
          {/*      통관/선적*/}
          {/*      <p className="data">02</p>*/}
          {/*    </div>*/}
          {/*    <div className="py-3">*/}
          {/*      <img src={arrowright} style={{ width: "10px" }} />*/}
          {/*    </div>*/}
          {/*    <div className="Step_off">*/}
          {/*      <span className="txt-ssm bold">STEP06</span>*/}
          {/*      <br />*/}
          {/*      완료*/}
          {/*      <p className="data">02</p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </BuyerProjectCard>
  );
};

export default BuyerProjectHome;
