import React, {useEffect, useState} from "react";
import {useLocation, withRouter} from "react-router-dom";
import ReactGA from "react-ga";
import Constants from "../constants/constants";

const RouteChangeTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize(Constants.GOOGLE_ANALYTICS_TRACKING_ID);
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      if (location) {
        ReactGA.pageview(location.pathname + location.search);
      }
    }
  }, [initialized, location]);

  return <></>;
}

export default RouteChangeTracker;
