import React, {useEffect, useState} from "react";
import last_page_black_48dp from "../../image/last_page_black_48dp.svg";
import navigate_next_black_48dp from "../../image/navigate_next_black_48dp.svg";
import first_page_black_48dp from "../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../image/chevron_left_black_24dp.svg";
import APIHelper from "../../helpers/APIHelper";
import Constants from "../../constants/constants";

const SearchProduct = (props) => {
  const [arrProducts, setArrProducts] = useState([]);
  const [arrTypes, setArrTypes] = useState([]);

  // 셀렉된 것들
  const [selectProduct, setSelectProduct] = useState([]);
  const [selectType, setSelectType] = useState({});
  const [selectItem, setSelectItem] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '110000'});
      if (code === 200) {
        setArrProducts(result);
        if (result.length > 0) {
          // setSelectProduct(result[0]);
          // await getArrTypes(result[0].cd);
        }
      }
    }
    fetch();
  }, []);

  const getArrTypes = (parentCd) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd});
      if (code === 200) setArrTypes(result);
      return resolve(true);
    });
  }

  // 제품분류 핸들러
  const productsList = arrProducts.map((item, i) => (
    <div className="col">
      <button
        type="button"
        key={i}
        onClick={async () => {
          if (selectProduct.cd === item.cd) {
            setSelectProduct({});
            return;
          }
          setSelectProduct(item);
          await getArrTypes(item.cd);
          setSelectType({});
        }}
        className={` btn btn-sm  ${
          selectProduct && selectProduct.cd === item.cd
            ? "category1_sm_on"
            : "category1_sm"
        }`}
      >
        {item?.image_uri && item?.image_uri.length > 0 ?
          <img
            src={process.env.PUBLIC_URL + item?.image_uri.substring(0, item?.image_uri.indexOf(".")) + ((selectProduct && selectProduct.cd === item.cd) ? "1" : "") + '.' + item?.image_uri.substring(item?.image_uri.indexOf(".") + 1)}
            style={{height: `38px`, marginBottom: "5px", objectFit: "contain"}}
          />
          :
          <div style={{width: "38px", height: "38px", borderRadius: "18px", backgroundColor: "#efefef", marginLeft: "18px"}}></div>
        }
        <br/>
        <div style={{fontSize: "0.6rem", lineHeight: "130%"}}>{item.name_kr}</div>
      </button>
    </div>
  ));

  // 제품종류 핸들러
  const typesList = arrTypes.map((item, i) => (
    <div className="col">
      <button
        type="button"
        key={i}
        onClick={() => {
          if (selectType.cd === item.cd) {
            setSelectType({});
            return;
          }
          setSelectType(item);
          setSelectItem([]);
        }}
        className={` btn btn-sm  ${
          selectType && selectType.cd === item.cd
            ? "category1_sm_on"
            : "category1_sm"
        }`}
      >
        {item?.image_uri && item?.image_uri.length > 0 ?
          <img
            src={process.env.PUBLIC_URL + item?.image_uri.substring(0, item?.image_uri.indexOf(".")) + ((selectType && selectType.cd === item.cd) ? "1" : "") + '.' + item?.image_uri.substring(item?.image_uri.indexOf(".") + 1)}
            style={{maxWidth: `38px`, maxHeight: `38px`, marginBottom: "5px"}}
          />
          :
          <div style={{width: "38px", height: "38px", borderRadius: "18px", backgroundColor: "#efefef", marginLeft: "18px"}}></div>
        }
        <br/>
        <div style={{fontSize: "0.6rem", lineHeight: "130%"}}>{item.name_kr}</div>
      </button>
    </div>
  ));

  const [items, setItems] = useState();
  const [searchKeyword, setSearchKeyword] = useState(props.searchKeyword);

  const load = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("data/product/gets", {type: "MF", keyword: searchKeyword, categoryCd: selectProduct?.cd || '', productCd: selectType?.cd || '', mode: "search"});
      if (code === 200) {
        setItems(result);
      }
      return resolve(true);
    });
  }

  useEffect(() => {
    const fetch = async () => {
      await load();
    }
    fetch();
  }, [selectProduct, selectType]);

  return (
    <>
      <div className="box_round min_h cnt_area pb-5">
        <section className="mx-5 mt-4">
          <p className="h3 bold border-bottom pb-3">제품 검색</p>

          <article className="row row-cols-2 g-2">
            <div className="col">
              <div className="border p_rative" style={{minHeight: "100%"}}>
                <div className="row row-cols-4 g-3 p-3 text-left">
                  {productsList}
                </div>
              </div>
            </div>

            <div className="col">
              <div className="border p_rative" style={{minHeight: "100%"}}>
                <div className="row row-cols-4 g-3 text-center p-3">
                  {typesList}
                </div>
              </div>
            </div>
          </article>

          <p className="border-bottom py-2 txt-gray txt-sm">
            - OEM/ODM 제작 제품, 제조사의 제품을 검색 합니다.
          </p>
        </section>

        <section className="mx-5 mt-4 pb-5">
          <p className="h5 pb-3 bold">OEM/ODM 제품 / 제조사 제품 ({items?.length || 0})</p>
          <div className="row row-cols-4 g-3 pt-3">
            {items && items.map((makerProduct, i) =>
              <div className="col" style={{cursor: "pointer"}} onClick={() => props.click(makerProduct.id)}>
                <div className="box_border">
                  <p className="border-bottom" style={{height: "165px"}}>
                    {makerProduct?.dtls.filter(i => i.image_fid !== '').length > 0 &&
                      <img
                        src={`${Constants.API_HOST}/common/download?fid=${makerProduct?.dtls.filter(i => i.image_fid !== '')[0]?.image_fid}&cd=inline&thumb=400`}
                        style={{height: "165px", objectFit: "cover"}}
                      />
                    }
                  </p>
                  <p className="p-3" style={{height: "120px"}}>
                    <a href="">
                      <span className="txt-brown bold">{makerProduct?.category?.name_kr}{makerProduct?.product?.name_kr?.length > 0 ? ('-' + makerProduct?.product?.name_kr) : ""}</span>
                      <br/>
                      <span className="txt-gray" style={{
                        "overflow": "hidden",
                        "textOverflow": "ellipsis",
                        "display": "block",
                        "line-height": "normal",
                        "-webkit-line-clamp": "2",
                        "-webkit-box-orient": "vertical",
                        "-moz-line-clamp": "2",
                        "maxHeight": "60px",
                      }}>{makerProduct?.name}</span>
                    </a>
                  </p>
                </div>
              </div>
            )}
            {items && items.length === 0 &&
              <div className="col w-100">
                <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-lblue bold">
                  검색 결과가 없습니다.
                </div>
              </div>
            }
          </div>

          {/*<div className="d-flex justify-content-center py-4">*/}
          {/*  <ul className="d-flex my-3 p-0">*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" aria-label="Previous">*/}
          {/*        <img src={first_page_black_48dp} style={{width: "25px"}}/>*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" aria-label="Previous">*/}
          {/*        <img*/}
          {/*          src={chevron_left_black_24dp}*/}
          {/*          style={{width: "25px"}}*/}
          {/*        />*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" className="ON">*/}
          {/*        1*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#">2</a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#">3</a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" aria-label="Previous">*/}
          {/*        <img*/}
          {/*          src={navigate_next_black_48dp}*/}
          {/*          style={{width: "25px"}}*/}
          {/*        />*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" aria-label="Previous">*/}
          {/*        <img src={last_page_black_48dp} style={{width: "25px"}}/>*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*</div>*/}
        </section>

        {/*<section className="mx-5 mt-4 pb-5">*/}
        {/*  <p className="h5 pb-3 bold">*/}
        {/*    스킨케어 Best 제품 (Amazon & Olive Young)*/}
        {/*  </p>*/}
        {/*  <div className="row row-cols-4 g-3 pt-3">*/}
        {/*    {bestProduct.map((item, i) => {*/}
        {/*      return (*/}
        {/*        <div className="col">*/}
        {/*          <div className="box_border">*/}
        {/*            <p className="border-bottom" style={{height: "165px"}}>*/}
        {/*              <img*/}
        {/*                src={require(`../../image/prod_img02.png`)}*/}
        {/*                style={{height: "165px"}}*/}
        {/*              />*/}
        {/*            </p>*/}
        {/*            <p className="p-3" style={{height: "120px"}}>*/}
        {/*              <a href="">*/}
        {/*                <span className="txt-brown bold">{item.title}</span>*/}
        {/*                <br/>*/}
        {/*                <span className="txt-gray">{item.en}</span>*/}
        {/*              </a>*/}
        {/*            </p>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*  </div>*/}
        {/*</section>*/}
      </div>
    </>
  );
};

export default SearchProduct;
