import React, {useEffect, useState} from "react";
import first_page_black_48dp from "../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../image/chevron_left_black_24dp.svg";
import navigate_next_black_48dp from "../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../image/last_page_black_48dp.svg";
import "../../sass/main.scss";
import {useHistory} from "react-router-dom";
import APIHelper from "../../helpers/APIHelper";
import Constants from "../../constants/constants";

const MakerProduct = (props) => {
  const {maker} = props;

  const history = useHistory();
  const [makerProducts, setMakerProducts] = useState();

  useEffect(() => {
    const fetch = async () => {
      await load();
    }
    fetch();
  }, []);

  const load = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm(`user/maker/product/gets`, {id: maker?.user_id});
      if (code === 200) {
        setMakerProducts(result);
      }
      return resolve(true);
    });
  }

  if (!makerProducts)
    return "";

  return makerProducts && makerProducts.length > 0 ?
    <>
      <div className="row row-cols-4 g-3 pt-3">
        {makerProducts && makerProducts.map((makerProduct, i) =>
          <div className="col" style={{cursor: "pointer"}} onClick={() => props.click(makerProduct.id)}>
            <div className="box_border">
              <p className="border-bottom" style={{height: "165px"}}>
                {makerProduct?.dtls.filter(i => i.image_fid !== '').length > 0 &&
                  <img
                    src={`${Constants.API_HOST}/common/download?fid=${makerProduct?.dtls.filter(i => i.image_fid !== '')[0]?.image_fid}&cd=inline&thumb=400`}
                    style={{height: "165px", objectFit: "cover"}}
                  />
                }
              </p>
              <p className="p-3" style={{height: "120px"}}>
                <a href="">
                  <span className="txt-brown bold">{makerProduct?.category?.name_kr}{makerProduct?.product?.name_kr?.length > 0 ? ('-' + makerProduct?.product?.name_kr) : ""}</span>
                  <br/>
                  <span className="txt-gray" style={{
                    "overflow": "hidden",
                    "textOverflow": "ellipsis",
                    "display": "block",
                    "line-height": "normal",
                    "-webkit-line-clamp": "2",
                    "-webkit-box-orient": "vertical",
                    "-moz-line-clamp": "2",
                    "maxHeight": "60px",
                  }}>{makerProduct?.name}</span>
                </a>
              </p>
            </div>
          </div>
        )}
      </div>

      <div className="d-flex justify-content-center py-4 d-none">
        <ul className="d-flex my-3 p-0">
          <li className="PAGE">
            <a href="#" aria-label="Previous">
              <img src={first_page_black_48dp} style={{width: "25px"}}/>
            </a>
          </li>
          <li className="PAGE">
            <a href="#" aria-label="Previous">
              <img src={chevron_left_black_24dp} style={{width: "25px"}}/>
            </a>
          </li>
          <li className="PAGE">
            <a href="#" className="ON">
              1
            </a>
          </li>
          <li className="PAGE">
            <a href="#">2</a>
          </li>
          <li className="PAGE">
            <a href="#">3</a>
          </li>
          <li className="PAGE">
            <a href="#" aria-label="Previous">
              <img src={navigate_next_black_48dp} style={{width: "25px"}}/>
            </a>
          </li>
          <li className="PAGE">
            <a href="#" aria-label="Previous">
              <img src={last_page_black_48dp} style={{width: "25px"}}/>
            </a>
          </li>
        </ul>
      </div>
    </>
    :
    <div className="col w-100">
      <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-lblue bold">
        등록된 제품군이 없습니다.
      </div>
    </div>;
};

export default MakerProduct;
