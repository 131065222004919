import React from "react";
import { Link } from "react-router-dom";
import "../../sass/main.scss";
import moment from "moment";

const NoticeItem = (props) => {
  return (
    <>
      <tr>
        <td>{props.no}</td>

        <td>
          <Link
            to={{
              pathname: `/cscenter/notice/${props.no}`,
              state: {
                title: props.title,
                hits: props.hits,
                text: props.text,
                date: props.date,
                no: props.no,
              },
            }}
          >
            {props.title}
          </Link>
        </td>
        <td>{props.date}</td>
      </tr>
    </>
  );
};

export default NoticeItem;
