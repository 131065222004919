import React, {useEffect, useState} from "react";
import first_page_black_48dp from "../../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../../image/chevron_left_black_24dp.svg";
import navigate_next_black_48dp from "../../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../../image/last_page_black_48dp.svg";
import modalStyle from "../../../actions/openModalStyle";
import "../../../sass/main.scss";
import {useDispatch, useSelector} from "react-redux";
import ProductCategoryCard from "./ProductCategoryCard";
import APIHelper from "../../../helpers/APIHelper";
import Constants from "../../../constants/constants";
import CommonLib from "../../../libraries/CommonLib";

// 제품리스트 카드
const RequestsComponent = (props) => {
  const [project, setProject] = useState({});
  const [makers, setMakers] = useState(false);
  const [selectedMaker, setSelectedMaker] = useState({});
  const [expireDays, setExpireDays] = useState(3);
  const [keyword, setKeyword] = useState('');

  // 제조사 리스트 가져오기
  const getMakers = (keyword = '') => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("user/maker/gets", {keyword});
      setMakers(result);
      return resolve(true);
    });
  };

  const handleSearchKeyDown = async (e) => {
    if (e.key === "Enter") {
      await getMakers(keyword);
    }
  };

  const getProject = (id) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("project/get", {id});
      if (code === 200) setProject(result);
      //await selectReset(id);
      return resolve(true);
    });
  }

  const selectReset = (projectId) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.postForm("project/request/select", {
        id: projectId,
        value: '5'
      });
      if (code === 200) {
        //{closeCallback && await closeCallback(projectId)};  //부모창 함수 호출 방법 GD
      }
    });
  }


  const dispatch = useDispatch();
  const selector = useSelector((state) => state.request);
  const modal = useSelector((state) => state.modalStyle);
  const [renew, setRenew] = useState(modal?.content?.renew);

  useEffect(() => {
    const fetch = async () => {
      setKeyword('');
      await getProject(props.projectId);
      await getMakers();
    }
    fetch();
  }, [modal?.content?.renew]);

  const submitMakerRequest = () => {
    return new Promise(async (resolve, reject) => {

      const {projectId, projectProductId, item_cd, item, requestId} = modal?.content;

      const {data: {code, result, message}} = await APIHelper.postForm("project/maker/request/submit", {
        projectId,
        projectProductId,
        requestType: item_cd,
        makerUserId: selectedMaker?.user_id,
        expireDays,
        requestId
      });
      await CommonLib.jalert(message);

      if (code === 200) {
        props.callback();
        dispatch(modalStyle("closed", ""));
      }
      return resolve(true);
    });
  }

  let modalInlineStyle;

  if (modal.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }

  return (
    <>
      {/* 모달 */}
      <div style={{display: modalInlineStyle}}>
        <div
          className="pop_box02"
          style={{width: "900px", minHeight: "500px"}}
        >
          <div className="POP_title pb-3 border-bottom">
            <h4>
              <strong>{modal?.content?.item?.name_kr} 제조사 선택</strong>
            </h4>
            <p className="closed">
              <button
                type="button"
                className="btn"
                onClick={() => dispatch(modalStyle("closed", ""))}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box_lg" style={{marginBottom: "55px"}}>
            <div className="bg-lpurple box_round p-3 mt-3">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="검색어를 입력해 주세요"
                  onChange={async (e) => await setKeyword(e.target.value)}
                  value={keyword}
                  onKeyDown={handleSearchKeyDown}
                />
                <button
                  className="btn btn-purple px-5"
                  type="button"
                  id="button-addon2"
                  onClick={() => getMakers(keyword)}
                >
                  검색
                </button>
              </div>
              {/*<ul className="d-flex pt-3 px-0 m-0">*/}
              {/*  <li className="px-1">*/}
              {/*    <a href="#" className="Hash2 txt-point txt-sm">*/}
              {/*      Mask*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*  <li className="px-1">*/}
              {/*    <a href="#" className="Hash2 txt-point txt-sm">*/}
              {/*      Hair*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*  <li className="px-1">*/}
              {/*    <a href="#" className="Hash2 txt-point txt-sm">*/}
              {/*      Skincare*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*  <li className="px-1">*/}
              {/*    <a href="#" className="Hash2 txt-point txt-sm">*/}
              {/*      Face Oil*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll"
              style={{height: "600px", maxWidth: "calc(100% + 4px)"}}
            >
              {makers && makers.map((maker, key) =>
                <div
                  key={key}
                  className={`d-flex p-4 p_rative border${
                    selectedMaker && selectedMaker === maker
                      ? "_purple"
                      : " border-bottom"
                  }`}
                  style={{clear: "both"}}
                  onClick={() => setSelectedMaker(maker)}
                >
                  <p>
                    {maker.rep_image_fid && maker.rep_image_fid.length > 0 ?
                      <img
                        src={`${Constants.API_HOST}/common/download?fid=${maker.rep_image_fid}&cd=inline&thumb=400`}
                        style={{width: "220px", height: "155px", objectFit: "cover"}}
                      />
                      :
                      <img
                        src={process.env.PUBLIC_URL + "/images/no_image.png"}
                        style={{width: "220px", height: "155px", objectFit: "cover"}}
                      />
                    }
                  </p>
                  <div className="px-4">
                    <h5 className="txt-point">
                      <strong>{maker.company_name}</strong>
                    </h5>
                    <p>
                      {maker?.introduce.length > 0 ? maker.introduce : "등록된 한줄 소개가 없습니다."}
                      <br/>
                      {maker?.functionals && maker?.functionals.length > 0 ? maker.functionals.map((item, key) => (
                        <>
                          {key > 0 ? ", " : ""} {item.name_kr}
                        </>
                      )) : " 등록된 기능성 제품 분류가 없습니다."}
                    </p>

                    <ul className="txt-gray p-0 pt-2" style={{marginBottom: "8px"}}>
                      <li className=" txt-sm">
                        인증서 :
                        {maker?.certs && maker?.certs?.length > 0 ? maker?.certs.map((item, key) => (
                          <>
                            {key > 0 ? " / " : ""} {item.name_en}
                          </>
                        )) : " 없음"}
                      </li>
                      <li className="d-flex txt-sm">
                        <p className="txt-sm">MOQ : {maker?.moq?.name_kr?.length > 0 ? maker?.moq?.name_kr : "정보없음"}</p> <p className="txt-sm" style={{marginLeft: "15px"}}>거래 횟수 : 0회</p>
                      </li>
                    </ul>
                    <div className="mb-0 float-left">
                      {maker?.mf_items && maker?.mf_items.map((item, key) => (
                        <span className="Hash txt-point">{item.name_kr}</span>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {/*<div className="d-flex justify-content-center py-4 d-none">*/}
              {/*  <ul className="d-flex my-3" style={{margin: "0 auto"}}>*/}
              {/*    <li className="PAGE">*/}
              {/*      <a href="#" aria-label="Previous">*/}
              {/*        <img*/}
              {/*          src={first_page_black_48dp}*/}
              {/*          style={{width: "25px"}}*/}
              {/*        />*/}
              {/*      </a>*/}
              {/*    </li>*/}
              {/*    <li className="PAGE">*/}
              {/*      <a href="#" aria-label="Previous">*/}
              {/*        <img*/}
              {/*          src={chevron_left_black_24dp}*/}
              {/*          style={{width: "25px"}}*/}
              {/*        />*/}
              {/*      </a>*/}
              {/*    </li>*/}
              {/*    <li className="PAGE">*/}
              {/*      <a href="#" className="ON">*/}
              {/*        1*/}
              {/*      </a>*/}
              {/*    </li>*/}
              {/*    <li className="PAGE">*/}
              {/*      <a href="#">2</a>*/}
              {/*    </li>*/}
              {/*    <li className="PAGE">*/}
              {/*      <a href="#">3</a>*/}
              {/*    </li>*/}
              {/*    <li className="PAGE">*/}
              {/*      <a href="#" aria-label="Previous">*/}
              {/*        <img*/}
              {/*          src={navigate_next_black_48dp}*/}
              {/*          style={{width: "25px"}}*/}
              {/*        />*/}
              {/*      </a>*/}
              {/*    </li>*/}
              {/*    <li className="PAGE">*/}
              {/*      <a href="#" aria-label="Previous">*/}
              {/*        <img src={last_page_black_48dp} style={{width: "25px"}}/>*/}
              {/*      </a>*/}
              {/*    </li>*/}
              {/*  </ul>*/}
              {/*</div>*/}

            </div>
          </div>
          <div className="POP_foot d-flex justify-content-between" style={{zIndex: 999999}}>
            <dl className="d-flex">
              <dt className="py-1">수락 만료일 :</dt>
              <dd className="px-2">
                <select
                  className="form-select form-select-sm form-select-inline"
                  aria-label=".form-select-sm example"
                  onChange={async (e) => await setExpireDays(e.target.value)}
                >
                  {(Array.from({length: 60}, (_, i) => i + 1)).map((d, k) => <option key={k} selected={d === 3} value={d}>{d}일</option>)}
                </select>
              </dd>
            </dl>
            <button
              className="btn btn-sm px-3 btn-purple"
              type="button"
              onClick={() => submitMakerRequest()}
            >
              선택한 제조사에 제작 의뢰합니다.
            </button>
          </div>
        </div>
      </div>
      {/* 모달 끝 */}

      {project.products && project.products.map((product, key) => (
        <article className="m-2 mt-0">
          <div className="border bg-white p-3 w-100 p_rative">
            <div className="d-flex border-bottom pb-2">
              <p className="p-2">
                {product?.product?.image_uri && product?.product?.image_uri.length > 0 ?
                  <img
                    src={process.env.PUBLIC_URL + product?.product?.image_uri.substring(0, product?.product?.image_uri.indexOf(".")) + '1.' + product?.product?.image_uri.substring(product?.product?.image_uri.indexOf(".") + 1)}
                    style={{width: `45px`, maxHeight: `45px`, marginBottom: "5px", borderRadius: "22.5px"}}
                  />
                  :
                  <div style={{width: "45px", height: "45px", marginBottom: "5px", borderRadius: "22.5px", backgroundColor: "#efefef"}}></div>
                }
              </p>
              <div className="d-flex flex-wrap mt-3 m-2">
                <p className="h5 mx-1 txt-point bold mb-0 px-2">
                  {product?.category?.name_kr} | {product?.product?.name_kr}
                </p>
                <p className="txt-gray txt-sm mt-1 px-2">
                  <span className="bold txt-sm ">요청번호 :</span> {product?.req_no}
                </p>
              </div>
            </div>

            <div className="row row-cols-2 pt-2 g-2">
              <ProductCategoryCard items={product?.items} product={product} closeCallback={async () => await getProject(props.projectId)}/>
            </div>
          </div>
        </article>
      ))}
    </>
  );
};

export default RequestsComponent;
