import React, {useContext, useEffect, useState} from "react";
import "../../../sass/main.scss";
import {useHistory} from "react-router-dom";
import APIHelper from "../../../helpers/APIHelper";
import moment from "moment";
import Paging from "../../common/Paging";
import CommonLib from "../../../libraries/CommonLib";
import {GlobalContext} from "../../global-context";
import AdminCard from "../AdminCard";

const AdminMyProjectAlarm = (props) => {
  const history = useHistory();
  const [renew, setRenew] = useState(0);
  const state = useContext(GlobalContext);

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  const [rets, setRets] = useState([]);

  const gets = async () => {
    const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("manager/project/message/gets", {paging: 1, currPage});
    if (pageInfo) setPageInfo(pageInfo);
    setRets(result);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetch = async () => {
      await gets();
    }
    fetch();
  }, [currPage]);

  const setMessageRead = async (id,projectId) => {
    await APIHelper.putForm("admin/message/read", {id});
    history.push(`/admin/project/timeline/${projectId}`);
    //await gets();
    //setRenew(new Date().getTime());
    //state.setRefreshAlarm(new Date().getTime());
  };

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "매니저 개인홈 > 프로젝트 알림",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <AdminCard renew={renew}>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)", maxWidth: "1250px"}}
      >
        <section className="col-12" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">프로젝트 알림</p>
              <p className="m-1 mb-2"></p>
            </div>

            <p className="px-3 text-start bold my-3">총 {pageInfo?.totalArticles || 0}건</p>
            {rets?.length > 0 &&
              <div
                className="wid_scroll scroll_y custom_scroll px-2"
                style={{height: "calc(100% - 330px)"}}
              >
                <article className="wid_inline">
                  {rets && rets.map((ret, i) => (
                    <div className="box_round border p-3 my-3 p_rative" onClick={() => setMessageRead(ret.id,ret.items.project_id)} style={{cursor: CommonLib.parseInt(ret.read_yn) === 0 ? "pointer" : "initial"}}>
                      <p className={CommonLib.parseInt(ret.read_yn) === 0 ? `h6 pb-3 border-bottom` : `h6 txt-gray pb-3 border-bottom`} style={{paddingRight: "40px"}}>
                        {ret.message.replace(/\n/gi, " / ")}
                      </p>
                      <p className="txt-sm txt-gray">{ret.category}</p>
                      <p className="p_absolute_br2 txt-sm txt-gray">{moment(ret.created_at).format("YYYY.MM.DD HH:mm")}</p>
                    </div>
                  ))}
                </article>
              </div>
            }

            {(!rets || rets?.length === 0) &&
              <div className="col w-100">
                <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-lblue bold">
                  확인할 메세지가 없습니다.
                </div>
              </div>
            }
            <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminMyProjectAlarm;
