import React, {useEffect, useState} from "react";
import info_comp_01 from "../../../image/info_comp_01.png";
import open_in_new from "../../../image/open_in_new.svg";
import refresh_b from "../../../image/refresh_b.svg";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import RequestsComponent from "./RequestsComponent";
import {useDispatch} from "react-redux";
import APIHelper from "../../../helpers/APIHelper";
import moment from "moment";
import openRequestFormulation from "../../../actions/openRequestFormulation";
import openRequestDesign from "../../../actions/openRequestDesign";
import openRequestBottle from "../../../actions/openRequestBottle";
import openRequestBox from "../../../actions/openRequestBox";
import CommonLib from "../../../libraries/CommonLib";
import openTimelineAttach from "../../../actions/openTimelineAttach";

const AdminProjectConnect = (props) => {
  const projectId = props?.match?.params?.id;
  const dispatch = useDispatch();

  const [readyRequests, setReadyRequests] = useState([]);
  const [acceptedRequests, setAcceptedRequests] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      await load();
    }
    fetch();
  }, []);

  const load = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("project/maker/request/gets", {projectId, mode: "admin"});
      setReadyRequests(result?.filter(i => i.status_cd !== 'Y') || []);
      setAcceptedRequests(result?.filter(i => i.status_cd === 'Y') || []);
      return resolve(true);
    });
  };

  const cancel = async (id) => {
    if (!await CommonLib.jconfirm('취소하시겠습니까?')) return;

    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.putForm("project/maker/request/cancel", {projectId, id});
      await load();
      return resolve(true);
    });
  };

  const openRequestForm = (projectId, projectProductId, itemCd, requestId, readOnly = false) => {
    const obj = {projectId, projectProductId, requestId, renew: new Date().getTime(), readOnly};
    if (itemCd === 'MF') dispatch(openRequestFormulation("open", obj));
    else if (itemCd === 'DS') dispatch(openRequestDesign("open", obj));
    else if (itemCd === 'BT') dispatch(openRequestBottle("open", obj));
    else if (itemCd === 'BX') dispatch(openRequestBox("open", obj));
  }

  return (
    <AdminCard>
      <div
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <section className="col-4" style={{height: "98%"}}>
          <div
            className="bg-lpurple shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "100%"}}
          >
            <p className="h5 bold p-2 border-bottom">제품리스트</p>
            <div
              className="wid_scroll scroll_y custom_scroll_p mt-2"
              style={{height: "calc(100% - 60px)"}}
            >
              <RequestsComponent projectId={projectId} callback={async () => {
                await load();
              }}/>
            </div>
          </div>
        </section>
        <section className="col" style={{height: "98%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "100%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">제조사 요청</p>
              <p className="m-1 mb-2">
                <button type="button" className="btn p-0 mx-2" onClick={async () => await load()}>
                  <img src={refresh_b} style={{width: "30px"}}/>
                </button>
                <button
                  type="button"
                  className="btn btn-blue txt-sm pb-2 px-3"
                  onClick={() => cancel(0)}
                >
                  일괄취소처리
                </button>
              </p>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll mt-2"
              style={{height: "calc(100% - 50px)"}}
            >
              {readyRequests.map((request, key) => {
                  const requestType =
                    request.request_type === 'MF' ? '제형' :
                      request.request_type === 'BT' ? '용기' :
                        request.request_type === 'BX' ? '단상자' :
                          request.request_type === 'DS' ? '디자인' : '';

                  let area = request.area;
                  if (request.maker.company_addr.length > 0)
                    area = request.maker.company_addr.split(' ')[0];

                  const requestFormObj = {projectId: request.project_id, projectProductId: request.project_product_id, item_cd: request.request_type};

                  return (
                    <article key={key} className="border wid_inline my-2">
                      <div className="bg-lgray0 d-flex border-bottom p-2">
                        <p>
                          <img
                            src={open_in_new}
                            style={{width: "25px", marginBottom: "2px"}}
                          />{" "}
                          <span className="h6 bold">{requestType}</span>
                        </p>
                        <p className="px-3 txt-point bold">{request?.category?.name_kr} - {request?.product?.name_kr}</p>
                        <p className="mt-1 txt-sm txt-gray ms-auto">
                          <span className="txt-sm txt-dark bold">요청일 :</span>{" "}
                          {moment(request.created_at).format("YYYY.MM.DD")}
                        </p>
                      </div>

                      <div className="d-flex p-2">
                        <p className="btn_oval_sm mb-1 mx-1">
                          <img src={info_comp_01}/>
                        </p>
                        <div className="text-start mx-2">
                          <p className="h6 mb-2">업체명 : {request.maker.company_name}</p>
                          <p className="txt-sm">
                            위치 : {area}
                            <br/>
                            의뢰서번호 :{" "}
                            <span className="txt-point txt-sm">{request.request_form?.request_no}</span>
                            <br/>
                          </p>
                        </div>
                        <p className="ms-auto">
                          {request.status_cd === 'C' ?
                            <button
                              type="button"
                              className="btn w-48 btn-sm btn-dark p-1 my-1"
                              style={{minWidth: "85px", pointerEvents: "none"}}
                            >
                              취소됨
                            </button>
                            :
                            request.status_cd === 'N' ?
                              <button
                                type="button"
                                className="btn w-48 btn-sm btn-ligray p-1 my-1"
                                style={{minWidth: "85px", backgroundColor: "#afafaf", pointerEvents: "none"}}
                              >
                                거절됨
                              </button>
                              :
                              <button
                                type="button"
                                className="btn w-48 btn-sm btn-ligray p-1 my-1"
                                style={{minWidth: "85px"}}
                                onClick={() => cancel(request.id)}
                              >
                                취소
                              </button>
                          }
                          <br/>
                          <button
                            onClick={() => openRequestForm(request.project_id, request.project_product_id, request.request_type, request.request_form_id)}
                            type="button"
                            className="btn w-48 btn-sm btn-blue p-1 my-1"
                            style={{minWidth: "85px"}}
                          >
                            제작의뢰서
                          </button>
                        </p>
                      </div>
                    </article>
                  )
                }
              )}
              {readyRequests.length === 0 &&
                <div className="col w-100">
                  <div className="box_round_5 border my-2 d-flex p-3 justify-content-center">
                    요청 내역이 없습니다.
                  </div>
                </div>
              }
            </div>
          </div>
        </section>
        <section className="col" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">제조사 수락</p>
              <p className="m-1 mb-2">
                <button type="button" className="btn p-0" onClick={async () => await load()}>
                  <img src={refresh_b} style={{width: "30px"}}/>
                </button>
              </p>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll mt-2"
              style={{height: "calc(100% - 60px)"}}
            >
              {acceptedRequests.map((request, key) => {
                  const requestType =
                    request.request_type === 'MF' ? '제형' :
                      request.request_type === 'BT' ? '용기' :
                        request.request_type === 'BX' ? '단상자' :
                          request.request_type === 'DS' ? '디자인' : '';

                  let area = request.area;
                  if (request.maker.company_addr.length > 0)
                    area = request.maker.company_addr.split(' ')[0];

                  return (
                    <article key={key} className="border wid_inline my-2">
                      <div className="bg-lgray0 d-flex border-bottom p-2">
                        <p>
                          <img
                            src={open_in_new}
                            style={{width: "25px", marginBottom: "2px"}}
                          />{" "}
                          <span className="h6 bold">{requestType}</span>
                        </p>
                        <p className="px-3 txt-point bold">{request?.category?.name_kr} - {request?.product?.name_kr}</p>
                        <p className="mt-1 txt-sm txt-gray ms-auto">
                          <span className="txt-sm txt-dark bold">요청일 :</span>{" "}
                          {moment(request.created_at).format("YYYY.MM.DD")}
                        </p>
                      </div>

                      <div className="d-flex p-2">
                        <p className="btn_oval_sm mb-1 mx-1">
                          <img src={info_comp_01}/>
                        </p>
                        <div className="text-start mx-2">
                          <p className="h6 mb-2">업체명 : {request.maker.company_name}</p>
                          <p className="txt-sm">
                            위치 : {area}
                            <br/>
                            의뢰서번호 :{" "}
                            <span className="txt-point txt-sm">{request.request_form?.request_no}</span>
                            <br/>
                          </p>
                        </div>
                        <p className="ms-auto">
                          <div style={{display: "flex", flexDirection: "row"}}>
                            <div>
                              <button
                                type="button"
                                className="btn w-48 btn-sm btn-ligray p-1 my-1"
                                style={{minWidth: "85px"}}
                                onClick={() => {
                                  const obj = {
                                    projectId: request.project_id,
                                    projectProductId: request.project_product_id,
                                    projectMakerRequestId: request.id,
                                    title : '간단 견적서 확인',
                                    renew: new Date().getTime(),
                                    readOnly: true,
                                    formType: '간단 견적서 작성'
                                  };
                                  dispatch(openTimelineAttach("open", obj));
                                }}
                              >
                                견적서
                              </button>
                              <br/>
                              <button
                                onClick={() => openRequestForm(request.project_id, request.project_product_id, request.request_type, request.request_form_id, true)}
                                type="button"
                                className="btn w-48 btn-sm btn-blue p-1 my-1"
                                style={{minWidth: "85px"}}
                              >
                                제작의뢰서
                              </button>
                            </div>
                            <div>
                              <button
                                type="button"
                                className="btn w-48 btn-sm btn-ligray p-1 my-1"
                                style={{minWidth: "85px", backgroundColor: "#afafaf"}}
                                onClick={() => cancel(request.id)}
                              >
                                취소
                              </button>
                            </div>
                          </div>
                        </p>
                      </div>
                    </article>
                  )
                }
              )}
              {acceptedRequests.length === 0 &&
                <div className="col w-100">
                  <div className="box_round_5 border my-2 d-flex p-3 justify-content-center">
                    수락된 요청이 없습니다.
                  </div>
                </div>
              }
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminProjectConnect;
