import React, {useEffect, useState} from "react";
import info_comp_01 from "../../../image/info_comp_01.png";
import open_in_new from "../../../image/open_in_new.svg";
import close_b from "../../../image/close_b.svg";
import plus_circle_g from "../../../image/plus_circle_g.svg";
import minus_circle_g from "../../../image/minus_circle_g.svg";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import {Link, useHistory} from "react-router-dom";
import ProductsComponent from "./ProductsComponent";
import {useDispatch} from "react-redux";
import openRequestFormulation from "../../../actions/openRequestFormulation";
import openRequestDesign from "../../../actions/openRequestDesign";
import openRequestBottle from "../../../actions/openRequestBottle";
import openRequestBox from "../../../actions/openRequestBox";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";
import openTimelineAttach from "../../../actions/openTimelineAttach";
import Constants from "../../../constants/constants";

const AdminProjectFormedProduct = (props) => {
  const history = useHistory();

  const projectId = props?.match?.params?.id;
  const [product, setProduct] = useState();
  const [mfRequest, setMfRequest] = useState();
  const [dsRequest, setDsRequest] = useState();
  const [btRequest, setBtRequest] = useState();
  const [bxRequest, setBxRequest] = useState();

  // modal액션으로 카테고리(단상자, 디자인...) 보내기
  const dispatch = useDispatch();

  const openRequestForm = (projectId, projectProductId, itemCd) => {
    const obj = {projectId, projectProductId, renew: new Date().getTime()};
    if (itemCd === 'MF') dispatch(openRequestFormulation("open", obj));
    else if (itemCd === 'DS') dispatch(openRequestDesign("open", obj));
    else if (itemCd === 'BT') dispatch(openRequestBottle("open", obj));
    else if (itemCd === 'BX') dispatch(openRequestBox("open", obj));
  }

  const downloadTimelineAttachModal = (project_id, project_product_id, project_maker_request_id, title = '간단 견적서', formType = '간단 견적서 작성') => {
    const obj = {
      projectId: project_id,
      projectProductId: project_product_id,
      projectMakerRequestId: project_maker_request_id,
      title,
      renew: new Date().getTime(),
      readOnly: true,
      formType
    };
    dispatch(openTimelineAttach("open", obj));
  }

  useEffect(() => {
    const fetch = async () => {
      await getArrCategories();
    }
    fetch();
  }, []);

  const [arrCategories, setArrCategories] = useState([]);
  const [formedProductCategoryCd, setFormedProductCategoryCd] = useState();
  const [arrTypes, setArrTypes] = useState([]);
  const [formedProductTypeCd, setFormedProductTypeCd] = useState();
  const [formedProductName, setFormedProductName] = useState('');

  const getArrCategories = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '110000'});
      if (code === 200) {
        setArrCategories(result);
        if (result.length > 0) {
          setFormedProductCategoryCd(result[0].cd);
          await getArrTypes(result[0].cd);
        }
      }
      return resolve(true);
    });
  }

  const getArrTypes = (parentCd) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd});
      if (code === 200) {
        setArrTypes(result);
        if (result.length > 0) {
          setFormedProductTypeCd(result[0].cd);
        }
      }
      return resolve(true);
    });
  }

  const addFormedProduct = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.postForm("project/formed/product/add", {
        projectId: product.project_id,
        projectProductId: product.id,
        categoryCd: formedProductCategoryCd,
        productCd: formedProductTypeCd,
        name: formedProductName,
        mfRequestId: mfRequest?.id,
        dsRequestId: dsRequest?.id,
        btRequestId: btRequest?.id,
        bxRequestId: bxRequest?.id
      });
      await loadFormedProducts(product);
      await CommonLib.jalert(message);
      setOpen(false);
      return resolve(true);
    });
  }

  const deleteFormedProduct = async (id) => {
    if (!await CommonLib.jconfirm('제품을 삭제하시겠습니까?')) return;
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.postForm("project/formed/product/delete", {id});
      await loadFormedProducts(product);
      setOpen(false);
      return resolve(true);
    });
  }

  const [formedProducts, setFormedProducts] = useState([]);

  const loadFormedProducts = (product) => {
    return new Promise(async (resolve, reject) => {
      setFormedProducts([]);
      if (CommonLib.parseInt(product.project_id) > 0) {
        const {data: {code, result, message}} = await APIHelper.getForm("project/formed/product/gets", {
          projectId: product.project_id,
        });
        setFormedProducts(result);
      }
      return resolve(true);
    });
  }

  // 모달 state
  const [open, setOpen] = useState(false);
  let modalInlineStyle;
  if (open) {
    modalInlineStyle = "block";
  } else {
    modalInlineStyle = "none";
  }

  return (
    <AdminCard isOpen={open} click={setOpen}>
      {/* 모달 */}
      <div style={{display: modalInlineStyle}}>
        <div
          className="pop_box02"
          style={{width: "900px", minHeight: "350px"}}
        >
          <div className="POP_title pb-3 border-bottom">
            <h4>
              <strong>제품 생성</strong>
            </h4>
            <p className="closed">
              <button
                onClick={() => setOpen(!open)}
                type="button"
                className="btn"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box_lg" style={{marginBottom: "90px"}}>
            <table className="table basic w-100 my-3">
              <colgroup>
                <col width="15%"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th className="center txt-sm">제품 분류</th>
                <td className="border-top">
                  <div className="input-group input-group-sm" style={{padding: "0px"}}>
                    <select
                      className="form-select w-85"
                      aria-label="Default select example"
                      onChange={async (e) => {
                        await setFormedProductCategoryCd(e.target.value);
                        await getArrTypes(e.target.value);
                      }}
                      value={formedProductCategoryCd}
                    >
                      {arrCategories && arrCategories.map((item, key) => <option key={key} selected={item.cd === formedProductCategoryCd} value={item.cd}>{item.name_kr}</option>)}
                    </select>
                    <select
                      className="form-select w-85"
                      aria-label="Default select example"
                      onChange={async (e) => await setFormedProductTypeCd(e.target.value)}
                      value={formedProductTypeCd}
                    >
                      {arrTypes && arrTypes.map((item, key) => <option key={key} selected={item.cd === formedProductTypeCd} value={item.cd}>{item.name_kr}</option>)}
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">제품명</th>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="제품명을 입력해 주세요"
                    onChange={async (e) => await setFormedProductName(e.target.value)}
                    value={formedProductName}
                  />
                </td>
              </tr>

              {/*TODO*/}
              {/*<tr>*/}
              {/*  <th className="center txt-sm">태그 선택</th>*/}
              {/*  <td>*/}
              {/*    <div*/}
              {/*      className="border box_round_4_paddingNo_paddingNo w-100 d-flex flex-wrap scroll_y custom_scroll p-3"*/}
              {/*      style={{height: "150px"}}*/}
              {/*    >*/}
              {/*      <div className="Tag1 d-flex" style={{height: "30px"}}>*/}
              {/*        <p className="p-1">녹차</p>*/}
              {/*        <button type="button" className="btn p-0">*/}
              {/*          <img src={plus_circle_g} style={{width: "20px"}}/>*/}
              {/*        </button>*/}
              {/*      </div>*/}
              {/*      <div className="Tag1 d-flex" style={{height: "30px"}}>*/}
              {/*        <p className="p-1">스킨</p>*/}
              {/*        <button type="button" className="btn p-0">*/}
              {/*          <img src={plus_circle_g} style={{width: "20px"}}/>*/}
              {/*        </button>*/}
              {/*      </div>*/}
              {/*      <div className="Tag1 d-flex" style={{height: "30px"}}>*/}
              {/*        <p className="p-1">기능성</p>*/}
              {/*        <button type="button" className="btn p-0">*/}
              {/*          <img src={plus_circle_g} style={{width: "20px"}}/>*/}
              {/*        </button>*/}
              {/*      </div>*/}
              {/*      <div className="Tag1 d-flex" style={{height: "30px"}}>*/}
              {/*        <p className="p-1">보습효과</p>*/}
              {/*        <button type="button" className="btn p-0">*/}
              {/*          <img src={plus_circle_g} style={{width: "20px"}}/>*/}
              {/*        </button>*/}
              {/*      </div>*/}
              {/*      <div className="Tag1 d-flex" style={{height: "30px"}}>*/}
              {/*        <p className="p-1">계절용</p>*/}
              {/*        <button type="button" className="btn p-0">*/}
              {/*          <img src={plus_circle_g} style={{width: "20px"}}/>*/}
              {/*        </button>*/}
              {/*      </div>*/}
              {/*      <div className="Tag1 d-flex" style={{height: "30px"}}>*/}
              {/*        <p className="p-1">여름아</p>*/}
              {/*        <button type="button" className="btn p-0">*/}
              {/*          <img src={plus_circle_g} style={{width: "20px"}}/>*/}
              {/*        </button>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <th className="center txt-sm">선택된 태그</th>*/}
              {/*  <td className="border-bottom">*/}
              {/*    <div className="border box_round_4_paddingNo w-100 d-flex flex-wrap p-3">*/}
              {/*      <div className="Tag1 d-flex" style={{height: "30px"}}>*/}
              {/*        <p className="p-1">녹차</p>*/}
              {/*        <button type="button" className="btn p-0">*/}
              {/*          <img src={minus_circle_g} style={{width: "20px"}}/>*/}
              {/*        </button>*/}
              {/*      </div>*/}
              {/*      <div className="Tag1 d-flex" style={{height: "30px"}}>*/}
              {/*        <p className="p-1">스킨</p>*/}
              {/*        <button type="button" className="btn p-0">*/}
              {/*          <img src={minus_circle_g} style={{width: "20px"}}/>*/}
              {/*        </button>*/}
              {/*      </div>*/}
              {/*      <div className="Tag1 d-flex" style={{height: "30px"}}>*/}
              {/*        <p className="p-1">기능성</p>*/}
              {/*        <button type="button" className="btn p-0">*/}
              {/*          <img src={minus_circle_g} style={{width: "20px"}}/>*/}
              {/*        </button>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <p>※ 최대 5개 추가 가능 합니다.</p>*/}
              {/*  </td>*/}
              {/*</tr>*/}
            </table>
          </div>
          <div className="POP_foot">
            <button
              onClick={() => setOpen(!open)}
              className="btn btn-sm px-3 btn_gray"
              type="button"
            >
              취소
            </button>
            <button className="btn btn-sm px-3 btn-purple" type="button" onClick={() => addFormedProduct()}>
              제품 생성
            </button>
          </div>
        </div>
      </div>
      {/* 모달 끝 */}

      <div
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <section className="col-4" style={{height: "98%"}}>
          <div
            className="bg-lpurple shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "100%"}}
          >
            <p className="h5 bold p-2 border-bottom">제조 의뢰서</p>
            <div
              className="wid_scroll scroll_y custom_scroll_p mt-2"
              style={{height: "calc(100% - 60px)"}}
            >
              <ProductsComponent projectId={projectId} click={async (e) => {
                setMfRequest({});
                setDsRequest({});
                setBtRequest({});
                setBxRequest({});
                setProduct(e);
                await loadFormedProducts(e);
              }}/>
            </div>
          </div>
        </section>

        <section className="col" style={{height: "98%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "100%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">제조사</p>
              <p className="m-1 mb-2">
                <button
                  type="button"
                  className="btn btn_outline_puple txt-sm py-1 px-3"
                  style={{marginRight: "5px"}}
                  onClick={() => {
                    setMfRequest({});
                    setDsRequest({});
                    setBtRequest({});
                    setBxRequest({});
                  }}
                >
                  전체선택해제
                </button>
                <button
                  onClick={async () => {
                    if (!parseInt(mfRequest?.id) && !parseInt(dsRequest?.id) && !parseInt(btRequest?.id) && !parseInt(bxRequest?.id)) {
                      await CommonLib.jalert('제품 생성 항목이 선택되지 않았습니다.');
                      return;
                    }

                    await setFormedProductCategoryCd(0);
                    await setFormedProductTypeCd(0);
                    await setFormedProductName('');

                    setOpen(!open)
                  }}
                  type="button"
                  className="btn btn-purple txt-sm py-1 px-3"
                >
                  제품 생성
                </button>
              </p>
            </div>

            <div className="row row-cols-2 gx-3 mt-2" style={{height: "46%"}}>
              <div className="col" style={{height: "100%"}}>
                <div className="border" style={{height: "100%"}}>
                  <div className="bg-lgray0 d-flex border-bottom p-2">
                    <p>
                      <img
                        src={open_in_new}
                        style={{width: "25px", marginBottom: "2px"}}
                      />{" "}
                      <span className="h6 bold">내용물</span>
                    </p>
                  </div>

                  <article
                    className="wid_scroll scroll_y custom_scroll"
                    style={{height: "calc(100% - 45px)"}}
                  >
                    {product?.maker_requests.filter(i => i.status_cd === 'Y' && i.request_type === 'MF')?.map((request, key) => {
                      let area = '';
                      if (request.maker.company_addr.length > 0)
                        area = request.maker.company_addr.split(' ')[0];

                      return (
                        <div className={`wid_inline p-2 border${
                          request.id === mfRequest?.id
                            ? "_purple"
                            : "_bottom"
                        }`} onClick={() => mfRequest?.id === request?.id ? setMfRequest({}) : setMfRequest(request)}>
                          <div className="d-flex">
                            <p className="btn_oval_sm mb-1 mx-1">
                              {request.maker.rep_image_fid && request.maker.rep_image_fid.length > 0 ?
                                <img
                                  src={`${Constants.API_HOST}/common/download?fid=${request.maker.rep_image_fid}&cd=inline&thumb=400`}
                                  style={{width: "45px", height: "45px", objectFit: "cover"}}
                                />
                                :
                                <div style={{width: "45px", height: "45px", backgroundColor: "#efefef", borderRadius: "65px"}}></div>}
                            </p>
                            <p className="h6 m-1">
                              {request.maker.company_name}
                              <br/>
                              <span className="txt-sm">위치 : {area}</span>
                            </p>
                          </div>
                          <div className="text-start mx-2">
                            <p className="txt-sm">
                              의뢰서번호 :{" "}
                              <span className="txt-point txt-sm">{request.request_form.request_no}</span>
                              <br/>
                            </p>
                          </div>

                          <p className="text-center">
                            <button
                              type="button"
                              className="btn w-48 btn-sm btn-ligray p-1 my-1"
                              style={{minWidth: "82px"}}
                              onClick={(e) => {
                                downloadTimelineAttachModal(product?.project_id, product?.id, request?.id);
                                e.stopPropagation();
                              }}
                            >
                              견적서
                            </button>
                            <button
                              type="button"
                              className="btn w-48 btn-sm btn-purple p-1 my-1"
                              style={{minWidth: "82px"}}
                              onClick={(e) => {
                                openRequestForm(product?.project_id, product?.id, 'MF');
                                e.stopPropagation();
                              }}
                            >
                              의뢰서확인
                            </button>
                          </p>
                        </div>
                      )
                    })}
                  </article>
                </div>
              </div>


              <div className="col" style={{height: "100%"}}>
                <div className="border" style={{height: "100%"}}>
                  <div className="bg-lgray0 d-flex border-bottom p-2">
                    <p>
                      <img
                        src={open_in_new}
                        style={{width: "25px", marginBottom: "2px"}}
                      />{" "}
                      <span className="h6 bold">디자인</span>
                    </p>
                  </div>

                  <article
                    className="wid_scroll scroll_y custom_scroll"
                    style={{height: "calc(100% - 45px)"}}
                  >
                    {product?.maker_requests.filter(i => i.status_cd === 'Y' && i.request_type === 'DS')?.map((request, key) => {
                      let area = '';
                      if (request.maker.company_addr.length > 0)
                        area = request.maker.company_addr.split(' ')[0];

                      return (
                        <div className={`wid_inline p-2 border${
                          request.id === dsRequest?.id
                            ? "_purple"
                            : "_bottom"
                        }`} onClick={() => dsRequest?.id === request?.id ? setDsRequest({}) : setDsRequest(request)}>
                          <div className="d-flex">
                            <p className="btn_oval_sm mb-1 mx-1">
                              {request.maker.rep_image_fid && request.maker.rep_image_fid.length > 0 ?
                                <img
                                  src={`${Constants.API_HOST}/common/download?fid=${request.maker.rep_image_fid}&cd=inline&thumb=400`}
                                  style={{width: "45px", height: "45px", objectFit: "cover"}}
                                />
                                :
                                <div style={{width: "45px", height: "45px", backgroundColor: "#efefef", borderRadius: "65px"}}></div>}
                            </p>
                            <p className="h6 m-1">
                              {request.maker.company_name}
                              <br/>
                              <span className="txt-sm">위치 : {area}</span>
                            </p>
                          </div>
                          <div className="text-start mx-2">
                            <p className="txt-sm">
                              의뢰서번호 :{" "}
                              <span className="txt-point txt-sm">{request.request_form.request_no}</span>
                              <br/>
                            </p>
                          </div>

                          <p className="text-center">
                            <button
                              type="button"
                              className="btn w-48 btn-sm btn-ligray p-1 my-1"
                              style={{minWidth: "82px"}}
                              onClick={(e) => {
                                downloadTimelineAttachModal(product?.project_id, product?.id, request?.id);
                                e.stopPropagation();
                              }}
                            >
                              견적서
                            </button>
                            <button
                              type="button"
                              className="btn w-48 btn-sm btn-purple p-1 my-1"
                              style={{minWidth: "82px"}}
                              onClick={(e) => {
                                openRequestForm(product?.project_id, product?.id, 'DS');
                                e.stopPropagation();
                              }}
                            >
                              의뢰서확인
                            </button>
                          </p>
                        </div>
                      )
                    })}
                  </article>
                </div>
              </div>
            </div>

            <div className="row row-cols-2 gx-3 mt-3" style={{height: "46%"}}>
              <div className="col" style={{height: "100%"}}>
                <div className="border" style={{height: "100%"}}>
                  <div className="bg-lgray0 d-flex border-bottom p-2">
                    <p>
                      <img
                        src={open_in_new}
                        style={{width: "25px", marginBottom: "2px"}}
                      />{" "}
                      <span className="h6 bold">용기</span>
                    </p>
                  </div>

                  <article
                    className="wid_scroll scroll_y custom_scroll"
                    style={{height: "calc(100% - 45px)"}}
                  >
                    {product?.maker_requests.filter(i => i.status_cd === 'Y' && i.request_type === 'BT')?.map((request, key) => {
                      let area = '';
                      if (request.maker.company_addr.length > 0)
                        area = request.maker.company_addr.split(' ')[0];

                      return (
                        <div className={`wid_inline p-2 border${
                          request.id === btRequest?.id
                            ? "_purple"
                            : "_bottom"
                        }`} onClick={() => btRequest?.id === request?.id ? setBtRequest({}) : setBtRequest(request)}>
                          <div className="d-flex">
                            <p className="btn_oval_sm mb-1 mx-1">
                              {request.maker.rep_image_fid && request.maker.rep_image_fid.length > 0 ?
                                <img
                                  src={`${Constants.API_HOST}/common/download?fid=${request.maker.rep_image_fid}&cd=inline&thumb=400`}
                                  style={{width: "45px", height: "45px", objectFit: "cover"}}
                                />
                                :
                                <div style={{width: "45px", height: "45px", backgroundColor: "#efefef", borderRadius: "65px"}}></div>}
                            </p>
                            <p className="h6 m-1">
                              {request.maker.company_name}
                              <br/>
                              <span className="txt-sm">위치 : {area}</span>
                            </p>
                          </div>
                          <div className="text-start mx-2">
                            <p className="txt-sm">
                              의뢰서번호 :{" "}
                              <span className="txt-point txt-sm">{request.request_form.request_no}</span>
                              <br/>
                            </p>
                          </div>

                          <p className="text-center">
                            <button
                              type="button"
                              className="btn w-48 btn-sm btn-ligray p-1 my-1"
                              style={{minWidth: "82px"}}
                              onClick={(e) => {
                                downloadTimelineAttachModal(product?.project_id, product?.id, request?.id);
                                e.stopPropagation();
                              }}
                            >
                              견적서
                            </button>
                            <button
                              type="button"
                              className="btn w-48 btn-sm btn-purple p-1 my-1"
                              style={{minWidth: "82px"}}
                              onClick={(e) => {
                                openRequestForm(product?.project_id, product?.id, 'BT');
                                e.stopPropagation();
                              }}
                            >
                              의뢰서확인
                            </button>
                          </p>
                        </div>
                      )
                    })}
                  </article>
                </div>
              </div>
              <div className="col" style={{height: "100%"}}>
                <div className="border" style={{height: "100%"}}>
                  <div className="bg-lgray0 d-flex border-bottom p-2">
                    <p>
                      <img
                        src={open_in_new}
                        style={{width: "25px", marginBottom: "2px"}}
                      />{" "}
                      <span className="h6 bold">단상자</span>
                    </p>
                  </div>

                  <article
                    className="wid_scroll scroll_y custom_scroll"
                    style={{height: "calc(100% - 45px)"}}
                  >
                    {product?.maker_requests.filter(i => i.status_cd === 'Y' && i.request_type === 'BX')?.map((request, key) => {
                      let area = '';
                      if (request.maker.company_addr.length > 0)
                        area = request.maker.company_addr.split(' ')[0];

                      return (
                        <div className={`wid_inline p-2 border${
                          request.id === bxRequest?.id
                            ? "_purple"
                            : "_bottom"
                        }`} onClick={() => bxRequest?.id === request?.id ? setBxRequest({}) : setBxRequest(request)}>
                          <div className="d-flex">
                            <p className="btn_oval_sm mb-1 mx-1">
                              {request.maker.rep_image_fid && request.maker.rep_image_fid.length > 0 ?
                                <img
                                  src={`${Constants.API_HOST}/common/download?fid=${request.maker.rep_image_fid}&cd=inline&thumb=400`}
                                  style={{width: "45px", height: "45px", objectFit: "cover"}}
                                />
                                :
                                <div style={{width: "45px", height: "45px", backgroundColor: "#efefef", borderRadius: "65px"}}></div>}
                            </p>
                            <p className="h6 m-1">
                              {request.maker.company_name}
                              <br/>
                              <span className="txt-sm">위치 : {area}</span>
                            </p>
                          </div>
                          <div className="text-start mx-2">
                            <p className="txt-sm">
                              의뢰서번호 :{" "}
                              <span className="txt-point txt-sm">{request.request_form.request_no}</span>
                              <br/>
                            </p>
                          </div>

                          <p className="text-center">
                            <button
                              type="button"
                              className="btn w-48 btn-sm btn-ligray p-1 my-1"
                              style={{minWidth: "82px"}}
                              onClick={(e) => {
                                downloadTimelineAttachModal(product?.project_id, product?.id, request?.id);
                                e.stopPropagation();
                              }}
                            >
                              견적서
                            </button>
                            <button
                              type="button"
                              className="btn w-48 btn-sm btn-purple p-1 my-1"
                              style={{minWidth: "82px"}}
                              onClick={(e) => {
                                openRequestForm(product?.project_id, product?.id, 'BX');
                                e.stopPropagation();
                              }}
                            >
                              의뢰서확인
                            </button>
                          </p>
                        </div>
                      )
                    })}
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="col" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">제품</p>
              <p className="m-1 mb-2">
                <Link
                  onClick={async () => {
                    if (CommonLib.parseInt(product?.id, 10) === 0) {
                      await CommonLib.jalert('선택된 제조의뢰서가 없습니다.');
                      return;
                    }
                    if (formedProducts?.length === 0) {
                      await CommonLib.jalert('생성된 제품이 없습니다.');
                      return;
                    }
                    history.push('/admin/project/estimates/' + product?.project_id + '/' + product?.id);
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-ligray txt-sm p-1 my-1"
                    style={{marginRight: "5px"}}
                  >
                    견적서 리스트
                  </button>
                </Link>
                <button
                  type="button"
                  className="btn btn-purple txt-sm py-1 px-3"
                  onClick={async () => {
                    if (CommonLib.parseInt(product?.id, 10) === 0) {
                      await CommonLib.jalert('선택된 제조의뢰서가 없습니다.');
                      return;
                    }
                    if (formedProducts?.length === 0) {
                      await CommonLib.jalert('생성된 제품이 없습니다.');
                      return;
                    }
                    history.push('/admin/project/estimate/' + product?.project_id + '/' + product?.id);
                  }}
                >
                  견적 생성
                </button>
              </p>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll mt-2"
              style={{height: "calc(100% - 60px)"}}
            >
              <div className="row row-cols-2 wid_inline gx-2 ">
                {formedProducts && formedProducts.map((formedProduct, key) => {
                  return (
                    <div className="col">
                      <article key={key} className="border my-2">
                        <div className="bg-lgray d-flex border-bottom p-2">
                          <p>
                            <img
                              src={open_in_new}
                              style={{width: "25px", marginBottom: "2px"}}
                            />
                          </p>
                          <p className="px-3 bold">{formedProduct.name}</p>
                          <p className="txt-sm txt-gray ms-auto">
                            <button type="button" className="btn p-0 mb-1" onClick={() => deleteFormedProduct(formedProduct?.id)}>
                              <img src={close_b} style={{width: "20px"}}/>
                            </button>
                          </p>
                        </div>

                        {formedProduct.mf_request_id > 0 &&
                          <div className="p-2 border-bottom">
                            <div className="d-flex">
                              <p className="btn_oval_sm mb-1 mx-1">
                                {formedProduct.mf_maker.rep_image_fid && formedProduct.mf_maker.rep_image_fid.length > 0 ?
                                  <img
                                    src={`${Constants.API_HOST}/common/download?fid=${formedProduct.mf_maker.rep_image_fid}&cd=inline&thumb=400`}
                                    style={{width: "45px", height: "45px", objectFit: "cover"}}
                                  />
                                  :
                                  <div style={{width: "45px", height: "45px", backgroundColor: "#efefef", borderRadius: "65px"}}></div>}
                              </p>
                              <p className="h6 m-1">
                                {formedProduct.mf_maker.company_name}
                                <br/>
                                <span className="txt-sm">위치 : {formedProduct.mf_maker.company_addr.length > 0 ? formedProduct.mf_maker.company_addr.split(' ')[0] : ''}</span>
                              </p>
                            </div>
                            <div className="text-start mx-2">
                              <p className="txt-sm">
                                요청번호 : {formedProduct?.mf_request_form?.request_no}
                                <br/>
                                의뢰타입 :{" "}
                                <span className="txt-point txt-sm">
                                  내용물
                                </span>
                                <br/>
                              </p>
                            </div>

                            <p className="text-center">
                              <button
                                type="button"
                                className="btn w-48 btn-sm btn-ligray p-1 my-1"
                                style={{minWidth: "82px"}}
                                onClick={(e) => {
                                  downloadTimelineAttachModal(formedProduct?.project_id, formedProduct?.project_product_id, formedProduct?.mf_request_id);
                                  e.stopPropagation();
                                }}
                              >
                                견적서
                              </button>
                              <button
                                type="button"
                                className="btn w-48 btn-sm btn-purple p-1 my-1"
                                style={{minWidth: "82px"}}
                                onClick={(e) => {
                                  openRequestForm(formedProduct?.project_id, formedProduct?.project_product_id, 'MF');
                                  e.stopPropagation();
                                }}
                              >
                                의뢰서확인
                              </button>
                            </p>
                          </div>
                        }

                        {formedProduct.ds_request_id > 0 &&
                          <div className="p-2 border-bottom">
                            <div className="d-flex">
                              <p className="btn_oval_sm mb-1 mx-1">
                                {formedProduct.ds_maker.rep_image_fid && formedProduct.ds_maker.rep_image_fid.length > 0 ?
                                  <img
                                    src={`${Constants.API_HOST}/common/download?fid=${formedProduct.ds_maker.rep_image_fid}&cd=inline&thumb=400`}
                                    style={{width: "45px", height: "45px", objectFit: "cover"}}
                                  />
                                  :
                                  <div style={{width: "45px", height: "45px", backgroundColor: "#efefef", borderRadius: "65px"}}></div>}
                              </p>
                              <p className="h6 m-1">
                                {formedProduct.ds_maker.company_name}
                                <br/>
                                <span className="txt-sm">위치 : {formedProduct.ds_maker.company_addr.length > 0 ? formedProduct.ds_maker.company_addr.split(' ')[0] : ''}</span>
                              </p>
                            </div>
                            <div className="text-start mx-2">
                              <p className="txt-sm">
                                요청번호 : {formedProduct?.ds_request_form?.request_no}
                                <br/>
                                의뢰타입 :{" "}
                                <span className="txt-point txt-sm">
                                  디자인
                                </span>
                                <br/>
                              </p>
                            </div>

                            <p className="text-center">
                              <button
                                type="button"
                                className="btn w-48 btn-sm btn-ligray p-1 my-1"
                                style={{minWidth: "82px"}}
                                onClick={(e) => {
                                  downloadTimelineAttachModal(formedProduct?.project_id, formedProduct?.project_product_id, formedProduct?.ds_request_id);
                                  e.stopPropagation();
                                }}
                              >
                                견적서
                              </button>
                              <button
                                type="button"
                                className="btn w-48 btn-sm btn-purple p-1 my-1"
                                style={{minWidth: "82px"}}
                                onClick={(e) => {
                                  openRequestForm(formedProduct?.project_id, formedProduct?.project_product_id, 'DS');
                                  e.stopPropagation();
                                }}
                              >
                                의뢰서확인
                              </button>
                            </p>
                          </div>
                        }

                        {formedProduct.bt_request_id > 0 &&
                          <div className="p-2 border-bottom">
                            <div className="d-flex">
                              <p className="btn_oval_sm mb-1 mx-1">
                                {formedProduct.bt_maker.rep_image_fid && formedProduct.bt_maker.rep_image_fid.length > 0 ?
                                  <img
                                    src={`${Constants.API_HOST}/common/download?fid=${formedProduct.bt_maker.rep_image_fid}&cd=inline&thumb=400`}
                                    style={{width: "45px", height: "45px", objectFit: "cover"}}
                                  />
                                  :
                                  <div style={{width: "45px", height: "45px", backgroundColor: "#efefef", borderRadius: "65px"}}></div>}
                              </p>
                              <p className="h6 m-1">
                                {formedProduct.bt_maker.company_name}
                                <br/>
                                <span className="txt-sm">위치 : {formedProduct.bt_maker.company_addr.length > 0 ? formedProduct.bt_maker.company_addr.split(' ')[0] : ''}</span>
                              </p>
                            </div>
                            <div className="text-start mx-2">
                              <p className="txt-sm">
                                요청번호 : {formedProduct?.bt_request_form?.request_no}
                                <br/>
                                의뢰타입 :{" "}
                                <span className="txt-point txt-sm">
                                  용기
                                </span>
                                <br/>
                              </p>
                            </div>

                            <p className="text-center">
                              <button
                                type="button"
                                className="btn w-48 btn-sm btn-ligray p-1 my-1"
                                style={{minWidth: "82px"}}
                                onClick={(e) => {
                                  downloadTimelineAttachModal(formedProduct?.project_id, formedProduct?.project_product_id, formedProduct?.bt_request_id);
                                  e.stopPropagation();
                                }}
                              >
                                견적서
                              </button>
                              <button
                                type="button"
                                className="btn w-48 btn-sm btn-purple p-1 my-1"
                                style={{minWidth: "82px"}}
                                onClick={(e) => {
                                  openRequestForm(formedProduct?.project_id, formedProduct?.project_product_id, 'BT');
                                  e.stopPropagation();
                                }}
                              >
                                의뢰서확인
                              </button>
                            </p>
                          </div>
                        }

                        {formedProduct.bx_request_id > 0 &&
                          <div className="p-2 border-bottom">
                            <div className="d-flex">
                              <p className="btn_oval_sm mb-1 mx-1">
                                {formedProduct.bx_maker.rep_image_fid && formedProduct.bx_maker.rep_image_fid.length > 0 ?
                                  <img
                                    src={`${Constants.API_HOST}/common/download?fid=${formedProduct.bx_maker.rep_image_fid}&cd=inline&thumb=400`}
                                    style={{width: "45px", height: "45px", objectFit: "cover"}}
                                  />
                                  :
                                  <div style={{width: "45px", height: "45px", backgroundColor: "#efefef", borderRadius: "65px"}}></div>}
                              </p>
                              <p className="h6 m-1">
                                {formedProduct.bx_maker.company_name}
                                <br/>
                                <span className="txt-sm">위치 : {formedProduct.bx_maker.company_addr.length > 0 ? formedProduct.bx_maker.company_addr.split(' ')[0] : ''}</span>
                              </p>
                            </div>
                            <div className="text-start mx-2">
                              <p className="txt-sm">
                                요청번호 : {formedProduct?.bx_request_form?.request_no}
                                <br/>
                                의뢰타입 :{" "}
                                <span className="txt-point txt-sm">
                                  단상자
                                </span>
                                <br/>
                              </p>
                            </div>

                            <p className="text-center">
                              <button
                                type="button"
                                className="btn w-48 btn-sm btn-ligray p-1 my-1"
                                style={{minWidth: "82px"}}
                                onClick={(e) => {
                                  downloadTimelineAttachModal(formedProduct?.project_id, formedProduct?.project_product_id, formedProduct?.bx_request_id);
                                  e.stopPropagation();
                                }}
                              >
                                견적서
                              </button>
                              <button
                                type="button"
                                className="btn w-48 btn-sm btn-purple p-1 my-1"
                                style={{minWidth: "82px"}}
                                onClick={(e) => {
                                  openRequestForm(formedProduct?.project_id, formedProduct?.project_product_id, 'BX');
                                  e.stopPropagation();
                                }}
                              >
                                의뢰서확인
                              </button>
                            </p>
                          </div>
                        }
                      </article>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminProjectFormedProduct;
