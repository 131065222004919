import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import GuideCard from "./GuideCard";
import $ from "jquery";

const Guide = (props) => {
  let imgSrc = '';

  if (window.location.pathname.includes("/guide/about")) imgSrc = 'guide_about.jpg';
  else if (window.location.pathname.includes("/guide/buyer")) imgSrc = 'guide_buyer_' + window.location.pathname.replace('/guide/buyer/', '') + '.jpg';
  else if (window.location.pathname.includes("/guide/maker")) imgSrc = 'guide_maker_' + window.location.pathname.replace('/guide/maker/', '') + '.jpg';

  // TODO : img 사이즈에 맞는 div height 조정할 것
  useEffect(() => {
    $('#guide_img').attr('style', 'width: 100%');
    $('#guide_img')[0].onload = function () {
      $('#guide_img, #guide_wrap').css('height', this.height);
      let diff = 400;
      if (window.location.pathname.includes("/guide/maker/5")) diff = 500;
      $('.BODY').css('height', this.height + diff);
    }
  }, [window.location.pathname]);

  return (
    <GuideCard>
      <div className="box_round min_h cnt_area">
        <div className="mx-5 my-4">
          <p className="h3 bold border-bottom py-3">{window.location.pathname.includes("/guide/about") ? "달리바바 소개" : window.location.pathname.includes("/guide/buyer") ? "바이어 이용방법" : "제조사 이용방법"}</p>
          <div className="d-flex justify-content-between mt-4">
            <p>&nbsp;</p>
            <div id="guide_wrap" style={{minHeight: "916px"}}>
              <img
                src={process.env.PUBLIC_URL + `/images/guide/${imgSrc}`}
                id="guide_img"
                style={{width: "100%"}}
              />
            </div>
          </div>
        </div>
      </div>
    </GuideCard>
  );
};

export default Guide;
