const search = (openClose, title, category, type) => {
  return {
    type: "OPEN_SEARCH",
    payload: {
      openClose,
      content: {
        title,
        category,
        type,
      },
    },
  };
};

const searchResult = (category, type) => {
  return {
    type: "OPEN_SEARCH_RESULT",
    payload: {
      result: {
        category,
        type,
      },
    },
  };
};

export default {
  search,
  searchResult,
};
