import React, {useEffect, useRef, useState} from "react";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import APIHelper from "../../../helpers/APIHelper";
import Constants from "../../../constants/constants";
import CommonLib from "../../../libraries/CommonLib";
import PasswordChecklist from "react-password-checklist";

const MyAdminInfo = (props) => {
  const [imageFile, setImageFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const imageFileRef = useRef();

  const handleImageChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setImageFile(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const [renew, setRenew] = useState(0);

  const [id, setId] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validPassword, setValidPassword] = useState(true);
  const [name, setName] = useState('');
  const [nickname, setNickname] = useState('');
  const [imageFid, setImageFid] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [phone3, setPhone3] = useState('');
  const [position, setPosition] = useState('');
  const [task, setTask] = useState('');
  const [remark, setRemark] = useState('');

  const load = async () => {
    const {data: {code, result, message}} = await APIHelper.get("admin/me");

    setId(result?.id || 0)
    setEmail(result?.email || '');
    setPassword('');
    setName(result?.name || '');
    setNickname(result?.nickname || '');
    setImageFid(result?.image_fid || '');
    if (result?.phone?.length > 0 && result?.phone.includes('-')) {
      setPhone1(result?.phone.split('-')[0]);
      setPhone2(result?.phone.split('-')[1]);
      setPhone3(result?.phone.split('-')[2]);
    } else {
      setPhone1('');
      setPhone2('');
      setPhone3('');
    }
    setPosition(result?.position || '');
    setTask(result?.task || '');
    setRemark(result?.remark || '');
  }

  const register = async () => {
    try {
      const files = imageFileRef?.current?.files;
      const response = await APIHelper.postForm("admin/me/update", {
        id,
        email,
        password,
        name,
        nickname,
        image: files ? files[0] : null,
        phone: phone1 + '-' + phone2 + '-' + phone3,
        position,
        task,
        remark,
      });
      const {data: {code, result, message}} = response;
      if (code === 200) {
        setRenew(new Date().getTime());

        try {
          await APIHelper.postForm("admin/action/log", {
            content: "매니저 개인호 > 내 정보 > 저장",
            param1: id,
            param2: email,
            param3: name,
            param4: nickname,
            param5: files ? files[0] : null,
            param6: phone1 + '-' + phone2 + '-' + phone3,
            param7: position,
            param8: task,
            param9: remark,
          });
        } catch (err) {
          console.log(err);
        }
      }
      await CommonLib.jalert(message);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "매니저 개인홈 > 내 정보",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)", maxWidth: "1250px"}}
      >
        <section className="col-12" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">내 정보</p>
              <p className="m-1 mb-2">
                <button
                  type="button"
                  className="btn btn-purple txt-sm py-1 px-3"
                  onClick={register}
                  disabled={password?.length > 0 && !validPassword}
                >
                  저장
                </button>
              </p>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll mt-2"
              style={{height: "calc(100% - 60px)"}}
            >
              <article className="wid_inline mt-4">
                <div className="d-flex ">
                  <p className="text-center" style={{marginRight: "15px"}}>
                    {(imageFid || imagePreviewUrl) ?
                      <img
                        src={imageFid.length > 0 ? `${Constants.API_HOST}/common/download?fid=${imageFid}&cd=inline&thumb=400` : imagePreviewUrl}
                        style={{
                          width: "190px",
                          height: "190px",
                          objectFit: "cover",
                        }}
                        onClick={() => imageFileRef.current.click()}
                      />
                      :
                      <div style={{width: "190px", height: "190px", backgroundColor: "#efefef"}}></div>
                    }
                    <input
                      type="file"
                      ref={imageFileRef}
                      style={{display: "none"}}
                      onChange={handleImageChange}
                    />
                    <button
                      type="button"
                      className="btn btn-sm btn-purple w-100 my-2"
                      style={{marginLeft: 0}}
                      onClick={() => imageFileRef.current.click()}
                    >
                      이미지 등록
                    </button>
                  </p>
                  <div className="">
                    <table className="table basic">
                      <colgroup>
                        <col width="19%"/>
                        <col width="25%"/>
                        <col width="19%"/>
                        <col width="*"/>
                      </colgroup>
                      <tr>
                        <th className="center">아이디(이메일)</th>
                        <td colSpan="3">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="이메일 입력"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setEmail(e.target.value)}
                            value={email}
                            readOnly={true}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">비밀번호</th>
                        <td colSpan="3">
                          <input
                            type="password"
                            className="form-control form-control-sm"
                            placeholder="비밀번호 입력"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setPassword(e.target.value)}
                            value={password}
                          />
                          {password?.length > 0 &&
                            <PasswordChecklist
                              rules={[
                                "minLength",
                                "specialChar",
                                "number",
                                // "match"
                                // "capital",
                              ]}
                              minLength={7}
                              value={password}
                              // valueAgain={passwordRe}
                              messages={{
                                minLength: "비밀번호는 최소 7자 이상이어야 합니다.",
                                specialChar: "비밀번호는 특수문자를 포함해야 합니다.",
                                number: "비밀번호는 숫자를 포함해야 합니다.",
                                capital: "비밀번호는 대문자를 포함해야 합니다.",
                                match: "비밀번호가 일치합니다.",
                              }}
                              onChange={(e) => setValidPassword(e)}
                            />
                          }
                        </td>
                      </tr>
                      <tr>
                        <th className="center">이름</th>
                        <td className="border-top">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="이름 입력"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setName(e.target.value)}
                            value={name}
                          />
                        </td>
                        <th className="center">닉네임</th>
                        <td>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="닉네임 입력"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setNickname(e.target.value)}
                            value={nickname}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">전화번호</th>
                        <td colspan="3">
                          <div className="input-group w-65">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              onChange={async (e) => await setPhone1(e.target.value)}
                              value={phone1}
                            />
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              onChange={async (e) => await setPhone2(e.target.value)}
                              value={phone2}
                            />
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              onChange={async (e) => await setPhone3(e.target.value)}
                              value={phone3}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="center">직급</th>
                        <td>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="직급 입력"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setPosition(e.target.value)}
                            value={position}
                          />
                        </td>
                        <th className="center">담당업무</th>
                        <td>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="담당업무 입력"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setTask(e.target.value)}
                            value={task}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="d-flex justify-content-between border-bottom pb-2 mt-4">
                  <p className="txt-md">비고</p>
                  <p></p>
                </div>

                <table className="table basic w-100">
                  <tr>
                    <th className="center">
                      <textarea
                        style={{background: "#fff"}}
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        onChange={async (e) => await setRemark(e.target.value)}
                        value={remark}
                      ></textarea>
                    </th>
                  </tr>
                </table>
              </article>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default MyAdminInfo;
