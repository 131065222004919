import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import MakerProjectCard from "./MakerProjectCard";
import last_page_black_48dp from "../../../image/last_page_black_48dp.svg";
import navigate_next_black_48dp from "../../../image/navigate_next_black_48dp.svg";
import chevron_left_black_24dp from "../../../image/chevron_left_black_24dp.svg";
import first_page_black_48dp from "../../../image/first_page_black_48dp.svg";
import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import moment from "moment";
import CommonLib from "../../../libraries/CommonLib";
import Constants from "../../../constants/constants";
import Paging from "../../common/Paging";
import Icon_bell_y from "../../../image/Icon_bell_y.svg";

const MakerProjectProgressList = (props) => {
  const [dv, setDv] = useState(window.location.pathname.includes('progress') ? "progress" : "done");
  const [requests, setRequests] = useState();
  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      await getRequests();
      if (dv === "done") await APIHelper.putForm("project/done/read");
    }
    fetch();
  }, [currPage]);

  const getRequests = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("project/maker/request/gets", {orderBy: "desc", dv, paging: 1, articlesPerPage: 5, currPage});
      if (code === 200) {
        if (pageInfo) setPageInfo(pageInfo);

        for (const [key, item] of Object.entries(result)) {
          const {data: {code, result: quickmessage}} = await APIHelper.getForm("project/timeline/quickmessage", {projectMakerRequestId: item?.id, type: 'MAKER'});
          if (CommonLib.parseInt(quickmessage?.id) > 0) item.action_yn = 1;
          else item.action_yn = 0;
        }

        setRequests(result);
      }
      window.scrollTo(0, 0);
      return resolve(true);
    });
  }

  return (
    <MakerProjectCard>
      <div className="box_round min_h cnt_area pb-5">
        <div className="mx-5 my-4 pb-0">
          <p className="h3 bold border-bottom py-3">{dv === 'progress' ? '진행중' : '완료된'} 작업(Task)</p>

          {/*<div className="box_round_4_paddingNo bg-lpurple p-4 px-5 my-4">*/}
          {/*  <div className="input-group mb-3">*/}
          {/*    <input*/}
          {/*      type="text"*/}
          {/*      className="form-control border_none"*/}
          {/*      placeholder="제조사 명 또는 아래 키워드를 선택하세요"*/}
          {/*    />*/}
          {/*    <button*/}
          {/*      className="btn btn_puple px-4"*/}
          {/*      type="button"*/}
          {/*      id="button-addon2"*/}
          {/*    >*/}
          {/*      검색*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*  <div className="d-flex">*/}
          {/*    <p className="px-3 box_round_sm bg-lpurple2 txt-sm mx-1">*/}
          {/*      거래태그*/}
          {/*    </p>*/}
          {/*    <p className="px-3 box_round_sm bg-lpurple2 txt-sm mx-1">*/}
          {/*      Skincare*/}
          {/*    </p>*/}
          {/*    <p className="px-3 box_round_sm bg-lpurple2 txt-sm mx-1">*/}
          {/*      Face Oil*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<p className="border-bottom py-3">*/}
          {/*  {dv === 'progress' ? '진행중' : '완료된'} 작업 : <span className="bold">총 {requests?.length}건</span>*/}
          {/*</p>*/}

          {requests && requests.map((request, key) => {
              let stepTitle = '';
              if (request.step === 1) stepTitle = 'STEP1 : 단가조사';
              else if (request.step === 2) stepTitle = 'STEP2 : 샘플 디자인';
              else if (request.step === 3) stepTitle = 'STEP3 : 견적 및 계약';
              else if (request.step === 4) stepTitle = 'STEP4 : 제품 제작';
              else if (request.step === 5) stepTitle = 'STEP5 : 통관/선적';
              else if (request.step === 6) stepTitle = 'STEP6 : 완료';

              return (
                <Link to={`/project/maker/task/home/${dv}/${request.id}`}>
                  <div
                    className=" border-bottom"
                    style={{borderTop: "1px solid #aaa !important"}}
                  >
                    <div className="border-bottom p-3 p_rative">
                      <div className="p_absolute_tr3" style={{top: "15px"}}>
                        <p className="box_round_sm bg-orange bold txt-sm">
                          {stepTitle}
                        </p>
                      </div>
                      <p className="p_absolute_br2 txt-sm" style={{bottom: "20px"}}>
                        제작 의뢰 요청일 : {moment(request.created_at).format("YYYY.MM.DD")}
                      </p>
                      <div className="txt-gray p_rative w-100 ">
                        <p>요청 No : {request.req_no}</p>
                        <p className="h5 bold pt-1 txt-point">{request.request_form.request_name}</p>
                        <p className="txt-sm">제작 의뢰서 : {request.request_form.request_no}</p>
                      </div>
                      {request?.action_yn === 1 &&
                        <div className="d-block" style={{position: "absolute", marginTop: "-82px", marginLeft: "180px"}}>
                          <p style={{backgroundColor: "#fcb042", width: "120px", height: "30px", borderRadius: "15px"}}>
                            <button type="button" className="btn btn-sm">
                              <img src={Icon_bell_y} style={{width: "16px", marginTop: "-2px"}}/>
                              <span style={{color: "#a93f00", marginLeft: "7px", fontWeight: "bold"}}>액션 필요</span>
                            </button>
                          </p>
                        </div>
                      }
                    </div>
                    <div className="row py-3">
                      <div
                        className="col col-8"
                        style={{borderRight: "1px solid #ddd"}}
                      >
                        <div className="mt-3">
                          <table className="table basic_ssm">
                            <colgroup>
                              <col width="20%"/>
                              <col width="*"/>
                              <col width="18%"/>
                              <col width="22%"/>
                            </colgroup>
                            <tr>
                              <th className="center">제품명</th>
                              <td>{request.category.name_kr}-{request.product.name_kr}</td>
                              <th className="center">용량</th>
                              <td>{CommonLib.numberWithCommas(request?.projectProduct?.wish_volume)}ml</td>
                            </tr>
                            <tr>
                              <th className="center">판매국가</th>
                              <td>{request?.country?.name_kr}</td>
                              <th className="center">MOQ</th>
                              <td>{CommonLib.processQuantity(request.category.name_kr, request?.projectProduct?.wish_quantity)}</td>
                            </tr>
                          </table>
                        </div>
                      </div>

                      <div className="col col-4 p_rative" style={{minWidth: "264px"}}>
                        <div className="m-2 p_rative" style={{minWidth: "264px"}}>
                          <div className="thum_buyer_info p_absolute p-3">
                            <p
                              className="box_round_30 bg-white px-1 txt-sm"
                              style={{width: "53px"}}
                            >
                              바이어
                            </p>
                            <p className="h5 bold mt-1">{request?.project?.buyer && (request.project.buyer?.company?.length > 0 ? request.project.buyer.company.substring(0, 4) + "***" : (request.project.buyer?.first_name + " ***"))}</p>
                            <p>
                              {/*<img*/}
                              {/*  src={Australia}*/}
                              {/*  style={{width: "24px", marginBottom: "2px"}}*/}
                              {/*/>{" "}*/}
                              {request.project.buyer.country.name_kr}{" "}
                            </p>
                          </div>
                          <div className="thum_buyer">
                            {request.project.buyer.rep_image_fid && request.project.buyer.rep_image_fid.length > 0 ?
                              <img
                                src={`${Constants.API_HOST}/common/download?fid=${request.project.buyer.rep_image_fid}&cd=inline&thumb=400`}
                                style={{width: "263px", height: "120px", objectFit: "cover"}}
                              />
                              :
                              <div style={{width: "263px", height: "120px", objectFit: "cover", backgroundColor: "#efefef"}}></div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            }
          )}
          {requests && requests.length === 0 &&
            <div className="col w-100">
              <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-lblue bold">
                {dv === 'progress' ? '진행 중인' : '완료된'} 작업이 없습니다.
              </div>
            </div>
          }
        </div>
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </div>
    </MakerProjectCard>
  );
};

export default MakerProjectProgressList;
