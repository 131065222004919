import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import openTimelineText from "../../actions/openTimelineText";
import $ from "jquery";

import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import CommonLib from "../../libraries/CommonLib";

const TimelineTextModal = (props) => {
  const closeModal = () => {
    props.openTimelineText("closed", "");
  };

  const {timelineId, formType, title, projectId, projectProductId, projectMakerRequestId, renew} = props.timelineTextPop?.content;
  const [readOnly, setReadOnly] = useState(false);

  let modalInlineStyle;

  if (props.timelineTextPop.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }

  const [content, setContent] = useState('');

  useEffect(() => {
    const fetch = async () => {
      setReadOnly(false);
      setContent('');
      $('#content').focus();
      const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/get", {id: timelineId, status: formType && formType.endsWith(' 확인') ? formType.replace(' 확인', '') : formType});
      if (CommonLib.parseInt(result?.id) > 0) {
        setReadOnly(true);
        if (title === '물류사 정보') setContent(result?.ref_ctnt_1 || '달리바바 물류사');
        else setContent(result?.ref_ctnt_1 || '');
      }
    }
    fetch();
  }, [renew])

  const submit = async () => {
    if (formType === '제작의뢰서 수신 - 제조사 피드백') {
      {
        const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
          projectId,
          projectProductId,
          projectMakerRequestId,
          refCtnt1: content,
          step: 1,
          type: 'MAKER',
          status: formType
        });
      }
      {
        const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
          projectId,
          projectProductId,
          projectMakerRequestId,
          refCtnt1: content,
          step: 1,
          type: 'MANAGER',
          status: formType
        });
      }
      await CommonLib.jalert('피드백이 등록되었습니다.');
    } else if (formType === '견적서 확인 및 진행 유무 판단 - 바이어 피드백' || formType === '견적서 확인 및 진행 유무 판단 - 매니저 피드백') {
      {
        const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
          projectId,
          projectProductId,
          projectMakerRequestId,
          refCtnt1: content,
          step: 1,
          type: 'BUYER',
          status: formType
        });
      }
      {
        const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
          projectId,
          projectProductId,
          projectMakerRequestId,
          refCtnt1: content,
          step: 1,
          type: 'MANAGER',
          status: formType
        });
      }
      await CommonLib.jalert('피드백이 등록되었습니다.');
    } else if (formType === '샘플 견적서 - 바이어 피드백' || formType === '샘플 견적서 - 매니저 피드백') {
      {
        const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
          projectId,
          projectProductId,
          projectMakerRequestId,
          refCtnt1: content,
          step: 2,
          type: 'BUYER',
          status: formType
        });
      }
      {
        const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
          projectId,
          projectProductId,
          projectMakerRequestId,
          refCtnt1: content,
          step: 2,
          type: 'MANAGER',
          status: formType
        });
      }
      await CommonLib.jalert('피드백이 등록되었습니다.');
    } else if (formType === '샘플 수령 - 바이어 피드백' || formType === '샘플 수령 - 매니저 피드백') {
      {
        const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
          projectId,
          projectProductId,
          projectMakerRequestId,
          refCtnt1: content,
          step: 2,
          type: 'BUYER',
          status: formType
        });
      }
      {
        const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
          projectId,
          projectProductId,
          projectMakerRequestId,
          refCtnt1: content,
          step: 2,
          type: 'MANAGER',
          status: formType
        });
      }
      await CommonLib.jalert('피드백이 등록되었습니다.');
    } else if (formType === '본 견적서 - 바이어 피드백' || formType === '본 견적서 - 매니저 피드백') {
      {
        const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
          projectId,
          projectProductId,
          projectMakerRequestId,
          refCtnt1: content,
          step: 3,
          type: 'BUYER',
          status: formType
        });
      }
      {
        const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
          projectId,
          projectProductId,
          projectMakerRequestId,
          refCtnt1: content,
          step: 3,
          type: 'MANAGER',
          status: formType
        });
      }
      await CommonLib.jalert('피드백이 등록되었습니다.');
    }
    closeModal();
  }

  return (
    <div style={{display: modalInlineStyle, zIndex: 999999999}}>
      <div className="pop_box02" style={{width: "650px", minHeight: "400px"}}>
        <div className="POP_title pb-3">
          <h4>
            <strong><span style={{fontSize: "1.5rem"}}>{title}</span></strong>
          </h4>
          <p className="closed">
            <button type="button" className="btn" onClick={closeModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </button>
          </p>
        </div>

        <div className="POP_cnt_box_lg" style={{marginBottom: "0px"}}>
          <table className="table basic_sm w-100">
            <colgroup>
              <col width="25%"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th className="center txt-sm">
                입력
              </th>
              <td>
                <textarea
                  className="form-control"
                  id="content"
                  rows="7"
                  onChange={async (e) => await setContent(e.target.value)}
                  value={content}
                />
              </td>
            </tr>
          </table>
          {!readOnly &&
            <div style={{
              height: "55px",
              textAlign: "center",
              marginTop: "14px",
              padding: "0.6em",
              backgroundColor: "#f7f7f7",
            }}>
              <button className="btn btn_puple" type="button" onClick={submit}>
                저장하기
              </button>
            </div>
          }
        </div>
      </div>
      <div className="pop_bak" onClick={closeModal}></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    timelineTextPop: state.timelineTextPop,
  };
}

function mapDispatchToProps(dispatcher) {
  return bindActionCreators(
    {
      openTimelineText: openTimelineText,
    },
    dispatcher
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TimelineTextModal);
