import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import CommonLib from "../../libraries/CommonLib";

const BuyerId = (props) => {
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [countryCd, setCountryCd] = useState('');
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: "106000"});
        if (code === 200) {
          setCountries(result);
          setCountryCd(result[0]?.cd);
        }
      }
    };
    fetch();
  }, []);

  const submit = async () => {
    window.location.href = "https://wa.me/821057668668";
    // const {data: {code, result, message}} = await APIHelper.getForm("user/find/email", {type: "BUYER", countryCd, firstName, middleName, lastName});
    // await CommonLib.jalert(message);
  }

  return (
    <>
      {/*<div className="mb-3">*/}
      {/*  <select className="form-select"*/}
      {/*          onChange={async (e) => await setCountryCd(e.target.value)}*/}
      {/*          value={countryCd}*/}
      {/*  >*/}
      {/*    {countries && countries.map((item, i) => {*/}
      {/*      return (*/}
      {/*        <option key={i} selected={countryCd === item.cd} value={item.cd}>{item.name_en}</option>*/}
      {/*      );*/}
      {/*    })}*/}
      {/*  </select>*/}
      {/*</div>*/}
      {/*<div className="mb-3">*/}
      {/*  <input*/}
      {/*    type="text"*/}
      {/*    className="form-control"*/}
      {/*    onChange={async (e) => await setFirstName(e.target.value)}*/}
      {/*    value={firstName}*/}
      {/*    placeholder="First Name"*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div className="mb-3">*/}
      {/*  <input*/}
      {/*    type="text"*/}
      {/*    className="form-control"*/}
      {/*    onChange={async (e) => await setMiddleName(e.target.value)}*/}
      {/*    value={middleName}*/}
      {/*    placeholder="Middle Name"*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div className="mb-5">*/}
      {/*  <input*/}
      {/*    type="text"*/}
      {/*    className="form-control"*/}
      {/*    onChange={async (e) => await setLastName(e.target.value)}*/}
      {/*    value={lastName}*/}
      {/*    placeholder="Last Name"*/}
      {/*  />*/}
      {/*</div>*/}
      <div style={{marginTop: "80px"}}>
        <button type="button" className="btn btn_puple w-100 py-2 bold" onClick={() => submit()}>
          Support
        </button>
      </div>
    </>
  );
};

export default BuyerId;
