import React from "react";
import OemCard from "./OemCard";
import main_recome_01 from "../../image/main_recome_01.jpg";
import main_recome_02 from "../../image/main_recome_02.jpg";
import main_recome_03 from "../../image/main_recome_03.jpg";
import main_cate_02 from "../../image/main_cate_02.jpg";
import prod_img01 from "../../image/prod_img01.png";
import prod_img03 from "../../image/prod_img03.png";
import arrowleft from "../../image/arrow-left_w.svg";
import arrowright from "../../image/arrow-right.svg";
import main_cate_01 from "../../image/main_cate_01.jpg";
import prod_img02 from "../../image/prod_img02.png";
import box_img03 from "../../image/box_img03.jpg";
import main_cate_03 from "../../image/main_cate_03.jpg";

const OemHome = (props) => {
  return (
    <OemCard>
      <section
        className="box_round min_h px-4"
        style={{ width: "calc(50% - 10px)" }}
      >
        <article className="py-4">
          <div className="d-flex justify-content-between border-bottom">
            <p className="h5 bold">㈜디엠코스메틱스 제품</p>
            <div
              className="btn-group btn-group-sm"
              role="group"
              aria-label="Basic outlined example"
              style={{ marginBottom: "-1px" }}
            >
              <button type="button" className="btn btn-outline-light p-1 px-3">
                <img src={arrowleft} style={{ width: "10px" }} />
              </button>
              <button type="button" className="btn btn-outline-light p-1 px-3">
                <img src={arrowright} style={{ width: "10px" }} />
              </button>
            </div>
          </div>

          <div className="row row-cols-3 g-3 pt-3">
            <div className="col">
              <div className="box_border">
                <p
                  className="border-bottom"
                  style={{ height: "150px", textAlign: "center" }}
                >
                  <img
                    src={main_cate_02}
                    style={{ height: "149px", objectFit: "cover" }}
                  />
                </p>
                <p className="p-3" style={{ height: "100px" }}>
                  <a href="">
                    <span className="txt-brown bold">제품분류-제품종류</span>
                    <br />
                    <span className="txt-gray txt-sm">
                      Abib Jericho Rose Essence
                      <br />
                      Nutrition Pump 50mL
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div className="col">
              <div className="box_border">
                <p
                  className="border-bottom"
                  style={{ height: "150px", textAlign: "center" }}
                >
                  <img
                    src={prod_img03}
                    style={{ height: "149px", objectFit: "cover" }}
                  />
                </p>
                <p className="p-3" style={{ height: "100px" }}>
                  <a href="">
                    <span className="txt-brown bold">제품분류-제품종류</span>
                    <br />
                    <span className="txt-gray txt-sm">
                      Abib Jericho Rose Essence
                      <br />
                      Nutrition Pump 50mL
                    </span>
                  </a>
                </p>
              </div>
            </div>
            <div className="col">
              <div className="box_border">
                <p
                  className="border-bottom"
                  style={{ height: "150px", textAlign: "center" }}
                >
                  <img
                    src={main_cate_01}
                    style={{ height: "149px", objectFit: "cover" }}
                  />
                </p>
                <p className="p-3" style={{ height: "100px" }}>
                  <a href="">
                    <span className="txt-brown bold">제품분류-제품종류</span>
                    <br />
                    <span className="txt-gray txt-sm">
                      Abib Jericho Rose Essence
                      <br />
                      Nutrition Pump 50mL
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </article>

        <article className="py-4">
          <div className="d-flex justify-content-between border-bottom">
            <p className="h5 bold">비슷한 OEM/ODM 제품 </p>
            <div
              className="btn-group btn-group-sm"
              role="group"
              aria-label="Basic outlined example"
              style={{ marginBottom: "-1px" }}
            >
              <button type="button" className="btn btn-outline-light p-1 px-3">
                <img src={arrowleft} style={{ width: "10px" }} />
              </button>
              <button type="button" className="btn btn-outline-light p-1 px-3">
                <img src={arrowright} style={{ width: "10px" }} />
              </button>
            </div>
          </div>

          <div className="row row-cols-3 g-3 pt-3">
            <div className="col">
              <div className="box_border">
                <p
                  className="border-bottom"
                  style={{ height: "150px", textAlign: "center" }}
                >
                  <img
                    src={main_cate_01}
                    style={{ height: "149px", objectFit: "cover" }}
                  />
                </p>
                <p className="p-3" style={{ height: "100px" }}>
                  <a href="">
                    <span className="txt-brown bold">Elysim Facial Cream</span>
                    <br />
                    <span className="txt-gray txt-sm">
                      Abib Jericho Rose Essence
                      <br />
                      Nutrition Pump 50mL
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div className="col">
              <div className="box_border">
                <p
                  className="border-bottom"
                  style={{ height: "150px", textAlign: "center" }}
                >
                  <img
                    src={prod_img01}
                    style={{ height: "149px", objectFit: "cover" }}
                  />
                </p>
                <p className="p-3" style={{ height: "100px" }}>
                  <a href="">
                    <span className="txt-brown bold">Elysim Facial Cream</span>
                    <br />
                    <span className="txt-gray txt-sm">
                      Abib Jericho Rose Essence
                      <br />
                      Nutrition Pump 50mL
                    </span>
                  </a>
                </p>
              </div>
            </div>
            <div className="col">
              <div className="box_border">
                <p
                  className="border-bottom"
                  style={{ height: "150px", textAlign: "center" }}
                >
                  <img
                    src={prod_img02}
                    style={{ height: "149px", objectFit: "cover" }}
                  />
                </p>
                <p className="p-3" style={{ height: "100px" }}>
                  <a href="">
                    <span className="txt-brown bold">제품분류-제품종류</span>
                    <br />
                    <span className="txt-gray txt-sm">
                      Abib Jericho Rose Essence
                      <br />
                      Nutrition Pump 50mL
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </article>
        <article className="py-4">
          <div className="d-flex justify-content-between border-bottom">
            <p className="h5 bold">비슷한 OEM/ODM 프로젝트</p>
            <div
              className="btn-group btn-group-sm"
              role="group"
              aria-label="Basic outlined example"
              style={{ marginBottom: "-1px" }}
            >
              <button type="button" className="btn btn-outline-light p-1 px-3">
                <img src={arrowleft} style={{ width: "10px" }} />
              </button>
              <button type="button" className="btn btn-outline-light p-1 px-3">
                <img src={arrowright} style={{ width: "10px" }} />
              </button>
            </div>
          </div>

          <div className="row row-cols-2 g-3 pt-3">
            <div className="col">
              <div className="box_border d-flex">
                <p
                  className="w-40"
                  style={{ height: "150px", textAlign: "center" }}
                >
                  <img
                    src={main_recome_02}
                    style={{ height: "149px", objectFit: "cover" }}
                  />
                </p>
                <p className="p-3" style={{ height: "100px" }}>
                  <a href="">
                    <span className="txt-brown bold">Elysim Facial Cream</span>
                    <br />
                    <span className="txt-gray txt-sm">
                      판매국가 : 미국
                      <br />
                      타겟층 : 여성
                      <br />
                      MOQ : 3,000
                      <br />
                      Unit Price : $3.01
                      <br />
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div className="col">
              <div className="box_border d-flex">
                <p
                  className="w-40"
                  style={{ height: "150px", textAlign: "center" }}
                >
                  <img
                    src={main_recome_03}
                    style={{ height: "149px", objectFit: "cover" }}
                  />
                </p>
                <p className="p-3" style={{ height: "100px" }}>
                  <a href="">
                    <span className="txt-brown bold">Elysim Facial Cream</span>
                    <br />
                    <span className="txt-gray txt-sm">
                      판매국가 : 미국
                      <br />
                      타겟층 : 여성
                      <br />
                      MOQ : 3,000
                      <br />
                      Unit Price : $3.01
                      <br />
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </article>
        <article className="py-4">
          <div className="d-flex justify-content-between border-bottom">
            <p className="h5 bold">스킨케어 추천 용기</p>
            <div
              className="btn-group btn-group-sm"
              role="group"
              aria-label="Basic outlined example"
              style={{ marginBottom: "-1px" }}
            >
              <button type="button" className="btn btn-outline-light p-1 px-3">
                <img src={arrowleft} style={{ width: "10px" }} />
              </button>
              <button type="button" className="btn btn-outline-light p-1 px-3">
                <img src={arrowright} style={{ width: "10px" }} />
              </button>
            </div>
          </div>

          <div className="row row-cols-3 g-3 pt-3">
            <div className="col">
              <div className="box_border">
                <p
                  className="border-bottom"
                  style={{ height: "150px", textAlign: "center" }}
                >
                  <img
                    src={main_recome_02}
                    style={{ height: "149px", objectFit: "cover" }}
                  />
                </p>
                <p className="p-3" style={{ height: "65px" }}>
                  <a href="">
                    <span className="txt-brown bold">타입 : 튜브</span>
                  </a>
                </p>
              </div>
            </div>

            <div className="col">
              <div className="box_border">
                <p
                  className="border-bottom"
                  style={{ height: "150px", textAlign: "center" }}
                >
                  <img
                    src={box_img03}
                    style={{ height: "149px", objectFit: "cover" }}
                  />
                </p>
                <p className="p-3" style={{ height: "65px" }}>
                  <a href="">
                    <span className="txt-brown bold">타입 : 진공 콤팩트</span>
                  </a>
                </p>
              </div>
            </div>
            <div className="col">
              <div className="box_border">
                <p
                  className="border-bottom"
                  style={{ height: "150px", textAlign: "center" }}
                >
                  <img
                    src={main_cate_03}
                    style={{ height: "149px", objectFit: "cover" }}
                  />
                </p>
                <p className="p-3" style={{ height: "65px" }}>
                  <a href="">
                    <span className="txt-brown bold">
                      타입 : 진공 싱글 용기
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </article>
        <article className="py-4">
          <div className="d-flex justify-content-between border-bottom">
            <p className="h5 bold">스킨케어 Best 제품 (Amazon & Olive Young)</p>
            <div
              className="btn-group btn-group-sm"
              role="group"
              aria-label="Basic outlined example"
              style={{ marginBottom: "-1px" }}
            >
              <button type="button" className="btn btn-outline-light p-1 px-3">
                <img src={arrowleft} style={{ width: "10px" }} />
              </button>
              <button type="button" className="btn btn-outline-light p-1 px-3">
                <img src={arrowright} style={{ width: "10px" }} />
              </button>
            </div>
          </div>

          <div className="row row-cols-3 g-3 pt-3">
            <div className="col">
              <div className="box_border">
                <p
                  className="border-bottom"
                  style={{ height: "150px", textAlign: "center" }}
                >
                  <img
                    src={main_recome_02}
                    style={{ height: "149px", objectFit: "cover" }}
                  />
                </p>
                <p className="p-3" style={{ height: "100px" }}>
                  <a href="">
                    <span className="txt-brown bold">제품분류-제품종류</span>
                    <br />
                    <span className="txt-gray txt-sm">
                      Abib Jericho Rose Essence
                      <br />
                      Nutrition Pump 50mL
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div className="col">
              <div className="box_border">
                <p
                  className="border-bottom"
                  style={{ height: "150px", textAlign: "center" }}
                >
                  <img
                    src={main_recome_01}
                    style={{ height: "149px", objectFit: "cover" }}
                  />
                </p>
                <p className="p-3" style={{ height: "100px" }}>
                  <a href="">
                    <span className="txt-brown bold">제품분류-제품종류</span>
                    <br />
                    <span className="txt-gray txt-sm">
                      Abib Jericho Rose Essence
                      <br />
                      Nutrition Pump 50mL
                    </span>
                  </a>
                </p>
              </div>
            </div>
            <div className="col">
              <div className="box_border">
                <p
                  className="border-bottom"
                  style={{ height: "150px", textAlign: "center" }}
                >
                  <img
                    src={main_cate_02}
                    style={{ height: "149px", objectFit: "cover" }}
                  />
                </p>
                <p className="p-3" style={{ height: "100px" }}>
                  <a href="">
                    <span className="txt-brown bold">제품분류-제품종류</span>
                    <br />
                    <span className="txt-gray txt-sm">
                      Abib Jericho Rose Essence
                      <br />
                      Nutrition Pump 50mL
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </article>
      </section>
    </OemCard>
  );
};

export default OemHome;
