import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import openDesignResult from "../../actions/openDesignResult";
import thum_comp_01 from "../../image/thum_comp_01.png";
import file_download_w from "../../image/file_download_w.svg";

import "../../sass/main.scss";

const DesignResultModal = (props) => {
  const closeModal = () => {
    props.openDesignResult("closed", "");
  };

  let modalInlineStyle;

  if (props.designResultPop.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }
  return (
    <div style={{ display: modalInlineStyle }}>
      <div className="pop_box02" style={{ width: "850px", minHeight: "500px" }}>
        <div className="POP_title pb-3">
          <h4>
            <strong>디자인 시안/결과 확인</strong>
          </h4>
          <p className="closed">
            <button onClick={closeModal} type="button" className="btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </p>
        </div>

        <div className="POP_cnt_box_lg" style={{ marginBottom: "90px" }}>
          <table className="table basic_sm w-100">
            <colgroup>
              <col width="20%" />
              <col width="*" />
            </colgroup>
            <tr>
              <th className="center txt-sm">시안 이미지</th>
              <td>
                <div className="d-flex">
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                </div>
                <div className="d-flex">
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <th className="center txt-sm">시안 PDF</th>
              <td className="d-flex">
                <p className="pt-1">전개도 및 시안 전체.pdf</p>
                <p className="mx-4">
                  <button className="btn btn-sm btn_puple" type="button">
                    다운로드
                  </button>
                </p>
              </td>
            </tr>
            <tr>
              <th className="center txt-sm">디자이너 의견 </th>
              <td>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </td>
            </tr>
            <tr>
              <th className="center txt-sm">피드백</th>
              <td>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </td>
            </tr>
          </table>
        </div>
        <div className="POP_foot">
          <button className="btn btn_puple" type="button">
            의뢰서 전송
          </button>
        </div>
      </div>
      <div className="pop_bak"></div>
      <div className="pop_box02" style={{ width: "850px", minHeight: "500px" }}>
        <div className="POP_title pb-3">
          <h4>
            <strong>디자인 시안/결과 확인</strong>
          </h4>
          <p className="closed">
            <button type="button" className="btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </p>
        </div>

        <div className="POP_cnt_box_lg" style={{ marginBottom: "90px" }}>
          <table className="table basic_sm w-100">
            <colgroup>
              <col width="20%" />
              <col width="*" />
            </colgroup>
            <tr>
              <th className="center txt-sm">시안 이미지</th>
              <td>
                <div className="d-flex">
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                </div>
                <div className="d-flex">
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                  <p className="thum_sm">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    >
                      <img src={file_download_w} className="thum_sm_del" />
                    </button>
                    <img src={thum_comp_01} />
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <th className="center txt-sm">시안 PDF</th>
              <td className="d-flex">
                <p className="pt-1" style={{ marginTop: "8px" }}>
                  전개도 및 시안 전체.pdf
                </p>
                <p className="mx-4">
                  <button className="btn btn-sm btn_puple" type="button">
                    다운로드
                  </button>
                </p>
              </td>
            </tr>
            <tr>
              <th className="center txt-sm">디자이너 의견 </th>
              <td>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </td>
            </tr>
            <tr>
              <th className="center txt-sm">피드백</th>
              <td>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </td>
            </tr>
          </table>
        </div>
        <div className="POP_foot">
          <button className="btn btn_puple" type="button">
            의뢰서 전송
          </button>
        </div>
      </div>
      <div className="pop_bak" onClick={closeModal}></div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    designResultPop: state.designResultPop,
  };
}

function mapDispatchToProps(dispatcher) {
  return bindActionCreators(
    {
      openDesignResult: openDesignResult,
    },
    dispatcher
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DesignResultModal);
