import {Link, useHistory} from "react-router-dom";
import "../../sass/main.scss";
import React, {useEffect, useRef, useState} from "react";
import APIHelper from "../../helpers/APIHelper";
import LoginCard from "./LoginCard";
import CommonLib from "../../libraries/CommonLib";
import ReCAPTCHA from "react-google-recaptcha";
import {changeLanguage} from "i18next";

const Login = (props) => {
  const history = useHistory();
  // 아이디 저장 체크여부
  const [idLocalStorage, setIdLocalStorage] = useState(true);

  // id, password
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const idRef = useRef();
  const passwordRef = useRef();
  const recaptchaRef = React.createRef();
  const [userCaptchaValue, setUserCaptchaValue] = useState("");

  // id, password 핸들러
  const idHandler = (e) => {
    setId(e.target.value);
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const handleIdKeyDown = async (e) => {
    if (e.key === 'Enter') {
      passwordRef.current.focus();
    }
  }

  const handlePasswordKeyDown = async (e) => {
    if (e.key === 'Enter') {
      await clickHandler();
    }
  }

  const onChangeRecaptcha = (value) => {
    setUserCaptchaValue(value);
  }

  // 로그인 버튼 핸들러
  const clickHandler = async () => {
    if (!id) {
      setTimeout(() => {
        CommonLib.jalert('아이디를 입력해 주세요');
      }, 100);
      return;
    }
    if (!password) {
      setTimeout(() => {
        CommonLib.jalert('비밀번호를 입력해 주세요');
      }, 100);
      return;
    }
    if (!userCaptchaValue) {
      setTimeout(() => {
        CommonLib.jalert('인증 확인 체크박스를 클릭해 주세요');
      }, 100);
      return;
    }
    if (userCaptchaValue !== recaptchaRef.current.getValue()) {
      setTimeout(() => {
        CommonLib.jalert('보안 인증이 올바르지 않습니다');
      }, 100);
      return;
    }

    try {
      const response = await APIHelper.postForm("user/login", {
        email: id,
        password,
      });
      const {data: {code, result, message}} = response;
      if (code === 200) {
        props.onLogin(result?.email, result?.token, result?.name, result?.userType, idLocalStorage);
        history.push("/");
      } else {
        await CommonLib.jalert(message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <LoginCard>
      <div className="p-4" style={{minWidth: "450px"}}>
        <div className="px-4 pt-4">
          <h4>
            <strong>로그인</strong>
          </h4>
        </div>

        <div className="POP_cnt_box w-100">
          <div className="mb-3">
            <input
              onChange={idHandler}
              type="text"
              className="form-control"
              placeholder="ID"
              aria-describedby="button-addon2"
              ref={idRef}
              autoFocus={true}
              onKeyDown={handleIdKeyDown}
            />
          </div>
          <div className="mb-3">
            <input
              onChange={passwordHandler}
              type="password"
              className="form-control"
              placeholder="Password"
              aria-describedby="button-addon2"
              ref={passwordRef}
              onKeyDown={handlePasswordKeyDown}
            />
          </div>
          <div className="mb-3">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Le2PYwiAAAAAEwhq63MCLytYxJlS-iUI9KqRFhV"
              stoken="6Le2PYwiAAAAAOckZc4dP16GQTT94uxu0OYeTxS9"
              onChange={onChangeRecaptcha}
              size="normal"
            />
          </div>

          {/*<div className="mb-3">*/}
          {/*  <input*/}
          {/*    className="mt-1"*/}
          {/*    type="checkbox"*/}
          {/*    onChange={(e) => setIdLocalStorage(e.currentTarget.checked)}*/}
          {/*    id="checkboxNoLabel"*/}
          {/*    aria-label="..."*/}
          {/*    checked*/}
          {/*  />*/}
          {/*  <span className="align-top" style={{paddingLeft: 5}}>아이디 저장</span>*/}
          {/*</div>*/}
          <div className="mb-3">
            <button
              onClick={clickHandler}
              type="button"
              className="btn btn_puple w-100 py-2 bold"
            >
              로그인
            </button>
          </div>

          <div
            className="mb-3 d-flex justify-content-center log_menu"
            style={{textAlign: "center"}}
          >
            <li>
              <Link to="/find/id">아이디찾기</Link>
            </li>
            <li>
              <Link to="/find/password">비밀번호찾기</Link>
            </li>
          </div>
          <div className="mt-3 txt-lightgray">
            Darlibaba 회원이 아니신가요?{" "}
            <Link
              to="/join/intro"
              style={{color: "#306CC7", textDecoration: "underline"}}
            >
              회원가입
            </Link>
          </div>
        </div>
      </div>
    </LoginCard>
  );
};

export default Login;
