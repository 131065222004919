import React, {useContext, useEffect, useState} from "react";
import logo from "../image/logo.png";
import "../sass/main.scss";
import {Link, useHistory} from "react-router-dom";
import AuthContext from "./auth-context";
import country_kr from "../image/country.kr.png";
import country_en from "../image/country.en.png";
import {useTranslation} from "react-i18next";
import {changeLanguage} from "i18next";
import GoogleTranslate from "./GoogleTranslate";
import $ from "jquery";
import CommonLib from "../libraries/CommonLib";
import APIHelper from "../helpers/APIHelper";
import {GlobalContext} from "./global-context";

const NavBar = (props) => {
  const path = window.location.pathname;
  const history = useHistory();
  const ctx = useContext(AuthContext);
  const {t} = useTranslation();
  const state = useContext(GlobalContext)

  const [unread, setUnread] = useState(0);
  const [projectUnread, setProjectUnread] = useState(0);
  const [requestUnread, setRequestUnread] = useState(0);

  const getUnreads = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {result: result1}} = await APIHelper.getForm("user/message/unread/check");
      const {data: {result: result2}} = await APIHelper.getForm("user/oem/proposal/unread/check");

      const unreads = result1 + result2;

      setUnread(unreads);

      if (localStorage.getItem("user.session.user_type")?.length > 0) {
        {
          let projectUnread = 0;
          const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/quickmessage", {type: localStorage.getItem("user.session.user_type")});
          if (code === 200 && result?.id > 0)
            projectUnread = 1;
          setProjectUnread(projectUnread);
        }
        if (localStorage.getItem("user.session.user_type") === "MAKER") {
          let cnt = 0;
          const {data: {code, result, message}} = await APIHelper.getForm("user/maker/request/unread/check");
          if (code === 200 && parseInt(result, 10) > 0)
            cnt = 1;
          setRequestUnread(cnt);
        }
      }

      return resolve(unreads + projectUnread + requestUnread);
    });
  };

  useEffect(() => {
    const fetch = async () => {
      await getUnreads();
    }
    fetch();
  }, [state.refreshAlarm]);

  if (path === "/" || path.includes("admin")) {
    return "";
  }

  const changeLang = async (ln) => {
    alert($('iframe'))
    localStorage.setItem("lang", ln);
    await changeLanguage(ln);
    if (ln === 'kr') {
      GoogleTranslate.googleTranslateToOrigin();
    } else {
      if (!window.location.pathname.startsWith("/admin")) {
        const lang = '영어';
        const frame = $('.goog-te-menu-frame:first');
        try {
          frame.contents().find('.goog-te-menu2-item span.text:contains(' + lang + ')').get(0).click();
        } catch (err) {
        }
        try {
          frame.contents().find('.goog-te-menu2-item span.text:contains(' + lang + ')').get(0).click();
        } catch (err) {
        }
      }
    }

    CommonLib.processTranslation();
  }

  return (
    <nav id="GNB_MENU" className="navbar sticky-top p-0">
      <div
        className="MAX w-100 d-flex justify-content-between p_absolute"
        style={{
          top: "0",
          left: "50%",
          transform: "translate(-50%, 0)",
          zIndex: "100",
          marginTop: "10px",
        }}
      >
        <p className="mt-3 pl-3 text-left float-left">
          <a style={{cursor: 'pointer'}} onClick={() => history.push('/')}>
            <img src={logo} style={{width: "140px"}}/>
          </a>
        </p>
        <ul
          id="sidebarMenu"
          className="TOP_menu"
          style={{marginTop: "4px", marginLeft: "8px"}}
        >
          {((localStorage.getItem("user.session.user_type") || '') === 'BUYER' || (localStorage.getItem("user.session.user_type") || '') === '') &&
            <li>
              <Link
                onClick={() => {
                  localStorage.setItem("manufacture.result", JSON.stringify([]));
                  history.push('/manufacture');
                }}
                style={{cursor: 'pointer'}}
                className={`${path.startsWith("/manufacture") === true ? "ON" : ""}`}
              >
                {t('navbar.production_inquiry')}
              </Link>
            </li>
          }
          {/*2023.08.09 수정*/}
          {/*{(localStorage.getItem("user.session.user_type") || '') !== 'BUYER' &&*/}
          {/*  <li>*/}
          {/*    <Link*/}
          {/*      to="/buyer/find"*/}
          {/*      className={`${path.startsWith("/buyer/") === true ? "ON" : ""}`}*/}
          {/*    >*/}
          {/*      {t('navbar.find_buyer')}*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*}*/}
          {/*{(localStorage.getItem("user.session.user_type") || '') !== 'MAKER' &&*/}
          {/*  <li>*/}
          {/*    <Link*/}
          {/*      to="/maker/find"*/}
          {/*      className={`${path.startsWith("/maker/") === true ? "ON" : ""}`}*/}
          {/*    >*/}
          {/*      {t('navbar.find_manufacturer')}*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*}*/}
          <li>
            <Link
              to="/search"
              className={`${path.includes("search") === true ? "ON" : ""}`}
            >
              {t('navbar.dictionary')}
            </Link>
          </li>
        </ul>
        <ul
          id="sidebarMenu"
          className="TOP_menu_second"
          style={{paddingTop: "2px"}}
        >
          {!ctx.isUserLoggedIn && (
            <>
              <li>
                <Link to="/login">{t('navbar.login')}</Link>
              </li>
              <li>
                <Link onClick={ctx.onLogout} to="/join/intro">
                  {t('navbar.join')}
                </Link>
              </li>
            </>
          )}
          {ctx.isUserLoggedIn && (
            <>
              <li>
                {localStorage.getItem("user.session.user_type") ===
                "MAKER" ? (
                  <Link to="/project/maker/request">{t('navbar.project')}</Link>
                ) : (
                  <Link to="/project/buyer/task/progress">{t('navbar.project')}</Link>
                )}
                {CommonLib.parseInt(projectUnread) > 0 && <div className="new-icon3" style={{marginLeft: "45px"}}>N</div>}
              </li>
              <li>
                <Link to="/mypage/home">
                  {t('navbar.mypage')}
                  {CommonLib.parseInt(unread) > 0 && <div className="new-icon3" style={{marginLeft: "57px"}}>N</div>}
                </Link>
              </li>
              <li>
                <Link onClick={ctx.onUserLogout} to="/login">
                  {t('navbar.logout')}
                </Link>
              </li>
            </>
          )}
          {/*<li style={{border: 0}}>*/}
          {/*  <img src={country_kr} id="btn_lang_kr" style={{width: "20px", borderStyle: "solid", borderWidth: "1px", borderColor: "#afafaf", cursor: "pointer"}} onClick={() => changeLang('kr')}/>*/}
          {/*  <img src={country_en} id="btn_lang_en" style={{width: "20px", marginLeft: "5px", borderStyle: "solid", borderWidth: "1px", borderColor: "#afafaf", cursor: "pointer"}} onClick={() => changeLang('en')}/>*/}
          {/*</li>*/}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
