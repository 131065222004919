import React, {useEffect, useState} from "react";
import MypageCard from "./MypageCard";
import toggle_off from "../../image/toggle_off.svg";
import toggle_on from "../../image/toggle_on.svg";
import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import CommonLib from "../../libraries/CommonLib";

const MypagePreference = (props) => {
  // 문자, 이메일 수신 on-off
  const [isMessaging, setIsMessaging] = useState(false);
  const [isEmailing, setIsEmailing] = useState(false);
  const [user, setUser] = useState({});

  const [langs, setLangs] = useState([]);
  const [langCd, setLangCd] = useState('');

  const getLangs = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '105000'});
      if (code === 200) {
        setLangs(result);
      }
      return resolve(true);
    });
  }

  const getPreference = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("user/preference");
      if (code === 200) {
        setUser(result);
        setLangCd(result?.lang_cd);

        if (result?.sms_yn === 'Y') setIsMessaging(true);
        else setIsMessaging(false);

        if (result?.mailing_yn === 'Y') setIsEmailing(true);
        else setIsEmailing(false);
      }
      return resolve(true);
    });
  }

  const submit = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.putForm("user/preference", {
        langCd,
        mailingYn: isEmailing ? 'Y' : 'N',
        smsYn: isMessaging ? 'Y' : 'N',
      });
      await CommonLib.jalert(message);
      return resolve(true);
    });
  };

  useEffect(() => {
    const fetch = async () => {
      await getLangs();
      await getPreference();
    }
    fetch();
  }, []);

  const messagingOnHandler = () => {
    setIsMessaging(!isMessaging);
  };
  const emailingOnHandler = () => {
    setIsEmailing(!isEmailing);
  };

  return (
    <MypageCard>
      <div className="box_round cnt_area pb-5">
        <div className="mx-5 my-4">
          <p className="h3 bold border-bottom py-3">설정</p>
          <br/>

          <div className="my-5">
            <table className="table basic my-3" style={{textAlign: "center"}}>
              <colgroup>
                <col width="20%"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th rowspan="2">알림 설정</th>
                <td className="left">
                  <div className="d-flex">
                    <p className="py-1 bold" style={{width: "110px"}}>
                      알림 문자 수신
                    </p>
                    <button
                      type="button"
                      className="btn btn-sm py-0"
                      onClick={messagingOnHandler}
                    >
                      <img
                        style={{width: "40px"}}
                        src={`${
                          isMessaging === false ? toggle_off : toggle_on
                        }`}
                      />
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="left">
                  <div className="d-flex">
                    <p className="py-1 bold" style={{width: "110px"}}>
                      알림 이메일 수신
                    </p>
                    <button
                      type="button"
                      className="btn btn-sm py-0"
                      onClick={emailingOnHandler}
                    >
                      <img
                        src={`${isEmailing === false ? toggle_off : toggle_on}`}
                        style={{width: "40px"}}
                      />
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>선호 언어</th>
                <td className="left">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={async (e) => await setLangCd(e.target.value)}
                    value={langCd}
                  >
                    {langs && langs.map((item, key) => <option selected value={item.cd}>{item.name_kr}</option>)}
                  </select>
                  <p className="py-1 txt-sm">
                    (준비중)선호 언어로 변경을 해줍니다. (바이어 상세, 제품상세)
                  </p>
                </td>
              </tr>
              <tr>
                <th>시간대</th>
                <td className="left">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>(UTC +9:00) 한국</option>
                  </select>
                  <p className="py-1 txt-sm">
                    (준비중)선택하신 시간대로 날짜 및 시간을 표현합니다.
                  </p>
                </td>
              </tr>
            </table>

            <div className="d-flex justify-content-center my-5">
              <p/>
              <button type="button" className="btn btn_puple px-3" onClick={submit}>
                저장
              </button>
            </div>
          </div>
        </div>
      </div>
    </MypageCard>
  );
};

export default MypagePreference;
