import React, {useState} from "react";
import favorite from "../../image/favorite.svg";
import favorite_border from "../../image/favorite_border.svg";
import "../../sass/main.scss";
import CommonLib from "../../libraries/CommonLib";
import Constants from "../../constants/constants";

const BuyerDetail = (props) => {
  const {buyer} = props;
  const [isFavorite, setIsFavorite] = useState(false);

  const favoriteHandler = () => {
    setIsFavorite(!isFavorite);
  }

  return (
    <div className="cnt_area_comp p-5">
      {/*<p className="p_absolute_tr">*/}
      {/*  <button type="button" className="btn btn-sm">*/}
      {/*    <img*/}
      {/*      className="mb-1"*/}
      {/*      src={`${isFavorite ? favorite : favorite_border}`}*/}
      {/*      style={{width: "31px"}}*/}
      {/*    />*/}
      {/*  </button>*/}
      {/*</p>*/}
      <p className="h5 bold txt-point">회사 기본정보</p>

      <table className="table table-sm basic_sm my-4">
        <colgroup>
          <col width="20%"/>
          <col width="*"/>
        </colgroup>

        <tr>
          <th className="border-right">회사명</th>
          <td>{buyer.company}</td>
        </tr>
        <tr>
          <th className="border-right">국적</th>
          <td>{buyer.country?.name_kr}</td>
        </tr>

        <tr>
          <th className="border-right">타겟 국가</th>
          <td>{buyer.market_country}</td>
        </tr>
        <tr>
          <th className="border-right">프로젝트 횟수</th>
          {/*TODO*/}
          <td>0회</td>
        </tr>
        <tr>
          <th className="border-right">추가정보</th>
          <td>
            <div className="d-flex">
              {buyer?.offstore_name?.length > 0 && <p className="Tag1">오프라인 매장</p>}
              {buyer?.license_fid?.length > 0 && <p className="Tag1">사업자</p>}
              {buyer?.onshop_url?.length > 0 && <p className="Tag1">온라인 몰</p>}
              {buyer?.company_website?.length > 0 && <p className="Tag1">홈페이지</p>}
              {(buyer?.youtube?.length > 0 || buyer?.facebook?.length > 0 || buyer?.instagram?.length > 0 || buyer?.twitter?.length > 0 || buyer?.tiktok?.length > 0 || buyer?.etc_sns?.length > 0) && <p className="Tag1">SNS</p>}
            </div>
          </td>
        </tr>

        <tr>
          <th className="border-right">선호하는 상품 분야 Tag</th>
          <td>
            <div className="d-flex">
              {buyer.favorites && buyer.favorites.map((item, key) => (
                <p key={key} className="Tag1">{item.name_kr}</p>
              ))}
            </div>
          </td>
        </tr>
      </table>

      {buyer?.photos?.length > 0 && buyer.summary.length > 0 &&
        <>
          <p className="h5 bold txt-point border-bottom py-3">소개</p>

          <div className="row row-cols-3 py-5">
            {buyer.photos && buyer.photos.map((item, key) => (
              <div className="col px-3">
                <p>
                  <img
                    src={item?.photo_fid.length > 0 ? `${Constants.API_HOST}/common/download?fid=${item.photo_fid}&cd=inline&thumb=400` : ""}
                    style={{height: "175px", objectFit: "cover"}}
                  />
                </p>
              </div>
            ))}
          </div>

          <div className="mb-5">
            <p>
              {CommonLib.escapedNewLineToLineBreakTag(buyer.summary)}
            </p>
          </div>
        </>
      }

      {/*<p className="h5 bold txt-point py-3">프로젝트 History</p>*/}

      {/*<div className="box_border">*/}
      {/*  <div className="border-bottom d-flex">*/}
      {/*    <div className="w-70" style={{borderRight: "1px solid #ddd"}}>*/}
      {/*      <ul className="m-3 mx-4 border-bottom p-0 pb-2 px-2">*/}
      {/*        <li className="h5 bold">세럼 OEM</li>*/}
      {/*        <li className="txt-sm txt-gray">*/}
      {/*          프로젝트 기간 : 2021.10.22 ~ 2021.12.30*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="m-3 mx-4 p-0 pb-2 px-2">*/}
      {/*        <li>g*****5 (1년전)</li>*/}
      {/*        <li className=" txt-gray">*/}
      {/*          매우 친절하시고 다양한 요구조건도 잘 맞춰주셔서 매우*/}
      {/*          만족스럽습니다.*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*    <div className="w-30 p-3">*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 h5"*/}
      {/*          style={{textAlign: "right", color: "#D56451"}}*/}
      {/*        >*/}
      {/*          5*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <img src={star_r} style={{width: "20px"}}/>*/}
      {/*          <img src={star_r} style={{width: "20px"}}/>*/}
      {/*          <img src={star_r} style={{width: "20px"}}/>*/}
      {/*          <img src={star_g} style={{width: "20px"}}/>*/}
      {/*          <img src={star_g} style={{width: "20px"}}/>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{textAlign: "right", fontWeight: "normal"}}*/}
      {/*        >*/}
      {/*          성과만족도*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{textAlign: "right", fontWeight: "normal"}}*/}
      {/*        >*/}
      {/*          소통능력*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{textAlign: "right", fontWeight: "normal"}}*/}
      {/*        >*/}
      {/*          일정준수*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{textAlign: "right", fontWeight: "normal"}}*/}
      {/*        >*/}
      {/*          적극성*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{textAlign: "right", fontWeight: "normal"}}*/}
      {/*        >*/}
      {/*          전문성*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className="border-bottom d-flex">*/}
      {/*    <div className="w-70" style={{borderRight: "1px solid #ddd"}}>*/}
      {/*      <ul className="m-3 mx-4 border-bottom p-0 pb-2 px-2">*/}
      {/*        <li className="h5 bold">세럼 OEM</li>*/}
      {/*        <li className="txt-sm txt-gray">*/}
      {/*          프로젝트 기간 : 2021.10.22 ~ 2021.12.30*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="m-3 mx-4 p-0 pb-2 px-2">*/}
      {/*        <li>g*****5 (1년전)</li>*/}
      {/*        <li className=" txt-gray">*/}
      {/*          매우 친절하시고 다양한 요구조건도 잘 맞춰주셔서 매우*/}
      {/*          만족스럽습니다.*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*    <div className="w-30 p-3">*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 h5"*/}
      {/*          style={{textAlign: "right", color: "#D56451"}}*/}
      {/*        >*/}
      {/*          5*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <img src={star_r} style={{width: "20px"}}/>*/}
      {/*          <img src={star_r} style={{width: "20px"}}/>*/}
      {/*          <img src={star_r} style={{width: "20px"}}/>*/}
      {/*          <img src={star_g} style={{width: "20px"}}/>*/}
      {/*          <img src={star_g} style={{width: "20px"}}/>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{textAlign: "right", color: "#D56451"}}*/}
      {/*        >*/}
      {/*          성과만족도*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{textAlign: "right", color: "#D56451"}}*/}
      {/*        >*/}
      {/*          소통능력*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{textAlign: "right", color: "#D56451"}}*/}
      {/*        >*/}
      {/*          일정준수*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{textAlign: "right", color: "#D56451"}}*/}
      {/*        >*/}
      {/*          적극성*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{textAlign: "right", fontWeight: "normal"}}*/}
      {/*        >*/}
      {/*          전문성*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className="border-bottom d-flex">*/}
      {/*    <div className="w-70" style={{borderRight: "1px solid #ddd"}}>*/}
      {/*      <ul className="m-3 mx-4 border-bottom p-0 pb-2 px-2">*/}
      {/*        <li className="h5 bold">세럼 OEM</li>*/}
      {/*        <li className="txt-sm txt-gray">*/}
      {/*          프로젝트 기간 : 2021.10.22 ~ 2021.12.30*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="m-3 mx-4 p-0 pb-2 px-2">*/}
      {/*        <li>g*****5 (1년전)</li>*/}
      {/*        <li className=" txt-gray">*/}
      {/*          매우 친절하시고 다양한 요구조건도 잘 맞춰주셔서 매우*/}
      {/*          만족스럽습니다.*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*    <div className="w-30 p-3">*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 h5"*/}
      {/*          style={{textAlign: "right", color: "#D56451"}}*/}
      {/*        >*/}
      {/*          5*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <img src={star_r} style={{width: "20px"}}/>*/}
      {/*          <img src={star_r} style={{width: "20px"}}/>*/}
      {/*          <img src={star_r} style={{width: "20px"}}/>*/}
      {/*          <img src={star_g} style={{width: "20px"}}/>*/}
      {/*          <img src={star_g} style={{width: "20px"}}/>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{textAlign: "right", fontWeight: "normal"}}*/}
      {/*        >*/}
      {/*          성과만족도*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{textAlign: "right", fontWeight: "normal"}}*/}
      {/*        >*/}
      {/*          소통능력*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{textAlign: "right", fontWeight: "normal"}}*/}
      {/*        >*/}
      {/*          일정준수*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{textAlign: "right", fontWeight: "normal"}}*/}
      {/*        >*/}
      {/*          적극성*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{textAlign: "right", fontWeight: "normal"}}*/}
      {/*        >*/}
      {/*          전문성*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className="d-flex justify-content-center py-3">*/}
      {/*    <ul className="d-flex my-3 p-0">*/}
      {/*      <li className="PAGE">*/}
      {/*        <a href="#" aria-label="Previous">*/}
      {/*          <img src={first_page_black_48dp} style={{width: "25px"}}/>*/}
      {/*        </a>*/}
      {/*      </li>*/}
      {/*      <li className="PAGE">*/}
      {/*        <a href="#" aria-label="Previous">*/}
      {/*          <img src={chevron_left_black_24dp} style={{width: "25px"}}/>*/}
      {/*        </a>*/}
      {/*      </li>*/}
      {/*      <li className="PAGE">*/}
      {/*        <a href="#" className="ON">*/}
      {/*          1*/}
      {/*        </a>*/}
      {/*      </li>*/}
      {/*      <li className="PAGE">*/}
      {/*        <a href="#">2</a>*/}
      {/*      </li>*/}
      {/*      <li className="PAGE">*/}
      {/*        <a href="#">3</a>*/}
      {/*      </li>*/}
      {/*      <li className="PAGE">*/}
      {/*        <a href="#" aria-label="Previous">*/}
      {/*          <img src={navigate_next_black_48dp} style={{width: "25px"}}/>*/}
      {/*        </a>*/}
      {/*      </li>*/}
      {/*      <li className="PAGE">*/}
      {/*        <a href="#" aria-label="Previous">*/}
      {/*          <img src={last_page_black_48dp} style={{width: "25px"}}/>*/}
      {/*        </a>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default BuyerDetail;
