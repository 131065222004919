import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import openRequest from "../../actions/openRequest";
import { useDispatch, useSelector } from "react-redux";
import thum_comp_01 from "../../image/thum_comp_01.png";
import file_download_w from "../../image/file_download_w.svg";

import "../../sass/main.scss";

const RequestModal = (props) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.requestForm);
  const closeModal = () => {
    dispatch(openRequest("closed", ""));
  };

  let modalInlineStyle;

  if (selector.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }
  return (
    <div style={{ display: modalInlineStyle }}>
      <div className="pop_box02" style={{ width: "850px", minHeight: "500px" }}>
        <div className="POP_title pb-3">
          <h4>
            <strong>제작 의뢰서</strong>
          </h4>
          <p className="closed">
            <button onClick={closeModal} type="button" className="btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </p>
        </div>

        <div className="POP_cnt_box_lg" style={{ marginBottom: "90px" }}>
          <table className="table basic_sm w-100 my-3">
            <colgroup>
              <col width="24%" />
              <col width="*" />
            </colgroup>
            <tr>
              <th className="center txt-sm">제품 분류</th>
              <td style={{ borderTop: "none" }}>
                <div className="input-group input-group-sm">
                  <select
                    className="form-select w-85"
                    aria-label="Default select example"
                  >
                    <option value="스킨케어" defaultValue>
                      스킨케어
                    </option>
                    <option value="핸드케어">핸드케어</option>
                    <option value="선케어">선케어</option>
                    <option value="바디케어">바디케어</option>
                  </select>
                  <select
                    className="form-select w-85"
                    aria-label="Default select example"
                  >
                    <option value="로션" defaultValue>
                      로션
                    </option>
                    <option value="핸드크림">핸드크림</option>
                    <option value="바디로션">바디로션</option>
                    <option value="립스틱">립스틱</option>
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <th className="center txt-sm">
                의뢰서 제목 및<br />
                의뢰번호
              </th>
              <td>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="의뢰서 제목을 입력해 주세요"
                />
                <p className="mb-3">제작 의뢰서 번호 : B1-2021-00001</p>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input mt-1"
                    type="checkbox"
                    id="inlineCheckbox1"
                    value="option1"
                  />
                  <label className="form-check-label " for="inlineCheckbox1">
                    용기 배송{" "}
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input mt-1"
                    type="checkbox"
                    id="inlineCheckbox2"
                    value="option2"
                  />
                  <label className="form-check-label" for="inlineCheckbox2">
                    단상자 배송
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <th className="center txt-sm">
                브랜드명
                <br />
                (Brand name)
              </th>
              <td>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="브랜드명 (Brand name)"
                />
              </td>
            </tr>
            <tr>
              <th className="center txt-sm">
                제품명
                <br />
                (Product name)
              </th>
              <td>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder=""
                />
              </td>
            </tr>
            <tr>
              <th className="center txt-sm">
                용량
                <br />
                (Volum)
              </th>
              <td>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder=""
                />
              </td>
            </tr>
            <tr>
              <th className="center txt-sm">
                제형/타겟 제품
                <br />
                (Target item)
              </th>
              <td>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder=""
                />
              </td>
            </tr>
            <tr>
              <th className="center txt-sm">
                향/색
                <br />
                (Scent/Color)
              </th>
              <td>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="향 무관"
                />
                <input
                  type="text"
                  className="form-control form-control-sm mt-1"
                  placeholder="색 무관"
                />
              </td>
            </tr>

            <tr>
              <th className="center txt-sm">
                제품 컨셉
                <br />
                (Concept of Line)
              </th>
              <td>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="5"
                ></textarea>
              </td>
            </tr>
            <tr>
              <th
                className="center txt-sm"
                style={{ borderBottom: "1px solid #ddd" }}
              >
                노출 유무
              </th>
              <td>
                <select
                  className="form-select form-select-sm w-85"
                  aria-label="Default select example"
                >
                  <option selected>노출함</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </td>
            </tr>
          </table>
        </div>
        <div className="POP_foot">
          <button className="btn btn_purple" type="button" onClick={closeModal}>
            저장
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestModal;
