import React, {useContext, useEffect, useState} from "react";
import last_page_black_48dp from "../../image/last_page_black_48dp.svg";
import navigate_next_black_48dp from "../../image/navigate_next_black_48dp.svg";
import chevron_left_black_24dp from "../../image/chevron_left_black_24dp.svg";
import first_page_black_48dp from "../../image/first_page_black_48dp.svg";
import cancel_g from "../../image/cancel_g.svg";
import MypageCard from "./MypageCard";
import "../../sass/main.scss";
import {useHistory} from "react-router-dom";
import APIHelper from "../../helpers/APIHelper";
import moment from "moment";
import Paging from "../common/Paging";
import CommonLib from "../../libraries/CommonLib";
import {GlobalContext} from "../global-context";

const MypageAlarm = (props) => {
  const history = useHistory();
  const [renew, setRenew] = useState(0);
  const state = useContext(GlobalContext);

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  const [rets, setRets] = useState([]);

  const gets = async () => {
    const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("user/message/gets", {paging: 1, currPage});
    if (pageInfo) setPageInfo(pageInfo);
    setRets(result);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetch = async () => {
      await gets();
    }
    fetch();
  }, [currPage]);

  const setMessageRead = async (id) => {
    await APIHelper.putForm("user/message/read", {id});
    await gets();
    setRenew(new Date().getTime());
    state.setRefreshAlarm(new Date().getTime());
  };

  return (
    <MypageCard renew={renew}>
      <div className="box_round min_h cnt_area pb-5">
        <div className="mx-5 my-4">
          <p className="h3 bold border-bottom py-3">알림</p>
          <br/>
          <div className="d-flex justify-content-between">
            <p className="txt-gray">
              총 <span className="bold txt-red">{CommonLib.parseInt(pageInfo?.totalArticles)}</span>건
            </p>
            <p>
              <button
                type="button"
                className="btn btn-sm btn-outline-light hov_pup"
                onClick={async () => {
                  if (!await CommonLib.jconfirm('읽은 알림을 삭제하시겠습니까?')) return;
                  await APIHelper.delete(`user/message/read/delete`);
                  await gets();
                  setRenew(new Date().getTime());
                  state.setRefreshAlarm(new Date().getTime());
                }}
              >
                읽은 알림 삭제
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-light hov_pup"
                onClick={async () => {
                  if (!await CommonLib.jconfirm('전체 알림을 삭제하시겠습니까?')) return;
                  await APIHelper.delete(`user/message/deletes`);
                  await gets();
                  setRenew(new Date().getTime());
                  state.setRefreshAlarm(new Date().getTime());
                }}
              >
                전체 삭제
              </button>
            </p>
          </div>

          {rets.map((ret, i) => (
            <div className="box_round border p-3 my-3 p_rative" onClick={() => setMessageRead(ret.id)} style={{cursor: CommonLib.parseInt(ret.read_yn) === 0 ? "pointer" : "initial"}}>
              <p className={CommonLib.parseInt(ret.read_yn) === 0 ? `h6 bold pb-3 border-bottom` : `h6 txt-gray pb-3 border-bottom`} style={{paddingRight: "40px"}}>
                {ret.message.replace(/\n/gi, " / ")}
              </p>
              <p className="txt-sm txt-gray">{ret.category}</p>
              <p className="p_absolute_tr3">
                <button type="button" className="btn btn-sm" onClick={async e => {
                  e.stopPropagation();
                  if (!await CommonLib.jconfirm('선택한 알림을 삭제하시겠습니까?')) return;
                  await APIHelper.delete(`user/message/delete?id=${ret.id}`);
                  await gets();
                  setRenew(new Date().getTime());
                  state.setRefreshAlarm(new Date().getTime());
                }}>
                  <img src={cancel_g} style={{width: "25px"}}/>
                </button>
              </p>
              <p className="p_absolute_br2 txt-sm txt-gray">{moment(ret.created_at).format("YYYY.MM.DD HH:mm")}</p>
            </div>
          ))}
          {rets?.length === 0 &&
            <div className="col w-100">
              <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-white" style={{marginLeft: "8px", marginRight: "8px"}}>
                확인할 메세지가 없습니다.
              </div>
            </div>
          }
        </div>
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </div>
    </MypageCard>
  );
};

export default MypageAlarm;
