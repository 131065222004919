import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "../../sass/main.scss";
import MypageMenu from "./MypageMenu";

const MypageCard = (props) => {
  const [renew, setRenew] = useState(props.renew);

  useEffect(() => {
    setRenew(new Date().getTime());
  }, [props.renew]);

  return (
    <div className="BODY MAX my-4">
      <div
        className="mt-3 d-flex justify-content-between w-100"
        style={{height: "100%"}}
      >
        <MypageMenu renew={renew}/>
        {props.children}
      </div>
    </div>
  );
};

export default MypageCard;
