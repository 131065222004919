import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import AdminCsQnaList from "./AdminCsQnaList";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import CommonLib from "../../../libraries/CommonLib";
import APIHelper from "../../../helpers/APIHelper";

const AdminCsQna = (e) => {
  // 현재 선택한 Q&A state
  const [qnaState, setQnaState] = useState();
  const qnaClick = (e) => {
    setQnaState(e);
    setAnswer(e?.answer || '');
    setStatusCd(e.status_cd || '');
  };

  const [answer, setAnswer] = useState('');
  const [renew, setRenew] = useState(new Date().getTime());
  const [statusCd, setStatusCd] = useState('답변대기');
  const [statusCds, setStatusCds] = useState(['답변대기', '답변완료']);

  const submit = async () => {
    const {data: {code, result, message}} = await APIHelper.postForm("cs/inquiry/reply", {id: qnaState?.id, answer, statusCd});
    await CommonLib.jalert(message);
    setRenew(new Date().getTime());

    try {
      await APIHelper.postForm("admin/action/log", {
        content: "고객서비스 > 문의관리 > 답변저장",
        param1: qnaState?.id,
        param2: answer,
        param3: statusCd,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const remove = async () => {
    return new Promise(async (resolve, reject) => {
      if (!await CommonLib.jconfirm('삭제하시겠습니까?')) return resolve(true);
      const {data: {code, result, message}} = await APIHelper.deleteForm(`cs/inquiry/delete?id=${qnaState?.id}`);
      setRenew(new Date().getTime());

      try {
        await APIHelper.postForm("admin/action/log", {
          content: "고객서비스 > 문의관리 > 삭제",
          param1: qnaState?.id,
        });
      } catch (err) {
        console.log(err);
      }

      return resolve(true);
    });
  }

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "고객서비스 > 문의관리",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <AdminCsQnaList click={qnaClick} renew={renew}/>

        <section className="col-7" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">Q&A Detail</p>
              <p className="m-1 mb-2">
                <button type="button" className="btn btn_gray txt-sm py-1 px-3" onClick={() => remove()}>
                  삭제
                </button>
              </p>
            </div>
            {qnaState && (
              <div
                className="wid_scroll scroll_y custom_scroll px-2 mt-4"
                style={{height: "calc(100% - 70px)"}}
              >
                <article className="wid_inline">
                  <p className="py-3 h6 bold">문의 (Question)</p>
                  <table className="table basic w-100">
                    <colgroup>
                      <col width="18%"/>
                      <col width="*"/>
                      <col width="18%"/>
                      <col width="*"/>
                    </colgroup>
                    <tr>
                      <th className="center">제목</th>
                      <td colspan="3" className="border-top bold">
                        {qnaState?.subject}
                      </td>
                    </tr>
                    <tr>
                      <th className="center">이름</th>
                      <td>{qnaState?.qn_user?.name} </td>

                      <th className="center">이메일</th>
                      <td>{qnaState?.qn_user?.email}</td>
                    </tr>
                    <tr>
                      <th className="center">내용</th>
                      <td
                        colspan="3"
                        className="border-bottom p-4 p_rative pb-5"
                      >
                        {CommonLib.escapedNewLineToLineBreakTag(qnaState?.question)}
                        <p className="p_absolute_br2">{moment(qnaState?.inquiry_date).format('YYYY.MM.DD')}</p>
                      </td>
                    </tr>
                  </table>
                </article>
                <article className="wid_inline">
                  <div className="d-flex justify-content-between  w-100">
                    <p className="py-3 h6 bold">답변 (Answer)</p>
                    <p className="m-1 mt-3">
                      <button
                        onClick={() => submit()}
                        type="button"
                        className="btn btn-purple txt-sm py-1 px-3"
                      >
                        답변저장
                      </button>
                    </p>
                  </div>
                  <div>
                    <table className="table basic w-100">
                      <colgroup>
                        <col width="18%"/>
                        <col width="*"/>
                      </colgroup>
                      <tr>
                        <th className="center">상태</th>
                        <td className="border-top">
                          <select
                            className="form-select form-select-sm w-50"
                            aria-label="Default select example"
                            onChange={async (e) => await setStatusCd(e.target.value)}
                            value={statusCd}
                          >
                            {statusCds.map((item, k) => <option key={k} value={item} selected={item === statusCd}>{item}</option>)}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th className="center">내용</th>
                        <td className="border-bottom">
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="10"
                            placeholder="내용을 입력해 주세요."
                            onChange={async (e) => await setAnswer(e.target.value)}
                            value={answer}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </article>
              </div>
            )}
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminCsQna;
