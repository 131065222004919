import React, {useState} from "react";
import BuyerProjectSide from "./BuyerProjectSide";
import {Link} from "react-router-dom";
import icon_catogory_011 from "../../../image/icon_catogory_011.svg";
import cancel_g from "../../../image/cancel_g.svg";
import icon_catogory_091 from "../../../image/icon_catogory_091.svg";
import icon_catogory_051 from "../../../image/icon_catogory_051.svg";

import "../../../sass/main.scss";
import CommonLib from "../../../libraries/CommonLib";

const BuyerProjectRequest = (props) => {
  const dv = props?.location?.state?.dv || props?.match?.params?.dv;
  const id = props?.location?.state?.id || props?.match?.params?.id;

  const path = window.location.pathname;

  // 01. 제품분류
  const arrProducts = [
    {title: "skincare"},
    {title: "haircare"},
    {title: "cleanser"},
    {title: "mask"},
    {title: "faceMakeup"},
    {title: "eyeMakeup"},
    {title: "lipMakeup"},
    {title: "bodycare"},
    {title: "handcare"},
    {title: "footcare"},
    {title: "suncare"},
    {title: "baby"},
    {title: "etc"},
  ];

  // 02. 제품종류
  const arrTypes = [
    {title: "toner", width: 18, num: 1},
    {title: "skin", width: 18, num: 2},
    {title: "mist", width: 25, num: 3},
    {title: "emulsion", width: 20, num: 4},
    {title: "ample", width: 20, num: 5},
    {title: "serum", width: 20, num: 6},
    {title: "essence", width: 16, num: 7},
    {title: "cream", width: 30, num: 8},
    {title: "gel", width: 30, num: 9},
  ];

  // 03. 항목
  const arrItems = [
    {title: "contents", width: 35, num: 1},
    {title: "bottle", width: 45, num: 2},
    {title: "design", width: 40, num: 3},
    {title: "box", width: 45, num: 4},
  ];

  // select된 것들
  const [selectProduct, setSelectProduct] = useState([]);
  const [selectType, setSelectType] = useState([]);
  const [selectItem, setSelectItem] = useState([]);

  // 제품분류 핸들러
  const productsList = arrProducts.map((item, i) => (
    <div className="col">
      <button
        type="button"
        key={i}
        onClick={() => {
          !selectProduct.includes(item.title)
            ? setSelectProduct((select) => [...selectProduct, item.title])
            : setSelectProduct(
              selectProduct.filter((select) => select !== item.title)
            );
        }}
        className={` btn btn-sm  ${
          selectProduct.includes(item.title) ? "category1_on" : "category1"
        }`}
      >
        <img
          src={require(`../../../image/icon_catogory_${
            `${i + 1}`.toString().length === 1 ? "0" + `${i + 1}` : `${i + 1}`
          }${selectProduct.includes(item.title) ? "1" : ""}.svg`)}
          style={{width: "45px", marginBottom: "5px"}}
        />
        <br/>
        {item.title}
      </button>
    </div>
  ));

  // 제품종류 핸들러
  const typesList = arrTypes.map((item, i) => (
    <div className="col">
      <button
        type="button"
        key={i}
        onClick={() => {
          !selectType.includes(item.title)
            ? setSelectType((select) => [...selectType, item.title])
            : setSelectType(
              selectType.filter((select) => select !== item.title)
            );
        }}
        className={` btn btn-sm  ${
          selectType.includes(item.title) ? "category1_on" : "category1"
        }`}
      >
        <img
          src={require(`../../../image/icon_cate01_0${item.num}${
            selectType.includes(item.title) ? "1" : ""
          }.svg`)}
          style={{width: `${item.width}px`, marginBottom: "5px"}}
        />
        <br/>
        {item.title}
      </button>
    </div>
  ));

  // 항목 핸들러
  const itemsList = arrItems.map((item, i) => (
    <div className="col">
      <button
        type="button"
        key={i}
        onClick={() => {
          !selectItem.includes(item.title)
            ? setSelectItem((select) => [...selectItem, item.title])
            : setSelectItem(
              selectItem.filter((select) => select !== item.title)
            );
        }}
        className={` btn btn-sm  ${
          selectItem.includes(item.title) ? "category1_on" : "category1"
        }`}
      >
        <img
          src={require(`../../../image/icon_prod_0${item.num}${
            selectItem.includes(item.title) ? "1" : ""
          }.svg`)}
          style={{width: `${item.width}px`, marginBottom: "5px"}}
        />
        <br/>
        {item.title}
      </button>
    </div>
  ));

  // 최종 선택
  const list = {products: null, types: null, items: null};
  const clickHandler = async (e) => {
    list.products = selectProduct;
    list.types = selectType;
    list.items = selectItem;
    if (selectProduct.length === 0 || selectType.length === 0) {
      await CommonLib.jalert("제품분류와 제품종류는 필수로 선택해주세요.");
    } else {
      await CommonLib.jalert("제품 생성을 완료하였습니다! 제품 리스트를 확인해주세요!");
    }
  };

  return (
    <BuyerProjectSide dv={dv} id={id}>
      <div className="text-center border-bottom py-4 pb-5">
        <p className="h4 bold txt-point">
          어떤 종류의 제품을 만들고 싶으신가요?
        </p>
        <p className="h6">
          원하는 제품이 여러 개인가요? 제품 추가를 누르시면 됩니다.
        </p>
      </div>
      <div className="row row-cols-2 gx-3 my-3">
        <div className="col">
          <div
            className="border p_rative"
            style={{minHeight: "100%", marginBottom: "90px"}}
          >
            <div className="p-3 h6 txt-gray border-bottom bg-lpurple">
              <span className="h6 bold">01.제품 분류</span>를 선택하세요 (필수)
            </div>

            <div className="row row-cols-4 g-2 text-center px-3 my-4">
              {productsList}
            </div>

            <div className="p-3 h6 txt-gray border-bottom bg-lpurple">
              <span className="h6 bold">02.제품 종류</span>를 선택하세요 (필수)
            </div>

            <div className="row row-cols-4 g-2 text-center px-3 my-4">
              {typesList}
            </div>

            <div className="p-3 h6 txt-gray border-bottom bg-lpurple">
              <span className="h6 bold">03.제작을 원하시는 항목</span>을
              선택하세요
            </div>

            <div className="row row-cols-4 g-2 text-center px-3 my-4">
              {itemsList}
            </div>

            <div
              className="border-top p-4 text-center w-100"
              style={{position: "absolute", bottom: "0"}}
            >
              <button
                onClick={clickHandler}
                type="button"
                className="btn btn_outline_puple hov_pup p-2 px-4 bold"
              >
                제품 생성 +
              </button>
            </div>
          </div>
        </div>
        <div className="col">
          <div
            className="border p_rative"
            style={{minHeight: "100%", marginBottom: "90px"}}
          >
            <div className="p-3 h6 txt-gray border-bottom bg-lpurple bold">
              제품 리스트
            </div>

            <div className="d-flex px-3 my-4">
              <p style={{marginRight: "8px"}}>
                <input type="checkbox"/>
              </p>
              <div className="border p-3 w-100 p_rative">
                <p className="p_absolute_tr3" style={{top: "5px"}}>
                  <button type="button" className="btn btn-sm txt-sm p-0 px-2">
                    수정
                  </button>
                  <button type="button" className="btn btn-sm txt-sm p-0">
                    <img src={cancel_g} style={{width: "20px"}}/>
                  </button>
                </p>
                <div className="d-flex border-bottom pb-2">
                  <p className="p-2">
                    <img
                      src={icon_catogory_011}
                      style={{width: "50px", marginBottom: "5px"}}
                    />
                  </p>
                  <div className="mt-1 m-2">
                    <p className="mx-1 txt-point bold mb-2">스킨케어 | 로션</p>
                    <div className="d-flex">
                      <p className="border box_round_4 bg-lgray txt-sm px-2 mx-1">
                        성분
                      </p>
                    </div>
                  </div>
                </div>

                <div className="pt-2">
                  <ul className="p-0 d-flex flex-wrap mb-2">
                    <li className="px-2 txt-sm txt-gray">
                      <span className="bold">인증서 :</span> 111515
                    </li>
                    <li className="px-3 txt-sm txt-gray">
                      <span className="bold">판매희망국가 :</span> 대한민국
                    </li>
                    <li className="px-2 txt-sm txt-gray">
                      <span className="bold">주요기능 :</span> 상관없음
                    </li>
                    <li className="px-3 txt-sm txt-gray">
                      <span className="bold">예상단가 :</span> 5,000원
                    </li>
                  </ul>
                  <p style={{textAlign: "right"}}>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-light"
                      style={{minWidth: "95px"}}
                    >
                      상세등록 완료
                    </button>
                  </p>
                </div>
              </div>
            </div>

            <div className="d-flex px-3 mb-3">
              <p style={{marginRight: "8px"}}>
                <input type="checkbox"/>
              </p>
              <div className="border p-3 w-100 p_rative">
                <p className="p_absolute_tr3" style={{top: "5px"}}>
                  <button type="button" className="btn btn-sm txt-sm p-0 px-2">
                    수정
                  </button>
                  <button type="button" className="btn btn-sm txt-sm p-0">
                    <img src={cancel_g} style={{width: "20px"}}/>
                  </button>
                </p>
                <div className="d-flex border-bottom pb-2">
                  <p className="p-2">
                    <img
                      src={icon_catogory_091}
                      style={{width: "50px", marginBottom: "5px"}}
                    />
                  </p>
                  <div className="mt-1 m-2">
                    <p className="mx-1 txt-point bold mb-2">
                      핸드케어 | 핸드크림
                    </p>
                    <div className="d-flex">
                      <p className="border box_round_4 bg-lgray txt-sm px-2 mx-1">
                        용기
                      </p>
                      <p className="border box_round_4 bg-lgray txt-sm px-2 mx-1">
                        단상자
                      </p>
                      <p className="border box_round_4 bg-lgray txt-sm px-2 mx-1">
                        디자인
                      </p>
                    </div>
                  </div>
                </div>

                <div className="pt-2">
                  <ul className="p-0 d-flex flex-wrap mb-2">
                    <li className="px-2 txt-sm txt-gray">
                      <span className="bold">인증서 :</span> 111515
                    </li>
                    <li className="px-3 txt-sm txt-gray">
                      <span className="bold">판매희망국가 :</span> 대한민국
                    </li>
                    <li className="px-2 txt-sm txt-gray">
                      <span className="bold">주요기능 :</span> 상관없음
                    </li>
                    <li className="px-3 txt-sm txt-gray">
                      <span className="bold">예상단가 :</span> 5,000원
                    </li>
                  </ul>
                  <p style={{textAlign: "right"}}>
                    <button
                      type="button"
                      className="btn btn-sm btn_puple"
                      style={{minWidth: "95px"}}
                    >
                      상세정보 등록
                    </button>
                  </p>
                </div>
              </div>
            </div>

            <div className="d-flex px-3 mb-3">
              <p style={{marginRight: "8px"}}>
                <input type="checkbox"/>
              </p>
              <div className="border p-3 w-100 p_rative">
                <p className="p_absolute_tr3" style={{top: "5px"}}>
                  <button type="button" className="btn btn-sm txt-sm p-0 px-2">
                    수정
                  </button>
                  <button type="button" className="btn btn-sm txt-sm p-0">
                    <img src={cancel_g} style={{width: "20px"}}/>
                  </button>
                </p>
                <div className="d-flex border-bottom pb-2">
                  <p className="p-2">
                    <img
                      src={icon_catogory_051}
                      style={{width: "55px", marginBottom: "5px"}}
                    />
                  </p>
                  <div className="mt-1 m-2">
                    <p className="mx-1 txt-point bold mb-2">
                      페이스 메이크업 | 하이라이터
                    </p>
                    <div className="d-flex">
                      <p className="border box_round_4 bg-lgray txt-sm px-2 mx-1">
                        성분
                      </p>
                      <p className="border box_round_4 bg-lgray txt-sm px-2 mx-1">
                        용기
                      </p>
                      <p className="border box_round_4 bg-lgray txt-sm px-2 mx-1">
                        단상자
                      </p>
                    </div>
                  </div>
                </div>

                <div className="pt-2">
                  <ul className="p-0 d-flex flex-wrap mb-2">
                    <li className="px-2 txt-sm txt-gray">
                      <span className="bold">인증서 :</span> 111515
                    </li>
                    <li className="px-3 txt-sm txt-gray">
                      <span className="bold">판매희망국가 :</span> 대한민국
                    </li>
                    <li className="px-2 txt-sm txt-gray">
                      <span className="bold">주요기능 :</span> 상관없음
                    </li>
                    <li className="px-3 txt-sm txt-gray">
                      <span className="bold">예상단가 :</span> 5,000원
                    </li>
                  </ul>
                  <p style={{textAlign: "right"}}>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-light"
                      style={{minWidth: "95px"}}
                    >
                      상세등록 완료
                    </button>
                  </p>
                </div>
              </div>
            </div>

            <div
              className="border-top p-4 text-center w-100"
              style={{position: "absolute", bottom: "0"}}
            >
              <Link to="/project/buyer/request/form">
                <button type="button" className="btn btn_puple p-2 px-4 bold">
                  의뢰서 보내기
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </BuyerProjectSide>
  );
};

export default BuyerProjectRequest;
