import React from "react";
import "../../sass/main.scss";
import Constants from "../../constants/constants";
import Img from "react-cool-img";

const MakerCompany = (props) => {
  const {maker} = props;

  return (
    ((maker.int_content && maker.int_content.length > 0) || (maker.int_image_fid && maker.int_image_fid.length > 0)) ||
    ((maker.biz_content && maker.biz_content.length > 0) || (maker.biz_image_fid && maker.biz_image_fid.length > 0)) ||
    ((maker.maf_content && maker.maf_content.length > 0) || (maker.maf_image_fid && maker.maf_image_fid.length > 0)) ||
    ((maker.qal_content && maker.qal_content.length > 0) || (maker.qal_image_fid && maker.qal_image_fid.length > 0)) ||
    ((maker.etc_content && maker.etc_content.length > 0) || (maker.etc_image_fid && maker.etc_image_fid.length > 0))
  )
    ?
    <>
      {((maker.int_content && maker.int_content.length > 0) || (maker.int_image_fid && maker.int_image_fid.length > 0)) &&
        <p className="h5 bold txt-point pb-2 border-bottom mt-5">회사 소개</p>
      }
      {maker.int_content && maker.int_content.length > 0 &&
        <div className="mt-3" dangerouslySetInnerHTML={{__html: maker.int_content}}/>
      }
      {maker.int_image_fid && maker.int_image_fid.length > 0 &&
        <div className="row row-cols-3 mt-3">
          <Img
            src={`${Constants.API_HOST}/common/download?fid=${maker.int_image_fid}&cd=inline&thumb=1000`}
            style={{width: "100%"}}
          />
        </div>
      }

      {((maker.biz_content && maker.biz_content.length > 0) || (maker.biz_image_fid && maker.biz_image_fid.length > 0)) &&
        <p className="h5 bold txt-point pb-2 border-bottom mt-5">사업 분야</p>
      }
      {maker.biz_content && maker.biz_content.length > 0 &&
        <div className="mt-3" dangerouslySetInnerHTML={{__html: maker.biz_content}}/>
      }
      {maker.biz_image_fid && maker.biz_image_fid.length > 0 &&
        <div className="row row-cols-3 mt-3">
          <Img
            src={`${Constants.API_HOST}/common/download?fid=${maker.biz_image_fid}&cd=inline&thumb=1000`}
            style={{width: "100%"}}
          />
        </div>
      }

      {((maker.maf_content && maker.maf_content.length > 0) || (maker.maf_image_fid && maker.maf_image_fid.length > 0)) &&
        <p className="h5 bold txt-point pb-2 border-bottom mt-5">연구 및 생산 품목</p>
      }
      {maker.maf_content && maker.maf_content.length > 0 &&
        <div className="mt-3" dangerouslySetInnerHTML={{__html: maker.maf_content}}/>
      }
      {maker.maf_image_fid && maker.maf_image_fid.length > 0 &&
        <div className="row row-cols-3 mt-3">
          <Img
            src={`${Constants.API_HOST}/common/download?fid=${maker.maf_image_fid}&cd=inline&thumb=1000`}
            style={{width: "100%"}}
          />
        </div>
      }

      {((maker.qal_content && maker.qal_content.length > 0) || (maker.qal_image_fid && maker.qal_image_fid.length > 0)) &&
        <p className="h5 bold txt-point pb-2 border-bottom mt-5">인증서 및 특허</p>
      }
      {maker.qal_content && maker.qal_content.length > 0 &&
        <div className="mt-3" dangerouslySetInnerHTML={{__html: maker.qal_content}}/>
      }
      {maker.qal_image_fid && maker.qal_image_fid.length > 0 &&
        <div className="row row-cols-3 mt-3">
          <Img
            src={`${Constants.API_HOST}/common/download?fid=${maker.qal_image_fid}&cd=inline&thumb=1000`}
            style={{width: "100%"}}
          />
        </div>
      }

      {((maker.etc_content && maker.etc_content.length > 0) || (maker.etc_image_fid && maker.etc_image_fid.length > 0)) &&
        <p className="h5 bold txt-point pb-2 border-bottom mt-5">기타</p>
      }
      {maker.etc_content && maker.etc_content.length > 0 &&
        <div className="mt-3" dangerouslySetInnerHTML={{__html: maker.etc_content}}/>
      }
      {maker.etc_image_fid && maker.etc_image_fid.length > 0 &&
        <div className="row row-cols-3 mt-3">
          <Img
            src={`${Constants.API_HOST}/common/download?fid=${maker.etc_image_fid}&cd=inline&thumb=1000`}
            style={{width: "100%"}}
          />
        </div>
      }
    </>
    :
    <div className="col w-100">
      <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-lblue bold">
        등록된 회사 상세 내용이 없습니다.
      </div>
    </div>;
}

export default MakerCompany;
