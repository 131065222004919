import React from "react";
import {useState} from "react";
import "../../sass/main.scss";
import Notice from "./Notice";
import Faq from "./Faq";
import NoticeDetail from "./NoticeDetail";
import {Link, useHistory} from "react-router-dom"

const ServicePolicy = (props) => {
  const path = window.location.pathname;
  const history = useHistory();

  return (
    <div className="BODY MAX my-4">
      <div
        className="mt-3 d-flex justify-content-between w-100"
        style={{height: "100%"}}
      >
        <div className="box_round min_h left_menu">
          <div className="left_menu_title">서비스 운영정책</div>
          <ul className="left_menu_list p-0">
            <li className={`${path.includes("agreement") ? "on" : ""}`}>
              <Link to="/servicepolicy/agreement">
                이용약관
              </Link>
            </li>
            <li className={`${path.includes("privacy") ? "on" : ""}`}>
              <Link to="/servicepolicy/privacy">
                개인정보처리방침
              </Link>
            </li>
          </ul>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default ServicePolicy;
