import React from "react";
import "../../sass/main.scss";
import CommonLib from "../../libraries/CommonLib";

const MakerDetail = (props) => {
  const {maker} = props;

  return (
    <>
      <p className="h5 bold txt-point">회사 기본정보</p>

      <table className="table table-sm basic_sm my-4">
        <colgroup>
          <col width="20%"/>
          <col width="*"/>
        </colgroup>

        <tr>
          <th className="border-right">회사명</th>
          <td>{maker.company_name}</td>
        </tr>
        <tr>
          <th className="border-right">담당자</th>
          <td>{maker.charger_name}</td>
        </tr>
        <tr>
          <th className="border-right">유형</th>
          <td>
            {
              maker.maker_type === 'MF' ? '제형 제조사' :
                maker.maker_type === 'DS' ? '디자인 제조사' :
                  maker.maker_type === 'BT' ? '용기 제조사' :
                    maker.maker_type === 'BX' ? '단상자 제조사' : ''
            }</td>
        </tr>

        {/*TODO*/}
        {/*<tr>*/}
        {/*  <th className="border-right">MOQ</th>*/}
        {/*  <td>{maker?.moq || ''}</td>*/}
        {/*</tr>*/}
        <tr>
          <th className="border-right">생산 가능 품목</th>
          <td>
            <div className="d-flex">
              {maker.mf_items && maker.mf_items.map((item, key) => (
                <p className="Tag1">{item.name_kr}</p>
              ))}
            </div>
          </td>
        </tr>
        <tr>
          <th className="border-right">기능성 제품 생산</th>
          <td>
            <div className="d-flex">
              {maker.functionals && maker.functionals.map((item, key) => (
                <p className="Tag1">{item.name_kr}</p>
              ))}
            </div>
          </td>
        </tr>

        <tr>
          <th className="border-right">보유 인증서</th>
          <td>
            <div className="d-flex">
              {maker.certs && maker.certs.map((item, key) => (
                <p className="Tag1">{item.name_kr}</p>
              ))}
            </div>
          </td>
        </tr>
        <tr>
          <th className="border-right">보유 특허</th>
          <td>
            <div className="d-flex">
              {maker.patent_num}건 보유
            </div>
          </td>
        </tr>
      </table>

      {maker?.summary && maker.summary?.length > 0 &&
        <>
          <p className="h5 bold txt-point border-bottom py-3">소개</p>

          {/*<div className="row row-cols-3 py-5">*/}
          {/*  <div className="col px-3">*/}
          {/*    <p>*/}
          {/*      <img src={info_comp_03} style={{ height: "175px" }} />*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*  <div className="col px-3">*/}
          {/*    <p>*/}
          {/*      <img src={info_comp_02} style={{ height: "175px" }} />*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*  <div className="col px-3">*/}
          {/*    <p>*/}
          {/*      <img src={info_comp_01} style={{ height: "175px" }} />*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="mb-5">
            <p>
              {CommonLib.escapedNewLineToLineBreakTag(maker.summary)}
            </p>
          </div>
        </>
      }

      {/*<p className="h5 bold txt-point py-3">프로젝트 History</p>*/}

      {/*<div className="box_border">*/}
      {/*  <div className="border-bottom d-flex">*/}
      {/*    <div className="w-70" style={{ borderRight: "1px solid #ddd" }}>*/}
      {/*      <ul className="m-3 mx-4 border-bottom p-0 pb-2 px-2">*/}
      {/*        <li className="h5 bold">세럼 OEM</li>*/}
      {/*        <li className="txt-sm txt-gray">*/}
      {/*          프로젝트 기간 : 2021.10.22 ~ 2021.12.30*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="m-3 mx-4 p-0 pb-2 px-2">*/}
      {/*        <li>g*****5 (1년전)</li>*/}
      {/*        <li className=" txt-gray">*/}
      {/*          매우 친절하시고 다양한 요구조건도 잘 맞춰주셔서 매우*/}
      {/*          만족스럽습니다.*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*    <div className="w-30 p-3">*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 h5"*/}
      {/*          style={{ textAlign: "right", color: "#D56451" }}*/}
      {/*        >*/}
      {/*          5*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <img src={star_r} style={{ width: "20px" }} />*/}
      {/*          <img src={star_r} style={{ width: "20px" }} />*/}
      {/*          <img src={star_r} style={{ width: "20px" }} />*/}
      {/*          <img src={star_g} style={{ width: "20px" }} />*/}
      {/*          <img src={star_g} style={{ width: "20px" }} />*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{ textAlign: "right", fontWeight: "normal" }}*/}
      {/*        >*/}
      {/*          성과만족도*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{ textAlign: "right", fontWeight: "normal" }}*/}
      {/*        >*/}
      {/*          소통능력*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{ textAlign: "right", fontWeight: "normal" }}*/}
      {/*        >*/}
      {/*          일정준수*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{ textAlign: "right", fontWeight: "normal" }}*/}
      {/*        >*/}
      {/*          적극성*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{ textAlign: "right", fontWeight: "normal" }}*/}
      {/*        >*/}
      {/*          전문성*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className="border-bottom d-flex">*/}
      {/*    <div className="w-70" style={{ borderRight: "1px solid #ddd" }}>*/}
      {/*      <ul className="m-3 mx-4 border-bottom p-0 pb-2 px-2">*/}
      {/*        <li className="h5 bold">세럼 OEM</li>*/}
      {/*        <li className="txt-sm txt-gray">*/}
      {/*          프로젝트 기간 : 2021.10.22 ~ 2021.12.30*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="m-3 mx-4 p-0 pb-2 px-2">*/}
      {/*        <li>g*****5 (1년전)</li>*/}
      {/*        <li className=" txt-gray">*/}
      {/*          매우 친절하시고 다양한 요구조건도 잘 맞춰주셔서 매우*/}
      {/*          만족스럽습니다.*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*    <div className="w-30 p-3">*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 h5"*/}
      {/*          style={{ textAlign: "right", color: "#D56451" }}*/}
      {/*        >*/}
      {/*          5*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <img src={star_r} style={{ width: "20px" }} />*/}
      {/*          <img src={star_r} style={{ width: "20px" }} />*/}
      {/*          <img src={star_r} style={{ width: "20px" }} />*/}
      {/*          <img src={star_g} style={{ width: "20px" }} />*/}
      {/*          <img src={star_g} style={{ width: "20px" }} />*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{ textAlign: "right", fontWeight: "normal" }}*/}
      {/*        >*/}
      {/*          성과만족도*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{ textAlign: "right", fontWeight: "normal" }}*/}
      {/*        >*/}
      {/*          소통능력*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{ textAlign: "right", fontWeight: "normal" }}*/}
      {/*        >*/}
      {/*          일정준수*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{ textAlign: "right", fontWeight: "normal" }}*/}
      {/*        >*/}
      {/*          적극성*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{ textAlign: "right", fontWeight: "normal" }}*/}
      {/*        >*/}
      {/*          전문성*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className="border-bottom d-flex">*/}
      {/*    <div className="w-70" style={{ borderRight: "1px solid #ddd" }}>*/}
      {/*      <ul className="m-3 mx-4 border-bottom p-0 pb-2 px-2">*/}
      {/*        <li className="h5 bold">세럼 OEM</li>*/}
      {/*        <li className="txt-sm txt-gray">*/}
      {/*          프로젝트 기간 : 2021.10.22 ~ 2021.12.30*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="m-3 mx-4 p-0 pb-2 px-2">*/}
      {/*        <li>g*****5 (1년전)</li>*/}
      {/*        <li className=" txt-gray">*/}
      {/*          매우 친절하시고 다양한 요구조건도 잘 맞춰주셔서 매우*/}
      {/*          만족스럽습니다.*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*    <div className="w-30 p-3">*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 h5"*/}
      {/*          style={{ textAlign: "right", color: "#D56451" }}*/}
      {/*        >*/}
      {/*          5*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <img src={star_r} style={{ width: "20px" }} />*/}
      {/*          <img src={star_r} style={{ width: "20px" }} />*/}
      {/*          <img src={star_r} style={{ width: "20px" }} />*/}
      {/*          <img src={star_g} style={{ width: "20px" }} />*/}
      {/*          <img src={star_g} style={{ width: "20px" }} />*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{ textAlign: "right", fontWeight: "normal" }}*/}
      {/*        >*/}
      {/*          성과만족도*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{ textAlign: "right", fontWeight: "normal" }}*/}
      {/*        >*/}
      {/*          소통능력*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{ textAlign: "right", fontWeight: "normal" }}*/}
      {/*        >*/}
      {/*          일정준수*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{ textAlign: "right", fontWeight: "normal" }}*/}
      {/*        >*/}
      {/*          적극성*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <ul className="d-flex m-0 p-0">*/}
      {/*        <li*/}
      {/*          className="w-40 px-2 txt-gray"*/}
      {/*          style={{ textAlign: "right", fontWeight: "normal" }}*/}
      {/*        >*/}
      {/*          전문성*/}
      {/*        </li>*/}
      {/*        <li className="">*/}
      {/*          <div className="progress_bar">*/}
      {/*            <div className="On w-75"></div>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className="d-flex justify-content-center py-3">*/}
      {/*    <ul className="d-flex my-3 p-0">*/}
      {/*      <li className="PAGE">*/}
      {/*        <a href="#" aria-label="Previous">*/}
      {/*          <img src={first_page_black_48dp} style={{ width: "25px" }} />*/}
      {/*        </a>*/}
      {/*      </li>*/}
      {/*      <li className="PAGE">*/}
      {/*        <a href="#" aria-label="Previous">*/}
      {/*          <img src={chevron_left_black_24dp} style={{ width: "25px" }} />*/}
      {/*        </a>*/}
      {/*      </li>*/}
      {/*      <li className="PAGE">*/}
      {/*        <a href="#" className="ON">*/}
      {/*          1*/}
      {/*        </a>*/}
      {/*      </li>*/}
      {/*      <li className="PAGE">*/}
      {/*        <a href="#">2</a>*/}
      {/*      </li>*/}
      {/*      <li className="PAGE">*/}
      {/*        <a href="#">3</a>*/}
      {/*      </li>*/}
      {/*      <li className="PAGE">*/}
      {/*        <a href="#" aria-label="Previous">*/}
      {/*          <img src={navigate_next_black_48dp} style={{ width: "25px" }} />*/}
      {/*        </a>*/}
      {/*      </li>*/}
      {/*      <li className="PAGE">*/}
      {/*        <a href="#" aria-label="Previous">*/}
      {/*          <img src={last_page_black_48dp} style={{ width: "25px" }} />*/}
      {/*        </a>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
};

export default MakerDetail;
