import React, {useState} from "react";
import add_circle from "../../image/add_circle.svg";
import close_w from "../../image/close_w.svg";
import "../../sass/main.scss";
import FormData from "form-data";
import APIHelper from "../../helpers/APIHelper";
import ImageUploading from "react-images-uploading";
import CommonLib from "../../libraries/CommonLib";

const BuyerPropose = (props) => {

  const [product, setProduct] = useState('');
  const [moq, setMoq] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [period, setPeriod] = useState('');
  const [etc, setEtc] = useState('');
  const [images, setImages] = useState([]);

  const onChangeImageUploading = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  const submit = async () => {
    try {
      if (product.trim() === '') {
        await CommonLib.jalert('제안할 상품을 입력해 주세요.');
        return;
      }
      if (moq.trim() === '') {
        await CommonLib.jalert('MOQ를 입력해 주세요.');
        return;
      }
      if (unitPrice.trim() === '') {
        await CommonLib.jalert('Unit Price를 입력해 주세요.');
        return;
      }
      if (period.trim() === '') {
        await CommonLib.jalert('제작 기간을 입력해 주세요.');
        return;
      }

      const formData = new FormData();
      formData.append("buyer_user_id", props.buyer_user_id);
      formData.append("product", product);
      formData.append("moq", moq);
      formData.append("unitPrice", unitPrice);
      formData.append("period", period);
      formData.append("etc", etc);
      for (const [key, value] of Object.entries(images)) {
        formData.append("files[]", value.file);
      }

      const response = await APIHelper.putRawForm("data/oem/propose", formData);
      const {data: {code, result, message}} = response;
      if (code === 200) {
        setProduct('');
        setMoq('');
        setUnitPrice('');
        setPeriod('');
        setEtc('');
        setImages([]);
      }
      await CommonLib.jalert(message);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onChangeImageUploading}
      maxNumber={6}
      dataURLKey="data_url"
    >
      {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
        <div className="cnt_area_comp" style={{paddingTop: "3rem", paddingLeft: "1.5rem"}}>
          <p className="p_absolute_tr">
            <button type="button" className="btn btn-sm"></button>
          </p>
          <p className="h3 bold border-bottom pb-3">제품제작 제안하기</p>
          <p className="txt-gray mt-4">※ 바이어에 제품 제작을 홍보 합니다.</p>
          <table className="table basic my-3">
            <colgroup>
              <col width="20%"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th className="border-right">제안할 상품</th>
              <td>
                <input
                  type="text"
                  className="form-control"
                  placeholder="상품 제안하기"
                  aria-describedby="button-addon2"
                  onChange={async (e) => await setProduct(e.target.value)}
                  value={product}
                />
              </td>
            </tr>
            <tr>
              <th className="border-right">MOQ</th>
              <td>
                <input
                  type="text"
                  className="form-control2"
                  placeholder="MOQ"
                  aria-describedby="button-addon2"
                  onChange={async (e) => await setMoq(e.target.value)}
                  value={moq}
                />
              </td>
            </tr>

            <tr>
              <th className="border-right">Unit Price</th>
              <td>
                <input
                  type="text"
                  className="form-control2"
                  placeholder="Unit Price"
                  aria-describedby="button-addon2"
                  onChange={async (e) => await setUnitPrice(e.target.value)}
                  value={unitPrice}
                />
              </td>
            </tr>
            <tr>
              <th className="border-right">제작 기간</th>
              <td>
                <input
                  type="text"
                  className="form-control"
                  placeholder="제작 기간"
                  aria-describedby="button-addon2"
                  onChange={async (e) => await setPeriod(e.target.value)}
                  value={period}
                />
              </td>
            </tr>
            <tr>
              <th className="border-right">유사 제작 사례 이미지</th>
              <td>
                <div className="d-flex">
                  <p className="thum_add">
                    <button type="button" className="btn btn-sm p_absolute_mid" onClick={onImageUpload}>
                      <img src={add_circle} style={{width: "50px", marginLeft: "-8px"}}/>
                    </button>
                  </p>

                  {imageList.map((image, index) => (
                    <p key={index} className="thum_add">
                      <button
                        type="button"
                        className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                        onClick={() => onImageRemove(index)}
                      >
                        <img src={close_w} className="thum_add_del"/>
                      </button>
                      <img
                        src={image["data_url"]}
                        onClick={() => onImageUpdate(index)}
                        style={{
                          borderWidth: 1,
                          borderStyle: "solid",
                          borderColor: "#cfcfcf",
                        }}
                      />
                    </p>
                  ))}
                </div>
              </td>
            </tr>

            <tr>
              <th className="border-right">기타</th>
              <td>
                <div className="d-flex">
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="9"
                    placeholder={`전달 내용을 입력하세요.\n한글로 작성하셔도 됩니다. (번역 후 바이어에게 전달됩니다)`}
                    onChange={async (e) => await setEtc(e.target.value)}
                    value={etc}
                  />
                </div>
              </td>
            </tr>
          </table>

          <p className="text-center">
            <button type="button" className="btn btn-lg btn_puple w-25 my-3" onClick={submit}>
              제안하기
            </button>
          </p>
        </div>
      )}
    </ImageUploading>
  );
};

export default BuyerPropose;
