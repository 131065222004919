import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import AdminRequestOemList from "./AdminRequestOemList";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import CommonLib from "../../../libraries/CommonLib";
import APIHelper from "../../../helpers/APIHelper";
import ImageUploading from "react-images-uploading";
import add_circle from "../../../image/add_circle.svg";
import close_w from "../../../image/close_w.svg";
import FormData from "form-data";
import Constants from "../../../constants/constants";

const AdminRequestOem = (e) => {
  // 현재 선택한 Q&A state
  const [oemState, setOemState] = useState();
  const qnaClick = (e) => {
    setOemState(e);
    setProduct(e?.product || '');
    setMoq(e?.moq || '');
    setUnitPrice(e?.unit_price || '');
    setPeriod(e?.period || '');
    setEtc(e?.etc || '');
    setManagerEtc(e?.manager_etc || '');
    setDarlibabaUnitPrice(e?.darlibaba_unit_price || '');
    setStatusCd(e?.status_cd || 'HIDE');
    setUploadImages(e?.photos || []);
  };

  const [renew, setRenew] = useState(new Date().getTime());

  const remove = async () => {
    return new Promise(async (resolve, reject) => {
      if (!await CommonLib.jconfirm('삭제하시겠습니까?')) return resolve(true);
      const {data: {code, result, message}} = await APIHelper.deleteForm(`data/oem/proposal/delete?id=${oemState?.id}`);
      setRenew(new Date().getTime());
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "운영관리 > OEM 제작 제안 내역 > 삭제",
        });
      } catch (err) {
        console.log(err);
      }
      return resolve(true);
    });
  }

  const [product, setProduct] = useState('');
  const [moq, setMoq] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [period, setPeriod] = useState('');
  const [etc, setEtc] = useState('');
  const [images, setImages] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const [managerEtc, setManagerEtc] = useState('');
  const [darlibabaUnitPrice, setDarlibabaUnitPrice] = useState('');
  const [statusCd, setStatusCd] = useState('');

  const onChangeImageUploading = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  const onDeleteUploadImage = async (photoFid) => {
    const {data: {code, result, message}} = await APIHelper.deleteForm(`data/oem/proposal/photo/delete?photoFid=${photoFid}&oemProposalId=${oemState?.id}`);
    if (code === 200) {
      setRenew(new Date().getTime());
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "운영관리 > OEM 제작 제안 내역 > 이미지삭제",
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const submit = async () => {
    try {
      if (product.trim() === '') {
        await CommonLib.jalert('제안할 상품을 입력해 주세요.');
        return;
      }
      if (moq.trim() === '') {
        await CommonLib.jalert('MOQ를 입력해 주세요.');
        return;
      }
      if (unitPrice.trim() === '') {
        await CommonLib.jalert('Unit Price를 입력해 주세요.');
        return;
      }
      if (period.trim() === '') {
        await CommonLib.jalert('제작 기간을 입력해 주세요.');
        return;
      }

      const formData = new FormData();
      formData.append("id", oemState.id);
      formData.append("product", product);
      formData.append("moq", moq);
      formData.append("unitPrice", unitPrice);
      formData.append("period", period);
      formData.append("etc", etc);
      formData.append("darlibabaUnitPrice", darlibabaUnitPrice);
      formData.append("statusCd", statusCd);
      formData.append("managerEtc", managerEtc);

      for (const [key, value] of Object.entries(images)) {
        formData.append("files[]", value.file);
      }

      const response = await APIHelper.putRawForm("data/oem/proposal/edit", formData);
      const {data: {code, result, message}} = response;
      if (code === 200) {
        setProduct('');
        setMoq('');
        setUnitPrice('');
        setPeriod('');
        setEtc('');
        setImages([]);
        setDarlibabaUnitPrice('');
        setStatusCd('');
        setManagerEtc('');

        try {
          await APIHelper.postForm("admin/action/log", {
            content: "운영관리 > OEM 제작 제안 내역 > 저장",
            param1 : oemState.id,
            param2 : product,
            param3 : moq,
            param4 : unitPrice,
            param5 : period,
            param6 : etc,
            param7 : darlibabaUnitPrice,
            param8 : statusCd,
            param9 : managerEtc,
          });
        } catch (err) {
          console.log(err);
        }

      }
      await CommonLib.jalert(message);
      setRenew(new Date().getTime());
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "운영관리 > OEM 제작 제안 내역",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <AdminRequestOemList click={qnaClick} renew={renew}/>

        <section className="col-7" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">OEM 제작제안 Detail</p>
              <p className="m-1 mb-2">
                <button type="button" className="btn btn_gray txt-sm py-1 px-3" onClick={() => remove()}>
                  삭제
                </button>
                <button type="button" className="btn btn-purple txt-sm py-1 px-3" onClick={() => submit()} style={{marginLeft: "7px"}}>
                  저장
                </button>
              </p>
            </div>
            {oemState && (
              <div
                className="wid_scroll scroll_y custom_scroll px-2 mt-4"
                style={{height: "calc(100% - 70px)"}}
              >
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChangeImageUploading}
                  maxNumber={6}
                  dataURLKey="data_url"
                >
                  {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                    <table className="table basic my-3">
                      <colgroup>
                        <col width="20%"/>
                        <col width="*"/>
                      </colgroup>

                      <tr>
                        <th className="border-right">제안할 상품</th>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="상품 제안하기"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setProduct(e.target.value)}
                            value={product}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="border-right">MOQ</th>
                        <td>
                          <input
                            type="text"
                            className="form-control2"
                            placeholder="MOQ"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setMoq(e.target.value)}
                            value={moq}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th className="border-right">Unit Price</th>
                        <td>
                          <input
                            type="text"
                            className="form-control2"
                            placeholder="Unit Price"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setUnitPrice(e.target.value)}
                            value={unitPrice}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="border-right">달리바바 Unit Price</th>
                        <td>
                          <input
                            type="text"
                            className="form-control2"
                            placeholder="달리바바 Unit Price"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setDarlibabaUnitPrice(e.target.value)}
                            value={darlibabaUnitPrice}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="border-right">제작 기간</th>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="제작 기간"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setPeriod(e.target.value)}
                            value={period}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="border-right">상태</th>
                        <td>
                          <select
                            className="form-select form-select-sm"
                            aria-label="Default select example"
                            onChange={async (e) => await setStatusCd(e.target.value)}
                            value={statusCd}
                            style={{width: "150px"}}
                          >
                            <option value="HIDE" selected={statusCd === 'HIDE'}>HIDE</option>
                            <option value="SHOW" selected={statusCd === 'SHOW'}>SHOW</option>
                            <option value="SHOW" selected={statusCd === 'DELETE'}>DELETE</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th className="border-right">유사 제작 사례 이미지</th>
                        <td>
                          <div className="d-flex">
                            <p className="thum_add">
                              <button type="button" className="btn btn-sm p_absolute_mid" onClick={onImageUpload}>
                                <img src={add_circle} style={{width: "50px", marginLeft: "-5px"}}/>
                              </button>
                            </p>

                            {uploadImages && uploadImages.map((image, index) => (
                              <p key={index} className="thum_add p-0">
                                <button
                                  type="button"
                                  className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                                  onClick={() => onDeleteUploadImage(image.photo_fid)}
                                >
                                  <img src={close_w} className="comp_thum_add_del"/>
                                </button>
                                <img
                                  src={
                                    image?.photo_fid.length > 0
                                      ? `${Constants.API_HOST}/common/download?fid=${image.photo_fid}&cd=inline&thumb=400`
                                      : ""
                                  }
                                  style={{
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    borderColor: "#cfcfcf",
                                  }}
                                />
                              </p>
                            ))}

                            {imageList.map((image, index) => (
                              <p key={index} className="thum_add">
                                <button
                                  type="button"
                                  className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                                  onClick={() => onImageRemove(index)}
                                >
                                  <img src={close_w} className="thum_add_del"/>
                                </button>
                                <img
                                  src={image["data_url"]}
                                  onClick={() => onImageUpdate(index)}
                                  style={{
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    borderColor: "#cfcfcf",
                                  }}
                                />
                              </p>
                            ))}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th className="border-right">기타</th>
                        <td>
                          <div className="d-flex">
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="5"
                              placeholder={`전달 내용을 입력하세요.\n한글로 작성하셔도 됩니다. (번역 후 바이어에게 전달됩니다)`}
                              onChange={async (e) => await setEtc(e.target.value)}
                              value={etc}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="border-right">기타 수정</th>
                        <td>
                          <div className="d-flex">
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="5"
                              placeholder={`매니저의 수정 내용`}
                              onChange={async (e) => await setManagerEtc(e.target.value)}
                              value={managerEtc}
                            />
                          </div>
                        </td>
                      </tr>
                    </table>
                  )}
                </ImageUploading>
              </div>
            )}
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminRequestOem;
