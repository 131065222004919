import React, {useEffect, useState} from "react";
import first_page_black_48dp from "../../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../../image/chevron_left_black_24dp.svg";
import navigate_next_black_48dp from "../../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../../image/last_page_black_48dp.svg";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import ManagerList from "../common/ManagerList";
import APIHelper from "../../../helpers/APIHelper";
import moment from "moment";
import Paging from "../../common/Paging";

const AdminManagerVisitLog = (props) => {
  const [me, setMe] = useState({});
  const [results, setResults] = useState();
  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      await gets(me);
    }
    fetch();
  }, [me?.id, currPage]);

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "어드민 관리 > 관리자 접속 정보",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const managerClick = async (e) => {
    setMe(e);
    setCurrPage(1);
  };

  // 방문기록(바이어) 가져오기
  const gets = (item) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("admin/login/gets", {
        articlesPerPage: 15,
        adminId: item.id,
        paging: 1,
        currPage
      });
      if (pageInfo) setPageInfo(pageInfo);
      setResults(result);
      window.scrollTo(0, 0);
      return resolve(true);
    });
  };

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{ height: "calc(100% - 30px)", minHeight: 800 }}
      >
        <section className="col-4" style={{ height: "100%" }}>
          <ManagerList click={managerClick} renew={new Date().getTime()} type={"ord"}/>
        </section>
        <section className="col-8" style={{ height: "100%" }}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{ height: "98%" }}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">접속 내역</p>
              <p className="m-1 mb-2"></p>
            </div>

            <p className="px-3 text-start bold my-3">총 {pageInfo?.totalArticles || 0}건</p>
            {results?.length > 0 &&
              <div
                className="wid_scroll scroll_y custom_scroll px-2"
                style={{height: "auto"}}
              >
                <article className="wid_inline">
                  <table className="table basic w-100">
                    <colgroup>
                      <col width="150px"/>
                      <col width="150px"/>
                      <col width="*"/>
                    </colgroup>
                    <tr>
                      <th className="center">일시</th>
                      <th className="center">IP Addr</th>
                      <th className="center">브라우저 헤더</th>
                    </tr>
                    {results && results?.map((item, i) => (
                      <tr>
                        <td className="center">{moment(item.created_at).format("YYYY.MM.DD HH:mm")}</td>
                        <td className="center">{item.ip_addr}</td>
                        <td className="left">{item.header}</td>
                      </tr>
                    ))}
                  </table>
                </article>
              </div>
            }

            {(!results || results?.length === 0) &&
              <div className="col w-100">
                <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-lblue bold">
                  방문 기록이 없습니다.
                </div>
              </div>
            }
            <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminManagerVisitLog;
