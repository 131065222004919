import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import first_page_black_48dp from "../../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../../image/chevron_left_black_24dp.svg";
import navigate_next_black_48dp from "../../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../../image/last_page_black_48dp.svg";
import ProfileMaker from "./ProfileMaker";

import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import Constants from "../../../constants/constants";
import CommonLib from "../../../libraries/CommonLib";

const ProfileMakerProduct = (props) => {
  const history = useHistory();
  const [makerProducts, setMakerProducts] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      await load();
    }
    fetch();
  }, []);

  const load = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm(`user/maker/product/gets`);
      if (code === 200) {
        setMakerProducts(result);
      }
      return resolve(true);
    });
  }

  const remove = async (pkId) => {
    return new Promise(async (resolve, reject) => {
      if (!await CommonLib.jconfirm('삭제하시겠습니까?')) return resolve(true);
      const {data: {code, result, message}} = await APIHelper.deleteForm(`user/maker/product/delete?pkId=${pkId}`);
      await load();
      return resolve(true);
    });
  }

  const modify = (id) => {
    history.push(`/profile/maker/product/add/${id}`);
  }

  return (
    <ProfileMaker>
      <div className="d-flex justify-content-between">
        <p/>
        <p className="text-center">
          <Link to="/profile/maker/product/add">
            <button type="button" className="btn btn_puple px-3 mb-4">
              제품 추가
            </button>
          </Link>
        </p>
      </div>
      <div className="row row-cols-4 g-3 pt-3">
        {makerProducts && makerProducts.map((makerProduct, i) =>
          <div className="col">
            <div className="box_border p_rative">
              {makerProduct.show_yn === 'Y' &&
                <div className="p_absolute_tl">
                  <p className="Oval_md bg-darkred pt-2 bold">노출</p>
                </div>
              }
              <p className="border-bottom" style={{height: "165px"}}>
                {makerProduct?.dtls.filter(i => i.image_fid !== '').length > 0 &&
                  <img
                    src={`${Constants.API_HOST}/common/download?fid=${makerProduct?.dtls.filter(i => i.image_fid !== '')[0]?.image_fid}&cd=inline&thumb=400`}
                    style={{height: "165px", objectFit: "cover"}}
                  />
                }
              </p>
              <p className="p-3" style={{height: "120px"}}>
                <a href="">
                  <span className="txt-brown bold">{makerProduct?.category?.name_kr}{makerProduct?.product?.name_kr?.length > 0 ? ('-' + makerProduct?.product?.name_kr) : ""}</span>
                  <br/>
                  <span className="txt-gray" style={{
                    "overflow": "hidden",
                    "textOverflow": "ellipsis",
                    "display": "block",
                    "line-height": "normal",
                    "-webkit-line-clamp": "2",
                    "-webkit-box-orient": "vertical",
                    "-moz-line-clamp": "2",
                    "maxHeight": "60px",
                  }}>{makerProduct?.name}</span>
                </a>
              </p>
              <div className="px-3 mb-3">
                <div
                  className="btn-group btn-group-sm w-100"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <button type="button" className="btn btn-outline-light txt-sm" onClick={() => modify(makerProduct.id)}>
                    수정
                  </button>
                  <button type="button" className="btn btn-outline-light txt-sm" onClick={() => remove(makerProduct.id)}>
                    삭제
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="d-flex justify-content-center py-4 d-none">
        <ul className="d-flex my-3 p-0">
          <li className="PAGE">
            <a href="#" aria-label="Previous">
              <img src={first_page_black_48dp} style={{width: "25px"}}/>
            </a>
          </li>
          <li className="PAGE">
            <a href="#" aria-label="Previous">
              <img src={chevron_left_black_24dp} style={{width: "25px"}}/>
            </a>
          </li>
          <li className="PAGE">
            <a href="#" className="ON">
              1
            </a>
          </li>
          <li className="PAGE">
            <a href="#">2</a>
          </li>
          <li className="PAGE">
            <a href="#">3</a>
          </li>
          <li className="PAGE">
            <a href="#" aria-label="Previous">
              <img src={navigate_next_black_48dp} style={{width: "25px"}}/>
            </a>
          </li>
          <li className="PAGE">
            <a href="#" aria-label="Previous">
              <img src={last_page_black_48dp} style={{width: "25px"}}/>
            </a>
          </li>
        </ul>
      </div>
    </ProfileMaker>
  );
};

export default ProfileMakerProduct;
