import React from "react";

import FaqItem from "./FaqItem";

import "../../sass/main.scss";
import moment from "moment";

const FaqItems = (props) => {
  return (
    <>
      {props.items.map((item, i) => (
        <FaqItem
          key={i}
          no={props.items.length - i}
          title={item.question}
          category={item.gubun?.name_kr}
          text={item.text_answer}
          date={moment(item.created_at).format('YYYY.MM.DD')}
        />
      ))}
    </>
  );
};

export default FaqItems;
