import React, {useEffect, useRef, useState} from "react";
import BuyerTimelineCard from "./BuyerTimelineCard";
import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import $ from "jquery";
import {useDispatch} from "react-redux";
import openRequestFormulation from "../../../actions/openRequestFormulation";
import openRequestDesign from "../../../actions/openRequestDesign";
import openRequestBottle from "../../../actions/openRequestBottle";
import openRequestBox from "../../../actions/openRequestBox";
import openTTTransfer from "../../../actions/openTTTransfer";
import CommonLib from "../../../libraries/CommonLib";
import {Link} from "react-router-dom";
import pay_img01 from "../../../image/pay_img01.png";
import pay_img03 from "../../../image/pay_img03.png";
import icon_minus_g from "../../../image/icon_minus_g.svg";
import icon_plus_g from "../../../image/icon_plus_g.svg";
import openTimelineAttach from "../../../actions/openTimelineAttach";
import openEstimate from "../../../actions/openEstimate";
import CanvasDraw from "react-canvas-draw";
import {PayPalButtons, PayPalScriptProvider,} from "@paypal/react-paypal-js";
import Constants from "../../../constants/constants";
import {confirmAlert} from "react-confirm-alert";

const BuyerTimeline = (props) => {
  const dv = props?.location?.state?.dv || props?.match?.params?.dv;
  const id = props?.location?.state?.id || props?.match?.params?.id;

  const [paymentMethod, setPaymentMethod] = useState(0);
  const [timelines, setTimelines] = useState([]);
  const [step1Timelines, setStep1Timelines] = useState([]);
  const [step2Timelines, setStep2Timelines] = useState([]);
  const [step3Timelines, setStep3Timelines] = useState([]);
  const [step4Timelines, setStep4Timelines] = useState([]);
  const [step5Timelines, setStep5Timelines] = useState([]);
  const [step6Timelines, setStep6Timelines] = useState([]);
  const [timeline, setTimeline] = useState({});
  const [lastest, setLastest] = useState({});
  const canvasRef = useRef();

  const fetch = async () => {
    await getTimelines();
    window.scrollTo(0, document.body.scrollHeight);
  }

  useEffect(() => {
    (async () => {
      setPaymentMethod(0);
      await fetch();
    })();
  }, [lastest?.id, lastest?.updated_at]);

  let invalTimeline;
  useEffect(() => {
    invalTimeline = setInterval(async function () {
      const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/lastest/light", {projectId: id, type: 'BUYER'});
      if (code === 200) setLastest(result);
    }, 2000);
    return () => clearInterval(invalTimeline);
  }, []);

  const processTimelines = (timelines, data) => {
    let title = '';
    let subtitle = '';
    let content = '';
    let html = '';
    let buttons = [];

    const last = timelines[timelines.length - 1];

    /*
    if (
      last?.status === '제품 수령 확인' || last?.status === '제품 수령 완료'
    ) {
      if (parseInt(data.custompass_cnt_accept, 10) >= 0) {
        title = '';
        content = `${data.custompass_cnt_accept}/${data.custompass_cnt_total} 완료되었습니다.`;
        html = ``;
        buttons.push({
          name: '확인',
          click: async () => {
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/delivery/fixed`, {
              projectId: last?.project_id,
              projectProductId: last?.project_product_id,
            });
            await CommonLib.jalert(message);
          },
          disabled: parseInt(data.custompass_cnt_accept, 10) < parseInt(data.custompass_cnt_total, 10)
        });

        const obj = {
          translated: true,
          status: title,
          title,
          subtitle,
          content,
          html,
          step: 5,
          dt: moment().format("YYYY.MM.DD HH:mm"),
          buttons
        };

        timelines.push(obj);
      }
    }
    */

    const skips = [];
    const ntimelines = [];
    for (const [key, timeline] of Object.entries(timelines)) {
      if (skips.indexOf(timeline.status) === -1 && !timeline.status.endsWith(' - DONE')) {
        ntimelines.push(timeline);
      }
    }

    return ntimelines;
  }

  const getTimelines = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/gets", {projectId: id, type: 'BUYER'});
      if (code === 200) {
        const {data: {result: data}} = await APIHelper.getForm("project/timeline/data", {projectId: id});
        const trets = processTimelines(result, data);
        setTimelines(trets);

        const {data: {code, result: iResult, message}} = await APIHelper.getForm("project/timeline/lastest", {projectId: id, type: 'BUYER'});
        setTimeline(iResult);

        setStep1Timelines(trets.filter(item => item.step === 1) || []);
        setStep2Timelines(trets.filter(item => item.step === 2) || []);
        setStep3Timelines(trets.filter(item => item.step === 3) || []);
        setStep4Timelines(trets.filter(item => item.step === 4) || []);
        setStep5Timelines(trets.filter(item => item.step === 5) || []);
        setStep6Timelines(trets.filter(item => item.step === 6) || []);

        setStep(iResult?.step || 1);
      }

      $('.btn-request-form').click(function () {
        openRequestForm($(this).attr('projectId'), $(this).attr('projectProductId'), $(this).attr('requestType'));
      });

      $('.btn-bank-info').click(function () {
        openTTTransferModal($(this).attr('projectId'), $(this).attr('projectProductId'), $(this).attr('projectMakerRequestId'), $(this).attr('timelineId'), $(this).attr('status'));
      });

      $('.btn-estimate-form').click(function () {
        if ($(this).attr('status').includes('단순') || $(this).attr('status') === '본 견적서') downloadTimelineAttachModal($(this).attr('projectId'), $(this).attr('projectProductId'), $(this).attr('projectMakerRequestId'), $(this).attr('status'), $(this).attr('timelineId'));
        else downloadEstimateModal($(this).attr('projectId'), $(this).attr('projectProductId'), $(this).attr('projectMakerRequestId'), $(this).attr('status'), $(this).attr('timelineId'));
      });

      $('.t-container').not(':last').each(function () {
        $(this).find('.btn').not('.btn-action').each(function () {
          $(this).attr('disabled', true);
          if ($(this).parent().is("a")) $(this).unwrap();
        });
        $(this).find('.btn-action').each(function () {
          $(this).attr('disabled', false);
        });
      });

      return resolve(true);
    });
  };

  const dispatch = useDispatch();

  const downloadTimelineAttachModal = (projectId, projectProductId, projectMakerRequestId, formType) => {
    const obj = {
      projectId,
      projectProductId,
      projectMakerRequestId,
      title: formType.replace(' 작성', ''),
      renew: new Date().getTime(),
      readOnly: true,
      formType
    };
    dispatch(openTimelineAttach("open", obj));
  }

  const downloadEstimateModal = (projectId, projectProductId, projectMakerRequestId, formType, timelineId) => {
    const obj = {
      projectId,
      projectProductId,
      projectMakerRequestId,
      title: formType.replace(' 작성', ''),
      renew: new Date().getTime(),
      readOnly: true,
      formType,
      timelineId
    };
    dispatch(openEstimate("open", obj));
  }

  const openTTTransferModal = (projectId, projectProductId, projectMakerRequestId, timelineId, status) => {
    const obj = {
      projectId,
      projectProductId,
      projectMakerRequestId,
      timelineId,
      status,
      renew: new Date().getTime()
    };
    dispatch(openTTTransfer("open", obj));
  }

  const openRequestForm = (projectId, projectProductId, itemCd) => {
    const obj = {projectId, projectProductId, renew: new Date().getTime(), readOnly: true};
    if (itemCd === 'MF') dispatch(openRequestFormulation("open", obj));
    else if (itemCd === 'DS') dispatch(openRequestDesign("open", obj));
    else if (itemCd === 'BT') dispatch(openRequestBottle("open", obj));
    else if (itemCd === 'BX') dispatch(openRequestBox("open", obj));
  }

  const [step01, setStep01] = useState(true);
  const [step02, setStep02] = useState(true);
  const [step03, setStep03] = useState(true);
  const [step04, setStep04] = useState(true);
  const [step05, setStep05] = useState(true);
  const [step06, setStep06] = useState(true);

  const [step, setStep] = useState(1);

  const makeTimelineObj = (item, timelines) => {
    item.canvasRef = canvasRef;
    const obj = CommonLib.getTimelineObj(item, timelines);

    if (obj.status.includes("결제 요청") && !obj.status.includes(" - T.T송금")) {
      return (
        <div
          className="box_round_5 bg-white border px-4 py-3 p_rative my-2 t-container"
          style={{textAlign: "left", minHeight: "100px"}}
        >
          <div className="p_absolute" style={{top: "15px", right: "15px"}}>
            <p className="txt-sm txt-gray ">{obj.dt}</p>
          </div>

          <div className="m-3">
            <p className="h6 bold">{obj.title}</p>
            {obj?.html?.length > 0 && <div className="my-2" dangerouslySetInnerHTML={{__html: obj.html}}/>}
            <div className="p-3">
              <p className="h6">
                <span className="h6 bold">
                  {CommonLib.escapedNewLineToLineBreakTag(obj.content)}
                  <br/>
                </span>
              </p>

              <div
                className="btn-group btn-group my-2"
                role="group"
                aria-label="..."
              >
                <button type="button" className="btn btn_outline_puple bold px-4" onClick={async () => {
                  setPaymentMethod(1);
                }}>
                  <img src={pay_img01} style={{width: "60px"}}/>
                  <p className="mt-2">PAYPAL</p>
                </button>
                <button type="button" className="btn btn_outline_puple bold px-4" onClick={async () => {
                  if (!await CommonLib.jconfirm("T.T 송금을 진행하시겠습니까?")) return;
                  setPaymentMethod(2);

                  confirmAlert({
                    title: 'DARLIBABA',
                    message: '결제 통화를 선택하세요',
                    buttons: [
                      {
                        label: 'USD',
                        onClick: () => {
                          const obj = {
                            projectId: item?.project_id,
                            projectProductId: item?.project_product_id,
                            projectMakerRequestId: item?.project_maker_request_id,
                            timelineId: item?.id,
                            title: item.status,
                            renew: new Date().getTime(),
                            readOnly: true,
                            formType: item.status,
                            step: item.step,
                            refCtnt1: 'USD',
                            refCtnt2: CommonLib.parseFloat(item.ref_ctnt_1).toFixed(4)
                          };
                          dispatch(openTTTransfer("open", obj));
                        },
                      },
                      {
                        label: 'KRW',
                        onClick: () => {
                          const obj = {
                            projectId: item?.project_id,
                            projectProductId: item?.project_product_id,
                            projectMakerRequestId: item?.project_maker_request_id,
                            timelineId: item?.id,
                            title: item.status,
                            renew: new Date().getTime(),
                            readOnly: true,
                            formType: item.status,
                            step: item.step,
                            refCtnt1: 'KRW',
                            refCtnt2: CommonLib.parseFloat(item.ref_ctnt_1).toFixed(4)
                          };
                          dispatch(openTTTransfer("open", obj));
                        },
                      }
                    ]
                  });
                }}>
                  <img src={pay_img03} style={{width: "60px"}}/>
                  <p className="mt-2">T.T 송금</p>
                </button>
              </div>

              {paymentMethod === 1 &&
                <div
                  className="btn-group btn-group my-2"
                  role="group"
                  aria-label="..."
                  style={{display: `${item.action_yn === 1 ? "none" : "block"}`}}
                >
                  <PayPalScriptProvider options={{"client-id": Constants.PAYPAL_CLIENTID, components: 'buttons'}}>
                    <PayPalButtons
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                value: CommonLib.parseFloat(item.ref_ctnt_1).toFixed(2)
                              },
                            },
                          ],
                        });
                      }}
                      onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                          // const name = details.payer.name.given_name;
                          // alert(`Transaction completed by ${name}`);

                          (async () => {
                            await CommonLib.dispatchAction(item);

                            if (obj.status.includes('샘플')) {
                              const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/estimate/payment`, {
                                projectId: item?.project_id,
                                projectProductId: item?.project_product_id,
                                projectMakerRequestId: item?.project_maker_request_id,
                              });
                              await CommonLib.jalert(message);
                            } else if (obj.status.includes('잔금')) {
                              const {data: {code, result, message}} = await APIHelper.postForm(`flow/buyer/balance/payment/submit`, {
                                projectId: item?.project_id,
                                projectProductId: item?.project_product_id,
                                projectMakerRequestId: item?.project_maker_request_id,
                              });
                              await CommonLib.jalert(message);
                            } else {
                              const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/payment`, {
                                projectId: item?.project_id,
                                projectProductId: item?.project_product_id,
                                projectMakerRequestId: item?.project_maker_request_id,
                              });
                              await CommonLib.jalert(message);
                            }
                          })();
                        });
                      }}
                    />
                  </PayPalScriptProvider>
                </div>
              }
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="p_rative border mt-3 t-container">
          <div className="bg-purple p-2 px-3 border-bottom time_box_l_on ">
            <p className="txt-md bold">{obj.title}</p>
            <p className="txt-sm">{obj.dt}</p>
          </div>

          <div className="p-3">
            {obj.subtitle.length > 0 && <p className="box_round_30 bg-lgray0 txt-sm bold p-1">{obj.subtitle}</p>}
            <p className="my-2">{CommonLib.escapedNewLineToLineBreakTag(obj?.content)}</p>
            {obj?.html?.length > 0 && <div className="my-2" dangerouslySetInnerHTML={{__html: obj.html}}/>}
            {obj.status.includes("서명하기") &&
              <div className="w-100" style={{width: "100%"}}>
                <CanvasDraw
                  ref={item.canvasRef}
                  saveData={item.ref_ctnt_1 || null}
                  immediateLoading={true}
                  disabled={item?.ref_ctnt_1?.length > 0}
                  canvasWidth={380}
                  canvasHeight={200}
                  brushRadius={4}
                  lazyRadius={0}
                />
              </div>
            }
            {obj.buttons.length > 0 &&
              <div
                className="btn-group btn-group-sm my-2"
                role="group"
                aria-label="..."
              >
                {obj.buttons.map((button, key) =>
                  button.link && button.link.length > 0 ?
                    <Link key={key} to={button.link} target={button.target}>
                      <button
                        type="button"
                        className={`btn btn_outline_puple bold px-3 btn-sm ${button.action ? "btn-action" : ""}`}
                        style={{borderRadius: "0"}}
                        disabled={(!button.action && (button.prevent || button.disabled || CommonLib.parseInt(item.action_yn) === 1)) || false}
                      >
                        <span className="txt-sm">{button.name}</span>
                      </button>
                    </Link>
                    :
                    button.click ?
                      <button
                        key={key}
                        type="button"
                        className={`btn btn_outline_puple bold px-3 btn-sm ${button.action ? "btn-action" : ""}`}
                        onClick={button.click}
                        style={{borderRadius: "0"}}
                        disabled={(!button.action && (button.prevent || button.disabled || CommonLib.parseInt(item.action_yn) === 1)) || false}
                      >
                        <span className="txt-sm">{button.name}</span>
                      </button>
                      : ""
                )}
              </div>
            }
          </div>
        </div>
      )
    }
  }

  return (
    <BuyerTimelineCard step={step} dv={dv} id={id}>
      <div className="container my-4 timeline" style={{overflowY: "auto"}}>
        <div className="row justify-content-start">
          <div className="col-6 pb-4">
            <div
              className="d-flex my-4 justify-content-end"
              style={{marginRight: "-23px"}}
            >
              <div onClick={() => setStep01(!step01)}>
                <p
                  className="box_round_30 border_gray_1 mx-2 h6 bold px-2"
                  style={{paddingTop: "1px"}}
                >
                  <span className="px-2">단가조사 ({step1Timelines?.length})</span>
                  <button type="button" className="btn p-0 m-0" style={{width: "18px", height: "18px"}}>
                    <img
                      style={{width: "15px", height: "15px", marginTop: "-12px"}}
                      src={step01 ? icon_minus_g : icon_plus_g}
                    />
                  </button>
                </p>
              </div>
              <p
                className={`btn_oval_25 bg-${
                  step === 1 ? "purple" : "gray"
                } txt-white txt-sm bold`}
                style={{paddingTop: "3px"}}
              >
                01
              </p>
            </div>
            {step01 && step1Timelines.map((item, key) => {
              return makeTimelineObj(item, timelines);
            })}
          </div>
        </div>

        <div className="row justify-content-end timeline_on">
          <div className="col-6 pb-4">
            <div className="p_rative">
              <div
                className="d-flex justify-content-start"
                style={{margin: "0 0 0 -23px"}}
              >
                <p
                  className={`btn_oval_25 bg-${
                    step === 2 ? "purple" : "gray"
                  } txt-white txt-sm bold`}
                  style={{paddingTop: "3px"}}
                >
                  02
                </p>
                <div onClick={() => setStep02(!step02)}>
                  <p
                    className="box_round_30 border_gray_1 mx-2 h6 bold px-2"
                    style={{paddingTop: "1px"}}
                  >
                    <span className="px-2">샘플 디자인 ({step2Timelines?.length})</span>
                    <button type="button" className="btn p-0 m-0" style={{width: "18px", height: "18px"}}>
                      <img
                        style={{width: "15px", height: "15px", marginTop: "-12px"}}
                        src={step02 ? icon_minus_g : icon_plus_g}
                      />
                    </button>
                  </p>
                </div>
              </div>
              {step02 && step2Timelines.map((item, key) => {
                return makeTimelineObj(item, timelines);
              })}
            </div>
          </div>
        </div>

        <div className="row justify-content-start">
          <div className="col-6 pb-4">
            <div
              className="d-flex justify-content-end"
              style={{marginRight: "-23px"}}
            >
              <div onClick={() => setStep03(!step03)}>
                <p
                  className="box_round_30 border_gray_1 mx-2 h6 bold px-2"
                  style={{paddingTop: "1px"}}
                >
                  <span className="px-2">견적 및 계약 ({step3Timelines?.length})</span>
                  <button type="button" className="btn p-0 m-0" style={{width: "18px", height: "18px"}}>
                    <img
                      style={{width: "15px", height: "15px", marginTop: "-12px"}}
                      src={step03 ? icon_minus_g : icon_plus_g}
                    />
                  </button>
                </p>
              </div>
              <p
                className={`btn_oval_25 bg-${
                  step === 3 ? "purple" : "gray"
                } txt-white txt-sm bold`}
                style={{paddingTop: "3px", marginRight: "-2px"}}
              >
                03
              </p>
            </div>
            {step03 && step3Timelines.map((item, key) => {
              return makeTimelineObj(item, timelines);
            })}
          </div>
        </div>

        <div className="row justify-content-end">
          <div className="col-6 pb-4">
            <div className="p_rative">
              <div
                className="d-flex justify-content-start"
                style={{margin: "0 0 0 -23px"}}
              >
                <p
                  className={`btn_oval_25 bg-${
                    step === 4 ? "purple" : "gray"
                  } txt-white txt-sm bold`}
                  style={{paddingTop: "3px"}}
                >
                  04
                </p>
                <div onClick={() => setStep04(!step04)}>
                  <p
                    className="box_round_30 border_gray_1 mx-2 h6 bold px-2"
                    style={{paddingTop: "1px"}}
                  >
                    <span className="px-2">제품 제작 ({step4Timelines?.length})</span>
                    <button type="button" className="btn p-0 m-0" style={{width: "18px", height: "18px"}}>
                      <img
                        style={{width: "15px", height: "15px", marginTop: "-12px"}}
                        src={step04 ? icon_minus_g : icon_plus_g}
                      />
                    </button>
                  </p>
                </div>
              </div>
              {step04 && step4Timelines.map((item, key) => {
                return makeTimelineObj(item, timelines);
              })}
            </div>
          </div>
        </div>

        <div className="row justify-content-start">
          <div className="col-6 pb-4">
            <div
              className="d-flex justify-content-end"
              style={{marginRight: "-23px"}}
            >
              <div onClick={() => setStep05(!step05)}>
                <p
                  className="box_round_30 border_gray_1 mx-2 h6 bold px-2"
                  style={{paddingTop: "1px"}}
                >
                  <span className="px-2">통관/선적 ({step5Timelines?.length})</span>
                  <button type="button" className="btn p-0 m-0" style={{width: "18px", height: "18px"}}>
                    <img
                      style={{width: "15px", height: "15px", marginTop: "-12px"}}
                      src={step05 ? icon_minus_g : icon_plus_g}
                    />
                  </button>
                </p>
              </div>

              <p
                className={`btn_oval_25 bg-${
                  step === 5 ? "purple" : "gray"
                } txt-white txt-sm bold`}
                style={{paddingTop: "3px", marginRight: "-2px"}}
              >
                05
              </p>
            </div>
            {step05 && step5Timelines.map((item, key) => {
              return makeTimelineObj(item, timelines);
            })}
          </div>
        </div>

        <div className="row justify-content-end">
          <div className="col-6 pb-4">
            <div
              className="d-flex justify-content-start"
              style={{margin: "0 0 0 -23px"}}
            >
              <p
                className={`btn_oval_25 bg-${
                  step === 6 ? "purple" : "gray"
                } txt-white txt-sm bold`}
                style={{paddingTop: "3px"}}
              >
                06
              </p>

              <div onClick={() => setStep06(!step06)}>
                <p
                  className="box_round_30 border_gray_1 mx-2 h6 bold px-2"
                  style={{paddingTop: "1px"}}
                >
                  <span className="px-2">완료</span>
                  <button type="button" className="btn p-0 m-0" style={{width: "18px", height: "18px"}}>
                    <img
                      style={{width: "15px", height: "15px", marginTop: "-12px"}}
                      src={step06 ? icon_minus_g : icon_plus_g}
                    />
                  </button>
                </p>
              </div>
            </div>
            {step06 && step6Timelines.map((item, key) => {
              return makeTimelineObj(item, timelines);
            })}
          </div>
        </div>
      </div>
    </BuyerTimelineCard>
  );
};

export default BuyerTimeline;
