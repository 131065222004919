import React, {useEffect, useRef, useState} from "react";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import refresh_b from "../../../image/refresh_b.svg";
import APIHelper from "../../../helpers/APIHelper";
import {Link} from "react-router-dom";
import CommonLib from "../../../libraries/CommonLib";
import $ from "jquery";
import {useDispatch} from "react-redux";
import openRequestFormulation from "../../../actions/openRequestFormulation";
import openRequestDesign from "../../../actions/openRequestDesign";
import openRequestBottle from "../../../actions/openRequestBottle";
import openRequestBox from "../../../actions/openRequestBox";
import openTimelineAttach from "../../../actions/openTimelineAttach";
import openTimelineRequest from "../../../actions/openTimelineRequest";
import moment from "moment";
import openEstimate from "../../../actions/openEstimate";
import CanvasDraw from "react-canvas-draw";
import openTTTransfer from "../../../actions/openTTTransfer";
import modalStyle from "../../../actions/openModalStyle";

const AdminProjectTimeline = (props) => {
  const id = props?.location?.state?.id || props?.match?.params?.id;
  const [timelines, setTimelines] = useState([]);
  const [timelinesO, setTimelinesO] = useState([]);
  const [lastest, setLastest] = useState({});
  const canvasRef = useRef();
  const [renew, setRenew] = useState('');

  const useScrollToBottom = (ref) => {
    const scrollToBottom = () => {
      ref.current.style.scrollBehavior = 'smooth';
      ref.current.scrollTop = ref.current.scrollHeight;
    };

    return {
      scrollToBottom,
    }
  }

  const ref = useRef();
  const {scrollToBottom} = useScrollToBottom(ref);

  const fetch = async () => {
    await getTimelines();
    scrollToBottom();
  }

  const projectManufacturer = (projectId) => {
    const obj = {projectId,  renew: new Date().getTime()};
    dispatch(openTimelineRequest("open", obj))
  }

  const [timelineMode, setTimelineMode] = useState('MANAGER');

  useEffect(() => {
    (async () => {
      await fetch();
    })();
  }, [lastest?.id, lastest?.updated_at, timelineMode]);

  let invalTimeline;
  useEffect(() => {
    invalTimeline = setInterval(async function () {
      const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/lastest/light", {projectId: id, type: timelineMode});
      if (code === 200) {
        setLastest(result);
      }
    }, 2000);
    return () => clearInterval(invalTimeline);
  }, []);

  const processTimelines = (timelines, data) => {
    let title = '';
    let subtitle = '';
    let content = '';
    let html = '';
    let buttons = [];

    const last = timelines[timelines.length - 1];

    if (
        last?.status === '간단 견적서 확인 완료' ||
        last?.status === '간단 견적서 확인'
    ) {
      if (parseInt(data.maker_request_cnt_accept, 10) > 0) {
        title = '간단 견적서 작성 상태';
        content = `${data.maker_request_cnt_accept}/${data.maker_request_cnt_total} 완료되었습니다.`;
        html = ``;
        buttons.push({
          name: '확인',
          click: async () => {
            {
              const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
                projectId: last.project_id,
                projectProductId: last.project_product_id,
                // projectMakerRequestId: last.project_maker_request_id,
                step: 1,
                type: 'MANAGER',
                status: '바이어용 견적서 작성'
              });
            }
            {
              const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
                projectId: last.project_id,
                projectProductId: last.project_product_id,
                // projectMakerRequestId: last.project_maker_request_id,
                step: 1,
                type: 'BUYER',
                status: '견적서 작성중'
              });
            }
          },
          disabled: parseInt(data.maker_request_cnt_accept, 10) < parseInt(data.maker_request_cnt_total, 10),
          prevent: timelines.filter(t => t?.project_id === last?.project_id && t?.project_product_id === last?.project_product_id && t?.type === 'MANAGER' && t?.status === '바이어용 견적서 작성').length > 0,
        });

        const obj = {
          translated: true,
          status: title,
          title,
          subtitle,
          content,
          html,
          dt: moment().format("YYYY.MM.DD HH:mm"),
          buttons
        };

        timelines.push(obj);
      }
    } else if (
        last?.status === '샘플 본사 수령 완료' ||
        last?.status === '샘플 수령 후 승인'
    ) {
      if (parseInt(data.sample_progress_cnt_accept, 10) > 0) {
        title = '샘플 수령 후 승인 상태';
        content = `${data.sample_progress_cnt_accept}/${data.sample_progress_cnt_total} 완료되었습니다.`;
        html = ``;
        buttons.push({
          name: '확인',
          click: async () => {
            const {data: {code, result, message}} = await APIHelper.postForm("flow/sample/done", {
              projectId: last.project_id,
              projectProductId: last.project_product_id,
            });
            await CommonLib.jalert('제조사 측에 본 견적서 작성 메세지를 발송하였습니다.');
          },
          disabled: parseInt(data.sample_progress_cnt_accept, 10) < parseInt(data.sample_progress_cnt_total, 10),
          prevent: timelines.filter(t => t?.project_id === last?.project_id && t?.project_product_id === last?.project_product_id && t?.type === 'MANAGER' && t?.status === '샘플 수령 후 승인 - DONE').length > 0,
        });

        const obj = {
          translated: true,
          status: title,
          title,
          subtitle,
          content,
          html,
          dt: moment().format("YYYY.MM.DD HH:mm"),
          buttons
        };

        timelines.push(obj);
      }
    } else if (
        last?.status === '본 견적서 확인 및 결정' ||
        last?.status === '본 견적서 확인 및 결정 완료'
    ) {
      if (parseInt(data.real_estimate_cnt_accept, 10) > 0) {
        title = '본 견적서 확인 및 결정 완료 상태';
        content = `${data.real_estimate_cnt_accept}/${data.real_estimate_cnt_total} 완료되었습니다.`;
        html = ``;
        buttons.push({
          name: '확인',
          click: async () => {
            {
              const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
                projectId: last.project_id,
                projectProductId: last.project_product_id,
                projectMakerRequestId: last.project_maker_request_id,
                step: 3,
                type: 'MANAGER',
                status: '바이어용 본 견적서 작성'
              });
            }
          },
          disabled: parseInt(data.real_estimate_cnt_accept, 10) < parseInt(data.real_estimate_cnt_total, 10),
          prevent: timelines.filter(t => t?.project_id === last?.project_id && t?.project_product_id === last?.project_product_id && t?.project_maker_request_id === last?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '바이어용 본 견적서 작성').length > 0,
        });

        const obj = {
          translated: true,
          status: title,
          title,
          subtitle,
          content,
          html,
          dt: moment().format("YYYY.MM.DD HH:mm"),
          buttons
        };

        timelines.push(obj);
      }
    } else if (
        last?.status === '계약서 확인 및 서명 완료' ||
        last?.status === '계약서 작성' ||
        last?.status === '계약서 전달 및 서명 요청' ||
        last?.status === '제조사 계약 입금 처리' ||
        last?.status === '계약 및 서명 완료 처리'
    ) {
      if (parseInt(data.signing_cnt_total, 10) > 0) {
        title = '';
        content = `${data.signing_cnt_accept}/${data.signing_cnt_total} 서명 완료되었습니다.\n${data.receipt_cnt_accept}/${data.receipt_cnt_total} 입금 완료되었습니다.`;
        html = ``;
        buttons.push({
          name: '확인',
          click: async () => {
            {
              const {data: {code, result, message}} = await APIHelper.postForm("flow/indicate/manufacture", {
                projectId: last.project_id,
                projectProductId: last.project_product_id,
              });
            }
          },
          disabled: parseInt(data.signing_cnt_accept, 10) < parseInt(data.signing_cnt_total, 10) || parseInt(data.receipt_cnt_accept, 10) < parseInt(data.receipt_cnt_total, 10),
          prevent: timelines.filter(t => t?.project_id === last?.project_id && t?.project_product_id === last?.project_product_id && t?.type === 'MANAGER' && t?.status === '제품 생산 지시').length > 0,
        });

        const obj = {
          translated: true,
          status: title,
          title,
          subtitle,
          content,
          html,
          dt: moment().format("YYYY.MM.DD HH:mm"),
          buttons
        };

        timelines.push(obj);
      }
    } else if (
        (last?.status === '잔금 입금' ||
            last?.status === '잔금 입금 완료' ||
            last?.status === '잔금 입금 확인' ||
            last?.status === '잔금 입금 확인 - DONE'
        )
        && last?.ref_ctnt_1 === '내용물'
    ) {
      if (parseInt(data.balance_cnt_accept, 10) >= 0) {
        title = '';
        content = `${data.balance_cnt_accept}/${data.balance_cnt_total} 완료되었습니다.`;
        html = ``;
        buttons.push({
          name: '확인',
          click: async () => {
            {
              const {data: {code, result, message}} = await APIHelper.postForm("flow/balance/payment/request", {
                projectId: last.project_id,
                projectProductId: last.project_product_id,
                projectMakerRequestId: last.project_maker_request_id,
              });
              await CommonLib.jalert('바이어에게 잔금 결제 요청하였습니다.');
            }
          },
          disabled: parseInt(data.balance_cnt_accept, 10) < parseInt(data.balance_cnt_total, 10),
          prevent: timelines.filter(t => t?.project_id === last?.project_id && t?.project_product_id === last?.project_product_id && t?.project_maker_request_id === last?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '잔금 입금 완료 - DONE').length > 0,
        });

        const obj = {
          translated: true,
          status: title,
          title,
          subtitle,
          content,
          html,
          dt: moment().format("YYYY.MM.DD HH:mm"),
          buttons
        };

        timelines.push(obj);
      }
    } else if (
        last?.status === '제품 배송지로 배송'
    ) {
      if (parseInt(data.delivery_cnt_accept, 10) >= 0) {
        title = '';
        content = `${data.delivery_cnt_accept}/${data.delivery_cnt_total} 완료되었습니다.`;
        html = ``;
        buttons.push({
          name: '확인',
          click: async () => {
            {
              const {data: {code, result, message}} = await APIHelper.postForm("flow/buyer/balance/request", {
                projectId: last.project_id,
                projectProductId: last.project_product_id,
              });
              await CommonLib.jalert('바이어에게 잔금 결제를 요청하였습니다.');
            }
          },
          disabled: parseInt(data.delivery_cnt_accept, 10) < parseInt(data.delivery_cnt_total, 10),
          prevent: timelines.filter(t => t?.project_id === last?.project_id && t?.project_product_id === last?.project_product_id && t?.type === 'MANAGER' && t?.status === '제품 배송지로 배송 - DONE').length > 0,
        });

        const obj = {
          translated: true,
          status: title,
          title,
          subtitle,
          content,
          html,
          dt: moment().format("YYYY.MM.DD HH:mm"),
          buttons
        };

        timelines.push(obj);
      }
    }
    /*
    else if (
      last?.status === '통관 발송'
    ) {
      if (parseInt(data.documentation_cnt_accept, 10) >= 0) {
        title = '';
        content = `${data.documentation_cnt_accept}/${data.documentation_cnt_total} 완료되었습니다.`;
        html = ``;
        buttons.push({
          name: '확인',
          click: async () => {
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/delivery/pass`, {
              projectId: last?.project_id,
              projectProductId: last?.project_product_id,
            });
            await CommonLib.jalert(message);
          },
          disabled: parseInt(data.documentation_cnt_accept, 10) < parseInt(data.documentation_cnt_total, 10)
        });

        const obj = {
          translated: true,
          status: title,
          title,
          subtitle,
          content,
          html,
          dt: moment().format("YYYY.MM.DD HH:mm"),
          buttons
        };

        timelines.push(obj);
      }
    }
    */

    const skips = ['간단 견적서 확인 완료', '패킹 리스트', '인보이스', '면장'];
    const ntimelines = [];
    for (const [key, timeline] of Object.entries(timelines)) {
      if (skips.indexOf(timeline.status) === -1 && !timeline.status.endsWith(' - DONE')) {
        ntimelines.push(timeline);
      }
    }

    setTimelinesO(timelines);
    return ntimelines;
  }

  const getTimelines = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/gets", {projectId: id, type: timelineMode});
      if (code === 200) {
        const {data: {result: data}} = await APIHelper.getForm("project/timeline/data", {projectId: id});
        setTimelines(processTimelines(result, data));
      }

      $('.btn-request-form').click(function () {
        openRequestForm($(this).attr('projectId'), $(this).attr('projectProductId'), $(this).attr('requestType'), $(this).attr('requestId'));
      });

      $('.btn-estimate-form').click(function () {
        if ($(this).attr('status').includes('단순') || $(this).attr('status') === '본 견적서') downloadTimelineAttachModal($(this).attr('projectId'), $(this).attr('projectProductId'), $(this).attr('projectMakerRequestId'), $(this).attr('status'), $(this).attr('timelineId'));
        else downloadEstimateModal($(this).attr('projectId'), $(this).attr('projectProductId'), $(this).attr('projectMakerRequestId'), $(this).attr('status'), $(this).attr('timelineId'));
      });

      $('.container').not(':last').each(function () {
        $(this).find('.btn').not('.btn-action').each(function () {
          $(this).attr('disabled', true);
          if ($(this).parent().is("a")) $(this).unwrap();
        });
        $(this).find('.btn-action').each(function () {
          $(this).attr('disabled', false);
        });
      });

      return resolve(true);
    });
  };

  const dispatch = useDispatch();

  const downloadTimelineAttachModal = (projectId, projectProductId, projectMakerRequestId, formType) => {
    const obj = {
      projectId,
      projectProductId,
      projectMakerRequestId,
      title: formType.replace(' 작성', ''),
      renew: new Date().getTime(),
      readOnly: true,
      formType
    };
    dispatch(openTimelineAttach("open", obj));
  }

  const downloadEstimateModal = (projectId, projectProductId, projectMakerRequestId, formType, timelineId) => {
    const obj = {
      projectId,
      projectProductId,
      projectMakerRequestId,
      title: formType.replace(' 작성', ''),
      renew: new Date().getTime(),
      readOnly: true,
      formType,
      timelineId
    };
    dispatch(openEstimate("open", obj));
  }

  const openRequestForm = (projectId, projectProductId, itemCd, requestId) => {
    const obj = {projectId, projectProductId, requestId, renew: new Date().getTime(), readOnly: true};
    if (itemCd === 'MF') dispatch(openRequestFormulation("open", obj));
    else if (itemCd === 'DS') dispatch(openRequestDesign("open", obj));
    else if (itemCd === 'BT') dispatch(openRequestBottle("open", obj));
    else if (itemCd === 'BX') dispatch(openRequestBox("open", obj));
  }

  return (
      <AdminCard>
        <div
            className="col gx-3 mt-4"
            style={{height: "calc(100% - 30px)"}}
        >
          <section className="col-12" style={{height: "98%"}}>
            <div
                className="bg-lgray shadow2 my-3 box_round_4_paddingNo p-3"
                style={{height: "100%"}}
            >
              <div className="d-flex col justify-content-between">
                <p className="h5 bold p-2 border-bottom">프로젝트 타임라인</p>
                <p
                    className="ms-auto mt-1 pt-1">
                  <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      onChange={async (e) => await setTimelineMode((e.target.value === "매니저 타임라인") ? 'MANAGER' : 'BUYER')}
                      value={(timelineMode === 'MANAGER') ? "매니저 타임라인" : "바이어 타임라인"}
                      style={{width: "150px"}}
                      // onClick={refresh_timeline()}
                  >
                    <option value="매니저 타임라인" selected={timelineMode === 'MANAGER'}>매니저 타임라인</option>
                    <option value="바이어 타임라인" selected={timelineMode === 'BUYER'}>바이어 타임라인</option>
                  </select>
                </p>
                <p
                    className="m-1 mb-2">
                  <button type="button" className="mt-1 btn btn-sm btn-blue px-4" onClick={() => projectManufacturer(id)}>
                    프로젝트 제조사
                  </button>
                </p>
              </div>
              <div
                  className="bg-lpurple shadow2 my-3 box_round_4_paddingNo p-3 custom_scroll_p"
                  style={{height: "calc(98% - 40px)", maxHeight: "calc(98% - 40px)", overflowY: "auto"}}
                  ref={ref}
              >
                {timelines.map((item, key) => {
                  item.canvasRef = canvasRef;
                  const obj = CommonLib.getTimelineObj(item, timelinesO);

                  return (
                      <div key={key} className="container" style={{width: "550px"}}>
                        <div className="p_rative border mt-3">
                          <div className="bg-lgray2 p-2 px-3 border-bottom time_box_l ">
                            <p className="txt-md bold">{obj.title}</p>
                            <p className="txt-sm">{obj.dt}</p>
                          </div>

                          <div className="p-3 bg-white">
                            {obj.subtitle.length > 0 && <p className="box_round_30 bg-lgray0 txt-sm bold p-1">{obj.subtitle}</p>}
                            <p className="my-2">{CommonLib.escapedNewLineToLineBreakTag(obj?.content)}</p>
                            {obj?.html?.length > 0 && <div className="my-2" dangerouslySetInnerHTML={{__html: obj.html}}/>}
                            {obj.status.includes("서명하기") &&
                                <div className="w-100" style={{width: "100%"}}>
                                  <CanvasDraw
                                      ref={item.canvasRef}
                                      saveData={item.ref_ctnt_1 || null}
                                      immediateLoading={true}
                                      disabled={item?.ref_ctnt_1?.length > 0}
                                      canvasWidth={600}
                                      canvasHeight={200}
                                      brushRadius={4}
                                      lazyRadius={0}
                                  />
                                </div>
                            }
                            {obj.buttons.length > 0 &&
                                <div
                                    className="btn-group btn-group-sm my-2 ml-0"
                                    role="group"
                                    aria-label="..."
                                >
                                  {obj.buttons.map((button, key) =>
                                      button.link && button.link.length > 0 ?
                                          <Link key={key} to={button.link} target={button.target}>
                                            <button
                                                type="button"
                                                className={`btn btn-outline-danger bold px-3 btn-sm ${button.action ? "btn-action" : ""}`}
                                                style={{borderRadius: "0"}}
                                                disabled={(!button.action && (button.prevent || button.disabled || CommonLib.parseInt(item.action_yn) === 1)) || false}
                                            >
                                              {button.name}
                                            </button>
                                          </Link>
                                          :
                                          button.click ?
                                              <button
                                                  key={key}
                                                  type="button"
                                                  className={`btn btn-outline-danger bold px-3 btn-sm ${button.action ? "btn-action" : ""}`}
                                                  onClick={button.click}
                                                  style={{borderRadius: "0"}}
                                                  disabled={!button.action && (button.prevent || button.disabled || CommonLib.parseInt(item.action_yn)) === 1 || false}
                                              >
                                                {button.name}
                                              </button>
                                              : ""
                                  )}
                                </div>
                            }
                          </div>
                        </div>
                      </div>
                  )
                })}
              </div>
            </div>
          </section>
        </div>
      </AdminCard>
  );
};

export default AdminProjectTimeline;
