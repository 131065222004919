import React from "react";

import "../../sass/main.scss";
import InquiryItem from "./InquiryItem";
import moment from "moment";

const InquiryItems = (props) => {
  return (
    <>
      {props.items.map((item, k) => (
        <InquiryItem
          key={k}
          no={props.items.length - k}
          title={item.subject}
          category={item?.gubun?.name_kr}
          text={item.question}
          status={item.status_cd}
          date={moment(item.inquiry_date).format('YYYY.MM.DD')}
          response={item?.answer}
        />
      ))}
    </>
  );
};

export default InquiryItems;
