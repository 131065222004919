import React from "react";
import NoticeItem from "./NoticeItem";
import "../../sass/main.scss";
import moment from "moment";

const NoticeItems = (props) => {
  return (
    <>
      {props.items.map((item, i) => (
        <NoticeItem
          key={i}
          no={props.items.length - i}
          title={item.subject}
          hits={item.view_hits}
          text={item.content}
          date={moment(item.created_at).format('YYYY.MM.DD')}
        />
      ))}
    </>
  );
};

export default NoticeItems;
