import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import openDeliveryVendor from "../../actions/openDeliveryVendor";
import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import CommonLib from "../../libraries/CommonLib";

const DeliveryVendorModal = (props) => {
  const closeModal = () => {
    props.openDeliveryVendor("closed", "");
  };

  let modalInlineStyle;

  if (props.deliveryVendorPop.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }

  const [content, setContent] = useState('');
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    (async () => {
      const {data: {code, result, message}} = await APIHelper.getForm(`flow/vendor/choice/view`, {
        projectId: props.deliveryVendorPop.content?.projectId,
        projectProductId: props.deliveryVendorPop.content?.projectProductId,
        projectMakerRequestId: props.deliveryVendorPop.content?.projectMakerRequestId,
      });
      setContent(result?.ref_ctnt_1);
    })();
  }, [props.deliveryVendorPop.content?.renew]);

  const submit = async () => {
    if (content.trim() === '') {
      await CommonLib.jalert("물류사 정보를 입력해 주세요.");
      return;
    }

    await CommonLib.dispatchAction(props.deliveryVendorPop.content?.id);
    const {data: {code, result, message}} = await APIHelper.postForm(`flow/vendor/choice/submit`, {
      projectId: props.deliveryVendorPop.content?.projectId,
      projectProductId: props.deliveryVendorPop.content?.projectProductId,
      projectMakerRequestId: props.deliveryVendorPop.content?.projectMakerRequestId,
      refCtnt1: content
    });
    closeModal();
    await CommonLib.jalert(message);
  };

  return (
    <div style={{display: modalInlineStyle}}>
      <div
        className="pop_box02"
        style={{
          width: "750px",
        }}
      >
        <div className="POP_title pb-3">
          <h4>
            <strong>
              바이어 물류사 정보</strong>
          </h4>
          <p className="closed">
            <button type="button" className="btn" onClick={closeModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </button>
          </p>
        </div>

        <div className="POP_cnt_box_lg" style={{marginBottom: "95px"}}>
          <table className="table light_gray w-100">
            <tr style={{borderStyle: "none", minHeight: "100px"}}>
              <td className="px-0">
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    placeholder={'[입력란]\n물류사명, 휴대폰 번호'}
                    onChange={async (e) => await setContent(e.target.value)}
                    value={content}
                  />
              </td>
            </tr>
          </table>
        </div>
        <div className="POP_foot">
          <button className="btn btn_puple" type="button" onClick={() => submit()}>
            확인
          </button>
          <button className="btn btn_puplelight" type="button" onClick={closeModal} style={{marginLeft: "7px"}}>
            닫기
          </button>
        </div>
      </div>
      <div className="pop_bak" onClick={closeModal}></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    deliveryVendorPop: state.deliveryVendorPop,
  };
}

function mapDispatchToProps(dispatcher) {
  return bindActionCreators(
    {
      openDeliveryVendor: openDeliveryVendor,
    },
    dispatcher
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryVendorModal);
