import icon_catogory_011 from "../image/category/icon_catogory_011.svg";
import icon_catogory_091 from "../image/category/icon_catogory_091.svg";
import icon_catogory_071 from "../image/category/icon_catogory_071.svg";
import icon_catogory_081 from "../image/category/icon_catogory_081.svg";
const DUMMY_DATA = [
  {
    id: 1,
    type: ["스킨케어", "로션"],
    category: { 내용물: 1, 용기: 1, 단상자: 1, 디자인: 1 },
    license: 111111,
    country: "대한민국",
    skill: "피부재생",
    unitPrice: 5000,
    img: icon_catogory_011,
    number: "ABC-2020-10",
    date: "2020.10.10",
    state: "진행중",
  },
  {
    id: 2,
    type: ["핸드케어", "핸드크림"],
    category: { 내용물: 1, 디자인: 1 },
    license: 222222,
    country: "일본",
    skill: "보습강화",
    unitPrice: 2000,
    img: icon_catogory_091,
    number: "CBA-2021-11",
    date: "2021.11.20",
    state: "진행중",
  },
  {
    id: 3,
    type: ["바디케어", "바디워시"],
    category: { 내용물: 1, 단상자: 1, 디자인: 1 },
    license: 333333,
    country: "미국",
    skill: "민감성피부",
    unitPrice: 5500,
    img: icon_catogory_081,
    number: "ABC-2019-07",
    date: "2019.07.15",
    state: "진행중",
  },
  {
    id: 4,
    type: ["립 메이크업", "립스틱"],
    category: { 단상자: 1, 디자인: 1 },
    license: 444444,
    country: "중국",
    skill: "화장품",
    unitPrice: 7000,
    img: icon_catogory_071,
    number: "CBA-2022-01",
    date: "2022.01.10",
    state: "진행중",
  },
];

const requestReducer = (state = DUMMY_DATA, action) => {
  if (action.type === "REQUEST_ADD") {
    const newState = [...state, action.payload];
    return newState;
  }
  return state;
};

export default requestReducer;
