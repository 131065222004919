import React from "react";
import {useState} from "react";
import "../../sass/main.scss";
import Notice from "./Notice";
import Faq from "./Faq";
import NoticeDetail from "./NoticeDetail";
import {Link, useHistory} from "react-router-dom"
import CommonLib from "../../libraries/CommonLib";

const CsCenterCard = (props) => {
  const path = window.location.pathname;
  const history = useHistory();

  const inquiry = async () => {
    if (!localStorage.getItem("user.session.email")) {
      await CommonLib.jalert('로그인이 필요합니다.');
      return;
    }
    history.push({
      pathname: '/cscenter/inquiry'
    })
  }

  return (
    <div className="BODY MAX my-4">
      <div
        className="mt-3 d-flex justify-content-between w-100"
        style={{height: "100%"}}
      >
        <div className="box_round min_h left_menu">
          <div className="left_menu_title">고객센터</div>
          <ul className="left_menu_list p-0">
            <li className={`${path.includes("notice") ? "on" : ""}`}>
              <Link to="/cscenter/notice">
                공지사항
              </Link>
            </li>
            <li className={`${path.includes("faq/buyer") ? "on" : ""}`}>
              <Link
                onClick={() => {
                  localStorage.setItem("user.faq.user_type_cd", 'BUYER');
                  history.push({
                    pathname: "/cscenter/faq/buyer"
                  });
                }}>
                FAQ - 바이어
              </Link>
            </li>
            <li className={`${path.includes("faq/maker") ? "on" : ""}`}>
              <Link
                onClick={() => {
                  localStorage.setItem("user.faq.user_type_cd", 'MAKER');
                  history.push({
                    pathname: "/cscenter/faq/maker"
                  });
                }}>
                FAQ - 제조사
              </Link>
            </li>
            <li className={`${path.includes("inquiry") ? "on" : ""}`} onClick={() => inquiry()}>
              <Link onClick={() => inquiry()}>
                1:1 문의
              </Link>
            </li>
          </ul>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default CsCenterCard;
