import React, {useEffect, useState} from "react";
import icon_prod_011 from "../../../image/category/icon_prod_011.svg";
import icon_prod_021 from "../../../image/category/icon_prod_021.svg";
import icon_prod_031 from "../../../image/category/icon_prod_031.svg";
import icon_prod_041 from "../../../image/category/icon_prod_041.svg";
import icon_plus_g from "../../../image/icon_plus_g.svg";
import icon_minus_g from "../../../image/icon_minus_g.svg";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import ProductsComponent from "./ProductsComponent";
import APIHelper from "../../../helpers/APIHelper";
import RequestBottleComponent from "./RequestBottleComponent";
import RequestFormulationComponent from "./RequestFormulationComponent";
import RequestDesignComponent from "./RequestDesignComponent";
import RequestBoxComponent from "./RequestBoxComponent";

const AdminProjectRequestForm = (props) => {
  const projectId = props?.match?.params?.id;
  const productId = props?.match?.params?.productId || 0;
  const [project, setProject] = useState({});
  const [product, setProduct] = useState({});
  const [totalMF, setTotalMF] = useState(0);
  const [totalBT, setTotalBT] = useState(0);
  const [totalBX, setTotalBX] = useState(0);
  const [totalDS, setTotalDS] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      await getProject(projectId);
      //console.log(product.items);
      //console.log(project?.products);
    }
    fetch();
  }, []);

  const getProject = (id) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("project/get", {id, productId});
      if (code === 200) {
        setProject(result);
        let _totalMF = 0;
        let _totalBT = 0;
        let _totalBX = 0;
        let _totalDS = 0;
        result?.products.map((product, k) => {
          _totalMF += product.items.filter(i => i.item_cd === 'MF').length;
          _totalBT += product.items.filter(i => i.item_cd === 'BT').length;
          _totalBX += product.items.filter(i => i.item_cd === 'BX').length;
          _totalDS += product.items.filter(i => i.item_cd === 'DS').length;
        });
        setTotalMF(_totalMF);
        setTotalBT(_totalBT);
        setTotalBX(_totalBX);
        setTotalDS(_totalDS);
      }
      return resolve(true);
    });
  }

  const clickProductHandler = (item) => {
    setProduct(item);
  }

  const [rfProjectId, setRfProjectId] = useState();
  const [rfProductId, setRfProductId] = useState();
  const [rfRequestID, setRfRequestID] = useState();
  const [rfRequestType, setRfRequestType] = useState();

  const showRequestForm = (projectId, productId, requestID, requestType) => {
    setRfProjectId(projectId);
    setRfProductId(productId);
    setRfRequestID(requestID);
    setRfRequestType(requestType);
    setRequestShow(true);
  }

  // 제작 의뢰서 보기
  const [requestShow, setRequestShow] = useState(false);
  // 제품 종류 골라서 보기
  const [tabState, setTabState] = useState({
    all: false,
    mf: true,
    bt: false,
    bx: false,
    ds: false,
  });

  const tabClick = (e) => {
    const tab = {...tabState};
    const target = e.currentTarget.id;
    for (let key in tab) {
      target === key ? (tab[key] = true) : (tab[key] = false);
    }
    setTabState(tab);
  };


  return (
      <AdminCard>
        <div
            className="row row-cols-3 gx-3 mt-4"
            style={{height: "calc(100% - 30px)"}}
        >
          <section className="col" style={{height: "98%"}}>
            {productId === 0 &&
                <div
                    className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
                    style={{height: "370px"}}
                >
                  <p className="h5 bold p-2 border-bottom">바이어 제작 문의</p>

                  <table className="w-100 text-center">
                    <tr>
                      <td id="all" onClick={tabClick}>
                        <div
                            className={`bg-${
                                tabState.all ? "purple" : "lpurple"
                            } border p-2 px-1 text-center`}
                        >
                          제품 전체
                          <br/>
                          <img
                              src={require(`../../../image/zoom_in${
                                  tabState.all ? "_w" : ""
                              }.svg`)}
                              style={{width: "25px"}}
                          />
                        </div>
                      </td>
                      <td id="mf" onClick={tabClick}>
                        <div
                            className={`bg-${
                                tabState.mf ? "purple" : "lpurple"
                            } border p-2 px-1 text-center`}
                        >
                          내용물
                          <br/>
                          <img
                              src={require(`../../../image/zoom_in${
                                  tabState.mf ? "_w" : ""
                              }.svg`)}
                              style={{width: "25px"}}
                          />
                        </div>
                      </td>
                      <td id="bt" onClick={tabClick}>
                        <div
                            className={`bg-${
                                tabState.bt ? "purple" : "lpurple"
                            } border p-2 px-1 text-center`}
                        >
                          용기
                          <br/>
                          <img
                              src={require(`../../../image/zoom_in${
                                  tabState.bt ? "_w" : ""
                              }.svg`)}
                              style={{width: "25px"}}
                          />
                        </div>
                      </td>
                      <td id="bx" onClick={tabClick}>
                        <div
                            className={`bg-${
                                tabState.bx ? "purple" : "lpurple"
                            } border p-2 px-1 text-center`}
                        >
                          단상자
                          <br/>
                          <img
                              src={require(`../../../image/zoom_in${
                                  tabState.bx ? "_w" : ""
                              }.svg`)}
                              style={{width: "25px"}}
                          />
                        </div>
                      </td>
                      <td id="ds" onClick={tabClick}>
                        <div
                            className={`bg-${
                                tabState.ds ? "purple" : "lpurple"
                            } border p-2 px-1 text-center`}
                        >
                          디자인
                          <br/>
                          <img
                              src={require(`../../../image/zoom_in${
                                  tabState.ds ? "_w" : ""
                              }.svg`)}
                              style={{width: "25px"}}
                          />
                        </div>
                      </td>
                    </tr>
                  </table>

                  <div
                      className="mt-1 wid_scroll scroll_y custom_scroll p_rative"
                      style={{height: "193px"}}
                  >
                    <table
                        className="text-center basic_list"
                        style={{width: "calc(100% - 4px)"}}
                    >
                      <tr>
                        <th className="py-1 bg-lgray0 border">제품</th>
                        <th className="py-1 bg-lgray0 border">
                          <p className="py-1">
                            <img src={icon_prod_011} style={{width: "25px"}}/>
                          </p>
                        </th>
                        <th className="py-1 bg-lgray0 border">
                          <p className="py-1">
                            <img src={icon_prod_021} style={{width: "30px"}}/>
                          </p>
                        </th>
                        <th className="py-1 bg-lgray0 border">
                          <p className="py-1">
                            <img src={icon_prod_031} style={{width: "30px"}}/>
                          </p>
                        </th>
                        <th className="py-1 bg-lgray0 border">
                          <p className="py-1">
                            <img src={icon_prod_041} style={{width: "30px"}}/>
                          </p>
                        </th>
                      </tr>
                      {project?.products?.length > 0 && project?.products.map((product, k) => {
                        return (
                            <tr key={k}>
                              <td>{product.product.name_kr}</td>
                              <td>{product.items.filter(i => i.item_cd === 'MF').length > 0 ? '내용물' : ''}</td>
                              <td>{product.items.filter(i => i.item_cd === 'BT').length > 0 ? '용기' : ''}</td>
                              <td>{product.items.filter(i => i.item_cd === 'BX').length > 0 ? '단상자' : ''}</td>
                              <td>{product.items.filter(i => i.item_cd === 'DS').length > 0 ? '디자인' : ''}</td>
                            </tr>
                        );
                      })}
                    </table>
                  </div>

                  <table className="w-100 text-center box_round_4_paddingNo bg-purple txt-sm mt-1">
                    <tr>
                      <td
                          className="txt-sm py-1"
                          style={{borderRight: "1px solid #A294CF"}}
                      >
                        {project?.products?.length}건
                      </td>
                      <td
                          className="txt-sm py-1"
                          style={{borderRight: "1px solid #A294CF"}}
                      >
                        {totalMF}건
                      </td>
                      <td
                          className="txt-sm py-1"
                          style={{borderRight: "1px solid #A294CF"}}
                      >
                        {totalBT}건
                      </td>
                      <td
                          className="txt-sm py-1"
                          style={{borderRight: "1px solid #A294CF"}}
                      >
                        {totalBX}건
                      </td>
                      <td className="txt-sm py-1">
                        {totalDS}건
                      </td>
                    </tr>
                  </table>
                </div>
            }
            <div
                className="bg-lpurple shadow2 my-3 box_round_4_paddingNo p-3"
                style={{height: productId === 0 ? "calc(98% - 385px)" : "98%"}}
            >
              <p className="h5 bold p-2 border-bottom">
                <span className="h5 bold">제품 리스트</span>
              </p>
              <div
                  className="wid_scroll  scroll_y custom_scroll_p"
                  style={{height: "calc(100% - 50px)"}}
              >
                <ProductsComponent projectId={projectId} productId={productId} click={clickProductHandler}/>
              </div>
            </div>
          </section>
          <section className="col" style={{height: "98%"}}>
            <div
                className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
                style={{height: "98%"}}
            >
              <p className="h5 bold p-2 border-bottom">제작 의뢰서</p>

              <div className="row row-cols-4 gx-2">
                <div className="col">
                  <div className="bg-lgray border p-2 px-3 d-flex justify-content-around flex-wrap">
                    <p className="py-1">
                      <img src={icon_prod_011} style={{width: "25px"}}/>
                    </p>
                    <p className="text-end">
                      <span>내용물</span>
                      <br/>
                      <span className="h4">{totalMF}건</span>
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="bg-lgray border p-2 px-3 d-flex justify-content-around flex-wrap">
                    <p className="py-1">
                      <img src={icon_prod_021} style={{width: "30px"}}/>
                    </p>
                    <p className="text-end">
                      <span>용기</span>
                      <br/>
                      <span className="h4">{totalBT}건</span>
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="bg-lgray border p-2 px-3 d-flex justify-content-around flex-wrap">
                    <p className="py-1">
                      <img src={icon_prod_031} style={{width: "30px"}}/>
                    </p>
                    <p className="text-end">
                      <span>단상자</span>
                      <br/>
                      <span className="h4">{totalBX}건</span>
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="bg-lgray border p-2 px-3 d-flex justify-content-around flex-wrap">
                    <p className="py-1">
                      <img src={icon_prod_041} style={{width: "30px"}}/>
                    </p>
                    <p className="text-end">
                      <span>디자인</span>
                      <br/>
                      <span className="h4">{totalDS}건</span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                  className="mt-4 wid_scroll scroll_y custom_scroll"
                  style={{height: "calc(100% - 175px)"}}
              >
                <article style={{marginRight: "10px"}}>
                  {project?.products?.length > 0 && project?.products.map((product, k) => {
                    return (
                        <>
                          <p style={{clear: "both"}} className="h6 mt-3 mb-2 idx_tit">{product?.category?.name_kr} | {product?.product?.name_kr}</p>
                          {product.items.filter(i => i.item_cd === 'MF').map((item, key) => {
                            if (item?.request_forms?.length) {
                              return (
                                  item?.request_forms?.map(f => {
                                        return (
                                            <>
                                              <div style={f.request_parent_id ? {width: '85%', float: 'right', clear: 'right'} : {clear: 'both'}} className='border box_round_5 d-flex justify-content-between my-2 p-2 px-3'>
                                                <p className='py-1 w-20 text-start' style={{width: '50%'}}>내용물 {f.request_name}</p>
                                                <p>
                                                  {!f.request_parent_id &&
                                                      <button
                                                          type='button'
                                                          className='btn btn-sm'
                                                          onClick={async () => {
                                                            try {
                                                              const {data: {code: retCode, result, message}} = await APIHelper.postForm('project/request/copy', {id: f.id});
                                                              await getProject(projectId);
                                                            } catch (err) {
                                                              console.log(err);
                                                            }
                                                          }}
                                                      >
                                                        <img src={icon_plus_g} style={{width: '20px'}}/>
                                                      </button>
                                                  }

                                                  {f.request_parent_id &&
                                                      <button
                                                          type='button'
                                                          className='btn btn-sm'
                                                          onClick={async () => {
                                                            try {
                                                              const {data: {code: retCode, result, message}} = await APIHelper.postForm('project/request/delete', {id: f.id});
                                                              await getProject(projectId);
                                                            } catch (err) {
                                                              console.log(err);
                                                            }
                                                          }}
                                                      >
                                                        <img src={icon_minus_g} style={{width: '20px'}}/>
                                                      </button>
                                                  }
                                                </p>
                                                <p>
                                                  <button
                                                      type='button'
                                                      className='btn btn-sm btn-blue'
                                                      style={{width: '88px'}}
                                                      onClick={() => showRequestForm(product.project_id, f.project_product_id, f.id, 'MF')}
                                                  >
                                                    제작의뢰서
                                                  </button>
                                                </p>
                                              </div>
                                            </>
                                        )
                                      }
                                  )
                              ); // return (
                            } else {
                              return (
                                  <div className='border box_round_5 d-flex justify-content-between my-2 p-2 px-3' style={{clear: 'both'}}>
                                    <p className='py-1 w-20 text-start' style={{width: '50%'}}>내용물</p>
                                    <p>
                                      <button
                                          type='button'
                                          className='btn btn-sm'
                                          onClick={() => alert('제작의뢰서를 먼저 생성해 주세요.')}
                                      >
                                        <img src={icon_plus_g} style={{width: '20px'}}/>
                                      </button>
                                    </p>
                                    <p>
                                      <button
                                          type='button'
                                          className='btn btn-sm btn-blue'
                                          style={{width: '88px'}}
                                          onClick={() => showRequestForm(product.project_id, product.id, 0, 'MF')}
                                      >
                                        제작의뢰서
                                      </button>
                                    </p>
                                  </div>
                              );
                            }
                          })}

                          {product.items.filter(i => i.item_cd === 'BT').map((item, key) => {
                            if (item?.request_forms?.length) {
                              return (
                                  item?.request_forms?.map(f => {
                                        return (
                                            <>
                                              <div style={f.request_parent_id ? {width: '85%', float: 'right', clear: 'right'} : {clear: 'both'}} className='border box_round_5 d-flex justify-content-between my-2 p-2 px-3'>
                                                <p className='py-1 w-20 text-start' style={{width: '50%'}}>용기 {f.request_name}</p>
                                                <p>
                                                  {!f.request_parent_id &&
                                                      <button
                                                          type='button'
                                                          className='btn btn-sm'
                                                          onClick={async () => {
                                                            try {
                                                              const {data: {code: retCode, result, message}} = await APIHelper.postForm('project/request/copy', {id: f.id});
                                                              await getProject(projectId);
                                                            } catch (err) {
                                                              console.log(err);
                                                            }
                                                          }}
                                                      >
                                                        <img src={icon_plus_g} style={{width: '20px'}}/>
                                                      </button>
                                                  }

                                                  {f.request_parent_id &&
                                                      <button
                                                          type='button'
                                                          className='btn btn-sm'
                                                          onClick={async () => {
                                                            try {
                                                              const {data: {code: retCode, result, message}} = await APIHelper.postForm('project/request/delete', {id: f.id});
                                                              await getProject(projectId);
                                                            } catch (err) {
                                                              console.log(err);
                                                            }
                                                          }}
                                                      >
                                                        <img src={icon_minus_g} style={{width: '20px'}}/>
                                                      </button>
                                                  }
                                                </p>
                                                <p>
                                                  <button
                                                      type='button'
                                                      className='btn btn-sm btn-blue'
                                                      style={{width: '88px'}}
                                                      onClick={() => showRequestForm(product.project_id, f.project_product_id, f.id, 'BT')}
                                                  >
                                                    제작의뢰서
                                                  </button>
                                                </p>
                                              </div>
                                            </>
                                        )
                                      }
                                  )
                              ); // return (
                            } else {
                              return (
                                  <div className='border box_round_5 d-flex justify-content-between my-2 p-2 px-3' style={{clear: 'both'}}>
                                    <p className='py-1 w-20 text-start' style={{width: '50%'}}>용기</p>
                                    <p>
                                      <button
                                          type='button'
                                          className='btn btn-sm'
                                          onClick={() => alert('제작의뢰서를 먼저 생성해 주세요.')}
                                      >
                                        <img src={icon_plus_g} style={{width: '20px'}}/>
                                      </button>
                                    </p>
                                    <p>
                                      <button
                                          type='button'
                                          className='btn btn-sm btn-blue'
                                          style={{width: '88px'}}
                                          onClick={() => showRequestForm(product.project_id, product.id, 0, 'BT')}
                                      >
                                        제작의뢰서
                                      </button>
                                    </p>
                                  </div>
                              );
                            }
                          })}

                          {product.items.filter(i => i.item_cd === 'BX').map((item, key) => {
                            if (item?.request_forms?.length) {
                              return (
                                  item?.request_forms?.map(f => {
                                        return (
                                            <>
                                              <div style={f.request_parent_id ? {width: '85%', float: 'right', clear: 'right'} : {clear: 'both'}} className='border box_round_5 d-flex justify-content-between my-2 p-2 px-3'>
                                                <p className='py-1 w-20 text-start' style={{width: '50%'}}>단상자 {f.request_name}</p>
                                                <p>
                                                  {!f.request_parent_id &&
                                                      <button
                                                          type='button'
                                                          className='btn btn-sm'
                                                          onClick={async () => {
                                                            try {
                                                              const {data: {code: retCode, result, message}} = await APIHelper.postForm('project/request/copy', {id: f.id});
                                                              await getProject(projectId);
                                                            } catch (err) {
                                                              console.log(err);
                                                            }
                                                          }}
                                                      >
                                                        <img src={icon_plus_g} style={{width: '20px'}}/>
                                                      </button>
                                                  }

                                                  {f.request_parent_id &&
                                                      <button
                                                          type='button'
                                                          className='btn btn-sm'
                                                          onClick={async () => {
                                                            try {
                                                              const {data: {code: retCode, result, message}} = await APIHelper.postForm('project/request/delete', {id: f.id});
                                                              await getProject(projectId);
                                                            } catch (err) {
                                                              console.log(err);
                                                            }
                                                          }}
                                                      >
                                                        <img src={icon_minus_g} style={{width: '20px'}}/>
                                                      </button>
                                                  }
                                                </p>
                                                <p>
                                                  <button
                                                      type='button'
                                                      className='btn btn-sm btn-blue'
                                                      style={{width: '88px'}}
                                                      onClick={() => showRequestForm(product.project_id, f.project_product_id, f.id, 'BX')}
                                                  >
                                                    제작의뢰서
                                                  </button>
                                                </p>
                                              </div>
                                            </>
                                        )
                                      }
                                  )
                              ); // return (
                            } else {
                              return (
                                  <div className='border box_round_5 d-flex justify-content-between my-2 p-2 px-3' style={{clear: 'both'}}>
                                    <p className='py-1 w-20 text-start' style={{width: '50%'}}>단상자</p>
                                    <p>
                                      <button
                                          type='button'
                                          className='btn btn-sm'
                                          onClick={() => alert('제작의뢰서를 먼저 생성해 주세요.')}
                                      >
                                        <img src={icon_plus_g} style={{width: '20px'}}/>
                                      </button>
                                    </p>
                                    <p>
                                      <button
                                          type='button'
                                          className='btn btn-sm btn-blue'
                                          style={{width: '88px'}}
                                          onClick={() => showRequestForm(product.project_id, product.id, 0, 'BX')}
                                      >
                                        제작의뢰서
                                      </button>
                                    </p>
                                  </div>
                              );
                            }
                          })}

                          {product.items.filter(i => i.item_cd === 'DS').map((item, key) => {
                            if (item?.request_forms?.length) {
                              return (
                                  item?.request_forms?.map(f => {
                                        return (
                                            <>
                                              <div style={f.request_parent_id ? {width: '85%', float: 'right', clear: 'right'} : {clear: 'both'}} className='border box_round_5 d-flex justify-content-between my-2 p-2 px-3'>
                                                <p className='py-1 w-20 text-start' style={{width: '50%'}}>디자인 {f.request_name}</p>
                                                <p>
                                                  {!f.request_parent_id &&
                                                      <button
                                                          type='button'
                                                          className='btn btn-sm'
                                                          onClick={async () => {
                                                            try {
                                                              const {data: {code: retCode, result, message}} = await APIHelper.postForm('project/request/copy', {id: f.id});
                                                              await getProject(projectId);
                                                            } catch (err) {
                                                              console.log(err);
                                                            }
                                                          }}
                                                      >
                                                        <img src={icon_plus_g} style={{width: '20px'}}/>
                                                      </button>
                                                  }

                                                  {f.request_parent_id &&
                                                      <button
                                                          type='button'
                                                          className='btn btn-sm'
                                                          onClick={async () => {
                                                            try {
                                                              const {data: {code: retCode, result, message}} = await APIHelper.postForm('project/request/delete', {id: f.id});
                                                              await getProject(projectId);
                                                            } catch (err) {
                                                              console.log(err);
                                                            }
                                                          }}
                                                      >
                                                        <img src={icon_minus_g} style={{width: '20px'}}/>
                                                      </button>
                                                  }

                                                </p>
                                                <p>
                                                  <button
                                                      type='button'
                                                      className='btn btn-sm btn-blue'
                                                      style={{width: '88px'}}
                                                      onClick={() => showRequestForm(product.project_id, f.project_product_id, f.id, 'DS')}
                                                  >
                                                    제작의뢰서
                                                  </button>
                                                </p>
                                              </div>
                                            </>
                                        )
                                      }
                                  )
                              ); // return (
                            } else {
                              return (
                                  <div className='border box_round_5 d-flex justify-content-between my-2 p-2 px-3' style={{clear: 'both'}}>
                                    <p className='py-1 w-20 text-start' style={{width: '50%'}}>디자인</p>
                                    <p>
                                      <button
                                          type='button'
                                          className='btn btn-sm'
                                          onClick={() => alert('제작의뢰서를 먼저 생성해 주세요.')}
                                      >
                                        <img src={icon_plus_g} style={{width: '20px'}}/>
                                      </button>
                                    </p>
                                    <p>
                                      <button
                                          type='button'
                                          className='btn btn-sm btn-blue'
                                          style={{width: '88px'}}
                                          onClick={() => showRequestForm(product.project_id, product.id, 0, 'DS')}
                                      >
                                        제작의뢰서
                                      </button>
                                    </p>
                                  </div>
                              );
                            }
                          })}

                        </>
                    );
                  })}
                </article>
              </div>
            </div>
          </section>
          <section className="col" style={{height: "98%"}}>
            {requestShow ? (
                <div
                    className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
                    style={{height: "98%"}}
                >
                  <div className="d-flex justify-content-between border-bottom">
                    <p className="h5 bold p-2">상세</p>
                  </div>
                  {rfRequestType === 'MF' && <RequestFormulationComponent projectId={rfProjectId} projectProductId={rfProductId} requestId={rfRequestID} renew={new Date().getTime()} key={new Date().getTime()} getProject={getProject}/>}
                  {rfRequestType === 'BT' && <RequestBottleComponent projectId={rfProjectId} projectProductId={rfProductId} requestId={rfRequestID} renew={new Date().getTime()} key={new Date().getTime()} getProject={getProject}/>}
                  {rfRequestType === 'BX' && <RequestBoxComponent projectId={rfProjectId} projectProductId={rfProductId} requestId={rfRequestID} renew={new Date().getTime()} key={new Date().getTime()} getProject={getProject}/>}
                  {rfRequestType === 'DS' && <RequestDesignComponent projectId={rfProjectId} projectProductId={rfProductId} requestId={rfRequestID} renew={new Date().getTime()} key={new Date().getTime()} getProject={getProject}/>}
                </div>
            ) : (
                <div
                    className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
                    style={{height: "98%"}}
                >
                  <div className="d-flex justify-content-between border-bottom">
                    <p className="h5 bold p-2">상세</p>
                  </div>
                </div>
            )}
          </section>
        </div>
      </AdminCard>
  );
};

export default AdminProjectRequestForm;
