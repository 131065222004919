import React, {useContext, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../sass/main.scss";
import {useDispatch, useSelector} from "react-redux";
import AuthContext from "../auth-context";
import {faCaretRight, faCaretLeft} from "@fortawesome/free-solid-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";
import APIHelper from "../../helpers/APIHelper";
import CommonLib from "../../libraries/CommonLib";

library.add(faCaretRight);
library.add(faCaretLeft);

const AdminHomeRedirect = (props) => {
  const ctx = useContext(AuthContext);
  const history = useHistory();
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.modalStyle);
  const requestModal = useSelector((state) => state.requestForm);
  const [containerHeight, setContainerHeight] = useState(window.innerHeight - 88);
  const [sidebarHidden, setSidebarHidden] = useState((localStorage.getItem("admin.sidebar.hidden") || "false") === "true");

  const [admin, setAdmin] = useState();

  const [auth1, setAuth1] = useState('N');
  const [auth2, setAuth2] = useState('N');
  const [auth3, setAuth3] = useState('N');
  const [auth4, setAuth4] = useState('N');
  const [auth5, setAuth5] = useState('N');
  const [auth6, setAuth6] = useState('N');
  const [auth7, setAuth7] = useState('N');
  const [auth8, setAuth8] = useState('N');

  React.useEffect(() => {
    (async () => {
      let authCnt = 0;

      const {data: {code, result, message}} = await APIHelper.getForm("admin/me");
      setAdmin(result);

      if (result?.auth1 === 'Y') {
        setAuth1("Y");
        authCnt++;
      } else
        setAuth1("N");

      if (result?.auth2 === 'Y') {
        setAuth2("Y");
        authCnt++;
      } else
        setAuth2("N");

      if (result?.auth3 === 'Y') {
        setAuth3("Y");
        authCnt++;
      } else
        setAuth3("N");

      if (result?.auth4 === 'Y') {
        setAuth4("Y");
        authCnt++;
      } else
        setAuth4("N");

      if (result?.auth5 === 'Y') {
        setAuth5("Y");
        authCnt++;
      } else
        setAuth5("N");

      if (result?.auth6 === 'Y') {
        setAuth6("Y");
        authCnt++;
      } else
        setAuth6("N");

      if (result?.auth7 === 'Y') {
        setAuth7("Y");
        authCnt++;
      } else
        setAuth7("N");

      if (result?.auth8 === 'Y') {
        setAuth8("Y");
        authCnt++;
      } else
        setAuth8("N");

      if (authCnt === 0) {
        await CommonLib.jalert('해당 계정은 관리자 모드 접근 권한이 없습니다.');
        ctx.onAdminLogout();
      } else if (result?.auth5 === 'Y') {
        history.push('/admin/project/status/all');
      } else {
        if (result?.auth1 === 'Y') history.push('/admin/ops/home');
        else if (result?.auth2 === 'Y') history.push('/admin/data/oem/story');
        else if (result?.auth3 === 'Y') history.push('/admin/operate/oem');
        else if (result?.auth4 === 'Y') history.push('/admin/buyer');
        else if (result?.auth5 === 'Y') history.push('/admin/maker');
        else if (result?.auth6 === 'Y') history.push('/admin/project/status/all');
        else if (result?.auth7 === 'Y') history.push('/admin/cs/qna');
        else if (result?.auth8 === 'Y') history.push('/admin/mypage/myinfo');
      }
    })();
  }, []);

  return (
    <>
    </>
  );
};

export default AdminHomeRedirect;
