import {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import $ from 'jquery';
import APIHelper from "../helpers/APIHelper";
import CommonLib from "../libraries/CommonLib";
import {confirmAlert} from "react-confirm-alert";

function TokenRegister() {
  useEffect(() => {
    (async () => {
      const userEmail = localStorage.getItem("user.session.email") || '';
      const adminEmail = localStorage.getItem("admin.session.email") || '';

      if (userEmail !== '' || adminEmail !== '') {
        if (Notification.permission === 'granted') {
          const {data: {code, result, message}} = await APIHelper.postForm("user/push/token", {token: localStorage.getItem("push_token"), userEmail, adminEmail});
        } else if (Notification.permission === 'denied') {
          // confirmAlert({
          //   customUI: ({onClose}) => {
          //     return (
          //       <div className="react-confirm-alert">
          //         <div className="react-confirm-alert-body">
          //           <h1>DARLIBABA</h1>
          //           <p>알림 기능이 차단되어 있습니다. 브라우저 설정에서 알림 기능을 활성화시켜 주세요.</p>
          //           <br/>
          //           (1) 브라우저 설정 버튼 클릭<br/>
          //           (2) 설정 검색창에서 알림 검색<br/>
          //           (3) 알림 관련 메뉴가 뜨면, https://www.darlibaba.com 이 차단된 사이트에 포함되어 있는지 확인<br/>
          //           (4) https://www.darlibaba.com 이 차단되어 있으면, 우측의 메뉴 버튼을 이용하여 차단을 해제하여 주세요.
          //           <div className="react-confirm-alert-button-group">
          //             <button onClick={onClose}>확인</button>
          //           </div>
          //         </div>
          //       </div>
          //     )
          //   }
          // });
        }
      }
    })();
  }, [window.location.pathname]);

  return null;
}

export default withRouter(TokenRegister);
