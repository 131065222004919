import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import openTimelineAttach from "../../actions/openTimelineAttach";
import {useDispatch} from "react-redux";
import openPrivacy from "../../actions/openPrivacy";
import openTerms from "../../actions/openTerms";
import CommonLib from "../../libraries/CommonLib";
import $ from "jquery";
import PasswordChecklist from "react-password-checklist";

const JoinBuyer = (props) => {
  const dispatch = useDispatch();

  // 최종 형식체크 state
  const [formIsValid, setFormIsValid] = useState(false);

  // Email state
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [email, setEmail] = useState("");
  // Email 중복체크
  const [emailCheck, setEmailCheck] = useState("");

  // Country state
  const [selectedCountry, setSelectedCountry] = useState('');

  // Language state
  const [selectedLang, setSelectedLang] = useState('');

  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);

  // Password state
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordRe, setPasswordRe] = useState("");
  const [passwordCheck, setPasswordCheck] = useState(false);
  const [validPassword, setValidPassword] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");

  // 동의 state
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      {
        const {
          data: {code, result, message},
        } = await APIHelper.getForm("code/gets", {parentCd: "106000"});
        if (code === 200) {
          setCountries(result);
          if (result && result.length > 0) setSelectedCountry(result[0].cd);
        }
      }
      {
        const {
          data: {code, result, message},
        } = await APIHelper.getForm("code/gets", {parentCd: "105000"});
        if (code === 200) {
          setLanguages(result);
          if (result && result.length > 0) setSelectedLang(result[0].cd);
        }
      }
    };
    fetch();
  }, []);

  // 약관동의 핸들러
  const allAgreeHandler = (checked) => {
    setIsAllChecked(!isAllChecked);

    if (checked) {
      setCheckedItems([
        ...checkedItems,
        "option1",
        "option2",
        "option3",
      ]);
    } else if (
      (!checked && checkedItems.includes("option1")) ||
      (!checked && checkedItems.includes("option2")) ||
      (!checked && checkedItems.includes("option3"))
    ) {
      setCheckedItems([]);
    }
  };

  const agreeHandler = (checked, value) => {
    if (checked) {
      setCheckedItems([...checkedItems, value]);
    } else if (!checked && checkedItems.includes(value)) {
      setCheckedItems(checkedItems.filter((el) => el !== value));
    }
  };

  useEffect(() => {
    if (checkedItems.length >= 3) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [checkedItems]);

  // 비밀번호 핸들러
  // 비밀번호는 7자 이상으로 가정
  const passwordHandler = (e) => {
    setPasswordIsValid(false);
    if (e.target.value.trim().length > 6) {
      setPasswordIsValid(true);
      setPassword(e.target.value);
    }
  };

  // 비밀번호 확인 핸들러
  const passwordCheckHandler = (e) => {
    setPasswordCheck(password === e.target.value);
  };

  // 6 Digits state : 인증번호
  const [code, setCode] = useState(Math.floor(100000 + Math.random() * 900000).toString());
  const [verificationCode, setVerificationCode] = useState('');
  const [codeIsValid, setCodeIsValid] = useState(false);

  const verifyEmail = async (e) => {
    if (!emailIsValid) {
      await CommonLib.jalert("올바른 이메일 형식이 아닙니다.");
      return;
    }

    const _code = Math.floor(100000 + Math.random() * 900000).toString();
    setCode(_code);
    setCodeIsValid(false);
    const {data: {code, result, message}} = await APIHelper.postForm("user/email/verification", {email, code: _code});
    if (code === 200)
      await CommonLib.jalert("인증번호가 이메일로 발송되었습니다.");
    else
      await CommonLib.jalert("인증번호 발송이 실패하였습니다.");
  }

  // 인증번호(6 Digits) 핸들러
  const codeClicked = async (e) => {
    if (code.toString() === verificationCode.toString()) {
      setCodeIsValid(true);
      await CommonLib.jalert("인증번호 확인되었습니다.");
    } else {
      setCodeIsValid(false);
      await CommonLib.jalert("인증번호가 틀렸습니다. 다시 시도해주세요.");
    }
  };

  const codeHandler = (e) => {
    setVerificationCode(e.target.value);
  };

  // 국가선택 핸들러
  const countryHandler = (e) => {
    setSelectedCountry(e.target.value);
  };

  // 언어선택 핸들러
  const languageHandler = (e) => {
    setSelectedLang(e.target.value);
  };

  // 최종 형식 체크 -> 버튼 활성화
  useEffect(() => {
    setFormIsValid(
      codeIsValid &&
      passwordCheck &&
      emailIsValid &&
      firstName &&
      lastName &&
      company &&
      checkedItems.includes("option1") &&
      checkedItems.includes("option2")
    );
  }, [
    codeIsValid,
    passwordCheck,
    emailIsValid,
    checkedItems,
    firstName,
    lastName,
    company
  ]);

  const history = useHistory();
  const btnHandler = async () => {
    try {
      const {
        data: {code, result, message},
      } = await APIHelper.postForm("user/join/buyer", {
        email,
        password,
        firstName,
        middleName,
        lastName,
        company,
        countryCd: selectedCountry,
        langCd: selectedLang,
      });
      if (code === 200) {
        await CommonLib.jalert(message);
        history.push("/login");
      } else {
        await CommonLib.jalert(message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [length, setLength] = useState("");
  // Email 중복되는지 실시간 체크
  useEffect(() => {
    (async () => {
      try {
        const response = await APIHelper.postForm("user/join/idcheck", {
          email,
        });
        setEmailCheck(response.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [email]);

  // 이메일 핸들러
  const emailChange = () => {
    const email = $('.cls-email').val();
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setEmailIsValid(reg.test(email));
    if (emailIsValid) {
      if (email.length > 8) {
        setLength(email.length);
      }
    }
    setCodeIsValid(false);
  };

  return (
    <div className="BODY MAX my-4 bg-white">
      <div className="p-5">
        <p className="h3 bold">회원 가입</p>
        <p>바이어님 아래 항목 입력하셔서 회원가입을 진행 하세요.</p>
        <table className="table basic my-5">
          <colgroup>
            <col width="20%"/>
            <col width="*"/>
          </colgroup>

          {/*2023.08.08*/}
          <tr>
            <th className="border-right">
              {/*이메일*/}
              {/*<br/>*/}
              {/*(e-mail)*/}
              E-Mail
            </th>
            <td>
              <div
                style={{padding: 0}}
                className={`input-group ${
                  emailIsValid === false || emailCheck === 1 ? "invalid" : ""
                }`}
              >
                <input
                  onChange={async (e) => {
                    await setEmail(e.target.value);
                    await emailChange();
                  }}
                  value={email}
                  type="text"
                  className="cls-email form-control"
                  placeholder="E-mail (ID)"
                />

                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id="button-addon2"
                  onClick={verifyEmail}
                >
                  이메일인증
                </button>
              </div>
              <p className={` ${length < 6 ? "displayNone" : ""}`}>{`${
                emailCheck === 1
                  ? "중복된 아이디입니다."
                  : "사용가능한 아이디입니다."
              }`}</p>
            </td>
          </tr>

          <tr>
            <th className="border-right">
              {/*인증번호*/}
              {/*<br/>*/}
              {/*(verification code)*/}
              Verification code
            </th>
            <td>
              <div className="input-group" style={{padding: 0}}>
                <input
                  onChange={codeHandler}
                  type="text"
                  className="form-control"
                  placeholder="verification code (6)"
                />
                <button
                  onClick={codeClicked}
                  className="btn btn-outline-secondary"
                  type="button"
                  id="button-addon2"
                >
                  인증번호 확인
                </button>
              </div>
            </td>
          </tr>

          <tr>
            <th className="border-right">
              {/*비밀번호*/}
              {/*<br/>*/}
              {/*(Password)*/}
              Password
            </th>
            <td className={`${passwordIsValid === false ? "invalid" : ""}`}>
              <input
                onChange={async (e) => {
                  await setPassword(e.target.value);
                  await passwordHandler(e);
                }}
                value={password}
                type="password"
                className="form-control"
                placeholder="Password"
                aria-describedby="button-addon2"
              />
              {password?.length > 0 &&
                <PasswordChecklist
                  rules={[
                    "minLength",
                    "specialChar",
                    "number",
                    "match"
                    // "capital",
                  ]}
                  minLength={7}
                  value={password}
                  valueAgain={passwordRe}
                  messages={{
                    minLength: "비밀번호는 최소 7자 이상이어야 합니다.",
                    specialChar: "비밀번호는 특수문자를 포함해야 합니다.",
                    number: "비밀번호는 숫자를 포함해야 합니다.",
                    capital: "비밀번호는 대문자를 포함해야 합니다.",
                    match: "비밀번호가 일치합니다.",
                  }}
                  onChange={(e) => setValidPassword(e)}
                />
              }
            </td>
          </tr>
          <tr>
            <th className="border-right">
              {/*비밀번호 확인*/}
              {/*<br/>*/}
              {/*(Password)*/}
              Confirm password
            </th>
            <td className={`${passwordCheck === false ? "invalid" : ""}`}>
              <input
                onChange={async (e) => {
                  await setPasswordRe(e.target.value);
                  await passwordCheckHandler(e);
                }}
                value={passwordRe}
                type="password"
                className="form-control"
                placeholder="Password Confirm"
                aria-describedby="button-addon2"
              />
            </td>
          </tr>

          {/*2023.08.08*/}
          <tr>
            <th className="border-right">
              {/*이름*/}
              {/*<br/>*/}
              {/*(Name)*/}
              Name
            </th>
            <td>
              <input
                type="text"
                className="form-control2"
                placeholder="First Name"
                aria-describedby="button-addon2"
                onChange={async (e) => {
                  await setFirstName(e.target.value);
                }}
                value={firstName}
              />
              <input
                type="text"
                className="form-control2"
                placeholder="Middle Name"
                aria-describedby="button-addon2"
                onChange={async (e) => await setMiddleName(e.target.value)}
                value={middleName}
              />
              <input
                type="text"
                className="form-control2"
                placeholder="Last Name"
                aria-describedby="button-addon2"
                onChange={async (e) => {
                  await setLastName(e.target.value);
                }}
                value={lastName}
              />
            </td>
          </tr>
          <tr>
            <th className="border-right">
              {/*회사명*/}
              {/*<br/>*/}
              {/*(Company Name)*/}
              Company Name
            </th>
            <td>
              <input
                type="text"
                className="form-control"
                placeholder="Company Name"
                aria-describedby="button-addon2"
                onChange={async (e) => {
                  await setCompany(e.target.value);
                }}
                value={company}
              />
            </td>
          </tr>
          <tr>
            <th className="border-right">
              {/*국가선택*/}
              {/*<br/>*/}
              {/*(Country)*/}
              Country
            </th>
            <td>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={countryHandler}
                value={selectedCountry?.name_en}
              >
                {countries &&
                  countries.map((item, i) => (
                    <option value={item.cd} key={i}>
                      {item.name_en}
                    </option>
                  ))}
              </select>
            </td>
          </tr>
          <tr>
            <th className="border-right">
              {/*언어선택*/}
              {/*<br/>*/}
              {/*(Preferred Language)*/}
              Preferred Language
            </th>
            <td>
              <select
                onChange={languageHandler}
                value={selectedLang?.name_en}
                className="form-select"
                aria-label="Default select example"
              >
                {languages &&
                  languages.map((item, i) => (
                    <option value={item.cd} key={i}>
                      {item.name_en}
                    </option>
                  ))}
              </select>
            </td>
          </tr>

          <tr>
            <th>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="allcheck"
                  onChange={(e) => allAgreeHandler(e.currentTarget.checked)}
                  checked={isAllChecked}
                />
                <label className="form-check-label" for="inlineCheckbox1">
                  전체약관
                </label>
              </div>
            </th>
            <td>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="option1"
                  onChange={(e) =>
                    agreeHandler(e.currentTarget.checked, e.target.value)
                  }
                  checked={checkedItems.includes("option1")}
                />
                <label className="form-check-label" for="inlineCheckbox1">
                  <div onClick={() => dispatch(openTerms("open", {}))} style={{cursor: "pointer"}}>(필수) 이용약관</div>
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  onChange={(e) =>
                    agreeHandler(e.currentTarget.checked, e.target.value)
                  }
                  checked={checkedItems.includes("option2")}
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="option2"
                />
                <label className="form-check-label" for="inlineCheckbox1" onClick={() => dispatch(openPrivacy("open", {}))}>
                  <div onClick={() => dispatch(openPrivacy("open", {}))} style={{cursor: "pointer"}}>(필수) 개인정보처리방침</div>
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  onChange={(e) =>
                    agreeHandler(e.currentTarget.checked, e.target.value)
                  }
                  checked={checkedItems.includes("option3")}
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="option5"
                />
                <label className="form-check-label" for="inlineCheckbox1">
                  (선택) 이벤트 및 프로모션 알림
                </label>
              </div>
            </td>
          </tr>
        </table>
        <p className="text-center">
          <button
            style={{width: "18%"}}
            // disabled={!formIsValid || !validPassword}
            onClick={btnHandler}
            type="button"
            className="btn btn-lg btn_puple"
          >
            회원가입
          </button>
          <button
            style={{marginLeft: 10, width: "18%"}}
            onClick={() => history.goBack()}
            type="button"
            className="btn btn-lg btn-outline-gray"
          >
            돌아가기
          </button>
        </p>
      </div>
    </div>
  );
};

export default JoinBuyer;
