import React, {useEffect, useState} from "react";
import icon_visibility from "../../image/icon_visibility.svg";
import star_r from "../../image/star_r.svg";
import star_g from "../../image/star_g.svg";
import chevron_left_black_24dp from "../../image/chevron_left_black_24dp.svg";
import first_page_black_48dp from "../../image/first_page_black_48dp.svg";
import navigate_next_black_48dp from "../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../image/last_page_black_48dp.svg";
import "../../sass/main.scss";
import {useHistory} from "react-router-dom";
import APIHelper from "../../helpers/APIHelper";
import moment from "moment/moment";
import CommonLib from "../../libraries/CommonLib";
import Paging from "../common/Paging";

const MakerReview = (props) => {
  const [reviews, setReviews] = useState(false);
  const history = useHistory();

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      await getMakers();
    }
    fetch();
  }, [currPage]);

  // 제조사 리스트 가져오기
  const getMakers = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("user/maker/reviews", {id: props?.maker?.user_id});
      if (pageInfo) setPageInfo(pageInfo);
      setReviews(result);
      window.scrollTo(0, 0);
      return resolve(true);
    });
  };

  return (
    <div className="box_round min_h cnt_area pb-0 w-100">
      <div className="mx-0 my-0 pb-0">
        <div className="box_border">
          {reviews && reviews.map((item, i) => (
            <div className="border-bottom d-flex" key={i}>
              <div className="w-70" style={{borderRight: "1px solid #ddd"}}>
                <ul className="m-3 border-bottom p-0 pb-2 px-2">
                  <li className="h5 bold">{item.project.name}</li>
                  <li className="txt-sm txt-gray">
                    프로젝트 기간 : {moment(item.project.created_at).format("YYYY.MM.DD")} ~ {!item.project?.finished_at ? "진행중" : moment(item.project.finished_at).format("YYYY.MM.DD")}
                  </li>
                </ul>
                <ul className="m-3 p-0 pb-2 px-2">
                  <li className="txt-gray pb-2 h6">{CommonLib.escapedNewLineToLineBreakTag(item.content)}</li>
                </ul>
              </div>
              <div className="w-30 p-3 px-4">
                <ul className="d-flex p-0" style={{marginTop: "12px"}}>
                  <li
                    className="w-40 px-2 h5"
                    style={{textAlign: "right", color: "#D56451", fontSize: "24px"}}
                  >
                    {item.review_score}
                  </li>
                  <li className="" style={{paddingTop: "2px"}}>
                    <img
                      src={`${
                        item.review_score >
                        0
                          ? star_r
                          : star_g
                      }`}
                      style={{width: "22px"}}
                    />
                    <img
                      src={`${
                        item.review_score >
                        1
                          ? star_r
                          : star_g
                      }`}
                      style={{width: "22px"}}
                    />
                    <img
                      src={`${
                        item.review_score >
                        2
                          ? star_r
                          : star_g
                      }`}
                      style={{width: "22px"}}
                    />
                    <img
                      src={`${
                        item.review_score >
                        3
                          ? star_r
                          : star_g
                      }`}
                      style={{width: "22px"}}
                    />
                    <img
                      src={`${
                        item.review_score >
                        4
                          ? star_r
                          : star_g
                      }`}
                      style={{width: "22px"}}
                    />
                  </li>
                </ul>
              </div>
            </div>
          ))}

          {reviews?.length === 0 &&
            <div className="col w-100">
              <div className="box_round_5 my-2 d-flex p-5 justify-content-center bg-white">
                등록된 후기가 없습니다.
              </div>
            </div>
          }

          <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        </div>
      </div>
    </div>
  );
};

export default MakerReview;
