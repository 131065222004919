import React, {useContext, useRef, useState} from "react";

import MypageCard from "./MypageCard";

import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import AuthContext from "../auth-context";
import {Link, useHistory} from "react-router-dom";
import CommonLib from "../../libraries/CommonLib";

const MypageWithdraw = (props) => {
  const [withdrawReason, setWithdrawReason] = useState('');
  const [withdrawReasonDtl, setWithdrawReasonDtl] = useState('');
  const ctx = useContext(AuthContext);
  const history = useHistory();
  const btnLogoutRef = useRef();

  const submit = async () => {
    const _withdrawReason = withdrawReason === '기타(직접 입력)' ? withdrawReasonDtl : withdrawReason
    if (_withdrawReason.length <= 0) {
      await CommonLib.jalert('탈퇴 사유를 선택/입력해 주세요.');
      return;
    }

    if (!await CommonLib.jconfirm('탈퇴하시겠습니까?')) return;

    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.putForm("user/withdraw", {withdrawReason: _withdrawReason});
      await CommonLib.jalert(message);
      if (code === 200) {
        btnLogoutRef?.current.click();
      }
      return resolve(true);
    });
  };

  return (
    <MypageCard>
      <div className="box_round cnt_area pb-5">
        <div className="mx-5 my-4">
          <p className="h3 bold border-bottom py-3">회원 탈퇴</p>
          <br/>

          <p className="py-3 txt-gray txt-sm">
            <span className="h5 bold">
              회원 탈퇴시 사용하던 이메일 주소는 재가입이 불가능 합니다.
            </span>{" "}
            <br/>
            <br/>
            ※ 이름, 이메일, 전화번호 등의 정보는 암호화 처리 되어 별도 보관 후
            개인 정보 정책에 표기된 기간이 지나면 폐기 됩니다.
            <br/>
            ※ 프로젝트(거래)에 발생한 견적서, 계약서 암호화 처리 되어 별도 보관
            후 이용약관에 표기된 기간이 지나면 폐기 됩니다.
            <br/>※ 탈퇴 처리후 복구는 불가능 합니다.
          </p>

          <div className="my-4">
            <table className="table basic my-3" style={{textAlign: "center"}}>
              <colgroup>
                <col width="20%"/>
                <col width="*"/>
              </colgroup>

              <tr>
                <th>탈퇴 사유 선택</th>
                <td className="left">
                  <div className="form-check py-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios1"
                      style={{marginTop: "2px"}}
                      onChange={async (e) => await setWithdrawReason(e.target.value)}
                      value={'사용 불편'}
                    />
                    <label className="form-check-label" for="exampleRadios1">
                      사용 불편
                    </label>
                  </div>
                  <div className="form-check py-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios2"
                      style={{marginTop: "2px"}}
                      onChange={async (e) => await setWithdrawReason(e.target.value)}
                      value={'서비스에 대한 불만'}
                    />
                    <label className="form-check-label" for="exampleRadios2">
                      서비스에 대한 불만
                    </label>
                  </div>
                  <div className="form-check py-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios3"
                      style={{marginTop: "2px"}}
                      onChange={async (e) => await setWithdrawReason(e.target.value)}
                      value={'버그로 인한 불편'}
                    />
                    <label className="form-check-label" for="exampleRadios3">
                      버그로 인한 불편
                    </label>
                  </div>
                  <div className="form-check py-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios4"
                      style={{marginTop: "2px"}}
                      onChange={async (e) => await setWithdrawReason(e.target.value)}
                      value={'기타(직접 입력)'}
                    />
                    <label className="form-check-label" for="exampleRadios4">
                      기타(직접 입력)
                    </label>
                  </div>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    onChange={async (e) => await setWithdrawReasonDtl(e.target.value)}
                    value={withdrawReasonDtl}
                  />
                </td>
              </tr>
            </table>

            <div className="d-flex justify-content-center my-5">
              <p></p>
              <button type="button" className="btn btn_puple px-3" onClick={submit}>
                회원 탈퇴
              </button>
              <Link onClick={ctx.onUserLogout} to="/">
                <button type="button" className="btn btn_puple px-3" style={{display: "none"}} ref={btnLogoutRef}>
                  회원 탈퇴
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </MypageCard>
  );
};

export default MypageWithdraw;
