import React, {useEffect, useState} from "react";
import double_arrow_b from "../../../image/double_arrow_b.svg";
import add_circle from "../../../image/add_circle.svg";
import crown from "../../../image/crown.svg";
import close_b from "../../../image/close_b.svg";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import ProductsComponent from "./ProductsComponent";
import APIHelper from "../../../helpers/APIHelper";
import moment from "moment";
import {Link} from "react-router-dom";
import Constants from "../../../constants/constants";
import CommonLib from "../../../libraries/CommonLib";
import modalStyle from "../../../actions/openModalStyle";
import {useDispatch, useSelector} from "react-redux";
import first_page_black_48dp from "../../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../../image/chevron_left_black_24dp.svg";
import navigate_next_black_48dp from "../../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../../image/last_page_black_48dp.svg";

const AdminProjectManager = (props) => {
  const projectId = props?.match?.params?.id;
  const productId = props?.match?.params?.productId;

  const [project, setProject] = useState({});
  const [product, setProduct] = useState({});
  const [buyer, setBuyer] = useState({});
  const [renew, setRenew] = useState('');
  const [keyword, setKeyword] = useState('');

  const getBuyer = (id) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("user/buyer/get", {id});
      if (code === 200) {
        setBuyer(result);
      }
      return resolve(true);
    });
  }

  const getProject = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("project/get", {id: projectId});
      if (code === 200) {
        setProject(result);
        await getBuyer(result.buyer_user_id);
      }
      return resolve(true);
    });
  }

  useEffect(() => {
    const fetch = async () => {
      await getProject();
      await getManagers();
    }
    fetch();
  }, []);

  const [managers, setManagers] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);

  const getManagers = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result: _data, message}} = await APIHelper.getForm("admin/gets");
      if (code === 200) {
        const {data: {code, result: _managers, message}} = await APIHelper.getForm("manager/gets", {projectId});
        setManagers(_data.filter((item) => _managers.filter((element) => element.admin_id === item.id).length === 0));
        const arr = _data.filter((item) => _managers.filter((element) => element.admin_id === item.id).length > 0);
        for (const [key, item] of Object.entries(arr))
          item.leader_yn = _managers.filter((element) => element.admin_id === item.id)[0].leader_yn;
        setSelectedManagers(arr);
      }
      return resolve(true);
    });
  }

  const submitManager = () => {
    return new Promise(async (resolve, reject) => {
      await APIHelper.delete(`manager/clear?projectId=${projectId}&productId=${product.id}`);
      for (const [key, item] of Object.entries(selectedManagers)) {
        await APIHelper.postForm("manager/add", {
          projectId: projectId,
          projectProductId: product.id,
          adminId: item.id,
          leaderYn: item.leader_yn || 'N'
        });
      }
      await CommonLib.jalert('저장이 완료되었습니다.');
      return resolve(true);
    });
  }

  const clickManagerHandler = (e) => {
    if (selectedManagers.filter((element) => element.id === e.id).length > 0) {
      setSelectedManagers(selectedManagers.filter((element) => element.id !== e.id));
    } else setSelectedManagers([...selectedManagers, e]);
  };

  const primaryManagerHandler = (e) => {
    const arr = [...selectedManagers];
    for (const [key, value] of Object.entries(arr)) {
      if (value === e) {
        if (value.leader_yn === "Y")
          value.leader_yn = "N";
        else
          value.leader_yn = "Y";
      } else value.leader_yn = "N";
    }
    setSelectedManagers(arr);
  };

  const handleSearchKeyDown = async (e) => {
    if (e.key === "Enter") {
      await getMakers(keyword);
    }
  };

  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modalStyle);

  const selectMakerSubmit = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.postForm("project/rep/maker/submit", {
        projectId,
        repMakerUserId: selectedMaker?.user_id,
      });
      await CommonLib.jalert(message);

      if (code === 200) await getProject(projectId);

      dispatch(modalStyle("closed", ""));
      return resolve(true);
    });
  }

  const submitMakerRequest = (projectId) => {
    const obj = {projectId};
    setRenew(new Date().getTime());
    dispatch(modalStyle("open", obj))
  }

  const [makers, setMakers] = useState(false);
  const [selectedMaker, setSelectedMaker] = useState({});

  useEffect(() => {
    const fetch = async () => {
      setKeyword('');
      await getMakers();
    }
    fetch();
  }, [renew]);

  // 제조사 리스트 가져오기
  const getMakers = (keyword = '') => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("user/maker/gets", {keyword});
      setMakers(result);
      return resolve(true);
    });
  };

  
  let modalInlineStyle;

  if (modal.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }

  return (
    <AdminCard>

      {/*대표 제조사 모달 S*/}
      <div style={{display: modalInlineStyle}}>
        <div
            className="pop_box02"
            style={{width: "900px", minHeight: "500px"}}
        >
          <div className="POP_title pb-3 border-bottom">
            <h4>
              <strong>대표 제조사 선택</strong>
            </h4>
            <p className="closed">
              <button
                  type="button"
                  className="btn"
                  onClick={() => dispatch(modalStyle("closed", ""))}
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box_lg" style={{marginBottom: "40px"}}>
            <div className="bg-lpurple box_round p-3 mt-3">
              <div className="input-group">
                <input
                    type="text"
                    className="form-control"
                    placeholder="제조사 명 또는 아래 키워드를 선택하세요"
                    onChange={async (e) => await setKeyword(e.target.value)}
                    value={keyword}
                    onKeyDown={handleSearchKeyDown}
                />
                <button
                    className="btn btn-blue px-5"
                    type="button"
                    id="button-addon2"
                    onClick={() => getMakers(keyword)}
                >
                  검색
                </button>
              </div>
              <ul className="d-flex pt-3 px-0 m-0">
                <li className="px-1">
                  <a href="#" className="Hash2 txt-point txt-sm">
                    Mask
                  </a>
                </li>
                <li className="px-1">
                  <a href="#" className="Hash2 txt-point txt-sm">
                    Hair
                  </a>
                </li>
                <li className="px-1">
                  <a href="#" className="Hash2 txt-point txt-sm">
                    Skincare
                  </a>
                </li>
                <li className="px-1">
                  <a href="#" className="Hash2 txt-point txt-sm">
                    Face Oil
                  </a>
                </li>
              </ul>
            </div>

            <div
                className="wid_scroll scroll_y custom_scroll"
                style={{height: "740px", maxWidth: "calc(100% + 4px)"}}
            >
              {makers && makers.map((maker, key) =>
                  <div
                      key={key}
                      className={`d-flex p-4 p_rative border${
                          selectedMaker && selectedMaker === maker
                              ? "_purple"
                              : " border-bottom"
                      }`}
                      style={{clear: "both"}}
                      onClick={() => setSelectedMaker(maker)}
                  >
                    <p>
                      {maker.rep_image_fid && maker.rep_image_fid.length > 0 ?
                          <img
                              src={`${Constants.API_HOST}/common/download?fid=${maker.rep_image_fid}&cd=inline&thumb=400`}
                              style={{width: "220px", height: "155px", objectFit: "cover"}}
                          />
                          :
                          <img
                              src={process.env.PUBLIC_URL + "/images/no_image.png"}
                              style={{width: "220px", height: "155px", objectFit: "cover"}}
                          />
                      }
                    </p>
                    <div className="px-4">
                      <h5 className="txt-point">
                        <strong>{maker.company_name}</strong>
                      </h5>
                      <p>
                        {maker?.introduce.length > 0 ? maker.introduce : "등록된 한줄 소개가 없습니다."}
                        <br/>
                        {maker?.functionals && maker?.functionals.length > 0 ? maker.functionals.map((item, key) => (
                            <>
                              {key > 0 ? ", " : ""} {item.name_kr}
                            </>
                        )) : " 등록된 기능성 제품 분류가 없습니다."}
                      </p>

                      <ul className="txt-gray p-0 pt-2" style={{marginBottom: "8px"}}>
                        <li className=" txt-sm">
                          인증서 :
                          {maker?.certs && maker?.certs?.length > 0 ? maker?.certs.map((item, key) => (
                              <>
                                {key > 0 ? " / " : ""} {item.name_en}
                              </>
                          )) : " 없음"}
                        </li>
                        <li className="d-flex txt-sm">
                          <p className="txt-sm">MOQ : {maker?.moq?.name_kr?.length > 0 ? maker?.moq?.name_kr : "정보없음"}</p> <p className="txt-sm" style={{marginLeft: "15px"}}>거래 횟수 : 0회</p>
                        </li>
                      </ul>
                      <div className="mb-0 float-left">
                        {maker?.mf_items && maker?.mf_items.map((item, key) => (
                            <span className="Hash txt-point">{item.name_kr}</span>
                        ))}
                      </div>
                    </div>
                  </div>
              )}

              <div className="d-flex justify-content-center py-4 d-none">
                <ul className="d-flex my-3" style={{margin: "0 auto"}}>
                  <li className="PAGE">
                    <a href="#" aria-label="Previous">
                      <img
                          src={first_page_black_48dp}
                          style={{width: "25px"}}
                      />
                    </a>
                  </li>
                  <li className="PAGE">
                    <a href="#" aria-label="Previous">
                      <img
                          src={chevron_left_black_24dp}
                          style={{width: "25px"}}
                      />
                    </a>
                  </li>
                  <li className="PAGE">
                    <a href="#" className="ON">
                      1
                    </a>
                  </li>
                  <li className="PAGE">
                    <a href="#">2</a>
                  </li>
                  <li className="PAGE">
                    <a href="#">3</a>
                  </li>
                  <li className="PAGE">
                    <a href="#" aria-label="Previous">
                      <img
                          src={navigate_next_black_48dp}
                          style={{width: "25px"}}
                      />
                    </a>
                  </li>
                  <li className="PAGE">
                    <a href="#" aria-label="Previous">
                      <img src={last_page_black_48dp} style={{width: "25px"}}/>
                    </a>
                  </li>
                </ul>
              </div>

            </div>
          </div>
          <div className="POP_foot d-flex justify-content-between">
            <button
                className="btn btn-sm px-3 btn-blue"
                type="button"
                onClick={() => selectMakerSubmit()}
            >
              선택한 제조사를 대표 제조사로 지정합니다
            </button>
          </div>
        </div>
      </div>
      {/*대표 제조사 모달 E*/}
      <div
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <section className="col-4" style={{height: "98%"}}>
          <div
            className="bg-lpurple shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "100%"}}
          >
            <p className="h5 bold p-2 border-bottom">제품리스트</p>
            <div
              className="wid_scroll scroll_y custom_scroll_p"
              style={{height: "calc(100% - 60px)"}}
            >
              <ProductsComponent projectId={projectId} productId={productId} click={async (e) => {
                setProduct(e);
                await getManagers(e);
              }}/>
            </div>
          </div>
        </section>
        <section className="col-5" style={{height: "98%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "100%"}}
          >
            <p className="h5 bold p-2 border-bottom">관리</p>
            <article className="border">
              <ul className="p-3 w-100 d-flex flex-wrap mb-2">
                <li className="px-3 w-45 txt-sm txt-gray">
                  <span className="bold">요청번호 :</span> {product.req_no}
                </li>
                <li className="px-3 w-45 txt-sm txt-gray">
                  <span className="bold">요청일자 :</span> {moment(project.created_at).format("YYYY.MM.DD HH:mm")}
                </li>
                <li className="px-3 w-45 txt-sm txt-gray">
                  <span className="bold">진행상태 :</span>&nbsp;{product?.status?.name_kr}
                </li>
                <li className="px-3 w-45 txt-sm txt-gray">
                  <span className="bold">바이어 제작 문의</span>
                </li>
              </ul>

              <div className="row row-cols-2 mb-3 gx-3 px-3">
                <div className="col">
                  <div className="border box_round_5 p-2 px-3">
                    <div className="d-flex flex-wrap justify-content-between">
                      <p className="h6 mt-1">바이어</p>
                      <p>
                        {/*<button*/}
                        {/*  type="button"*/}
                        {/*  className="btn btn-sm p-0 px-2 btn-ligray"*/}
                        {/*>*/}
                        {/*  교체*/}
                        {/*</button>*/}
                        <Link to={`/buyer/home/${buyer.user_id}`} target="_blank">
                          <button
                            type="button"
                            className="btn btn-sm p-0 px-2 btn-ligray"
                          >
                            새창
                          </button>
                        </Link>
                      </p>
                    </div>

                    <div className="d-flex justify-content-center mt-3 mb-2">
                      <p className="btn_oval_lg mb-1 mx-1">
                        {buyer?.rep_image_fid?.length > 0 ?
                          <img src={`${Constants.API_HOST}/common/download?fid=${buyer?.rep_image_fid}&cd=inline&thumb=400`} style={{width: "65px", height: "65px", objectFit: "cover"}}/>
                          :
                          <div style={{width: "65px", height: "65px", borderRadius: "33.5px", backgroundColor: "#efefef"}}></div>
                        }
                      </p>
                      <div className="text-start mx-1">
                        <p className="h6">{buyer?.company}</p>
                        <p>국적 : {buyer?.country?.name_kr}</p>
                        <p>거래횟수 : 0회</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="border box_round_5 p-2"
                    style={{minHeight: "145px"}}
                  >
                    {project?.rep_maker_user_id > 0 ? (
                      <>
                        <div className="d-flex flex-wrap justify-content-between">
                          <p className="h6 mt-1">대표 제조사</p>
                          <p>
                            <button
                              type="button"
                              className="btn btn-sm p-0 px-2 btn-ligray"
                              onClick={() => submitMakerRequest(props?.product?.project_id)}
                            >
                              교체
                            </button>
                            <Link to={`/maker/home/${project?.rep_maker_user_id}`} target="_blank">
                              <button
                                type="button"
                                className="btn btn-sm p-0 px-2 btn-ligray"
                              >
                                새창
                              </button>
                            </Link>
                          </p>
                        </div>

                        <div className="d-flex justify-content-center mt-3 mb-2">
                          <p className="btn_oval_lg mb-1 mx-1">
                            {project?.rep_maker?.id > 0 ?
                              <img src={`${Constants.API_HOST}/common/download?fid=${project?.rep_maker?.rep_image_fid}&cd=inline&thumb=400`} style={{width: "65px", height: "65px", objectFit: "cover"}}/>
                              :
                              <div style={{width: "65px", height: "65px", borderRadius: "33.5px", backgroundColor: "#efefef"}}></div>
                            }
                          </p>
                          <div className="text-start mx-1">
                            <p className="h6">{project?.rep_maker?.company_name}</p>
                            <p>국적 : {project?.rep_maker?.country?.name_kr}</p>
                            <p>거래횟수 : 0회</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <Link onClick={() => submitMakerRequest(props?.product?.project_id)}>
                        <p className="text-center p-3 h6">
                          대표 제조사를 연동하세요.
                          <br/>
                          <button type="button" className="btn btn-sm mt-2">
                            <img src={add_circle} style={{width: "45px"}}/>
                          </button>
                        </p>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </article>

            {/*<article className="p-3 my-2">*/}
            {/*  <p className="h6">*/}
            {/*    진행 하시려면 접수진행 및 프로젝트 생성 하세요*/}
            {/*  </p>*/}
            {/*  <p className="mt-3">*/}
            {/*    <button*/}
            {/*      type="button"*/}
            {/*      className="btn btn-purple p-1 px-4"*/}
            {/*      style={{marginRight: "5px"}}*/}
            {/*    >*/}
            {/*      접수진행*/}
            {/*    </button>*/}
            {/*    <button type="button" className="btn btn_gray p-1 px-4">*/}
            {/*      프로젝트 종료*/}
            {/*    </button>*/}
            {/*  </p>*/}
            {/*</article>*/}
            <div
              className="wid_scroll scroll_y custom_scroll mt-2"
              style={{height: "calc(100% - 430px)"}}
            >
              <article className="border wid_inline">
                <div className="p-3">
                  <div className="d-flex justify-content-between pb-2 border-bottom">
                    <p className="h5 mt-1 idx_tit">전체 매니저</p>
                  </div>
                  <div className="row row-cols-2 gx-3  mt-4">
                    {managers.filter((item) => selectedManagers.filter((element) => element.id === item.id).length === 0).map((item, i) => (
                      <div key={i} className="col">
                        <div className="box_round_5 border my-2 d-flex p-3">
                          <p className="btn_oval_sm mb-1 mx-1">
                            {item.image_fid && item.image_fid.length > 0 ?
                              <img
                                src={`${Constants.API_HOST}/common/download?fid=${item.image_fid}&cd=inline&thumb=400`}
                                style={{width: "45px", height: "45px"}}
                              />
                              :
                              <div style={{width: "45px", height: "45px", backgroundColor: "#efefef", borderRadius: "22.5px"}}></div>
                            }
                          </p>
                          <div className="text-start mx-2">
                            <p className="h6 mb-1">{item.name}</p>
                            <p className="txt-sm">{item.position} | {item.task}</p>
                          </div>
                          <p className="ms-auto">
                            <button type="button" className="btn p=0" onClick={() => clickManagerHandler(item)}>
                              <img
                                src={double_arrow_b}
                                style={{width: "25px"}}
                              />
                            </button>
                          </p>
                        </div>
                      </div>
                    ))}
                    {!product?.id &&
                      <div className="col w-100">
                        <div className="box_round_5 border my-2 d-flex p-3 justify-content-center">
                          제품을 선택해 주세요.
                        </div>
                      </div>
                    }
                    {product?.id > 0 && managers.filter((item) => selectedManagers.filter((element) => element.id === item.id).length === 0).length === 0 &&
                      <div className="col w-100">
                        <div className="box_round_5 border my-2 d-flex p-3 justify-content-center">
                          선택 가능한 매니저가 없습니다.
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </article>
            </div>
          </div>
        </section>
        <section className="col-3" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom mb-4">
              <p className="h5 bold p-2">매니저 배정 </p>
              <p className="m-1">
                <button
                  type="button"
                  className="btn btn-purple txt-sm py-1 px-3"
                  onClick={submitManager}
                >
                  저장
                </button>
              </p>
            </div>

            {selectedManagers.length === 0 &&
              <div className="col w-100">
                <div className="box_round_5 border my-2 d-flex p-3 justify-content-center">
                  매니저를 배정해 주세요.
                </div>
              </div>
            }
            {selectedManagers && selectedManagers.map((item, i) => (
              <div key={i} className="box_round_5 border my-2 d-flex p-3">
                <p className="py-3 p-1">
                  <input type="checkbox" checked={item.leader_yn === 'Y'} onClick={() => primaryManagerHandler(item)}/>
                </p>
                <div className="btn_oval_sm mb-1 mx-2 p_rative">
                  {item.image_fid && item.image_fid.length > 0 ?
                    <img
                      src={`${Constants.API_HOST}/common/download?fid=${item.image_fid}&cd=inline&thumb=400`}
                      style={{width: "45px", height: "45px"}}
                    />
                    :
                    <div style={{width: "45px", height: "45px", backgroundColor: "#efefef", borderRadius: "22.5px"}}></div>
                  }
                </div>
                <div className="text-start mx-2">
                  <div className="h6 mb-1 p_rative float-left">
                    {item.leader_yn === 'Y' &&
                      <img src={crown} style={{width: "18px", marginTop: "-4px", marginRight: "4px"}}/>
                    }
                    {item.name}
                  </div>
                  <p className="txt-sm">{item.position} | {item.task}</p>
                </div>
                <div className="ms-auto">
                  <button type="button" className="btn p-0 mb-1">
                    <img src={close_b} style={{width: "20px"}} onClick={() => clickManagerHandler(item)}/>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminProjectManager;
