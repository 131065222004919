import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import BuyerProjectSide from "./BuyerProjectSide";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import openInquiry from "../../../actions/openInquiry";

import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import moment from "moment";
import CommonLib from "../../../libraries/CommonLib";
import API from "../../../helpers/APIHelper";

const BuyerProjectInquiry = (props) => {
  const dv = props?.location?.state?.dv || props?.match?.params?.dv;
  const id = props?.location?.state?.id || props?.match?.params?.id;

  const [rets, setRets] = useState([]);

  const gets = async () => {
    const {data: {code, result, message}} = await APIHelper.getForm("cs/inquiry/mines", {projectId: id});
    setRets(result);
  };

  useEffect(() => {
    const fetch = async () => {
      await gets();
    }
    fetch();
  }, []);

  const [renew, setRenew] = useState(0);

  return (
    <BuyerProjectSide dv={dv} id={id} renew={renew}>
      <div className="d-flex justify-content-between border-bottom py-3">
        <div>
          <p className="h5 bold txt-point">
            프로젝트 담당 매니저와 1:1로 문의를 할 수 있습니다.
          </p>
          <p className="h6">
            문의 할 내용이 있으면 오른쪽의 1:1 문의하기를 누르세요.
          </p>
        </div>

        <div>
          <button type="button" className="btn btn_puple px-4" onClick={() => props.openInquiry("open", "write", id, "", "", "", "", "", "", 0, new Date().getTime(), async () => await gets())}>
            1:1 문의하기
          </button>
        </div>
      </div>
      <div className="my-4" style={{minHeight: "580px"}}>
        <table className="table basic w-100">
          <colgroup>
            <col width="55px"/>
            <col width="150px"/>
            <col width="*"/>
            <col width="10%"/>
            <col width="15%"/>
          </colgroup>
          <tr>
            <th className="center">No</th>
            <th className="center">구분</th>
            <th className="center">내용</th>
            <th className="center"></th>
            <th className="center">작성일</th>
          </tr>
          {rets.map((ret, k) =>
            <tr key={k}>
              <td className="center">{rets.length - k}</td>
              <td className="center">{ret?.gubun?.name_kr}</td>
              <td
                style={{cursor: "pointer"}}
                onClick={async () => {
                  if (ret.status_cd === '답변완료' && ret.read_yn === 0) {
                    await APIHelper.putForm("user/inquiry/read", {id: ret.id});
                    await gets();
                    setRenew(new Date().getTime());
                  }
                  props.openInquiry(
                    "open",
                    "view",
                    id,
                    ret.subject,
                    ret.question,
                    ret.answer,
                    moment(ret.inquiry_date).format("YYYY.MM.DD"),
                    ret?.gubun?.name_kr,
                    ret?.status_cd,
                    ret?.id,
                    new Date().getTime()
                  );
                }}
              >
                <div className="d-flex row">
                  {ret.status_cd === '답변완료' && ret.read_yn === 0 && <p style={{width: "50px", marginRight: "4px"}} className="box_round_4 bg-blue px-2 text-center">NEW</p>}
                  {ret.subject}
                </div>
              </td>
              <td>
                <p style={{width: "50px"}} className={`box_round_4 bg-${ret.status_cd === '답변대기' ? 'darkgray' : 'red'} px-2 text-center`}>{ret.status_cd === '답변대기' ? '대기' : '답변'}</p>
              </td>
              <td className="center">{moment(ret.inquiry_date).format("YYYY.MM.DD")}</td>
            </tr>
          )}
        </table>
        {rets?.length === 0 &&
          <div className="col w-100">
            <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-white">
              등록된 1:1 문의가 없습니다.
            </div>
          </div>
        }
      </div>
    </BuyerProjectSide>
  );
};

function mapDispatchToProps(dispatcher) {
  return bindActionCreators(
    {
      openInquiry: openInquiry,
    },
    dispatcher
  );
}

export default connect(null, mapDispatchToProps)(BuyerProjectInquiry);
