import React, {useContext, useState} from "react";
import firebase from 'firebase';
import APIHelper from "../helpers/APIHelper";

const FirebaseMessaging = (props) => {
  React.useEffect(() => {
    (async () => {
      const config = {
        apiKey: "AIzaSyDB6znXi3gX9CYVLPPd6na9Wyt8VzsolvY",
        authDomain: "darlibaba-dev-39bd2.firebaseapp.com",
        projectId: "darlibaba-dev-39bd2",
        storageBucket: "darlibaba-dev-39bd2.appspot.com",
        messagingSenderId: "1030636099281",
        appId: "1:1030636099281:web:3f823accb27ff5743e57f0"
      };

      firebase.initializeApp(config);
      const messaging = firebase.messaging();

      console.log('FIREBASE MESSAGING : SUPPROTED', firebase.messaging.isSupported());

      messaging.requestPermission()
        .then(function () {
          console.log('FIREBASE MESSAGING : Permission OK');
          return messaging.getToken();
        })
        .then(function (token) {
          (async () => {
            const userEmail = localStorage.getItem("user.session.email") || '';
            const adminEmail = localStorage.getItem("admin.session.email") || '';
            if (userEmail !== '' || adminEmail !== '') {
              localStorage.setItem("push_token", token);
              const {data: {code, result, message}} = await APIHelper.postForm("user/push/token", {token, userEmail, adminEmail});
            }
            console.log('FIREBASE MESSAGING : TOKEN', token);
          })();
        })
        .catch(function (err) {
          console.log('FIREBASE MESSAGING : FCM ERROR', err);
        });

      messaging.onMessage(function (payload) {
        console.log('FIREBASE MESSAGING : PAYLOAD', payload);
        console.log(payload.notification.title);
        console.log(payload.notification.body);
      });

      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register("/firebase-messaging-sw.js")
          .then(function (registration) {
            console.log("FIREBASE MESSAGING : ServiceWorker", "Registration successful, scope is", registration.scope);
          })
          .catch(function (err) {
            console.log("FIREBASE MESSAGING : ServiceWorker", "Service worker registration failed, error", err);
          });
      }
    })();
  }, []);

  return (
    <>
    </>
  );
};

export default FirebaseMessaging;
