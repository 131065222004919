import React, {useContext, useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../sass/main.scss";
import {useDispatch, useSelector} from "react-redux";
import openModalStyle from "../../actions/openModalStyle";
import openRequest from "../../actions/openRequest";
import admin_logo from "../../image/admin_logo.png";
import AuthContext from "../auth-context";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretLeft, faCaretRight} from "@fortawesome/free-solid-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";
import APIHelper from "../../helpers/APIHelper";
import CommonLib from "../../libraries/CommonLib";
import $ from 'jquery';
import Icon_bell_y from "../../image/Icon_bell_y.svg";

library.add(faCaretRight);
library.add(faCaretLeft);

const AdminCard = (props) => {
  const ctx = useContext(AuthContext);
  const history = useHistory();
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.modalStyle);
  const requestModal = useSelector((state) => state.requestForm);
  const [containerHeight, setContainerHeight] = useState(window.innerHeight - 88);
  const [sidebarHidden, setSidebarHidden] = useState((localStorage.getItem("admin.sidebar.hidden") || "false") === "true");

  const [admin, setAdmin] = useState();

  const [auth1, setAuth1] = useState('N');
  const [auth2, setAuth2] = useState('N');
  const [auth3, setAuth3] = useState('N');
  const [auth4, setAuth4] = useState('N');
  const [auth5, setAuth5] = useState('N');
  const [auth6, setAuth6] = useState('N');
  const [auth7, setAuth7] = useState('N');
  const [auth8, setAuth8] = useState('N');


  const [unread, setUnread] = useState(0);

  useEffect(() => {
    const myframe = $("iframe[title='언어 번역 위젯']");
    const frame = $('.goog-te-menu-frame:first');
    frame.css({'border': 'none !important', 'background-color': 'transparent !important', 'position': 'absolute !important', 'top': '-9999px !important'})
    myframe.css({'border': 'none !important', 'background-color': 'transparent !important', 'position': 'absolute !important', 'top': '-9999px !important'})
    setTimeout(() => {
      $('.skiptranslate iframe').addClass("jnone")
    })
  }, []);
  const getUnreads = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {result: result1}} = await APIHelper.getForm("manager/project/unread/check");
      const _unreads = result1;
      setUnread(_unreads);
      return resolve({unreads: _unreads});
    });
  }

  let intvalUnreads;
  useEffect(() => {
    const fetch = async () => {
      await getUnreads();
      intvalUnreads = setInterval(async function () {
        getUnreads();
      }, 3000);
    }
    fetch();
    return () => {
      if (intvalUnreads) clearInterval(intvalUnreads);
    }
  }, []);

  const setSidebarStyle = () => {
    const val = !sidebarHidden;

    const sidebar = $('.sidebar');
    const main = $('.main-section');

    const cssSidebarMin = {'maxWidth': '60px'};
    const cssSidebarMax = {'maxWidth': '220px'};

    const cssMainMax = {'maxWidth': ($('.MAX_wide').width() - 120) + 'px'};
    const cssMainMin = {'maxWidth': ($('.MAX_wide').width() - 280) + 'px'};

    if (val) {
      sidebar.contents().not('.sidebar-button').hide();
      sidebar.css(cssSidebarMax);
      main.css(cssMainMin);
      main.animate(cssMainMax, 'slow');
      sidebar.animate(cssSidebarMin, 'slow', () => {
        setSidebarHidden(val);
        localStorage.setItem("admin.sidebar.hidden", val.toString());
      });
    } else {
      sidebar.css(cssSidebarMin);
      main.css(cssMainMax);
      main.animate(cssMainMin, 'slow');
      sidebar.animate(cssSidebarMax, 'slow', () => {
        sidebar.contents().show();
        setSidebarHidden(val);
        localStorage.setItem("admin.sidebar.hidden", val.toString());
      });
    }
  }

  React.useEffect(() => {
    (async () => {
      const {data: {code, result, message}} = await APIHelper.getForm("admin/me");
      setAdmin(result);

      if (result?.auth1 === "Y") setAuth1("Y");
      else setAuth1("N");

      if (result?.auth2 === "Y") setAuth2("Y");
      else setAuth2("N");

      if (result?.auth3 === "Y") setAuth3("Y");
      else setAuth3("N");

      if (result?.auth4 === "Y") setAuth4("Y");
      else setAuth4("N");

      if (result?.auth5 === "Y") setAuth5("Y");
      else setAuth5("N");

      if (result?.auth6 === "Y") setAuth6("Y");
      else setAuth6("N");

      if (result?.auth7 === "Y") setAuth7("Y");
      else setAuth7("N");

      if (result?.auth8 === "Y") setAuth8("Y");
      else setAuth8("N");

      if (result?.auth1 !== 'Y' && window.location.pathname.includes("/admin/ops/")) {
        await CommonLib.jalert("해당 메뉴에 대한 접근 권한이 없습니다.");
        history.push('/admin');
      } else if (result?.auth2 !== 'Y' && window.location.pathname.includes("/admin/data/")) {
        await CommonLib.jalert("해당 메뉴에 대한 접근 권한이 없습니다.");
        history.push('/admin');
      } else if (result?.auth3 !== 'Y' && window.location.pathname.includes("/admin/operate/")) {
        await CommonLib.jalert("해당 메뉴에 대한 접근 권한이 없습니다.");
        history.push('/admin');
      } else if (result?.auth4 !== 'Y' && window.location.pathname.includes("/admin/buyer/")) {
        await CommonLib.jalert("해당 메뉴에 대한 접근 권한이 없습니다.");
        history.push('/admin');
      } else if (result?.auth5 !== 'Y' && window.location.pathname.includes("/admin/maker/")) {
        await CommonLib.jalert("해당 메뉴에 대한 접근 권한이 없습니다.");
        history.push('/admin');
      } else if (result?.auth6 !== 'Y' && window.location.pathname.includes("/admin/project/")) {
        await CommonLib.jalert("해당 메뉴에 대한 접근 권한이 없습니다.");
        history.push('/admin');
      } else if (result?.auth7 !== 'Y' && window.location.pathname.includes("/admin/cs/")) {
        await CommonLib.jalert("해당 메뉴에 대한 접근 권한이 없습니다.");
        history.push('/admin');
      } else if (result?.auth8 !== 'Y' && window.location.pathname.includes("/admin/mypage/")) {
        await CommonLib.jalert("해당 메뉴에 대한 접근 권한이 없습니다.");
        history.push('/admin');
      }

      $('.nav-dev').each(function () {
        if (window.location.host.includes('localhost')) {
          $(this).show();
          $(this).parent().show();
        } else {
          $(this).hide();
          $(this).parent().hide();
        }
      });
    })();
  }, []);

  React.useEffect(() => {
    function handleResize() {
      setContainerHeight(window.innerHeight - 88);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`notranslate`}>
      <div
        onClick={() => {
          dispatch(openModalStyle("closed", ""));
          dispatch(openRequest("closed", ""));
          props.click(false);
        }}
        className={`${
          requestModal.openClose === "open" ||
          selector.openClose === "open" ||
          props.isOpen
            ? "pop_bak"
            : ""
        }`}
      ></div>
      <body>
      <nav
        style={{height: "100%"}}
        id="GNB_MENU_ADMIN"
        className="navbar navbar-dark sticky-top bg-darkblue_admin flex-md-nowrap p-0"
      >
        <Link
          to="/admin"
          className="p-2 txt-white"
          style={{width: "auto", marginLeft: "25px", marginRight: "30px"}}
        >
          <img src={admin_logo} style={{height: "35px", width: "auto"}}/>
        </Link>
        <ul className="d-flex admin-top-menu"
            style={{height: "100%", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", paddingLeft: "70px", paddingTop: "10px", width: "calc(100%)"}}
        >
          {auth1 === "Y" &&
            <li className="px-1" style={{minWidth: "150px", maxWidth: "210px", width: "11.11%"}}>
              <Link to="/admin/ops/home"><span style={{fontSize: "16px", marginTop: "5px", fontWeight: 600}}>어드민 관리</span></Link>
            </li>
          }
          {auth2 === "Y" &&
            <li className="px-1" style={{minWidth: "200px", maxWidth: "260px", width: "11.11%"}}>
              <Link to="/admin/data/oem/story"><span style={{fontSize: "16px", marginTop: "5px", fontWeight: 600}}>홈노출/검색관리</span></Link>
            </li>
          }
          {auth3 === "Y" &&
            <li className="px-1" style={{minWidth: "130px", maxWidth: "190px", width: "11.11%"}}>
              <Link to="/admin/operate/oem"><span style={{fontSize: "16px", marginTop: "5px", fontWeight: 600}}>운영관리</span></Link>
            </li>
          }
          {auth4 === "Y" &&
            <li className="px-1" style={{minWidth: "150px", maxWidth: "210px", width: "11.11%"}}>
              <Link to="/admin/buyer"><span style={{fontSize: "16px", marginTop: "5px", fontWeight: 600}}>바이어관리</span></Link>
            </li>
          }
          {auth5 === "Y" &&
            <li className="px-1" style={{minWidth: "150px", maxWidth: "210px", width: "11.11%"}}>
              <Link to="/admin/maker"><span style={{fontSize: "16px", marginTop: "5px", fontWeight: 600}}>제조사관리</span></Link>
            </li>
          }
          {auth6 === "Y" &&
            <li className="px-1" style={{minWidth: "150px", maxWidth: "210px", width: "11.11%"}}>
              <Link to="/admin/project/status/all"><span style={{fontSize: "16px", marginTop: "5px", fontWeight: 600}}>프로젝트관리</span></Link>
            </li>
          }
          {auth7 === "Y" &&
            <li className="px-1" style={{minWidth: "130px", maxWidth: "210px", width: "11.11%"}}>
              <Link to="/admin/cs/qna"><span style={{fontSize: "16px", marginTop: "5px", fontWeight: 600}}>고객서비스</span></Link>
            </li>
          }
          {auth8 === "Y" &&
            <li className="px-1" style={{minWidth: "130px", width: "11.11%"}}>
              <Link to="/admin/mypage/myinfo"><span style={{fontSize: "16px", marginTop: "5px", fontWeight: 600}}>매니저 개인홈</span></Link>
            </li>
          }
        </ul>

        <ul className="ms-auto navbar-nav px-3">
          <li className="nav-item text-nowrap">
            {auth6 === "Y" &&
              <Link to="/admin/mypage/alarm/project">
                  <span style={{marginRight: "20px"}}>
                  <img src={Icon_bell_y} style={{width: "30px", marginTop: "-2px"}}/>
                    {CommonLib.parseInt(unread) > 0 && <div className="new-icon3" style={{top: "20px", marginLeft: "15px"}}>{unread}</div>}
                </span>
              </Link>
            }
            <Link to="/admin/mypage/myinfo">
              <button type="button" className="btn btn-sm btn_puplelight no-transform" style={{cursor: "auto", pointerEvents: "none"}}>
                {admin?.email} | {admin?.grade === 99 ? "ADMIN" : "MANAGER"}
              </button>
            </Link>
            <span style={{paddingLeft: 10, paddingRight: 10}}>/</span>
            <Link onClick={ctx.onAdminLogout} to="/admin/login">
              <button type="button" className="btn btn-sm btn_puplelight no-transform" style={{cursor: "auto", pointerEvents: "none"}}>
                Sign out
              </button>
            </Link>
          </li>
        </ul>
      </nav>
      <div
        className="d-flex MAX_wide"
        style={{height: containerHeight, background: "#f5f8fd"}}
      >
        <div className="bg-darkblue_admin col-2 d-md-block sidebar collapse" style={{maxWidth: sidebarHidden ? "60px" : "220px"}}>
          <ul className='sidebar-button' style={{textAlign: "right", paddingRight: "15px"}}><FontAwesomeIcon icon={sidebarHidden ? "caret-right" : "caret-left"} size="2x" onClick={() => setSidebarStyle()} style={{cursor: "pointer"}}/></ul>
          <nav className="bg-darkblue_admin col-2 d-md-block sidebar collapse w-100" style={{visibility: sidebarHidden ? "hidden" : "visible"}}>
            <ul className="nav flex-column my-4 text-left left_menu_list_admin">
              {path.startsWith("/admin/ops/") && (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/ops/home" style={{fontSize: "14px"}}>
                      매니저 현황
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/ops/manager/review" style={{fontSize: "14px"}}>
                      매니저 후기 및 평점 관리
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/ops/log/visit" style={{fontSize: "14px"}}>
                      관리자 접속 정보
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link nav-dev" to="/admin/ops/log/action" style={{fontSize: "14px"}}>
                      관리자 액션 정보
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/ops/common/code" className="nav-link" style={{fontSize: "14px"}}>
                      공통 코드 관리
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/ops/common/code/110000" className="nav-link" style={{fontSize: "14px"}}>
                      제품 분류 코드 관리
                    </Link>
                  </li>
                  {/*<li className="nav-item">*/}
                  {/*  <Link className="nav-link" to="/admin/ops/log/visit">*/}
                  {/*    매니저 접속 정보*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <Link className="nav-link" to="/admin/ops/log/action">*/}
                  {/*    매니저 액션 정보*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <Link className="nav-link" to="/admin/ops/notice">*/}
                  {/*    매니저 공지 관리*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <a className="nav-link" href="#">*/}
                  {/*    매니저 후기 및 평점 관리 (X)*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <a className="nav-link" href="#">*/}
                  {/*    이용약관 관리 (X)*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <a className="nav-link" href="#">*/}
                  {/*    개인정보취급방침 (X)*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <Link*/}
                  {/*    to="/admin/ops/conductor/log/visit"*/}
                  {/*    className="nav-link"*/}
                  {/*    href="#"*/}
                  {/*  >*/}
                  {/*    관리자 접속 정보*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <Link*/}
                  {/*    to="/admin/ops/conductor/log/action"*/}
                  {/*    className="nav-link"*/}
                  {/*    href="#"*/}
                  {/*  >*/}
                  {/*    관리자 액션 정보*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <a className="nav-link" href="#">*/}
                  {/*    매니저 알림 메세지 (X)*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                </>
              )}
              {path.startsWith("/admin/operate/") && (
                <>
                  <li className="nav-item">
                    <Link to="/admin/operate/manager/project" className="nav-link" style={{fontSize: "14px"}}>
                      매니저별 프로젝트 현황
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/operate/manager/review" className="nav-link" style={{fontSize: "14px"}}>
                      매니저별 후기 및 평점
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/operate/oem" className="nav-link" style={{fontSize: "14px"}}>
                      OEM 제작 제안 내역
                    </Link>
                  </li>
                  {/*<li className="nav-item">*/}
                  {/*  <Link to="/admin/ops/tag/code" className="nav-link">*/}
                  {/*    바이어 태그 코드 관리*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <a className="nav-link" href="#">*/}
                  {/*    제조사 태그 코드 관리*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <a className="nav-link" href="#">*/}
                  {/*    프로젝트 태그 코드 관리*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <a className="nav-link" href="#">*/}
                  {/*    부자재 카테고리 코드 관리*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <a className="nav-link" href="#">*/}
                  {/*    성분 카테고리 코드 관리*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <a className="nav-link" href="#">*/}
                  {/*    제품 관리 - 카테고리별*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <a className="nav-link" href="#">*/}
                  {/*    부자재 관리 - 카테고리별*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <a className="nav-link" href="#">*/}
                  {/*    성분 관리 - 카테고리별*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      프로젝트 매니저 배정
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      제조사 연동 처리
                    </a>
                  </li>
              */}
                </>
              )}
              {path.startsWith("/admin/data") && (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/data/home/banner" style={{fontSize: "14px"}}>
                      홈 - 슬라이딩
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/data/oem/story" className="nav-link" style={{fontSize: "14px"}}> 홈 - OEM/ODM 스토리 </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/data/recommend/item" style={{fontSize: "14px"}}>
                      홈 - 제조사 추천상품
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/data/search/ingredient" style={{fontSize: "14px"}}>
                      검색 - 성분
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/data/search/item/mf" style={{fontSize: "14px"}}>
                      검색 - 제품
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/data/search/item/bt" style={{fontSize: "14px"}}>
                      검색 - 용기
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/data/search/item/bx" style={{fontSize: "14px"}}>
                      검색 - 단상자
                    </Link>
                  </li>
                </>
              )}
              {path.startsWith("/admin/buyer") && (
                <>
                  <li className="nav-item">
                    <Link to="/admin/buyer" className="nav-link" style={{fontSize: "14px"}}>
                      바이어 현황
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/buyer/list" className="nav-link" style={{fontSize: "14px"}}>
                      바이어 관리
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/buyer/log/visit" className="nav-link" style={{fontSize: "14px"}}>
                      바이어별 방문현황
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/buyer/favorite" className="nav-link" style={{fontSize: "14px"}}>
                      바이어별 관심내역
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/buyer/review" className="nav-link" style={{fontSize: "14px"}}>
                      바이어별 후기 및 평점
                    </Link>
                  </li>
                  {/*<li className="nav-item">*/}
                  {/*  <a className="nav-link">바이어별 후기 및 평점 (X)</a>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <Link to="/admin/buyer/favorite" className="nav-link">*/}
                  {/*    바이어별 관심 내역*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <Link to="/admin/buyer/log/visit" className="nav-link">*/}
                  {/*    바이어별 방문 현황*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                </>
              )}
              {path.startsWith("/admin/maker") && (
                <>
                  <li className="nav-item">
                    <Link to="/admin/maker" className="nav-link" style={{fontSize: "14px"}}>
                      제조사 현황
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/maker/list" className="nav-link" style={{fontSize: "14px"}}>
                      제조사 관리
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/maker/product" className="nav-link" style={{fontSize: "14px"}}>
                      제조사 제품관리
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/maker/log/visit" className="nav-link" style={{fontSize: "14px"}}>
                      제조사별 방문현황
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/maker/favorite" className="nav-link" style={{fontSize: "14px"}}>
                      제조사별 관심내역
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/maker/review" className="nav-link" style={{fontSize: "14px"}}>
                      제조사별 후기 및 평점
                    </Link>
                  </li>
                  {/*<li className="nav-item">*/}
                  {/*  <Link to="/admin/maker/product" className="nav-link">*/}
                  {/*    제조사 제품 관리*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <Link to="/admin/maker/favorite" className="nav-link">*/}
                  {/*    제조사별 관심 내역*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <Link to="/admin/maker/log/visit" className="nav-link">*/}
                  {/*    제조사별 방문 현황*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <Link to="/admin/maker/log/action" className="nav-link">*/}
                  {/*    제조사별 액션 내역*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                </>
              )}
              {(path.startsWith("/admin/project") ||
                path.startsWith("/admin/product")) && (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to={{pathname: `/admin/project/status/all`}} style={{fontSize: "14px"}}> 전체 프로젝트 </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={{
                        pathname: `/admin/project/status/progress`,
                      }}
                      style={{fontSize: "14px"}}
                    >
                      진행중인 프로젝트
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={{
                        pathname: `/admin/project/status/done`,
                      }}
                      style={{fontSize: "14px"}}
                    >
                      완료된 프로젝트
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={{
                        pathname: `/admin/project/status/new`,
                      }}
                      style={{fontSize: "14px"}}
                    >
                      신규 프로젝트
                    </Link>
                  </li>
                </>
              )}
              {path.startsWith("/admin/cs") && (
                <>
                  <li className="nav-item">
                    <Link to="/admin/cs/notice" className="nav-link" style={{fontSize: "14px"}}>
                      공지사항
                    </Link>
                  </li>
                  <li className="nav-item" style={{marginLeft: "17px", cursor: "pointer", fontSize: "14px"}}
                      onClick={() => {
                        localStorage.setItem("admin.faq.user_type_cd", 'BUYER');
                        history.push({
                          pathname: "/admin/cs/faq",
                          state: {user_type_cd: 'BUYER'},
                        });
                      }}>
                    FAQ - 바이어
                  </li>
                  <li className="nav-item" style={{marginLeft: "17px", cursor: "pointer", fontSize: "14px"}}
                      onClick={() => {
                        localStorage.setItem("admin.faq.user_type_cd", 'MAKER');
                        history.push({
                          pathname: "/admin/cs/faq",
                          state: {user_type_cd: 'MAKER'},
                        });
                      }}>
                    FAQ - 제조사
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/cs/qna" className="nav-link" style={{fontSize: "14px"}}>
                      문의관리
                    </Link>
                  </li>
                </>
              )}
              {path.startsWith("/admin/mypage") && (
                <>
                  <li className="nav-item">
                    <Link to="/admin/mypage/myinfo" className="nav-link" style={{fontSize: "14px"}}>
                      내 정보
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/mypage/review" className="nav-link" style={{fontSize: "14px"}}>
                      후기 및 평점
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="#" className="nav-link nav-dev" style={{fontSize: "14px"}}>
                      개인 알림*
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/mypage/alarm/project" className="nav-link" style={{fontSize: "14px"}}>
                      프로젝트 알림
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link onClick={ctx.onAdminLogout} to="/admin/login" className="nav-link" style={{fontSize: "14px"}}>
                      로그아웃
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </div>
        <main
          role="main"
          className={`main-section col-12 px-md-5`}
          style={{marginTop: "0", maxWidth: sidebarHidden ? "calc(100% - 120px)" : "calc(100% - 280px)"}}
        >
          {props.children}
        </main>
      </div>
      </body>
    </div>
  );
};

export default AdminCard;
