import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import Constants from "../../constants/constants";
import {useHistory} from "react-router-dom";
import CommonLib from "../../libraries/CommonLib";

const MakerProductView = (props) => {
  const {maker, makerProductId} = props;

  const history = useHistory();

  const [content, setContent] = useState('');
  const [imageFile, setImageFile] = useState('');
  const [image, setImage] = useState('');
  const imageFileRef = useRef();

  const [arrProducts, setArrProducts] = useState([]);
  const [arrTypes, setArrTypes] = useState([]);
  const [arrCountries, setArrCountries] = useState([]);

  const [makerProduct, setMakerProduct] = useState({});

  const [pkId, setPkId] = useState(makerProductId || 0);
  const [productCd, setProductCd] = useState();
  const [typeCd, setTypeCd] = useState();
  const [countryCd, setCountryCd] = useState();
  const [name, setName] = useState('');
  const [moq, setMoq] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [volume, setVolume] = useState('');
  const [showYn, setShowYn] = useState('Y');

  let seq = 0;

  useEffect(() => {
    const fetch = async () => {
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '110000'});
        if (code === 200) {
          await setArrProducts(result);
          if (result.length > 0) {
            await setProductCd(result[0]);
            await getArrTypes(result[0].cd);
          }
        }
      }
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '106000'});
        await setArrCountries(result);
      }
      await load();
    }
    fetch();
  }, []);

  const load = () => {
    return new Promise(async (resolve, reject) => {
      if (pkId > 0) {
        const {data: {code, result, message}} = await APIHelper.getForm(`user/maker/product/get`, {id: maker.user_id, pkId});
        if (code === 200) {
          setMakerProduct(result);

          setProductCd(result?.category_cd);
          await getArrTypes(result?.category_cd);
          setTypeCd(result?.product_cd);
          setCountryCd(result?.country_cd);
          setName(result?.name);
          setMoq(result?.moq);
          setUnitPrice(result?.unit_price);
          setVolume(result?.volume);
          setShowYn(result?.show_yn);
        }
      }
      return resolve(true);
    });
  }

  const getArrTypes = (parentCd) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd});
      if (code === 200) {
        setArrTypes(result);
        if (result.length > 0) {
          setTypeCd(result[0]);
        }
      }
      return resolve(true);
    });
  }

  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setImageFile(file);
    };
    reader.readAsDataURL(file);
  };

  const submit = () => {
    return new Promise(async (resolve, reject) => {
      let makerProductId = 0;
      try {
        {
          const {data: {code, result, message}} = await APIHelper.postForm(`user/maker/product/submit`, {
            pkId,
            productCd,
            typeCd,
            countryCd,
            name,
            moq,
            unitPrice,
            volume,
            showYn,
          });
          if (code === 200) {
            setPkId(result?.id);
            makerProductId = result?.id;
          } else {
            await CommonLib.jalert(message);
          }
        }
        if (makerProductId > 0) {
          const {data: {code, result, message}} = await APIHelper.postForm(`user/maker/product/dtl/submit`, {
            makerProductId,
            content,
            image: imageFileRef?.current?.files ? imageFileRef?.current?.files[0] : null,
          });
          await CommonLib.jalert(message);
          await load();

          setContent('');
          setImage('');
        }
      } catch (err) {
      }
      return resolve(true);
    });
  }

  const remove = (makerProductId, makerProductDtlId) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.deleteForm(`user/maker/product/dtl/delete?makerProductId=${makerProductId}&makerProductDtlId=${makerProductDtlId}`);
      await load();
      return resolve(true);
    });
  }

  return (
    <>
      <h5 className="mb-4" style={{fontWeight: "bold"}}>{name}</h5>
      <div>
        <table className="table basic w-100">
          <colgroup>
            <col width="18%"/>
            <col width="*"/>
            <col width="18%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th className="center">제품 분류</th>
            <td>
              {makerProduct?.category?.name_kr}
            </td>
            <th className="center">제품 종류</th>
            <td>
              {maker.maker_type === 'BT' || maker.maker_type === 'BX' ? '' : makerProduct?.product?.name_kr}
            </td>
          </tr>
          <tr>
            <th className="center">판매 국가</th>
            <td>
              {makerProduct?.country?.name_kr}
            </td>
            <th className="center">MOQ</th>
            <td>
              {CommonLib.numberWithCommas(moq)}
            </td>
          </tr>
          <tr>
            <th className="center">Unit Price</th>
            <td>
              {CommonLib.numberWithCommas(unitPrice)}
            </td>
            <th className="center">Volume</th>
            <td>
              {CommonLib.numberWithCommas(volume)}
            </td>
          </tr>
        </table>
      </div>

      {makerProduct?.dtls && makerProduct?.dtls?.length > 0 && makerProduct?.dtls.map((item, key) =>
        <div key={key} className="mb-3">
          {((item.content && item.content.length > 0) || (item.image_fid && item.image_fid.length > 0)) &&
            <div className="d-flex justify-content-between mt-5 border-bottom">
              <p className="h5 bold txt-point pb-2">No. {++seq}</p>
            </div>
          }
          {item.content && item.content.length > 0 &&
            <div className="mt-3" dangerouslySetInnerHTML={{__html: item.content}}/>
          }
          {item.image_fid && item.image_fid.length > 0 &&
            <div className="row row-cols-3 mt-3">
              <img
                src={`${Constants.API_HOST}/common/download?fid=${item.image_fid}&cd=inline&thumb=1000`}
                style={{maxWidth: "100%"}}
              />
            </div>
          }
        </div>)
      }

      <br/>

      <p className="text-center">
        <button type="button" className="btn btn_puplelight px-5 my-3" onClick={props.click}>
          돌아가기
        </button>
      </p>
    </>
  );
};

export default MakerProductView;
