import React, {useContext, useEffect, useRef, useState} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import AuthContext from "./auth-context";
import logo from "../image/logo.png";
import arrowright_w from "../image/arrow-right_w.svg";
import Icon_quick_history from "../image/Icon_quick_history.svg";
import icon_quick_recomend from "../image/icon_quick_recomend.svg";
import icon_quick_category from "../image/icon_quick_category.svg";
import main_3d_01 from "../image/simulator_l.jpg";
import main_3d_02 from "../image/simulator_r.jpg";
import main_cate_01 from "../image/look_category_1.jpg";
import main_cate_02 from "../image/look_category_2.jpg";
import main_cate_03 from "../image/look_category_3.jpg";
import main_cate_04 from "../image/look_category_4.jpg";
import Icon_quick_3d from "../image/Icon_quick_3d.svg";
import main_bnr_01_kr from "../image/rolling_banner_01.jpg";
import main_bnr_02_kr from "../image/rolling_banner_02.jpg";
import main_bnr_03_kr from "../image/rolling_banner_03.jpg";
import main_bnr_01_en from "../image/rolling_banner_01.en.jpg";
import main_bnr_02_en from "../image/rolling_banner_02.en.jpg";
import main_bnr_03_en from "../image/rolling_banner_03.en.jpg";
import country_kr from "../image/country.kr.png";
import country_en from "../image/country.en.png";

import arrowleft_w from "../image/arrow-left_w.svg";
import arrowright from "../image/arrow-right.svg";

import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import APIHelper from "../helpers/APIHelper";
import Constants from "../constants/constants";
import CommonLib from "../libraries/CommonLib";
import SwiperCore, {Autoplay, Navigation} from "swiper";
import {useTranslation} from "react-i18next";
import {changeLanguage} from "i18next";
import $ from "jquery";
import GoogleTranslate from "./GoogleTranslate";

const Home = (props) => {
  const path = window.location.pathname;
  const location = useLocation();
  const ctx = useContext(AuthContext);
  const history = useHistory();
  const {t} = useTranslation();
  const {i18n} = useTranslation('home');

  const [oemStories, setOemStories] = useState([]);
  const [rcmdProducts, setRcmdProducts] = useState([]);

  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [language, setLanguage] = useState('en');

  const [statuses, setStatuses] = useState({});
  const [homeBanners, setHomeBanners] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      if (
        localStorage.getItem("user.session.email")?.length > 0 &&
        ctx.isUserLoggedIn
      )
        await getStatuses();
    };
    fetch();
  }, [new Date().getTime()]);

  const getStatuses = () => {
    return new Promise(async (resolve, reject) => {
      const {
        data: {code, result, message},
      } = await APIHelper.get("project/task/cnt");
      if (code === 200) {
        setStatuses(result);
      }
      return resolve(true);
    });
  };

  const [searchDv, setSearchDv] = useState("product");
  const [searchKeyword, setSearchKeyword] = useState("");
  const search = () => {
    history.push({
      pathname: "/search",
      state: {dv: searchDv, searchKeyword},
    });
  };
  const handleSearchKeyDown = async (e) => {
    if (e.key === "Enter") {
      search();
    }
  };

  const getRcmdProducts = () => {
    return new Promise(async (resolve, reject) => {
      const {
        data: {code, result, message},
      } = await APIHelper.getForm("data/product/gets", {
        limit: 4,
        dv: "recommend",
        orderBy: "RAND()",
      });
      if (code === 200) {
        setRcmdProducts(result);
      }
      return resolve(true);
    });
  };

  const viewRcmdProductDetail = (item) => {
    if (item.maker?.maker_type === "MF") {
      history.push({
        pathname: "/search",
        state: {dv: "productDetail", searchKeyword: "", detailId: item.id},
      });
    } else if (item.maker?.maker_type === "BT") {
      history.push({
        pathname: "/search",
        state: {dv: "bottleDetail", searchKeyword: "", detailId: item.id},
      });
    } else if (item.maker?.maker_type === "BX") {
      history.push({
        pathname: "/search",
        state: {dv: "boxDetail", searchKeyword: "", detailId: item.id},
      });
    }
  };

  const getHomeBanners = async () => {
    const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("data/home/banners");
    setHomeBanners(result);
  };

  useEffect(() => {
    const fetch = async () => {
      await getHomeBanners();
      await getOemStories();
      await getRcmdProducts();
    };
    fetch();
  }, []);

  const getOemStories = (autoSelect) => {
    return new Promise(async (resolve, reject) => {
      const {
        data: {code, result, message},
      } = await APIHelper.getForm("data/oemstory/gets");
      setOemStories(result);
      return resolve(true);
    });
  };

  const inquiry = async () => {
    if (!localStorage.getItem("user.session.email")) {
      await CommonLib.jalert('로그인이 필요합니다.');
      return;
    }
    history.push({
      pathname: '/cscenter/inquiry'
    })
  }

  const changeLang = async (ln) => {
    localStorage.setItem("lang", ln);
    await changeLanguage(ln);
    setLanguage(ln);

    if (ln === 'kr') {
      GoogleTranslate.googleTranslateToOrigin();
    } else {
      const lang = '영어';
      const frame = $('.goog-te-menu-frame:first');
      frame.contents().find('.goog-te-menu2-item span.text:contains(' + lang + ')').get(0).click();
      frame.contents().find('.goog-te-menu2-item span.text:contains(' + lang + ')').get(0).click();
    }
  }

  const sectionOemRef = useRef(null);
  const sectionRecommendRef = useRef(null);
  const sectionCategoryRef = useRef(null);
  const sectionSimulatorRef = useRef(null);

  // 퀵 메뉴
  let quickWidth;
  const [quickMenu, setQuickMenu] = useState(true);
  if (quickMenu) {
    quickWidth = "135px";
  } else {
    quickWidth = "0px";
  }

  // 슬라이드 버튼 Ref
  const nextBtn = useRef(null);
  const prevBtn = useRef(null);

  // 슬라이드 설정
  SwiperCore.use([Navigation, Autoplay]);

  const [unread, setUnread] = useState(0);
  const [projectUnread, setProjectUnread] = useState(0);
  const [requestUnread, setRequestUnread] = useState(0);
  const [doneUnread, setDoneUnread] = useState(0);

  const getUnreads = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {result: result1}} = await APIHelper.getForm("user/message/unread/check");
      const {data: {result: result2}} = await APIHelper.getForm("user/oem/proposal/unread/check");

      const _unreads = result1 + result2;

      setUnread(_unreads);

      let _projectUnread = 0;
      let _requestUnread = 0;
      let _doneUnread = 0;

      if (localStorage.getItem("user.session.user_type")?.length > 0) {
        try {
          {
            const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/quickmessage", {type: localStorage.getItem("user.session.user_type")});
            if (code === 200 && result?.id > 0)
              _projectUnread = 1;
            setProjectUnread(_projectUnread);
          }
        } catch (err) {
        }
        try {
          if (localStorage.getItem("user.session.user_type") === "MAKER") {
            const {data: {code, result, message}} = await APIHelper.getForm("user/maker/request/unread/check");
            if (code === 200 && parseInt(result, 10) > 0)
              _requestUnread = 1;
            setRequestUnread(_requestUnread);
          }
        } catch (err) {
        }
        try {
          const {data: {code, result, message}} = await APIHelper.getForm("project/done/unreads/cnt");
          if (code === 200 && parseInt(result, 10) > 0)
            _doneUnread = 1;
          setDoneUnread(_doneUnread);
        } catch (err) {
          console.log(err);
        }
      }

      return resolve({unreads: _unreads, projectUnread: _projectUnread, requestUnread: _requestUnread, doneUnread: _doneUnread});
    });
  };

  let intvalProjectUnreads = null;
  let intvalRequestUnreads = null;
  let intvalDoneUnreads = null;
  useEffect(() => {
    const fetch = async () => {
      const {unreads, projectUnread, requestUnread, doneUnread} = await getUnreads();
      if (projectUnread > 0) {
        intvalProjectUnreads = setInterval(async function () {
          if ($('.project-progress').hasClass('bg-orange')) $('.project-progress').removeClass('bg-orange');
          else $('.project-progress').addClass('bg-orange');
        }, 1000);
      }
      if (requestUnread > 0) {
        intvalRequestUnreads = setInterval(async function () {
          if ($('.request-progress').hasClass('bg-orange')) $('.request-progress').removeClass('bg-orange');
          else $('.request-progress').addClass('bg-orange');
        }, 1000);
      }
      if (doneUnread > 0) {
        intvalDoneUnreads = setInterval(async function () {
          if ($('.project-done').hasClass('bg-orange')) $('.project-done').removeClass('bg-orange');
          else $('.project-done').addClass('bg-orange');
        }, 1000);
      }
    }
    fetch();
    return () => {
      if (intvalProjectUnreads) clearInterval(intvalProjectUnreads);
      if (intvalRequestUnreads) clearInterval(intvalRequestUnreads);
      if (intvalDoneUnreads) clearInterval(intvalDoneUnreads);
    }
  }, []);

  return (
    <body style={{background: "#f9f1f3 !important", height: "auto", minWidth: "1450px"}}>
    <div id="main_bnr">
      <div
        className="MAX w-100 d-flex justify-content-between p_absolute"
        style={{
          top: "0",
          left: "50%",
          transform: "translate(-50%, 0)",
          zIndex: "100",
          marginTop: "10px",
        }}
      >
        <p className="mt-3 pl-3 text-left float-left">
          <a href="#">
            <img src={logo} style={{width: "140px"}}/>
          </a>
        </p>
        <ul
          id="sidebarMenu"
          className="TOP_menu"
          style={{marginTop: "4px", marginLeft: "8px"}}
        >
          {((localStorage.getItem("user.session.user_type") || '') === 'BUYER' || (localStorage.getItem("user.session.user_type") || '') === '') &&
            <li>
              <Link
                onClick={() => {
                  localStorage.setItem("manufacture.result", JSON.stringify([]));
                  history.push('/manufacture');
                }}
                style={{cursor: 'pointer'}}
                className={`${path.startsWith("/manufacture") === true ? "ON" : ""}`}
              >
                {t('navbar.production_inquiry')}
              </Link>
            </li>
          }
          {/*2023.08.09 수정*/}
          {/*{(localStorage.getItem("user.session.user_type") || '') !== 'BUYER' &&*/}
          {/*  <li>*/}
          {/*    <Link*/}
          {/*      to="/buyer/find"*/}
          {/*      className={`${path.startsWith("/buyer/") === true ? "ON" : ""}`}*/}
          {/*    >*/}
          {/*      {t('navbar.find_buyer')}*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*}*/}
          {/*{(localStorage.getItem("user.session.user_type") || '') !== 'MAKER' &&*/}
          {/*  <li>*/}
          {/*    <Link*/}
          {/*      to="/maker/find"*/}
          {/*      className={`${path.startsWith("/maker/") === true ? "ON" : ""}`}*/}
          {/*    >*/}
          {/*      {t('navbar.find_manufacturer')}*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*}*/}
          <li>
            <Link
              to="/search"
              className={`${path.includes("search") === true ? "ON" : ""}`}
            >
              {t('navbar.dictionary')}
            </Link>
          </li>
        </ul>
        <ul
          id="sidebarMenu"
          className="TOP_menu_second"
          style={{paddingTop: "2px"}}
        >
          {!ctx.isUserLoggedIn && (
            <>
              <li>
                <Link to="/login">{t('navbar.login')}</Link>
              </li>
              <li>
                <Link onClick={ctx.onLogout} to="/join/intro">
                  {t('navbar.join')}
                </Link>
              </li>
            </>
          )}
          {ctx.isUserLoggedIn && (
            <>
              <li onMouseEnter={() => setShowInfoPopup(false)}>
                {localStorage.getItem("user.session.user_type") ===
                "MAKER" ? (
                  <Link to="/project/maker/request">{t('navbar.project')}</Link>
                ) : (
                  <Link to="/project/buyer/task/progress">{t('navbar.project')}</Link>
                )}
                {CommonLib.parseInt(projectUnread) > 0 && <div className="new-icon3" style={{marginLeft: "45px"}}>N</div>}
              </li>
              <li onMouseEnter={() => setShowInfoPopup(true)}>
                <Link to="/mypage/home">
                  {t('navbar.mypage')}
                  {CommonLib.parseInt(unread) > 0 && <div className="new-icon3" style={{marginLeft: "57px"}}>N</div>}
                </Link>
                {localStorage.getItem("user.session.email")?.length > 0 &&
                  ctx.isUserLoggedIn &&
                  showInfoPopup && (
                    <div
                      className="p_absolute"
                      style={{top: "55px", right: "10px"}}
                    >
                      <div
                        className="myinfo_box"
                        style={{width: "290px", minHeight: "200px"}}
                      >
                        <p className="myinfo_title">내정보 보기</p>
                        <div className="mx-2">
                          <div className="d-flex justify-content-between border-bottom p-2">
                            <Link to="/mypage/home">
                              <p className="py-1 bold">My Home</p>
                            </Link>
                            <div
                              className="btn-group"
                              role="group"
                              aria-label="Basic outlined example"
                            >
                              {/*<Link to="/mypage/alarm">*/}
                              {/*  <button*/}
                              {/*    type="button"*/}
                              {/*    className="btn btn-sm p-0 px-3 btn-outline-light hov_pup"*/}
                              {/*  >*/}
                              {/*    알림*/}
                              {/*  </button>*/}
                              {/*</Link>*/}
                              <button
                                type="button"
                                className="btn btn-sm p-0 px-3 btn-outline-light hov_pup"
                                onClick={() => inquiry()}
                              >
                                1:1문의
                              </button>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between border-bottom p-2">
                            <Link
                              to={localStorage.getItem("user.session.user_type") === "BUYER" ? "/profile/buyer" : "/profile/maker"}
                            >
                              <p className="py-2 bold">프로필 관리</p>
                            </Link>
                          </div>
                          <div className="border-bottom p-2">
                            <p
                              className="py-2 bold"
                              style={{textAlign: "left"}}
                            >
                              프로젝트 관리
                            </p>
                            <div
                              className="btn-group w-100"
                              role="group"
                              aria-label="Basic outlined example"
                            >
                              {localStorage.getItem(
                                "user.session.user_type"
                              ) === "MAKER" && (
                                <Link
                                  to="/project/maker/request"
                                  className="btn btn-sm p-1 btn-outline-light hov_pup request-progress"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-sm"
                                  >
                                    요청
                                    <br/>({statuses?.request}건)
                                  </button>
                                </Link>
                              )}
                              <Link
                                to={
                                  localStorage.getItem(
                                    "user.session.user_type"
                                  ) === "BUYER"
                                    ? "/project/buyer/task/progress"
                                    : "/project/maker/task/progress"
                                }
                                className="btn btn-sm p-1 btn-outline-light hov_pup project-progress"
                              >
                                <button type="button" className="btn btn-sm">
                                  진행중
                                  <br/>({statuses?.progress}건)
                                </button>
                              </Link>
                              <Link
                                to={localStorage.getItem("user.session.user_type") === "BUYER" ? "/project/buyer/task/done" : "/project/maker/task/done"}
                                className="btn btn-sm p-1 btn-outline-light hov_pup project-done"
                              >
                                <button type="button" className="btn btn-sm">
                                  완료
                                  <br/>({statuses?.done}건)
                                </button>
                              </Link>
                            </div>
                          </div>
                          {/*<div className="d-flex justify-content-between p-2">*/}
                          {/*  <p className="py-1 bold">관심 업체</p>*/}
                          {/*  <button*/}
                          {/*    type="button"*/}
                          {/*    className="btn btn-sm p-0 px-3 btn-outline-light hov_pup"*/}
                          {/*  >*/}
                          {/*    관심 15건*/}
                          {/*  </button>*/}
                          {/*</div>*/}
                        </div>
                      </div>
                    </div>
                  )}
              </li>
              <li onMouseEnter={() => setShowInfoPopup(false)}>
                <Link onClick={ctx.onUserLogout} to="/login">
                  {t('navbar.logout')}
                </Link>
              </li>
            </>
          )}
          <li onMouseEnter={() => setShowInfoPopup(false)} style={{border: 0}}>
            <img src={country_kr} style={{width: "20px", borderStyle: "solid", borderWidth: "1px", borderColor: "#afafaf", cursor: "pointer"}} onClick={() => changeLang('kr')}/>
            <img src={country_en} style={{width: "20px", marginLeft: "5px", borderStyle: "solid", borderWidth: "1px", borderColor: "#afafaf", cursor: "pointer"}} onClick={() => changeLang('en')}/>
          </li>
        </ul>
      </div>
      {" "}
      <div className="MAX w-100 p_rative" style={{zIndex: "1234567890"}}>
        <p className="p_absolute" style={{top: "550px", right: "0"}}>
          <button
            ref={prevBtn}
            type="button"
            className="btn border_gray_2 p-2 px-3 m-1 next"
          >
            <img src={arrowleft_w} style={{width: "15px"}}/>
          </button>
          <button
            ref={nextBtn}
            type="button"
            className="btn border_gray_2 p-2 px-3 m-1 prev"
          >
            <img src={arrowright} style={{width: "15px"}}/>
          </button>
        </p>
      </div>
      <Swiper
        autoplay={true}
        loop={true}
        navigation={{
          nextEl: nextBtn.current,
          prevEl: prevBtn.current,
        }}
        onBeforeInit={(e) => {
          e.params.navigation.nextEl = nextBtn.current;
          e.params.navigation.prevEl = prevBtn.current;
        }}
      >
        {/*<SwiperSlide>*/}
        {/*  <Link to="/maker/find">*/}
        {/*    <div*/}
        {/*      onMouseEnter={() => setShowInfoPopup(false)}*/}
        {/*      className="w-100"*/}
        {/*      style={{*/}
        {/*        background: `url(${language === 'kr' ? main_bnr_01_kr : main_bnr_01_en}) center center/cover`,*/}
        {/*        backgroundSize: "cover",*/}
        {/*        height: "700px",*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </Link>*/}
        {/*</SwiperSlide>*/}
        {/*<SwiperSlide>*/}
        {/*  <Link to="/search">*/}
        {/*    <div*/}
        {/*      className="w-100"*/}
        {/*      style={{*/}
        {/*        background: `url(${language === 'kr' ? main_bnr_02_kr : main_bnr_02_en}) center center/cover`,*/}
        {/*        backgroundSize: "cover",*/}
        {/*        height: "700px",*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </Link>*/}
        {/*</SwiperSlide>*/}
        {/*<SwiperSlide>*/}
        {/*  <Link to="/search/bottle">*/}
        {/*    <div*/}
        {/*      className="w-100"*/}
        {/*      style={{*/}
        {/*        background: `url(${language === 'kr' ? main_bnr_03_kr : main_bnr_03_en}) center center/cover`,*/}
        {/*        backgroundSize: "cover",*/}
        {/*        height: "700px",*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </Link>*/}
        {/*</SwiperSlide>*/}
        {homeBanners?.home_banner_1_fid && homeBanners?.home_banner_1_en_fid &&
          <SwiperSlide>
            <div
              className="w-100"
              style={{
                background: `url(${Constants.API_HOST}/common/download?fid=${language === 'kr' ? homeBanners?.home_banner_1_fid : homeBanners?.home_banner_1_en_fid}&cd=inline) center center/cover`,
                backgroundSize: "cover",
                height: "700px",
              }}
            />
          </SwiperSlide>
        }
        {homeBanners?.home_banner_2_fid && homeBanners?.home_banner_2_en_fid &&
          <SwiperSlide>
            <div
              className="w-100"
              style={{
                background: `url(${Constants.API_HOST}/common/download?fid=${language === 'kr' ? homeBanners?.home_banner_2_fid : homeBanners?.home_banner_2_en_fid}&cd=inline) center center/cover`,
                backgroundSize: "cover",
                height: "700px",
              }}
            />
          </SwiperSlide>
        }
        {homeBanners?.home_banner_3_fid && homeBanners?.home_banner_3_en_fid &&
          <SwiperSlide>
            <div
              className="w-100"
              style={{
                background: `url(${Constants.API_HOST}/common/download?fid=${language === 'kr' ? homeBanners?.home_banner_3_fid : homeBanners?.home_banner_3_en_fid}&cd=inline) center center/cover`,
                backgroundSize: "cover",
                height: "700px",
              }}
            />
          </SwiperSlide>
        }
        {homeBanners?.home_banner_4_fid && homeBanners?.home_banner_4_en_fid &&
          <SwiperSlide>
            <div
              className="w-100"
              style={{
                background: `url(${Constants.API_HOST}/common/download?fid=${language === 'kr' ? homeBanners?.home_banner_4_fid : homeBanners?.home_banner_4_en_fid}&cd=inline) center center/cover`,
                backgroundSize: "cover",
                height: "700px",
              }}
            />
          </SwiperSlide>
        }
        {homeBanners?.home_banner_5_fid && homeBanners?.home_banner_5_en_fid &&
          <SwiperSlide>
            <div
              className="w-100"
              style={{
                background: `url(${Constants.API_HOST}/common/download?fid=${language === 'kr' ? homeBanners?.home_banner_5_fid : homeBanners?.home_banner_5_en_fid}&cd=inline) center center/cover`,
                backgroundSize: "cover",
                height: "700px",
              }}
            />
          </SwiperSlide>
        }
        {homeBanners?.home_banner_6_fid && homeBanners?.home_banner_6_en_fid &&
          <SwiperSlide>
            <div
              className="w-100"
              style={{
                background: `url(${Constants.API_HOST}/common/download?fid=${language === 'kr' ? homeBanners?.home_banner_6_fid : homeBanners?.home_banner_6_en_fid}&cd=inline) center center/cover`,
                backgroundSize: "cover",
                height: "700px",
              }}
            />
          </SwiperSlide>
        }
        {homeBanners?.home_banner_7_fid && homeBanners?.home_banner_7_en_fid &&
          <SwiperSlide>
            <div
              className="w-100"
              style={{
                background: `url(${Constants.API_HOST}/common/download?fid=${language === 'kr' ? homeBanners?.home_banner_7_fid : homeBanners?.home_banner_7_en_fid}&cd=inline) center center/cover`,
                backgroundSize: "cover",
                height: "700px",
              }}
            />
          </SwiperSlide>
        }
        {homeBanners?.home_banner_8_fid && homeBanners?.home_banner_8_en_fid &&
          <SwiperSlide>
            <div
              className="w-100"
              style={{
                background: `url(${Constants.API_HOST}/common/download?fid=${language === 'kr' ? homeBanners?.home_banner_8_fid : homeBanners?.home_banner_8_en_fid}&cd=inline) center center/cover`,
                backgroundSize: "cover",
                height: "700px",
              }}
            />
          </SwiperSlide>
        }
        {homeBanners?.home_banner_9_fid && homeBanners?.home_banner_9_en_fid &&
          <SwiperSlide>
            <div
              className="w-100"
              style={{
                background: `url(${Constants.API_HOST}/common/download?fid=${language === 'kr' ? homeBanners?.home_banner_9_fid : homeBanners?.home_banner_9_en_fid}&cd=inline) center center/cover`,
                backgroundSize: "cover",
                height: "700px",
              }}
            />
          </SwiperSlide>
        }
        {homeBanners?.home_banner_10_fid && homeBanners?.home_banner_10_en_fid &&
          <SwiperSlide>
            <div
              className="w-100"
              style={{
                background: `url(${Constants.API_HOST}/common/download?fid=${language === 'kr' ? homeBanners?.home_banner_10_fid : homeBanners?.home_banner_10_en_fid}&cd=inline) center center/cover`,
                backgroundSize: "cover",
                height: "700px",
              }}
            />
          </SwiperSlide>
        }
      </Swiper>
    </div>

    <div className="QUICKMENU" style={{display: "none"}}>
      <div className="QUICK_box" style={{width: quickWidth}}>
        <p className="quick_x">
          <button
            type="button"
            className="btn btn-sm"
            onClick={() => setQuickMenu(!quickMenu)}
          >
            {quickMenu ? "X" : "<"}
          </button>
        </p>
        <p className="quick_title">QUICKMENU</p>
        <ul className="m-2 mx-3 p-0">
          <li
            className="border-bottom p-2 text-center"
            onClick={() => sectionOemRef.current.scrollIntoView()}
          >
            <img src={Icon_quick_history} style={{width: "30px"}}/>
            <p className="py-1">OEM 스토리</p>
          </li>
          <li
            className="border-bottom p-2 text-center"
            onClick={() => sectionRecommendRef.current.scrollIntoView()}
          >
            <img src={icon_quick_recomend} style={{width: "35px"}}/>
            <p className="py-1">
              제조사
              <br/>
              추천 상품
            </p>
          </li>
          <li
            className="border-bottom p-2 text-center"
            onClick={() => sectionCategoryRef.current.scrollIntoView()}
          >
            <img src={icon_quick_category} style={{width: "35px"}}/>
            <p className="py-1">
              카테고리
              <br/>
              둘러보기
            </p>
          </li>
          <li
            className="p-2 text-center"
            onClick={() => sectionSimulatorRef.current.scrollIntoView()}
          >
            <img src={Icon_quick_3d} style={{width: "30px"}}/>
            <p className="py-1">3D 시뮬레이터</p>
          </li>
        </ul>

        <div className="quick_gotop text-center">TOP</div>
      </div>
    </div>

    <nav
      id="GNB_MENU"
      className="navbar sticky-top p-0 w-100"
      style={{position: "fixed", top: "0", zIndex: "12"}}
    >
      <div className="MAX w-100 d-flex justify-content-between">
        <p className="mt-3 pl-3 text-left float-left">
          <a href="#">
            <img src={logo} style={{width: "140px"}}/>
          </a>
        </p>
        <ul id="sidebarMenu" className="TOP_menu"
            style={{marginTop: "4px", marginLeft: "8px"}}
        >
          {((localStorage.getItem("user.session.user_type") || '') === 'BUYER' || (localStorage.getItem("user.session.user_type") || '') === '') &&
            <li>
              <Link
                onClick={() => {
                  localStorage.setItem("manufacture.result", JSON.stringify([]));
                  history.push('/manufacture');
                }}
                style={{cursor: 'pointer'}}
                className={`${path.startsWith("/manufacture") === true ? "ON" : ""}`}
              >
                {t('navbar.production_inquiry')}
              </Link>
            </li>
          }
          {/*2023.08.09 수정*/}
          {/*{(localStorage.getItem("user.session.user_type") || '') !== 'BUYER' &&*/}
          {/*  <li>*/}
          {/*    <Link*/}
          {/*      to="/buyer/find"*/}
          {/*      className={`${path.startsWith("/buyer/") === true ? "ON" : ""}`}*/}
          {/*    >*/}
          {/*      {t('navbar.find_buyer')}*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*}*/}
          {/*{(localStorage.getItem("user.session.user_type") || '') !== 'MAKER' &&*/}
          {/*  <li>*/}
          {/*    <Link*/}
          {/*      to="/maker/find"*/}
          {/*      className={`${path.startsWith("/maker/") === true ? "ON" : ""}`}*/}
          {/*    >*/}
          {/*      {t('navbar.find_manufacturer')}*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*}*/}
          {/*
          <li>
            <a href="#">이용가이드 & News</a>
          </li>
         */}
          {/*<li>*/}
          {/*  <Link*/}
          {/*    to="/cscenter/notice"*/}
          {/*    className={`${path.includes("cscenter") === true ? "ON" : ""}`}*/}
          {/*  >*/}
          {/*    고객센터*/}
          {/*  </Link>*/}
          {/*</li>*/}
          <li>
            <Link
              to="/search"
              className={`${path.includes("search") === true ? "ON" : ""}`}
            >
              {t('navbar.dictionary')}
            </Link>
          </li>
        </ul>
        <ul id="sidebarMenu" className="TOP_menu_second">
          {!ctx.isUserLoggedIn && (
            <>
              <li>
                <Link to="/login">{t('navbar.login')}</Link>
              </li>
              <li>
                <Link onClick={ctx.onLogout} to="/join/intro">
                  {t('navbar.join')}
                </Link>
              </li>
            </>
          )}
          {ctx.isUserLoggedIn && (
            <>
              <li onMouseEnter={() => setShowInfoPopup(false)}>
                {localStorage.getItem("user.session.user_type") === "MAKER" ?
                  <Link to="/project/maker/request">{t('navbar.project')}</Link>
                  :
                  <Link to="/project/buyer/task/progress">{t('navbar.project')}</Link>
                }
                {CommonLib.parseInt(projectUnread) > 0 && <div className="new-icon3" style={{top: "26px", marginLeft: "45px"}}>N</div>}
              </li>
              <li onMouseEnter={() => setShowInfoPopup(true)}>
                <Link to="/mypage/home">
                  {t('navbar.mypage')}
                  {CommonLib.parseInt(unread) > 0 && <div className="new-icon3" style={{top: "26px", marginLeft: "57px"}}>N</div>}
                </Link>
              </li>
              <li onMouseEnter={() => setShowInfoPopup(false)}>
                <Link onClick={ctx.onLogout} to="/login">
                  {t('navbar.logout')}
                </Link>
              </li>
            </>
          )}
          <li onMouseEnter={() => setShowInfoPopup(false)} style={{border: 0}}>
            {/*<GoogleTranslate/>*/}
            <img src={country_kr} style={{width: "20px", borderStyle: "solid", borderWidth: "1px", borderColor: "#afafaf", cursor: "pointer"}} onClick={() => changeLang('kr')}/>
            <img src={country_en} style={{width: "20px", marginLeft: "5px", borderStyle: "solid", borderWidth: "1px", borderColor: "#afafaf", cursor: "pointer"}} onClick={() => changeLang('en')}/>
          </li>
        </ul>
      </div>
    </nav>

    <div
      className="MAX bg-purple box_round_5"
      style={{
        height: "105px",
        marginTop: "-70px",
        zIndex: "123456",
        position: "relative",
      }}
    >
      <div className="d-flex" style={{padding: "20px 100px 0 100px"}}>
        <div className="mx-1">
          <select
            className="form-select"
            aria-label=".form-select-lg example"
            style={{height: "50px", width: "200px"}}
            onChange={async (e) => await setSearchDv(e.target.value)}
            value={searchDv}
          >
            <option selected={searchDv === "ingredient"} value="ingredient">
              {t('home.search.option1')}
            </option>
            <option selected={searchDv === "product"} value="product">
              {t('home.search.option2')}
            </option>
            <option selected={searchDv === "bottle"} value="bottle">
              {t('home.search.option3')}
            </option>
            <option selected={searchDv === "box"} value="box">
              {t('home.search.option4')}
            </option>
          </select>
        </div>

        <div className="input-group mx-1">
          <input
            type="text"
            className="form-control border_none"
            placeholder={t('home.search.placeholder')}
            onChange={async (e) => await setSearchKeyword(e.target.value)}
            value={searchKeyword}
            onKeyDown={handleSearchKeyDown}
          />
          <button
            className="btn btn-lg btn_darkpup px-5"
            type="button"
            id="button-addon2"
            onClick={() => search()}
          >
            {t('home.search.button')}
          </button>
        </div>
      </div>

      {/*<div className="d-flex" style={{padding: "10px 100px"}}>*/}
      {/*  <p style={{height: "26px", width: "220px"}}></p>*/}
      {/*  <p className="box_round_30 border_white px-3 mx-1">스킨케어</p>*/}
      {/*  <p className="box_round_30 border_white px-3 mx-1">스킨케어</p>*/}
      {/*  <p className="box_round_30 border_white px-3 mx-1">스킨케어</p>*/}
      {/*</div>*/}
    </div>

    <div className="py-5" style={{background: "#f9f1f3 !important"}}>
      <div className="MAX">
        <div className="my-4" ref={sectionOemRef}>
          <div className="w-100 text-center" style={{margin: "0 auto"}}>
            <p className="h3 txt-point bold">{t('home.oem.title')}</p>
            <p className="txt-dark h6">{t('home.oem.sub_title')}</p>
          </div>

          {/*<div className="d-flex justify-content-center my-3">*/}
          {/*  <p className="box_round_30 border bg-white px-4 p-1 mx-1">*/}
          {/*    스킨케어*/}
          {/*  </p>*/}
          {/*  <p className="box_round_30 border bg-white px-4 p-1 mx-1">로션</p>*/}
          {/*  <p className="box_round_30 border bg-white px-4 p-1 mx-1">미백</p>*/}
          {/*  <p className="box_round_30 border bg-white px-4 p-1 mx-1">보습</p>*/}
          {/*  <p className="box_round_30 border bg-white px-4 p-1 mx-1">미백</p>*/}
          {/*</div>*/}

          <div className="row row-cols-3 gx-5 my-5 p_rative">
            <div className="w-100">
              <div
                className="p_absolute"
                style={{left: "-45px", top: "140px"}}
              >
                <button ref={prevBtn} type="button" className="btn prev">
                  <img src={arrowleft_w} style={{width: "20px"}}/>
                </button>
              </div>
              <div
                className="p_absolute"
                style={{right: "-45px", top: "140px"}}
              >
                <button ref={nextBtn} type="button" className="btn next">
                  <img src={arrowright} style={{width: "20px"}}/>
                </button>
              </div>
            </div>
            <Swiper
              style={{
                width: "90%",
              }}
              slidesPerView={3}
              spaceBetween={30}
              loop={true}
              navigation={{
                nextEl: nextBtn.current,
                prevEl: prevBtn.current,
              }}
              onBeforeInit={(e) => {
                e.params.navigation.nextEl = nextBtn.current;
                e.params.navigation.prevEl = prevBtn.current;
              }}
            >
              {oemStories.length > 0 &&
                oemStories.map((item, k) => (
                  <SwiperSlide key={k} className="col">
                    <Link to={`/oem/detail/${item.id}`}>
                      <div className="bg-white">
                        <p>
                          {item?.image1_fid && item?.image1_fid.length > 0 ? (
                            <img
                              src={`${Constants.API_HOST}/common/download?fid=${item?.image1_fid}&cd=inline&thumb=400`}
                              style={{
                                width: "377px",
                                height: "194px",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <img
                              src={process.env.PUBLIC_URL + "/images/no_image.png"}
                              style={{width: "377px", height: "194px", objectFit: "cover"}}
                            />
                          )}
                        </p>
                        <div
                          className="p-3 p_rative"
                          style={{minHeight: "130px"}}
                        >
                          <div
                            className="p_absolute"
                            style={{top: "-30px", right: "20px"}}
                          >
                            <div
                              className="btn_oval bg_main_03 txt-sm bold"
                              style={{
                                padding: "12px 0",
                                lineHeight: "120%",
                              }}
                            >
                              {CommonLib.escapedSpaceToLineBreakTag(
                                item?.category?.name_en
                              )}
                            </div>
                          </div>
                          <p className="h5 bold">{item?.name}</p>
                          <p>
                            {item?.category?.name_en} {">"}{" "}
                            {item?.product?.name_en}
                          </p>
                          <div
                            className="p_absolute"
                            style={{bottom: "15px", right: "20px"}}
                          >
                            <p className="txt-sm txt-gray bold">
                              MOQ : {item?.moq} | Unit Price :{" "}
                              {item?.unit_price}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>

        <br/>

        <div className="mt-5" ref={sectionRecommendRef}>
          <div className="w-100 text-center">
            <p className="h3 txt-point bold">{t('home.recommend.title')}</p>
            <p className="txt-dark h6">{t('home.recommend.sub_title')}</p>
          </div>
          {/*<div className="w-100 mt-4" style={{textAlign: "right"}}>*/}
          {/*  <a href="">+ 더보기</a>*/}
          {/*</div>*/}

          <div className="row row-cols-4 gx-5 mt-4 mb-5">
            {rcmdProducts.length > 0 &&
              rcmdProducts.map((item, key) => (
                <div
                  className="col"
                  onClick={() => viewRcmdProductDetail(item)}
                  style={{cursor: "pointer"}}
                >
                  <div className="bg-white">
                    <p>
                      {item?.dtls.filter((i) => i.image_fid !== "").length >
                        0 && (
                          <img
                            src={`${Constants.API_HOST}/common/download?fid=${
                              item?.dtls.filter((i) => i.image_fid !== "")[0]
                                ?.image_fid
                            }&cd=inline&thumb=400`}
                            style={{height: "165px", objectFit: "cover"}}
                          />
                        )}
                    </p>
                    <p className="bg_main_03 bold text-center p-1">
                      {item?.category?.name_en} {">"} {item?.product?.name_en}
                    </p>
                    <div
                      className="p-3 p_rative"
                      style={{minHeight: "110px"}}
                    >
                      {item?.name}
                      <br/>
                      {item?.dtls.filter((i) => i.content.length > 0).length >
                        0 && (
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "block",
                              "line-height": "normal",
                              "-webkit-line-clamp": "2",
                              "-webkit-box-orient": "vertical",
                              "-moz-line-clamp": "2",
                              maxHeight: "40px",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: item?.dtls.filter(
                                (i) => i.content.length > 0
                              )[0].content,
                            }}
                          />
                        )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>

    <div className="bg-white py-5">
      <div className="MAX">
        <div className="mt-5" ref={sectionCategoryRef}>
          <div className="w-100 text-center">
            <p className="h3 txt-point bold">{t('home.category.title')}</p>
            <p className="txt-dark h6">{t('home.category.sub_title')}</p>
          </div>

          <div className="row row-cols-4 gx-5 mt-4 mb-5">
            <div className="col">
              <Link
                // to="/search"
                onClick={() => CommonLib.jalert('To be released.')}
              >
                <div className="bg_main_01">
                  <p>
                    <img src={main_cate_01}/>
                  </p>
                  <div
                    className="p-3 p_rative"
                    style={{minHeight: "130px"}}
                  >
                    <div
                      className="p_absolute"
                      style={{top: "15px", right: "20px"}}
                    >
                      <p>
                        <button type="button" className="btn p-0">
                          <img src={arrowright_w}/>
                        </button>
                      </p>
                    </div>
                    <p className="h5 bold">{t('home.category.ingredient')}</p>
                    <p className="my-3 home_category_desc">
                      {t('home.category.ingredient.desc')}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link
                // to="/search/product"
                onClick={() => CommonLib.jalert('To be released.')}
              >
                <div className="bg_main_02">
                  <p>
                    <img src={main_cate_02}/>
                  </p>
                  <div
                    className="p-3 p_rative"
                    style={{minHeight: "130px"}}
                  >
                    <div
                      className="p_absolute"
                      style={{top: "15px", right: "20px"}}
                    >
                      <p>
                        <button type="button" className="btn p-0">
                          <img src={arrowright_w}/>
                        </button>
                      </p>
                    </div>
                    <p className="h5 bold">{t('home.category.product')}</p>
                    <p className="my-3 home_category_desc">
                      {t('home.category.product.desc')}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link
                // to="/search/bottle"
                onClick={() => CommonLib.jalert('To be released.')}
              >
                <div className="bg_main_03">
                  <p>
                    <img src={main_cate_03}/>
                  </p>
                  <div
                    className="p-3 p_rative"
                    style={{minHeight: "130px"}}
                  >
                    <div
                      className="p_absolute"
                      style={{top: "15px", right: "20px"}}
                    >
                      <p>
                        <button type="button" className="btn p-0">
                          <img src={arrowright_w}/>
                        </button>
                      </p>
                    </div>
                    <p className="h5 bold">{t('home.category.container')}</p>
                    <p className="my-3 home_category_desc">
                      {t('home.category.container.desc')}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link
                // to="/search/box"
                onClick={() => CommonLib.jalert('To be released.')}
              >
                <div className="bg_main_04">
                  <p>
                    <img src={main_cate_04}/>
                  </p>
                  <div
                    className="p-3 p_rative"
                    style={{minHeight: "130px"}}
                  >
                    <div
                      className="p_absolute"
                      style={{top: "15px", right: "20px"}}
                    >
                      <p>
                        <button type="button" className="btn p-0">
                          <img src={arrowright_w}/>
                        </button>
                      </p>
                    </div>
                    <p className="h5 bold">{t('home.category.singlebox')}</p>
                    <p className="my-3 home_category_desc">
                      {t('home.category.singlebox.desc')}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-lblue py-5">
      <div className="MAX">
        <div className="mt-5" ref={sectionSimulatorRef}>
          <div className="w-100 text-center">
            <p className="h3 txt-point bold">{t('home.simulator.title')}</p>
            <p className="txt-dark h6">{t('home.simulator.sub_title')}</p>
            <p className="py-3">
              {/*<Link to="/simulator/bottle">*/}
              <button
                type="button"
                className="btn btn-lg btn_blue2 px-4 mx-1"
                onClick={() => CommonLib.jalert('To be released.')}
              >
                {t('home.container.simulation')}
              </button>
              {/*</Link>*/}
              {/*<Link to="/simulator/box">*/}
              <button
                type="button"
                className="btn btn-lg btn-outline-gray px-4 mx-1"
                onClick={() => CommonLib.jalert('To be released.')}
              >
                {t('home.singlebox.simulation')}
              </button>
              {/*</Link>*/}
            </p>
          </div>
          <div className="my-4 p_rative">
            {/*<div className="w-100">*/}
            {/*  <div*/}
            {/*    className="p_absolute"*/}
            {/*    style={{left: "-60px", top: "48%"}}*/}
            {/*  >*/}
            {/*    <button type="button" className="btn">*/}
            {/*      <img src={arrowleft_w} style={{width: "20px"}}/>*/}
            {/*    </button>*/}
            {/*    {" "}*/}
            {/*  </div>*/}
            {/*  <div*/}
            {/*    className="p_absolute"*/}
            {/*    style={{right: "-60px", top: "48%"}}*/}
            {/*  >*/}
            {/*    <button type="button" className="btn">*/}
            {/*      <img src={arrowright} style={{width: "20px"}}/>*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="d-flex w-100">
              <div className="p_rative">
                <div
                  className="p_absolute"
                  style={{left: "0", bottom: "0"}}
                >
                  <div
                    className="p-3"
                    style={{
                      width: "255px",
                      background: "#48517e",
                      color: "#fff",
                    }}
                  >
                    <p>
                      {t('home.container.simulation.desc')}
                    </p>
                    <Link to="/simulator/bottle">
                      <div className="d-flex justify-content-between py-2">
                        <p className="h5 bold">{t('home.container.simulation')}</p>
                        {/*<p>*/}
                        {/*  <button type="button" className="btn p-0 px-3">*/}
                        {/*    <img src={arrowright_w}/>*/}
                        {/*  </button>*/}
                        {/*</p>*/}
                      </div>
                    </Link>
                  </div>
                </div>
                <img src={main_3d_01} style={{height: "500px"}}/>
              </div>
              <div className="p_rative">
                <div className="p_absolute" style={{right: "0", top: "0"}}>
                  <div className="triangle_darkgray">
                    <p
                      className="txt-white bold"
                      style={{paddingRight: "10px", paddingTop: "5px"}}
                    >
                      {t('home.simulator.360viewer')}
                    </p>
                  </div>
                </div>
                <img src={main_3d_02} style={{height: "500px"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </body>
  );
};

export default Home;
