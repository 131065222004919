import React, {useEffect, useRef, useState} from "react";
import "../../../sass/main.scss";
import DaumPostCode from "react-daum-postcode";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";

const AdminBuyerDetail = (props) => {
  const {buyer} = props;

  const licenseFileRef = useRef();
  const etc1FileRef = useRef();
  const etc2FileRef = useRef();

  // 주소검색 열고 닫기
  const [addressOpen, setAddressOpen] = useState(false);
  // 주소 state
  const [address, setAddress] = useState({
    postcode: "",
    address: "",
  });
  // 상세주소 state
  const [detailAddress, setDetailAddress] = useState("");

  // 주소검색 클릭 핸들러
  const addressClick = (e) => {
    setAddressOpen(!addressOpen);
  };

  // 주소찾기
  const postcodeHandler = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? `(${extraAddress})` : "";
    }

    setAddress({postcode: data.zonecode, address: fullAddress});
    setAddressOpen(false);
  };

  // 상세주소 핸들러
  const detailAddressHandler = (e) => {
    setDetailAddress(e.target.value);
  };

  const [company, setCompany] = useState('');
  const [marketCountry, setMarketCountry] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyTel1, setCompanyTel1] = useState('');
  const [companyTel2, setCompanyTel2] = useState('');
  const [companyTel3, setCompanyTel3] = useState('');
  const [chargerName, setChargerName] = useState('');
  const [chargerGender, setChargerGender] = useState('M');
  const [chargerEmail, setChargerEmail] = useState('');
  const [chargerTel, setChargerTel] = useState('');
  const [youtube, setYoutube] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [etcSns, setEtcSns] = useState('');
  const [licenseAttach, setLicenseAttach] = useState(null);
  const [etc1Attach, setEtc1Attach] = useState(null);
  const [etc2Attach, setEtc2Attach] = useState(null);
  const [offstoreName, setOffstoreName] = useState('');
  const [offstoreAddr, setOffstoreAddr] = useState('');
  const [onshopName, setOnshopName] = useState('');
  const [onshopUrl, setOnshopUrl] = useState('');

  const [countryCd, setCountryCd] = useState('');
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '106000'});
        if (code === 200) {
          setCountries(result);
        }
      }
      await getBuyerProfile();
    }
    fetch();
  }, [props.renew]);

  // 바이어 프로필 가져오기
  const getBuyerProfile = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("user/buyer/get", {id: buyer?.user_id});
      if (code === 200) {
        setCompany(result.company);
        setMarketCountry(result.market_country);
        setCompanyEmail(result.company_email);
        setCompanyWebsite(result.company_website);
        setAddress({postcode: result.company_zip, address: result.company_addr});
        setDetailAddress(result.company_addr_dtl);
        setCompanyTel1(result.company_tel ? result.company_tel.split('-')[0] : '');
        setCompanyTel2(result.company_tel ? result.company_tel.split('-')[1] : '');
        setCompanyTel3(result.company_tel ? result.company_tel.split('-')[2] : '');
        setChargerName(result.charger_name);
        setChargerGender(result.charger_gender || 'M');
        setChargerEmail(result.charger_email);
        setChargerTel(result.charger_tel);
        setYoutube(result.youtube);
        setFacebook(result.facebook);
        setInstagram(result.instagram);
        setTwitter(result.twitter);
        setTiktok(result.tiktok);
        setEtcSns(result.etc_sns);
        setCountryCd(result.country_cd);
        setLicenseAttach(result.license_attach);
        setEtc1Attach(result.etc1_attach);
        setEtc2Attach(result.etc2_attach);
        setOffstoreName(result.offstore_name);
        setOffstoreAddr(result.offstore_addr);
        setOnshopName(result.onshop_name);
        setOnshopUrl(result.onshop_url);
      }
      return resolve(true);
    });
  };

  const updateSubmit = (e) => {
    return new Promise(async (resolve, reject) => {
      try {
        const licenseFiles = licenseFileRef?.current?.files;
        const etc1Files = etc1FileRef?.current?.files;
        const etc2Files = etc2FileRef?.current?.files;

        const {data: {code: retCode, result, message}} = await APIHelper.putForm(`user/buyer/update/detail`, {
          company: company || '',
          marketCountry: marketCountry || '',
          countryCd: countryCd || '',
          companyEmail: companyEmail || '',
          companyWebsite: companyWebsite || '',
          companyZip: address.postcode || '',
          companyAddr: address.address || '',
          companyAddrDtl: detailAddress || '',
          companyTel1: companyTel1 || '',
          companyTel2: companyTel2 || '',
          companyTel3: companyTel3 || '',
          chargerName: chargerName || '',
          chargerGender: chargerGender || '',
          chargerEmail: chargerEmail || '',
          chargerTel: chargerTel || '',
          youtube: youtube || '',
          facebook: facebook || '',
          instagram: instagram || '',
          twitter: twitter || '',
          tiktok: tiktok || '',
          etcSns: etcSns || '',
          offstoreName: offstoreName || '',
          offstoreAddr: offstoreAddr || '',
          onshopName: onshopName || '',
          onshopUrl: onshopUrl || '',
          license: licenseFiles ? licenseFiles[0] : null,
          etc1: etc1Files ? etc1Files[0] : null,
          etc2: etc2Files ? etc2Files[0] : null,
        });
        await CommonLib.jalert(message);
        try {
          await APIHelper.postForm("admin/action/log", {
            content: "바이어관리 > 바이어 관리 > 상세정보 수정",
            param1 : company,
            param2 : marketCountry,
            param3 : countryCd,
            param4 : companyEmail,
            param5 : companyWebsite,
            param6 : address.postcode,
            param7 : address.address,
            param8 : detailAddress,
            param9 : chargerGender,
          });
        } catch (err) {
          console.log(err);
        }

      } catch (err) {
        console.error(err);
      }
      return resolve(true);
    });
  };

  return (
    <article className="wid_inline mt-4">
      <div className="d-flex justify-content-between pb-2 mt-4">
        <p className="txt-md">대표정보</p>
      </div>

      <div>
        <table className="table basic w-100">
          <colgroup>
            <col width="22%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th className="center">회사명</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="회사명 입력"
                aria-describedby="button-addon2"
                onChange={async (e) => {
                  await setCompany(e.target.value);
                }}
                value={company}
              />
            </td>
          </tr>
          <tr>
            <th className="center">국적</th>
            <td>
              <select
                className="form-select form-select-sm w-50"
                aria-label="Default select example"
                onChange={async (e) => await setCountryCd(e.target.value)}
                value={countryCd}
              >
                {countries && countries.map((item, i) => {
                  return (
                    <option key={i} selected={countryCd === item.cd} value={item.cd}>{item.name_kr}</option>
                  );
                })}
              </select>
            </td>
          </tr>
          <tr>
            <th className="center">타겟 시장 국가</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="타겟국가 입력"
                aria-describedby="button-addon2"
                onChange={async (e) => {
                  await setMarketCountry(e.target.value);
                }}
                value={marketCountry}
              />
            </td>
          </tr>
          <tr>
            <th className="center">회사 이메일</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="회사 이메일 입력"
                aria-describedby="button-addon2"
                onChange={async (e) => {
                  await setCompanyEmail(e.target.value);
                }}
                value={companyEmail}
              />
            </td>
          </tr>
          <tr>
            <th className="center">Homepage</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Homepage 입력"
                aria-describedby="button-addon2"
                onChange={async (e) => await setCompanyWebsite(e.target.value)}
                value={companyWebsite}
              />
            </td>
          </tr>
          <tr>
            <th className="center">회사주소</th>
            <td>
              <div className="input-group input-group-sm w-50 mb-2">
                <input
                  value={address.postcode}
                  type="text"
                  className="form-control "
                  placeholder="우편번호"
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                />
                <button
                  onClick={addressClick}
                  className="btn btn_outline_puple"
                  type="button"
                  id="button-addon2"
                >
                  주소 검색
                </button>
              </div>
              <div className="input-group input-group-sm">
                {addressOpen && (
                  <DaumPostCode autoClose onComplete={postcodeHandler}/>
                )}
                <input
                  value={address.address}
                  type="text"
                  className="form-control "
                  placeholder="주소"
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                />
                <input
                  onChange={detailAddressHandler}
                  value={detailAddress}
                  type="text"
                  className="form-control "
                  placeholder="상세주소"
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                />
              </div>
            </td>
          </tr>
          <tr>
            <th className="center">회사전화</th>
            <td>
              <div className="input-group w-65">
                <input type="text" className="form-control form-control-sm" placeholder="02"
                       onChange={async (e) => await setCompanyTel1(e.target.value)}
                       value={companyTel1}
                />
                <input type="text" className="form-control form-control-sm"
                       onChange={async (e) => await setCompanyTel2(e.target.value)}
                       value={companyTel2}
                />
                <input type="text" className="form-control form-control-sm"
                       onChange={async (e) => await setCompanyTel3(e.target.value)}
                       value={companyTel3}
                />
              </div>
            </td>
          </tr>
          <tr>
            <th className="center">사업자 등록증</th>
            <td>
              {licenseAttach && <div>{licenseAttach?.filename}</div>}
              <div className="input-group input-group-sm ">
                <input
                  type="file"
                  className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                  ref={licenseFileRef}
                />
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div className="d-flex justify-content-between pb-2 mt-4">
        <p className="txt-md">담당자 정보</p>
      </div>

      <div>
        <table className="table basic w-100">
          <colgroup>
            <col width="22%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th className="center">담당자 이름</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="담당자 이름"
                aria-describedby="button-addon2"
                onChange={async (e) => await setChargerName(e.target.value)}
                value={chargerName}
              />
            </td>
          </tr>
          <tr>
            <th className="center">성별</th>
            <td>
              <select
                className="form-select form-select-sm w-85"
                aria-label="Default select example"
                onChange={async (e) => await setChargerGender(e.target.value)}
                value={chargerGender}
              >
                <option value="M" selected={chargerGender === 'M' ? "true" : "false"}>남</option>
                <option value="F" selected={chargerGender === 'F' ? "true" : "false"}>여</option>
              </select>
            </td>
          </tr>
          <tr>
            <th className="center">담당자 이메일</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="담당자 이메일 입력"
                aria-describedby="button-addon2"
                onChange={async (e) => await setChargerEmail(e.target.value)}
                value={chargerEmail}
              />
            </td>
          </tr>
          <tr>
            <th className="center">담당자 전화번호</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="담당자 전화번호"
                aria-describedby="button-addon2"
                onChange={async (e) => await setChargerTel(e.target.value)}
                value={chargerTel}
              />
            </td>
          </tr>
        </table>
      </div>

      <div className="d-flex justify-content-between pb-2 mt-4">
        <p className="txt-md">비즈니스 추가 정보</p>
      </div>

      <div>
        <table className="table basic w-100">
          <colgroup>
            <col width="22%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th className="center">유투브 채널</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Youtube 채널"
                aria-describedby="button-addon2"
                onChange={async (e) => await setYoutube(e.target.value)}
                value={youtube}
              />
            </td>
          </tr>
          <tr>
            <th className="center">Facebook</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Facebook"
                aria-describedby="button-addon2"
                onChange={async (e) => await setFacebook(e.target.value)}
                value={facebook}
              />
            </td>
          </tr>
          <tr>
            <th className="center">인스타그램</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Instagram"
                aria-describedby="button-addon2"
                onChange={async (e) => await setInstagram(e.target.value)}
                value={instagram}
              />
            </td>
          </tr>
          <tr>
            <th className="center">트위터</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="twitter"
                aria-describedby="button-addon2"
                onChange={async (e) => await setTwitter(e.target.value)}
                value={twitter}
              />
            </td>
          </tr>
          <tr>
            <th className="center">틱톡</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Tictok"
                aria-describedby="button-addon2"
                onChange={async (e) => await setTiktok(e.target.value)}
                value={tiktok}
              />
            </td>
          </tr>
          <tr>
            <th className="center">기타 SNS</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="기타 SNS"
                aria-describedby="button-addon2"
                onChange={async (e) => await setEtcSns(e.target.value)}
                value={etcSns}
              />
            </td>
          </tr>
          <tr>
            <th className="center">오프라인 매장</th>
            <td>
              <div>
                <table className="table light_gray border w-100 mb-0">
                  <colgroup>
                    <col width="22%"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th className="center">오프라인 매장 상호</th>
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="오프라인 매장 상호"
                        aria-describedby="button-addon2"
                        onChange={async (e) => await setOffstoreName(e.target.value)}
                        value={offstoreName}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">오프라인 매장 주소</th>
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="오프라인 매장 주소"
                        aria-describedby="button-addon2"
                        onChange={async (e) => await setOffstoreAddr(e.target.value)}
                        value={offstoreAddr}
                      />
                    </td>
                  </tr>
                  {/*TODO : START*/}
                  <tr>
                    <th className="center">오프라인 매장 사진</th>
                    <td>
                      <div className="input-group input-group-sm ">
                        <input
                          type="file"
                          className="form-control"
                          id="inputGroupFile04"
                          aria-describedby="inputGroupFileAddon04"
                          aria-label="Upload"
                        />
                        <button
                          className="btn btn_outline_puple"
                          type="button"
                          id="inputGroupFileAddon04"
                        >
                          파일첨부
                        </button>
                      </div>
                      <textarea
                        className="form-control mt-2"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      />
                    </td>
                  </tr>
                  {/*TODO : END*/}
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <th className="center">온라인 쇼핑몰</th>
            <td>
              <div>
                <table className="table light_gray border w-100 mb-0">
                  <colgroup>
                    <col width="22%"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th className="center">쇼핑몰 상호</th>
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="쇼핑몰 상호"
                        aria-describedby="button-addon2"
                        onChange={async (e) => await setOnshopName(e.target.value)}
                        value={onshopName}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">쇼핑몰 URL</th>
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="쇼핑몰 URL"
                        aria-describedby="button-addon2"
                        onChange={async (e) => await setOnshopUrl(e.target.value)}
                        value={onshopUrl}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div className="d-flex justify-content-between pb-2 mt-4">
        <p className="txt-md">기타 정보</p>
      </div>

      <div>
        <table className="table basic w-100">
          <colgroup>
            <col width="22%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th className="center">기타 첨부1</th>
            <td>
              {etc1Attach && <div>{etc1Attach?.filename}</div>}
              <div className="input-group input-group-sm ">
                <input
                  type="file"
                  className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                  ref={etc1FileRef}
                />
              </div>
              <p className="txt-sm txt-gray pt-1">
                ※ 이미지 또는 pdf만 업로드 가능합니다. (최대 20Mb){" "}
              </p>
            </td>
          </tr>
          <tr>
            <th className="center">기타 첨부2</th>
            <td>
              {etc2Attach && <div>{etc2Attach?.filename}</div>}
              <div className="input-group input-group-sm ">
                <input
                  type="file"
                  className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                  ref={etc2FileRef}
                />
              </div>
              <p className="txt-sm txt-gray pt-1">
                ※ 이미지 또는 pdf만 업로드 가능합니다. (최대 20Mb){" "}
              </p>
            </td>
          </tr>
        </table>
      </div>

      <p className="text-center">
        <button type="button" className="btn btn_puple px-5 my-3" onClick={async (e) => await updateSubmit(e)}>
          수정하기
        </button>
      </p>
    </article>
  );
};

export default AdminBuyerDetail;
