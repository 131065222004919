import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import MakerList from "../common/MakerList";
import AdminMakerProductList from "./AdminMakerProductList";
import AdminMakerProductAdd from "./AdminMakerProductAdd";
import APIHelper from "../../../helpers/APIHelper";

const AdminMakerProduct = (props) => {
  const history = useHistory();
  const [currState, setCurrState] = useState({state: "list"});

  const modify = (id) => {
    setCurrState({state: "modify", id});
  }

  const list = () => {
    setCurrState({state: "list", renew: new Date().getTime()});
  }

  // 현재 선택한 제조사 state
  const [makerState, setMakerState] = useState();
  const [renew, setRenew] = useState(0);
  const makerClick = (e) => {
    setMakerState(e);
    list();
    setRenew(new Date().getTime());
  };

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "제조사관리 > 제조사 관리 > 제조사 제품관리",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <section className="col-4" style={{height: "98%"}}>
          {/*<MakerList click={makerClick} addbtn={true} userId={userId}/>*/}
          <MakerList click={makerClick} addbtn={true}/>
        </section>
        <section className="col-5" style={{height: "98%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "100%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold p-2">제조사 제품관리</p>
              {/*<p className="m-1 mb-2">*/}
              {/*  <button type="button" className="btn btn_gray">*/}
              {/*    상세 확인*/}
              {/*  </button>*/}
              {/*</p>*/}
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll mt-4 px-2"
              style={{height: "calc(100% - 80px)"}}
            >
              <div className="wid_inline TAB justify-content-between">
                <li className={`"ON"}`}>
                  <a href="#" id="product">
                    제품
                  </a>
                </li>
                <div>
                  <p className="text-center">
                    <button type="button" className="btn btn_puple px-3" onClick={() => setCurrState({state: "add"})}>
                      제품 추가
                    </button>
                  </p>
                </div>
              </div>

              {currState.state === 'list' && <AdminMakerProductList userId={makerState?.user_id} modify={(id) => modify(id)} renew={renew}/>}
              {currState.state === 'modify' && <AdminMakerProductAdd userId={makerState?.user_id} id={currState?.id} list={() => list()} renew={renew}/>}
              {currState.state === 'add' && <AdminMakerProductAdd userId={makerState?.user_id} id={0} list={() => list()} renew={renew}/>}
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminMakerProduct;
