export default class Constants {
	static DEV_MODE = true;

	// static API_HOST = "http://localhost:18080"; // LOCAL
	static API_HOST = "https://www.darlibaba.com"; // DEPLOYMENT

	static API_URL = `${Constants.API_HOST}/api/`;
	static APP_NAME = `Darlibaba`;
	static DISPLAY_NAME = `Darlibaba`;

	// static GOOGLE_ANALYTICS_TRACKING_ID = `G-C7LBVRFQ31`;
	static GOOGLE_ANALYTICS_TRACKING_ID = `UA-236251561-1`;
	// static PAYPAL_CLIENTID = `AUvHuTHs2X2gxjmfQ6y3NNOnFCQ-pYte7kCpbSIEqw5Jy-sYiw0rLbeY1z61zsUqCzGFeZjQ0FGcSXkn`; // Paypal 테스트 Sandbox 계정
	static PAYPAL_CLIENTID = `AYdD19H5nxqSJVgkyfNGVXhvjhWsckU9a24A_PL3vR2PEAOKCWmHExSTJdGBSH_maC76qWnpH5pLolFt`; // Paypal Real 계정
};
