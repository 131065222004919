import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import BuyerProjectCard from "./BuyerProjectCard";
import Icon_bell_p from "../../../image/Icon_bell_p.svg";
import Icon_bell_off from "../../../image/Icon_bell_off.svg";
import first_page_black_48dp from "../../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../../image/chevron_left_black_24dp.svg";
import navigate_next_black_48dp from "../../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../../image/last_page_black_48dp.svg";

import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import moment from "moment";
import CommonLib from "../../../libraries/CommonLib";
import Constants from "../../../constants/constants";
import Paging from "../../common/Paging";
import BuyerProjectSide from "./BuyerProjectSide";
import ProductsComponent from "./ProductsComponent";

const BuyerProjectDetail = (props) => {
  const dv = props?.location?.state?.dv || props?.match?.params?.dv || (window.location.pathname.includes('progress') ? "progress" : "done");
  const id = props?.location?.state?.id || props?.match?.params?.id;
  const [requests, setRequests] = useState();

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      await getProjects();
    }
    fetch();
  }, [currPage]);

  const getProjects = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("project/gets", {mode: "buyer_me", orderBy: "desc", status: dv, paging: 1, currPage});
      if (code === 200) {
        if (pageInfo) setPageInfo(pageInfo);
        setRequests(result);
      }
      window.scrollTo(0, 0);
      return resolve(true);
    });
  }

  return (
    <BuyerProjectSide dv={dv} id={id}>
      <div className="box_round min_h cnt_area pb-5 w-100">
        <div
          className="shadow my-3 box_round_4_paddingNo p-3"
          style={{height: "100%", width: "100%"}}
        >
          <div
            className="wid_scroll scroll_y custom_scroll_p"
            style={{height: "calc(100% - 60px)"}}
          >
            <ProductsComponent projectId={id}/>
          </div>
        </div>
      </div>
    </BuyerProjectSide>
  );
};

export default BuyerProjectDetail;
