import React, {useEffect, useState} from "react";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import APIHelper from "../../../helpers/APIHelper";
import moment from "moment";
import Paging from "../../common/Paging";
import {useDispatch} from "react-redux";
import BuyerList from "../common/BuyerList";
import openReviewMaker from "../../../actions/openReviewMaker";

const AdminOperateManagerReview = (props) => {
  const [me, setMe] = useState({});
  const [results, setResults] = useState();
  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [currItem, setCurrItem] = useState();

  useEffect(() => {
    const fetch = async () => {
      await gets(me);
    }
    fetch();
  }, [me?.id, currPage]);

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "바이어관리 > 바이어별 후기 및 평점",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  const itemClick = async (e) => {
    setMe(e);
    setCurrPage(1);
  };

  // 리뷰내역 가져오기
  const gets = (item) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("review/maker/gets", {
        buyerUserId: item?.user_id,
        paging: 1,
        articlesPerPage: 15,
        currPage
      });
      if (pageInfo) setPageInfo(pageInfo);
      setResults(result);
      console.log(item);
      window.scrollTo(0, 0);
      return resolve(true);
    });
  };

  const dispatch = useDispatch();

  const openForm = async (projectId) => {
    const obj = {
      projectId,
      buyerUserId: me?.id,
      renew: new Date().getTime(),
      closeCallback: async () => {
        await gets(me);
      },
      readOnly: true
    };
    dispatch(openReviewMaker("open", obj));

    try {
      await APIHelper.postForm("admin/action/log", {
        content: "바이어관리 > 바이어별 후기 및 평점 > 보기",
        param1 : projectId,
        param2 : me?.id,
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <section className="col-4" style={{height: "100%"}}>
          <BuyerList click={itemClick}/>
        </section>
        <section className="col-8" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">바이어별 후기 및 평점</p>
              <p className="m-1 mb-2"></p>
            </div>

            <p className="px-3 text-start bold my-3">총 {pageInfo?.totalArticles || 0}건</p>
            {results?.length > 0 &&
              <div
                className="wid_scroll scroll_y custom_scroll px-2"
                style={{height: "calc(100% - 330px)"}}
              >
                <article className="wid_inline">
                  <table className="table basic w-100">
                    <colgroup>
                      <col width="250px"/>
                      <col width="*"/>
                      {/*<col width="80px"/>*/}
                      <col width="150px"/>
                      <col width="100px"/>
                    </colgroup>
                    <tr>
                      <th className="center">바이어</th>
                      <th className="center">프로젝트</th>
                      {/*<th className="center">평점</th>*/}
                      <th className="center">일시</th>
                      <th className="center">상세</th>
                    </tr>
                    {results && results?.map((item, i) => (
                      <tr>
                        <td className="center">{item?.buyer?.company ? item?.buyer?.company : item?.buyer?.charger_name}</td>
                        <td className="left">{item?.project?.name ? ("프로젝트 No. " + item?.project?.prj_no + " / " + item?.project?.name) : "프로젝트 No. " + item?.project?.prj_no}</td>
                        {/*<td className="center">{item?.review_score}점</td>*/}
                        <td className="center">{moment(item.created_at).format("YYYY.MM.DD HH:mm")}</td>
                        <td className="center">
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => openForm(item?.project?.id)}>보기</button>
                        </td>
                      </tr>
                    ))}
                  </table>
                </article>
              </div>
            }

            {(!results || results?.length === 0) &&
              <div className="col w-100">
                <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-lblue bold">
                  후기 내역이 없습니다.
                </div>
              </div>
            }
            <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminOperateManagerReview;
