import React, { useState } from "react";
import "../../../sass/main.scss";

const AdminMakerActive = (props) => {
  return (
    <article className="wid_inline mt-4">
      <div>
        <table className="table basic w-100">
          <colgroup>
            <col width="22%" />
            <col width="*" />
          </colgroup>
          <tr>
            <th className="center">회원 이메일 </th>
            <td className="border-top">ggg@gmail.com (연결된 계정)</td>
          </tr>
          <tr>
            <th className="center">회원가입 일자 </th>
            <td className="border-top">2021.10.22 11:45</td>
          </tr>
          <tr>
            <th className="center">회원가입 구분</th>
            <td>직접 가입</td>
          </tr>
          <tr>
            <th className="center">최근 로그인</th>
            <td>2021.12.22 11:11</td>
          </tr>
          <tr>
            <th className="center">총 방문 횟수</th>
            <td> 328회 (제조사 상세 조회 횟수)</td>
          </tr>
        </table>
      </div>

      <div className="my-3">
        <table className="table basic w-100">
          <colgroup>
            <col width="22%" />
            <col width="*" />
          </colgroup>
          <tr>
            <th className="center">마지막 수정 (제조사)</th>
            <td className="border-top"> 2021.12.22 11:11</td>
          </tr>
          <tr>
            <th className="center">마지막 수정 (매니저)</th>
            <td className="border-top"> 2021.12.22 11:11</td>
          </tr>
        </table>
      </div>
    </article>
  );
};

export default AdminMakerActive;
