import React, {useRef} from "react";
import moment from "moment";
import APIHelper from "../helpers/APIHelper";
import Constants from "../constants/constants";
import {useDispatch} from "react-redux";
import openTimelineText from "../actions/openTimelineText";
import openTimelineAttach from "../actions/openTimelineAttach";
import {confirmAlert} from '../libraries/react-confirm-alert/lib/index'
import '../libraries/react-confirm-alert/src/react-confirm-alert.css'
import $ from "jquery";
import openRequestFormulation from "../actions/openRequestFormulation";
import openDeliveryVendor from "../actions/openDeliveryVendor";
import openReview from "../actions/openReview";
import openTTTransfer from "../actions/openTTTransfer";

export default class CommonLib {
  static jalert = message => {
    $(':focus').blur();

    return new Promise(async (resolve, reject) => {
      confirmAlert({
        title: 'DARLIBABA',
        message,
        keyCodeForClose: [8, 13, 32],
        willUnmount: () => {
          window.event.preventDefault();
          window.event.stopPropagation();
        },
        afterClose: () => {
          return resolve(true);
        },
        buttons: [
          {
            label: '확인',
            onClick: () => {
              return resolve(true);
            },
          }
        ]
      });
    });
  }

  static jconfirm = message => {
    return new Promise(async (resolve, reject) => {
      confirmAlert({
        title: 'DARLIBABA',
        message,
        afterClose: () => {
          return resolve(false);
        },
        buttons: [
          {
            label: '확인',
            onClick: () => {
              return resolve(true);
            },
          },
          {
            label: '취소',
            onClick: () => {
              return resolve(false);
            },
          }
        ]
      });
    });
  }

  static generateUUID = () => {
    let d = new Date().getTime();
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  static parseInt = val => {
    let ret = 0;
    try {
      ret = parseInt(val);
    } catch (err) {
    }
    if (isNaN(ret)) ret = 0;
    if (!ret) ret = 0;
    return ret;
  };

  static parseFloat = val => {
    let ret = 0;
    try {
      ret = parseFloat(val);
    } catch (err) {
    }
    if (isNaN(ret)) ret = 0;
    if (!ret) ret = 0;
    return ret;
  };

  static numberWithCommas = (x) => {
    x = CommonLib.parseInt(x);
    let ret;
    try {
      ret = x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (err) {
      ret = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    if (ret === "NaN") ret = "";
    if (!ret) ret = "0";
    return ret;
  };

  static floatWithCommas = (x) => {
    x = CommonLib.parseFloat(x);
    if (isNaN(x) || x === "NaN") x = 0;
    let ret;
    try {
      const formatter = new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 15,
        style: 'currency',
        currency: 'USD',
      });
      ret = formatter.format(x.toFixed(4));
    } catch (err) {
    }
    if (ret === "NaN") ret = "0";
    if (!ret) ret = "0";
    return ret.replace("$", "");
  };

  static strWithCommas = (x) => {
    let ret = "";
    try {
      for (let i = x.length - 1; i >= 0; i--) {
        if ((i + 1) % 3 === 0) ret += ",";
        ret += x[i];
      }
    } catch (err) {
    }
    return ret;
  };

  static zeroPad(nr, base) {
    const len = (String(base).length - String(nr).length) + 1;
    return len > 0 ? new Array(len).join("0") + nr : nr;
  };

  static getRenewTime() {
    return new Date().getTime();
  }

  static escapedNewLineToLineBreakTag = (string = '') => {
    return string.split('\n').map((item, index) => {
      return (index === 0) ? item : [<br key={index}/>, item]
    })
  }

  static escapedSpaceToLineBreakTag = (string = '') => {
    return string.split(' ').map((item, index) => {
      return (index === 0) ? item : [<br key={index}/>, item]
    })
  }

  static getTimelineHTML = (item, fields = []) => {
    const getEstimateHTML = (value) => {
      let estimateType = '바이어용';
      if (value.includes('[')) estimateType = value.split('[')[1].replace(']', '');
      if (item?.type === 'MAKER' && estimateType === '샘플') {
        return ``;
      } else {
        return `
          <div class="d-flex mt-2 mb-2 w-100 p-2 border-bottom" style="border-bottom-color: #cfcfcf !important;">
            <p style="width: calc(100% - 150px); font-size: 0.85rem; padding-top: 5px;">
              ${estimateType} 견적서 확인
            </p>
            <div class="text-end txt-sm" style="width: 150px; margin-top: 4px;">
               <button type="button" class="btn btn-ssm px-3 btn-estimate-form btn-action" style="background-color: #dfdfdf; border: 1px solid #cfcfcf;" 
                    projectId="${item?.project_id}" 
                    projectProductId="${item?.project_product_id}" 
                    projectMakerRequestId="${item?.project_maker_request_id}" 
                    timelineId="${item?.id}" 
                    status="${estimateType} 견적서">견적서 확인</button>
            </div>
          </div>
        `;
      }
    }

    let html = ``;
    for (const [key, value] of Object.entries(fields)) {
      if (value === '제조사+제작의뢰서') {
        const maker = item?.maker;
        let area = '';
        if (maker.company_addr.length > 0)
          area = maker.company_addr.split(' ')[0];

        let request_type = '';
        if (item?.request?.request_type === 'MF') request_type = '내용물';
        else if (item?.request?.request_type === 'DS') request_type = '디자인';
        else if (item?.request?.request_type === 'BT') request_type = '용기';
        else if (item?.request?.request_type === 'BX') request_type = '단상자';

        html += `
        <div class="w-100 px-2" style="background-color: #efefef;">
          <div class="d-flex mt-2 mb-2 w-100 p-2 border-bottom" style="border-bottom-color: #cfcfcf !important;">
              <span class="mb-1" style="margin-right: 15px; width: 50px;">
        `;
        if (maker.rep_image_fid && maker.rep_image_fid.length > 0)
          html += `
                  <img
                    src="${Constants.API_HOST}/common/download?fid=${maker.rep_image_fid}&cd=inline&thumb=400"
                    style="width: 40px; height: 40px; object-fit: cover;"
                  />`;
        else
          html += `<div style="width: 40px; height: 40px; background-color: #dfdfdf;"></div>`;
        html += `
              </span>
              <div class="text-start txt-sm" style="width: 230px">
                <p>${maker.company_name}</p>
                <p>${area}</p>
              </div>
              <div class="text-end txt-sm" style="width: calc(100% - 50px - 230px); min-width: 150px;">
                <p class="txt-sm">${item?.projectProduct?.category?.name_kr || ''} - ${item?.projectProduct?.product?.name_kr || ''}</p>
                <p class="txt-sm">품목 : ${request_type}</p>
              </div>
            </div>`;

        html += `
          <div class="d-flex mt-2 mb-2 w-100 p-2">
            <p style="width: calc(100% - 150px); font-size: 0.85rem;">
              ${item?.requestForm?.request_name}
              <br>
              <span style="font-size: 0.8rem">No. ${item?.requestForm?.request_no}</span>
            </p>
            <div class="text-end txt-sm" style="width: 150px; margin-top: 6px;">
               <button type="button" class="btn btn-ssm px-3 btn-request-form btn-action" style="background-color: #dfdfdf; border: 1px solid #cfcfcf;" projectId="${item?.requestForm?.project_id}" projectProductId="${item?.requestForm?.project_product_id}" requestType="${item?.requestForm?.request_type}" requestId="${item?.request?.request_form_id}">제작 의뢰서 확인</button>
            </div>
          </div>
        `;

        html += `</div>`;
      } else if (value === '제조사+견적서+제작의뢰서' || value.startsWith('제조사+견적서+제작의뢰서 [')) {
        const maker = item?.maker;
        let area = '';
        if (maker?.company_addr?.length > 0)
          area = maker?.company_addr?.split(' ')?.[0];

        let request_type = '';
        if (item?.request?.request_type === 'MF') request_type = '내용물';
        else if (item?.request?.request_type === 'DS') request_type = '디자인';
        else if (item?.request?.request_type === 'BT') request_type = '용기';
        else if (item?.request?.request_type === 'BX') request_type = '단상자';

        html += `
        <div class="w-100 px-2" style="background-color: #efefef;">
          <div class="d-flex mt-2 mb-2 w-100 p-2 border-bottom" style="border-bottom-color: #cfcfcf !important;">
              <span class="mb-1" style="margin-right: 15px; width: 50px;">
        `;
        if (maker?.rep_image_fid && maker?.rep_image_fid?.length > 0)
          html += `
                  <img
                    src="${Constants.API_HOST}/common/download?fid=${maker.rep_image_fid}&cd=inline&thumb=400"
                    style="width: 40px; height: 40px; object-fit: cover;"
                  />`;
        else
          html += `<div style="width: 40px; height: 40px; background-color: #dfdfdf;"></div>`;
        html += `
              </span>
              <div class="text-start txt-sm" style="width: 230px">
                <p>${maker?.company_name}</p>
                <p>${area}</p>
              </div>
              <div class="text-end txt-sm" style="width: calc(100% - 50px - 230px); min-width: 150px;">
                <p class="txt-sm">${item?.projectProduct?.category?.name_kr || ''} - ${item?.projectProduct?.product?.name_kr || ''}</p>
                <p class="txt-sm">품목 : ${request_type}</p>
              </div>
            </div>`;

        html += getEstimateHTML(value);

        html += `
          <div class="d-flex mt-2 mb-2 w-100 p-2">
            <p style="width: calc(100% - 150px); font-size: 0.85rem;">
              ${item?.requestForm?.request_name}
              <br>
              <span style="font-size: 0.8rem">No. ${item?.requestForm?.request_no}</span>
            </p>
            <div class="text-end txt-sm" style="width: 150px; margin-top: 6px;">
               <button type="button" class="btn btn-ssm px-3 btn-request-form btn-action" style="background-color: #dfdfdf; border: 1px solid #cfcfcf;" projectId="${item?.requestForm?.project_id}" projectProductId="${item?.requestForm?.project_product_id}" requestType="${item?.requestForm?.request_type}" requestId="${item?.request?.request_form_id}">제작 의뢰서 확인</button>
            </div>
          </div>
        `;

        html += `</div>`;
      } else if (value === '제작의뢰서') {
        html += `<div class="w-100 px-2" style="background-color: #efefef;">`;
        html += `
          <div class="d-flex mt-2 mb-2 w-100 p-2">
            <p style="width: calc(100% - 150px); font-size: 0.85rem;">
              ${item?.requestForm?.request_name}
              <br>
              <span style="font-size: 0.8rem">No. ${item?.requestForm?.request_no}</span>
            </p>
            <div class="text-end txt-sm" style="width: 150px; margin-top: 6px;">
               <button type="button" class="btn btn-ssm px-3 btn-request-form btn-action" style="background-color: #dfdfdf; border: 1px solid #cfcfcf;" projectId="${item?.requestForm?.project_id}" projectProductId="${item?.requestForm?.project_product_id}" requestType="${item?.requestForm?.request_type}" requestId="${item?.request?.request_form_id}">제작 의뢰서 확인</button>
            </div>
          </div>
        `;
        html += `</div>`;
      } else if (value === '바이어+견적서+제작의뢰서' || value.startsWith('바이어+견적서+제작의뢰서 [')) {
        const buyer = item?.buyer;
        let area = '';
        if (buyer.company_addr.length > 0)
          area = buyer.company_addr.split(' ')[0];

        let request_type = '';
        if (item?.request?.request_type === 'MF') request_type = '내용물';
        else if (item?.request?.request_type === 'DS') request_type = '디자인';
        else if (item?.request?.request_type === 'BT') request_type = '용기';
        else if (item?.request?.request_type === 'BX') request_type = '단상자';

        html += `
        <div class="w-100 px-2" style="background-color: #efefef;">
          <div class="d-flex mt-2 mb-2 w-100 p-2 border-bottom" style="border-bottom-color: #cfcfcf !important;">
              <span class="mb-1" style="margin-right: 15px; width: 50px;">
        `;
        if (buyer.rep_image_fid && buyer.rep_image_fid.length > 0)
          html += `
                  <img
                    src="${Constants.API_HOST}/common/download?fid=${buyer.rep_image_fid}&cd=inline&thumb=400"
                    style="width: 40px; height: 40px; object-fit: cover;"
                  />`;
        else
          html += `<div style="width: 40px; height: 40px; background-color: #dfdfdf;"></div>`;
        html += `
              </span>
              <div class="text-start txt-sm" style="width: 230px">
                <p>${buyer?.company?.length > 0 ? buyer.company : (buyer?.first_name + " " + buyer?.middle_name + " " + buyer?.last_name)}</p>
                <p>${area}</p>
              </div>
              <div class="text-end txt-sm" style="width: calc(100% - 50px - 230px); min-width: 150px;">
                <p class="txt-sm">${item?.projectProduct?.category?.name_kr || ''} - ${item?.projectProduct?.product?.name_kr || ''}</p>
                <p class="txt-sm">품목 : ${request_type}</p>
              </div>
            </div>`;

        html += getEstimateHTML(value);

        html += `
          <div class="d-flex mt-2 mb-2 w-100 p-2">
            <p style="width: calc(100% - 150px); font-size: 0.85rem;">
              ${item?.requestForm?.request_name}
              <br>
              <span style="font-size: 0.8rem">No. ${item?.requestForm?.request_no}</span>
            </p>
            <div class="text-end txt-sm" style="width: 150px; margin-top: 6px;">
               <button type="button" class="btn btn-ssm px-3 btn-request-form btn-action" style="background-color: #dfdfdf; border: 1px solid #cfcfcf;" projectId="${item?.requestForm?.project_id}" projectProductId="${item?.requestForm?.project_product_id}" requestType="${item?.requestForm?.request_type}" requestId="${item?.request?.request_form_id}">제작 의뢰서 확인</button>
            </div>
          </div>
        `;

        html += `</div>`;
      } else if (value === '견적서+제작의뢰서' || value.startsWith('견적서+제작의뢰서 [')) {
        html += `<div class="w-100 px-2" style="background-color: #efefef;">`;

        html += getEstimateHTML(value);

        html += `
          <div class="d-flex mt-2 mb-2 w-100 p-2">
            <p style="width: calc(100% - 150px); font-size: 0.85rem;">
              ${item?.requestForm?.request_name}
              <br>
              <span style="font-size: 0.8rem">No. ${item?.requestForm?.request_no}</span>
            </p>
            <div class="text-end txt-sm" style="width: 150px; margin-top: 6px;">
               <button type="button" class="btn btn-ssm px-3 btn-request-form btn-action" style="background-color: #dfdfdf; border: 1px solid #cfcfcf;" projectId="${item?.requestForm?.project_id}" projectProductId="${item?.requestForm?.project_product_id}" requestType="${item?.requestForm?.request_type}" requestId="${item?.request?.request_form_id}">제작 의뢰서 확인</button>
            </div>
          </div>
        `;

        html += `</div>`;
      } else if (value === '바이어견적서') {
        html += `<div class="w-100 px-2" style="background-color: #efefef;">`
        html += `
          <div class="d-flex mt-2 mb-2 w-100 p-2">
            <p style="width: calc(100% - 150px); font-size: 0.85rem; padding-top: 5px;">
              바이어용 견적서 확인
            </p>
            <div class="text-end txt-sm" style="width: 150px; margin-top: 4px;">
               <button type="button" class="btn btn-ssm px-3 btn-estimate-form btn-action" style="background-color: #dfdfdf; border: 1px solid #cfcfcf;" 
                    projectId="${item?.project_id}" 
                    projectProductId="${item?.project_product_id}" 
                    projectMakerRequestId="${item?.project_maker_request_id}" 
                    timelineId="${item?.id}" 
                    status="바이어용 견적서">견적서 확인</button>
            </div>
          </div>
        `;
        html += `</div>`;
      } else if (value === '샘플견적서') {
        html += `<div class="w-100 px-2" style="background-color: #efefef;">`
        html += `
          <div class="d-flex mt-2 mb-2 w-100 p-2">
            <p style="width: calc(100% - 150px); font-size: 0.85rem; padding-top: 5px;">
              샘플 견적서 확인
            </p>
            <div class="text-end txt-sm" style="width: 150px; margin-top: 4px;">
               <button type="button" class="btn btn-ssm px-3 btn-estimate-form btn-action" style="background-color: #dfdfdf; border: 1px solid #cfcfcf;" 
                    projectId="${item?.project_id}" 
                    projectProductId="${item?.project_product_id}" 
                    projectMakerRequestId="${item?.project_maker_request_id}" 
                    timelineId="${item?.id}" 
                    status="샘플 견적서">견적서 확인</button>
            </div>
          </div>
        `;
        html += `</div>`;
      } else if (value === '본견적서') {
        html += `<div class="w-100 px-2" style="background-color: #efefef;">`
        html += `
          <div class="d-flex mt-2 mb-2 w-100 p-2">
            <p style="width: calc(100% - 150px); font-size: 0.85rem; padding-top: 5px;">
              본 견적서 확인
            </p>
            <div class="text-end txt-sm" style="width: 150px; margin-top: 4px;">
               <button type="button" class="btn btn-ssm px-3 btn-estimate-form btn-action" style="background-color: #dfdfdf; border: 1px solid #cfcfcf;" 
                    projectId="${item?.project_id}" 
                    projectProductId="${item?.project_product_id}" 
                    projectMakerRequestId="${item?.project_maker_request_id}" 
                    timelineId="${item?.id}" 
                    status="본 견적서">견적서 확인</button>
            </div>
          </div>
        `;
        html += `</div>`;
      } else if (value === '바이어용본견적서') {
        html += `<div class="w-100 px-2" style="background-color: #efefef;">`
        html += `
          <div class="d-flex mt-2 mb-2 w-100 p-2">
            <p style="width: calc(100% - 150px); font-size: 0.85rem; padding-top: 5px;">
              본 견적서 확인
            </p>
            <div class="text-end txt-sm" style="width: 150px; margin-top: 4px;">
               <button type="button" class="btn btn-ssm px-3 btn-estimate-form btn-action" style="background-color: #dfdfdf; border: 1px solid #cfcfcf;" 
                    projectId="${item?.project_id}" 
                    projectProductId="${item?.project_product_id}" 
                    projectMakerRequestId="${item?.project_maker_request_id}" 
                    timelineId="${item?.id}" 
                    status="바이어용 본 견적서">본 견적서 확인</button>
            </div>
          </div>
        `;
        html += `</div>`;
      } else if (value === '결제은행정보') {
        html += `<div class="w-100 px-2" style="background-color: #efefef;">`
        html += `
          <div class="d-flex mt-2 mb-2 w-100 p-2">
            <p style="width: calc(100% - 150px); font-size: 0.85rem; padding-top: 5px;">
              결제 요청
            </p>
            <div class="text-end txt-sm" style="width: 150px; margin-top: 4px;">
               <button type="button" class="btn btn-ssm px-3 btn-bank-info btn-action" style="background-color: #dfdfdf; border: 1px solid #cfcfcf;" 
                    projectId="${item?.project_id}" 
                    projectProductId="${item?.project_product_id}" 
                    projectMakerRequestId="${item?.project_maker_request_id}" 
                    timelineId="${item?.id}"
                    status="${item.status}">결제은행정보</button>
            </div>
          </div>
        `;
        html += `</div>`;
      }
    }
    return html;
  }

  static getTimelineObj = (item, timelines) => {
    let title = '';
    let subtitle = '';
    let content = '';
    let html = '';
    let dt = moment(item.created_at).format("YYYY.MM.DD HH:mm");
    let buttons = [];

    if (item?.translated) return item;

    const dispatch = useDispatch();

    const openTimelineTextModal = (item, title, formType) => {
      const obj = {
        projectId: item.project_id,
        projectProductId: item.project_product_id,
        projectMakerRequestId: item.project_maker_request_id,
        title,
        renew: new Date().getTime(),
        readOnly: false,
        formType,
        timelineId: item.id
      };
      dispatch(openTimelineText("open", obj));
    }

    const openDeliveryVendorModal = (item) => {
      const obj = {
        projectId: item.project_id,
        projectProductId: item.project_product_id,
        projectMakerRequestId: item.project_maker_request_id,
        renew: new Date().getTime(),
      };
      dispatch(openDeliveryVendor("open", obj));
    }

    const openTimelineAttachModal = (item, title, formType, readOnly = false) => {
      const obj = {
        id: item.id,
        projectId: item.project_id,
        projectProductId: item.project_product_id,
        projectMakerRequestId: item.project_maker_request_id,
        title,
        renew: new Date().getTime(),
        readOnly,
        formType
      };
      dispatch(openTimelineAttach("open", obj));
    }

    const downloadTimelineAttachModal = (item, title, formType) => {
      const obj = {
        projectId: item.project_id,
        projectProductId: item.project_product_id,
        projectMakerRequestId: item.project_maker_request_id,
        title,
        renew: new Date().getTime(),
        readOnly: true,
        formType
      };
      dispatch(openTimelineAttach("open", obj));
    }

    let mode = "";
    if (window.location.pathname.includes("/project/buyer")) mode = "BUYER";
    else if (window.location.pathname.includes("/project/maker")) mode = "MAKER";
    else mode = "MANAGER";

    switch (item.status) {
      case '바이어 상담 문의 - 신청': { // 매니저
        if (mode === 'MANAGER') {
          title = item.status.split('-')[0].trim();
          content = '바이어 제품 제작 상담 문의 접수 하였습니다.\n바이어 제작 문의 내역을 확인하세요.';
          buttons.push({name: '제작문의확인', link: `/admin/project/detail/${item.project_id}`, target: '_blank'});
        } else if (mode === 'BUYER') {
          title = item.status.split('-')[0].trim();
          content = '제품 제작의 단가 상담 접수 되었습니다.';
        }
        break;
      }
      case '바이어 상담 문의 - 접수': { // 바이어, 매니저
        if (mode === 'MANAGER') {
          title = item.status.split('-')[0].trim();
          content = '바이어 제품 제작 상담 문의를 접수 완료 하였습니다.\n제조사에게 보낼 제작의뢰서를 작성해 주세요.';
          buttons.push({name: '제작의뢰서 작성', link: `/admin/project/connect/${item.project_id}`, target: '_blank'});
        } else if (mode === 'BUYER') {
          title = item.status.split('-')[0].trim();
          content = '제조사별 단가 문의를 합니다.';
        }
        break;
      }
      case '제조사별 단가 문의 진행': { // 바이어
        title = item.status.split('-')[0].trim();
        content = '제조사별 단가 문의를 합니다.';
        break;
      }
      case '제작의뢰서 수신': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = '제작 의뢰서 확인하시고 진행 유무를 결정해주세요.';
        buttons.push({
          name: '수락',
          click: async () => {
            if (!await CommonLib.jconfirm('해당 의뢰를 수락하시겠습니까?')) return;
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.putForm("project/maker/request/status", {projectId: item?.project_id, id: item?.project_maker_request_id, status_cd: 'Y'});
            await CommonLib.jalert(message);
          }
        });
        buttons.push({
          name: '거절',
          click: async () => {
            if (!await CommonLib.jconfirm('해당 의뢰를 거절하시겠습니까?')) return;
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.putForm("project/maker/request/status", {projectId: item?.project_id, id: item?.project_maker_request_id, status_cd: 'N'});
            await CommonLib.jalert(message);
          }
        });
        buttons.push({
          name: '피드백',
          click: async () => {
            openTimelineTextModal(item, '피드백', '제작의뢰서 수신 - 제조사 피드백');
          }
        });
        break;
      }
      case '제작의뢰서 발송': { // 매니저
        title = item.status;
        content = '제작 의뢰서를 전달 하였습니다.';
        html = CommonLib.getTimelineHTML(item, ['제조사+제작의뢰서']);
        break;
      }
      case '제작의뢰서 수신 - 제조사 피드백': { // 제조사, 매니저
        if (item.type === 'MAKER') {
          title = item.status;
          content = '피드백을 전달 하였습니다.';
          html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
          buttons.push({
            name: '수락',
            click: async () => {
              if (!await CommonLib.jconfirm('해당 의뢰를 수락하시겠습니까?')) return;
              await CommonLib.dispatchAction(item);
              const {data: {code, result, message}} = await APIHelper.putForm("project/maker/request/status", {projectId: item?.project_id, id: item?.project_maker_request_id, status_cd: 'Y'});
              await CommonLib.jalert(message);
            }
          });
          buttons.push({
            name: '거절',
            click: async () => {
              if (!await CommonLib.jconfirm('해당 의뢰를 거절하시겠습니까?')) return;
              await CommonLib.dispatchAction(item);
              const {data: {code, result, message}} = await APIHelper.putForm("project/maker/request/status", {projectId: item?.project_id, id: item?.project_maker_request_id, status_cd: 'N'});
              await CommonLib.jalert(message);
            }
          });
          buttons.push({
            name: '내 피드백',
            click: async () => {
              openTimelineTextModal(item, '피드백', '제작의뢰서 수신 - 제조사 피드백');
            }
          });
        } else if (item.type === 'MANAGER') {
          title = '제작 의뢰 피드백';
          content = '제작의뢰서 기준 - 제조사가 피드백하였습니다.';
          html = CommonLib.getTimelineHTML(item, ['제조사+제작의뢰서']);
          buttons.push({
            action: true,
            name: '피드백 확인',
            click: async () => {
              openTimelineTextModal(item, '피드백', '제작의뢰서 수신 - 제조사 피드백 확인');
            }
          });
        }
        break;
      }
      case '제작 의뢰 수락': { // 매니저
        title = item.status;
        content = `제작 의뢰를 수락하였습니다.`;
        html = CommonLib.getTimelineHTML(item, ['제조사+제작의뢰서']);
        // buttons.push({name: '제작의뢰서 확인', link: `/admin/project/connect/${item.project_id}`, target: '_blank'});
        break;
      }
      case '제작 의뢰 거절': { // 매니저
        title = item.status;
        content = `제작 의뢰를 거절하였습니다.`;
        html = CommonLib.getTimelineHTML(item, ['제조사+제작의뢰서']);
        break;
      }
      case '간단 견적서 작성': { // 제조사
        title = item.status;
        content = `간단 견적서를 작성하세요.\n아래의 “견적서 작성” 버튼을 누르시면 견적서 작성 화면이 팝업 됩니다.`;
        buttons.push({
          name: '간단 견적서 작성',
          click: async () => {
            openTimelineAttachModal(item, '간단 견적서', '간단 견적서 작성');
          }
        });
        buttons.push({
          name: '확인',
          click: async () => {
            if (!await CommonLib.jconfirm('간단 견적서를 보내시겠습니까?')) return;

            const {data: {code, result, message}} = await APIHelper.postForm(`flow/simple/estimate/submit`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            if (code === 200) await CommonLib.dispatchAction(item);
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '바이어 견적 확인중': { // 제조사
        title = item.status.split('-')[0].trim();
        content = '바이어에 단순 견적서 전달 되었고, 바이어 쪽에서 확인중에 있습니다.';
        break;
      }
      case '간단 견적서 확인': { // 매니저
        title = item.status;
        content = `제조사의 제작 의뢰서 기준 – 단순 견적서 확인 하세요.`;
        html = CommonLib.getTimelineHTML(item, ['제조사+제작의뢰서']);
        buttons.push({
          name: '단순견적서 확인',
          action: true,
          click: () => {
            downloadTimelineAttachModal(item, '간단 견적서 다운로드', '간단 견적서 작성');
          }
        });
        buttons.push({
          name: '견적서 확인 완료',
          action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '간단 견적서 확인 완료').length === 0,
          prevent: timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '간단 견적서 확인 완료').length > 0,
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
              step: 1,
              type: 'MANAGER',
              status: '간단 견적서 확인 완료'
            });
            if (code !== 200) await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '견적서 작성중': { // 바이어
        title = item.status;
        content = `견적서 작성 중 입니다.\n제조사에서 견적서를 작성 중 입니다.`;
        break;
      }
      case '바이어용 견적서 작성': { // 매니저
        title = item.status;
        content = `바이어용 견적서를 작성 하세요.`;
        buttons.push({
          name: '바이어용 견적서 작성',
          click: async () => {
            localStorage.setItem("timeline.params.type", '바이어용 견적서');
            localStorage.setItem("timeline.params.project_id", item?.project_id);
            localStorage.setItem("timeline.params.project_product_id", item?.project_product_id);
            localStorage.setItem("timeline.params.project_maker_request_id", item?.project_maker_request_id);
            window.location.href = `/admin/project/formed/product/${item.project_id}`;
          }
        });
        break;
      }


      case '견적서 확인 및 진행 유무 판단': { // 바이어
        title = item.status;
        content = `견적서를 확인하시고, 진행 여부를 결정해 주세요.`;
        html = CommonLib.getTimelineHTML(item, ['바이어견적서']);
        buttons.push({
          action: true,
          prevent: timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.type === 'BUYER' && (t?.status === '견적서 확인 및 진행 유무 판단 - 진행' || t?.status === '견적서 확인 및 진행 유무 판단 - 미진행')).length > 0,
          name: '진행',
          click: async () => {
            if (!await CommonLib.jconfirm('해당 작업을 진행하시겠습니까?')) return;
            await CommonLib.dispatchAction(item);

            const {data: {code, result, message}} = await APIHelper.postForm(`flow/maker/request/confirm`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
              status: '견적서 확인 및 진행 유무 판단 - 진행',
            });
            await CommonLib.jalert(message);
          }
        });
        buttons.push({
          action: true,
          prevent: timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.type === 'BUYER' && (t?.status === '견적서 확인 및 진행 유무 판단 - 진행' || t?.status === '견적서 확인 및 진행 유무 판단 - 미진행')).length > 0,
          name: '미진행',
          click: async () => {
            if (!await CommonLib.jconfirm('해당 작업을 진행하지 않으시겠습니까?')) return;
            await CommonLib.dispatchAction(item);

            const {data: {codeh, result, message}} = await APIHelper.postForm(`flow/maker/request/confirm`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
              status: '견적서 확인 및 진행 유무 판단 - 미진행',
            });
            await CommonLib.jalert(message);
          }
        });
        buttons.push({
          name: '피드백',
          click: async () => {
            openTimelineTextModal(item, '피드백', '견적서 확인 및 진행 유무 판단 - 바이어 피드백');
          }
        });
        break;
      }
      case '견적서 확인 및 진행 유무 판단 - 미진행': { // 매니저, 바이어
        title = item.status;
        content = `견적 프로세스를 추가 진행하지 않습니다.`;
        break;
      }
      case '견적서 확인 및 진행 유무 판단 - 바이어 피드백': {
        if (item.type === 'BUYER') { // 바이어
          title = item.status;
          content = `피드백을 발송하였습니다.`;
          html = CommonLib.getTimelineHTML(item, ['바이어견적서']);
          buttons.push({
            name: '진행',
            click: async () => {
              if (!await CommonLib.jconfirm('해당 작업을 진행하시겠습니까?')) return;
              await CommonLib.dispatchAction(item);

              const {data: {code, result, message}} = await APIHelper.postForm(`flow/maker/request/confirm`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
                status: '견적서 확인 및 진행 유무 판단 - 진행',
              });
              await CommonLib.jalert(message);
            }
          });
          buttons.push({
            name: '미진행',
            click: async () => {
              if (!await CommonLib.jconfirm('해당 작업을 진행하지 않으시겠습니까?')) return;
              await CommonLib.dispatchAction(item);

              const {data: {code, result, message}} = await APIHelper.postForm(`flow/maker/request/confirm`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
                status: '견적서 확인 및 진행 유무 판단 - 미진행',
              });
              await CommonLib.jalert(message);
            }
          });
          // buttons.push({
          //   name: '내 피드백',
          //   click: async () => {
          //     openTimelineTextModal(item, '피드백', '견적서 확인 및 진행 유무 판단 - 바이어 피드백');
          //   }
          // });
        } else if (item.type === 'MANAGER') { // 매니저
          title = item.status;
          content = `피드백이 도착하였습니다.`;
          html = CommonLib.getTimelineHTML(item, ['바이어견적서']);
          buttons.push({
            name: '바이어용 견적서 작성',
            click: async () => {
              localStorage.setItem("timeline.params.type", '바이어용 견적서');
              localStorage.setItem("timeline.params.project_id", item?.project_id);
              localStorage.setItem("timeline.params.project_product_id", item?.project_product_id);
              localStorage.setItem("timeline.params.project_maker_request_id", item?.project_maker_request_id);
              window.location.href = `/admin/project/formed/product/${item.project_id}`;
              //openTimelineAttachModal(item, '바이어용 견적서', '바이어용 견적서 작성');
            }
          });
          buttons.push({
            action: true,
            name: '피드백 확인',
            click: async () => {
              openTimelineTextModal(item, '피드백', '견적서 확인 및 진행 유무 판단 - 바이어 피드백');
            }
          });
          buttons.push({
            name: '피드백',
            click: async () => {
              openTimelineTextModal(item, '피드백', '견적서 확인 및 진행 유무 판단 - 매니저 피드백');
            }
          });
        }
        break;
      }
      case '견적서 확인 및 진행 유무 판단 - 매니저 피드백': {
        if (item.type === 'BUYER') { // 바이어
          title = item.status;
          content = `피드백이 도착하였습니다.`;
          html = CommonLib.getTimelineHTML(item, ['바이어견적서']);
          buttons.push({
            name: '진행',
            click: async () => {
              if (!await CommonLib.jconfirm('해당 작업을 진행하시겠습니까?')) return;
              await CommonLib.dispatchAction(item);

              const {data: {code, result, message}} = await APIHelper.postForm(`flow/maker/request/confirm`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
                status: '견적서 확인 및 진행 유무 판단 - 진행',
              });
              await CommonLib.jalert(message);
            }
          });
          buttons.push({
            name: '미진행',
            click: async () => {
              if (!await CommonLib.jconfirm('해당 작업을 진행하지 않으시겠습니까?')) return;
              await CommonLib.dispatchAction(item);

              const {data: {code, result, message}} = await APIHelper.postForm(`flow/maker/request/confirm`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
                status: '견적서 확인 및 진행 유무 판단 - 미진행',
              });
              await CommonLib.jalert(message);
            }
          });
          buttons.push({
            action: true,
            name: '피드백 확인',
            click: async () => {
              openTimelineTextModal(item, '피드백', '견적서 확인 및 진행 유무 판단 - 매니저 피드백');
            }
          });
          buttons.push({
            name: '피드백',
            click: async () => {
              openTimelineTextModal(item, '피드백', '견적서 확인 및 진행 유무 판단 - 바이어 피드백');
            }
          });
        } else if (item.type === 'MANAGER') { // 매니저
          title = item.status;
          content = `피드백을 전달하였습니다.`;
          html = CommonLib.getTimelineHTML(item, ['바이어견적서']);
          buttons.push({
            name: '내 피드백',
            click: async () => {
              openTimelineTextModal(item, '피드백', '견적서 확인 및 진행 유무 판단 - 매니저 피드백');
            }
          });
        }
        break;
      }
      case '견적서 확인 및 진행 유무 판단 - 진행': { // 바이어, 매니저, 제조사
        html = CommonLib.getTimelineHTML(item, ['바이어견적서']);
        if (item.type === 'BUYER') {
          title = item.status.split('-')[0].trim();
          content = `견적서를 기반으로 작업 진행을 선택하셨습니다.`;
        } else if (item.type === 'MANAGER') {
          title = item.status.split('-')[0].trim();
          content = `바이어 쪽에서 견적 승인하였습니다.`;
        } else if (item.type === 'MAKER') {
          title = item.status.split('-')[0].trim();
          content = `바이어 쪽에서 견적 승인하였습니다.`;
        }
        break;
      }
      case '샘플 진행 여부': { // 바이어
        title = item.status;
        content = `샘플 제작을 원하시나요?`;
        // fix : '샘플진행' 버튼만 disabled 풀어준다. 만에 하나 샘플 피드백 단계에서 제조사가 교체될 경우 대처하기 위함.
        buttons.push({
          // action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.type === 'BUYER' && t?.status === '샘플 진행 여부 - 진행').length === 0,
          // prevent: timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.type === 'BUYER' && t?.status === '샘플 진행 여부 - 진행').length > 0,
          action: true,
          prevent: false,
          name: '샘플 진행 확정',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/process/yes`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
            });
            await CommonLib.jalert(message);
          }
        });
        buttons.push({
          action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.type === 'BUYER' && t?.status === '본 견적서 작성중').length === 0,
          prevent: timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.type === 'BUYER' && t?.status === '본 견적서 작성중').length > 0,
          name: '아니오',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/process/no`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              // projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '샘플 진행 여부 - 진행': { // 바이어
        title = item.status.split('-')[0].trim();
        content = `샘플 제작 진행을 선택하셨습니다.`;
        break;
      }
      case '샘플 진행 여부 - 안함': { // 매니저
        title = item.status.split('-')[0].trim();
        content = `바이어쪽에서 샘플 진행을 하지 않습니다.`;
        break;
      }
      case '샘플 진행 여부 - 결정': { // 매니저
        title = item.status.split('-')[0].trim();
        content = `샘플 제작 진행 합니다.`;
        buttons.push({
          action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '샘플 견적서 작성').length === 0,
          prevent: timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '샘플 견적서 작성').length > 0,
          // name: '샘플 진행 품목 확인 및 확정 처리',
          name: '샘플 진행 확정',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/process/confirm`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '샘플 견적서 작성': { // 매니저
        title = item.status;
        content = `바이어용 샘플 견적서를 작성 하세요.`;
        buttons.push({
          name: '샘플 견적서 작성',
          click: async () => {
            localStorage.setItem("timeline.params.type", '샘플 견적서');
            localStorage.setItem("timeline.params.project_id", item?.project_id);
            localStorage.setItem("timeline.params.project_product_id", item?.project_product_id);
            localStorage.setItem("timeline.params.project_maker_request_id", item?.project_maker_request_id);
            window.location.href = `/admin/project/formed/product/${item.project_id}`;
          }
        });
        break;
      }
      case '샘플 견적서 확인 및 진행 유무 판단': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['샘플견적서']);
        title = item.status;
        content = `샘플 견적서를 확인하시고, 진행 여부를 결정해 주세요.`;
        buttons.push({
          name: '진행',
          click: async () => {
            if (!await CommonLib.jconfirm('해당 작업을 진행하시겠습니까?')) return;
            await CommonLib.dispatchAction(item);

            const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/estimate/confirm`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
              status: '샘플 견적서 확인 및 진행 유무 판단 - 진행',
            });
            await CommonLib.jalert(message);
          }
        });
        buttons.push({
          name: '미진행',
          click: async () => {
            if (!await CommonLib.jconfirm('해당 작업을 진행하지 않으시겠습니까?')) return;
            await CommonLib.dispatchAction(item);

            const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/estimate/confirm`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
              status: '샘플 견적서 확인 및 진행 유무 판단 - 미진행',
            });
            await CommonLib.jalert(message);
          }
        });
        buttons.push({
          name: '피드백',
          click: async () => {
            openTimelineTextModal(item, '피드백', '샘플 견적서 - 바이어 피드백');
          }
        });
        break;
      }
      case '샘플 견적서 - 바이어 피드백': { // 바이어, 매니저
        if (item.type === 'BUYER') {
          title = '샘플 견적서 피드백';
          content = '피드백을 전달 하였습니다.';
          html = CommonLib.getTimelineHTML(item, ['샘플견적서']);
          buttons.push({
            name: '진행',
            click: async () => {
              if (!await CommonLib.jconfirm('해당 작업을 진행하시겠습니까?')) return;
              await CommonLib.dispatchAction(item);

              const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/estimate/confirm`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
                status: '샘플 견적서 확인 및 진행 유무 판단 - 진행',
              });
              await CommonLib.jalert(message);
            }
          });
          buttons.push({
            name: '미진행',
            click: async () => {
              if (!await CommonLib.jconfirm('해당 작업을 진행하지 않으시겠습니까?')) return;
              await CommonLib.dispatchAction(item);

              const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/estimate/confirm`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
                status: '샘플 견적서 확인 및 진행 유무 판단 - 미진행',
              });
              await CommonLib.jalert(message);
            }
          });
        } else if (item.type === 'MANAGER') {
          title = '샘플 견적서 피드백';
          content = '샘플 견적서 - 바이어가 피드백하였습니다.';
          html = CommonLib.getTimelineHTML(item, ['샘플견적서']);
          buttons.push({
            name: '샘플 견적서 작성',
            click: async () => {
              localStorage.setItem("timeline.params.type", '샘플 견적서');
              localStorage.setItem("timeline.params.project_id", item?.project_id);
              localStorage.setItem("timeline.params.project_product_id", item?.project_product_id);
              localStorage.setItem("timeline.params.project_maker_request_id", item?.project_maker_request_id);
              window.location.href = `/admin/project/formed/product/${item.project_id}`;
            }
          });
          buttons.push({
            action: true,
            name: '피드백 확인',
            click: async () => {
              openTimelineTextModal(item, '피드백', '샘플 견적서 - 바이어 피드백 확인');
            }
          });
          buttons.push({
            name: '피드백',
            click: async () => {
              openTimelineTextModal(item, '피드백', '샘플 견적서 - 매니저 피드백');
            }
          });
        }
        break;
      }
      case '샘플 견적서 - 매니저 피드백': { // 바이어, 매니저
        if (item.type === 'BUYER') {
          title = '샘플 견적서 피드백';
          content = '샘플 견적서 - 매니저가 피드백하였습니다.';
          html = CommonLib.getTimelineHTML(item, ['샘플견적서']);
          buttons.push({
            name: '진행',
            click: async () => {
              if (!await CommonLib.jconfirm('해당 작업을 진행하시겠습니까?')) return;
              await CommonLib.dispatchAction(item);

              const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/estimate/confirm`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
                status: '샘플 견적서 확인 및 진행 유무 판단 - 진행',
              });
              await CommonLib.jalert(message);
            }
          });
          buttons.push({
            name: '미진행',
            click: async () => {
              if (!await CommonLib.jconfirm('해당 작업을 진행하지 않으시겠습니까?')) return;
              await CommonLib.dispatchAction(item);

              const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/estimate/confirm`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
                status: '샘플 견적서 확인 및 진행 유무 판단 - 미진행',
              });
              await CommonLib.jalert(message);
            }
          });
          buttons.push({
            action: true,
            name: '피드백 확인',
            click: async () => {
              openTimelineTextModal(item, '피드백', '샘플 견적서 - 매니저 피드백 확인');
            }
          });
          buttons.push({
            name: '피드백',
            click: async () => {
              openTimelineTextModal(item, '피드백', '샘플 견적서 - 바이어 피드백');
            }
          });
        } else if (item.type === 'MANAGER') {
          title = '샘플 견적서 피드백';
          content = '피드백을 전달하였습니다.';
          html = CommonLib.getTimelineHTML(item, ['샘플견적서']);
          buttons.push({
            action: true,
            name: '피드백 확인',
            click: async () => {
              openTimelineTextModal(item, '피드백', '샘플 견적서 - 매니저 피드백 확인');
            }
          });
        }
        break;
      }
      case '샘플 견적서 확인 및 진행 유무 판단 - 진행': { // 바이어, 매니저, 제조사
        if (item.type === 'BUYER') {
          title = item.status.split('-')[0].trim();
          content = `샘플 견적서를 기반으로 작업 진행을 선택하셨습니다.`;
        } else if (item.type === 'MANAGER') {
          html = CommonLib.getTimelineHTML(item, ['샘플견적서']);
          title = item.status.split('-')[0].trim();
          content = `바이어 쪽에서 샘플 견적서를 승인하였습니다.`;
        } else if (item.type === 'MAKER') {
          html = CommonLib.getTimelineHTML(item, ['샘플견적서']);
          title = item.status.split('-')[0].trim();
          content = `바이어 쪽에서 샘플 견적서를 승인하였습니다.`;
        }
        break;
      }
      case '샘플 비용 결제 요청': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['샘플견적서']);
        title = item.status.split('-')[0].trim();
        content = `$${item.ref_ctnt_1} 결제 진행 합니다.\n아래 결제 방법을 선택 하세요.`;
        break;
      }
      case '샘플 비용 결제 요청 - T.T송금': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['결제은행정보']);
        title = item.status.split('-')[0].trim();
        content = `하기 은행 주소로 입금해 주세요.`;
        break;
      }
      case '샘플 비용 결제 내역 확인중': { // 바이어
        title = item.status.split('-')[0].trim();
        content = `결제 내역 확인 중입니다.\n결제 내역 확인이 완료 되면 제조사에 샘플 제작 요청 합니다.`;
        break;
      }
      case '샘플 비용 결제 확인 및 결정': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['샘플견적서']);
        title = item.status.split('-')[0].trim();
        content = `바이어가 샘플 비용을 결제하였습니다.\n입금 확인 되었으면 승인을 해주세요.`;
        buttons.push({
          name: '결제 확인 완료',
          click: async () => {
            if (!await CommonLib.jconfirm('결제 확인을 하시겠습니까?')) return;
            await CommonLib.dispatchAction(item);

            const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/estimate/payment/confirm`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        buttons.push({
          name: '결제 정보 확인',
          action: true,
          prevent: false,
          click: async () => {
            const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/get", {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
              type: 'BUYER',
              status: "샘플 비용 결제 요청 - T.T송금",
            });
            if (CommonLib.parseInt(result?.id) === 0) {
              await CommonLib.jalert('Paypal 로 결제한 내역입니다.');
              return;
            }
            dispatch(openTTTransfer("open", {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
              timelineId: item?.id,
              refCtnt1: item?.ref_ctnt_1,
              refCtnt2: item?.ref_ctnt_2,
              step: item?.step,
              status: '샘플',
              renew: new Date().getTime()
            }));
          }
        });
        break;
      }
      case '결제 내역 확인 완료': { // 바이어
        title = item.status.split('-')[0].trim();
        content = `결제 내역을 확인 완료하였습니다.`;
        break;
      }
      case '샘플 제작 요청': { // 매니저
        title = item.status.split('-')[0].trim();
        content = `제조사별 입금 진행하시고, 바이어가 선택한 항목의 샘플 제작을 요청하세요.`;
        buttons.push({
          name: '제조사 샘플 제작 요청',
          click: async () => {
            if (!await CommonLib.jconfirm('샘플 제작을 요청 하시겠습니까?')) return;
            await CommonLib.dispatchAction(item);

            const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/manufacture/request`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '샘플 제작 요청 완료': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['견적서 [샘플]']); // TODO
        title = item.status.split('-')[0].trim();
        content = `제조사에 샘플 제작 요청 하였습니다.`;
        break;
      }
      case '샘플 제작비 입금 확인': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['바이어+견적서+제작의뢰서 [샘플]']);
        title = item.status.split('-')[0].trim();
        content = `샘플 제작비 입금하였습니다.\n입금된 내역 확인 후 완료 처리해 주세요.`;
        buttons.push({
          name: '입금 확인 완료',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/manufacture/payment/confirm`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '샘플 제작': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['바이어+견적서+제작의뢰서 [샘플]']);
        title = item.status.split('-')[0].trim();
        content = `샘플 제작 준비가 되었다면 시작 버튼을 눌러주세요.`;
        buttons.push({
          name: '샘플 제작 시작',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/manufacture/start`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '샘플 제작 중': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['제조사+제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `제조사에서 샘플을 제작 중입니다.`;
        break;
      }
      case '샘플 제작 완료 및 배송': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제조사+제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `샘플 제작이 완료되면 버튼을 클릭해 주세요.`;
        buttons.push({
          name: '시안 파일 첨부',
          click: async () => {
            openTimelineAttachModal(item, '시안 파일 첨부', '샘플 제작 완료 및 배송');
          }
        });
        buttons.push({
          name: '샘플 제작 완료 및 배송 시작',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/manufacture/finish`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '샘플 배송 수령 처리': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['제조사+제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `제조사로부터 샘플 배송 수령 되었으면 완료 처리 하세요.`;
        buttons.push({
          action: true,
          name: '첨부파일 확인',
          click: async () => {
            downloadTimelineAttachModal(item, '시안 파일 첨부', '샘플 제작 완료 및 배송');
          }
        });
        buttons.push({
          action: true,
          name: '첨부파일 수정',
          click: async () => {
            const obj = {
              projectId: item.project_id,
              projectProductId: item.project_product_id,
              projectMakerRequestId: item.project_maker_request_id,
              title: '시안 파일 첨부',
              renew: new Date().getTime(),
              readOnly: false,
              formType: '샘플 배송 수령 처리'
            };
            dispatch(openTimelineAttach("open", obj));
          }
        });
        buttons.push({
          action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '샘플 본사 수령 완료').length === 0,
          name: '샘플 본사 수령 완료',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/manager/recv`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '샘플 본사 수령 완료': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['제조사+제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `샘플 수령 처리하였습니다.`;
        break;
      }
      case '샘플 배송 중': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['제조사+제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `샘플을 배송 중입니다.\n샘플을 받으시면 확인하시고 아래의 결정을 해주세요.`;
        buttons.push({
          action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'BUYER' && t?.status === '샘플 수령 후 승인').length === 0,
          prevent: timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'BUYER' && t?.status === '샘플 수령 후 승인').length > 0,
          name: '샘플 진행 확정',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/progress/yes`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        buttons.push({
          action: true,
          name: '첨부파일 확인',
          click: async () => {
            downloadTimelineAttachModal(item, '시안 파일 첨부', '샘플 배송 수령 처리');
          }
        });
        buttons.push({
          action: true,
          name: '피드백',
          click: async () => {
            openTimelineTextModal(item, '피드백', '샘플 수령 - 바이어 피드백');
          }
        });
        break;
      }
      case '샘플 수령 - 바이어 피드백': { // 바이어, 매니저
        if (item.type === 'BUYER') {
          title = '샘플 배송 피드백';
          content = '피드백을 전달 하였습니다.';
          html = CommonLib.getTimelineHTML(item, ['샘플견적서']);
          buttons.push({
            action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'BUYER' && t?.status === '샘플 수령 후 승인').length === 0,
            prevent: timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'BUYER' && t?.status === '샘플 수령 후 승인').length > 0,
            name: '샘플 진행 확정',
            click: async () => {
              await CommonLib.dispatchAction(item);
              const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/progress/yes`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
              });
              await CommonLib.jalert(message);
            }
          });
          buttons.push({
            name: '첨부파일 확인',
            click: async () => {
              downloadTimelineAttachModal(item, '시안 파일 첨부', '샘플 배송 수령 처리');
            }
          });
        } else if (item.type === 'MANAGER') {
          title = '샘플 배송 피드백';
          content = '샘플 수령 - 바이어가 피드백하였습니다.';
          html = CommonLib.getTimelineHTML(item, ['샘플견적서']);
          buttons.push({
            action: true,
            name: '기존 첨부파일 확인',
            click: async () => {
              downloadTimelineAttachModal(item, '시안 파일 첨부', '샘플 배송 수령 처리');
            }
          });
          buttons.push({
            action: true,
            name: '첨부파일 수정',
            click: async () => {
              const obj = {
                projectId: item.project_id,
                projectProductId: item.project_product_id,
                projectMakerRequestId: item.project_maker_request_id,
                title: '시안 파일 첨부',
                renew: new Date().getTime(),
                readOnly: false,
                formType: '샘플 배송 수령 처리'
              };
              dispatch(openTimelineAttach("open", obj));
            }
          });
          buttons.push({
            action: true,
            name: '피드백 확인',
            click: async () => {
              openTimelineTextModal(item, '피드백', '샘플 수령 - 바이어 피드백 확인');
            }
          });
          buttons.push({
            name: '피드백',
            click: async () => {
              openTimelineTextModal(item, '피드백', '샘플 수령 - 매니저 피드백');
            }
          });
        }
        break;
      }
      case '샘플 수령 - 매니저 피드백': { // 바이어, 매니저
        if (item.type === 'BUYER') {
          title = '샘플 배송 피드백';
          content = '샘플 수령 - 매니저가 피드백하였습니다.';
          html = CommonLib.getTimelineHTML(item, ['샘플견적서']);
          buttons.push({
            action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'BUYER' && t?.status === '샘플 수령 후 승인').length === 0,
            prevent: timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'BUYER' && t?.status === '샘플 수령 후 승인').length > 0,
            name: '샘플 진행 확정',
            click: async () => {
              await CommonLib.dispatchAction(item);
              const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/progress/yes`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
              });
              await CommonLib.jalert(message);
            }
          });
          buttons.push({
            action: true,
            name: '피드백 확인',
            click: async () => {
              openTimelineTextModal(item, '피드백', '샘플 수령 - 매니저 피드백 확인');
            }
          });
          buttons.push({
            name: '피드백',
            click: async () => {
              openTimelineTextModal(item, '피드백', '샘플 수령 - 바이어 피드백');
            }
          });
        } else if (item.type === 'MANAGER') {
          title = '샘플 배송 피드백';
          content = '피드백을 전달하였습니다.';
          html = CommonLib.getTimelineHTML(item, ['샘플견적서']);
          buttons.push({
            action: true,
            name: '피드백 확인',
            click: async () => {
              openTimelineTextModal(item, '피드백', '샘플 수령 - 매니저 피드백 확인');
            }
          });
        }
        break;
      }
      case '샘플 수령 후 승인': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['견적서+제작의뢰서 [샘플]']);
        title = item.status.split('-')[0].trim();
        if (item.type === 'MANAGER')
          content = `바이어가 제품 샘플 수령 후 승인 처리 하였습니다.`;
        else
          content = `제품 샘플 수령 후 승인 처리 하였습니다.`;
        break;
      }
      case '샘플 MSDS 첨부 요청': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['견적서+제작의뢰서 [샘플]']);
        title = item.status.split('-')[0].trim();
        content = `샘플에 대한 MSDS 첨부하세요.`;
        buttons.push({
          name: 'MSDS 첨부',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/msds/attach`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '샘플 MSDS 첨부 완료': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['견적서+제작의뢰서 [샘플]']);
        title = item.status.split('-')[0].trim();
        content = `샘플 MSDS 를 첨부하였습니다.`;
        break;
      }
      case '샘플 MSDS 수신': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [샘플]']);
        title = item.status.split('-')[0].trim();
        content = `제조사에서 MSDS 첨부 하였습니다. 확인하세요.`;
        buttons.push({
          name: 'MSDS 확인',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/msds/confirm`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '본 제품 제작 진행 결정': { // 바이어
        title = item.status.split('-')[0].trim();
        content = `본 제품 제작 진행을 하시겠습니까?`;
        buttons.push({
          name: '아니오',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/product/manufacture/process/no`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        buttons.push({
          name: '항목 확인 후 결정',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/product/manufacture/process/yes`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '제조사별 제작 견적서 요청': { // 매니저
        title = item.status.split('-')[0].trim();
        content = `제조사별 제작 견적서 요청 하세요.`;
        buttons.push({
          name: '바이어 본 견적 작성 메시지 발신',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/working/message`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        buttons.push({
          name: '제조사 견적서 요청',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/working/request`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '본 견적서 작성중': { // 바이어
        title = item.status.split('-')[0].trim();
        content = `제품 제작을 위한 본 견적서를 작성 중입니다.`;
        break;
      }
      case '본 견적서 작성': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `본 견적서를 작성하세요.\n아래의 “견적서 작성” 버튼을 누르시면 견적서 작성 화면이 팝업 됩니다.`;
        buttons.push({
          name: '본 견적서 작성',
          click: async () => {
            openTimelineAttachModal(item, '본 견적서', '본 견적서 작성');
          }
        });
        buttons.push({
          name: '확인',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/working/done`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '본 견적서 작성 완료': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `본 견적서를 작성 완료하였습니다.`;
        break;
      }
      case '본 견적서 확인 및 결정': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [본]']);
        title = item.status.split('-')[0].trim();
        content = `본 견적서를 확인하세요.`;
        buttons.push({
          action: true,
          name: '본 견적서 확인',
          click: async () => {
            downloadTimelineAttachModal(item, '본 견적서', '본 견적서 작성');
          }
        });
        buttons.push({
          action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '본 견적서 확인 및 결정 완료').length === 0,
          name: '견적서 결정',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/working/confirm`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '본 견적서 확인 및 결정 완료': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [본]']);
        title = item.status.split('-')[0].trim();
        content = `본 견적서를 확인 및 결정 완료하였습니다.`;
        break;
      }
      case '바이어용 본 견적서 작성': { // 매니저
        title = item.status.split('-')[0].trim();
        content = `바이어용 본 견적서 작성 하세요.`;
        buttons.push({
          name: '제품/견적 생성',
          click: async () => {
            localStorage.setItem("timeline.params.type", '바이어용 본 견적서');
            localStorage.setItem("timeline.params.project_id", item?.project_id);
            localStorage.setItem("timeline.params.project_product_id", item?.project_product_id);
            localStorage.setItem("timeline.params.project_maker_request_id", item?.project_maker_request_id);
            window.location.href = `/admin/project/formed/product/${item.project_id}`;
          },
          prevent: timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.type === 'MANAGER' && t?.status === '바이어용 견적서 작성 - DONE').length > 0,
        });
        break;
      }
      case '바이어용 본 견적서 확인 및 진행 유무 판단 #1': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
        title = '본 견적서 확인 및 진행 유무 판단 1';
        content = `본 견적서를 확인하시고, 서명하기 버튼을 눌러주세요.`;
        // buttons.push({
        //   name: '견적서 다운로드',
        //   click: async () => {
        //     openTimelineAttachModal(item, '바이어용 본 견적서', '바이어용 본 견적서 작성');
        //   }
        // });
        buttons.push({
          name: '서명하기',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/recv/confirm`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
          }
        });
        buttons.push({
          name: '미진행',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/progress/no`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        buttons.push({
          name: '피드백',
          click: async () => {
            openTimelineTextModal(item, '피드백', '본 견적서 - 바이어 피드백');
          }
        });
        break;
      }
      case '본 견적서 - 바이어 피드백': { // 바이어, 매니저
        if (item.type === 'BUYER') {
          title = '본 견적서 피드백';
          content = '피드백을 전달 하였습니다.';
          html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
          buttons.push({
            name: '견적서 다운로드',
            click: async () => {
              openTimelineAttachModal(item, '바이어용 본 견적서', '바이어용 본 견적서 작성');
            }
          });
          buttons.push({
            name: '서명하기',
            click: async () => {
              await CommonLib.dispatchAction(item);
              const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/recv/confirm`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
              });
              await CommonLib.jalert(message);
            }
          });
          buttons.push({
            name: '미진행',
            click: async () => {
              await CommonLib.dispatchAction(item);
              const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/progress/no`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
              });
              await CommonLib.jalert(message);
            }
          });
        } else if (item.type === 'MANAGER') {
          title = '본 견적서 피드백';
          content = '본 견적서 - 바이어가 피드백하였습니다.';
          html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
          buttons.push({
            name: '본 견적서 작성',
            click: async () => {
              localStorage.setItem("timeline.params.type", '바이어용 본 견적서');
              localStorage.setItem("timeline.params.project_id", item?.project_id);
              localStorage.setItem("timeline.params.project_product_id", item?.project_product_id);
              localStorage.setItem("timeline.params.project_maker_request_id", item?.project_maker_request_id);
              window.location.href = `/admin/project/formed/product/${item.project_id}`;
            }
          });
          buttons.push({
            action: true,
            name: '피드백 확인',
            click: async () => {
              openTimelineTextModal(item, '피드백', '본 견적서 - 바이어 피드백 확인');
            }
          });
          buttons.push({
            name: '피드백',
            click: async () => {
              openTimelineTextModal(item, '피드백', '본 견적서 - 매니저 피드백');
            }
          });
        }
        break;
      }
      case '본 견적서 - 매니저 피드백': { // 바이어, 매니저
        if (item.type === 'BUYER') {
          title = '본 견적서 피드백';
          content = '본 견적서 - 매니저가 피드백하였습니다.';
          html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
          buttons.push({
            name: '서명하기',
            click: async () => {
              await CommonLib.dispatchAction(item);
              const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/recv/confirm`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
              });
              await CommonLib.jalert(message);
            }
          });
          buttons.push({
            name: '미진행',
            click: async () => {
              await CommonLib.dispatchAction(item);
              const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/progress/no`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
              });
              await CommonLib.jalert(message);
            }
          });
          buttons.push({
            action: true,
            name: '피드백 확인',
            click: async () => {
              openTimelineTextModal(item, '피드백', '본 견적서 - 매니저 피드백 확인');
            }
          });
          buttons.push({
            name: '피드백',
            click: async () => {
              openTimelineTextModal(item, '피드백', '본 견적서 - 바이어 피드백');
            }
          });
        } else if (item.type === 'MANAGER') {
          title = '본 견적서 피드백';
          content = '피드백을 전달하였습니다.';
          html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
          buttons.push({
            action: true,
            name: '피드백 확인',
            click: async () => {
              openTimelineTextModal(item, '피드백', '본 견적서 - 매니저 피드백 확인');
            }
          });
        }
        break;
      }
      case '바이어용 본 견적서 확인 및 진행 유무 판단 #2 - 서명하기': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
        title = '본 견적서 확인 및 진행 유무 판단 2';
        content = `제품 생산 본 견적서를 확인 하셨나요?\n아래의 결정을 해주세요.\n문의가 있으시면 오른쪽의 담당 매니저에 문의주세요.`;
        buttons.push({
          name: '완료',
          click: async () => {
            await CommonLib.dispatchAction(item);
            {
              const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/progress/yes`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
              });
            }
            {
              const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/sign`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
                refCtnt1: item?.canvasRef?.current?.getSaveData()
              });
              await CommonLib.jalert(message);
            }
          }
        });
        buttons.push({
          name: '초기화',
          click: () => item?.canvasRef?.current?.clear()
        });
        break;
      }
      case '바이어용 본 견적서 확인 및 진행 유무 판단 #2 - 바이어 피드백': {
        if (item.type === 'BUYER') {
          html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
          title = '본 견적서 확인 및 진행 유무 판단 2';
          content = `피드백을 전달하였습니다.`;
          buttons.push({
            name: '서명하기',
            click: async () => {
              await CommonLib.dispatchAction(item);
              {
                const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/progress/yes`, {
                  projectId: item?.project_id,
                  projectProductId: item?.project_product_id,
                  projectMakerRequestId: item?.project_maker_request_id,
                });
              }
              {
                const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/sign`, {
                  projectId: item?.project_id,
                  projectProductId: item?.project_product_id,
                  projectMakerRequestId: item?.project_maker_request_id,
                });
                await CommonLib.jalert(message);
              }
            }
          });
          buttons.push({
            name: '미진행',
            click: async () => {
              await CommonLib.dispatchAction(item);
              const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/progress/no`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
              });
              await CommonLib.jalert(message);
            }
          });
          buttons.push({
            name: '내 피드백',
            click: async () => {
              openTimelineTextModal(item, '피드백', '바이어용 본 견적서 확인 및 진행 유무 판단 #2 - 바이어 피드백');
            }
          });
        } else if (item.type === 'MANAGER') {
          html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
          title = '본 견적서 확인 및 진행 유무 판단 2';
          content = `피드백이 도착하였습니다.`;
          buttons.push({
            name: '제품/견적 생성',
            click: async () => {
              localStorage.setItem("timeline.params.type", '바이어용 본 견적서');
              localStorage.setItem("timeline.params.project_id", item?.project_id);
              localStorage.setItem("timeline.params.project_product_id", item?.project_product_id);
              localStorage.setItem("timeline.params.project_maker_request_id", item?.project_maker_request_id);
              window.location.href = `/admin/project/formed/product/${item.project_id}`;
            }
          });
          buttons.push({
            action: true,
            name: '피드백 확인',
            click: async () => {
              openTimelineTextModal(item, '피드백', '바이어용 본 견적서 확인 및 진행 유무 판단 #2 - 바이어 피드백');
            }
          });
          buttons.push({
            name: '피드백',
            click: async () => {
              openTimelineTextModal(item, '피드백', '바이어용 본 견적서 확인 및 진행 유무 판단 #2 - 매니저 피드백');
            }
          });
        }
        break;
      }
      case '바이어용 본 견적서 확인 및 진행 유무 판단 #2 - 매니저 피드백': {
        if (item.type === 'BUYER') {
          html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
          title = '본 견적서 확인 및 진행 유무 판단 2';
          content = `피드백이 도착하였습니다.`;
          buttons.push({
            name: '서명하기',
            click: async () => {
              await CommonLib.dispatchAction(item);
              {
                const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/progress/yes`, {
                  projectId: item?.project_id,
                  projectProductId: item?.project_product_id,
                  projectMakerRequestId: item?.project_maker_request_id,
                });
              }
              {
                const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/sign`, {
                  projectId: item?.project_id,
                  projectProductId: item?.project_product_id,
                  projectMakerRequestId: item?.project_maker_request_id,
                });
                await CommonLib.jalert(message);
              }
            }
          });
          buttons.push({
            name: '미진행',
            click: async () => {
              await CommonLib.dispatchAction(item);
              const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/progress/no`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
              });
              await CommonLib.jalert(message);
            }
          });
          buttons.push({
            action: true,
            name: '피드백 확인',
            click: async () => {
              openTimelineTextModal(item, '피드백', '바이어용 본 견적서 확인 및 진행 유무 판단 #2 - 매니저 피드백');
            }
          });
        } else if (item.type === 'MANAGER') {
          html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
          title = '본 견적서 확인 및 진행 유무 판단 2';
          content = `피드백을 전달하였습니다.`;
          buttons.push({
            name: '내 피드백',
            click: async () => {
              openTimelineTextModal(item, '피드백', '바이어용 본 견적서 확인 및 진행 유무 판단 #2 - 매니저 피드백');
            }
          });
        }
        break;
      }
      case '바이어용 본 견적서 확인 및 진행 유무 판단 #3 - 미진행': { // 바이어, 매니저
        if (item.type === 'BUYER') {
          html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
          title = '본 견적서 확인 및 진행 유무 판단 3';
          content = `미진행을 선택하셨습니다.`;
        } else if (item.type === 'MANAGER') {
          html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
          title = '본 견적서 확인 및 진행 유무 판단';
          content = `바이어가 미진행을 선택하였습니다.`;
        }
        break;
      }
      case '바이어용 본 견적서 확인 및 진행 유무 판단 #3 - 진행': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
        title = '본 견적서 확인 및 진행 유무 판단 3';
        content = `서명을 완료하셨습니다.`;
        break;
      }
      case '바이어용 본 견적서 승인 및 서명 완료': { // 바이어, 매니저, 제조사
        if (item.type === 'BUYER') {
          title = '본 견적서 승인 및 서명 완료';
          content = `견적서 및 계약서 서명을 완료하셨습니다.`;
        } else if (item.type === 'MANAGER') {
          html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
          title = '바이어 본 견적 승인';
          content = `바이어 쪽에서 본 견적서 승인 및 서명을 완료하였습니다.`;
        } else if (item.type === 'MAKER') {
          html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
          title = '바이어 입금 대기중';
          content = `바이어가 입금 진행중입니다.`;
        }
        break;
      }
      case '제작 비용 결제 요청': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
        title = '선금 비용 결제 요청';
        content = `$${item.ref_ctnt_1} 결제 진행 합니다.\n아래 결제 방법을 선택 하세요.`;
        break;
      }
      case '제작 비용 결제 요청 - T.T송금': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['결제은행정보']);
        title = item.status.split('-')[0].trim();
        content = `하기 은행 주소로 입금해 주세요.`;
        break;
      }
      case '제작 비용 결제 내역 확인중': { // 바이어
        title = '결제 내역 확인 중';
        content = `결제 내역 확인중입니다.\n결제 내역 확인이 완료 되면 제조사에 제품 제작 요청 합니다.`;
        break;
      }
      case '제작 비용 결제 확인 및 결정': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
        title = item.status.split('-')[0].trim();
        content = `바이어가 제작 비용을 결제하였습니다.\n입금 확인 되었으면 승인을 해주세요.`;
        buttons.push({
          name: '결제 확인 완료',
          click: async () => {
            if (!await CommonLib.jconfirm('제작 비용 결제 확인을 하시겠습니까?')) return;
            await CommonLib.dispatchAction(item);

            const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/payment/confirm`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
            });
            await CommonLib.jalert(message);
          }
        });
        buttons.push({
          name: '결제 정보 확인',
          action: true,
          prevent: false,
          click: async () => {
            const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/get", {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
              type: 'BUYER',
              status: "제작 비용 결제 요청 - T.T송금",
            });
            if (CommonLib.parseInt(result?.id) === 0) {
              await CommonLib.jalert('Paypal 로 결제한 내역입니다.');
              return;
            }
            dispatch(openTTTransfer("open", {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
              timelineId: item?.id,
              refCtnt1: item?.ref_ctnt_1,
              refCtnt2: item?.ref_ctnt_2,
              step: item?.step,
              status: '제작',
              renew: new Date().getTime()
            }));
          }
        });
        break;
      }
      case '제조사 제품 제작 요청': { // 바이어
        title = '제조사 제품 제작 요청';
        content = `제조사에 제품 제작을 요청하였습니다.`;
        break;
      }
      case '제작 비용 결제 내역 확인 완료': { // 제조사
        title = '제품 제작 계약서 작성';
        content = `제품 제작 계약서 작성 중입니다.`;
        break;
      }
      case 'MSDS 첨부 여부': { // 매니저
        title = item.status.split('-')[0].trim();
        content = `MSDS 가 있으면 'MSDS 있음' 버튼을 눌러주세요.`;
        buttons.push({
          name: 'MSDS 있음',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/msds/attachment/yes`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        buttons.push({
          name: 'MSDS 없음',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/msds/attachment/no`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case 'MSDS 파일 첨부': { // 매니저
        title = item.status.split('-')[0].trim();
        content = `MSDS 를 첨부하고 확인을 눌러주세요.`;
        buttons.push({
          name: 'MSDS 첨부',
          click: async () => {
          }
        });
        buttons.push({
          name: '확인',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/msds/attachment/done`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case 'MSDS 확인': { // 바이어, 매니저
        html = CommonLib.getTimelineHTML(item, ['제조사+제작의뢰서']);
        if (item.type === 'BUYER') {
          title = item.status.split('-')[0].trim();
          content = `제조사에서 MSDS 를 보냈습니다. 확인하세요.`;
          buttons.push({
            name: 'MSDS 확인',
            click: async () => {
              await CommonLib.dispatchAction(item);
              const {data: {code, result, message}} = await APIHelper.postForm(`flow/msds/attachment/confirm`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
              });
              await CommonLib.jalert(message);
            }
          });
        } else if (item.type === 'MANAGER') {
          title = item.status.split('-')[0].trim();
          content = `MSDS 를 전송하였습니다.`;
        }
        break;
      }
      case '계약서 작성': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [바이어용 본]']);
        title = item.status.split('-')[0].trim();
        content = `제조사용 계약서를 작성하세요.`;
        buttons.push({
          action: true,
          name: '계약서 생성',
          click: async () => {
            openTimelineAttachModal(item, '계약서', '계약서 작성');
          }
        });
        buttons.push({
          action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '계약서 전달 및 서명 요청').length === 0,
          name: '계약서 보내기',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/contract/send`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '계약서 전달 및 서명 요청': { // 제조사, 매니저
        if (item.type === 'MAKER') {
          html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
          title = item.status.split('-')[0].trim();
          content = `계약서를 확인해 주세요.`;
          buttons.push({
            name: '계약서 다운로드',
            click: async () => {
              downloadTimelineAttachModal(item, '계약서', '계약서 작성');
            }
          });
          buttons.push({
            name: '계약서 확인',
            click: async () => {
              await CommonLib.dispatchAction(item);
              const {data: {code, result, message}} = await APIHelper.postForm(`flow/contract/confirm`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
              });
              await CommonLib.jalert(message);
            }
          });
        } else if (item.type === 'MANAGER') {
          html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [바이어용 본]']);
          title = item.status.split('-')[0].trim();
          content = `제조사에 계약서 전달 및 서명 요청하였습니다.`;
          buttons.push({
            action: true,
            name: '계약서 확인',
            click: async () => {
              downloadTimelineAttachModal(item, '계약서', '계약서 작성');
            }
          });
        }
        break;
      }
      case '입금 확인 및 서명하기': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `제품 생산 본 계약서를 확인 하셨나요?\n아래의 결정을 해주세요.\n문의가 있으시면  오른쪽의 담당 매니저에 문의주세요.\n계약서 서명을 진행 하셔야 합니다.`;
        buttons.push({
          name: '완료',
          click: async () => {
            if (!await CommonLib.jconfirm('서명을 완료하시겠습니까?')) return;
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/contract/sign`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
              refCtnt1: item?.canvasRef?.current?.getSaveData()
            });
            await CommonLib.jalert(message);
          }
        });
        buttons.push({
          name: '초기화',
          click: () => item?.canvasRef?.current?.clear()
        });
        break;
      }
      case '계약서 확인 및 서명 완료': { // 매니저, 제조사
        if (item.type === 'MANAGER') {
          html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [바이어용 본]']);
          title = item.status.split('-')[0].trim();
          content = `제조사에서 계약서 및 서명 완료 하였습니다.`;
          buttons.push({
            action: true,
            name: '계약서 확인',
            click: async () => {
              downloadTimelineAttachModal(item, '계약서', '계약서 작성');
            }
          });
          buttons.push({
            name: '입금하기',
            action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '제조사 계약 입금 처리').length === 0,
            prevent: timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '제조사 계약 입금 처리').length > 0,
            click: async () => {
              await CommonLib.dispatchAction(item);
              const {data: {code, result, message}} = await APIHelper.postForm(`flow/contract/payment`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
              });
              await CommonLib.jalert(message);
            }
          });
        } else if (item.type === 'MAKER') {
          html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
          title = item.status.split('-')[0].trim();
          content = `서명 완료 하였습니다.`;
        }
        break;
      }
      case '제조사 계약 입금 처리': { // 매니저, 제조사
        if (item.type === 'MANAGER') {
          html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [바이어용 본]']);
          title = item.status.split('-')[0].trim();
          content = `제조사로 입금 완료하였습니다.`;
          buttons.push({
            action: true,
            name: '계약서 확인',
            click: async () => {
              downloadTimelineAttachModal(item, '계약서', '계약서 작성');
            }
          });
        } else if (item.type === 'MAKER') {
          html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
          title = item.status.split('-')[0].trim();
          content = `입금을 확인하셨으면, 입금 확인 버튼을 클릭해 주세요.`;
          buttons.push({
            action: true,
            name: '계약서 확인',
            click: async () => {
              downloadTimelineAttachModal(item, '계약서', '계약서 작성');
            }
          });
          buttons.push({
            name: '입금 확인',
            click: async () => {
              await CommonLib.dispatchAction(item);
              const {data: {code, result, message}} = await APIHelper.postForm(`flow/contract/done`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
              });
              await CommonLib.jalert(message);
            }
          });
        }
        break;
      }
      case '계약 및 서명 완료 처리': { // 매니저
        if (mode === 'MANAGER') {
          html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [바이어용 본]']);
          title = item.status.split('-')[0].trim();
          content = `계약 및 서명 완료 처리하였습니다.`;
          buttons.push({
            action: true,
            name: '계약서 확인',
            click: async () => {
              downloadTimelineAttachModal(item, '계약서', '계약서 작성');
            }
          });
        } else if (mode === 'MAKER') {
          html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
          title = item.status.split('-')[0].trim();
          content = `계약 및 서명 완료 처리하였습니다.`;
          buttons.push({
            action: true,
            name: '계약서 확인',
            click: async () => {
              downloadTimelineAttachModal(item, '계약서', '계약서 작성');
            }
          });
        }
        break;
      }
      case '제품 생산 지시': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [바이어용 본]']);
        title = item.status.split('-')[0].trim();
        content = `제품 생산 지시를 하시려면 아래 버튼을 누르세요.`;
        buttons.push({
          action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '제품 생산 지시 완료').length === 0,
          name: '제품 생산 지시',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/manufacture/start`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '제품 생산 지시 완료': { // 바이어, 제조사, 매니저
        if (item.type === 'BUYER') {
          html = CommonLib.getTimelineHTML(item, ['제조사+제작의뢰서']);
          title = '제품 제작 중';
          content = `제품을 제작 중입니다.`;
        } else if (item.type === 'MANAGER') {
          html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [바이어용 본]']);
          title = '제품 생산 지시';
          content = `제조사에 제품 제작 요청 하였습니다.`;
          buttons.push({
            action: true,
            name: '계약서 확인',
            click: async () => {
              downloadTimelineAttachModal(item, '계약서', '계약서 작성');
            }
          });
        } else if (item.type === 'MAKER') {
          html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
          title = '제품 생산 시작';
          content = `제품 생산 시작시 아래 버튼을 눌러주세요.`;
          buttons.push({
            name: '제품 제작 시작',
            click: async () => {
              await CommonLib.dispatchAction(item);
              const {data: {code, result, message}} = await APIHelper.postForm(`flow/manufacture/launch`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
              });
              await CommonLib.jalert(message);
            }
          });
        }
        break;
      }
      case '제품 생산 완료': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `제품 생산 완료시 아래 버튼을 눌러주세요.`;
        buttons.push({
          name: '제품 제작 완료',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/manufacture/finish`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '제품 검수': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `제품 검수 준비가 되었으면 아래 버튼을 눌러주세요.`;
        buttons.push({
          name: '검수 파일 등록',
          click: async () => {
            openTimelineAttachModal(item, '검수 파일 첨부', '제품 검수');
          }
        });
        buttons.push({
          name: '제품 검수 요청',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/manufacture/check/request`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '제품 검수 요청': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `제품 검수 요청 처리 하였습니다.`;
        break;
      }
      case '제품 검수 중': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['제조사+제작의뢰서 [바이어용 본]']);
        title = item.status.split('-')[0].trim();
        content = `제품 생산 완료 후 검수중 입니다.`;
        break;
      }
      case '제품 검수 진행': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [바이어용 본]']);
        title = item.status.split('-')[0].trim();
        content = `제조사에서 생산된 제품 검수 진행 하세요.`;
        buttons.push({
          action: true,
          name: '첨부 파일 확인',
          click: async () => {
            downloadTimelineAttachModal(item, '검수 파일 첨부', '제품 검수');
          }
        });
        buttons.push({
          action: true,
          name: '계약서 확인',
          click: async () => {
            downloadTimelineAttachModal(item, '계약서', '계약서 작성');
          }
        });
        buttons.push({
          action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '잔금 입금').length === 0,
          name: '검수 완료 처리',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/manufacture/check/done`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '제품 검수 완료': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [바이어용 본]']);
        title = item.status.split('-')[0].trim();
        content = `제품 생산 완료 후 검수 완료 되었으며, 배송 준비 중입니다.`;
        buttons.push({
          action: true,
          name: '검수 결과 확인',
          click: async () => {
            downloadTimelineAttachModal(item, '검수 파일 첨부', '제품 검수');
          }
        });
        break;
      }
      case '잔금 입금': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [바이어용 본]']);
        title = item.status.split('-')[0].trim();
        content = `검수 완료 처리되었으며, 잔금을 입금해 주세요.`;
        buttons.push({
          name: '잔금 입금',
          action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '잔금 입금 완료').length === 0,
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/balance/payment`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '잔금 입금 완료': { // 매니저, 제조사
        if (item.type === 'MANAGER') {
          html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [바이어용 본]']);
          title = item.status.split('-')[0].trim();
          content = `잔금 송금이 완료되었습니다.`;
        } else if (item.type === 'MAKER') {
          html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
          title = item.status.split('-')[0].trim();
          content = `잔금 입금을 확인하고, 잔금 입금 확인 버튼을 눌러주세요.`;
          buttons.push({
            name: '잔금 입금 확인',
            click: async () => {
              await CommonLib.dispatchAction(item);
              const {data: {code, result, message}} = await APIHelper.postForm(`flow/balance/payment/confirm`, {
                projectId: item?.project_id,
                projectProductId: item?.project_product_id,
                projectMakerRequestId: item?.project_maker_request_id,
              });
              await CommonLib.jalert(message);
            }
          });
        }
        break;
      }
      case '잔금 입금 확인': { // 제조사
        if (item.type === 'MAKER') {
          html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
          title = item.status.split('-')[0].trim();
          content = `잔금 입금 확인 처리하였습니다.`;
        }
        break;
      }
      case '제품 배송': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `제작 의뢰서에 명시된 배송지로 배송 하세요.`;
        buttons.push({
          name: '배송 완료 처리',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/manufacture/delivery/done`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '제품 배송 완료': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `제작 의뢰서에 명시된 배송지로 배송하였습니다.`;
        break;
      }
      case '용기 입고 예정': { // 내용물 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `용기 생산 업체에서 배송을 하였습니다. 입고가 되면 입고 처리를 눌러주세요.`;
        buttons.push({
          action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MAKER' && t?.status === '용기 입고 완료').length === 0,
          name: '용기 입고 완료',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/manufacture/delivery/bt/done`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '단상자 입고 예정': { // 내용물 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `단상자 생산 업체에서 배송을 하였습니다. 입고가 되면 입고 처리를 눌러주세요.`;
        buttons.push({
          action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MAKER' && t?.status === '단상자 입고 완료').length === 0,
          name: '단상자 입고 완료',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/manufacture/delivery/bx/done`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '디자인 발송 예정': { // 내용물 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `디자인 업체에서 발송을 하였습니다. 입고가 되면 입고 처리를 눌러주세요.`;
        buttons.push({
          action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MAKER' && t?.status === '디자인 입고 완료').length === 0,
          name: '디자인 입고 완료',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/manufacture/delivery/ds/done`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '용기 입고 완료': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `용기 입고 완료 처리 하였습니다.`;
        break;
      }
      case '단상자 입고 완료': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `단상자 입고 완료 처리 하였습니다.`;
        break;
      }
      case '디자인 입고 완료': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `디자인 입고 완료 처리 하였습니다.`;
        break;
      }
      case '배송 완료 처리': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `배송 완료 처리 하였습니다.`;
        break;
      }
      case '제품 배송지로 배송': { // 바이어, 매니저
        if (item.type === 'BUYER') {
          title = item.status.split('-')[0].trim();
          content = `제품이 배송지로 배송 완료 되었습니다.`;
        } else if (item.type === 'MANAGER') {
          html = CommonLib.getTimelineHTML(item, ['제조사+제작의뢰서']);
          title = item.status.split('-')[0].trim();
          content = `제품이 배송지로 배송 완료 되었습니다.`;
        }
        break;
      }
      case '품목 및 수량 확인서, MSDS 등록': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `실제 품목 및 수량 확인서, MSDS 등록 요청합니다.`;
        buttons.push({
          name: '서류 첨부',
          action: true,
          click: async () => {
            openTimelineAttachModal(item, '서류 첨부', '품목 및 수량 확인서, MSDS 등록');
          }
        });
        break;
      }
      case '바이어 잔금 결제 요청': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
        title = item.status.split('-')[0].trim();
        content = `바이어에게 잔금 결제를 요청 합니다.`;
        buttons.push({
          name: '바이어 잔금 요청',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/balance/payment/request`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '잔금 결제 요청 - T.T송금': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['결제은행정보']);
        title = item.status.split('-')[0].trim();
        content = `하기 은행 주소로 입금해 주세요.`;
        break;
      }
      case '잔금 입금 대기': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `바이어에 잔금 결제 요청을 합니다. 기다려 주세요.`;
        break;
      }
      case '잔금 결제 요청': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
        title = item.status.split('-')[0].trim();
        content = `$${item.ref_ctnt_1} 결제 진행 합니다.\n아래 결제 방법을 선택 하세요.`;
        break;
      }
      case '잔금 결제 내역 확인 중': { // 바이어
        title = item.status.split('-')[0].trim();
        content = `결제 내역 확인중입니다.\n결제 내역 확인이 완료되면 제품 통관/배송 진행 합니다.`;
        break;
      }
      case '잔금 결제 확인 및 결정': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['바이어용본견적서']);
        title = item.status.split('-')[0].trim();
        content = `결제 확인 되었으면 승인을 해주세요.`;
        buttons.push({
          name: '결제 확인 완료',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/buyer/balance/payment/confirm`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        buttons.push({
          name: '결제 정보 확인',
          action: true,
          prevent: false,
          click: async () => {
            const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/get", {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
              type: 'BUYER',
              status: "잔금 결제 요청 - T.T송금",
            });
            if (CommonLib.parseInt(result?.id) === 0) {
              await CommonLib.jalert('Paypal 로 결제한 내역입니다.');
              return;
            }
            dispatch(openTTTransfer("open", {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
              timelineId: item?.id,
              refCtnt1: item?.ref_ctnt_1,
              refCtnt2: item?.ref_ctnt_2,
              step: item?.step,
              status: '잔금',
              renew: new Date().getTime()
            }));
          }
        });
        break;
      }
      case '패킹리스트 다운로드': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [바이어용 본]']);
        title = item.status.split('-')[0].trim();
        content = `서류를 확인해 주세요.`;
        buttons.push({
          action: true,
          name: '품목 및 수량 확인서',
          click: async () => {
            downloadTimelineAttachModal(item, '서류 첨부', '품목 및 수량 확인서');
          }
        });
        buttons.push({
          action: true,
          name: 'MSDS',
          click: async () => {
            downloadTimelineAttachModal(item, '서류 첨부', 'MSDS');
          }
        });
        break;
      }
      case '물류사 선택': { // 바이어
        title = item.status.split('-')[0].trim();
        content = `물류사를 선택하세요.`;
        buttons.push({
          name: '바이어 물류사',
          click: async () => {
            openDeliveryVendorModal(item);
          }
        });
        buttons.push({
          name: '달리바바 물류사',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/vendor/choice/submit`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '제품 발송 준비 중': { // 바이어
        title = item.status.split('-')[0].trim();
        content = `제품 발송 준비 중입니다.`;
        break;
      }
      case '선적 서류 작성': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [바이어용 본]']);
        title = item.status.split('-')[0].trim();
        content = `선적 서류를 작성해 주세요.`;
        buttons.push({
          action: true,
          name: '물류사 정보',
          click: async () => {
            openTimelineTextModal(item, '물류사 정보', '선적 서류 작성');
          }
        });
        buttons.push({
          action: true,
          name: '패킹리스트',
          click: async () => {
            openTimelineAttachModal(item, '선적 서류', '패킹 리스트');
          }
        });
        buttons.push({
          action: true,
          name: '인보이스',
          click: async () => {
            openTimelineAttachModal(item, '선적 서류', '인보이스');
          }
        });
        buttons.push({
          action: true,
          name: '면장',
          click: async () => {
            openTimelineAttachModal(item, '선적 서류', '면장');
          }
        });
        buttons.push({
          action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '상품 발송').length === 0,
          prevent: timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '상품 발송').length > 0,
          name: '확인',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/manager/documents/done`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '선적 서류 대기': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `선적 서류 대기 중입니다.`;
        break;
      }
      case '상품 발송': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `상품 발송을 진행합니다.`;
        buttons.push({
          name: '상품 발송',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/delivery/start`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          },
        });
        break;
      }
      case '상품 발송 완료': { // 제조사
        html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
        title = item.status.split('-')[0].trim();
        content = `상품 발송이 완료되었습니다.`;
        break;
      }
      case '제품 발송': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['견적서+제작의뢰서 [바이어용 본]']);
        title = item.status.split('-')[0].trim();
        content = `제품 발송하였습니다.`;
        break;
      }
      case '통관 발송': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [바이어용 본]']);
        title = item.status.split('-')[0].trim();
        content = `통관 발송을 진행합니다.`;
        buttons.push({
          action: true,
          name: '통관 첨부',
          click: async () => {
            openTimelineAttachModal(item, '통관 첨부', '통관 발송');
          }
        });
        buttons.push({
          prevent: timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'MANAGER' && t?.status === '통관 확인 - DONE').length > 0,
          name: '통관 발송',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/delivery/pass`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
            });
            await CommonLib.jalert(message);
          },
        });
        break;
      }
      case '통관 확인': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [바이어용 본]']);
        title = item.status.split('-')[0].trim();
        content = `통관 확인을 진행합니다.`;
        buttons.push({
          action: true,
          name: '통관 다운로드',
          click: async () => {
            downloadTimelineAttachModal(item, '통관 첨부', '통관 발송');
          }
        });
        buttons.push({
          action: true,
          name: 'MSDS 다운로드',
          click: async () => {
            downloadTimelineAttachModal(item, '서류 첨부', 'MSDS');
          }
        });
        buttons.push({
          action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.project_maker_request_id === item?.project_maker_request_id && t?.type === 'BUYER' && t?.status === '제품 수령 확인').length === 0,
          name: '통관 확인',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/delivery/confirm`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '제품 수령 확인': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [바이어용 본]']);
        title = item.status.split('-')[0].trim();
        content = `제품 수령을 확인합니다.`;
        buttons.push({
          action: CommonLib.parseInt(item?.action_yn) === 0 && timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.type === 'BUYER' && t?.status === '후기 및 평가 작성').length === 0,
          prevent: timelines.filter(t => t?.project_id === item?.project_id && t?.project_product_id === item?.project_product_id && t?.type === 'BUYER' && t?.status === '후기 및 평가 작성').length > 0,
          name: '수령 완료',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/delivery/fixed`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '제품 수령 완료': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['제조사+견적서+제작의뢰서 [바이어용 본]']);
        title = item.status.split('-')[0].trim();
        content = `제품 수령이 완료되었습니다.`;
        break;
      }
      case '후기 및 평가 작성': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['견적서 [바이어용 본]']);
        title = item.status.split('-')[0].trim();
        content = `후기 및 평가를 작성합니다.`;
        buttons.push({
          name: '후기 작성',
          click: async () => {
            const obj = {projectId: item.project_id, renew: new Date().getTime()};
            dispatch(openReview("open", obj));
          }
        });
        buttons.push({
          name: '확인',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/review/done`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '후기 및 평가 작성 완료': { // 바이어
        html = CommonLib.getTimelineHTML(item, ['견적서 [바이어용 본]']);
        title = item.status.split('-')[0].trim();
        content = `후기 작성이 완료되었습니다.`;
        break;
      }
      case '프로젝트 완료': { // 매니저
        html = CommonLib.getTimelineHTML(item, ['견적서 [바이어용 본]']);
        title = item.status.split('-')[0].trim();
        content = `프로젝트를 완료합니다.`;
        buttons.push({
          name: '후기 확인',
          click: async () => {
            const obj = {projectId: item.project_id, renew: new Date().getTime(), readOnly: true};
            dispatch(openReview("open", obj));
          }
        });
        buttons.push({
          name: '프로젝트 완료',
          click: async () => {
            await CommonLib.dispatchAction(item);
            const {data: {code, result, message}} = await APIHelper.postForm(`flow/process/done`, {
              projectId: item?.project_id,
              projectProductId: item?.project_product_id,
              projectMakerRequestId: item?.project_maker_request_id,
            });
            await CommonLib.jalert(message);
          }
        });
        break;
      }
      case '거래 프로세스 완료': { // 제조사, 바이어, 매니저
        if (item.type === 'BUYER') {
          html = CommonLib.getTimelineHTML(item, ['견적서 [바이어용 본]']);
          title = item.status.split('-')[0].trim();
          content = `모든 거래 프로세스가 완료되었습니다.\n감사합니다.`;
        } else if (item.type === 'MANAGER') {
          html = CommonLib.getTimelineHTML(item, ['견적서 [바이어용 본]']);
          title = item.status.split('-')[0].trim();
          content = `모든 거래 프로세스가 완료되었습니다.\n감사합니다.`;
        } else if (item.type === 'MAKER') {
          html = CommonLib.getTimelineHTML(item, ['제작의뢰서']);
          title = item.status.split('-')[0].trim();
          content = `모든 거래 프로세스가 완료되었습니다.\n감사합니다.`;
        }
        break;
      }
      case '간단 견적서 작성 상태': { // 매니저 - n/k 확인
        if (mode === 'MANAGER') {
          title = ''
          content = item.content;
          for (const [key, value] of Object.entries(item.buttons)) buttons.push(value);
        }
        break;
      }
      case '거래 프로세스 종료': { // 제조사, 바이어, 매니저
        html = '';
        title = item.status.split('-')[0].trim();
        content = `거래 프로세스가 종료되었습니다.`;
        break;
      }
      case '부분 거래 프로세스 종료': { // 제조사, 바이어, 매니저
        html = '';
        title = '거래 프로세스 종료';
        content = `거래 프로세스가 종료되었습니다.`;
        break;
      }
      case '제조사 거래 취소': { // 제조사, 바이어, 매니저
        html = '';
        title = '제조사 거래 취소';
        content = `매니저에 의해 제조사와의 거래가 취소되었습니다.`;
        break;
      }
    }

    return {
      status: item.status,
      title,
      subtitle,
      content,
      html,
      dt,
      buttons,
      action_yn: item.action_yn
    };
  }

  static dispatchAction = async (item) => {
    await APIHelper.postForm("project/timeline/action", {id: item?.id});
  }

  static processQuantity = (cateName, wishQuantity) => {
    if (cateName === '마스크') {
      if (CommonLib.parseInt(wishQuantity) === 10000) return "10000 - 20000";
      else if (CommonLib.parseInt(wishQuantity) === 20000) return "20001 - 30000";
      else if (CommonLib.parseInt(wishQuantity) === 30000) return "30001 - 40000";
    } else {
      if (CommonLib.parseInt(wishQuantity) === 3000) return "3000 - 5000";
      else if (CommonLib.parseInt(wishQuantity) === 5000) return "5001 - 10000";
      else if (CommonLib.parseInt(wishQuantity) === 10000) return "10001 - 20000";
      else if (CommonLib.parseInt(wishQuantity) === 20000) return "20001 - 30000";
      else if (CommonLib.parseInt(wishQuantity) === 30000) return "30001 - 40000";
    }
    return wishQuantity;
  }

  // 구글 번역 관련 처리
  static processTranslation = () => {
    if (!window.location.pathname.startsWith("/admin") && localStorage.getItem("lang") === "en") {
      $('*').each(function () {
        $(this).addClass('first-letter-capitalize');
      });
      $('input, textarea').each(function () {
        $(this).parent().addClass('no-transform');
        $(this).addClass('no-transform');
      });
      $('.no-transform').each(function () {
        $(this).addClass('first-letter-no-transform');
      });
    } else {
      $('*').each(function () {
        $(this).removeClass('first-letter-capitalize');
        $(this).removeClass('first-letter-no-transform');
      });
    }
  }
};
