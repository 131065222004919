import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import openSearch from "../../actions/openSearch";
import "../../sass/main.scss";
import CommonLib from "../../libraries/CommonLib";

const SearchModal = (props) => {
  const refCategory = useRef(false);
  const dispatch = useDispatch();
  const [selectType, setSelectType] = useState("");
  const [selectCategory, setSelectCategory] = useState("");

  const closeModal = () => {
    dispatch(openSearch.search("closed", ""));
  };

  let modalInlineStyle;

  if (props.searchPop.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }

  const categoryHandler = (e) => {
    setSelectCategory(e.target.value);
  };

  const typeHandler = (e) => {
    setSelectType(e.target.value);
  };

  const click = async (e) => {
    if (selectType === "" || selectCategory === "") {
      await CommonLib.jalert("반드시 하나를 선택해주세요");
      return "";
    }
    dispatch(openSearch.searchResult(selectCategory, selectType));
    closeModal();
  };

  return (
    <div style={{ display: modalInlineStyle }}>
      <div className="pop_box02" style={{ width: "550px", minHeight: "400px" }}>
        <div className="POP_title pb-3">
          <h4>
            <strong>고급검색-{props.searchPop.content.title}</strong>
          </h4>
          <p className="closed">
            <button type="button" className="btn" onClick={closeModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </p>
        </div>

        <div className="POP_cnt_box_lg" style={{ marginBottom: "90px" }}>
          <table className="table basic_sm w-100 my-4">
            <colgroup>
              <col width="28%" />
              <col width="*" />
            </colgroup>
            <tr>
              <th className="center txt-sm">제품 분류</th>
              <td>
                {props.searchPop.content.category &&
                  props.searchPop.content.category.map((item, i) => (
                    <div className="form-check form-check-inline" key={i}>
                      <input
                        ref={refCategory}
                        onClick={categoryHandler}
                        className="form-check-input mt-1"
                        type="checkbox"
                        id="categoryCheckbox"
                        value={item}
                      />
                      <label
                        className="form-check-label "
                        for="categoryCheckbox"
                      >
                        {item}
                      </label>
                    </div>
                  ))}
              </td>
            </tr>
            <tr>
              <th className="center txt-sm">재질</th>
              <td>
                {props.searchPop.content.type &&
                  props.searchPop.content.type.map((item, i) => (
                    <div className="form-check form-check-inline" key={i}>
                      <input
                        onChange={typeHandler}
                        className="form-check-input mt-1"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value={item}
                      />
                      <label
                        className="form-check-label "
                        for="inlineCheckbox1"
                      >
                        {item}
                      </label>
                    </div>
                  ))}
              </td>
            </tr>
          </table>
        </div>
        <div className="POP_foot">
          <button className="btn btn_puple" type="button" onClick={click}>
            검색하기
          </button>
        </div>
      </div>
      <div className="pop_bak"></div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    searchPop: state.searchPop,
    searchResult: state.searchResult,
  };
}

function mapDispatchToProps(dispatcher) {
  return bindActionCreators(
    {
      openSearch: openSearch,
    },
    dispatcher
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchModal);
