import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import openEstimate from "../../actions/openEstimate";
import "../../sass/main.scss";
import CommonLib from "../../libraries/CommonLib";
import APIHelper from "../../helpers/APIHelper";
import $ from "jquery";
import close_b from "../../image/close_b.svg";
import CurrencyInput from "react-currency-input-field";

const EstimateModal = (props) => {
  const closeModal = () => {
    props.openEstimate("closed", "");
  };

  const {formType, title, projectId, projectProductId, projectMakerRequestId, renew, readOnly: _readOnly, timelineId} = props.estimatePop?.content;

  const [selectedFormedProducts, setSelectedFormProducts] = useState([]);

  const [id, setId] = useState(props?.match?.params?.id || 0);
  const [estimateNo, setEstimateNo] = useState('');
  const [estimateName, setEstimateName] = useState('');
  const [shippingCost, setShippingCost] = useState(0);
  const [tax, setTax] = useState(0);
  const [supplyCost, setSupplyCost] = useState(0);
  const [vat, setVat] = useState(0);
  const [sumCost, setSumCost] = useState(0);
  const [etc, setEtc] = useState('');

  const sortBy = (a, b) => {
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;
    return 0;
  }

  const calculate = (fps, _shippingCost, _tax) => {
    if (_shippingCost === undefined) _shippingCost = shippingCost;
    if (_tax === undefined) _tax = tax;

    let supplyCost = CommonLib.parseFloat(_shippingCost) + CommonLib.parseFloat(_tax);
    fps.map(fp => {
      fp.types.map(item => {
        supplyCost += CommonLib.parseFloat(item.cnt) * CommonLib.parseFloat(item.unit_cost);
      });
    });
    setSupplyCost(supplyCost);
    setVat(supplyCost * 0.1);
    setSumCost(supplyCost * 1.1);
  }

  const loadEstimate = async () => {
    try {
      const {data: {code: retCode, result, message}} = await APIHelper.getForm("project/estimate/get", {projectId, projectProductId, projectMakerRequestId, type: formType, timelineId});

      setId(result.id);
      setEstimateNo(result.estimate_no || '');
      setEstimateName(result.subject || '');
      setShippingCost(result.shipping_cost || 0);
      setTax(result.tax || 0);
      setSupplyCost(result.supply_cost || 0);
      setVat(result.vat || 0);
      setSumCost(result.sum_cost || 0);
      setEtc(result.etc || '');

      const fps = [];
      for (const [key, product] of Object.entries(result.products)) {
        const fp = {id: product.formed_product_id, name: product.formed_product.name, product_name: product.product_name, types: []};
        for (const [key, item] of Object.entries(product.items)) {
          fp.types.push({type: item.item_cd, item_name: item.item_name, cnt: item.cnt, unit_cost: item.unit_cost});
        }
        fps.push(fp);
      }
      setSelectedFormProducts(fps);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const fetch = async () => {
      // setReadOnly(false);
      // setRefCtnt1('');
      await loadEstimate();
      $('.estimate-modal input').attr('readOnly', true);
      $('.estimate-modal textarea').attr('readOnly', true);
    }
    fetch();
  }, [renew])

  let modalInlineStyle;

  if (props.estimatePop.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }
  return (
    <div style={{display: modalInlineStyle}}>
      <div className="pop_box02 estimate-modal thinscrollbar" style={{width: "900px", minHeight: "500px", maxHeight: "calc(100% - 200px)", overflowY: "auto"}}>
        <div className="POP_title pb-3 border-bottom">
          <h4>
            <strong>견적서 보기</strong>
          </h4>
          <p className="closed">
            <button onClick={closeModal} type="button" className="btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </button>
          </p>
        </div>

        <div className="POP_cnt_box_lg">
          <div style={{width: "100%", height: "100%"}}>
            <div className="wid_inline">
              <div className="pb-3">
                <p className="bold py-2"> 1. 견적서 코드</p>
                <div className="d-flex flex-wrap bg-lpurple border-top border-bottom p-2">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="E1-2021-00001"
                    value={estimateNo}
                    readOnly={true}
                  />
                </div>
              </div>

              <div className="pb-3">
                <p className="bold py-2">
                  2. 견적서 제목
                </p>
                <div className="d-flex flex-wrap bg-lpurple border-top border-bottom p-2">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder=" "
                    onChange={async (e) => await setEstimateName(e.target.value)}
                    value={estimateName}
                  />
                </div>
              </div>

              <div className="pb-2">
                <p className="bold">3. 제품 </p>
              </div>

              <table className="table estimate w-100">
                <colgroup>
                  <col width="16%"/>
                  <col width="*"/>
                  <col width="12%"/>
                  <col width="12%"/>
                  <col width="16%"/>
                  <col width="25px"/>
                </colgroup>
                <tr>
                  <th className="center">제품</th>
                  <th className="center">항목</th>
                  <th className="center">수량</th>
                  <th className="center">단가</th>
                  <th className="center">금액</th>
                  <th className="center"></th>
                </tr>
                {selectedFormedProducts.map(fp => {
                  return (
                    <>
                      <tr>
                        <td className="p-1">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder={fp.product_name}
                            onChange={async (e) => {
                              const fps = [...selectedFormedProducts];
                              const __fp = fps.filter(_fp => _fp.id === fp.id)[0];
                              __fp.product_name = e.target.value;
                              await setSelectedFormProducts(fps);
                            }}
                            value={fp.product_name}
                          />
                        </td>
                        <td className="p-1">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder=" "
                          />
                        </td>
                        <td className="p-1">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder=" "
                          />
                        </td>
                        <td className="p-1">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder=" "
                          />
                        </td>
                        <td className="p-1">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder=" "
                          />
                        </td>
                        <td className="p-0">
                        </td>
                      </tr>
                      {fp.types.map(item => {
                        const type = item.type;

                        const fps = [...selectedFormedProducts];
                        const __fp = fps.filter(_fp => _fp.id === fp.id)[0];
                        const __item = __fp.types.filter(__item => __item.type === item.type)[0];

                        return (
                          <tr>
                            <td className="p-1">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder=" "
                              />
                            </td>
                            <td className="p-1">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder={item.item_name}
                                onChange={async (e) => {
                                  __item.item_name = e.target.value;
                                  await setSelectedFormProducts(fps);
                                }}
                                value={__item.item_name}
                              />
                            </td>
                            <td className="p-1">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder=""
                                onChange={async (e) => {
                                  __item.cnt = e.target.value;
                                  await setSelectedFormProducts(fps);
                                  calculate(fps);
                                }}
                                value={__item.cnt}
                              />
                            </td>
                            <td className="p-1">
                              <CurrencyInput
                                decimalsLimit={4}
                                value={__item.unit_cost}
                                onValueChange={async (value, name) => {
                                  __item.unit_cost = value;
                                  await setSelectedFormProducts(fps);
                                  calculate(fps);
                                }}
                                className="form-control form-control-sm"
                              />
                            </td>
                            <td className="p-1">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder=" "
                                value={CommonLib.floatWithCommas(CommonLib.parseFloat(item.cnt) * CommonLib.parseFloat(item.unit_cost))}
                                readOnly={true}
                              />
                            </td>
                            <td className="p-0">
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )
                })}
              </table>
              {selectedFormedProducts.length === 0 &&
                <div className="col w-100 mb-3">
                  <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-lblue bold">
                    제품이 없습니다.
                  </div>
                </div>
              }

              <div className="pb-2 my-2">
                <p className="bold">4. 기타</p>
              </div>

              <table className="table estimate w-100">
                <colgroup>
                  <col width="16%"/>
                  <col width="*"/>
                  <col width="12%"/>
                  <col width="12%"/>
                  <col width="16%"/>
                  <col width="25px"/>
                </colgroup>

                <tr>
                  <th className="center">운송비</th>
                  <td className="p-1">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder=" "
                    />
                  </td>
                  <td className="p-1">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder=" "
                    />
                  </td>
                  <td className="p-1">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder=" "
                    />
                  </td>
                  <td className="p-1">
                    <CurrencyInput
                      decimalsLimit={4}
                      className="form-control form-control-sm"
                      onValueChange={async (value, name) => {
                        await setShippingCost(value);
                        calculate(selectedFormedProducts, value, undefined);
                      }}
                      value={shippingCost}
                    />
                  </td>
                  <td className="p-1">
                  </td>
                </tr>
                <tr>
                  <th className="center">통관비</th>
                  <td className="p-1">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder=" "
                    />
                  </td>
                  <td className="p-1">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder=" "
                    />
                  </td>
                  <td className="p-1">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder=" "
                    />
                  </td>
                  <td className="p-1">
                    <CurrencyInput
                      decimalsLimit={4}
                      className="form-control form-control-sm"
                      onValueChange={async (value, name) => {
                        await setTax(value);
                        calculate(selectedFormedProducts, undefined, value);
                      }}
                      value={tax}
                    />
                  </td>
                  <td className="p-1">
                  </td>
                </tr>
              </table>

              <div className="d-flex  justify-content-between" style={{width: "calc(100% - 25px)"}}>
                <p></p>
                <div>
                  <dl className="box_price d-flex p-0 m-1">
                    <dt>공급가액($)</dt>
                    <dd>${CommonLib.floatWithCommas(supplyCost)}</dd>
                  </dl>
                  <dl className="box_price d-flex p-0 m-1">
                    <dt>VAT($)</dt>
                    <dd>${CommonLib.floatWithCommas(vat)}</dd>
                  </dl>
                  <dl className="box_price d-flex p-0 m-1">
                    <dt>합계($)</dt>
                    <dd>${CommonLib.floatWithCommas(sumCost)}</dd>
                  </dl>
                </div>
              </div>

              <div>
                <div className="border-bottom pb-2">
                  <p className="bold">3. 기타 </p>
                </div>

                <div className="d-flex flex-wrap bg-lpurple border-bottom p-2">
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      onChange={async (e) => await setEtc(e.target.value)}
                      value={etc}
                      style={{backgroundColor: "#fff"}}
                    ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pop_bak" onClick={closeModal}></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    estimatePop: state.estimatePop,
  };
}

function mapDispatchToProps(dispatcher) {
  return bindActionCreators(
    {
      openEstimate: openEstimate,
    },
    dispatcher
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EstimateModal);
