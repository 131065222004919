import React, {useContext, useEffect, useRef, useState} from "react";
import "../../../sass/main.scss";
import {useHistory} from "react-router-dom";
import APIHelper from "../../../helpers/APIHelper";
import moment from "moment";
import Paging from "../../common/Paging";
import CommonLib from "../../../libraries/CommonLib";
import {GlobalContext} from "../../global-context";
import AdminCard from "../AdminCard";
import FormData from "form-data";

const AdminHomeBanner = (props) => {
  const [renew, setRenew] = useState(0);

  const [ret, setRet] = useState([]);
  const homeBanner1 = useRef();
  const homeBanner1En = useRef();
  const homeBanner2 = useRef();
  const homeBanner2En = useRef();
  const homeBanner3 = useRef();
  const homeBanner3En = useRef();
  const homeBanner4 = useRef();
  const homeBanner4En = useRef();
  const homeBanner5 = useRef();
  const homeBanner5En = useRef();
  const homeBanner6 = useRef();
  const homeBanner6En = useRef();
  const homeBanner7 = useRef();
  const homeBanner7En = useRef();
  const homeBanner8 = useRef();
  const homeBanner8En = useRef();
  const homeBanner9 = useRef();
  const homeBanner9En = useRef();
  const homeBanner10 = useRef();
  const homeBanner10En = useRef();

  const get = async () => {
    const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("data/home/banners");
    setRet(result);
  };

  const onDeleteAttach = async (dv) => {
    const {data: {code, result, message}} = await APIHelper.deleteForm(`data/home/banner?dv=${dv}`);
    if (code === 200) {
      await get();
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "홈노출/검색관리 > 홈 - 슬라이딩 > 삭제",
          param1 : dv,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  const submit = async () => {
    try {
      const formData = new FormData();

      if (homeBanner1?.current?.files.length) formData.append("homeBanner1", homeBanner1?.current?.files[0]);
      if (homeBanner1En?.current?.files.length) formData.append("homeBanner1En", homeBanner1En?.current?.files[0]);

      if (homeBanner2?.current?.files.length) formData.append("homeBanner2", homeBanner2?.current?.files[0]);
      if (homeBanner2En?.current?.files.length) formData.append("homeBanner2En", homeBanner2En?.current?.files[0]);

      if (homeBanner3?.current?.files.length) formData.append("homeBanner3", homeBanner3?.current?.files[0]);
      if (homeBanner3En?.current?.files.length) formData.append("homeBanner3En", homeBanner3En?.current?.files[0]);

      if (homeBanner4?.current?.files.length) formData.append("homeBanner4", homeBanner4?.current?.files[0]);
      if (homeBanner4En?.current?.files.length) formData.append("homeBanner4En", homeBanner4En?.current?.files[0]);

      if (homeBanner5?.current?.files.length) formData.append("homeBanner5", homeBanner5?.current?.files[0]);
      if (homeBanner5En?.current?.files.length) formData.append("homeBanner5En", homeBanner5En?.current?.files[0]);

      if (homeBanner6?.current?.files.length) formData.append("homeBanner6", homeBanner6?.current?.files[0]);
      if (homeBanner6En?.current?.files.length) formData.append("homeBanner6En", homeBanner6En?.current?.files[0]);

      if (homeBanner7?.current?.files.length) formData.append("homeBanner7", homeBanner7?.current?.files[0]);
      if (homeBanner7En?.current?.files.length) formData.append("homeBanner7En", homeBanner7En?.current?.files[0]);

      if (homeBanner8?.current?.files.length) formData.append("homeBanner8", homeBanner8?.current?.files[0]);
      if (homeBanner8En?.current?.files.length) formData.append("homeBanner8En", homeBanner8En?.current?.files[0]);

      if (homeBanner9?.current?.files.length) formData.append("homeBanner9", homeBanner9?.current?.files[0]);
      if (homeBanner9En?.current?.files.length) formData.append("homeBanner9En", homeBanner9En?.current?.files[0]);

      if (homeBanner10?.current?.files.length) formData.append("homeBanner10", homeBanner10?.current?.files[0]);
      if (homeBanner10En?.current?.files.length) formData.append("homeBanner10En", homeBanner10En?.current?.files[0]);

      const response = await APIHelper.putRawForm(
        "data/home/banner",
        formData
      );
      const {data: {code, result, message},} = response;
      if (code === 200) {
        await get();
        try {
          await APIHelper.postForm("admin/action/log", {
            content: "홈노출/검색관리 > 홈 - 슬라이딩 > 저장",
          });
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await get();
    }
    fetch();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "홈노출/검색관리 > 홈 - 슬라이딩",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <AdminCard renew={renew}>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)", maxWidth: "1250px"}}
      >
        <section className="col-12" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">홈 - 슬라이딩</p>
              <p className="m-1 mb-2">
                <button
                  type="button"
                  className="btn btn-purple txt-sm py-1 px-3"
                  onClick={submit}
                >
                  저장
                </button>
              </p>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll px-2"
              style={{height: "calc(100% - 50px)"}}
            >
              <article className="wid_inline">
                <table className="table basic">
                  <colgroup>
                    <col width="25%"/>
                    <col width="75%"/>
                  </colgroup>
                  <tr>
                    <th className="center">롤링 배너 01 (한글)</th>
                    <td>
                      {ret?.home_banner_1_fid && ret?.home_banner_1_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_1_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_1', ret?.home_banner_1_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner1}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 01 (영문)</th>
                    <td>
                      {ret?.home_banner_1_en_fid && ret?.home_banner_1_en_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_1_en_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_1_en', ret?.home_banner_1_en_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner1En}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 02 (한글)</th>
                    <td>
                      {ret?.home_banner_2_fid && ret?.home_banner_2_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_2_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_2', ret?.home_banner_2_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner2}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 02 (영문)</th>
                    <td>
                      {ret?.home_banner_2_en_fid && ret?.home_banner_2_en_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_2_en_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_2_en', ret?.home_banner_2_en_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner2En}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 03 (한글)</th>
                    <td>
                      {ret?.home_banner_3_fid && ret?.home_banner_3_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_3_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_3', ret?.home_banner_3_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner3}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 03 (영문)</th>
                    <td>
                      {ret?.home_banner_3_en_fid && ret?.home_banner_3_en_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_3_en_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_3_en', ret?.home_banner_3_en_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner3En}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 04 (한글)</th>
                    <td>
                      {ret?.home_banner_4_fid && ret?.home_banner_4_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_4_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_4', ret?.home_banner_4_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner4}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 04 (영문)</th>
                    <td>
                      {ret?.home_banner_4_en_fid && ret?.home_banner_4_en_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_4_en_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_4_en', ret?.home_banner_4_en_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner4En}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 05 (한글)</th>
                    <td>
                      {ret?.home_banner_5_fid && ret?.home_banner_5_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_5_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_5', ret?.home_banner_5_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner5}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 05 (영문)</th>
                    <td>
                      {ret?.home_banner_5_en_fid && ret?.home_banner_5_en_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_5_en_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_5_en', ret?.home_banner_5_en_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner5En}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 06 (한글)</th>
                    <td>
                      {ret?.home_banner_6_fid && ret?.home_banner_6_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_6_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_6', ret?.home_banner_6_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner6}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 06 (영문)</th>
                    <td>
                      {ret?.home_banner_6_en_fid && ret?.home_banner_6_en_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_6_en_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_6_en', ret?.home_banner_6_en_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner6En}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 07 (한글)</th>
                    <td>
                      {ret?.home_banner_7_fid && ret?.home_banner_7_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_7_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_7', ret?.home_banner_7_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner7}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 07 (영문)</th>
                    <td>
                      {ret?.home_banner_7_en_fid && ret?.home_banner_7_en_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_7_en_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_7_en', ret?.home_banner_7_en_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner7En}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 08 (한글)</th>
                    <td>
                      {ret?.home_banner_8_fid && ret?.home_banner_8_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_8_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_8', ret?.home_banner_8_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner8}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 08 (영문)</th>
                    <td>
                      {ret?.home_banner_8_en_fid && ret?.home_banner_8_en_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_8_en_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_8_en', ret?.home_banner_8_en_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner8En}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 09 (한글)</th>
                    <td>
                      {ret?.home_banner_9_fid && ret?.home_banner_9_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_9_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_9', ret?.home_banner_9_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner9}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 09 (영문)</th>
                    <td>
                      {ret?.home_banner_9_en_fid && ret?.home_banner_9_en_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_9_en_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_9_en', ret?.home_banner_9_en_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner9En}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 10 (한글)</th>
                    <td>
                      {ret?.home_banner_10_fid && ret?.home_banner_10_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_10_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_10', ret?.home_banner_10_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner10}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">롤링 배너 10 (영문)</th>
                    <td>
                      {ret?.home_banner_10_en_fid && ret?.home_banner_10_en_fid?.length > 0 &&
                        <div
                          style={{background: "#f9edf8", color: "#ae6faf"}}
                          className="box_round_4_paddingNo px-2 p-1 m-2"
                        >
                          <span style={{paddingLeft: "0.5rem"}}>{ret?.home_banner_10_en_attach?.filename}</span>{" "}
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('home_banner_10_en', ret?.home_banner_10_en_fid)}>삭제
                          </button>
                        </div>
                      }
                      <div className="input-group input-group-sm">
                        <input
                          type="file"
                          className="form-control"
                          ref={homeBanner10En}
                        />
                      </div>
                    </td>
                  </tr>
                </table>
              </article>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminHomeBanner;
