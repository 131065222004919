import React, { useState } from "react";
import first_page_black_48dp from "../../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../../image/chevron_left_black_24dp.svg";
import navigate_next_black_48dp from "../../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../../image/last_page_black_48dp.svg";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";

const AdminConductorActionLog = (props) => {
  return (
    <AdminCard>
      <div id="body" style={{ height: "calc(100% - 30px)" }}>
        <div
          className="bg-white my-4 shadow2 box_round_4_paddingNo p-3"
          style={{ height: "98%" }}
        >
          <div className="d-flex justify-content-between border-bottom">
            <p className="h4 bold p-2">관리자 액션 내역</p>
            <p className="m-1 mb-2"></p>
          </div>

          <p className="bold h6 py-3 mx-4">총 51건</p>
          <div
            className="px-4 wid_scroll scroll_y custom_scroll"
            style={{ height: "calc(100% - 200px)" }}
          >
            <article className="wid_inline">
              <table className="table basic_list2 w-100">
                <colgroup>
                  <col width="10%" />
                  <col width="*" />
                  <col width="*" />
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>메뉴 - 액션 내용</th>
                  <th>일시</th>
                </tr>
                <tr>
                  <td>19</td>
                  <td className="left">해당 메뉴 표현 - 액션 내용 표현</td>
                  <td>2022.11.11 11:50</td>
                </tr>
                <tr>
                  <td>18</td>
                  <td className="left">
                    운영관리 / 제조사 연동 처리 - 연동 처리 함
                  </td>
                  <td>2022.11.11 11:50</td>
                </tr>
                <tr>
                  <td>17</td>
                  <td className="left">
                    바이어 관리 / 알림 메시지 관리 - 알림 삭제 함
                  </td>
                  <td>2022.11.11 11:50</td>
                </tr>
              </table>
            </article>
          </div>

          <div className="d-flex justify-content-center py-4 d-none">
            <ul className="d-flex my-3" style={{ margin: "0 auto" }}>
              <li className="PAGE">
                <a href="#" aria-label="Previous">
                  <img src={first_page_black_48dp} style={{ width: "25px" }} />
                </a>
              </li>
              <li className="PAGE">
                <a href="#" aria-label="Previous">
                  <img
                    src={chevron_left_black_24dp}
                    style={{ width: "25px" }}
                  />
                </a>
              </li>
              <li className="PAGE">
                <a href="#" className="ON">
                  1
                </a>
              </li>
              <li className="PAGE">
                <a href="#">2</a>
              </li>
              <li className="PAGE">
                <a href="#">3</a>
              </li>
              <li className="PAGE">
                <a href="#" aria-label="Previous">
                  <img
                    src={navigate_next_black_48dp}
                    style={{ width: "25px" }}
                  />
                </a>
              </li>
              <li className="PAGE">
                <a href="#" aria-label="Previous">
                  <img src={last_page_black_48dp} style={{ width: "25px" }} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </AdminCard>
  );
};

export default AdminConductorActionLog;
