import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../sass/main.scss";
import BuyerId from "./BuyerId";
import MakerId from "./MakerId";
import LoginCard from "./LoginCard";

const FindId = (props) => {
  const [tabState, setTabState] = useState({
    buyerId: true,
    makerId: false,
  });

  const clickTab = (e) => {
    const tabs = { ...tabState };
    const target = e.currentTarget.id;

    for (let key in tabs) {
      key === target ? (tabs[key] = true) : (tabs[key] = false);
    }
    setTabState(tabs);
  };
  return (
    <LoginCard>
      <div className="p-4" style={{ minWidth: "450px" }}>
        <div className="POP_title px-4 pt-4">
          <h4>
            <strong>이메일(ID) 찾기</strong>
          </h4>
        </div>

        <div className="POP_cnt_box w-100">
          <div
            className="btn-group w-100 mb-4"
            role="group"
            aria-label="Basic example"
          >
            <a
              href="#"
              onClick={clickTab}
              id="buyerId"
              type="button"
              className={`
                btn py-2 ${tabState.buyerId ? "btn_puplelight" : "btn_off"}`}
            >
              바이어
            </a>
            {/*2023.08.08 수정*/}
            <a
              href="#"
              // onClick={clickTab}
              id="makerId"
              type="button"
              className={`btn py-2 ${
                tabState.makerId ? "btn_puplelight" : "btn_off"
              }`}
            >
              제조사
            </a>
          </div>
          {tabState.buyerId ? <BuyerId /> : ""}
          {tabState.makerId ? <MakerId /> : ""}
          <div className="mb-5 log_menu" style={{ textAlign: "right" }}>
            <li>
              <Link to="/find/password">비밀번호찾기</Link>
            </li>
          </div>
          <div className="mt-5 txt-lightgray ">
            Darlibaba 회원이 아니신가요?{" "}
            <Link
              to="/join/intro"
              style={{ color: "#306CC7", textDecoration: "underline" }}
            >
              회원가입
            </Link>
          </div>
        </div>
      </div>
    </LoginCard>
  );
};

export default FindId;
