import React from "react";
import { Link } from "react-router-dom";
import CsCenterCard from "./CsCenterCard";
import first_page_black_48dp from "../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../image/chevron_left_black_24dp.svg";
import navigate_next_black_48dp from "../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../image/last_page_black_48dp.svg";

import "../../sass/main.scss";
import ServicePolicy from "./ServicePolicy";

const Agreement = (props) => {

  return (
    <ServicePolicy>
      <div className="box_round min_h cnt_area">
        <div className="mx-5 my-4">
          <p className="h3 bold border-bottom py-3">이용약관</p>
          <br />
          <div className="basic my-3" dangerouslySetInnerHTML={{__html: `
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;line-height:115%;'><strong><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">제1장 총칙</span></strong></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:3.75pt;text-align:left;line-height:115%;background:white;'><strong><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">제1조&nbsp;(목적)</span></strong></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">이 약관은 주식회사 빅파이씨앤티(이하&nbsp;&ldquo;회사&rdquo;라고 합니다)&nbsp;가&nbsp;&ldquo;달리바바&rdquo;&nbsp;인터넷 서비스 마켓플레이스 사이트&nbsp;(http://www.darlibaba.com,&nbsp;이하&nbsp;&quot;달리바바 사이트&rdquo;라고 합니다)를 통하여 제공하는 중개서비스 및 기타 정보서비스(이하&nbsp;&quot;서비스&quot;라고 합니다)와 관련하여 회사와 회원간의 권리와 의무,&nbsp;책임사항 및 회원의 서비스이용절차에 관한 사항을 규정함을 목적으로 합니다.</span></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h5>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제2조&nbsp;(약관의 명시,&nbsp;효력 및 변경)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">가.&nbsp;회사는 본 약관의 내용과 상호,&nbsp;회사소재지,&nbsp;대표자의 성명,&nbsp;사업자등록번호,&nbsp;연락처 등을 서비스를 이용하고자 하는 자와 회원이 알 수 있도록 서비스가 제공되는 달리바바 사이트의 초기화면(이하 연결화면 포함)에 게시합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">나.&nbsp;회사가 이 약관을 개정하는 경우에는 개정된 약관의 적용일자 및 개정사유를 명시하여 현행 약관과 함께 그 적용일자&nbsp;7일(다만,&nbsp;제5조 가. 2)의 회원에게 불리한 내용으로 변경하는 경우에는&nbsp;30일)&nbsp;이전부터 적용일자 전일까지 위 가항의 방법으로 공지합니다.&nbsp;개정된 약관은 개정약관에서 소급효를 부여하지 않는 이상 적용일자 이전으로 소급하여 적용되지 아니합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">다.&nbsp;회사는 약관의 규제에 관한 법률 정보통신망 이용촉진 및 정보보호에 관한 법률 전자상거래 등에서의 소비자보호에 관한 법률 등 관련법령을 위반하지 않는 범위에서 본 약관을 개정할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">라.&nbsp;회원은 개정 약관에 대해 거절할 권리가 있습니다.&nbsp;회원은 약관의 변경에 대하여 주의 의무를 다하여야 하며 개정 약관에 동의하지 않을 경우 이용계약을 해지할 수 있습니다.&nbsp;다만 개정 약관의 효력발생일까지 회원이 거절의 의사표시를 하지 않았을 때에는 변경약관에 동의한 것으로 봅니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">마.&nbsp;회원은 약관의 변경에 관하여 회사가 공지하거나 통지하는 내용을 확인할 의무가 있으며 개정된 약관에 동의한 회원이 약관의 변경으로 인하여 발생한 피해 및 회원이 과실로 개정된 약관의 내용을 알지 못해 발생하는 회원의 피해는 회사가 책임지지 않습니다.&nbsp;</span></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h5>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제3조&nbsp;(관련법령과의 관계)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">가.&nbsp;회사는 수수료(제29조의 판매서비스이용료 등 회사가 회원에게 서비스를 제공한 대가로 회원으로부터 받는 일체의 금원을 말함)&nbsp;및 판매촉진서비스이용료의 결정과 관련하여 다른 서비스 마켓 플레이스(오픈마켓 형태의 모든 서비스 포함)&nbsp;사업자와 담합 등 불공정행위를 하지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">나.&nbsp;회사는 회원에게 가격인하,&nbsp;기획전 참여 등을 부당하게 강요하지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">다.&nbsp;회사는 회원이 다른 서비스 마켓플레이스(오픈마켓 형태의 모든 서비스 포함)&nbsp;사업자와 거래하지 못 하게 하거나 다른 서비스 마켓플레이스(오픈마켓 형태의 모든 서비스 포함)&nbsp;사업자와 거래한다는 이유로 회원에게 불이익을 제공하지 아니합니다.</span></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h5>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제4조&nbsp;(서비스의 종류)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:justify;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;color:#212529;">회사가 회원에게 제공하는 서비스는 다음과 같습니다.&nbsp;중개 서비스:&nbsp;회사가 달리바바 사이트를 통하여 회원 상호간에 물품매매거래가 이루어질 수 있는 사이버 거래장소를 온라인으로 제공하는 서비스 및 관련 부가서비스 일체를 말합니다.&nbsp;회사는 회원의 물품판매의 촉진을 위한 관련 부가서비스로서 회원의 상품노출을 위한 전시권 등 판매촉진서비스를 유상으로 제공할 수 있습니다.</span></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h5>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제5조&nbsp;(용어의 정의)&nbsp;</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">가.&nbsp;이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">1)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">서비스</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">:&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">달리바바 사이트에서에서 판매 가능한 상품으로 유형의 제품 혹은 무형의 서비스를 말합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">2)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">회원:&nbsp;이 약관을 승인하고 회사와 서비스이용계약을 체결한 자를 말합니다.&nbsp;회원은 가입형태에 따라 개인회원과 사업자회원으로,&nbsp;서비스 이용목적에 따라 바이어회원,&nbsp;제조사회원으로 각 구분되며 상호간 회사가 정하는 바에 따라 전환할 수 있습니다.&nbsp;제조사회원과 바이어회원 모두에게 이 약관이 적용됩니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">3)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">아이디(ID):&nbsp;회원의 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 &ldquo;회사&rdquo;가 승인하는 &ldquo;회원&rdquo;의 이메일 형 문자 또는 숫자의 조합을 말합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">4)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">비밀번호:&nbsp;회원의 동일성 확인과 회원정보의 보호를 위하여 회원이 설정하고 회사가 승인한 문자와 숫자의 조합을 말합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">5)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">서비스 마켓플레이스:&nbsp;무형의 서비스를 사고 팔 수 있는 가상의 온라인 공간을 말합니다.&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">6)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">서비스 마켓플레이스 사업자:&nbsp;서비스 마켓플레이스 및 그 안에서의 부가서비스(광고서비스 등)를 제공하고,&nbsp;이에 대한 대가를 받는 사업자&nbsp;(이하&nbsp;&ldquo;회사&rdquo;라고 합니다)를 말합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">7)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">중개서비스:&nbsp;회사가 달리바바 사이트에서를 통하여 회원들에게 제공하는 회원 간의 중개서비스 및 관련 부가서비스 일체를 말합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">8)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">제조사회원:&nbsp;물품을 판매할 의사로 해당 서비스를 회사가 온라인으로 제공하는 양식에 맞추어 회사의 달리바바 사이트 또는 모바일 달리바바에 등록한 회원을 말합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">9)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">바이어회원:&nbsp;제조사회원의 서비스를 구매한 회원을 말합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">10)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">해지:&nbsp;회사 또는 회원이 중개서비스 이용계약을 장래에 대하여 종료시키는 행위를 말합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">나.&nbsp;위 가항에서 정의되지 않은 이 약관상의 용어의 의미는 일반적인 거래관행에 의합니다.</span></p>
<h4 style="margin-right:0cm;margin-left:36.0pt;font-size:16px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h4>
<h4 style="margin-right:0cm;margin-left:36.0pt;font-size:16px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h4>
<h4 style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">2</span><span style="font-size:15px;line-height:115%;color:#212529;">장 서비스이용계약의 체결</span></h4>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제6조&nbsp;(서비스이용계약)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">가.&nbsp;회사가 제공하는 서비스에 관한 이용계약(이하&nbsp;&quot;이용계약&quot;이라고 합니다)은 해당 서비스를 이용하고자 하는 자의 이용신청(회원가입신청)에 대하여 회사가 승낙함으로써 성립합니다.&nbsp;회사는 이용승낙의 의사를 해당 서비스화면에 게시하거나,&nbsp;메일(e-mail)&nbsp;또는 기타 방법으로 이용신청자에 통지합니다.&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">나.&nbsp;회원가입은 만&nbsp;14세 이상의 개인 또는 사업자(개인사업자 및 법인사업자 등)가 할 수 있습니다.&nbsp;이용신청자는 실명으로 가입신청을 하여야 하며,&nbsp;실명이 아니거나 타인의 정보를 도용하여 회원으로 가입한 자는 회사가 제공하는 서비스를 이용할 수 없으며,&nbsp;이용하여서도 안됩니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><strong><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">제7조&nbsp;(이용신청)</span></strong></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:left;line-height:115%;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">가.&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">(</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">주)빅파이씨앤티 이용자는&nbsp;(주)빅파이씨앤티가 정한 가입 양식에 따라 이 약관에 동의한다는 의사 표시를 한 후 회원정보를 기입함으로서 회원가입을 신청합니다</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:left;line-height:115%;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:left;line-height:115%;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">나.&nbsp;회원으로 가입하고자 하는 자는 본인의 진정한 정보로 이용신청을 해야 하며 허위로 기재하거나 타인의 명의를 도용한 경우 본 약관에 의한 회원의 권리를 주장할 수 없고 회사는 이용계약을 취소하거나 해지할 수 있습니다.</span></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">다.&nbsp;서비스를 이용하고자 하는 모든 자는 실제 연결 가능한 본인 번호를 통해 휴대폰 인증을 진행해야 합니다.&nbsp;또한,&nbsp;실제 거래 진행 또는 서비스 판매 시에는 회사가 요청하는 추가 정보를 기재해야 합니다.</span></h5>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">라.&nbsp;변경된 정보는 다양한 변수에 의해 고객정보에 즉시 반영되지 않을 수 있으며,&nbsp;회원은 이를 인지하고 이로 인해 발생될 수 있는 모든 불이익에 대해 회사의 고의 또는 중과실이 없는 한 회사에 책임을 묻지 않습니다.</span></h5>
<h5 style="margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h5>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제8조&nbsp;(이용신청의 승낙과 제한)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">가.&nbsp;회사는 이용신청자가 제8조에서 정한 필수기재항목을 정확하게 기재하고 이 약관에 동의한 경우에는 이 약관에 규정된 실명확인절차를 거쳐 서비스의 이용을 승낙하는 것을 원칙으로 합니다.&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;line-height:115%;'><span style="font-size:15px;line-height:115%;font-family:굴림;">나.&nbsp;회사는 다음과 같은 사유가 있는 경우,&nbsp;이용신청에 대한 승낙을 거부할 수 있습니다. 1)&nbsp;만&nbsp;14세 미만의 자가 이용신청을 하는 경우&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;line-height:115%;'><span style="font-size:15px;line-height:115%;font-family:굴림;">2)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;">회사의 실명확인절차에서 실명가입신청이 아님이 확인된 경우&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;line-height:115%;'><span style="font-size:15px;line-height:115%;font-family:굴림;">3)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;">이미 가입된 회원과 이름 및 주민등록번호(또는 사업자등록번호,&nbsp;법인등록번호)가 동일한 경우&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;line-height:115%;'><span style="font-size:15px;line-height:115%;font-family:굴림;">4)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;">이 약관 제10조 다항에 기하여 회사가 이용계약을 해지한 전(前)회원이 재이용신청을 하는 경우&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;line-height:115%;'><span style="font-size:15px;line-height:115%;font-family:굴림;">5)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;">이 약관 제15조에 기하여 회사로부터 회원자격정지조치를 받은 회원이 이용정지 중에 이용계약을 임의 해지하고 재이용신청을 하는 경우&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;line-height:115%;'><span style="font-size:15px;line-height:115%;font-family:굴림;">6)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;">이용신청 시 기재하여야 할 필수 기재 항목에 허위사실을 기재한 경우&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;line-height:115%;'><span style="font-size:15px;line-height:115%;font-family:굴림;">7)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;">기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 라.&nbsp;회사는 다음과 같은 사유가 있는 경우,&nbsp;이용신청에 대한 승낙을 유보할 수 있습니다.&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;line-height:115%;'><span style="font-size:15px;line-height:115%;font-family:굴림;">다.&nbsp;회사는<span style="color:#555555;background:white;">&nbsp;</span><span style="color:black;background:white;">다음 각 호의 어느 하나에 해당하는 경우에는 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다</span>.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;line-height:115%;'><span style="font-size:15px;line-height:115%;font-family:굴림;">1)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;">설비에 여유가 없는 경우</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;line-height:115%;'><span style="font-size:15px;line-height:115%;font-family:굴림;">2)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;">기술상 지장이 있는 경우</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;line-height:115%;'><span style="font-size:15px;line-height:115%;font-family:굴림;">3)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;background:white;">그 밖에 위 각 호에 준하는 사유로 이용신청의 승낙이 곤란한 경우</span><span style="font-size:15px;line-height:115%;font-family:굴림;">&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;line-height:115%;'><span style="font-size:15px;line-height:115%;font-family:굴림;">&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;line-height:115%;'><strong><span style="font-size:15px;line-height:115%;font-family:굴림;">제9조&nbsp;(이용계약의 종료)</span></strong></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">가.&nbsp;회원 또는 회사는 이 약관에서 정한 절차에 따라 이용계약을 해지할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">나.&nbsp;회원의 해지</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">1)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">회원은 언제든지 회사에게 해지의사를 통지함으로써 이용계약을 해지할 수 있습니다.&nbsp;회사는 온라인으로 이용계약을 해지할 수 있는 절차를 제공합니다.&nbsp;다만,&nbsp;바이어회원은 해지의사를 통지하기 전 모든 구매 중인 절차를 완료,&nbsp;철회 또는 취소해야만 하며,&nbsp;회원은 해지의사를 통지하기 전 진행 중인 모든 판매 절차를 마무리하고 서비스 이용에 따른 회사에 대한 채무를 정산하여야 합니다.&nbsp;이 경우 거래 건의 철회 또는 취소로 인한 불이익은 회원 본인이 부담하여야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">2)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">이용계약은 회원의 해지의사가 회사에 도달한 때에 종료됩니다.&nbsp;다만 회원이 제1)항의 조치를 마치지 않은 경우 위&nbsp;1)항의 조치가 완료된 때에 종료됩니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">3)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">본 항에 따라 해지를 한 회원은 이 약관이 정하는 회원가입절차와 관련조항에 따라 회원으로 다시 가입할 수 있습니다.&nbsp;다만 회원이 중복참여가 제한된 판촉이벤트 중복 참여 등 부정한 목적으로 회원탈퇴 후 재가입 신청하는 경우 회사는 재가입을 일정기간 동안 제한할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">다.&nbsp;회사의 해지</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">1)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">회사는 다음과 같은 사유가 있는 경우,&nbsp;이용계약을 해지할 수 있습니다.&nbsp;이 경우 회사는 회원에게 메일(e-mail),&nbsp;전화,&nbsp;팩스 기타의 방법을 통하여 해지사유를 밝혀 해지의사를 통지합니다.&nbsp;이 경우 회사는 해당 회원에게 사전에 해지사유에 대한 의견진술의 기회를 부여할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">①&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">회원에게 제9조 다항에서 정하고 있는 이용신청의 승낙거부사유가 있음이 확인된 경우</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">②&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">회원이 이 약관 제21조가 정하고 있는 매매 부적합 서비스를 판매 등록하거나,&nbsp;기타 공공질서 및 미풍양속에 위배되는 물품거래행위를 하거나 시도한 경우</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">③&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">회원이 회사나 다른 회원 기타 타인의 권리나 명예,&nbsp;신용 기타 정당한 이익을 침해하는 행위를 한 경우</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">④&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">회원이 직거래 등 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">⑤&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">회원이 실제로 서비스를 판매하고자 하는 의사 없이 서비스등록을 한 경우(이하&nbsp;&quot;판매 가장 등록&quot;이라 합니다)&nbsp;또는 이를 알고 구매한 것으로 인정되는 경우</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">⑥&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">회원에게 파산&middot;금치산&middot;한정치산&middot;회생&middot;파산의 결정 또는 선고,&nbsp;사망,&nbsp;실종선고,&nbsp;해산,&nbsp;부도 등 정상적 서비스 이용을 불가능하게 하거나 곤란하게 하는 사유가 발생한 경우 다만,&nbsp;이 경우 회사는 이용계약 해지 대신 거래안전 보호를 위해 회원자격정지,&nbsp;서비스이용제한 등의 조치를 취할 수 있습니다)</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">⑦&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">기타 회원이 이 약관에 위배되는 행위를 하거나 이 약관에서 정한 해지사유가 발생한 경우</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">2)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">이용계약은 회사의 해지의사가 회원에게 도달한 때에 종료되나,&nbsp;회원이 제공한 연락처 정보의 오류로 인하여 회사가 해지의사를 통지할 수 없는 경우에는 회사는 통지 없이 즉시 이용계약을 해지할 있고 이에따라 발생하는 문제에 대해서는 어떠한 책임도 지지 않습니다.&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">3)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">회사가 이용계약을 해지를 통지 내지 공지하는 경우,&nbsp;회사는 즉시 별도의 통지 없이 해당 회원과 관련된 매매를 취소할 수 있고,&nbsp;바이어회원이 서비스대금을 신용카드로 결제한 경우에는 그 신용카드매출을 취소할 수 있습니다.&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">4)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">회사가 이용계약을 해지하는 경우,&nbsp;제조사회원은 바이어회원의 보호를 위하여 해지 시까지 완결되지 아니한 주문 건의 발송,&nbsp;교환,&nbsp;환불,&nbsp;하자보수 등 필요한 조치를 취하여야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">라.&nbsp;이용계약이 종료되는 경우,&nbsp;회사는 해당 회원의 현금성 예치금 및 수익금을 모두 정산 후,&nbsp;잔존 금액을 회사는 해당 회원이 지정한 입금계좌로 그 금액을 즉시 송금하여 계약종료처리 합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">마.&nbsp;이용계약이 종료된 시점에서,&nbsp;이미 구매확정된 거래 건에 대한 분쟁이 발생될 경우 이에 대한 책임은 회원이 부담하여야 하고,&nbsp;회사는 회사의 고의 또는 과실이 없는 한 책임이 없습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><strong><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">제3장 회원정보의 보호</span></strong></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제10조&nbsp;(회원정보의 수집과 보호)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">가.&nbsp;회사는 이용계약을 위하여 회원이 제공한 정보 외에도 수집목적 또는 이용목적 등을 밝혀 회원으로부터 필요한 정보를 수집&middot;이용할 수 있습니다.&nbsp;이 경우,&nbsp;회사는 회원으로부터 정보수집&middot;이용에 대한 동의를 받습니다.&nbsp;회사는 개인정보의 수집&middot;이용&middot;제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다.&nbsp;또한 개인정보의 수집&middot;이용&middot;제공에 관한 회원의 동의거절 시 제한되는 서비스를 구체적으로 명시하고,&nbsp;필수수집항목이 아닌 개인정보의 수집&middot;이용&middot;제공에 관한 이용신청자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">나.&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">회사가 정보수집&middot;이용을 위하여 회원의 동의를 받는 경우,&nbsp;회사는 정보의 수집목적 및 이용목적,&nbsp;제3자에 대한 정보제공 관련사항(제공받는 자,&nbsp;제공목적,&nbsp;제공정보의 내용)을 미리 명시하거나 고지합니다.&nbsp;회원은 정보제공에 동의하더라도 언제든지 그 동의를 철회할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">다.&nbsp;회원은 회사에게 정보를 사실대로 제공하여야 합니다.&nbsp;회사는 회원이 이 약관 제8조 가항에 따라 제공한 정보의 정확성을 확인하기 위하여 관련법령이 허용하는 범위 내에서 증빙자료의 제공을 요청할 수 있고,&nbsp;회원이 정당한 사유 없이 증빙자료를 제공하지 않는 경우 회사는 서비스의 이용을 제한하거나 이용계약을 해지할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">라.&nbsp;회사는 메일 등 서비스화면을 통하여 공개되는 정보를 제외하고는 회사가 수집한 회원정보를 정보수집 시에 밝힌 수집목적,&nbsp;이용목적,&nbsp;원활한 서비스의 제공 및 제공서비스의 확대 외의 용도로 사용하거나 제3자에게 제공하지 않습니다.&nbsp;회사는 정보수집 시에 밝힌 이용목적 이외에 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용∙제공 단계에서 당해 회원에게 그 목적을 고지하고 동의를 받습니다,&nbsp;다만,&nbsp;관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">마.&nbsp;회사의 개인정보 보호책임자 및 연락처는 달리바바 사이트에서 별도로 공개하는 개인정보처리방침에서 공지합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">바.&nbsp;회원은 언제든지 자신에 관한 정보를 열람할 수 있고,&nbsp;회사 또는&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">정보보호책임자에게 잘못된 정보에 대한 정정을 요청할 수 있습니다.&nbsp;회사는 회원으로부터 정보정정의 요청이 있는 경우 그 오류를 정정하거나 정정하지 못하는 사유를 회원에게 통지하는 등 필요한 조치를 취할 때까지 당해 정보를 이용하지 않습니다.&nbsp;다만,&nbsp;다른 법률에 따라 개인정보의 제공을 요청을 받은 경우에는 그러하지 아니합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">사.&nbsp;회사는 회원정보의 보호를 위하여 관리자를 최소한으로 한정하며,&nbsp;회사의 고의 또는 과실로 인하여 회원정보가 분실,&nbsp;도난,&nbsp;유출,&nbsp;변조되거나 위법하게 제3자에게 제공된 경우에는 그로 인한 회원의 손해에 대하여 모든 책임을 부담합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">아.&nbsp;이용계약이 종료된 경우,&nbsp;회사는 당해 회원의 정보를 파기하는 것을 원칙으로 합니다.&nbsp;다만,&nbsp;아래의 경우에는 회원정보를 보관합니다.&nbsp;이 경우 회사는 보관하고 있는 회원정보를 그 보관의 목적으로만 이용합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">①&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">상법,&nbsp;전자상거래등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">②&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">비방이나 허위사실 유포 등으로 타인에게 피해를 입힌 경우,&nbsp;지적재산권 침해상품 판매 기타 인터넷 사기행위 등으로부터 회원과 회사를 보호하고 법적 절차에 따른 수사 협조를 위한 목적 등으로 회사는 이용계약 종료 후 2개월간 내역이 존재하는 회원의 메일,&nbsp;성명 또는 상호,&nbsp;연락처,&nbsp;그 외 제공받은 개인정보,&nbsp;해지 및 회원자격정지 관련정보 등 필요한 정보를 보관합니다.&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">③&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">기타&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">정보수집에 관한 동의를 받을 때 보유기간을 명시한 경우에는 그 보유기간까지 회원정보를 보관합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">자.&nbsp;회사가 제3자에게 회원의 개인정보를 제공할 필요가 있는 경우 회사는 실제 구매신청시(바이어 회원)&nbsp;또는 서비스등록시(제조사 회원),&nbsp;제공되는 개인정보 항목,&nbsp;제공받는 자,&nbsp;제공받는 자의 개인정보 이용목적 및 보유&middot;이용기간 등을 명시하여 회원의 동의를 받고,&nbsp;개인정보의 수집&middot;처리&middot;관리 등을 위탁하는 경우에는 위탁업무의 내용 및 수탁사의 명칭 등에 관한 사항을&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">서면,&nbsp;전자우편,&nbsp;모사전송,&nbsp;전화 또는 홈페이지를 통해 미리 회원에게 고지하여 동의를 받거나 개인정보처리방침에 포함하여 달리바바 사이트&nbsp;(http://www.darlibaba.com)에서에 공개합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">차.&nbsp;회사는 회원정보의 보호와 관리에 관한 개인정보처리방침을 회원과 회사의 서비스를 이용하고자 하는 자가 알 수 있도록 달리바바 사이트(http://www.darlibaba.com)에서에 공개합니다.</span></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h5>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제11조(회원정보의 변경)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">가.&nbsp;회사는 회원정보의 보호와 관리에 관한 개인정보처리방침을 회원과 회사의 서비스를 이용하고자 하는 자가 알 수 있도록 달리바바 사이트(http://www.darlibaba.com)에 공개합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">나.&nbsp;회사의 회원에 대한 통지는 회원정보에 기재된 메일(e-mail)주소로 통지된 것으로 보며,&nbsp;통지되었음에도 해당 사항을 수정하지 않음으로써 발생한 각종 손해와 잘못된 수정으로 인하여 발생한 손해는 당해 회원이 부담하여야 하며,&nbsp;회사는 이에 대하여 아무런 책임을 지지 않습니다.&nbsp;또한,&nbsp;회원이 변경된 정보를 수정하지 않거나 또는 허위 내용으로 수정하여 회사에게 손해를 입힌 경우에는 이에 대한 손해배상 책임을 부담합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">다.&nbsp;위 가.항에도 불구하고 회원이 이 약관 제24조 가항에 따라 휴대전화번호 인증을 한 경우에는 인증된 휴대전화번호가 회원정보로 변경(update)&nbsp;처리됩니다<span style="background:yellow;">.</span>&nbsp;</span></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h5>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제12조&nbsp;(아이디(ID)&nbsp;및 닉네임 관리)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">가.&nbsp;메일(e-mail)주소 및 비밀번호에 대한 보호책임은 회원에게 있으며,&nbsp;회원은 메일(e-mail)&nbsp;주소 및 비밀번호를 타인에게 양도,&nbsp;대여할 수 없습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">나.&nbsp;회사의 귀책사유에 의하지 아니한 메일(e-mail)&nbsp;주소 또는 비밀번호의 유출,&nbsp;양도,&nbsp;대여로 인한 손실이나 손해에 대하여 회사는 아무런 책임을 지지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">다.&nbsp;메일(e-mail)주소 또는 비밀번호를 도난당하거나 제3자가 이를 사용하고 있음을 인지한 경우,&nbsp;즉시 회사에게 통보하여야 하고,&nbsp;회사의 안내가 있는 경우에는 그에 따라야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">&nbsp;</span></p>
<h4 style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제4장 중개서비스의 이용</span></h4>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제13조&nbsp;(중개서비스의 종류 및 이용)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:justify;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;color:#212529;">회사가 제공하는 중개서비스는 제조사회원의 등록 상품에 대한 정보를 달리바바 사이트에 노출하여 정보를 중개하는 서비스 등이 있습니다.&nbsp;중개서비스의 종류와 내용은 회사의 정책에 따라 추가,&nbsp;변경될 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:72.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:justify;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;color:#212529;">&nbsp;</span></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제14조(중개서비스 이용정지,&nbsp;이용제한)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:justify;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;color:#212529;">회사는 중개서비스를 통한 거래의 안전성과 신뢰성을 위하여 이 약관에서 정한 바에 따라 회원자격을 정지하거나 서비스의 이용 제한,&nbsp;기타 필요한 조치를 취할 수 있습니다.&nbsp;회사가 회원자격을 정지하거나,&nbsp;기타 필요한 조치를 취할 경우 회사는 이 약관에 특별한 규정이 없는 한 사전에 회원에게 유선 또는 메일(e-mail),&nbsp;기타의 방법을 통하여 통보하며,&nbsp;회원의 연락이 두절되거나 긴급을 요하는 것과 같이 부득이한 경우 선 조치 후 통보할 수 있습니다.</span><span style="font-size:15px;line-height:115%;color:red;">&nbsp;</span><span style="font-size:15px;line-height:115%;color:#212529;">정지된 회원은 중개서비스를 이용할 수 없으며,&nbsp;중개서비스의 이용이 제한된 회원은 모든 서비스를 이용할 수 없습니다.</span></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h5>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제15조&nbsp;(대리 및 보증의 부인)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:justify;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;color:#212529;">회사는 통신판매중개자로서 회원 상호간의 거래를 위한 온라인 거래장소(marketplace)&nbsp;를 제공할 뿐이므로 물품을 판매하거나 구매하고자 하는 회원을 대리하지 않습니다.&nbsp;또한,&nbsp;회사의 어떠한 행위도 제조사회원 또는 바이어회원을 대리하는 행위로 간주되지 않습니다.</span></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h5>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제16조&nbsp;(제조사회원의 권리)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:justify;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;color:#212529;">제조사회원은 판매하는 서비스의 종류와 범위 등을 스스로 결정하고,&nbsp;회사는 이에 부당하게 관여하지 않습니다.&nbsp;다만,&nbsp;회사는 서비스발송 방식 등 바이어회원 피해 방지에 관한 사항을 약관 등으로 정할 수 있습니다.</span></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h5>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제17조&nbsp;(제조사회원의 의무)&nbsp;</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">가.&nbsp;제조사회원은 이 약관과 회사가 서비스화면을 통하여 미리 공지하는 개별약관이 정하는 절차에 따라 신원정보의 제공,&nbsp;서비스의 등록,&nbsp;거래진행과정의 관리,&nbsp;바이어회원에 대한 거래이행 등을 수행하여야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">나.&nbsp;제조사회원은 회원의 질문에 성실히 응해야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">다.&nbsp;제조사회원은 회사의 명칭,&nbsp;로고 및 회원의 회원등급 표시와 회사가 제공한 메인 이미지 등을 회사가 정한 목적 이외 용도 및 장소에 사용하거나 표시해서는 안되며,&nbsp;이러한 행위로 인하여 회사 또는 타 회원에게 피해가 발생한 경우 이에 대하여 일체의 법적 책임을 부담하여야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">라.&nbsp;제조사회원은 자신의 제조사회원 정보란에 회사가 정하는 절차에 따라 인증 받은 휴대폰 번호를 대표번호로서 설정하고 항상 최신 정보로서 유지하여야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">마.&nbsp;제조사회원은 통신판매중개 의뢰 및 그와 관련한 계약이행 과정에서 알게 된 바이어회원 등 다른 회원의 개인정보를 법률,&nbsp;이 약관 또는 회사의 개인정보처리방침에서 정한 목적 외의 용도로 사용할 수 없으며,&nbsp;이를 위반할 경우,&nbsp;모든 법적 책임을 지고 자신의 노력과 비용으로 회사를 면책시켜야 하고 회원자격이 정지 또는 박탈될 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">바.&nbsp;회사의 고의 또는 과실과 무관하게,&nbsp;특정 제조사회원이 처리&middot;처리 중인 다른 회원 또는 제3자의 개인 정보가 침해된 경우,&nbsp;회사는 그에 대하여 책임을 지지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">사.&nbsp;제조사회원은 달리바바를 통해 바이어회원에게서 온 문의 연락 등을 지속적으로 체크하여 빠르고 성실하게 응대해야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">아.&nbsp;회사는 제조사회원이 등록한 서비스의 판매 촉진 및 신뢰도 상승을 위해 서비스의 메인 이미지 또는 프로필 이미지 등에 대한 등록 기준을 마련하여 제조사회원에게 서비스 수정을 요청할 수 있으며,&nbsp;제조사회원은 이에 동의하여야 합니다.&nbsp;만일 이에 동의하지 않을 경우 회사는 제조사회원의 서비스 판매를 중단하거나 신규 서비스 등록을 제한할 수 있습니다.</span></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h5>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제18조&nbsp;(판매 서비스의 등록)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">가.&nbsp;등록자격</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">서비스를 판매하고자 하는 회원은 이 약관에 따라 서비스를 등록할 수 있습니다.&nbsp;다만,&nbsp;회사는 소비자보호 및 서비스 수준 유지를 위해 일부 카테고리 서비스의 경우 서비스를 등록할 수 있는 제조사회원의 자격을 제한할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">나.&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">서비스</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">의 등록&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">1)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">제조사회원은 판매서비스의 등록 시 바이어회원이 구매서비스에 대한 정보를 확인할 수 있도록 다음과 같은 사항을 정확하게 기재하여야 하며,&nbsp;회사가 승인하지 아니한 시스템을 통하여 회사 정보통신망에 무단 접속하여 자동으로 등록하는 방법으로 서비스를 등록할 수 없습니다.&nbsp;회사는 서비스검색의 효율성,&nbsp;시스템에 걸리는 부하 등을 고려하여 제조사회원에 대한 사전 통지로써 제조사회원&nbsp;1인당 서비스 등록 건수를 제한할 수 있습니다.&nbsp;제조사회원이 회사의 물품 등록건수 제한조치에 반하여 서비스등록을 한 경우 회사는 등록된 서비스를 중지하거나 삭제할 수 있고 위반횟수 및 수준에 따라 해당 서비스를 등록한 제조사회원의 회원자격을 정지하거나 서비스 이용을 제한할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">①&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">제조사회원 정보:&nbsp;제조사회원은 실제 연락 가능한 번호와 제조사회원에 대한 사업자 정보를 작성해야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">② 서비스의 상세정보:&nbsp;서비스에 대한 상세정보는 사실대로 명확하게 기재하여야 합니다.&nbsp;또한 실수 또는 착오 없이 거래하는 데 필요한 거래조건을 구체적으로 명시하여야 합니다.&nbsp;허위 또는 과장 정보를 기재한 경우,&nbsp;모순되는 내용의 정보가 기재되어 있는 경우,&nbsp;이 약관에 반하는 거래조건이 기재된 경우 회사는 해당 거래를 취소하거나 판매중지 시킬 수 있습니다.&nbsp;거래가 취소되거나 판매 중지가 되면,&nbsp;회사는 서비스화면 또는 메일(e-mail)&nbsp;등의 방법을 통하여 해당 제조사회원과 바이어회원에게 그러한 사실을 통지합니다.</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:red;">&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">③ 서비스 등의 정보:&nbsp;서비스 등록 후 상품정보가 변경되는 경우 그에 맞추어 관련 정보를 수정,&nbsp;보완하여야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">2)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">서비스는 등록 이후에도 수정이 가능합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">3)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">제조사회원은 서비스등록을 함에 있어서 저작권,&nbsp;초상권 등 제3자의 권리를 침해해서는 안 됩니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">4)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">제조사회원이 제조사회원 본인의 아이디 또는 다른 회원의 아이디를 이용하여 서비스 및 거래조건이 실질적으로 동일한 서비스&nbsp;(&quot;동일 서비스&quot;)을 둘 이상 중복하여 등록하거나,&nbsp;형식상 별개의 제조사회원임에도 회사가 사전에 고지하는 기준에 상응하는 동일 서비스를 등록할 수 없습니다.&nbsp;이에 위반한 서비스등록에 대해 회사는 서비스검색의 효율성 제고 등을 위해 일정한 절차를 거쳐 중복 등록된 서비스를 판매중단 또는 삭제 처리 할 수 있고 위반 횟수 및 수준에 따라 해당 서비스를 등록한 제조사회원의 회원자격을 정지 또는 서비스 이용을 제한할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">라.&nbsp;판매 서비스등록정보 게재</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">회사는 제조사회원이 등록한 서비스등록정보를 회사가 정한 기준과 방법에 따라 달리바바 사이트에 게재합니다.&nbsp;회사는 게재하는 서비스등록정보의 위치,&nbsp;크기,&nbsp;배열 등을 결정하고 조정할 수 있으며,&nbsp;이벤트 광고 등 회사의 서비스를 위하여 해당 서비스화면을 구성,&nbsp;편집하거나 서비스등록 정보 외의 사항을 게재할 수 있습니다.</span></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h5>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제19조&nbsp;(매매부적합서비스)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">가.&nbsp;도움요청 관리</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">회사는 등록된 서비스가 온라인상에서의 매매,&nbsp;유통에 적합하지 않은 서비스인 경우에는 직권 또는 권리주장자의 요청에 따라 해당 서비스에 대한 승인을 거절하거나 삭제,&nbsp;취소,&nbsp;중지할 수 있습니다.&nbsp;이 경우 회사는 회원에게 유선 또는 메일(e-mail),&nbsp;기타의 방법을 통하여 통보하며,&nbsp;회원의 연락이 두절되거나 긴급을 요하는 것과 같이 부득이한 경우 선 조치 후 사후 통보할 수 있습니다.&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">나.&nbsp;도움요청 거절</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">회사는 등록된 서비스가 법령 또는 약관에 위반되거나,&nbsp;공서양속을 저해하거나 기타 탈법행위와 관련되거나 그러한 목적이 있는 경우 또는 회사의 정책상 필요에 의한 경우에는 직권으로 이에 대한 승인을 거절하거나 삭제,&nbsp;취소,&nbsp;중지할 수 있습니다.&nbsp;등록부적합서비스는 등록불가서비스와 등록제한서비스로 구분됩니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">①&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">등록불가서비스:&nbsp;지적재산권 침해서비스,&nbsp;미등록 영상매체물 등 관련법령상 판매 또는 유통이 불가한 서비스를 말합니다.&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">②&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">등록제한서비스:&nbsp;서비스의 판매방식,&nbsp;판매장소 또는 판매 대상자 등에 대한 법적 제한이 있는 서비스,&nbsp;소비자에게 피해가 발생할 염려가 있는 서비스,&nbsp;원활한 거래진행을 방해하는 서비스,&nbsp;기타 사회통념상 매매에 부적합하거나 회사의 정책에 의하여 매매가 제한되는 서비스를 말합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">다.&nbsp;회사는 등록된 서비스가 바이어회원 또는 제3자에게 위해 또는 손해를 발생시키거나 발생시킬 우려가 있다고 인정되는 경우 아래와 같은 조치들을 취할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">①&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">해당 서비스 및 해당 서비스와 동일 또는 유사한 카테고리에 등록된 해당 제조사회원의 다른 서비스 등록의 삭제,&nbsp;취소 또는 중지</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">②&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">해당 제조사회원의 위 제1호에 해당하는 신규 서비스 등록 제한</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">③&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">해당 제조사회원의 회원자격 정지 또는 중개서비스 이용 제한</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">라.&nbsp;회사는 권리의 보호를 위하여,&nbsp;권리주장자가 회사가 정한 절차 및 방식에 따라 신고 또는 요청을 하는 경우,&nbsp;당해 서비스를 삭제,&nbsp;취소 또는 판매 중지하고 이를 해당 서비스의 제조사회원에게 통지합니다.&nbsp;이러한 경우 회사는 당해 권리주장자의 신원,&nbsp;신고 또는 요청이 회사의 절차 및 방식에 부합하는지 여부만을 서면으로 심사할 책임을 지는 것에 그치며,&nbsp;당해 권리의 실질적 유효성 및 범위,&nbsp;당해 권리의 주체 및 동 권리에 관련된 계약 또는 실질적 관계 등에 대한 심사를 행하지 않습니다.&nbsp;회사는 권리주장자의 신고 또는 요청에 의한 서비스등록의 삭제,&nbsp;취소 또는 판매 중지에 대해 일체의 책임을 지지 않으며,&nbsp;이는 권리주장자와 제조사회원 사이에서 해결되어야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">마.&nbsp;회사가 직권 또는 권리주장자의 신고 또는 요청에 따라 해당 서비스를 삭제,&nbsp;취소하거나 중지한 경우,&nbsp;제조사회원은 이에 대한 이의를 회사가 정한 절차와 방식에 따라 소명함으로써 회사의 조치에 대한 중단을 요청할 수 있습니다.&nbsp;이 경우 회사는 판매의 재개,&nbsp;재등록 등의 조치를 취하고 이를 권리주장자에게 통보합니다.&nbsp;제조사회원의 소명이 있는 경우,&nbsp;회사는 해당 소명이 회사가 정한 절차와 방식에 부합하는 지 여부만을 서면으로 심사하는 것에 그치며 해당 소명의 유효성,&nbsp;적법성,&nbsp;타당성 및 제조사회원의 권리 등에 대한 심사를 행하지 않습니다.&nbsp;회사는 제조사회원의 소명에 따른 판매의 재개,&nbsp;재등록 등에 대하여 권리주장자 기타 제3자에게 일체의 책임을 지지 않으며,&nbsp;이는 권리주장자와 제조사회원 사이에서 해결되어야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">바.&nbsp;등록부적합서비스의 등록을 이유로 서비스가 삭제,&nbsp;취소되거나 중지된 경우,&nbsp;그 등록에 사용된 상위노출광고 서비스이용료는 환불되지 않습니다.</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:red;">&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">사.&nbsp;회사는 등록부적합서비스 여부를 확인하기 위하여(서비스 또는 서비스등록정보 등에 대하여 이의,&nbsp;신고가 접수된 경우를 포함)&nbsp;해당 거래 진행을 일시 중지할 수 있습니다.&nbsp;이 경우,&nbsp;회사는 제조사회원과 바이어회원에게 중지사실을 통지합니다.&nbsp;회사는 이후 등록부적합서비스가 아닌 것으로 확인된 경우 즉시 해당 서비스가 정상적으로 진행될 수 있도록 조치합니다.</span></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h5>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제20조&nbsp;(서비스등록규정)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:justify;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;color:#212529;">서비스등록은 기간의 제한 없이 진행됩니다.&nbsp;다만,&nbsp;회사에 의하여 직권으로 해당 등록이 판매중지 처리가 될 수 있습니다.</span></p>
<h4 style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h4>
<h4 style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제5장 부가서비스 및 서비스이용료</span></h4>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제21조&nbsp;(서비스이용료)&nbsp;</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">가.&nbsp;회사가 부과하는 서비스이용료는 서비스의 광고효과 및 회사가 제공하는 시스템이용료로서 상위노출광고서비스이용료로 구분됩니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">나.&nbsp;상위노출광고서비스이용료</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">1)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">광고성 부가서비스에 관한 서비스이용료인 상위노출광고 서비스이용료는 거래 여부와 관계없이 선불로 부과되며 환불되지 않습니다.&nbsp;회사가 제공하는 서비스 또는 정보통신시스템의 하자로 인하여 광고등록이 정상적으로 이루어지지 않을 경우 환불합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">2)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">상위노출광고 서비스이용료는 당해 서비스가 제공되는 웹페이지의 서비스화면에서 과금기준,&nbsp;결제방식 등을 표시합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">라.&nbsp;회사는 필요한 경우 서비스이용료를 신설,&nbsp;변경할 수 있으며,&nbsp;신설 또는 변경사항은 회사가 제공하는 서비스화면을 통하여 공지합니다.&nbsp;회사는 판매 활성화 등을 위하여 서비스이용료 범위 내에서 특정 서비스에 대한 서비스이용료를 할인할 수 있습니다.&nbsp;또한 회사는 특정 제조사회원에 대한 서비스이용료를 서비스의 성격,&nbsp;판매실적,&nbsp;회원의 특성 등 회사가 정하는 일정한 기준과 절차에 따라 조정 또는 면제할 수 있습니다.&nbsp;특정 제조사회원에 대한 서비스이용료의 조정 또는 면제 시 회사는 미리 그에 관한 주요 사항을 대상자에게 고지 또는 약정할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">마.&nbsp;회사는 월 서비스이용료에 대한 세금계산서를 익월 초 일괄 발행합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><strong><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">제22조&nbsp;(정보 제공 및 광고의 게재)</span></strong></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">가.&nbsp;회사는 회원의 편의를 위하여 회원의 서비스 이용 중 필요하다고 인정되는 다양한 정보 및 광고를 배너 게재,&nbsp;전자우편,&nbsp;서신, SMS, LMS,&nbsp;전화 등의 방법으로 회원에게 제공할 수 있습니다.&nbsp;회원은 이를 원하지 않을 경우 회사가 제공하는 방법에 따라 수신을 거부할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">나.&nbsp;회사는 전항에 따른 수신 거부 회원의 경우라도 이용약관,&nbsp;개인정보보호정책,&nbsp;기타 회원의 이익에 영향을 미칠 수 있는 중요한 사항의 변경 등 회원이 반드시 알고 있어야 하는 사항에 대해서는 전항의 방법으로 정보를 제공할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">다.&nbsp;달리바바 서비스에는 다양한 형태의 광고가 포함될 수 있으며,&nbsp;이는 제&nbsp;3자가 제공하는 페이지와 연결될 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">라.&nbsp;다.항에 따라 제3자가 제공하는 페이지로 연결될 경우,&nbsp;해당 페이지는 달리바바의 서비스 영역이 아니므로 달리바바가 신뢰성,&nbsp;안정성 등을 보장하지 않으며 그러 인한 회원의 손해에 대해서도 책임지지 않습니다</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">마.&nbsp;회사는 서비스 상에 게재되어 있으나 서비스를 통한 제3자와의 판촉활동에 회원이 참여하거나 교신 또는 거래의 결과로서 발생하는 모든 손실 또는 손해에 대해 관련법에 특별한 규정이 있거나 회사의 고의 또는 중과실로 인한 경우가 아닌 한 책임을 지지 않습니다.</span></p>
<h4 style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h4>
<h4 style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제6장 손해배상,&nbsp;면책조항 등</span></h4>
<h4 style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제23조&nbsp;(손해배상)</span></h4>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">가.&nbsp;회사 또는 회사의 피고용인,&nbsp;대리인,&nbsp;기타 도급 및 위임 등으로 회사를 대신하여 이용계약을 이행하는 자의 책임 있는 사유로 이용계약의 이행과 관련하여 회원에게 손해가 발생한 경우,&nbsp;회사는 회원에게 발생한 손해를 배상할 책임이 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">나.&nbsp;회원 또는 회원의 피고용인,&nbsp;대리인,&nbsp;기타 도급 및 위임 등으로 회원을 대신하여 이용계약을 이행하는 자의 책임 있는 사유로 이용계약의 이행과 관련하여 회사에게 손해가 발생한 경우,&nbsp;회원은 회사에게 발생한 손해를 배상할 책임이 있습니다.</span></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h5>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제24조&nbsp;(회사의 면책)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">가.&nbsp;회사가 통신판매중개자로서 회원들에게 제공하는 서비스는 온라인 거래장소(marketplace)를 제공하거나 안전한 결제 수단을 제공하고 기타 부가정보를 제공함에 그치는 것이므로 중개서비스를 통하여 이루어지는 회원 상호간의 거래와 관련된 서비스 판매진행의 관리,&nbsp;바이어회원에 대한 거래이행,&nbsp;서비스발송,&nbsp;청약철회 또는 반품,&nbsp;취소요청으로 인한 분쟁해결 등 필요한 사후처리는 거래당사자인 회원들이 직접 수행하여야 합니다.&nbsp;회사는 회사의 고의 또는 중과실이 없는 한 이에 대하여 관여하지 않으며 어떠한 책임도 부담하지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">나.&nbsp;회사는 천재지변 또는 이에 준하는 불가항력,&nbsp;정보통신설비의 보수점검,&nbsp;교체 또는 고장,&nbsp;통신의 두절 등으로 인하여 일시적 또는 종국적으로 서비스를 제공할 수 없는 경우,&nbsp;회사의 고의 또는 중과실이 없는 한 서비스 제공에 관한 책임이 면제됩니다.&nbsp;이 경우 회사는 회사가 제공하는 달리바바 사이트 화면에 게시하거나 기타의 방법으로 회원들에게 통지합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">다.&nbsp;회사는 서비스 이용자 또는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">라.&nbsp;회사는 중개서비스를 통하여 거래되는 서비스의 하자,&nbsp;서비스등록정보의 오류,&nbsp;미비 등으로 인하여 회원이 입는 손해에 대해서는 회사의 고의 또는 중과실이 없는 한 책임(제조물책임 포함)을 지지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">마.&nbsp;회사는 회원이 게재한 정보,&nbsp;자료,&nbsp;사실의 허위성,&nbsp;위법성이 객관적으로 확인된 경우를 제외하고 서비스 화면에 표시되는 정보 등의 정확성,&nbsp;안정성,&nbsp;타당성 등을 보증하지 않으며,&nbsp;그와 관련하여 회사의 고의 또는 중과실이 없는 한 회사는 책임지지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">바.&nbsp;회사와 피연결회사(회사의 서비스 화면과 링크 등으로 연결된 사이트를 운영하는 회사를 말합니다)는 독자적으로 운영되며,&nbsp;회사는 피연결회사와 회원간에 이루어진 거래에 대하여는 책임을 지지 않습니다.</span></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h5>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제25조&nbsp;(준거법 및 관할법원)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:justify;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;color:#212529;">이 약관과 회사와 회원간의 이용계약,&nbsp;회원 상호간의 물품거래에 대해서는 대한민국 법령이 적용되며,&nbsp;이 약관,&nbsp;회사와 회원간의 서비스 이용계약 및 회원 상호간의 서비스 거래에 관한 해석과 관할법원에 대하여는 대한민국 법령에 따릅니다.</span></p>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;font-weight:normal;">&nbsp;</span></h5>
<h5 style="margin-right:0cm;margin-left:0cm;font-size:13px;font-family:굴림;font-weight:bold;margin-top:0cm;margin-bottom:3.75pt;line-height:115%;background:white;"><span style="font-size:15px;line-height:115%;color:#212529;">제26조&nbsp;(기타 조항)</span></h5>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">가.&nbsp;회사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 문제를 해결하기 위하여 고객센터를 설치,&nbsp;운영합니다.&nbsp;회사는 회원으로부터 제기되는 불만사항 및 의견이 정당하다고 판단되는 경우 이를 신속하게 처리하며, 3영업일 이내에 그 진행경과를 알리고&nbsp;10영업일 이내에 조사결과와 처리방안을 통보합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">나.&nbsp;본 서비스를 위반하거나 서비스 이용 시 불편 사항이 발생한 경우 주식회사 빅파이씨앤티의&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">&quot;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">고객센터&quot;&nbsp;서비스&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">또는 bigpie_global@jungdari.com으로 알려주시면 성심껏 처리해 드리겠습니다.&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">다.&nbsp;회사소개</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">1)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">주식회사 빅파이씨앤티</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">2)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">소재지:&nbsp;경기도 부천시 조마루로 385번길 122&nbsp;삼보테크노타워 1820호&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">3)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">대표:&nbsp;이중엽</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">4)&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">사업자 등록번호: 169-81-00496</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">준수사항&nbsp;1.&nbsp;회원의 권리와 책임&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">달리바바 사이트는&nbsp;Online Marketplace로서 회원 각자의 자기결정에 의하여 회원 상호간에 거래가 이루어질 수 있도록 사이버&nbsp;</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:black;">거래장소(marketplace)</span><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">를 온라인으로 제공하며,&nbsp;그 거래에 대하여 어떠한 보증도 제공하지 않습니다.&nbsp;또한,&nbsp;서비스의 결함,&nbsp;물품정보 오류,&nbsp;회원간 사기행위 등 회원간 거래행위에서 발생되는 손실에 대해서는 회사가 절대 책임을 부담하지 않으며,&nbsp;회원간에 직접 해결하여야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">준수사항&nbsp;2.&nbsp;개인정보 도용금지&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">모든 회원은 중개서비스와 관련하여 회사로부터 제공받은 정보를 그 제공목적 외의 다른 목적으로 사용하거나 타인에게 유출 또는 제공해서는 안되며,&nbsp;그 위반으로 인한 모든 책임을 부담하여야 합니다.&nbsp;또한,&nbsp;회원은 자신의 개인정보를 책임 있게 관리하여 타인이 회원의 개인정보를 사용하여 중개서비스를 부정하게 이용하지 않도록 해야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">준수사항&nbsp;3.&nbsp;매매 부적합 서비스 판매금지</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:7.5pt;text-align:left;line-height:115%;background:white;'><span style="font-size:15px;line-height:115%;font-family:굴림;color:#212529;">달리바바 사이트에서에 등록되는 모든 서비스는 판매가 가능한 서비스이어야 합니다.&nbsp;매매부적합 서비스를 등록할 경우 매매부적합 서비스 판매에 대한 법적인 책임을 지게 됩니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;line-height:115%;'><span style="font-size:15px;line-height:115%;font-family:굴림;">&nbsp;</span></p>
          `}}/>
        </div>
      </div>
    </ServicePolicy>
  );
};

export default Agreement;
