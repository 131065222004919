import React, {useEffect, useState} from "react";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import AdminSearchIngredientList from "./AdminSearchIngredientList";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";
import {useHistory} from "react-router-dom";

const AdminSearchIngredientAdd = (e) => {
  const history = useHistory();

  // 현재 선택한 성분제품사전 state
  const [noticeState, setNoticeState] = useState();
  const noticeClick = (e) => {
    setNoticeState(e);
  };
  const path = window.location.pathname;

  const [name, setName] = useState('');
  const [oldName, setOldName] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [casNo, setCasNo] = useState('');
  const [ecNo, setEcNo] = useState('');
  const [definition, setDefinition] = useState('');
  const [molecule, setMolecule] = useState('');
  const [rational, setRational] = useState('');
  const [purpose, setPurpose] = useState('');
  const [compoundProduct, setCompoundProduct] = useState('');
  const [product, setProduct] = useState('');
  const [alias, setAlias] = useState('');
  const [nameHistory, setNameHistory] = useState('');

  const submit = async () => {
    const {data: {code, result, message}} = await APIHelper.postForm("data/search/ingredient/register", {
      name,
      oldName,
      nameEn,
      casNo,
      ecNo,
      definition,
      molecule,
      rational,
      purpose,
      compoundProduct,
      product,
      alias,
      nameHistory
    });
    if (code === 200) {
      await CommonLib.jalert('저장하였습니다.');
      history.push('/admin/data/search/ingredient');

      try {
        await APIHelper.postForm("admin/action/log", {
          content: "홈노출/검색관리 > 검색 - 성분 > 추가 > 저장",
          param1 : name,
          param2 : oldName,
          param3 : nameEn,
          param4 : casNo,
          param5 : ecNo,
          param6 : definition,
          param7 : molecule,
          param8 : rational,
          param9 : purpose,
        });
      } catch (err) {
        console.log(err);
      }

    }
  }

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "홈노출/검색관리 > 검색 - 성분 > 추가",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);


  return (
    <>
      {path.includes("add") ? (
        <AdminCard>
          <div
            id="body"
            className="row row-cols-3 gx-3 mt-4"
            style={{height: "calc(100% - 30px)"}}
          >
            <AdminSearchIngredientList click={(id) => history.push("/admin/data/search/ingredient/detail/" + id)}/>

            <section className="col-8" style={{height: "100%"}}>
              <div
                className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
                style={{height: "98%"}}
              >
                <div className="d-flex justify-content-between border-bottom">
                  <p className="h5 bold px-2">성분제품사전 추가</p>
                  <p className="m-1 mb-2">
                    <button
                      className="btn btn-purple txt-sm py-1 px-3"
                      type="button"
                      onClick={submit}
                    >
                      저장
                    </button>
                  </p>
                </div>

                <div
                  className="wid_scroll scroll_y coustom_scroll px-2 mt-4"
                  style={{height: "calc(100% - 70px)"}}
                >
                  <article className="wid_inline">
                    <table className="table basic w-100">
                      <colgroup>
                        <col width="18%"/>
                        <col width="*"/>
                        <col width="18%"/>
                        <col width="*"/>
                      </colgroup>
                      <tr>
                        <th className="center">성분명</th>
                        <td className="border-top" colSpan="3">
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setName(e.target.value)}
                            value={name}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">구명칭</th>
                        <td className="border-top" colSpan="3">
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setOldName(e.target.value)}
                            value={oldName}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">영문명</th>
                        <td className="border-top" colSpan="3">
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setNameEn(e.target.value)}
                            value={nameEn}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">CAS No.</th>
                        <td className="border-top" colSpan="3">
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setCasNo(e.target.value)}
                            value={casNo}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">EC No</th>
                        <td className="border-top" colSpan="3">
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setEcNo(e.target.value)}
                            value={ecNo}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">기원 및 정의</th>
                        <td className="border-top" colSpan="3">
                      <textarea
                        className="form-control form-control-sm"
                        type="text"
                        aria-describedby="button-addon2"
                        onChange={async (e) => await setDefinition(e.target.value)}
                        value={definition}
                        rows="4"
                      />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">분자구조식</th>
                        <td className="border-top" colSpan="3">
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setMolecule(e.target.value)}
                            value={molecule}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">시성식</th>
                        <td className="border-top" colSpan="3">
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setRational(e.target.value)}
                            value={rational}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">배합목적</th>
                        <td className="border-top" colSpan="3">
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setPurpose(e.target.value)}
                            value={purpose}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">복합물 상품명</th>
                        <td className="border-top" colSpan="3">
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setCompoundProduct(e.target.value)}
                            value={compoundProduct}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">상품명</th>
                        <td className="border-top" colSpan="3">
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setProduct(e.target.value)}
                            value={product}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">별명</th>
                        <td className="border-top" colSpan="3">
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setAlias(e.target.value)}
                            value={alias}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">명칭변경이력</th>
                        <td className="border-top" colSpan="3">
                      <textarea
                        className="form-control form-control-sm"
                        type="text"
                        aria-describedby="button-addon2"
                        onChange={async (e) => await setNameHistory(e.target.value)}
                        value={nameHistory}
                        rows="4"
                      />
                        </td>
                      </tr>
                    </table>
                  </article>
                </div>
              </div>
            </section>
          </div>
        </AdminCard>
      ) : (
        ""
      )}
    </>
  );
};

export default AdminSearchIngredientAdd;
