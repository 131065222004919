import React from "react";
import icon_visibility from "../../../image/icon_visibility.svg";
import star_r from "../../../image/star_r.svg";
import star_g from "../../../image/star_g.svg";
import chevron_left_black_24dp from "../../../image/chevron_left_black_24dp.svg";
import first_page_black_48dp from "../../../image/first_page_black_48dp.svg";
import navigate_next_black_48dp from "../../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../../image/last_page_black_48dp.svg";
import "../../../sass/main.scss";

const ProfileBuyerReview = (props) => {
  const DUMMY_DATA = [
    {
      id: 1,
      title: "세럼 OEM",
      start_date: "2018.10.10",
      end_date: "2019.10.10",
      name: "hong123",
      content: "매우 친절하시고 다양한 요구조건도 잘 맞춰주셨습니다.",
      update_date: "2019.12.13",
      result: 85,
      communication: 65,
      calendar: 70,
      active: 60,
      professional: 75,
    },
    {
      id: 2,
      title: "로션 OEM",
      start_date: "2019.02.10",
      end_date: "2019.12.10",
      name: "hong123",
      content: "매우 친절하시고 다양한 요구조건도 잘 맞춰주셨습니다.",
      update_date: "2020.01.13",
      result: 40,
      communication: 65,
      calendar: 60,
      active: 65,
      professional: 45,
    },
    {
      id: 3,
      title: "핸드크림 OEM",
      start_date: "2020.03.25",
      end_date: "2021.11.19",
      name: "hong123",
      content: "매우 친절하시고 다양한 요구조건도 잘 맞춰주셨습니다.",
      update_date: "2022.01.13",
      result: 60,
      communication: 65,
      calendar: 70,
      active: 48,
      professional: 75,
    },
  ];

  return (
    <div className="box_round min_h cnt_area pb-5">
      <div className="mx-5 my-4 pb-5">
        {/*<div*/}
        {/*  className="p_absolute_tr d-flex"*/}
        {/*  style={{ top: "50px", right: "60px" }}*/}
        {/*>*/}
        {/*  <button type="button" className="btn btn-sm p-0 txt-gray">*/}
        {/*    <img src={icon_visibility} style={{ width: "20px" }} /> 내 프로필 미리보기{" "}*/}
        {/*  </button>*/}
        {/*</div>*/}
        <p className="h3 bold border-bottom py-3">후기 및 평점</p>
        <br />
        <br />

        <div className="box_border">
          {DUMMY_DATA.map((item, i) => (
            <div className="border-bottom d-flex" key={i}>
              <div className="w-70" style={{ borderRight: "1px solid #ddd" }}>
                <ul className="m-3 border-bottom p-0 pb-2 px-2">
                  <li className="h5 bold">{item.title}</li>
                  <li className="txt-sm txt-gray">
                    프로젝트 기간 : {item.start_date} ~ {item.end_date}
                  </li>
                </ul>
                <ul className="m-3 p-0 pb-2 px-2">
                  <li className="txt-gray pb-2 h6">{item.content}</li>
                </ul>
              </div>
              <div className="w-30 p-3 px-4">
                <ul className="d-flex m-0 p-0">
                  <li
                    className="w-40 px-2 h5"
                    style={{ textAlign: "right", color: "#D56451" }}
                  >
                    {(
                      item.result +
                      item.communication +
                      item.calendar +
                      item.active +
                      item.professional
                    ).toPrecision(1) / 100}
                  </li>
                  <li className="">
                    <img
                      src={`${
                        (
                          item.result +
                          item.communication +
                          item.calendar +
                          item.active +
                          item.professional
                        ).toPrecision(1) /
                          100 >
                        0
                          ? star_r
                          : star_g
                      }`}
                      style={{ width: "18px" }}
                    />
                    <img
                      src={`${
                        (
                          item.result +
                          item.communication +
                          item.calendar +
                          item.active +
                          item.professional
                        ).toPrecision(1) /
                          100 >
                        1
                          ? star_r
                          : star_g
                      }`}
                      style={{ width: "18px" }}
                    />
                    <img
                      src={`${
                        (
                          item.result +
                          item.communication +
                          item.calendar +
                          item.active +
                          item.professional
                        ).toPrecision(1) /
                          100 >
                        2
                          ? star_r
                          : star_g
                      }`}
                      style={{ width: "18px" }}
                    />
                    <img
                      src={`${
                        (
                          item.result +
                          item.communication +
                          item.calendar +
                          item.active +
                          item.professional
                        ).toPrecision(1) /
                          100 >
                        3
                          ? star_r
                          : star_g
                      }`}
                      style={{ width: "18px" }}
                    />
                    <img
                      src={`${
                        (
                          item.result +
                          item.communication +
                          item.calendar +
                          item.active +
                          item.professional
                        ).toPrecision(1) /
                          100 >
                        4
                          ? star_r
                          : star_g
                      }`}
                      style={{ width: "18px" }}
                    />
                  </li>
                </ul>
                <ul className="d-flex m-0 p-0">
                  <li
                    className="w-40 px-2 txt-gray txt-sm"
                    style={{ textAlign: "right", fontWeight: "normal" }}
                  >
                    성과만족도
                  </li>
                  <li className="">
                    <div className="progress_bar">
                      <div className={`On w-${item.result}`}></div>
                    </div>
                  </li>
                </ul>
                <ul className="d-flex m-0 p-0">
                  <li
                    className="w-40 px-2 txt-gray txt-sm"
                    style={{ textAlign: "right", fontWeight: "normal" }}
                  >
                    소통능력
                  </li>
                  <li className="">
                    <div className="progress_bar">
                      <div className={`On w-${item.communication}`}></div>
                    </div>
                  </li>
                </ul>
                <ul className="d-flex m-0 p-0">
                  <li
                    className="w-40 px-2 txt-gray txt-sm"
                    style={{ textAlign: "right", fontWeight: "normal" }}
                  >
                    일정준수
                  </li>
                  <li className="">
                    <div className="progress_bar">
                      <div className={`On w-${item.calendar}`}></div>
                    </div>
                  </li>
                </ul>
                <ul className="d-flex m-0 p-0">
                  <li
                    className="w-40 px-2 txt-gray txt-sm"
                    style={{ textAlign: "right", fontWeight: "normal" }}
                  >
                    적극성
                  </li>
                  <li className="">
                    <div className="progress_bar">
                      <div className={`On w-${item.active}`}></div>
                    </div>
                  </li>
                </ul>
                <ul className="d-flex m-0 p-0">
                  <li
                    className="w-40 px-2 txt-gray txt-sm"
                    style={{ textAlign: "right", fontWeight: "normal" }}
                  >
                    전문성
                  </li>
                  <li className="">
                    <div className="progress_bar">
                      <div className={`On w-${item.professional}`}></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          ))}

          <div className="d-flex justify-content-center py-3">
            <ul className="d-flex my-3 p-0">
              <li className="PAGE">
                <a href="#" aria-label="Previous">
                  <img src={first_page_black_48dp} style={{ width: "25px" }} />
                </a>
              </li>
              <li className="PAGE">
                <a href="#" aria-label="Previous">
                  <img
                    src={chevron_left_black_24dp}
                    style={{ width: "25px" }}
                  />
                </a>
              </li>
              <li className="PAGE">
                <a href="#" className="ON">
                  1
                </a>
              </li>
              <li className="PAGE">
                <a href="#">2</a>
              </li>
              <li className="PAGE">
                <a href="#">3</a>
              </li>
              <li className="PAGE">
                <a href="#" aria-label="Previous">
                  <img
                    src={navigate_next_black_48dp}
                    style={{ width: "25px" }}
                  />
                </a>
              </li>
              <li className="PAGE">
                <a href="#" aria-label="Previous">
                  <img src={last_page_black_48dp} style={{ width: "25px" }} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileBuyerReview;
