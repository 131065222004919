import React, {useEffect, useState} from "react";

import cancel_p from "../../../image/cancel_p.svg";
import ProfileMaker from "./ProfileMaker";

import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";

const ProfileMakerDetail = (props) => {
  const [chargerName, setChargerName] = useState('');
  const [chargerEmail, setChargerEmail] = useState('');
  const [chargerTel, setChargerTel] = useState('');
  const [patentNum, setPatentNum] = useState(0);
  const [moqs, setMoqs] = useState([]);
  const [moqCd, setMoqCd] = useState('');

  // open&close 버튼
  const [showProductAdd, setShowProductAdd] = useState(false);
  const [showSkillAdd, setShowSkillAdd] = useState(false);
  const [showLicenseAdd, setShowLicenseAdd] = useState(false);

  // TAG 종류
  const [tagProductData, setTagProductData] = useState([]);
  const [tagSkillData, setTagSkillData] = useState([]);
  const [tagLicenseData, setTagLicenseData] = useState([]);

  // 사용자가 선택한 Tag State
  const [productState, setProductState] = useState([]);
  const [skillState, setSkillState] = useState([]);
  const [licenseState, setLicenseState] = useState([]);

  // Tag 핸들러
  const clickProductTag = (e) => {
    if (productState.filter((element) => element.cd === e.cd).length > 0) {
      setProductState(productState.filter((element) => element.cd !== e.cd));
    } else setProductState([...productState, e]);
  };
  const clickSkillTag = (e) => {
    if (skillState.filter((element) => element.cd === e.cd).length > 0) {
      setSkillState(skillState.filter((element) => element.cd !== e.cd));
    } else setSkillState([...skillState, e]);
  };
  const clickLicenseTag = (e) => {
    if (licenseState.filter((element) => element.cd === e.cd).length > 0) {
      setLicenseState(licenseState.filter((element) => element.cd !== e.cd));
    } else setLicenseState([...licenseState, e]);
  };

  // 사용자가 선택한 Tag 보여주기
  const productTage = productState.map((item, i) => {
    return (
      <p className="box_round_4_paddingNo bg-lpurple px-2 p-1 m-2">
        <span className="px-2">{item.name_en}</span>{" "}
        <button
          onClick={() => clickProductTag(item)}
          type="button"
          className="btn btn-sm p-0"
        >
          <img src={cancel_p} style={{paddingBottom: "2px"}}/>
        </button>
      </p>
    );
  });
  const skillTage = skillState.map((item, i) => {
    return (
      <p className="box_round_4_paddingNo bg-lpurple px-2 p-1 m-2">
        <span className="px-2">{item.name_kr}</span>{" "}
        <button
          onClick={() => clickSkillTag(item)}
          type="button"
          className="btn btn-sm p-0"
        >
          <img src={cancel_p} style={{paddingBottom: "2px"}}/>
        </button>
      </p>
    );
  });
  const licenseTage = licenseState.map((item, i) => {
    return (
      <p className="box_round_4_paddingNo bg-lpurple px-2 p-1 m-2">
        <span className="px-2">{item.name_en}</span>{" "}
        <button
          onClick={() => clickLicenseTag(item)}
          type="button"
          className="btn btn-sm p-0"
        >
          <img src={cancel_p} style={{paddingBottom: "2px"}}/>
        </button>
      </p>
    );
  });

  // 사용자가 선택하지 않은 Tag들
  const canProductAdd = tagProductData.filter((item) => productState.filter((element) => element.cd === item.cd).length === 0);
  const canSkillAdd = tagSkillData.filter((item) => skillState.filter((element) => element.cd === item.cd).length === 0);
  const canLicenseAdd = tagLicenseData.filter((item) => licenseState.filter((element) => element.cd === item.cd).length === 0);

  // 선택 가능한 Tag 보여주기
  const addProductTag = canProductAdd.map((item, i) => {
    return (
      <p
        key={i}
        onClick={() => clickProductTag(item)}
        style={{background: "#f9edf8", color: "#ae6faf", cursor: "pointer"}}
        className="box_round_4_paddingNo px-2 p-1 m-2"
      >
        <span style={{paddingLeft: "0.5rem"}}>{item.name_en}</span>{" "}
        <button type="button" className="btn btn-sm p-0"></button>
      </p>
    );
  });
  const addSkillTag = canSkillAdd.map((item, i) => {
    return (
      <p
        key={i}
        onClick={() => clickSkillTag(item)}
        style={{background: "#f9edf8", color: "#ae6faf", cursor: "pointer"}}
        className="box_round_4_paddingNo px-2 p-1 m-2"
      >
        <span style={{paddingLeft: "0.5rem"}}>{item.name_kr}</span>{" "}
        <button type="button" className="btn btn-sm p-0"></button>
      </p>
    );
  });
  const addLicenseTag = canLicenseAdd.map((item, i) => {
    return (
      <p
        key={i}
        onClick={() => clickLicenseTag(item)}
        style={{background: "#f9edf8", color: "#ae6faf", cursor: "pointer"}}
        className="box_round_4_paddingNo px-2 p-1 m-2"
      >
        <span style={{paddingLeft: "0.5rem"}}>{item.name_en}</span>{" "}
        <button type="button" className="btn btn-sm p-0"></button>
      </p>
    );
  });

  useEffect(() => {
    const fetch = async () => {
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '110000'});
        if (code === 200) setTagProductData(result);
      }
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '103000'});
        if (code === 200) setTagLicenseData(result);
      }
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '107000'});
        if (code === 200) setTagSkillData(result);
      }
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '102000'});
        if (code === 200) setMoqs(result);
      }
      await getMakerProfile();
    }
    fetch();
  }, []);

  // 제조사 프로필 가져오기
  const getMakerProfile = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.get("user/maker/get");
      if (code === 200) {
        setProductState(result.mf_items || []);
        setSkillState(result.functionals || []);
        setLicenseState(result.certs || []);

        setChargerName(result.charger_name);
        setChargerEmail(result.charger_email);
        setChargerTel(result.charger_tel);
        setPatentNum(result.patent_num);
        setMoqCd(result.moq_cd);
      } else {
        await CommonLib.jalert(message);
      }
      return resolve(true);
    });
  };

  const updateSubmit = (e) => {
    return new Promise(async (resolve, reject) => {
      let mfItemCds = [];
      for (let key in productState) {
        mfItemCds.push(productState[key].cd);
      }

      let certCds = [];
      for (let key in licenseState) {
        certCds.push(licenseState[key].cd);
      }

      let functionalCds = [];
      for (let key in skillState) {
        functionalCds.push(skillState[key].cd);
      }

      try {
        const {data: {code: retCode, result, message}} = await APIHelper.putForm(`user/maker/update/additional`, {
          mfItemCds: mfItemCds.join(","),
          certCds: certCds.join(","),
          functionalCds: functionalCds.join(","),
          chargerName,
          chargerEmail,
          chargerTel,
          patentNum,
          moqCd,
        });
        await CommonLib.jalert(message);
      } catch (err) {
        console.error(err);
      }
      return resolve(true);
    });
  };

  return (
    <ProfileMaker>
      <div className="d-flex justify-content-between pb-2 mt-4">
        <p className="txt-md">담당자 정보</p>
      </div>

      <div>
        <table className="table basic w-100">
          <colgroup>
            <col width="22%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th className="center">담당자 이름</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="담당자 이름 입력"
                aria-describedby="button-addon2"
                onChange={async (e) => await setChargerName(e.target.value)}
                value={chargerName}
              />
            </td>
          </tr>
          <tr>
            <th className="center">담당자 이메일</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="담당자 이메일 입력"
                aria-describedby="button-addon2"
                onChange={async (e) => await setChargerEmail(e.target.value)}
                value={chargerEmail}
              />
            </td>
          </tr>
          <tr>
            <th className="center">담당자 전화번호</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="담당자 전화번호 입력"
                aria-describedby="button-addon2"
                onChange={async (e) => await setChargerTel(e.target.value)}
                value={chargerTel}
              />
            </td>
          </tr>
        </table>
      </div>

      <br/>

      <div className="d-flex justify-content-between border-bottom pb-2 mt-4">
        <p className="txt-md">생산 가능 품목 </p>
        <p>
          <button
            onClick={() => setShowProductAdd(!showProductAdd)}
            type="button"
            className="btn btn-sm btn_outline_puple px-3"
          >
            {showProductAdd ? "Close" : "Add TAG"}
          </button>
        </p>
      </div>
      {showProductAdd && (
        <div className="d-flex flex-wrap border-bottom p-3">
          {addProductTag}
        </div>
      )}
      <div className="d-flex flex-wrap border-bottom p-3">
        {productState.length > 0 ? productTage : <p>TAG를 추가해주세요</p>}
      </div>

      <br/>

      <div className="d-flex justify-content-between border-bottom pb-2 mt-4">
        <p className="txt-md">기능성 제품 생산</p>
        <p>
          <p>
            <button
              onClick={() => setShowSkillAdd(!showSkillAdd)}
              type="button"
              className="btn btn-sm btn_outline_puple px-3"
            >
              {showSkillAdd ? "Close" : "Add TAG"}
            </button>
          </p>
        </p>
      </div>
      {showSkillAdd && (
        <div className="d-flex flex-wrap border-bottom p-3">{addSkillTag}</div>
      )}
      <div className="d-flex flex-wrap border-bottom p-3">
        {skillState.length > 0 ? skillTage : <p>TAG를 추가해주세요</p>}
      </div>

      <br/>

      <div className="d-flex justify-content-between border-bottom pb-2 mt-4">
        <p className="txt-md">보유 인증서</p>
        <p>
          <p>
            <button
              onClick={() => setShowLicenseAdd(!showLicenseAdd)}
              type="button"
              className="btn btn-sm btn_outline_puple px-3"
            >
              {showLicenseAdd ? "Close" : "Add TAG"}
            </button>
          </p>
        </p>
      </div>

      {showLicenseAdd && (
        <div className="d-flex flex-wrap border-bottom p-3">
          {addLicenseTag}
        </div>
      )}
      <div className="d-flex flex-wrap border-bottom p-3">
        {licenseState.length > 0 ? licenseTage : <p>TAG를 추가해주세요</p>}
      </div>

      <br/>

      <div className="d-flex justify-content-between pb-2 mt-4">
        <p className="txt-md">보유 특허 / MOQ</p>
      </div>

      <div>
        <table className="table basic w-100">
          <colgroup>
            <col width="22%"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th className="center">보유 특허</th>
            <td>
              <select
                className="form-select form-select-sm w-85"
                aria-label="Default select example"
                onChange={async (e) => await setPatentNum(e.target.value)}
                value={patentNum}
              >
                {Array.from(Array(101).keys()).map((i, k) => <option selected={patentNum === i} value={i}>{i}건 보유</option>)}
              </select>
            </td>
          </tr>
          <tr>
            <th className="center">MOQ</th>
            <td>
              <select
                className="form-select form-select-sm w-85"
                aria-label="Default select example"
                onChange={async (e) => await setMoqCd(e.target.value)}
                value={moqCd}
              >
                <option value=''>선택</option>
                {moqs.map((i, k) => <option selected={moqCd === i.cd} value={i.cd}>{i.name_kr}</option>)}
              </select>
            </td>
          </tr>
        </table>
      </div>

      <p className="text-center">
        <button type="button" className="btn btn_puple px-5 my-3" onClick={async (e) => await updateSubmit(e)}>
          저장하기
        </button>
      </p>
    </ProfileMaker>
  );
};

export default ProfileMakerDetail;
