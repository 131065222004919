import React, {useEffect, useState} from "react";
import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";
import modalStyle from "../../../actions/openModalStyle";
import {useDispatch} from "react-redux";
import openRequestFormulation from "../../../actions/openRequestFormulation";

const RequestFormulationComponent = (props) => {
  const projectId = props.projectId;
  const projectProductId = props.projectProductId;
  const requestId = props.requestId;
  const requestType = "MF";
  const getProject = props.getProject;
  const requestList = props.requestList;

  useEffect(() => {
    const fetch = async () => {
      await setFormState();
    }
    fetch();
  }, [requestId]);

  const setFormState = () => {
    return new Promise(async (resolve, reject) => {
      if (projectId && projectProductId && requestType) {
        const {data: {code, result, message}} = await APIHelper.getForm("project/request/form/get", {projectId, projectProductId, requestId, requestType});
        if (code === 200) {
          setRequestNo(result?.request_no);
          setRequestName(result?.request_name);
          setMfContainerYn(result?.mf_container_yn);
          setMfBoxYn(result?.mf_box_yn);
          setMfBrandName(result?.mf_brand_name);
          setMfProductName(result?.mf_product_name);
          setMfVolume(result?.mf_volume);
          setMfTargetItem(result?.mf_target_item);
          setMfScent(result?.mf_scent);
          setMfConcept(result?.mf_concept);
        }
      }

      return resolve(true);
    });
  };

  const submitForm = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.postForm("project/request/form/submit", {
        projectId,
        projectProductId,
        requestId,
        requestType,
        requestName,
        mfContainerYn,
        mfBoxYn,
        mfBrandName,
        mfProductName,
        mfVolume,
        mfTargetItem,
        mfScent,
        mfConcept
      });
      if (code === 200) {
        await setFormState();
        {getProject && await getProject(projectId)};  //부모창 함수 호출 방법
        await CommonLib.jalert('저장이 완료되었습니다.');
      } else {
        await CommonLib.jalert('저장이 실패하였습니다.');
      }
      return resolve(true);
    });
  }

  const selectForm = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.postForm("project/request/select", {
        requestId: requestId,
        requestSelect: '1'
      });
      if (code === 200) {
        await CommonLib.jalert('선택이 완료되었습니다.');
        //await selectReset();
      } else {
        await CommonLib.jalert('선택이 실패하였습니다.');
      }
      return resolve(true);
    });
  }




  const [requestNo, setRequestNo] = useState('');
  const [requestName, setRequestName] = useState('');
  const [mfContainerYn, setMfContainerYn] = useState('N');
  const [mfBoxYn, setMfBoxYn] = useState('N');
  const [mfBrandName, setMfBrandName] = useState('');
  const [mfProductName, setMfProductName] = useState('');
  const [mfVolume, setMfVolume] = useState('');
  const [mfTargetItem, setMfTargetItem] = useState('');
  const [mfScent, setMfScent] = useState('');
  const [mfConcept, setMfConcept] = useState('');

  return (
    <>
      <table className="table basic_sm w-100">
        <colgroup>
          <col width="28%"/>
          <col width="*"/>
        </colgroup>
        <tr>
          <th className="center txt-sm">의뢰서 제목 및 의뢰번호</th>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="의뢰서 제목을 입력해 주세요"
              onChange={async (e) => await setRequestName(e.target.value)}
              value={requestName}
            />
            {requestNo && requestNo.length > 0 &&
              <p>제작 의뢰서 번호 : {requestNo}</p>
            }
          </td>
        </tr>
        <tr>
          <th className="center txt-sm">의뢰 항목</th>
          <td>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input mt-1"
                type="checkbox"
                id="inlineCheckbox1"
                value="Y"
                onChange={async (e) => await setMfContainerYn(e.target.checked ? 'Y' : 'N')}
                checked={mfContainerYn === 'Y'}
              />
              <label className="form-check-label" for="inlineCheckbox1">
                용기 배송{" "}
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input mt-1"
                type="checkbox"
                id="inlineCheckbox2"
                value="Y"
                onChange={async (e) => await setMfBoxYn(e.target.checked ? 'Y' : 'N')}
                checked={mfBoxYn === 'Y'}
              />
              <label className="form-check-label" for="inlineCheckbox2">
                단상자 배송
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <th className="center txt-sm">브랜드명 (Brand name)</th>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="브랜드명 (Brand name)"
              onChange={async (e) => await setMfBrandName(e.target.value)}
              value={mfBrandName}
            />
          </td>
        </tr>
        <tr>
          <th className="center txt-sm">제품명 (Product name)</th>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder=""
              onChange={async (e) => await setMfProductName(e.target.value)}
              value={mfProductName}
            />
          </td>
        </tr>
        <tr>
          <th className="center txt-sm">용량 (Volume)</th>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder=""
              onChange={async (e) => await setMfVolume(e.target.value)}
              value={mfVolume}
            />
          </td>
        </tr>
        <tr>
          <th className="center txt-sm">제형/타겟 제품 (Target item)</th>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder=""
              onChange={async (e) => await setMfTargetItem(e.target.value)}
              value={mfTargetItem}
            />
          </td>
        </tr>
        <tr>
          <th className="center txt-sm">향/색 (Scent/Color)</th>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="향/색 무관"
              onChange={async (e) => await setMfScent(e.target.value)}
              value={mfScent}
            />
          </td>
        </tr>
        <tr>
          <th className="center txt-sm">연관 이미지 첨부</th>
          <td>
            {!props.readOnly &&
            <div className="input-group input-group-sm">
              <input
                type="file"
                className="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
              />
            </div>
            }
          </td>
        </tr>
        <tr>
          <th className="center txt-sm">제품 컨셉 (Concept of Line)</th>
          <td>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="4"
                onChange={async (e) => await setMfConcept(e.target.value)}
                value={mfConcept}
              ></textarea>
          </td>
        </tr>
      </table>
      {!props.readOnly &&
        <div style={{
          height: "85px",
          textAlign: "center",
          marginTop: "14px",
          padding: "1.5em",
          justifyContent: "space-between",
          backgroundColor: "#efefef",
        }}>
          <button className="btn btn_puple mb-2" type="button" onClick={submitForm}>
            저장하기
          </button>
          {requestList && requestList.length > 1 &&
              <button style={{marginLeft:"10px"}} className="btn btn_puple mb-2" type="button" onClick={selectForm}>
                선택하기
              </button>
          }
        </div>
      }
    </>
  );
};

export default RequestFormulationComponent;

