import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import MakerProjectCard from "./MakerProjectCard";
import last_page_black_48dp from "../../../image/last_page_black_48dp.svg";
import navigate_next_black_48dp from "../../../image/navigate_next_black_48dp.svg";
import chevron_left_black_24dp from "../../../image/chevron_left_black_24dp.svg";
import first_page_black_48dp from "../../../image/first_page_black_48dp.svg";
import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import Constants from "../../../constants/constants";
import moment from "moment";
import Paging from "../../common/Paging";

const MakerProjectRequestList = (props) => {
  const [requests, setRequests] = useState();
  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      await getRequests();
    }
    fetch();
  }, [currPage]);

  const getRequests = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("project/maker/request/gets", {type: "R", orderBy: "desc", paging: 1, articlesPerPage: 5, currPage});
      if (code === 200) {
        if (pageInfo) setPageInfo(pageInfo);
        setRequests(result);
      }
      window.scrollTo(0, 0);
      return resolve(true);
    });
  }

  return (
    <MakerProjectCard>
      <div className="box_round min_h cnt_area pb-5">
        <div className="mx-5 my-4 pb-0">
          <p className="h3 bold border-bottom py-3">제작 의뢰</p>

          {requests && requests.map((request, key) => (
            <Link key={key} to={`/project/maker/request/detail/${request.id}`}>
              <div className="row border-bottom py-3 p_rative flex-nowrap" style={{cursor: "pointer"}}>
                <div className="col col-4 p_rative" style={{minWidth: "300px"}}>
                  <div className="m-2 my-3 p_rative" style={{minWidth: "264px"}}>
                    <div className="thum_buyer_info p_absolute p-3" style={{minHeight: "132px"}}>
                      <p
                        className="box_round_30 bg-white px-1 txt-sm"
                        style={{width: "53px"}}
                      >
                        바이어
                      </p>
                      <p className="h5 bold mt-1">{request.project && request.project.buyer && (request.project.buyer?.company?.length > 0 ? request.project.buyer.company.substring(0, 4) + "***" : (request.project.buyer?.first_name + " ***"))}</p>
                      <p>
                        {request.project && request.project.buyer.country.name_kr}
                      </p>
                    </div>
                    <div className="thum_buyer">
                      {request.project && request.project.buyer.rep_image_fid && request.project.buyer.rep_image_fid.length > 0 ?
                        <img
                          src={`${Constants.API_HOST}/common/download?fid=${request.project.buyer.rep_image_fid}&cd=inline&thumb=400`}
                          style={{width: "264px", height: "131px", objectFit: "cover"}}
                        />
                        :
                        <div style={{width: "264px", height: "131px", objectFit: "cover", backgroundColor: "#efefef"}}></div>
                      }
                    </div>
                  </div>
                </div>

                <div
                  className="col col-8 p_rative"
                  style={{borderLeft: "1px solid #ddd"}}
                >
                  <p className="p_absolute_tr3">제작 의뢰 요청일 : {moment(request.created_at).format("YYYY.MM.DD")}</p>
                  <div className="txt-gray p_rative w-100">
                    {/*TODO*/}
                    <p>요청 No : {request.req_no}</p>
                    <p className="h5 bold pt-1 txt-point">{request.request_form.request_name}</p>
                    <p className="txt-sm">제작 의뢰서 : {request.request_form.request_no}</p>
                    <div className="mt-3 d-flex">
                      <table className="table basic_ssm">
                        <colgroup>
                          <col width="20%"/>
                          <col width="*"/>
                        </colgroup>
                        <tr>
                          <th className="center">요청 상태</th>
                          <td>
                            {
                              request.status_cd === 'R' ? '수락 대기중' :
                                request.status_cd === 'N' ? '거절' :
                                  request.status_cd === 'Y' ? '수락' :
                                    request.status_cd === 'C' ? '취소' : ''
                            }
                          </td>
                        </tr>
                        <tr>
                          <th className="center">수락 만료일</th>
                          <td>{moment(request.created_at).add(request.expire_days, 'days').format("YYYY.MM.DD HH:mm")}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                {(request.status_cd === 'N' || request.status_cd === 'C') &&
                  <div style={{width: "100%", height: "100%", position: "absolute", top: 0, left: 0, opacity: "0.25", backgroundColor: "#000000", zIndex: 99999999}}></div>
                }
              </div>
            </Link>
          ))}
          {requests && requests.length === 0 &&
            <div className="col w-100">
              <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-lblue bold">
                제작 의뢰 내역이 없습니다.
              </div>
            </div>
          }
        </div>
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </div>
    </MakerProjectCard>
  );
};

export default MakerProjectRequestList;
