import axios from "axios";
import axiosRetry from "axios-retry";

import Constants from "../constants/constants";
import FormData from "form-data";
import * as qs from "qs";
import CommonLib from "../libraries/CommonLib";

const parseErrorCode = async (error) => {
  console.error(error);
  if (error.response) {
    // TODO : Redux
    if (error.response.status === 401) {
      await CommonLib.jalert("로그인이 필요합니다.");

      if (window.location.pathname.includes("/admin/")) {
        localStorage.removeItem("admin.session.token");
        localStorage.removeItem("admin.session.email");
        localStorage.removeItem("admin.session.name");
        localStorage.removeItem("admin.session.user_type");
        localStorage.removeItem("isAdminLoggedIn");

        sessionStorage.removeItem("admin.session.token");
        sessionStorage.removeItem("admin.session.email");
        sessionStorage.removeItem("admin.session.name");
        sessionStorage.removeItem("admin.session.user_type");
        sessionStorage.removeItem("isAdminLoggedIn");

        window.location.href = '/admin/login';
      } else {
        localStorage.removeItem("user.session.token");
        localStorage.removeItem("user.session.email");
        localStorage.removeItem("user.session.name");
        localStorage.removeItem("user.session.user_type");
        localStorage.removeItem("isUserLoggedIn");

        sessionStorage.removeItem("user.session.token");
        sessionStorage.removeItem("user.session.email");
        sessionStorage.removeItem("user.session.name");
        sessionStorage.removeItem("user.session.user_type");
        sessionStorage.removeItem("isUserLoggedIn");

        window.location.href = '/login';
      }
      return;
    } else if (error.response.status === 403) {
      await CommonLib.jalert("접근 권한이 없습니다.");
    }

    if (error.response.status === 404) {
      const {message} = error.response.data;
      await CommonLib.jalert(message);
    }
  } else {
    //await CommonLib.jalert("서버에 접속할 수 없습니다. 잠시 후 다시 시도해 주세요.");
  }

  return Promise.reject(error.response);
};

const API = axios.create();
// axios.defaults.withCredentials = true;

axiosRetry(API, {
  retries: 3,
  shouldResetTimeout: true,
  retryCondition: (_error) => false, // retry no matter what
});

// Request parsing interceptor
API.interceptors.request.use(
  async (config) => {
    config.baseURL = Constants.API_URL;
    if (config.url.startsWith("/")) config.baseURL = Constants.API_HOST;

    const token =
      window.location.pathname.startsWith("/admin") ?
        (localStorage.getItem("admin.session.token") ||
          sessionStorage.getItem("admin.session.token") ||
          null)
        :
        (localStorage.getItem("user.session.token") ||
          sessionStorage.getItem("user.session.token") ||
          null);
    const headers = token ? {Authorization: "Bearer " + token, CurrentUri: window.location.pathname} : {CurrentUri: window.location.pathname};
    if (headers) {
      config.headers = {...config.headers, ...headers};
    }
    return config;
  },
  (error) => {
    console.log(error);
  }
);

// Response parsing interceptor
API.interceptors.response.use(
  (response) => response,
  (error) => parseErrorCode(error)
);

// For DEBUG
/*
API.interceptors.request.use(request => {
    console.log('Starting Request', JSON.stringify(request, null, 2))
    return request
});

API.interceptors.response.use(response => {
    console.log('Response:', JSON.stringify(response, null, 2))
    return response
});
*/

API.postForm = (uri, objs = {}, headers = {}) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(objs)) {
    formData.append(key, value);
  }
  return API.post(uri, formData, headers);
};

API.postRawForm = (uri, formData, headers = {}) => {
  return API.post(uri, formData, headers);
};

API.getForm = (uri, objs = {}, headers = {}) => {
  const queryString = qs.stringify(objs);
  if (queryString) {
    uri = uri + "?" + queryString;
  }
  return API.get(uri, {}, headers);
};

API.putRawForm = (uri, formData, headers = {}) => {
  return API.put(uri, formData, headers);
};

API.putForm = (uri, objs = {}, headers = {}) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(objs)) {
    formData.append(key, value);
  }
  return API.put(uri, formData, headers);
};

API.deleteForm = (uri, objs = {}, headers = {}) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(objs)) {
    formData.append(key, value);
  }
  return API.delete(uri, formData, headers);
};

API.patchForm = (uri, objs = {}, headers = {}) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(objs)) {
    formData.append(key, value);
  }
  return API.patch(uri, formData, headers);
};

export default API;
