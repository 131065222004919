import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import BuyerProjectCard from "./BuyerProjectCard";
import Icon_bell_p from "../../../image/Icon_bell_p.svg";
import Icon_bell_off from "../../../image/Icon_bell_off.svg";
import first_page_black_48dp from "../../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../../image/chevron_left_black_24dp.svg";
import navigate_next_black_48dp from "../../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../../image/last_page_black_48dp.svg";

import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import moment from "moment";
import CommonLib from "../../../libraries/CommonLib";
import Constants from "../../../constants/constants";
import Paging from "../../common/Paging";
import _ from "lodash";
import Icon_bell_y from "../../../image/Icon_bell_y.svg";

const BuyerProjectList = (props) => {
  const [dv, setDv] = useState(props?.location?.state?.dv || props?.match?.params?.dv || (window.location.pathname.includes('progress') ? "progress" : "done"));
  const [id, setId] = useState(props?.location?.state?.id || props?.match?.params?.id);
  const [requests, setRequests] = useState();
  const history = useHistory();

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      await getProjects();
      if (dv === "done") await APIHelper.putForm("project/done/read");
    }
    fetch();
  }, [currPage]);

  const getProjects = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("project/gets", {mode: "buyer_me", orderBy: "desc", status: dv === 'progress' ? 'new_and_progress' : dv, paging: 1, currPage});
      if (code === 200) {
        if (pageInfo) setPageInfo(pageInfo);

        for (const [key, item] of Object.entries(result)) {
          const {data: {code, result: quickmessage}} = await APIHelper.getForm("project/timeline/quickmessage", {projectId: item?.id, type: 'BUYER'});
          if (window.location.pathname.includes('/progress') && CommonLib.parseInt(quickmessage?.id) > 0) item.action_yn = 1;
          else item.action_yn = 0;
        }

        setRequests(result);
      }
      window.scrollTo(0, 0);
      return resolve(true);
    });
  }

  return (
    <BuyerProjectCard dv={dv} id={id}>
      <div className="box_round min_h cnt_area pb-5">
        <div className="mx-5 my-4 pb-0">
          <p className="h3 bold border-bottom py-3">{window.location.pathname.includes("done") ? "완료" : "진행중"} 프로젝트</p>

          {requests && requests.map((project, key) => {
              let stepTitle = '';
              if (project.step === 1) project?.products?.[0].request_forms?.[0]?.request_no?.length > 0 ? stepTitle = 'STEP1 : 단가조사' : stepTitle = 'STEP1 : 제작문의';
              else if (project.step === 2) stepTitle = 'STEP2 : 샘플 디자인';
              else if (project.step === 3) stepTitle = 'STEP3 : 견적 및 계약';
              else if (project.step === 4) stepTitle = 'STEP4 : 제품 제작';
              else if (project.step === 5) stepTitle = 'STEP5 : 통관/선적';
              else if (project.step === 6) stepTitle = 'STEP6 : 완료';

              const rep_maker = project?.rep_maker_user_id > 0 ? project?.rep_maker : project?.products?.[0]?.maker_requests?.[0]?.maker;

              // 2023.08.08
              return (
                <div
                  // onClick={() => (project?.products?.[0].request_forms?.[0]?.request_no?.length === 0 || project?.accepted !== 1) ? CommonLib.jalert('현 프로젝트는 제작 문의 상태입니다.') : history.push(`/project/buyer/task/home/${dv}/${project.id}`)}
                  className=" border-bottom"
                  // style={{borderTop: "1px solid #aaa !important", cursor: "pointer"}}
                  style={{borderTop: "1px solid #aaa !important"}}
                >
                  <div className="border-bottom p-3 p_rative">
                    <div className="p_absolute_tr3" style={{top: "15px"}}>
                      <p className="box_round_sm bg-orange bold txt-sm">
                        {stepTitle}
                      </p>
                    </div>
                    <p className="p_absolute_br2 txt-sm" style={{bottom: "20px"}}>
                      제작 의뢰 요청일 : {moment(project.created_at).format("YYYY.MM.DD")}
                    </p>
                    <div className="txt-gray p_rative w-100 ">
                      <p>프로젝트 No : {project.prj_no}</p>
                      <p className="h5 bold pt-1 txt-point">{project?.name?.length > 0 ? project?.name : "프로젝트명 미정"}</p>
                      {project?.products?.[0].request_forms?.[0]?.request_no?.length > 0 && project?.accepted === 1 ?
                        <p className="txt-sm">제작 의뢰서 : {project?.products?.[0].request_forms?.[0]?.request_no}</p>
                        :
                        <p className="txt-sm">제작 문의 상태</p>
                      }
                    </div>
                    {project?.action_yn === 1 &&
                      <div className="d-block" style={{position: "absolute", marginTop: "-82px", marginLeft: "210px"}}>
                        <p style={{backgroundColor: "#fcb042", width: "120px", height: "30px", borderRadius: "15px"}}>
                          <button type="button" className="btn btn-sm">
                            <img src={Icon_bell_y} style={{width: "16px", marginTop: "-2px"}}/>
                            <span style={{color: "#a93f00", marginLeft: "7px", fontWeight: "bold"}}>액션 필요</span>
                          </button>
                        </p>
                      </div>
                    }
                  </div>
                  <div className="row py-3">
                    <div
                      className="col col-8"
                      style={{borderRight: "1px solid #ddd"}}
                    >
                      <div className="mt-3">
                        <table className="table basic_ssm">
                          <colgroup>
                            <col width="20%"/>
                            <col width="*"/>
                            <col width="18%"/>
                            <col width="22%"/>
                          </colgroup>
                          <tr>
                            <th className="center">제품명</th>
                            <td>{project.products?.[0].category.name_kr}-{project.products?.[0].product.name_kr}</td>
                            <th className="center">용량</th>
                            <td>{CommonLib.numberWithCommas(project?.products?.[0].wish_volume)}ml</td>
                          </tr>
                          <tr>
                            <th className="center">판매국가</th>
                            <td>{project?.products?.[0].country?.name_kr}</td>
                            <th className="center">MOQ</th>
                            <td>{CommonLib.numberWithCommas(project?.products?.[0].wish_quantity)}</td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div className="col col-4 p_rative" style={{minWidth: "263px"}}>
                      {false ?
                        <div className="m-2 p_rative" style={{minWidth: "263px"}}>
                          <div className="thum_buyer_info p_absolute p-3">
                            <p
                              className="box_round_30 bg-white px-1 txt-sm"
                              style={{width: "53px"}}
                            >
                              제조사
                            </p>
                            <p className="h5 bold mt-1">{rep_maker && (rep_maker?.company_name.substring(0, 4) + "***")}</p>
                            <p>
                              {/*<img*/}
                              {/*  src={Australia}*/}
                              {/*  style={{width: "24px", marginBottom: "2px"}}*/}
                              {/*/>{" "}*/}
                              {rep_maker && rep_maker?.company_addr && rep_maker?.company_addr.length > 0 ? rep_maker?.company_addr.split(' ')[0] : ''}{" "}
                            </p>
                          </div>
                          <div className="thum_maker">
                            {rep_maker?.rep_image_fid && rep_maker?.rep_image_fid.length > 0 ?
                              <img
                                src={`${Constants.API_HOST}/common/download?fid=${rep_maker?.rep_image_fid}&cd=inline&thumb=400`}
                                style={{width: "263px", height: "120px", objectFit: "cover"}}
                              />
                              :
                              <div style={{width: "263px", height: "120px", objectFit: "cover", backgroundColor: "#efefef"}}></div>
                            }
                          </div>
                        </div>
                        :
                        <div className="m-2 p_rative" style={{minWidth: "263px"}}>
                          <div className="thum_buyer_info p_absolute p-3">
                            <p
                              className="box_round_30 bg-white px-1 txt-sm"
                              style={{width: "80px"}}
                            >
                              매니저
                            </p>
                            <p className="h5 bold mt-1">{project?.manager_leader && project?.manager_leader?.nickname}</p>
                          </div>
                          <div className="thum_maker">
                            {project?.manager_leader?.image_fid ?
                              <img
                                src={`${Constants.API_HOST}/common/download?fid=${project?.manager_leader?.image_fid}&cd=inline&thumb=400`}
                                style={{
                                  width: "190px",
                                  height: "190px",
                                  objectFit: "cover",
                                }}
                              />
                              :
                              <div style={{width: "190px", height: "190px", backgroundColor: "#efefef"}}></div>
                            }
                          </div>
                        </div>
                      }
                    </div>

                  </div>
                </div>
              )
            }
          )}
          {requests && requests.length === 0 &&
            <div className="col w-100">
              <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-lblue bold">
                {window.location.pathname.includes("done") ? "완료된 프로젝트가 없습니다." : "진행 중인 프로젝트가 없습니다."}
              </div>
            </div>
          }
        </div>
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </div>
    </BuyerProjectCard>
  );
};

export default BuyerProjectList;
