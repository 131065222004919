import React, {useEffect, useState} from "react";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import AdminBuyerHome from "./AdminBuyerHome";
import AdminBuyerDetail from "./AdminBuyerDetail";
import AdminBuyerEtc from "./AdminBuyerEtc";
import BuyerList from "../common/BuyerList";
import AdminBuyerManagement from "./AdminBuyerManagement";
import CommonLib from "../../../libraries/CommonLib";
import AdminMakerManagement from "../maker/AdminMakerManagement";
import APIHelper from "../../../helpers/APIHelper";

const AdminBuyer = (props) => {
  const [userId, setUserId] = useState(props?.userId || (props?.location?.state?.id || props?.location?.state?.user_id || props?.match?.params?.id));

  // 탭 state
  const [tabState, setTabState] = useState({
    home: true,
    detail: false,
    etc: false,
  });

  // tab 클릭 핸들러
  const tabClick = (e) => {
    const tab = { ...tabState };
    const target = e.currentTarget.id;
    for (let key in tab) {
      target === key ? (tab[key] = true) : (tab[key] = false);
    }
    setTabState(tab);
  };

  // 현재 선택한 바이어 state
  const [buyerState, setBuyerState] = useState();
  const [renew, setRenew] = useState(0);
  const buyerClick = (e) => {
    setBuyerState(e);
    setRenew(new Date().getTime());
  };

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "바이어관리 > 바이어 관리",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{ height: "calc(100% - 30px)" }}
      >
        <section className="col-4" style={{ height: "98%" }}>
          <BuyerList click={buyerClick} addbtn={true} userId={userId} />
        </section>
        <section className="col-5" style={{ height: "98%" }}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{ height: "100%" }}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold p-2">바이어 상세</p>
              {/*<p className="m-1 mb-2">*/}
              {/*  <button type="button" className="btn btn_gray">*/}
              {/*    상세 확인*/}
              {/*  </button>*/}
              {/*</p>*/}
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll mt-4 px-2"
              style={{ height: "calc(100% - 80px)" }}
            >
              <div className="wid_inline TAB">
                <li className={`${tabState.home ? "ON" : ""}`}>
                  <a href="#" onClick={tabClick} id="home">
                    기본정보
                  </a>
                </li>
                <li className={`${tabState.detail ? "ON" : ""}`}>
                  <a href="#" onClick={tabClick} id="detail">
                    상세정보
                  </a>
                </li>
                {/*<li className={`${tabState.etc ? "ON" : ""}`}>*/}
                {/*  <a href="#" onClick={tabClick} id="etc">*/}
                {/*    활동&기타 정보*/}
                {/*  </a>*/}
                {/*</li>*/}
              </div>
              {tabState.home ? <AdminBuyerHome buyer={buyerState} renew={renew}/> : ""}
              {tabState.detail ? <AdminBuyerDetail buyer={buyerState} renew={renew}/> : ""}
              {tabState.etc ? <AdminBuyerEtc buyer={buyerState} renew={renew}/> : ""}
            </div>
          </div>
        </section>
        <section className="col-3" style={{height: "98%"}}>
          <AdminBuyerManagement buyer={buyerState} renew={renew}/>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminBuyer;
