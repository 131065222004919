import React, {useEffect, useState} from "react";
import CsCenterCard from "./CsCenterCard"
import FaqItems from "./FaqItems";
import first_page_black_48dp from "../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../image/chevron_left_black_24dp.svg";
import navigate_next_black_48dp from "../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../image/last_page_black_48dp.svg";

import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";

const Faq = (props) => {
  const [rets, setRets] = useState([]);
  const [gubunFilter, setGubunFilter] = useState('');
  const [gubunCds, setGubunCds] = useState([]);

  const getGubunCds = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '160000'});
      if (code === 200) setGubunCds(result);
      return resolve(true);
    });
  };

  const gets = async () => {
    const {data: {code, result, message}} = await APIHelper.getForm("cs/faq/gets", {gubunFilter, userTypeCd: localStorage.getItem("user.faq.user_type_cd"), mode: "USER"});
    setRets(result);
  };

  useEffect(() => {
    const fetch = async () => {
      await getGubunCds();
      await gets();
    }
    fetch();
  }, [gubunFilter, localStorage.getItem("user.faq.user_type_cd")]);

  return (
    <CsCenterCard>
      <div className="box_round min_h cnt_area">
        <div className="mx-5 my-4">
          <p className="h3 bold border-bottom py-3">FAQ - {window.location.pathname.includes("maker") ? "제조사" : "바이어"}</p>
          <div className="d-flex justify-content-between mt-4">
            <p>&nbsp;</p>

            <div>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={async (e) => await setGubunFilter(e.target.value)}
                value={gubunFilter}
              >
                <option value="" selected={'' === gubunFilter}>전체</option>
                {gubunCds.map((item, k) => <option key={k} value={item.cd} selected={item.cd === gubunFilter}>{item.name_kr}</option>)}
              </select>
            </div>
          </div>

          <table className="table accordion my-3">
            <colgroup>
              <col width="55px"/>
              <col width="160px"/>
              <col width="*"/>
              <col width="130px"/>
              <col width="55px"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>카테고리</th>
              <th>제목</th>
              <th>작성일</th>
              <th></th>
            </tr>
            <FaqItems items={rets}/>
          </table>
          {rets?.length === 0 &&
            <div className="col w-100">
              <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-white">
                등록된 FAQ 가 없습니다.
              </div>
            </div>
          }

          {/*<div className="d-flex justify-content-center py-4 d-none">*/}
          {/*  <ul className="d-flex my-3" style={{margin: "0 auto"}}>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" aria-label="Previous">*/}
          {/*        <img src={first_page_black_48dp} style={{width: "25px"}}/>*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" aria-label="Previous">*/}
          {/*        <img src={chevron_left_black_24dp} style={{width: "25px"}}/>*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" className="ON">*/}
          {/*        1*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#">2</a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#">3</a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" aria-label="Previous">*/}
          {/*        <img src={navigate_next_black_48dp} style={{width: "25px"}}/>*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" aria-label="Previous">*/}
          {/*        <img src={last_page_black_48dp} style={{width: "25px"}}/>*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*</div>*/}
        </div>
      </div>
    </CsCenterCard>
  );
};

export default Faq;
