import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import AdminCsFaqList from "./AdminCsFaqList";
import APIHelper from "../../../helpers/APIHelper";

const AdminCsFaq = (e) => {
  const history = useHistory();

  // 현재 선택한 Faq state
  const [faqState, setFaqState] = useState();
  const faqClick = (e) => {
    history.push(`/admin/cs/faq/detail/${e.id}`);
  };

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: localStorage.getItem("admin.faq.user_type_cd")=="BUYER" ? "고객서비스 > FAQ - 바이어" : "고객서비스 > FAQ - 제조사",
          param1 : localStorage.getItem("admin.faq.user_type_cd"),
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, [localStorage.getItem("admin.faq.user_type_cd")]);

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{ height: "calc(100% - 30px)" }}
      >
        <AdminCsFaqList click={faqClick} />

        <section className="col-8" style={{ height: "100%" }}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{ height: "98%" }}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">FAQ Detail</p>
              <p className="m-1 mb-2">
                <button
                  type="button"
                  className="btn btn_gray txt-sm py-1 px-3"
                  style={{ marginRight: "5px" }}
                >
                  삭제
                </button>
                <Link to={`/admin/cs/faq/detail/${faqState && faqState.id}`}>
                  <button
                    type="button"
                    className="btn btn-purple txt-sm py-1 px-3"
                  >
                    수정
                  </button>
                </Link>
              </p>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll px-2 mt-4"
              style={{ height: "calc(100% - 70px)" }}
            >
              <article className="wid_inline">
                {faqState && (
                  <table className="table basic w-100">
                    <colgroup>
                      <col width="18%" />
                      <col width="*" />
                      <col width="18%" />
                      <col width="*" />
                    </colgroup>
                    <tr>
                      <th className="center">제목</th>
                      <td colspan="3" className="border-top bold">
                        {faqState.title}
                      </td>
                    </tr>
                    <tr>
                      <th className="center">조회수 </th>
                      <td>{faqState.views} </td>

                      <th className="center">노출유무 </th>
                      <td>노출{faqState.exposure ? "" : "안"}함</td>
                    </tr>
                    <tr>
                      <th className="center">대상 </th>
                      <td>{faqState.target} </td>

                      <th className="center">분류 </th>
                      <td>{faqState.category} </td>
                    </tr>
                    <tr>
                      <th className="center">내용</th>
                      <td
                        colspan="3"
                        className="border-bottom p-4 p_rative pb-5"
                      >
                        {faqState.content}
                        <p className="p_absolute_br2">{faqState.date}</p>
                      </td>
                    </tr>
                  </table>
                )}
              </article>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminCsFaq;
