import React from "react";
import {Link} from "react-router-dom";

import "../../sass/main.scss";

const InquiryItem = (props) => {
	// 날짜 패턴 적용
	const date = props.date;

	return (
		<tr>
			<td>{props.no}</td>
			<td>{props.category}</td>
			<td className="left">
				<Link
					to={{
						pathname: `/cscenter/inquiry/${props.no}`,
						state: {
							title: props.title,
							category: props.category,
							text: props.text,
							date: date,
							response: props.response,
							status: props.status
						},
					}}
				>
					{props.title}
				</Link>
			</td>
			<td>
				<p
					className={`box_round_4 txt-sm ${
						props.status === "답변완료" ? "bg-red" : "bg-purple"
					}`}
					style={{width: "50px"}}
				>
					{props.status === '답변대기' ? '대기' : '답변'}
				</p>
			</td>
			<td>{date}</td>
		</tr>
	);
};

export default InquiryItem;
