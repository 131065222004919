import React from "react";
import { Link } from "react-router-dom";

import "../../../sass/main.scss";

const BuyerProjectCard = (props) => {
  const path = window.location.pathname;

  return (
    <div className="BODY MAX my-4">
      <div
        className="mt-3 d-flex justify-content-between w-100"
        style={{ height: "100%" }}
      >
        <div className="box_round left_menu">
          <div className="left_menu_title">프로젝트 관리</div>
          <ul className="left_menu_list p-0">
            <li
              className={`${path.includes("project/buyer/home") ? "on" : ""}`}
            >
              <Link to="/project/buyer/home">프로젝트 홈</Link>
            </li>

            <li className={`${path.includes("progress") ? "on" : ""}`}>
              <Link to="/project/buyer/task/progress">진행중 프로젝트</Link>
            </li>
            <li className={`${path.includes("done") ? "on" : ""}`}>
              <Link to="/project/buyer/task/done">완료 프로젝트</Link>
            </li>
          </ul>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default BuyerProjectCard;
