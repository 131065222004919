import React, {useState} from "react";
import MypageCard from "./MypageCard";
import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import CommonLib from "../../libraries/CommonLib";

const MypageChangePasswd = (props) => {
  const [currentPasswd, setCurrentPasswd] = useState('');
  const [newPasswd, setNewPasswd] = useState('');
  const [newPasswdRe, setNewPasswdRe] = useState('');

  const submit = async () => {
    if (currentPasswd.length <= 0) {
      await CommonLib.jalert('현재 비밀번호를 입력해 주세요.');
      return;
    }
    if (newPasswd.length <= 0) {
      await CommonLib.jalert('새 비밀번호를 입력해 주세요.');
      return;
    }
    if (newPasswdRe.length <= 0) {
      await CommonLib.jalert('새 비밀번호 확인을 입력해 주세요.');
      return;
    }

    if (newPasswd !== newPasswdRe) {
      await CommonLib.jalert('비밀번호 확인이 일치하지 않습니다.');
      return;
    }

    if (!await CommonLib.jconfirm('비밀번호를 변경하시겠습니까?')) return;

    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.putForm("user/change/passwd", {currentPasswd, newPasswd});
      await CommonLib.jalert(message);
      return resolve(true);
    });
  };

  return (
    <MypageCard>
      <div className="box_round cnt_area pb-5">
        <div className="mx-5 my-4">
          <p className="h3 bold border-bottom py-3">패스워드 변경</p>
          <br/>

          <p className="py-3">
            현재 비밀번호를 입력하신 후 변경할 비밀번호를 입력해 주세요.
          </p>
          <table className="table basic my-3" style={{textAlign: "center"}}>
            <colgroup>
              <col width="25%"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th>현재 비밀번호</th>
              <td>
                <input
                  className="form-control"
                  type="password"
                  placeholder="Current Password"
                  aria-label="default input example"
                  onChange={async (e) => await setCurrentPasswd(e.target.value)}
                  value={currentPasswd}
                />
              </td>
            </tr>
            <tr>
              <th>새 비밀번호</th>
              <td>
                <input
                  className="form-control"
                  type="password"
                  placeholder="New Password"
                  aria-label="default input example"
                  onChange={async (e) => await setNewPasswd(e.target.value)}
                  value={newPasswd}
                />
              </td>
            </tr>
            <tr>
              <th>새 비밀번호 확인</th>
              <td>
                <input
                  className="form-control"
                  type="password"
                  placeholder="New Password confirm"
                  aria-label="default input example"
                  onChange={async (e) => await setNewPasswdRe(e.target.value)}
                  value={newPasswdRe}
                />
              </td>
            </tr>
          </table>

          <div className="d-flex justify-content-between my-5">
            <p></p>
            <button type="button" className="btn btn_puple px-3" onClick={submit}>
              비밀번호 변경하기
            </button>
          </div>
        </div>
      </div>
    </MypageCard>
  );
};

export default MypageChangePasswd;
