import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import openInquiry from "../../actions/openInquiry";
import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import CommonLib from "../../libraries/CommonLib";

const InquiryModal = (props) => {
  const closeModal = () => {
    props.openInquiry("closed", "");
  };

  let modalInlineStyle;

  if (props.inquiryPop.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }

  const [id, setId] = useState(props.inquiryPop.content?.id || 0);
  const [gubunCds, setGubunCds] = useState([]);
  const [gubunCd, setGubunCd] = useState();
  const [subject, setSubject] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const getGubunCds = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '140000'});
      if (code === 200) {
        setGubunCds(result);
        if (result?.length > 0) setGubunCd(result?.[0]?.cd);
      }
      return resolve(true);
    });
  };

  const get = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("cs/inquiry/get", {id});
      if (code === 200) {
        setGubunCd(result?.gubun?.cd);
        setSubject(result?.subject);
        setQuestion(result?.question);
        setAnswer(result?.answer);
      }
      return resolve(true);
    });
  };

  useEffect(() => {
    const fetch = async () => {
      setGubunCds([]);
      setGubunCd('');
      setSubject('');
      setQuestion('');
      setAnswer('');
      await getGubunCds();
      if (parseInt(props.inquiryPop.content?.id, 10) > 0) await get();
    }
    fetch();
  }, [props.inquiryPop.content.renew]);

  const submit = async () => {
    if (subject.trim() === '') {
      await CommonLib.jalert("제목을 입력해 주세요.");
      return;
    }

    if (question.trim() === '') {
      await CommonLib.jalert("내용을 입력해 주세요.");
      return;
    }

    const {data: {code, result, message}} = await APIHelper.postForm("cs/inquiry/register", {projectId: props.inquiryPop.content.projectId, gubunCd, subject, question});
    await CommonLib.jalert(message);
    if (code === 200) closeModal();
    if (props.inquiryPop.content.callback) props.inquiryPop.content.callback();
  };

  return (
    <div style={{display: modalInlineStyle}}>
      <div
        className="pop_box02"
        style={{
          width: "750px",
          minHeight: "500px",
          maxHeight: "850px",
        }}
      >
        <div className="POP_title pb-3">
          <h4>
            <strong>
              {parseInt(props.inquiryPop.content.projectId, 10) > 0 && <span style={{fontSize: "1.5rem"}}>프로젝트&nbsp;</span>}
              <span style={{fontSize: "1.5rem"}}>1:1 문의</span>
            </strong>
          </h4>
          <p className="closed">
            <button type="button" className="btn" onClick={closeModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </button>
          </p>
        </div>

        {props.inquiryPop.content.mode === 'view' ?
          <>
            <div className="POP_cnt_box_lg" style={{marginBottom: "95px"}}>
              <table className="table light_gray w-100">
                <tr>
                  <th className="d-flex justify-content-between">
                    <p className="h5 txt-gray px-3 pt-1" style={{padding: "0"}}>
                      {props.inquiryPop.content.subject}
                    </p>
                    <p className="txt-sm txt-gray px-3 pt-2" style={{padding: "0"}}>
                      {props.inquiryPop.content.date}
                    </p>
                  </th>
                </tr>
                <tr style={{borderStyle: "none", minHeight: "100px"}}>
                  <td className="px-3">{CommonLib.escapedNewLineToLineBreakTag(props.inquiryPop.content.question || '')}</td>
                </tr>
              </table>

              {props.inquiryPop.content.status_cd === '답변완료' &&
                <div
                  className="my-3 bg-lpurple p-3 border-top border-bottom"
                  style={{minHeight: "200px"}}
                >
                  <p
                    className="box_round_4 bg-red px-2 text-center"
                    style={{width: "70px"}}
                  >
                    답변
                  </p>
                  <br/>
                  <p className="txt-dark">{CommonLib.escapedNewLineToLineBreakTag(props.inquiryPop.content.answer || '')}</p>
                </div>
              }
            </div>
          </>
          :
          <>
            <div className="POP_cnt_box_lg" style={{marginBottom: "95px"}}>
              <table className="table light_gray w-100">
                <tr>
                  <th className="d-flex justify-content-between bg-white" style={{borderStyle: "none"}}>
                    <select
                      className="form-select form-select-sm w-50"
                      aria-label="Default select example"
                      onChange={async (e) => await setGubunCd(e.target.value)}
                      value={gubunCd}
                    >
                      {gubunCds.map((item, k) => <option key={k} value={item.cd} selected={item.cd === gubunCd}>{item.name_kr}</option>)}
                    </select>
                  </th>
                </tr>
                <tr>
                  <th className="d-flex justify-content-between bg-white">
                    <input type="text" className="form-control form-control-sm"
                           placeholder="제목을 입력해 주세요."
                           onChange={async (e) => await setSubject(e.target.value)}
                           value={subject}
                    />
                  </th>
                </tr>
                <tr style={{borderStyle: "none", minHeight: "100px"}}>
                  <td className="px-0">
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="5"
                      placeholder="내용을 입력해 주세요."
                      onChange={async (e) => await setQuestion(e.target.value)}
                      value={question}
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div className="POP_foot">
              <button className="btn btn_puple" type="button" onClick={() => submit()}>
                문의하기
              </button>
              <button className="btn btn_puplelight" type="button" onClick={closeModal} style={{marginLeft: "7px"}}>
                닫기
              </button>
            </div>
          </>
        }
      </div>
      <div className="pop_bak" onClick={closeModal}></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    inquiryPop: state.inquiryPop,
  };
}

function mapDispatchToProps(dispatcher) {
  return bindActionCreators(
    {
      openInquiry: openInquiry,
    },
    dispatcher
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(InquiryModal);
