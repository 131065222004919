import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import icon_i from "../../image/icon_i.svg";
import icon_message from "../../image/icon_message.svg";
import MypageCard from "./MypageCard";
import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import CommonLib from "../../libraries/CommonLib";

const MypageHome = (props) => {
  const [statuses, setStatuses] = useState({});
  const [buyer, setBuyer] = useState({});
  const [maker, setMaker] = useState({});
  const [inquiryCnt, setInquiryCnt] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const fetch = async () => {
      await getStatuses();
    }
    fetch();
  }, []);

  const getStatuses = () => {
    return new Promise(async (resolve, reject) => {
      if (localStorage.getItem("user.session.user_type") === "MAKER") {
        const {data: {code, result, message}} = await APIHelper.getForm("user/maker/get");
        if (code === 200) setMaker(result);
      } else {
        const {data: {code, result, message}} = await APIHelper.getForm("user/buyer/get");
        if (code === 200) setBuyer(result);
      }
      {
        const {data: {code, result, message}} = await APIHelper.get(localStorage.getItem("user.session.user_type") === "MAKER" ? "project/statuses/maker/me" : "project/statuses/buyer/me");
        if (code === 200) {
          setStatuses(result);
        }
      }
      {
        const {data: {code, result, message}} = await APIHelper.getForm("cs/inquiry/mines", {projectId: 0});
        setInquiryCnt(result?.length);
      }
      return resolve(true);
    });
  };

  return (
    <MypageCard>
      <div className="box_round cnt_area pb-5">
        <div className="mx-5 my-4 pb-5">
          <p className="h3 bold border-bottom py-3">My Home</p>
          {/*<br />*/}
          {/*<div className="box_round bg-lgray p-3 d-flex my-2">*/}
          {/*  <p>*/}
          {/*    <img src={icon_i} />*/}
          {/*  </p>*/}
          {/*  <p className="px-3 h5 bold pt-1">*/}
          {/*    <span className="txt-red h5 bold">이메일 인증</span>을 하셔야*/}
          {/*    합니다{" "}*/}
          {/*    <span className="normal">*/}
          {/*      - 오른쪽 버튼을 눌러 인증을 완료하세요*/}
          {/*    </span>*/}
          {/*  </p>*/}
          {/*  <p className="ms-auto">*/}
          {/*    <Link to="/mypage/change/email">*/}
          {/*      <button*/}
          {/*        type="button"*/}
          {/*        className="btn btn-sm btn-outline-light hov_red"*/}
          {/*      >*/}
          {/*        이메일 인증하기*/}
          {/*      </button>*/}
          {/*    </Link>*/}
          {/*  </p>*/}
          {/*</div>*/}
          {/*<div className="box_round bg-lgray p-3 d-flex my-2">*/}
          {/*  <p>*/}
          {/*    <img src={icon_message} />*/}
          {/*  </p>*/}
          {/*  <p className="px-3 h5 bold pt-1">*/}
          {/*    <span className="txt-pink h5 bold">*/}
          {/*      읽지 않은 메세지가 총 5개*/}
          {/*    </span>{" "}*/}
          {/*    있습니다.*/}
          {/*  </p>*/}
          {/*  <p className="ms-auto">*/}
          {/*    <Link to="/mypage/alarm">*/}
          {/*      <button*/}
          {/*        type="button"*/}
          {/*        className="btn btn-sm btn-outline-light hov_pink"*/}
          {/*      >*/}
          {/*        메시지 보러가기*/}
          {/*      </button>*/}
          {/*    </Link>*/}
          {/*  </p>*/}
          {/*</div>*/}

          <p className="h5 bold py-3 mt-4">프로젝트 현황</p>
          <div className="row justify-content-between">
            <div className="col ">
              <div className="box_round bg-lpurple p-3 d-flex justify-content-center">
                <p
                  className="mt-4 box_round_sm bg-purple2"
                  style={{height: "28px"}}
                >
                  신규
                </p>
                <p className="px-3">
                  <span className="display-4 bold">{statuses.new}</span>{" "}
                  <span className="h2 bold">건</span>
                </p>
              </div>
            </div>
            <div className="col">
              <div className="box_round bg-lred p-3 d-flex justify-content-center" onClick={() => history.push(localStorage.getItem("user.session.user_type") === "BUYER" ? "/project/buyer/task/progress" : "/project/maker/task/progress")} style={{cursor: "pointer"}}>
                <p
                  className="mt-4 box_round_sm bg-red"
                  style={{height: "28px"}}
                >
                  진행중
                </p>
                <p className="px-3">
                  <span className="display-4 bold">{statuses.progress}</span>{" "}
                  <span className="h2 bold">건</span>
                </p>
              </div>
            </div>
            <div className="col">
              <div className="box_round bg-lblue p-3 d-flex justify-content-center" onClick={() => history.push(localStorage.getItem("user.session.user_type") === "BUYER" ? "/project/buyer/task/done" : "/project/maker/task/done")} style={{cursor: "pointer"}}>
                <p
                  className="mt-4 box_round_sm bg-blue"
                  style={{height: "28px"}}
                >
                  완료
                </p>
                <p className="px-3">
                  <span className="display-4 bold">{statuses.done}</span>{" "}
                  <span className="h2 bold">건</span>
                </p>
              </div>
            </div>
          </div>

          <p className="h5 bold py-3 mt-0"></p>
          <div className="row">
            <div className="col">
              <div className="box_round bg-lred p-3 d-flex justify-content-center">
                <p
                  className="mt-4 box_round_sm bg-red "
                  style={{height: "30px"}}
                >
                  신규알림
                </p>
                <p className="px-3">
                  {/*TODO*/}
                  <span className="display-4 bold">0</span>{" "}
                  <span className="h2 bold">건</span>
                </p>
              </div>
            </div>
            <div className="col">
              <div className="box_round bg-lblue p-3 d-flex justify-content-center" onClick={() => history.push('/cscenter/inquiry')} style={{cursor: "pointer"}}>
                <p
                  className="mt-4 box_round_sm bg-blue "
                  style={{height: "30px"}}
                >
                  1:1 문의
                </p>
                <p className="px-3">
                  <span className="display-4 bold">{inquiryCnt}</span>{" "}
                  <span className="h2 bold">건</span>
                </p>
              </div>
            </div>
            <div className="col">
              <div className="box_round bg-lpurple p-3 d-flex justify-content-center" onClick={() => history.push(localStorage.getItem("user.session.user_type") === "BUYER" ? "/maker/find" : "/buyer/find")} style={{cursor: "pointer"}}>
                <p
                  className="mt-4 box_round_sm bg-purple2"
                  style={{height: "30px"}}
                >
                  관심업체
                </p>
                <p className="px-3">
                  <span className="display-4 bold">{localStorage.getItem("user.session.user_type") === "MAKER" ? CommonLib.parseInt(maker?.my_favorite_cnt) : CommonLib.parseInt(buyer?.my_favorite_cnt)}</span>{" "}
                  <span className="h2 bold">건</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MypageCard>
  );
};

export default MypageHome;
