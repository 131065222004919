import React, {useState} from "react";
import icon_foot from "../../image/icon_foot.svg";
import join_img01 from "../../image/join_img01.png";
import join_img02 from "../../image/join_img02.png";
import "../../sass/main.scss";
import {useHistory} from "react-router-dom";

const JoinIntro = (props) => {
  const history = useHistory();
  const [type, setType] = useState("바이어");

  const next = () => {
    if (type === "바이어") history.push("/join/buyer");
    else history.push("/join/maker");
  };

  return (
    <>
      <div
        className="container-fluid p_rative"
        style={
          window.innerHeight > 1180
            ? {height: "calc(100% - 317px)"}
            : {minHeight: "850px"}
        }
      >
        <div
          className="MAX join_box my-5"
          style={{width: "1260px", minHeight: "900px"}}
        >
          <div className="p-5 p_rative">
            <p className="h3 bold">회원 가입</p>
            <p className="border-bottom py-2">
              Darlibaba 에 오신 것을 환영합니다.
            </p>
            <br/>
            <br/>
            <p className="h5 bold mt-3">01. 바이어, 제조사 선택하세요.</p>
            <div className="row row-cols-2 my-4">
              {/*2023.08.08*/}
              <div className="col"
                // onClick={() => setType("바이어")}
                   onClick={() => next()}
              >
                <a href="#">
                  <div
                    style={{height: "393px"}}
                    className={`border${
                      type === "바이어" ? "_purple" : ""
                    } box_round_5 p-4 txt-point 
                  }`}
                  >
                    <p className="h3 bold">바이어</p>
                    <p className="m-4">
                      <img src={join_img01} style={{width: "250px"}}/>
                    </p>
                    <p className="h6">
                      제품 OEM, ODM 주문 요청을 할 수 있어요.
                      <br/>
                      한국의 제조사를 찾아요.
                    </p>
                  </div>
                </a>
              </div>
              {/*2023.08.08 수정*/}
              <div className="col"
                // onClick={() => setType("제조사")}
                   style={{opacity: 0.5}}
              >
                {/*<a href="#">*/}
                <div
                  style={{height: "393px"}}
                  className={`border${
                    type === "제조사" ? "_purple" : ""
                  } box_round_5 p-4 txt-point 
                  }`}
                >
                  <p className="h3 bold">제조사</p>
                  <p className="m-4">
                    <img src={join_img02} style={{width: "250px"}}/>
                  </p>
                  <p className="h6">
                    제품을 생산 합니다.
                    <br/>
                    OEM, ODM 수주하고자 하는 기업.
                  </p>
                </div>
                {/*</a>*/}
              </div>
            </div>

            {/*2023.08.08*/}
            <div className="mt-4 join-tooltip text-start p-0 w-100"
                 onClick={() => next()} style={{cursor: "pointer"}}
            >
              <p className="h5 bold bg-lpurple border-bottom p-2 px-3 box_round_4_paddingNo">
                {type}
              </p>

              {type === '제조사' ?
                <p className="txt-dark p-3">
                  해외 바이어를 찾아보세요. 안전한 거래
                  시스템을 활용할 수 있어요.
                  <br/>
                  전문가에게 문의를 해보세요. 매달 매거진을 받아 보실 수 있어요.
                  <br/>
                </p>
                :
                <p className="txt-dark p-3">
                  국내 유명 제조사의 제품을 찾아보고 주문하세요. 안전한 거래
                  시스템을 활용할 수 있어요.
                  <br/>
                  전문가에게 문의를 해보세요. 매달 매거진을 받아 보실 수 있어요.
                  <br/>
                </p>
              }
            </div>

            <div className="my-5 text-center">
              <button
                type="button"
                className="btn btn-lg btn_puple py-2 bold px-5"
                onClick={next}
              >
                {type}로 회원가입 진행 합니다.
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinIntro;
