import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import favorite from "../../image/favorite.svg";
import favorite_border from "../../image/favorite_border.svg";
import icon_l_btn01 from "../../image/icon_l_btn01.svg";
import icon_l_btn02 from "../../image/icon_l_btn02.svg";
import icon_l_btn01_sel from "../../image/icon_l_btn01_sel.svg";
import icon_l_btn02_sel from "../../image/icon_l_btn02_sel.svg";
import "../../sass/main.scss";
import MakerDetail from "./MakerDetail";
import MakerProduct from "./MakerProduct";
import APIHelper from "../../helpers/APIHelper";
import Constants from "../../constants/constants";
import MakerCompany from "./MakerCompany";
import MakerProductView from "./MakerProductView";
import MakerReview from "./MakerReview";
import CommonLib from "../../libraries/CommonLib";

const MakerHome = (props) => {
  const [userId, setUserid] = useState((props?.location?.state?.user_id || props?.match?.params?.user_id) || 0);
  const [makerProductId, setMakerProductId] = useState(0);
  const [maker, setMaker] = useState({});

  const fetch = async () => {
    const {data: {code, result, message}} = await APIHelper.getForm("user/maker/get", {id: userId});
    if (code === 200) setMaker(result);
  }

  useEffect(() => {
    fetch();
  }, []);

  // Tab 핸들러
  const [tabState, setTabState] = useState({
    detail: true,
    company: false,
    product: false,
    productView: false,
    review: false,
  });

  const tabClick = (e) => {
    const newTab = {...tabState};
    const target = e.currentTarget.id;

    dispatchClick(newTab, target);
  };

  const dispatchClick = (newTab, target) => {
    // 클릭한 target만 true로 바꾸고 나머지는 false
    for (let key in newTab) {
      key === target ? (newTab[key] = true) : (newTab[key] = false);
    }
    setTabState(newTab);
  }

  const clickProductView = (_makerProductId) => {
    setMakerProductId(_makerProductId);

    const newTab = {...tabState};
    const target = "productView";

    dispatchClick(newTab, target);
  };

  const clickProduct = () => {
    setMakerProductId(0);

    const newTab = {...tabState};
    const target = "product";

    dispatchClick(newTab, target);
  };

  const history = useHistory();

  // 찜버튼 test
  const [isFavorite, setIsFavorite] = useState(false);

  const favoriteHandler = () => {
    setIsFavorite(!isFavorite);
  };

  const inquiry = async () => {
    if (!localStorage.getItem("user.session.email")) {
      await CommonLib.jalert('로그인이 필요합니다.');
      return;
    }
    history.push({
      pathname: '/manufacture',
      state: {maker_user_id: userId}
    })
  }

  return (
    <div className="BODY MAX my-4">
      <div className="box_round d-flex min_h w-100" style={{minHeight: "100%"}}>
        <div className="left_comp">
          <p>
            {maker.rep_image_fid && maker.rep_image_fid.length > 0 ?
              <img
                src={`${Constants.API_HOST}/common/download?fid=${maker.rep_image_fid}&cd=inline&thumb=400`}
                style={{width: "259px", height: "215px", objectFit: "cover"}}
              />
              :
              <img
                src={process.env.PUBLIC_URL + "/images/no_image.png"}
                style={{width: "259px", height: "215px", objectFit: "cover"}}
              />
            }
          </p>
          <div className="py-3" style={{textAlign: "center"}}>
            <h5 className="txt-point">{maker.company_name}</h5>
            <p className="txt-gray py-2">
              {maker.introduce}
            </p>
            <p className="txt-gray">{maker && maker.company_addr && maker.company_addr.length > 0 ? maker.company_addr.split(' ')[0] : ''}</p>
            <p className="box_border p-2 m-3">
              <img
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  await APIHelper.postForm("user/favorite/toggle", {favorite_user_id: maker?.user_id});
                  await fetch();
                }}
                src={`${parseInt(maker?.favorite, 10) === 1 ? favorite : favorite_border}`}
                className="mx-1"
                style={{width: "31px", cursor: "pointer"}}
              />
              <span className="txt-sm txt-gray">
                {parseInt(maker?.favorite_cnt, 10)}명이 관심을 갖고 있습니다.
              </span>
            </p>
            {localStorage.getItem("user.session.user_type") === "BUYER" &&
              <p className="px-3">
                <button type="button" className="btn btn_puple w-100 py-2 bold" onClick={() => inquiry()}>
                  제작 문의
                </button>
              </p>
            }
            <div className="p-3">
              <div
                className="btn-group w-100"
                role="group"
                aria-label="Basic outlined example"
              >
                {(maker?.user?.grade_cd === '151003' || maker?.user?.grade_cd === '151004') ?
                  <button type="button" className="btn btn-sm btn_puplelight" style={{cursor: "auto", pointerEvents: "none"}}>
                    <img src={icon_l_btn01_sel} style={{width: "18px"}}/> 매니저 인증
                  </button>
                  :
                  <button type="button" className="btn btn-sm btn-outline-light" style={{cursor: "auto", pointerEvents: "none"}}>
                    <img src={icon_l_btn01} style={{width: "18px"}}/> 매니저 인증
                  </button>
                }
                {maker?.license_certified_yn === 'Y' ?
                  <button type="button" className="btn btn-sm btn_puplelight" style={{cursor: "auto", pointerEvents: "none"}}>
                    <img src={icon_l_btn02_sel} style={{width: "18px"}}/> 사업자 인증
                  </button>
                  :
                  <button type="button" className="btn btn-sm btn-outline-light" style={{cursor: "auto", pointerEvents: "none"}}>
                    <img src={icon_l_btn02} style={{width: "18px"}}/> 사업자 인증
                  </button>
                }
              </div>
            </div>

            <div className="p_absolute w-100 py-3" style={{bottom: "0"}}>
              <button
                onClick={() => history.goBack()}
                type="button"
                className="btn btn-outline-light w-85"
              >
                뒤로가기
              </button>
            </div>
          </div>
        </div>
        <div className="cnt_area_comp p-5" style={{height: "max-content"}}>
          {/*<p className="p_absolute_tr">*/}
          {/*  <button type="button" className="btn btn-sm">*/}
          {/*    <img*/}
          {/*      onClick={favoriteHandler}*/}
          {/*      src={`${isFavorite === false ? favorite_border : favorite}`}*/}
          {/*      className="mb-1"*/}
          {/*      style={{width: "31px"}}*/}
          {/*    />*/}
          {/*  </button>*/}
          {/*</p>*/}
          <div className="TAB mb-5">
            <li className={`${tabState.detail ? "ON" : ""}`}>
              <a href="#" onClick={tabClick} id="detail">
                기본정보
              </a>
            </li>
            <li className={`${tabState.company ? "ON" : ""}`}>
              <a href="#" onClick={tabClick} id="company">
                회사상세
              </a>
            </li>
            <li className={`${(tabState.product || tabState.productView) ? "ON" : ""}`}>
              <a href="#" onClick={tabClick} id="product">
                제품
              </a>
            </li>
            <li className={`${tabState.review ? "ON" : ""}`}>
              <a href="#" onClick={tabClick} id="review">
                평가
              </a>
            </li>
          </div>
          {tabState.detail ? <MakerDetail maker={maker}/> : ""}
          {tabState.company ? <MakerCompany maker={maker}/> : ""}
          {tabState.product ? <MakerProduct maker={maker} click={clickProductView}/> : ""}
          {tabState.productView ? <MakerProductView maker={maker} makerProductId={makerProductId} click={clickProduct}/> : ""}
          {tabState.review ? <MakerReview maker={maker}/> : ""}
        </div>
      </div>
    </div>
  );
};

export default MakerHome;
