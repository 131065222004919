import React, {useEffect, useState} from "react";
import last_page_black_48dp from "../../image/last_page_black_48dp.svg";
import navigate_next_black_48dp from "../../image/navigate_next_black_48dp.svg";
import first_page_black_48dp from "../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../image/chevron_left_black_24dp.svg";
import {connect, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import openSearch from "../../actions/openSearch";
import Constants from "../../constants/constants";
import APIHelper from "../../helpers/APIHelper";

const SearchBox = (props) => {
  // 셀렉된 것들
  const [selectProduct, setSelectProduct] = useState([]);
  const [arrProducts, setArrProducts] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '120000'});
      if (code === 200) {
        setArrProducts(result);
      }
    }
    fetch();
  }, []);

  const [result, setResult] = useState({category: "전체", type: "전체"});

  const selector = useSelector((state) => state.searchResult.result);

  useEffect(() => {
    if (selector !== "") {
      setResult(selector);
    }
  }, [selector]);

  const [items, setItems] = useState();
  const [searchKeyword, setSearchKeyword] = useState(props.searchKeyword);

  const load = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("data/product/gets", {type: "BX", keyword: searchKeyword, categoryCd: selectProduct?.cd || ''});
      if (code === 200) {
        setItems(result);
      }
      return resolve(true);
    });
  }

  useEffect(() => {
    const fetch = async () => {
      await load();
    }
    fetch();
  }, [selectProduct]);

  return (
    <>
      <div className="box_round min_h cnt_area pb-5">
        <section className="mx-5 mt-4">
          <p className="h3 bold border-bottom pb-3">단상자 검색</p>

          <div className="border p_rative my-4" style={{minHeight: "100%"}}>
            <div className="row row-cols-7 g-3 p-3 text-left">
              {arrProducts.map((item, i) => (
                <div className="col">
                  <button
                    type="button"
                    onClick={async () => {
                      if (selectProduct.cd === item.cd) {
                        setSelectProduct({});
                        return;
                      }
                      setSelectProduct(item);
                    }}
                    className={` btn btn-sm  ${
                      selectProduct && selectProduct.cd === item.cd
                        ? "category1_sm_on"
                        : "category1_sm"
                    }`}
                  >
                    {item?.image_uri && item?.image_uri.length > 0 ?
                      <img
                        src={process.env.PUBLIC_URL + item?.image_uri.substring(0, item?.image_uri.indexOf(".")) + ((selectProduct && selectProduct.cd === item.cd) ? "1" : "") + '.' + item?.image_uri.substring(item?.image_uri.indexOf(".") + 1)}
                        style={{height: `40px`, marginBottom: "5px", objectFit: "contain"}}
                      />
                      :
                      <div style={{width: "40px", height: "40pxs", borderRadius: "18px", backgroundColor: "#efefef", marginLeft: "18px"}}></div>
                    }
                    <br/>
                    <div style={{fontSize: "0.6rem", lineHeight: "130%"}}>{item.name_kr}</div>
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/*<div className="d-flex justify-content-between mt-4 px-5 py-3 box_round_5 bg-lpurple">*/}
          {/*  <ul className="d-flex mt-3 px-0">*/}
          {/*    {*/}
          {/*      <li className="box_round_30 bg-white border p-1 txt-point txt-sm mx-1">*/}
          {/*        <span className="p-2">*/}
          {/*          {result.category} : {result.type}*/}
          {/*        </span>*/}
          {/*        <button type="button" className="btn btn-sm p-0">*/}
          {/*          <img src={cancel_p} style={{paddingBottom: "2px"}}/>*/}
          {/*        </button>*/}
          {/*      </li>*/}
          {/*    }*/}
          {/*  </ul>*/}

          {/*  <div className="mt-3">*/}
          {/*    <button*/}
          {/*      type="button"*/}
          {/*      className="btn btn_puple px-4 py-1"*/}
          {/*      onClick={() =>*/}
          {/*        props.openSearch(*/}
          {/*          "open",*/}
          {/*          "단상자",*/}
          {/*          ["전체", "맞뚜껑형", "십자조리형"],*/}
          {/*          ["전체", "아이보리", "스노우지"]*/}
          {/*        )*/}
          {/*      }*/}
          {/*    >*/}
          {/*      고급검색*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </section>

        <section className="mx-5 mt-4 pb-5">
          <div className="d-flex justify-content-between">
            {
              <p className="h6 bold">
                {/*검색결과 : {result.category} | {result.type} ({items.length})*/}
                검색결과 : {items?.length || 0}건
              </p>
            }
            {/*<button*/}
            {/*  type="button"*/}
            {/*  className="btn btn-sm btn_outline_puple"*/}
            {/*  onClick={resetClick}*/}
            {/*>*/}
            {/*  검색초기화*/}
            {/*</button>*/}
          </div>

          <div className="row row-cols-4 g-3 pt-3">
            {items && items.map((makerProduct, i) =>
              <div className="col" style={{cursor: "pointer"}} onClick={() => props.click(makerProduct.id)}>
                <div className="box_border">
                  <p className="border-bottom" style={{height: "165px"}}>
                    {makerProduct?.dtls.filter(i => i.image_fid !== '').length > 0 &&
                      <img
                        src={`${Constants.API_HOST}/common/download?fid=${makerProduct?.dtls.filter(i => i.image_fid !== '')[0]?.image_fid}&cd=inline&thumb=400`}
                        style={{height: "165px", objectFit: "cover"}}
                      />
                    }
                  </p>
                  <p className="p-3" style={{height: "120px"}}>
                    <a href="">
                      <span className="txt-brown bold">{makerProduct?.category?.name_kr}</span>
                      <br/>
                      <span className="txt-gray" style={{
                        "overflow": "hidden",
                        "textOverflow": "ellipsis",
                        "display": "block",
                        "line-height": "normal",
                        "-webkit-line-clamp": "2",
                        "-webkit-box-orient": "vertical",
                        "-moz-line-clamp": "2",
                        "maxHeight": "60px",
                      }}>{makerProduct?.name}</span>
                    </a>
                  </p>
                </div>
              </div>
            )}
            {items && items.length === 0 &&
              <div className="col w-100">
                <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-lblue bold">
                  검색 결과가 없습니다.
                </div>
              </div>
            }
          </div>

          {/*<div className="d-flex justify-content-center py-4">*/}
          {/*  <ul className="d-flex my-3 p-0">*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" aria-label="Previous">*/}
          {/*        <img src={first_page_black_48dp} style={{width: "25px"}}/>*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" aria-label="Previous">*/}
          {/*        <img*/}
          {/*          src={chevron_left_black_24dp}*/}
          {/*          style={{width: "25px"}}*/}
          {/*        />*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" className="ON">*/}
          {/*        1*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#">2</a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#">3</a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" aria-label="Previous">*/}
          {/*        <img*/}
          {/*          src={navigate_next_black_48dp}*/}
          {/*          style={{width: "25px"}}*/}
          {/*        />*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" aria-label="Previous">*/}
          {/*        <img src={last_page_black_48dp} style={{width: "25px"}}/>*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*</div>*/}
        </section>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    searchPop: state.searchPop,
  };
}

function mapDispatchToProps(dispatcher) {
  return bindActionCreators(
    {
      openSearch: openSearch.search,
    },
    dispatcher
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
