import React, {useEffect, useRef, useState} from "react";
import BuyerProjectSide from "./BuyerProjectSide";
import arrowright from "../../../image/arrow-right.svg";
import icon_message from "../../../image/icon_message.svg";
import "../../../sass/main.scss";
import CommonLib from "../../../libraries/CommonLib";
import {Link} from "react-router-dom";
import APIHelper from "../../../helpers/APIHelper";
import pay_img01 from "../../../image/pay_img01.png";
import pay_img02 from "../../../image/pay_img02.png";
import pay_img03 from "../../../image/pay_img03.png";
import {useDispatch} from "react-redux";
import openRequestFormulation from "../../../actions/openRequestFormulation";
import openRequestDesign from "../../../actions/openRequestDesign";
import openRequestBottle from "../../../actions/openRequestBottle";
import openRequestBox from "../../../actions/openRequestBox";
import openTTTransfer from "../../../actions/openTTTransfer";
import $ from "jquery";
import openTimelineAttach from "../../../actions/openTimelineAttach";
import openEstimate from "../../../actions/openEstimate";
import CanvasDraw from "react-canvas-draw";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import Constants from "../../../constants/constants";
import {confirmAlert} from "react-confirm-alert";
import openReview from "../../../actions/openReview";

const BuyerProjectProgressHome = (props) => {
  const dv = props?.location?.state?.dv || props?.match?.params?.dv;
  const id = props?.location?.state?.id || props?.match?.params?.id;

  const [paymentMethod, setPaymentMethod] = useState(0);
  const [timelines, setTimelines] = useState([]);
  const [timeline, setTimeline] = useState({});
  const [lastest, setLastest] = useState({});
  const canvasRef = useRef();

  const fetch = async () => {
    await getTimelines();
  }

  useEffect(() => {
    (async () => {
      setPaymentMethod(0);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await fetch();
    })();
  }, [lastest?.id, lastest?.updated_at]);

  let invalTimeline;
  useEffect(() => {
    invalTimeline = setInterval(async function () {
      const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/lastest/light", {projectId: id, type: 'BUYER'});
      if (code === 200) setLastest(result);
    }, 2000);
    return () => clearInterval(invalTimeline);
  }, []);

  const getTimelines = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/quickmessage", {projectId: id, type: 'BUYER'});
      if (code === 200 && result?.id > 0) {
        const arr = [];
        arr.push(result);
        setTimelines(arr);
        setTimeline(result);
      } else {
        setTimelines([]);
        setTimeline({});
      }

      $('.btn-request-form').click(function () {
        openRequestForm($(this).attr('projectId'), $(this).attr('projectProductId'), $(this).attr('requestType'));
      });

      $('.btn-bank-info').click(function () {
        openTTTransferModal($(this).attr('projectId'), $(this).attr('projectProductId'), $(this).attr('projectMakerRequestId'), $(this).attr('timelineId'), $(this).attr('status'));
      });

      $('.btn-estimate-form').click(function () {
        if ($(this).attr('status').includes('단순') || $(this).attr('status') === '본 견적서') downloadTimelineAttachModal($(this).attr('projectId'), $(this).attr('projectProductId'), $(this).attr('projectMakerRequestId'), $(this).attr('status'), $(this).attr('timelineId'));
        else downloadEstimateModal($(this).attr('projectId'), $(this).attr('projectProductId'), $(this).attr('projectMakerRequestId'), $(this).attr('status'), $(this).attr('timelineId'));
      });

      return resolve(true);
    });
  };

  const dispatch = useDispatch();

  const downloadTimelineAttachModal = (projectId, projectProductId, projectMakerRequestId, formType) => {
    const obj = {
      projectId,
      projectProductId,
      projectMakerRequestId,
      title: formType.replace(' 작성', ''),
      renew: new Date().getTime(),
      readOnly: true,
      formType
    };
    dispatch(openTimelineAttach("open", obj));
  }

  const downloadEstimateModal = (projectId, projectProductId, projectMakerRequestId, formType, timelineId) => {
    const obj = {
      projectId,
      projectProductId,
      projectMakerRequestId,
      title: formType.replace(' 작성', ''),
      renew: new Date().getTime(),
      readOnly: true,
      formType,
      timelineId
    };
    dispatch(openEstimate("open", obj));
  }

  const openTTTransferModal = (projectId, projectProductId, projectMakerRequestId, timelineId, status) => {
    const obj = {
      projectId,
      projectProductId,
      projectMakerRequestId,
      timelineId,
      status,
      renew: new Date().getTime()
    };
    dispatch(openTTTransfer("open", obj));
  }

  const openRequestForm = (projectId, projectProductId, itemCd) => {
    const obj = {projectId, projectProductId, renew: new Date().getTime(), readOnly: true};
    if (itemCd === 'MF') dispatch(openRequestFormulation("open", obj));
    else if (itemCd === 'DS') dispatch(openRequestDesign("open", obj));
    else if (itemCd === 'BT') dispatch(openRequestBottle("open", obj));
    else if (itemCd === 'BX') dispatch(openRequestBox("open", obj));
  }

  return (
    <BuyerProjectSide dv={dv} id={id}>
      <div className="d-flex justify-content-between mt-5 mb-4 pb-1">
        <p className="txt-md">현재 진행 단계</p>
        <p></p>
      </div>

      <div className="Step_box">
        <div className={`Step_${timeline.step === 1 ? 'on' : 'off'}`}>
          <span className="txt-ssm bold">STEP01</span>
          <br/>
          <div style={{paddingTop: "5px"}}>단가조사</div>
        </div>
        <div className="py-3">
          <img src={arrowright} style={{width: "10px"}}/>
        </div>
        <div className={`Step_${timeline.step === 2 ? 'on' : 'off'}`}>
          <span className="txt-ssm bold">STEP02</span>
          <br/>
          <div style={{paddingTop: "5px"}}>샘플 디자인</div>
        </div>
        <div className="py-3">
          <img src={arrowright} style={{width: "10px"}}/>
        </div>
        <div className={`Step_${timeline.step === 3 ? 'on' : 'off'}`}>
          <span className="txt-ssm bold">STEP03</span>
          <br/>
          <div style={{paddingTop: "5px"}}>견적 및 계약</div>
        </div>
        <div className="py-3">
          <img src={arrowright} style={{width: "10px"}}/>
        </div>
        <div className={`Step_${timeline.step === 4 ? 'on' : 'off'}`}>
          <span className="txt-ssm bold">STEP04</span>
          <br/>
          <div style={{paddingTop: "5px"}}>제품 제작</div>
        </div>
        <div className="py-3">
          <img src={arrowright} style={{width: "10px"}}/>
        </div>
        <div className={`Step_${timeline.step === 5 ? 'on' : 'off'}`}>
          <span className="txt-ssm bold">STEP05</span>
          <br/>
          <div style={{paddingTop: "5px"}}>통관/선적</div>
        </div>
        <div className="py-3">
          <img src={arrowright} style={{width: "10px"}}/>
        </div>
        <div className={`Step_${timeline.step === 6 ? 'on' : 'off'}`}>
          <span className="txt-ssm bold">STEP06</span>
          <br/>
          <div style={{paddingTop: "5px"}}>완료</div>
        </div>
      </div>

      <br/>
      <br/>

      <div
        className="box_round_5 bg-lgray p_rative p-3 mt-5"
        style={{minHeight: "500px"}}
      >
        <div
          className="p_absolute d-flex"
          style={{top: "-5px", left: "20px"}}
        >
          <img src={icon_message} style={{width: "65px"}}/>
          <p className="mt-4 px-4 h4 bold txt-pink">Quick Message</p>
        </div>
        <br/>
        <br/>
        <br/>
        {timelines.map((item, key) => {
          item.canvasRef = canvasRef;
          const obj = CommonLib.getTimelineObj(item, timelines);

          if (obj.status.includes("결제 요청") && !obj.status.includes(" - T.T송금")) {
            return (
              <div
                className="box_round_5 bg-white border px-4 py-3 p_rative my-2"
                style={{textAlign: "left", minHeight: "100px"}}
              >
                <div className="p_absolute" style={{top: "15px", right: "15px"}}>
                  <p className="txt-sm txt-gray ">{obj.dt}</p>
                </div>

                <div className="m-3">
                  <p className="h6 bold">{obj.title}</p>
                  {obj?.html?.length > 0 && <div className="my-2" dangerouslySetInnerHTML={{__html: obj.html}}/>}
                  <div className="p-3">
                    <p className="h6">
                      <span className="h6 bold">
                        {CommonLib.escapedNewLineToLineBreakTag(obj.content)}
                        <br/>
                      </span>
                    </p>

                    <div
                      className="btn-group btn-group my-2"
                      role="group"
                      aria-label="..."
                    >
                      <button type="button" className="btn btn_outline_puple bold px-4" onClick={async () => {
                        setPaymentMethod(1);
                      }}>
                        <img src={pay_img01} style={{width: "60px"}}/>
                        <p className="mt-2">PAYPAL</p>
                      </button>
                      <button type="button" className="btn btn_outline_puple bold px-4" onClick={async () => {
                        if (!await CommonLib.jconfirm("T.T 송금을 진행하시겠습니까?")) return;
                        setPaymentMethod(2);

                        confirmAlert({
                          title: 'DARLIBABA',
                          message: '결제 통화를 선택하세요',
                          buttons: [
                            {
                              label: 'USD',
                              onClick: () => {
                                const obj = {
                                  projectId: item?.project_id,
                                  projectProductId: item?.project_product_id,
                                  projectMakerRequestId: item?.project_maker_request_id,
                                  timelineId: item?.id,
                                  title: item.status,
                                  renew: new Date().getTime(),
                                  readOnly: true,
                                  formType: item.status,
                                  step: item.step,
                                  refCtnt1: 'USD',
                                  refCtnt2: CommonLib.parseFloat(item.ref_ctnt_1).toFixed(4)
                                };
                                dispatch(openTTTransfer("open", obj));
                              },
                            },
                            {
                              label: 'KRW',
                              onClick: () => {
                                const obj = {
                                  projectId: item?.project_id,
                                  projectProductId: item?.project_product_id,
                                  projectMakerRequestId: item?.project_maker_request_id,
                                  timelineId: item?.id,
                                  title: item.status,
                                  renew: new Date().getTime(),
                                  readOnly: true,
                                  formType: item.status,
                                  step: item.step,
                                  refCtnt1: 'KRW',
                                  refCtnt2: CommonLib.parseFloat(item.ref_ctnt_1).toFixed(4)
                                };
                                dispatch(openTTTransfer("open", obj));
                              },
                            }
                          ]
                        });
                      }}>
                        <img src={pay_img03} style={{width: "60px"}}/>
                        <p className="mt-2">T.T 송금</p>
                      </button>
                    </div>

                    {paymentMethod === 1 &&
                      <div
                        className="btn-group btn-group my-2"
                        role="group"
                        aria-label="..."
                        style={{display: `${item.action_yn === 1 ? "none" : "block"}`}}
                      >
                        <PayPalScriptProvider options={{"client-id": Constants.PAYPAL_CLIENTID, components: 'buttons'}}>
                          <PayPalButtons
                            createOrder={(data, actions) => {
                              return actions.order.create({
                                purchase_units: [
                                  {
                                    amount: {
                                      value: CommonLib.parseFloat(item.ref_ctnt_1).toFixed(2)
                                    },
                                  },
                                ],
                              });
                            }}
                            onApprove={(data, actions) => {
                              return actions.order.capture().then((details) => {
                                // const name = details.payer.name.given_name;
                                // alert(`Transaction completed by ${name}`);

                                (async () => {
                                  await CommonLib.dispatchAction(item);

                                  if (obj.status.includes('샘플')) {
                                    const {data: {code, result, message}} = await APIHelper.postForm(`flow/sample/estimate/payment`, {
                                      projectId: item?.project_id,
                                      projectProductId: item?.project_product_id,
                                      projectMakerRequestId: item?.project_maker_request_id,
                                    });
                                    await CommonLib.jalert(message);
                                  } else if (obj.status.includes('잔금')) {
                                    const {data: {code, result, message}} = await APIHelper.postForm(`flow/buyer/balance/payment/submit`, {
                                      projectId: item?.project_id,
                                      projectProductId: item?.project_product_id,
                                      projectMakerRequestId: item?.project_maker_request_id,
                                    });
                                    await CommonLib.jalert(message);
                                  } else {
                                    const {data: {code, result, message}} = await APIHelper.postForm(`flow/main/estimate/payment`, {
                                      projectId: item?.project_id,
                                      projectProductId: item?.project_product_id,
                                      projectMakerRequestId: item?.project_maker_request_id,
                                    });
                                    await CommonLib.jalert(message);
                                  }
                                })();
                              });
                            }}
                          />
                        </PayPalScriptProvider>
                      </div>
                    }
                  </div>
                </div>
              </div>
            )
          } else {
            return (
              <div
                className="box_round_5 bg-white border px-4 py-3 p_rative my-2"
                style={{textAlign: "left", minHeight: "100px"}}
              >
                <div className="p_absolute" style={{top: "15px", right: "15px"}}>
                  <p className="txt-sm txt-gray ">{obj.dt}</p>
                </div>

                <div className="m-3">
                  <p className="h6 bold">{obj.title}</p>
                  {CommonLib.escapedNewLineToLineBreakTag(obj.content)}
                  {obj?.html?.length > 0 && <div className="my-2" dangerouslySetInnerHTML={{__html: obj.html}}/>}
                  {obj.status.includes("서명하기") &&
                    <div className="w-100" style={{width: "100%"}}>
                      <CanvasDraw
                        ref={item.canvasRef}
                        saveData={item.ref_ctnt_1 || null}
                        immediateLoading={true}
                        disabled={item?.ref_ctnt_1?.length > 0}
                        canvasWidth={600}
                        canvasHeight={200}
                        brushRadius={4}
                        lazyRadius={0}
                      />
                    </div>
                  }
                  {obj.buttons.length > 0 &&
                    <div className="my-3">
                      {obj.buttons.map((button, key) =>
                        button.link && button.link.length > 0 ?
                          <Link key={key} to={button.link} target={button.target}>
                            <button
                              type="button"
                              className={`btn btn-outline-danger txt-sm hov_pink px-5 ${button.action ? "btn-action" : ""}`}
                              style={{borderRadius: "0"}}
                              disabled={(!button.action && (button.prevent || button.disabled || CommonLib.parseInt(item.action_yn) === 1)) || false}
                            >
                              {button.name}
                            </button>
                          </Link>
                          :
                          button.click ?
                            <button
                              key={key}
                              type="button"
                              className={`btn btn-outline-danger txt-sm hov_pink px-5 ${button.action ? "btn-action" : ""}`}
                              onClick={button.click}
                              style={{borderRadius: "0"}}
                              disabled={(!button.action && (button.prevent || button.disabled || CommonLib.parseInt(item.action_yn) === 1)) || false}
                            >
                              {button.name}
                            </button>
                            : ""
                      )}
                    </div>
                  }
                </div>
              </div>
            )
          }
        })}
        {timelines && timelines.length === 0 &&
          <div className="col w-100">
            <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-white bold">
              현재 진행 메세지가 없습니다.
            </div>
          </div>
        }
      </div>
    </BuyerProjectSide>
  );
};

export default BuyerProjectProgressHome;
