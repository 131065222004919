import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import Constants from "../../constants/constants";
import {useHistory} from "react-router-dom";
import CommonLib from "../../libraries/CommonLib";

const IngredientDetail = (props) => {
  const id = props?.id || (props?.location?.state?.id || props?.match?.params?.id);
  const keyword = props?.keyword || (props?.location?.state?.keyword || props?.match?.params?.keyword);
  const page = props?.page || (props?.location?.state?.page || props?.match?.params?.page);
  const [result, setResult] = useState({});
  const history = useHistory();

  useEffect(() => {
    const fetch = async () => {
      await load();
    }
    fetch();
  }, []);

  const load = () => {
    return new Promise(async (resolve, reject) => {
      if (parseInt(id) > 0) {
        const {data: {code, result, message}} = await APIHelper.getForm(`data/ingredient/get`, {id});
        if (code === 200) {
          setResult(result);
        }
      }
      return resolve(true);
    });
  }

  return (
    <div className="box_round min_h cnt_area pb-5">
      <section className="mx-5 mt-4">
        <h5 className="mb-4" style={{fontWeight: "bold"}}>{CommonLib.escapedNewLineToLineBreakTag(result?.name)}</h5>
        <div>
          <table className="table basic w-100">
            <colgroup>
              <col width="18%"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th className="center">구명칭</th>
              <td>
                {CommonLib.escapedNewLineToLineBreakTag(result?.old_name)}
              </td>
            </tr>
            <tr>
              <th className="center">영문명</th>
              <td>
                {CommonLib.escapedNewLineToLineBreakTag(result?.name_en)}
              </td>
            </tr>
            <tr>
              <th className="center">CAS No.</th>
              <td>
                {CommonLib.escapedNewLineToLineBreakTag(result?.cas_no)}
              </td>
            </tr>
            <tr>
              <th className="center">EC No</th>
              <td>
                {CommonLib.escapedNewLineToLineBreakTag(result?.ec_no)}
              </td>
            </tr>
            <tr>
              <th className="center">기원 및 정의</th>
              <td>
                {CommonLib.escapedNewLineToLineBreakTag(result?.definition)}
              </td>
            </tr>
            <tr>
              <th className="center">분자구조식</th>
              <td>
                {CommonLib.escapedNewLineToLineBreakTag(result?.molecule)}
              </td>
            </tr>
            <tr>
              <th className="center">시성식</th>
              <td>
                {CommonLib.escapedNewLineToLineBreakTag(result?.rational)}
              </td>
            </tr>
            <tr>
              <th className="center">배합목적</th>
              <td>
                {CommonLib.escapedNewLineToLineBreakTag(result?.purpose)}
              </td>
            </tr>
            <tr>
              <th className="center">복합물 상품명</th>
              <td>
                {CommonLib.escapedNewLineToLineBreakTag(result?.compound_product)}
              </td>
            </tr>
            <tr>
              <th className="center">상품명</th>
              <td>
                {CommonLib.escapedNewLineToLineBreakTag(result?.product)}
              </td>
            </tr>
            <tr>
              <th className="center">별명(이명)</th>
              <td>
                {CommonLib.escapedNewLineToLineBreakTag(result?.alias)}
              </td>
            </tr>
            <tr>
              <th className="center">명칭변경이력</th>
              <td>
                {CommonLib.escapedNewLineToLineBreakTag(result?.name_history)}
              </td>
            </tr>
          </table>
        </div>

        <p className="text-center">
          <button type="button" className="btn btn_puplelight px-5 my-3" onClick={() => props.click(keyword, page)}>
            돌아가기
          </button>
        </p>
      </section>
    </div>
  );
}

export default IngredientDetail;
