import React, {useContext, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../sass/main.scss";
import {useDispatch, useSelector} from "react-redux";
import openModalStyle from "../../actions/openModalStyle";
import openRequest from "../../actions/openRequest";
import admin_logo from "../../image/admin_logo.png";
import AuthContext from "../auth-context";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretRight, faCaretLeft} from "@fortawesome/free-solid-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";
import first_page_black_48dp from "../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../image/chevron_left_black_24dp.svg";
import _ from "lodash";
import navigate_next_black_48dp from "../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../image/last_page_black_48dp.svg";

const Paging = (props) => {
  const pageInfo = props.pageInfo;
  return (
    pageInfo?.lastPage > 1 ?
      <div className="d-flex justify-content-center py-4 notranslate">
        <ul className="d-flex my-3" style={{margin: "0 auto"}}>
          <li className="PAGE">
            <a onClick={() => props.setCurrPage(1)} aria-label="첫페이지" style={{cursor: "pointer"}}>
              <img src={first_page_black_48dp} style={{width: "25px"}}/>
            </a>
          </li>
          <li className="PAGE">
            <a onClick={() => props.setCurrPage(pageInfo.prevBlock)} aria-label="이전블록" style={{cursor: "pointer"}}>
              <img src={chevron_left_black_24dp} style={{width: "25px"}}/>
            </a>
          </li>
          {_.range(pageInfo.startPage, pageInfo.endPage + 1).map(page =>
            <li className="PAGE">
              <a onClick={() => props.setCurrPage(page)} className={pageInfo.currentPage === page ? 'ON' : ''} style={{cursor: "pointer"}}>
                {page}
              </a>
            </li>
          )}
          <li className="PAGE">
            <a onClick={() => props.setCurrPage(pageInfo.nextBlock)} aria-label="다음블록" style={{cursor: "pointer"}}>
              <img src={navigate_next_black_48dp} style={{width: "25px"}}/>
            </a>
          </li>
          <li className="PAGE">
            <a onClick={() => props.setCurrPage(pageInfo.lastPage)} aria-label="마지막페이지" style={{cursor: "pointer"}}>
              <img src={last_page_black_48dp} style={{width: "25px"}}/>
            </a>
          </li>
        </ul>
      </div>
      :
      <></>
  );
};

export default Paging;
