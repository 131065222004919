import React from "react";
import icon_3d_rotate_w from "../../image/icon_3d_rotate_w.svg";
import product from "../../image/product.png";
import container_01 from "../../image/container/container_01.png";
import container_02 from "../../image/container/container_02.png";
import container_03 from "../../image/container/container_03.png";
import container_04 from "../../image/container/container_04.png";
import container_05 from "../../image/container/container_05.png";
import container_06 from "../../image/container/container_06.png";
import container_07 from "../../image/container/container_07.png";
import container_08 from "../../image/container/container_08.png";
import container_09 from "../../image/container/container_09.png";
import arrowleft from "../../image/arrow-left_w.svg";
import arrowright from "../../image/arrow-right.svg";
import prod_img01 from "../../image/prod_img01.png";
import prod_img02 from "../../image/prod_img02.png";
import prod_img03 from "../../image/prod_img03.png";

const SimulatorBottle = (props) => {
  return (
    <div className="MAX_wide my-4">
      <div className="mt-3 justify-content-center align-items-center w-100 d-flex flex-column">
        <section
          className="box_round min_h"
          style={{width: "calc(50% - 10px)"}}
        >
          <div
            className="p-5 p_rative"
            style={{
              background: "#000",
              minHeight: "450px",
              textAlign: "center",
            }}
          >
            <p className="p_absolute" style={{bottom: "30px", right: "30px"}}>
              <button type="button" className="btn p-0">
                <img src={icon_3d_rotate_w} style={{width: "40px"}}/>
              </button>
            </p>
            <img src={product} style={{width: "380px"}}/>
          </div>
          <article className="px-4 py-1 mt-4">
            <div className="border p_rative">
              <div className="p-3 py-1 txt-gray border-bottom bg-lpurple">
                용기타입
              </div>

              <div className="row row-cols-6 g-2 text-center px-3 my-3">
                <div className="col">
                  <button type="button" className="btn btn-sm category1_on">
                    <img
                      src={container_01}
                      style={{width: "15px", marginBottom: "5px"}}
                    />
                    <br/>
                    세구병
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1">
                    <img
                      src={container_02}
                      style={{width: "15px", marginBottom: "5px"}}
                    />
                    <br/>
                    세구병
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1">
                    <img
                      src={container_03}
                      style={{width: "15px", marginBottom: "5px"}}
                    />
                    <br/>
                    세구병
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1">
                    <img
                      src={container_04}
                      style={{width: "15px", marginBottom: "5px"}}
                    />
                    <br/>
                    세구병
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1">
                    <img
                      src={container_05}
                      style={{width: "15px", marginBottom: "5px"}}
                    />
                    <br/>
                    세구병
                  </button>
                </div>
              </div>
            </div>
          </article>

          <article className="px-4 py-1">
            <div className="border p_rative">
              <div className="p-3 py-1 txt-gray border-bottom bg-lpurple">
                두껑타입
              </div>

              <div className="row row-cols-6 g-2 text-center px-3 my-3">
                <div className="col">
                  <button type="button" className="btn btn-sm category1_on">
                    <img
                      src={container_06}
                      style={{width: "15px", marginBottom: "5px"}}
                    />
                    <br/>
                    원터치 캡
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1">
                    <img
                      src={container_07}
                      style={{width: "15px", marginBottom: "5px"}}
                    />
                    <br/>
                    일반캡
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1">
                    <img
                      src={container_08}
                      style={{width: "15px", marginBottom: "5px"}}
                    />
                    <br/>
                    뾰족캡
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1">
                    <img
                      src={container_09}
                      style={{width: "15px", marginBottom: "5px"}}
                    />
                    <br/>
                    프레스캡
                  </button>
                </div>
              </div>
            </div>
          </article>

          <article className="px-4 d-flex py-1">
            <div className="border p_rative">
              <div className="p-3 py-1 txt-gray border-bottom bg-lpurple">
                내장
              </div>

              <div className="row row-cols-3 g-2 text-center px-3 my-3">
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm_on">
                    일반
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm">
                    이중
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm">
                    진공
                  </button>
                </div>
              </div>
            </div>

            <div className="border p_rative mx-2">
              <div className="p-3 py-1 txt-gray border-bottom bg-lpurple">
                재질
              </div>

              <div className="row row-cols-4 g-2 text-center px-3 my-3">
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm_on">
                    PP
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm">
                    ABS
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm">
                    유리
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-sm category1_sm">
                    LDE
                  </button>
                </div>
              </div>
            </div>
          </article>

          <article className="px-4 py-1 mb-4">
            <table className="table basic_sm my-4">
              <colgroup>
                <col width="20%"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th className="center txt-sm">컬러 변경</th>
                <td>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input mt-1"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="option1"
                    />
                    <label className="form-check-label " for="inlineCheckbox1">
                      캡 컬러 변경
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input mt-1"
                      type="checkbox"
                      id="inlineCheckbox2"
                      value="option2"
                    />
                    <label className="form-check-label" for="inlineCheckbox2">
                      용기 컬러변경
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">표현 유무</th>
                <td>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input mt-1"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="option1"
                    />
                    <label className="form-check-label " for="inlineCheckbox1">
                      로고
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input mt-1"
                      type="checkbox"
                      id="inlineCheckbox2"
                      value="option2"
                    />
                    <label className="form-check-label" for="inlineCheckbox2">
                      문구
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input mt-1"
                      type="checkbox"
                      id="inlineCheckbox2"
                      value="option2"
                    />
                    <label className="form-check-label" for="inlineCheckbox2">
                      설명글
                    </label>
                  </div>
                </td>
              </tr>
            </table>
          </article>
        </section>

        <section
          className="box_round min_h px-4 mt-3"
          style={{width: "calc(50% - 10px)"}}
        >
          <article className="py-4">
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold">관련 용기</p>
              <div
                className="btn-group btn-group-sm"
                role="group"
                aria-label="Basic outlined example"
                style={{marginBottom: "-1px"}}
              >
                <button
                  type="button"
                  className="btn btn-outline-light p-1 px-3"
                >
                  <img src={arrowleft} style={{width: "10px"}}/>
                </button>
                <button
                  type="button"
                  className="btn btn-outline-light p-1 px-3"
                >
                  <img src={arrowright} style={{width: "10px"}}/>
                </button>
              </div>
            </div>

            <div className="row row-cols-4 g-2 pt-3">
              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{height: "150px", textAlign: "center"}}
                  >
                    <img
                      src={prod_img02}
                      style={{height: "145px", objectFit: "cover"}}
                    />
                  </p>
                  <p className="p-3" style={{height: "100px"}}>
                    <a href="">
                      <span className="txt-sm bold">세구병 – 원터치 캡</span>
                      <br/>
                      <span className="txt-sm txt-gray">
                        형태 : 진공
                        <br/>
                        재질 : 아크릴
                        <br/>
                      </span>
                    </a>
                  </p>
                </div>
              </div>

              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{height: "150px", textAlign: "center"}}
                  >
                    <img
                      src={prod_img02}
                      style={{height: "145px", objectFit: "cover"}}
                    />
                  </p>
                  <p className="p-3" style={{height: "100px"}}>
                    <a href="">
                      <span className="txt-sm bold">콤팩트</span>
                      <br/>
                      <span className="txt-sm txt-gray">
                        형태 : 진공
                        <br/>
                        재질 : 알루미늄
                        <br/>
                      </span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{height: "150px", textAlign: "center"}}
                  >
                    <img
                      src={prod_img02}
                      style={{height: "145px", objectFit: "cover"}}
                    />
                  </p>
                  <p className="p-3" style={{height: "100px"}}>
                    <a href="">
                      <span className="txt-sm bold">스포이드-스포이드 캡</span>
                      <br/>
                      <span className="txt-sm txt-gray">
                        형태 : 이중
                        <br/>
                        재질 : 아크릴
                        <br/>
                      </span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{height: "150px", textAlign: "center"}}
                  >
                    <img
                      src={prod_img02}
                      style={{height: "145px", objectFit: "cover"}}
                    />
                  </p>
                  <p className="p-3" style={{height: "100px"}}>
                    <a href="">
                      <span className="txt-sm bold">
                        튜브-일반캡
                        <br/>
                      </span>
                      <span className="txt-sm txt-gray">
                        형태 : 진공
                        <br/>
                        재질 : PP
                        <br/>
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </article>
          <article className="py-4">
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold">관련 제조사 제품</p>
              <div
                className="btn-group btn-group-sm"
                role="group"
                aria-label="Basic outlined example"
                style={{marginBottom: "-1px"}}
              >
                <button
                  type="button"
                  className="btn btn-outline-light p-1 px-3"
                >
                  <img src={arrowleft} style={{width: "10px"}}/>
                </button>
                <button
                  type="button"
                  className="btn btn-outline-light p-1 px-3"
                >
                  <img src={arrowright} style={{width: "10px"}}/>
                </button>
              </div>
            </div>

            <div className="d-flex mt-3">
              <a href="#" className="box_round_5 bg-purple mx-1">
                스킨
              </a>
              <a href="#" className="box_round_5 border mx-1">
                로션
              </a>
              <a href="#" className="box_round_5 border mx-1">
                토너
              </a>
            </div>

            <div className="row row-cols-3 g-3 pt-3">
              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{height: "150px", textAlign: "center"}}
                  >
                    <img
                      src={prod_img02}
                      style={{height: "145px", objectFit: "cover"}}
                    />
                  </p>
                  <p className="p-3" style={{height: "100px"}}>
                    <a href="">
                      <span className="txt-brown bold">제품분류-제품종류</span>
                      <br/>
                      <span className="txt-gray">
                        Abib Jericho Rose Essence
                        <br/>
                        Nutrition Pump 50mL
                      </span>
                    </a>
                  </p>
                </div>
              </div>

              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{height: "150px", textAlign: "center"}}
                  >
                    <img
                      src={prod_img03}
                      style={{height: "145px", objectFit: "cover"}}
                    />
                  </p>
                  <p className="p-3" style={{height: "100px"}}>
                    <a href="">
                      <span className="txt-brown bold">제품분류-제품종류</span>
                      <br/>
                      <span className="txt-gray">
                        Abib Jericho Rose Essence
                        <br/>
                        Nutrition Pump 50mL
                      </span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{height: "150px", textAlign: "center"}}
                  >
                    <img
                      src={prod_img01}
                      style={{height: "145px", objectFit: "cover"}}
                    />
                  </p>
                  <p className="p-3" style={{height: "100px"}}>
                    <a href="">
                      <span className="txt-brown bold">제품분류-제품종류</span>
                      <br/>
                      <span className="txt-gray">
                        Abib Jericho Rose Essence
                        <br/>
                        Nutrition Pump 50mL
                      </span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{height: "150px", textAlign: "center"}}
                  >
                    <img
                      src={prod_img02}
                      style={{height: "145px", objectFit: "cover"}}
                    />
                  </p>
                  <p className="p-3" style={{height: "100px"}}>
                    <a href="">
                      <span className="txt-brown bold">제품분류-제품종류</span>
                      <br/>
                      <span className="txt-gray">
                        Abib Jericho Rose Essence
                        <br/>
                        Nutrition Pump 50mL
                      </span>
                    </a>
                  </p>
                </div>
              </div>

              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{height: "150px", textAlign: "center"}}
                  >
                    <img
                      src={prod_img03}
                      style={{height: "145px", objectFit: "cover"}}
                    />
                  </p>
                  <p className="p-3" style={{height: "100px"}}>
                    <a href="">
                      <span className="txt-brown bold">제품분류-제품종류</span>
                      <br/>
                      <span className="txt-gray">
                        Abib Jericho Rose Essence
                        <br/>
                        Nutrition Pump 50mL
                      </span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="box_border">
                  <p
                    className="border-bottom"
                    style={{height: "150px", textAlign: "center"}}
                  >
                    <img
                      src={prod_img01}
                      style={{height: "145px", objectFit: "cover"}}
                    />
                  </p>
                  <p className="p-3" style={{height: "100px"}}>
                    <a href="">
                      <span className="txt-brown bold">제품분류-제품종류</span>
                      <br/>
                      <span className="txt-gray">
                        Abib Jericho Rose Essence
                        <br/>
                        Nutrition Pump 50mL
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
    </div>
  );
};
export default SimulatorBottle;
