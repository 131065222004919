import React, {useEffect, useState} from "react";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import MakerList from "../common/MakerList";
import APIHelper from "../../../helpers/APIHelper";
import Constants from "../../../constants/constants";
import Paging from "../../common/Paging";

const AdminMakerFavorite = (props) => {
  const [me, setMe] = useState({});
  const [favorites, setFavorites] = useState();
  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      await getFavorites(me);
    }
    fetch();
  }, [me?.id, currPage]);

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "제조사관리 > 제조사별 관심내역",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  const makerClick = async (e) => {
    setMe(e);
    setCurrPage(1);
  };

  // 관심목록(바이어) 가져오기
  const getFavorites = (item) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("user/buyer/gets", {
        targetUserId: item.user_id,
        searchFavorite: 1,
        paging: 1,
        currPage
      });
      if (pageInfo) setPageInfo(pageInfo);
      setFavorites(result);
      window.scrollTo(0, 0);
      return resolve(true);
    });
  };

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <section className="col-4" style={{height: "98%"}}>
          <MakerList addbtn={true} click={makerClick} favorite={true}/>
        </section>
        <section className="col-8" style={{height: "98%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "100%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold p-2">관심 바이어 (총 {pageInfo?.totalArticles || 0}건)</p>
              <p className="m-1 mb-2"></p>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll px-2 mt-3"
              style={{height: "calc(100% - 180px)"}}
            >
              {favorites && favorites?.map((buyer, i) => (
                <article
                  className={`m-2 border p-3`}
                >
                  <div className="d-flex mt-2 mb-2">
                    <p className="btn_oval_xl mb-1" style={{marginRight: "15px"}}>
                      {buyer.rep_image_fid && buyer.rep_image_fid.length > 0 ?
                        <img
                          src={`${Constants.API_HOST}/common/download?fid=${buyer.rep_image_fid}&cd=inline&thumb=400`}
                          style={{width: "65px", height: "65px", objectFit: "cover"}}
                        />
                        :
                        <div style={{width: "65px", height: "65px", backgroundColor: "#efefef", borderRadius: "65px"}}></div>
                      }
                    </p>
                    <div className="text-start">
                      <p className="h6">{buyer && (buyer?.company?.length > 0 ? buyer.company : (buyer?.first_name + " " + buyer?.middle_name + " " + buyer?.last_name))}</p>
                      <p>국적 : {buyer.country?.name_kr}</p>
                      <p>{buyer?.introduce.length > 0 ? buyer.introduce : "등록된 한줄 소개가 없습니다."}</p>
                    </div>
                  </div>
                </article>
              ))}
              {favorites?.length === 0 &&
                <div className="col w-100">
                  <div className="box_round_5 border d-flex justify-content-center bg-white" style={{height: "145px", lineHeight: "145px"}}>
                    해당 유저의 관심 바이어가 없습니다.
                  </div>
                </div>
              }
              <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminMakerFavorite;
