import React, {Component, useEffect} from 'react';
import {changeLanguage} from "i18next";
import $ from "jquery";

function GoogleTranslate() {
  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement({pageLanguage: 'en', includedLanguages: 'ko,en', autoDisplay: false, layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE}, 'google_translate_element')
  // }
  //
  // useEffect(() => {
  //   const addScript = document.createElement('script');
  //   addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  //   alert('1');
  // }, [])

  // 2023.08.08
  /*
    useEffect(() => {
      changeLanguage("en");

      const myframe = $("iframe[title='언어 번역 위젯']");
      myframe.addClass('noshow');
      try {
        myframe.contents().find('span.text:contains(' + "영어" + ')').get(0).click();
      } catch (err) {
        console.log(err);
      }

      // 2023.08.08 수정
      /!*
      const lang = '영어';
      const frame = $('.goog-te-menu-frame:first');

      frame.css({'border': 'none !important', 'background-color': 'transparent !important', 'position': 'absolute !important', 'top': '-9999px !important'})
      myframe.css({'border': 'none !important', 'background-color': 'transparent !important', 'position': 'absolute !important', 'top': '-9999px !important'})
      // console.log(window.getComputedStyle($('.skiptranslate iframe')[0], null).top);
      // let myTimeout = setInterval(() => {
      //   if (window.getComputedStyle($('.skiptranslate iframe')[0], null).top === "0px") {
      //     $('.skiptranslate iframe').addClass("aadd sj")
      //     clearInterval(myTimeout);
      //   }
      // })
      // if(!$('.skiptranslate iframe').hasClass('sj')) $('body').css('opacity', 50);
      // setTimeout(() => {
      //   $('.skiptranslate iframe').addClass("aadd sj");
      //   // $('body').css('opacity', 100);
      // }, 1000)
      setTimeout(() => {
        $('.skiptranslate iframe').addClass("aadd sj");
        $('body').css('opacity', 100);
      }, 1000)

      try {
        frame.contents().find('.goog-te-menu2-item span.text:contains(' + lang + ')').get(0).click();
      } catch (err) {
      }
      try {
        frame.contents().find('.goog-te-menu2-item span.text:contains(' + lang + ')').get(0).click();
      } catch (err) {
      }
      *!/
    }, []);
  */

  return (
    <div id="google_translate_element"></div>
  );
}

GoogleTranslate.googleTranslateToOrigin = () => {
  const iframe = document.getElementsByClassName('goog-te-banner-frame')[0];
  if (!iframe) return;

  const innerDoc = iframe.contentDocument || iframe.contentWindow.document;
  const restore_el = innerDoc.getElementsByTagName("button");

  for (let i = 0; i < restore_el.length; i++) {
    if (restore_el[i].id.indexOf("restore") >= 0) {
      restore_el[i].click();
      const close_el = innerDoc.getElementsByClassName("goog-close-link");
      close_el[0].click();
      return;
    }
  }
}

export default GoogleTranslate;
