import React from "react";
import {Link, useHistory} from "react-router-dom";

import "../../sass/main.scss";
import moment from "moment";
import icon_l_btn01_sel from "../../image/icon_l_btn01_sel.svg";
import CommonLib from "../../libraries/CommonLib";
import APIHelper from "../../helpers/APIHelper";

const MypageOemItem = (props) => {
  const history = useHistory();

  return (
    <div className="box_round border my-4 p_rative"
         style={{cursor: "pointer"}}
         onClick={async () => {
           await APIHelper.putForm("user/oem/proposal/read", {id: props.item.id});
           history.push({
             pathname: `/mypage/oem/${props.item.id}`,
             state: {item: props.item, mode: props.mode},
           });
         }}
    >
      <div className="bg-lpurple h6 bold p-3 border-bottom">
        {props.mode === 'BUYER' && CommonLib.parseInt(props.item.read_yn) === 0 &&
          <button type="button" className="btn btn-sm btn_puplelight" style={{width: "60px", cursor: "auto", pointerEvents: "none", marginRight: "15px"}}>
            NEW
          </button>
        }
        {props.item.product}
      </div>
      <p className="p-3 pb-4">
        <table className="table basic w-100">
          <colgroup>
            <col width="22%"/>
            <col width="*"/>
            <col width="22%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th className="center">MOQ</th>
            <td>{props.item.moq}</td>
            <th className="center">Unit Price</th>
            <td>{props.mode === 'BUYER' ? props.item.darlibaba_unit_price : props.item.unit_price}</td>
          </tr>
          <tr>
            <th className="center">제작 기간</th>
            <td colspan="3">{props.item.period}</td>
          </tr>
        </table>
      </p>
      <p className="p_absolute_tr2"/>
      <p className="p_absolute_br2 txt-sm txt-gray">{moment(props.item.created_at).format('YYYY.MM.DD')}</p>
    </div>
  );
};

export default MypageOemItem;
