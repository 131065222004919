import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import Constants from "../../constants/constants";
import openReviewManager from "../../actions/openReviewManager";

const ReviewManagerModal = (props) => {
  const closeModal = () => {
    props.openReviewManager("closed", "");
  };

  const {projectId, renew, readOnly, managerId, closeCallback} = props.reviewManagerPop?.content;

  let modalInlineStyle;

  if (props.reviewManagerPop.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }

  const [result, setResult] = useState();

  const [managerScore1, setManagerScore1] = useState(3);
  const [managerScore2, setManagerScore2] = useState(3);
  const [managerScore3, setManagerScore3] = useState(3);

  const [managerContent, setManagerContent] = useState('');

  useEffect(() => {
    (async () => {
      const {data: {code, result}} = await APIHelper.getForm("project/review/form", {projectId, managerId});
      if (code === 200) {
        setResult(result);
        setManagerScore1(result?.manager_review?.review_score_t1 || 3);
        setManagerScore2(result?.manager_review?.review_score_t2 || 3);
        setManagerScore3(result?.manager_review?.review_score_t3 || 3);
        setManagerContent(result?.manager_review?.content || '');
      }
    })();
  }, [renew]);

  const submit = async () => {
    const {data: {code, result, message}} = await APIHelper.putForm("project/review/manager", {
      dv: "MANAGER",
      projectId,
      managerId,
      reviewScoreT1: managerScore1,
      reviewScoreT2: managerScore2,
      reviewScoreT3: managerScore3,
      content: managerContent,
    });

    try {
      await APIHelper.postForm("admin/action/log", {
        content: "어드민 관리 > 매니저 후기 및 평점 관리 > 보기 > 수정",
        param1 : projectId,
        param2 : managerId,
        param3 : managerScore1,
        param4 : managerScore2,
        param5 : managerScore3,
        param6 : managerContent,
      });
    } catch (err) {
      console.log(err);
    }

    if (closeCallback) closeCallback();
    closeModal();
  }

  const Stars = (props) => {
    const [score, setScore] = useState(props.defaultScore);
    return (
      <>
        <img src={require(score >= 1 ? `../../image/star_r.svg` : `../../image/star_g.svg`).default} style={{width: props.large ? '21px' : '18px'}} onClick={() => {
          if (readOnly) return;
          setScore(1);
          props.callback(1);
        }}/>
        <img src={require(score >= 2 ? `../../image/star_r.svg` : `../../image/star_g.svg`).default} style={{width: props.large ? '21px' : '18px'}} onClick={() => {
          if (readOnly) return;
          setScore(2);
          props.callback(2);
        }}/>
        <img src={require(score >= 3 ? `../../image/star_r.svg` : `../../image/star_g.svg`).default} style={{width: props.large ? '21px' : '18px'}} onClick={() => {
          if (readOnly) return;
          setScore(3);
          props.callback(3);
        }}/>
        <img src={require(score >= 4 ? `../../image/star_r.svg` : `../../image/star_g.svg`).default} style={{width: props.large ? '21px' : '18px'}} onClick={() => {
          if (readOnly) return;
          setScore(4);
          props.callback(4);
        }}/>
        <img src={require(score >= 5 ? `../../image/star_r.svg` : `../../image/star_g.svg`).default} style={{width: props.large ? '21px' : '18px'}} onClick={() => {
          if (readOnly) return;
          setScore(5);
          props.callback(5);
        }}/>
      </>
    )
  }

  return (
    <div style={{display: modalInlineStyle, zIndex: 9999999, width: "100%", height: "100%"}}>
      <div className="pop_box02" style={{width: "850px", minHeight: "500px"}}>
        <div className="POP_title pb-3">
          <h4><strong>후기 관리</strong></h4>
          <p className="closed">
            <button type="button" className="btn" onClick={() => closeModal()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </button>
          </p>
        </div>

        <div className="POP_cnt_box_lg" style={{marginBottom: "90px"}}>
          <div className="d-flex justify-content-between border-top border-bottom pb-2 pt-3">
            <div>
              <h5 className="txt-point"><strong>{result?.project?.name}</strong></h5>
              <span className="txt-gray txt-sm">(프로젝트 No:{result?.project?.prj_no})</span>
            </div>
            <div className="d-flex">
              <p className="p-2">
                {result?.manager?.id > 0 && result?.manager.image_fid && result?.manager.image_fid.length > 0 ?
                  <img
                    src={`${Constants.API_HOST}/common/download?fid=${result?.manager.image_fid}&cd=inline&thumb=400`}
                    style={{width: "44px", height: "44px", objectFit: "cover", marginBottom: "5px", borderRadius: "22px"}}
                  />
                  :
                  <div style={{width: "44px", height: "44px", objectFit: "cover", backgroundColor: "#efefef", marginBottom: "5px", borderRadius: "22px"}}></div>
                }
              </p>
              <p className="pt-2"><span className="h6 bold">{result?.manager?.name}</span><br/>
                <span className="txt-sm">{result?.manager?.position} | {result?.manager?.task}</span></p>
            </div>
          </div>

          <div>
            <div className="d-flex py-1">
              <div className="p-1 d-flex"><p className="Oval_dot_pup m-1 ms-auto "></p><h6 className="bold px-2">매니저는 어떠셨나요?</h6></div>
            </div>
            <div style={{paddingLeft: "10px", paddingBottom: "10px"}}>
              <table>
                <tr>
                  <td style={{verticalAlign: "middle", paddingTop: "10px", paddingRight: "10px"}}><h6>신뢰도</h6></td>
                  <td><Stars defaultScore={managerScore1} callback={(score) => setManagerScore1(score)} large={true}/></td>
                </tr>
                <tr>
                  <td style={{verticalAlign: "middle", paddingTop: "10px", paddingRight: "10px"}}><h6>신속도</h6></td>
                  <td><Stars defaultScore={managerScore2} callback={(score) => setManagerScore2(score)} large={true}/></td>
                </tr>
                <tr>
                  <td style={{verticalAlign: "middle", paddingTop: "10px", paddingRight: "10px"}}><h6>친절도</h6></td>
                  <td><Stars defaultScore={managerScore3} callback={(score) => setManagerScore3(score)} large={true}/></td>
                </tr>
              </table>
            </div>
            <table className="table basic_sm w-100">
              <colgroup>
                <col width="20%"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th className="center">후기</th>
                <td>
                      <textarea className="form-control" id="exampleFormControlTextarea1"
                                onChange={async (e) => await setManagerContent(e.target.value)}
                                readOnly={!!readOnly}
                                rows={readOnly ? 10 : 3}
                                value={managerContent}
                      ></textarea>
                </td>
              </tr>
            </table>
          </div>
        </div>
        {!readOnly &&
          <div className="POP_foot">
            <button className="btn px-4 btn_puple" type="button" onClick={() => submit()}>수정</button>
          </div>
        }
      </div>
      <div className="pop_bak"></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    reviewManagerPop: state.reviewManagerPop,
  };
}

function mapDispatchToProps(dispatcher) {
  return bindActionCreators(
    {
      openReviewManager: openReviewManager,
    },
    dispatcher
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewManagerModal);
