import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import icon_q from "../../image/Icon_q.svg";
import favorite from "../../image/favorite.svg";
import favorite_border from "../../image/favorite_border.svg";
import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import Constants from "../../constants/constants";
import CommonLib from "../../libraries/CommonLib";
import Paging from "../common/Paging";
import {useTranslation} from "react-i18next";

const MakerFind = (props) => {
  const [makers, setMakers] = useState(false);
  const history = useHistory();
  const {t} = useTranslation();

  const [keyword, setKeyword] = useState('');
  const search = async () => {
    await setCurrPage(1);
    await getMakers();
  }
  const handleSearchKeyDown = async (e) => {
    if (e.key === 'Enter') {
      await search();
    }
  }

  const [searchMfItems, setSearchMfItems] = useState([]);
  const [searchMoqs, setSearchMoqs] = useState([]);
  const [searchCerts, setSearchCerts] = useState([]);
  const [searchFunctionals, setSearchFunctionals] = useState([]);

  const [searchMfItemData, setSearchMfItemData] = useState([]);
  const [searchMoqData, setSearchMoqData] = useState([]);
  const [searchCertData, setSearchCertData] = useState([]);
  const [searchFunctionalData, setSearchFunctionalData] = useState([]);
  const [searchFavorite, setSearchFavorite] = useState(false);

  const [moreSearchMfItem, setMoreSearchMfItem] = useState(true);
  const [moreSearchMoq, setMoreSearchMoq] = useState(false);
  const [moreSearchCert, setMoreSearchCert] = useState(false);
  const [moreSearchFunctional, setMoreSearchFunctional] = useState(false);

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      await getMakers();
    }
    fetch();
  }, [currPage, searchCertData, searchMoqData, searchFunctionalData, searchMfItemData, searchFavorite]);

  // 제조사 리스트 가져오기
  const getMakers = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("user/maker/gets", {
        searchCertData: searchCertData.join(","),
        searchMoqData: searchMoqData.join(","),
        searchFunctionalData: searchFunctionalData.join(","),
        searchMfItemData: searchMfItemData.join(","),
        searchFavorite: searchFavorite ? 1 : 0,
        keyword,
        paging: 1,
        currPage
      });
      if (pageInfo) setPageInfo(pageInfo);
      setMakers(result);
      window.scrollTo(0, 0);
      return resolve(true);
    });
  };

  useEffect(() => {
    const fetch = async () => {
      await getSearchCerts();
      await getSearchMoqs();
      await getSearchFunctionals();
      await getSearchMfItems();
    }
    fetch();
  }, []);

  const getSearchCerts = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '103000'});
      if (code === 200) {
        setSearchCerts(result);
      }
      return resolve(true);
    });
  }

  const getSearchMoqs = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '102000'});
      if (code === 200) {
        setSearchMoqs(result);
      }
      return resolve(true);
    });
  }

  const getSearchMfItems = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '110000'});
      if (code === 200) {
        setSearchMfItems(result);
      }
      return resolve(true);
    });
  }

  const getSearchFunctionals = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '107000'});
      if (code === 200) {
        setSearchFunctionals(result);
      }
      return resolve(true);
    });
  }

  const addSearchCertData = (checked, cd) => {
    const arr = [...searchCertData];
    if (checked && !arr.includes(cd))
      arr.push(cd);
    else if (!checked && arr.includes(cd))
      arr.splice(arr.indexOf(cd), 1);
    setSearchCertData(arr);
  }

  const addSearchMoqData = (checked, cd) => {
    const arr = [...searchMoqData];
    if (checked && !arr.includes(cd))
      arr.push(cd);
    else if (!checked && arr.includes(cd))
      arr.splice(arr.indexOf(cd), 1);
    setSearchMoqData(arr);
  }

  const addSearchMfItemData = (checked, cd) => {
    const arr = [...searchMfItemData];
    if (checked && !arr.includes(cd))
      arr.push(cd);
    else if (!checked && arr.includes(cd))
      arr.splice(arr.indexOf(cd), 1);
    setSearchMfItemData(arr);
  }

  const addSearchFunctionalData = (checked, cd) => {
    const arr = [...searchFunctionalData];
    if (checked && !arr.includes(cd))
      arr.push(cd);
    else if (!checked && arr.includes(cd))
      arr.splice(arr.indexOf(cd), 1);
    setSearchFunctionalData(arr);
  }

  return (
    <div className="BODY MAX my-4">
      <div
        className="box_round d-flex justify-content-between p-3"
        style={{height: "110px"}}
      >
        <div className="h5 px-5 py-3" style={{wordBreak: "break-all", maxWidth: "480px", verticalAlign: "middle"}}>
          <span className="h5">키워드 검색을 통해</span> <span><br/></span>
          <span className="h4 bold txt-point">원하시는 제조사</span>를 찾아보세요.
        </div>
        <div className="px-5 py-2">
          <div className="input-group input-group-lg">
            <input
              type="text"
              className="form-control search"
              placeholder="키워드를 입력하세요"
              style={{width: "410px"}}
              onChange={async (e) => await setKeyword(e.target.value)}
              value={keyword}
              onKeyDown={handleSearchKeyDown}
            />
            <button
              className="btn btn_puple px-5"
              type="button"
              id="button-addon2"
              onClick={() => search()}
            >
              검색
            </button>
          </div>
          {/*<ul className="d-flex py-3 px-0">*/}
          {/*  <li className="px-1">*/}
          {/*    <a href="#" className="Hash txt-point txt-sm">*/}
          {/*      Mask*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*  <li className="px-1">*/}
          {/*    <a href="#" className="Hash txt-point txt-sm">*/}
          {/*      Hair*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*  <li className="px-1">*/}
          {/*    <a href="#" className="Hash txt-point txt-sm">*/}
          {/*      Skincare*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*  <li className="px-1">*/}
          {/*    <a href="#" className="Hash txt-point txt-sm">*/}
          {/*      Face Oil*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*</ul>*/}
        </div>
      </div>

      <div
        className="mt-3 d-flex justify-content-between w-100"
        style={{minHeight: "calc(100% - 125px)"}}
      >
        <div className="box_round min_h left_menu">
          <div className="left_menu_title">제조사 찾기 필터</div>
          <div className="mx-2 pt-3 px-2 border-bottom">
            <p className="d-flex justify-content-between ">
              <span className="txt-point h6" style={{cursor: "pointer"}} onClick={() => setSearchMfItemData([])}>생산품목</span>
              <span className="txt-sm txt-gray">
                중복선택 가능 <img src={icon_q} style={{width: "16px"}}/>
              </span>
            </p>
            <ul className="p-0 py-2 d-flex flex-wrap m-0 txt-gray" style={{maxHeight: moreSearchMfItem ? "9999px" : "100px", overflow: "hidden"}}>
              {searchMfItems && searchMfItems.length && searchMfItems.map((item, k) => (
                <li className="w-50 mb-1">
                  <div className="form-check">
                    <input
                      className="form-check-input mt-1"
                      type="checkbox"
                      id={`defaultCheck10${k}`}
                      value={item.cd}
                      onChange={(e) => addSearchMfItemData(e.target.checked, item.cd)}
                      checked={searchMfItemData.includes(item.cd)}
                    />
                    <label className="form-check-label" htmlFor={`defaultCheck10${k}`} style={{fontSize: "0.8rem"}}>
                      {" "}
                      {item.name_kr}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
            <div className="w-100 mt-0 mb-1 bold" style={{textAlign: "right", cursor: "pointer"}} onClick={() => setMoreSearchMfItem(!moreSearchMfItem)}>
              {moreSearchMfItem ? "- hide" : "+ more"}
            </div>
          </div>

          <div className="mx-2 pt-3 px-2 border-bottom">
            <p className="d-flex justify-content-between ">
              <span className="txt-point h6" style={{cursor: "pointer"}} onClick={() => setSearchMoqData([])}>MOQ</span>
              <span className="txt-sm txt-gray">
                중복선택 가능 <img src={icon_q} style={{width: "16px"}}/>
              </span>
            </p>
            <ul className="p-0 py-2 d-flex flex-wrap m-0 txt-gray" style={{maxHeight: moreSearchMoq ? "9999px" : "100px", overflow: "hidden"}}>
              {searchMoqs && searchMoqs.length && searchMoqs.map((item, k) => (
                <li className="w-50 mb-1">
                  <div className="form-check">
                    <input
                      className="form-check-input mt-1"
                      type="checkbox"
                      id={`defaultCheck20${k}`}
                      value={item.cd}
                      onChange={(e) => addSearchMoqData(e.target.checked, item.cd)}
                      checked={searchMoqData.includes(item.cd)}
                    />
                    <label className="form-check-label" htmlFor={`defaultCheck20${k}`} style={{fontSize: "0.8rem"}}>
                      {" "}
                      {item.name_kr}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
            <div className="w-100 mt-0 mb-1 bold" style={{textAlign: "right", cursor: "pointer"}} onClick={() => setMoreSearchMoq(!moreSearchMoq)}>
              {moreSearchMoq ? "- hide" : "+ more"}
            </div>
          </div>

          <div className="mx-2 pt-3 px-2 border-bottom">
            <p className="d-flex justify-content-between ">
              <span className="txt-point h6" style={{cursor: "pointer"}} onClick={() => setSearchCertData([])}>보유인증서</span>
              <span className="txt-sm txt-gray">
                중복선택 가능 <img src={icon_q} style={{width: "16px"}}/>
              </span>
            </p>
            <ul className="p-0 py-2 d-flex flex-wrap m-0 txt-gray" style={{maxHeight: moreSearchCert ? "9999px" : "100px", overflow: "hidden"}}>
              {searchCerts && searchCerts.length && searchCerts.map((item, k) => (
                <li className="w-50 mb-1">
                  <div className="form-check">
                    <input
                      className="form-check-input mt-1"
                      type="checkbox"
                      id={`defaultCheck30${k}`}
                      value={item.cd}
                      onChange={(e) => addSearchCertData(e.target.checked, item.cd)}
                      checked={searchCertData.includes(item.cd)}
                    />
                    <label className="form-check-label" htmlFor={`defaultCheck30${k}`} style={{fontSize: "0.8rem"}}>
                      {" "}
                      {item.name_kr}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
            <div className="w-100 mt-0 mb-1 bold" style={{textAlign: "right", cursor: "pointer"}} onClick={() => setMoreSearchCert(!moreSearchCert)}>
              {moreSearchCert ? "- hide" : "+ more"}
            </div>
          </div>

          <div className="mx-2 pt-3 px-2 border-bottom">
            <p className="d-flex justify-content-between ">
              <span className="txt-point h6" style={{cursor: "pointer"}} onClick={() => setSearchFunctionalData([])}>기능성 생산유무</span>
              <span className="txt-sm txt-gray">
                중복선택 가능 <img src={icon_q} style={{width: "16px"}}/>
              </span>
            </p>
            <ul className="p-0 py-2 d-flex flex-wrap m-0 txt-gray" style={{maxHeight: moreSearchFunctional ? "9999px" : "100px", overflow: "hidden"}}>
              {searchFunctionals && searchFunctionals.length && searchFunctionals.map((item, k) => (
                <li className="w-50 mb-1">
                  <div className="form-check">
                    <input
                      className="form-check-input mt-1"
                      type="checkbox"
                      id={`defaultCheck40${k}`}
                      value={item.cd}
                      onChange={(e) => addSearchFunctionalData(e.target.checked, item.cd)}
                      checked={searchFunctionalData.includes(item.cd)}
                    />
                    <label className="form-check-label" htmlFor={`defaultCheck40${k}`} style={{fontSize: "0.8rem"}}>
                      {" "}
                      {item.name_kr}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
            <div className="w-100 mt-0 mb-1 bold" style={{textAlign: "right", cursor: "pointer"}} onClick={() => setMoreSearchFunctional(!moreSearchFunctional)}>
              {moreSearchFunctional ? "- hide" : "+ more"}
            </div>
          </div>
        </div>
        <div className="box_round min_h cnt_area">
          <div className="d-flex justify-content-between p-3 border-bottom">
            <div/>
            <div className="form-check mt-1 p-1" style={{backgroundColor: "#7c6daf", color: "#ffffff", borderRadius: 20}}>
              <input
                className="form-check-input mt-1"
                type="checkbox"
                id="defaultCheck1"
                onChange={async (e) => await setSearchFavorite(!searchFavorite)}
                style={{marginLeft: "-5px"}}
              />
              <label className="form-check-label notranslate" htmlFor="defaultCheck1" style={{marginLeft: "8px", marginTop: "1px", paddingRight: 20}}>
                {" "}
                {t('find.favorite.maker')}{" "}
              </label>
            </div>
          </div>

          {makers && makers.map((maker, key) => (
            <div
              className="border-bottom d-flex p-4 p_rative"
              style={{clear: "both", cursor: "pointer"}}
              onClick={async () => {
                if ((localStorage.getItem("user.session.user_type") || '') !== 'BUYER') {
                  await CommonLib.jalert('바이어 회원만 이용 가능합니다.');
                  return;
                }
                history.push({
                  pathname: `/maker/home`,
                  state: {user_id: maker.user_id}
                });
              }}>
              <p className="p_absolute_tr">
                <img
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    await APIHelper.postForm("user/favorite/toggle", {favorite_user_id: maker?.user_id});
                    await getMakers();
                  }}
                  src={`${parseInt(maker?.favorite, 10) === 1 ? favorite : favorite_border}`}
                  className="mx-1"
                  style={{width: "31px", cursor: "pointer"}}
                />
              </p>
              <p>
                {maker.rep_image_fid && maker.rep_image_fid.length > 0 ?
                  <img
                    src={`${Constants.API_HOST}/common/download?fid=${maker.rep_image_fid}&cd=inline&thumb=400`}
                    style={{width: "315px", height: "175px", objectFit: "cover"}}
                  />
                  :
                  <img
                    src={process.env.PUBLIC_URL + "/images/no_image.png"}
                    style={{width: "315px", height: "175px", objectFit: "cover"}}
                  />
                }
              </p>
              <div className="px-4">
                <h5 className="txt-point">
                  <strong>{maker.company_name}</strong>
                </h5>
                <p>
                  {maker?.introduce.length > 0 ? <span>{maker.introduce}</span> : <span>등록된 한줄 소개가 없습니다.</span>}
                  <br/>
                  {maker?.functionals && maker?.functionals.length > 0 ? maker?.functionals.map((item, key) => (
                    <span>
                      {key > 0 ? <span>,&nbsp;</span> : <span></span>}<span>{" "}{item.name_kr}</span>
                    </span>
                  )) : <span>등록된 기능성 제품 분류가 없습니다.</span>}
                </p>
                <ul className="d-flex txt-gray p-0 pt-4">
                  <li className="txt-sm">
                    <span>인증서{" "}:{" "}</span>
                    {maker?.certs && maker?.certs.length > 0 ? maker?.certs.map((item, key) => (
                      <span>
                        {key > 0 ? <span>{" "}/{" "}</span> : <span></span>}<span>{" "}{item.name_en}</span>
                      </span>
                    )) : <span>{" "}없음</span>}
                  </li>
                  <li className="txt-sm px-4"><span>MOQ{" "}:{" "}</span>{maker?.moq?.name_kr?.length > 0 ? <span>{maker?.moq?.name_kr}</span> : <span>정보없음</span>}</li>
                  <li className="txt-sm"><span>거래 횟수{" "}:{" "}0회</span></li>
                </ul>
                <div className="pt-2 mb-0 float-left">
                  {maker.mf_items && maker.mf_items.map((item, key) => (
                    <span className="Hash txt-point">{item.name_kr}</span>
                  ))}
                </div>
              </div>
            </div>
          ))}

          <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        </div>
      </div>
    </div>
  );
};

export default MakerFind;
