import React from "react";
import MypageCard from "./MypageCard";
import "../../sass/main.scss";

const MypageChangeEmail = (props) => {
  return (
    <MypageCard>
      <div className="box_round min_h cnt_area pb-5">
        <div className="mx-5 my-4">
          <p className="h3 bold border-bottom py-3">이메일 변경</p>
          <br />

          <p className="py-3 txt-gray txt-sm">
            <span className="h6 bold">
              현재 사용중인 이메일 주소는{" "}
              <span className="h5 bold txt-red">{localStorage.getItem("user.session.email") || ''}</span> 입니다.
            </span>{" "}
            <br />
            <br />
            변경하시려면 아래 새이메일 주소와 현재 비밀번호를 입력하여 주세요.
            새 메일주소로 인증번호가 발송됩니다.
            <br />
            새 메일로 보내진 인증번호를 입력하신 후 인증번호 확인을 눌러주시면
            새로운 이메일로 변경이 됩니다.
            <br />
            <br />
            ※ 이메일 주소를 변경 하게 되면 새로운 이메일로 로그인 하셔야 합니다.
            <br />
          </p>

          <div className="my-4">
            <table className="table basic my-3" style={{ textAlign: "center" }}>
              <colgroup>
                <col width="25%" />
                <col width="*" />
              </colgroup>

              <tr>
                <th>새 이메일 주소</th>
                <td>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="New e-mail address"
                    aria-label="default input example"
                  />
                </td>
              </tr>
              <tr>
                <th>현재 비밀번호</th>
                <td>
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Current Password"
                    aria-label="default input example"
                  />
                </td>
              </tr>
            </table>

            <div className="d-flex justify-content-center my-5">
              <button type="button" className="btn btn_puple px-3">
                인증 번호 발송
              </button>
            </div>
          </div>

          <div className="my-4">
            <table className="table basic my-3" style={{ textAlign: "center" }}>
              <colgroup>
                <col width="25%" />
                <col width="*" />
              </colgroup>

              <tr>
                <th>인증번호 입력</th>
                <td>
                  <input
                    className="form-control"
                    type="password"
                    placeholder="New e-mail address"
                    aria-label="default input example"
                  />
                </td>
              </tr>
            </table>

            <div className="d-flex justify-content-center my-5">
              <button type="button" className="btn btn_puple px-3">
                인증 번호 확인
              </button>
            </div>
          </div>
        </div>
      </div>
    </MypageCard>
  );
};

export default MypageChangeEmail;
