import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import openSimpleEstimate from "../../actions/openSimpleEstimate";

import "../../sass/main.scss";

const SimpleEstimateModal = (props) => {
  const closeModal = () => {
    props.openSimpleEstimate("closed", "");
  };

  let modalInlineStyle;

  if (props.simpleEstimatePop.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }
  return (
    <div style={{ display: modalInlineStyle }}>
      <div className="pop_box02" style={{ width: "750px", minHeight: "500px" }}>
        <div className="POP_title pb-3 border-bottom">
          <h4>
            <strong>간단 견적서 작성</strong>
          </h4>
          <p className="closed">
            <button onClick={closeModal} type="button" className="btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </p>
        </div>

        <div className="POP_cnt_box_lg" style={{ marginBottom: "90px" }}>
          <div>
            <div className="border-bottom pb-2 mt-4">
              <p className="bold">
                1. 단가 체크위한 견적서 입니다. 전달 내용은 자유롭게 입력하시면
                됩니다.{" "}
              </p>
            </div>

            <div className="d-flex flex-wrap bg-lpurple border-bottom p-2">
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div>
          </div>

          <div>
            <div className="border-bottom pb-2 mt-4">
              <p className="bold">2. 샘플 제작이 가능한가요 ? </p>
            </div>

            <div className="d-flex flex-wrap bg-lpurple border-bottom p-3">
              <a
                href="#"
                className="bg-purple box_round_4_paddingNo py-1 px-3 m-1 txt-sm"
              >
                예
              </a>
              <a
                href="#"
                className="bg-white border box_round_4_paddingNo py-1 px-3 m-1 txt-sm "
              >
                아니오
              </a>
            </div>
          </div>
          <div>
            <div className="border-bottom pb-2 mt-4">
              <p className="bold">3. 샘플 제작시 비용은 얼마인가요 ? </p>
            </div>

            <div className="d-flex flex-wrap bg-lpurple border-bottom p-3">
              <input
                type="text"
                className="form-control form-control-sm w-50"
                placeholder="원"
              />
            </div>
          </div>
          <div>
            <div className="border-bottom pb-2 mt-4">
              <p className="bold">
                4. 샘플 제작시 특이사항이 있으면 남겨주세요{" "}
              </p>
            </div>

            <div className="d-flex flex-wrap bg-lpurple border-bottom p-2">
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div>
          </div>

          <div>
            <div className="border-bottom pb-2 mt-4">
              <p className="bold">
                5. 견적에 도움이 될 만한 첨부 자료나 이미지가 있으면 등록하세요.
              </p>
            </div>

            <div className="d-flex flex-wrap bg-lpurple border-bottom p-2">
              <div className="input-group input-group-sm">
                <input
                  type="file"
                  className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                />
                <button
                  className="btn btn_outline_puple"
                  type="button"
                  id="inputGroupFileAddon04"
                >
                  파일첨부
                </button>
              </div>
              <div className="input-group input-group-sm my-1">
                <input
                  type="file"
                  className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                />
                <button
                  className="btn btn_outline_puple"
                  type="button"
                  id="inputGroupFileAddon04"
                >
                  파일첨부
                </button>
              </div>
              <div className="input-group input-group-sm">
                <input
                  type="file"
                  className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                />
                <button
                  className="btn btn_outline_puple"
                  type="button"
                  id="inputGroupFileAddon04"
                >
                  파일첨부
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="POP_foot">
          <button className="btn btn_puple" type="button">
            간단 견적서 작성 완료
          </button>
        </div>
      </div>
      <div className="pop_bak" onClick={closeModal}></div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    simpleEstimatePop: state.simpleEstimatePop,
  };
}

function mapDispatchToProps(dispatcher) {
  return bindActionCreators(
    {
      openSimpleEstimate: openSimpleEstimate,
    },
    dispatcher
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleEstimateModal);
