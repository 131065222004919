import React from "react";
import main_recome_01 from "../../image/main_recome_01.jpg";
import main_recome_02 from "../../image/main_recome_02.jpg";
import main_recome_03 from "../../image/main_recome_03.jpg";
import main_recome_04 from "../../image/main_recome_04.jpg";
import info_comp_02 from "../../image/info_comp_02.png";
import oem_img01 from "../../image/oem_img01.png";

const OemCard = (props) => {
  return (
    <div className="MAX_wide my-4">
      <div className="mt-3 justify-content-center align-items-center w-100 d-flex flex-column">
        <section
          className="box_round min_h"
          style={{ width: "calc(50% - 10px)" }}
        >
          <div className="p_rative">
            <p className="p-4">
              <img src={oem_img01} />
            </p>

            <div className="row row-cols-5 g-4 px-4">
              <p>
                <img src={main_recome_02} />
              </p>
              <p>
                <img src={main_recome_01} />
              </p>
              <p>
                <img src={main_recome_03} />
              </p>
              <p>
                <img src={main_recome_01} />
              </p>
              <p>
                <img src={main_recome_04} />
              </p>
            </div>
          </div>

          <article className="p-4">
            <div className="d-flex border-bottom py-2">
              <p>
                OEM 제작정보
                <br />
                <span className="h4 txt-point bold">Elysim Facial Cream</span>
              </p>

              <p className="mt-4 mx-3">
                <span
                  className="box_round_30 bg-lpurple p-1 px-3"
                  style={{ marginRight: "3px" }}
                >
                  스킨케어
                </span>
                <span className="box_round_30 bg-lpurple p-1 px-3">로션</span>
              </p>

              <p className="mt-4 ms-auto">
                <button type="button" className="btn btn-sm btn_outline_puple">
                  상세
                </button>
              </p>
            </div>

            <div className="row my-4">
              <div className="col-7">
                <table className="table basic_sm">
                  <colgroup>
                    <col width="30%" />
                    <col width="*" />
                  </colgroup>
                  <tr>
                    <th className="center">판매국가</th>
                    <td>미국</td>
                  </tr>
                  <tr>
                    <th className="center">타겟층</th>
                    <td> 여성</td>
                  </tr>
                  <tr>
                    <th className="center">MOQ</th>
                    <td>3,000</td>
                  </tr>
                  <tr>
                    <th className="center">Unit Price</th>
                    <td>$3.01</td>
                  </tr>
                  <tr>
                    <th className="center" style={{ height: "120px" }}>
                      제품내용
                    </th>
                    <td style={{ lineHeight: "130%" }}>
                      구체적인 내용이 들어갑니다.구체적인 내용이 들어갑니다.
                      구체적인 내용이 들어갑니다.
                    </td>
                  </tr>
                </table>

                <div></div>
              </div>
              <div className="col-5 p_rative">
                <div className="border-bottom">
                  <div className="thum_buyer">
                    <img src={info_comp_02} />
                  </div>
                  <div className="px-3 py-2">
                    <p className="h5 bold mt-1">㈜디엠코스xxxxx</p>
                    <p>
                      시킨/바디케어, 헤어케어 전문
                      <br />
                      미백, 주름, 자외선 차단
                      <br />
                      인증서 : ISO22760 / ISO9001
                    </p>
                    <div className="my-3">
                      <span
                        className="box_round_30 border bg-lgray p-1 px-3 txt-sm"
                        style={{ marginRight: "3px" }}
                      >
                        스킨케어
                      </span>
                      <span className="box_round_30 border bg-lgray p-1 px-3 txt-sm">
                        로션
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>

        {props.children}
      </div>
    </div>
  );
};

export default OemCard;
