import { combineReducers } from "redux";
import inquiryReducer from "./inquiryReducer";
import estimateReducer from "./estimateReducer";
import simpleEstimateReducer from "./simpleEstimateReducer";
import estimateFormReducer from "./estimateFormReducer";
import requestFormulationReducer from "./requestFormulationReducer";
import requestDesignReducer from "./requestDesignReducer";
import requestBottleReducer from "./requestBottleReducer";
import designResultReducer from "./designResultReducer";
import searchReducer from "./searchReducer";
import searchResultReducer from "./searchResultReducer";
import requestReducer from "./requestReducer";
import modalStyleReducer from "./modalStyleReducer";
import requestProjectReducer from "./requestProjectReducer";
import requestBoxReducer from "./requestBoxReducer";
import timelineTextReducer from "./timelineTextReducer";
import timelineAttachReducer from "./timelineAttachReducer";
import timelineRequestReducer from "./timelineRequestReducer";
import privacyReducer from "./privacyReducer";
import termsReducer from "./termsReducer";
import deliveryVendorReducer from "./deliveryVendorReducer";
import ttTransferReducer from "./ttTransferReducer";
import reviewReducer from "./reviewReducer";
import reviewManagerReducer from "./reviewManagerReducer";
import reviewMakerReducer from "./reviewMakerReducer";

const rootReducer = combineReducers({
  inquiryPop: inquiryReducer,
  deliveryVendorPop: deliveryVendorReducer,
  estimatePop: estimateReducer,
  simpleEstimatePop: simpleEstimateReducer,
  timelineTextPop: timelineTextReducer,
  timelineAttachPop: timelineAttachReducer,
  timelineRequestPop: timelineRequestReducer,
  ttTransferPop: ttTransferReducer,
  reviewPop: reviewReducer,
  reviewManagerPop: reviewManagerReducer,
  reviewMakerPop: reviewMakerReducer,
  estimateFormPop: estimateFormReducer,
  requestFormulationPop: requestFormulationReducer,
  requestDesignPop: requestDesignReducer,
  requestBottlePop: requestBottleReducer,
  requestBoxPop: requestBoxReducer,
  designResultPop: designResultReducer,
  privacyPop: privacyReducer,
  termsPop: termsReducer,
  searchPop: searchReducer,
  searchResult: searchResultReducer,
  request: requestReducer,
  modalStyle: modalStyleReducer,
  requestForm: requestProjectReducer,
});

export default rootReducer;
