import React, {useEffect, useState} from "react";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import MakerList from "../common/MakerList";
import APIHelper from "../../../helpers/APIHelper";
import Constants from "../../../constants/constants";
import Paging from "../../common/Paging";

const AdminSearchItem = (props) => {
  const _type = props?.type || (props?.location?.state?.type || props?.location?.state?.type || props?.match?.params?.type);

  const [type, setType] = useState({});
  const [me, setMe] = useState({});
  const [items, setItems] = useState();
  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(new Date().getTime());

  const [keyword, setKeyword] = useState('');
  const search = async () => {
    await setRenew(new Date().getTime());
    await setCurrPage(1);
    await getData();
    try {
      await APIHelper.postForm("admin/action/log", {
        content: type === "MF" ? "홈노출/검색관리 > 검색 - 제품 > 조회" : type === "BT" ? "홈노출/검색관리 > 검색 - 용기 > 조회" : type === "BX" ? "홈노출/검색관리 > 검색 - 단상자 > 조회": "홈노출/검색관리 > 검색 > 조회",
      });
    } catch (err) {
      console.log(err);
    }
  }
  const handleSearchKeyDown = async (e) => {
    if (e.key === 'Enter') {
      await search();
    }
  }

  useEffect(() => {
    setRenew(new Date().getTime());
    setKeyword('');
    setType(window.location.pathname.includes("mf") ? "MF" :
        window.location.pathname.includes("bx") ? "BX" :
        window.location.pathname.includes("bt") ? "BT" : "");
  }, [_type, type]);

  useEffect(() => {
    const fetch = async () => {
      await getData();
    }
    fetch();
  }, [me?.id, currPage, renew]);

  const makerClick = async (e) => {
    setKeyword('');
    setMe(e);
    setCurrPage(1);
  };

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: type === "MF" ? "홈노출/검색관리 > 검색 - 제품" : type === "BT" ? "홈노출/검색관리 > 검색 - 용기" : type === "BX" ? "홈노출/검색관리 > 검색 - 단상자": "홈노출/검색관리 > 검색",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  },[type]);

  // 아이템 리스트 가져오기
  const getData = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("data/product/gets", {
        type,
        keyword,
        makerUserId: !(keyword || me?.user_id) ? -99999999 : keyword ? 0 : me?.user_id,
        paging: 1,
        currPage,
        articlesPerPage: 8
      });
      if (pageInfo) setPageInfo(pageInfo);
      setItems(result);
      window.scrollTo(0, 0);
      return resolve(true);
    });
  };

  // 아이템 처리
  const setItem = (id, searchYn) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.putForm("data/product/set/search", {
        id,
        searchYn
      });
      return resolve(true);
    });
  };

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <section className="col-4" style={{height: "98%"}}>
          <MakerList addbtn={true} click={makerClick} favorite={true} renew={type}/>
        </section>
        <section className="col-8" style={{height: "98%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "100%"}}
          >
            <div className="p-0">
              <article className="d-flex w-100 border">
                <ul className="w-75 p_rative p-3 bg-lpurple border-right mb-0">
                  <p className="bold mb-1">
                    검색{" "}
                    <span className="txt-sm normal">
                    - 명칭 또는 키워드
                  </span>
                  </p>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="명칭 또는 키워드 검색"
                    onChange={async (e) => await setKeyword(e.target.value)}
                    value={keyword}
                    onKeyDown={handleSearchKeyDown}
                  />
                </ul>
                <dl className="w-25 p-2 py-3 mb-0">
                  <dd className="text-left">
                    <button
                      type="button"
                      className="btn btn-sm btn-purple m-1"
                      style={{width: "100px"}}
                      onClick={() => search()}
                    >
                      조회
                    </button>
                    <br/>
                    <button
                      type="button"
                      className="btn btn-sm btn-dark m-1"
                      style={{width: "100px"}}
                      onClick={async () => {
                        await setKeyword('');
                        setRenew(new Date().getTime());
                      }}
                    >
                      검색 초기화
                    </button>
                  </dd>
                </dl>
              </article>
            </div>

            <div className="d-flex justify-content-between border-bottom mt-3">
              <p className="h5 bold p-2">검색 - {type === "MF" ? "제품" : type === "BT" ? "용기" : type === "BX" ? "단상자" : ""} (총 {pageInfo?.totalArticles || 0}건)</p>
              <p className="m-1 mb-2"></p>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll px-2 mt-3"
              style={{height: "calc(100% - 180px)"}}
            >
              <div className="row row-cols-4 g-3 pt-3">
                {items && items.map((item, i) =>
                  <div className="col">
                    <div className="box_border">
                      <p className="border-bottom" style={{height: "40px", padding: "10px"}}>
                        <input
                          className="form-check-input mt-1"
                          type="checkbox"
                          id="inlineCheckbox1"
                          value="Y"
                          onChange={async (e) => {
                            await setItem(item.id, e.target.checked ? 'Y' : 'N');
                            await getData();
                          }}
                          checked={item.search_yn === 'Y'}
                        />
                        <label className="form-check-label " htmlFor="inlineCheckbox1">
                          &nbsp;&nbsp;홈 검색결과노출
                        </label>
                      </p>
                      <p className="border-bottom" style={{height: "165px"}}>
                        {item?.dtls.filter(i => i.image_fid !== '').length > 0 &&
                          <img
                            src={`${Constants.API_HOST}/common/download?fid=${item?.dtls.filter(i => i.image_fid !== '')[0]?.image_fid}&cd=inline&thumb=400`}
                            style={{height: "165px", objectFit: "contain"}}
                          />
                        }
                      </p>
                      <p className="p-3" style={{height: "120px"}}>
                        <a href="">
                          <span className="txt-brown bold">{item?.category?.name_kr}{item?.product?.name_kr?.length > 0 ? ('-' + item?.product?.name_kr) : ""}</span>
                          <br/>
                          <span className="txt-gray" style={{
                            "overflow": "hidden",
                            "textOverflow": "ellipsis",
                            "display": "block",
                            "line-height": "normal",
                            "-webkit-line-clamp": "2",
                            "-webkit-box-orient": "vertical",
                            "-moz-line-clamp": "2",
                            "maxHeight": "60px",
                          }}>{item?.name}</span>
                        </a>
                      </p>
                    </div>
                  </div>
                )}
                {!items || items.length === 0 &&
                  <div className="col w-100">
                    <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-lblue bold">
                      검색 결과가 없습니다.
                    </div>
                  </div>
                }
                <div className="col w-100">
                  <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminSearchItem;
