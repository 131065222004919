import React, {useState} from "react";
import SearchBottle from "./SearchBottle";
import SearchIngredient from "./SearchIngredient";
import SearchProduct from "./SearchProduct";
import ProductDetail from "./ProductDetail";
import SearchBox from "./SearchBox";
import {useHistory} from "react-router-dom";
import IngredientDetail from "./IngredientDetail";

const Search = (props) => {
  const dv = (props?.location?.state?.dv || props?.match?.params?.dv) || 'ingredient';
  const _detailId = (props?.location?.state?.detailId || props?.match?.params?.detailId) || '';
  const [searchKeyword, setSearchKeyword] = useState((props?.location?.state?.searchKeyword || props?.match?.params?.searchKeyword) || '');
  const [currPage, setCurrPage] = useState();
  const history = useHistory();
  let mode = '';

  const [tabState, setTabState] = useState({
    ingredient: dv === 'ingredient',
    product: dv === 'product',
    bottle: dv === 'bottle',
    box: dv === 'box',
    ingredientDetail: dv === 'ingredientDetail',
    productDetail: dv === 'productDetail',
    bottleDetail: dv === 'bottleDetail',
    boxDetail: dv === 'boxDetail',
  });
  const [detailId, setDetailId] = useState(_detailId);

  const clickHandler = (e) => {
    const tab = {...tabState};
    const target = e?.currentTarget?.id || e;
    for (let key in tab) {
      target === key ? (tab[key] = true) : (tab[key] = false);
    }
    if (mode !== 'back') {
      if (!target.includes("Detail")) setSearchKeyword('');
    } else {
      mode = '';
    }
    setTabState(tab);
  };

  return (
    <div className="BODY MAX my-4">
      <div
        className="mt-3 d-flex justify-content-between w-100"
        style={{height: "100%"}}
      >
        <div className="box_round min_h left_menu">
          <div className="left_menu_title">성분 제품 사전</div>
          <ul className="left_menu_list p-0">
            <li className={`${tabState.ingredient || tabState.ingredientDetail ? "on" : ""}`}>
              <a href="#" onClick={clickHandler} id="ingredient">
                성분 검색
              </a>
            </li>
            <li className={`${tabState.product || tabState.productDetail ? "on" : ""}`}>
              <a href="#" onClick={clickHandler} id="product">
                제품 검색
              </a>
            </li>
            <li className={`${tabState.bottle || tabState.bottleDetail ? "on" : ""}`}>
              <a href="#" onClick={clickHandler} id="bottle">
                용기 검색
              </a>
            </li>
            <li className={`${tabState.box || tabState.boxDetail ? "on" : ""}`}>
              <a href="#" onClick={clickHandler} id="box">
                단상자 검색
              </a>
            </li>
          </ul>
        </div>

        {tabState.ingredient ? <SearchIngredient searchKeyword={searchKeyword} page={currPage} setProps={(keyword) => setSearchKeyword(keyword)} click={(id, keyword, currPage) => {
          setDetailId(id);
          setCurrPage(currPage);
          clickHandler('ingredientDetail')
        }}/> : ""}
        {tabState.product ?
          <SearchProduct
            searchKeyword={searchKeyword}
            click={(id) => {
              setDetailId(id);
              clickHandler('productDetail')
            }}/> : ""}
        {tabState.bottle ?
          <SearchBottle
            searchKeyword={searchKeyword}
            click={(id) => {
              setDetailId(id);
              clickHandler('bottleDetail')
            }}/> : ""}
        {tabState.box ?
          <SearchBox
            searchKeyword={searchKeyword}
            click={(id) => {
              setDetailId(id);
              clickHandler('boxDetail')
            }}/> : ""}
        {tabState.ingredientDetail && <IngredientDetail id={detailId} keyword={searchKeyword} page={currPage} click={(keyword, page) => {
          setSearchKeyword(keyword);
          setCurrPage(page);
          mode = 'back';
          clickHandler('ingredient', keyword, page);
        }}/>}
        {tabState.productDetail && <ProductDetail makerProductId={detailId} click={() => {
          clickHandler('product');
        }}/>}
        {tabState.bottleDetail && <ProductDetail makerProductId={detailId} click={() => {
          clickHandler('bottle');
        }}/>}
        {tabState.boxDetail && <ProductDetail makerProductId={detailId} click={() => {
          clickHandler('box');
        }}/>}
      </div>
    </div>
  );
};

export default Search;
