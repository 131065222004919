import React, {useEffect} from "react";
import logo from "../image/logo.svg";
import img01 from "../image/login_img01.png";
import $ from 'jquery';

const Renewal = (props) => {
  useEffect(() => {
    $('body').height('100%');
  }, []);

  return (
    <div className="h-100" style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
      <div className="d-flex align-self-center" style={{zoom: "155%"}}>
        <div className="box_round p-5" style={{width: '700px'}}>
          <div className="mb-4 border-bottom pb-4" style={{textAlign: "center"}}><img src={logo} style={{width: '180px'}}/></div>
          <div className="d-flex justify-content-around">
            <div className="" style={{textAlign: "center"}}><img src={img01} style={{width: "200px"}} className="mt-2"/></div>
            <div className="m-3">
              <span className="h4 bold txt-gray" style={{lineHeight: '55px'}}>웹서비스 리뉴얼 중입니다.</span><br/>
              <span className="h6 bold txt-gray">보다 나은 서비스로 다시 찾아뵙겠습니다.<br/>감사합니다.</span><br/><br/>
              <span className="h7 mt-3 txt-gray">㈜빅파이씨앤티</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Renewal;
