import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import openEstimateForm from "../../actions/openEstimateForm";

import "../../sass/main.scss";

const EstimateFormModal = (props) => {
  const closeModal = () => {
    props.openEstimateForm("closed", "");
  };

  let modalInlineStyle;

  if (props.estimateFormPop.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }
  return (
    <div style={{ display: modalInlineStyle }}>
      <div className="pop_box02" style={{ width: "900px", minHeight: "500px" }}>
        <div className="POP_title pb-3 border-bottom">
          <h4>
            <strong>견적서 작성</strong>
          </h4>
          <p className="closed">
            <button onClick={closeModal} type="button" className="btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </p>
        </div>

        <div className="POP_cnt_box_lg" style={{ marginBottom: "90px" }}>
          <div>
            <div className="pb-2 mt-4">
              <p className="bold">1. 견적서 첨부 하세요</p>
            </div>

            <div className="d-flex flex-wrap bg-lpurple border-bottom p-3">
              <div className="input-group input-group-sm">
                <input
                  type="file"
                  className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                />
                <button
                  className="btn btn_outline_puple"
                  type="button"
                  id="inputGroupFileAddon04"
                >
                  파일첨부
                </button>
              </div>
              <div className="input-group input-group-sm my-1">
                <input
                  type="file"
                  className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                />
                <button
                  className="btn btn_outline_puple"
                  type="button"
                  id="inputGroupFileAddon04"
                >
                  파일첨부
                </button>
              </div>
              <div className="input-group input-group-sm">
                <input
                  type="file"
                  className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                />
                <button
                  className="btn btn_outline_puple"
                  type="button"
                  id="inputGroupFileAddon04"
                >
                  파일첨부
                </button>
              </div>
              <div className="input-group input-group-sm my-1">
                <input
                  type="file"
                  className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                />
                <button
                  className="btn btn_outline_puple"
                  type="button"
                  id="inputGroupFileAddon04"
                >
                  파일첨부
                </button>
              </div>
              <div className="input-group input-group-sm">
                <input
                  type="file"
                  className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                />
                <button
                  className="btn btn_outline_puple"
                  type="button"
                  id="inputGroupFileAddon04"
                >
                  파일첨부
                </button>
              </div>
            </div>

            <p className="txt-sm txt-gray">
              ※ 이미지(jpg, png, bmp), pdf, word, excel 만 업로드 가능합니다. |
              최대 20Mb{" "}
            </p>
          </div>

          <div>
            <div className="border-bottom pb-2 mt-4">
              <p className="bold">
                2. 전달사항이 있거나 간단한 내용으로 견적서를 대신 하려면
                입력하세요.{" "}
              </p>
            </div>

            <div className="d-flex flex-wrap bg-lpurple border-bottom p-3">
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="7"
              ></textarea>
            </div>
          </div>
        </div>
        <div className="POP_foot">
          <button className="btn btn_puple" type="button">
            견적서 작성 완료
          </button>
        </div>
      </div>
      <div className="pop_bak" onClick={closeModal}></div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    estimateFormPop: state.estimateFormPop,
  };
}

function mapDispatchToProps(dispatcher) {
  return bindActionCreators(
    {
      openEstimateForm: openEstimateForm,
    },
    dispatcher
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EstimateFormModal);
