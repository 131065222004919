import React, {useEffect, useState} from "react";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import ManagerList from "../common/ManagerList";
import APIHelper from "../../../helpers/APIHelper";
import moment from "moment";
import Paging from "../../common/Paging";
import openRequestFormulation from "../../../actions/openRequestFormulation";
import openRequestDesign from "../../../actions/openRequestDesign";
import openRequestBottle from "../../../actions/openRequestBottle";
import openRequestBox from "../../../actions/openRequestBox";
import {useDispatch} from "react-redux";
import openReview from "../../../actions/openReview";
import openReviewManager from "../../../actions/openReviewManager";

const AdminOperateManagerReview = (props) => {
  const [me, setMe] = useState({});
  const [results, setResults] = useState();
  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [currItem, setCurrItem] = useState();

  useEffect(() => {
    const fetch = async () => {
      await gets(me);
    }
    fetch();
  }, [me?.id, currPage]);

  const managerClick = async (e) => {
    setMe(e);
    setCurrPage(1);
  };

  // 리뷰내역(매니저) 가져오기
  const gets = (item) => {
    return new Promise(async (resolve, reject) => {
      setCurrItem(item);
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("review/manager/gets", {
        managerId: item.id,
        paging: 1,
        articlesPerPage: 15,
        currPage
      });
      if (pageInfo) setPageInfo(pageInfo);
      setResults(result);
      window.scrollTo(0, 0);
      return resolve(true);
    });
  };

  const dispatch = useDispatch();

  const openForm = async (projectId) => {
    const obj = {
      projectId, managerId: me?.id,
      renew: new Date().getTime(),
      closeCallback: async () => {
        await gets(currItem);
      },
      readOnly: true
    };
    dispatch(openReviewManager("open", obj));

    try {
      await APIHelper.postForm("admin/action/log", {
        content: "운영관리 > 매니저별 후기 및 평점 > 보기",
      });
    } catch (err) {
      console.log(err);
    }

  }

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "운영관리 > 매니저별 후기 및 평점",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <section className="col-4" style={{height: "100%"}}>
          <ManagerList click={managerClick} renew={new Date().getTime()} type={"ord"}/>
        </section>
        <section className="col-8" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">매니저별 후기 및 평점</p>
              <p className="m-1 mb-2"></p>
            </div>

            <p className="px-3 text-start bold my-3">총 {pageInfo?.totalArticles || 0}건</p>
            {results?.length > 0 &&
              <div
                className="wid_scroll scroll_y custom_scroll px-2"
                style={{height: "calc(100% - 330px)"}}
              >
                <article className="wid_inline">
                  <table className="table basic w-100">
                    <colgroup>
                      <col width="250px"/>
                      <col width="*"/>
                      <col width="80px"/>
                      <col width="80px"/>
                      <col width="80px"/>
                      <col width="150px"/>
                      <col width="100px"/>
                    </colgroup>
                    <tr>
                      <th className="center">작성자</th>
                      <th className="center">프로젝트</th>
                      <th className="center">신뢰도</th>
                      <th className="center">신속도</th>
                      <th className="center">친절도</th>
                      <th className="center">일시</th>
                      <th className="center">상세</th>
                    </tr>
                    {results && results?.map((item, i) => (
                      <tr>
                        <td className="center">{item?.buyer?.company ? item?.buyer?.company : item?.buyer?.charger_name}</td>
                        <td className="left">{item?.project?.name ? (item?.project?.name + " / 프로젝트 No. " + item?.project?.prj_no) : "프로젝트 No. " + item?.project?.prj_no}</td>
                        <td className="center">{item?.review_score_t1}점</td>
                        <td className="center">{item?.review_score_t2}점</td>
                        <td className="center">{item?.review_score_t3}점</td>
                        <td className="center">{moment(item.created_at).format("YYYY.MM.DD HH:mm")}</td>
                        <td className="center">
                          <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => openForm(item?.project?.id)}>보기</button>
                        </td>
                      </tr>
                    ))}
                  </table>
                </article>
              </div>
            }

            {(!results || results?.length === 0) &&
              <div className="col w-100">
                <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-lblue bold">
                  후기 내역이 없습니다.
                </div>
              </div>
            }
            <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminOperateManagerReview;
