import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import favorite from "../../image/favorite.svg";
import favorite_border from "../../image/favorite_border.svg";
import l_comp_img01 from "../../image/l_comp_img01.png";
import icon_l_btn01 from "../../image/icon_l_btn01.svg";
import icon_l_btn02 from "../../image/icon_l_btn02.svg";
import icon_l_btn01_sel from "../../image/icon_l_btn01_sel.svg";
import icon_l_btn02_sel from "../../image/icon_l_btn02_sel.svg";
import "../../sass/main.scss";
import BuyerPropose from "./BuyerPropose";
import BuyerDetail from "./BuyerDetail";
import APIHelper from "../../helpers/APIHelper";
import Constants from "../../constants/constants";
import CommonLib from "../../libraries/CommonLib";

const BuyerHome = (props) => {
  const user_id = props?.location?.state?.user_id || props?.match?.params?.id;
  const [buyer, setBuyer] = useState({});

  const fetch = async () => {
    const {data: {code, result, message}} = await APIHelper.getForm("user/buyer/get", {id: user_id});
    if (code === 200) setBuyer(result);
  }

  useEffect(() => {
    fetch();
  }, []);

  const [tabState, setTabState] = useState({
    detail: true,
    propose: false,
  });

  const clickTab = async (e) => {
    if (!localStorage.getItem("user.session.email")) {
      await CommonLib.jalert('로그인이 필요합니다.');
      return;
    }
    const tabs = {...tabState};
    const target = e.currentTarget.id;

    for (let key in tabs) {
      key === target ? (tabs[key] = true) : (tabs[key] = false);
    }
    setTabState(tabs);
  };
  const history = useHistory();

  // 찜버튼 test
  const [isFavorite, setIsFavorite] = useState(false);

  const favoriteHandler = () => {
    setIsFavorite(!isFavorite);
  };

  return (
    <div className="BODY MAX my-4">
      <div className="box_round d-flex min_h w-100" style={{height: "100%"}}>
        <div className="left_comp">
          <p>
            {buyer.rep_image_fid && buyer.rep_image_fid.length > 0 ?
              <img
                src={`${Constants.API_HOST}/common/download?fid=${buyer.rep_image_fid}&cd=inline&thumb=400`}
                style={{width: "259px", height: "215px", objectFit: "cover"}}
              />
              :
              <img
                src={process.env.PUBLIC_URL + "/images/no_image.png"}
                style={{width: "259px", height: "215px", objectFit: "cover"}}
              />
            }
          </p>
          <div className="py-3" style={{textAlign: "center"}}>
            <h5 className="txt-point">{buyer && (buyer?.company?.length > 0 ? buyer?.company : (buyer?.first_name + " " + buyer?.middle_name + " " + buyer?.last_name))}</h5>
            <p className="txt-gray py-2">
              {buyer.introduce}
            </p>
            <p className="txt-gray">{buyer && buyer.company_addr && buyer.company_addr.length > 0 ? buyer.company_addr.split(' ')[0] : ''}</p>
            <p className="box_border p-2 m-3">
              <img
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  await APIHelper.postForm("user/favorite/toggle", {favorite_user_id: buyer?.user_id});
                  await fetch();
                }}
                src={`${parseInt(buyer?.favorite, 10) === 1 ? favorite : favorite_border}`}
                className="mx-1"
                style={{width: "31px", cursor: "pointer"}}
              />
              <span className="txt-sm txt-gray">
                {parseInt(buyer?.favorite_cnt, 10)}명이 관심을 갖고 있습니다.
              </span>
            </p>
            <p className="px-3">
              <button
                id="propose"
                onClick={clickTab}
                type="button"
                className="btn btn_puple w-100 py-2 bold"
              >
                OEM 제작 제안
              </button>
            </p>
            <div className="p-3">
              <div
                className="btn-group w-100"
                role="group"
                aria-label="Basic outlined example"
              >
                {(buyer?.user?.grade_cd === '151003' || buyer?.user?.grade_cd === '151004') ?
                  <button type="button" className="btn btn-sm btn_puplelight" style={{cursor: "auto", pointerEvents: "none"}}>
                    <img src={icon_l_btn01_sel} style={{width: "18px"}}/> 매니저 인증
                  </button>
                  :
                  <button type="button" className="btn btn-sm btn-outline-light" style={{cursor: "auto", pointerEvents: "none"}}>
                    <img src={icon_l_btn01} style={{width: "18px"}}/> 매니저 인증
                  </button>
                }
                {buyer?.license_certified_yn === 'Y' ?
                  <button type="button" className="btn btn-sm btn_puplelight" style={{cursor: "auto", pointerEvents: "none"}}>
                    <img src={icon_l_btn02_sel} style={{width: "18px"}}/> 사업자 인증
                  </button>
                  :
                  <button type="button" className="btn btn-sm btn-outline-light" style={{cursor: "auto", pointerEvents: "none"}}>
                    <img src={icon_l_btn02} style={{width: "18px"}}/> 사업자 인증
                  </button>
                }
              </div>
            </div>

            <div className="p_absolute w-100 py-3" style={{bottom: "0"}}>
              <button
                onClick={() => history.goBack()}
                type="button"
                className="btn btn-outline-light w-85"
              >
                뒤로가기
              </button>
            </div>
          </div>
        </div>
        {tabState.detail ? <BuyerDetail buyer={buyer}/> : ""}
        {tabState.propose ? <BuyerPropose buyer_user_id={buyer?.user?.id}/> : ""}
      </div>
    </div>
  );
};

export default BuyerHome;
