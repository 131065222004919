import React, {useEffect, useRef, useState} from "react";

import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CommonLib from "../../../libraries/CommonLib";

const AdminMakerCompany = (props) => {
  const [maker, setMaker] = useState(props?.maker);

  const [intContent, setIntContent] = useState('');
  const [intImageFid, setIntImageFid] = useState('');
  const [intVideoUrl, setIntVideoUrl] = useState('');
  const [intVideoFid, setIntVideoFid] = useState('');
  const [bizContent, setBizContent] = useState('');
  const [bizImageFid, setBizImageFid] = useState('');
  const [bizVideoUrl, setBizVideoUrl] = useState('');
  const [bizVideoFid, setBizVideoFid] = useState('');
  const [mafContent, setMafContent] = useState('');
  const [mafImageFid, setMafImageFid] = useState('');
  const [mafVideoUrl, setMafVideoUrl] = useState('');
  const [mafVideoFid, setMafVideoFid] = useState('');
  const [qalContent, setQalContent] = useState('');
  const [qalImageFid, setQalImageFid] = useState('');
  const [qalVideoUrl, setQalVideoUrl] = useState('');
  const [qalVideoFid, setQalVideoFid] = useState('');
  const [etcTitle, setEtcTitle] = useState('기타');
  const [etcContent, setEtcContent] = useState('');
  const [etcImageFid, setEtcImageFid] = useState('');
  const [etcVideoUrl, setEtcVideoUrl] = useState('');
  const [etcVideoFid, setEtcVideoFid] = useState('');

  const [intImageFile, setIntImageFile] = useState('');
  const [intVideoFile, setIntVideoFile] = useState('');
  const [bizImageFile, setBizImageFile] = useState('');
  const [bizVideoFile, setBizVideoFile] = useState('');
  const [mafImageFile, setMafImageFile] = useState('');
  const [mafVideoFile, setMafVideoFile] = useState('');
  const [qalImageFile, setQalImageFile] = useState('');
  const [qalVideoFile, setQalVideoFile] = useState('');
  const [etcImageFile, setEtcImageFile] = useState('');
  const [etcVideoFile, setEtcVideoFile] = useState('');

  const intImageFileRef = useRef();
  const intVideoFileRef = useRef();
  const bizImageFileRef = useRef();
  const bizVideoFileRef = useRef();
  const mafImageFileRef = useRef();
  const mafVideoFileRef = useRef();
  const qalImageFileRef = useRef();
  const qalVideoFileRef = useRef();
  const etcImageFileRef = useRef();
  const etcVideoFileRef = useRef();

  const submit = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const {data: {code, result, message}} = await APIHelper.putForm(`user/maker/update/company`, {
          id: maker?.user_id,
          intContent,
          intImage: intImageFileRef?.current?.files ? intImageFileRef?.current?.files[0] : null,
          intVideoUrl,
          intVideo: intVideoFileRef?.current?.files ? intVideoFileRef?.current?.files[0] : null,
          bizContent,
          bizImage: bizImageFileRef?.current?.files ? bizImageFileRef?.current?.files[0] : null,
          bizVideoUrl,
          bizVideo: bizVideoFileRef?.current?.files ? bizVideoFileRef?.current?.files[0] : null,
          mafContent,
          mafImage: mafImageFileRef?.current?.files ? mafImageFileRef?.current?.files[0] : null,
          mafVideoUrl,
          mafVideo: mafVideoFileRef?.current?.files ? mafVideoFileRef?.current?.files[0] : null,
          qalContent,
          qalImage: qalImageFileRef?.current?.files ? qalImageFileRef?.current?.files[0] : null,
          qalVideoUrl,
          qalVideo: qalVideoFileRef?.current?.files ? qalVideoFileRef?.current?.files[0] : null,
          etcTitle,
          etcContent,
          etcImage: etcImageFileRef?.current?.files ? etcImageFileRef?.current?.files[0] : null,
          etcVideoUrl,
          etcVideo: etcVideoFileRef?.current?.files ? etcVideoFileRef?.current?.files[0] : null,
        });
        await CommonLib.jalert(message);
        if (code === 200) {
          await load();
          try {
            await APIHelper.postForm("admin/action/log", {
              content: "제조사관리 > 제조사 관리 > 회사상세 > 저장하기",
              param1 : maker?.user_id,
              param2 : intVideoUrl,
              param3 : bizVideoUrl,
              param4 : mafVideoUrl,
              param5 : qalVideoUrl,
              param6 : mafVideoUrl,
              param7 : etcTitle,
            });
          } catch (err) {
            console.log(err);
          }
        }
      } catch (err) {
      }
      return resolve(true);
    });
  }

  const load = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const {data: {code, result, message}} = await APIHelper.getForm(`user/maker/get`, {id: maker?.user_id});

        setMaker(result);

        setIntContent(result?.int_content || '');
        setIntImageFid(result?.int_image_fid || '');
        setIntVideoUrl(result?.int_video_url || '');
        setIntVideoFid(result?.int_video_fid || '');

        setBizContent(result?.biz_content || '');
        setBizImageFid(result?.biz_image_fid || '');
        setBizVideoUrl(result?.biz_video_url || '');
        setBizVideoFid(result?.biz_video_fid || '');

        setMafContent(result?.maf_content || '');
        setMafImageFid(result?.maf_image_fid || '');
        setMafVideoUrl(result?.maf_video_url || '');
        setMafVideoFid(result?.maf_video_fid || '');

        setQalContent(result?.qal_content || '');
        setQalImageFid(result?.qal_image_fid || '');
        setQalVideoUrl(result?.qal_video_url || '');
        setQalVideoFid(result?.qal_video_fid || '');

        setEtcTitle(result?.etc_title || '');
        setEtcContent(result?.etc_content || '');
        setEtcImageFid(result?.etc_image_fid || '');
        setEtcVideoUrl(result?.etc_video_url || '');
        setEtcVideoFid(result?.etc_video_fid || '');
      } catch (err) {
      }
      return resolve(true);
    });
  }

  const onDeleteAttach = async (dv, fid) => {
    const {data: {code, result, message}} = await APIHelper.deleteForm(`user/maker/delete/attach?dv=${dv}&fid=${fid}`);
    if (code === 200) await load();
  }

  useEffect(() => {
    const fetch = async () => {
      await load();
    }
    fetch();
  }, [props.renew]);

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "제조사관리 > 제조사 관리 > 회사상세",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleIntImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setIntImageFile(file);
    };
    reader.readAsDataURL(file);
  };
  const handleIntVideoChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setIntVideoFile(file);
    };
    reader.readAsDataURL(file);
  };

  const handleBizImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setBizImageFile(file);
    };
    reader.readAsDataURL(file);
  };
  const handleBizVideoChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setBizVideoFile(file);
    };
    reader.readAsDataURL(file);
  };

  const handleMafImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setMafImageFile(file);
    };
    reader.readAsDataURL(file);
  };
  const handleMafVideoChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setMafVideoFile(file);
    };
    reader.readAsDataURL(file);
  };

  const handleQalImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setQalImageFile(file);
    };
    reader.readAsDataURL(file);
  };
  const handleQalVideoChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setQalVideoFile(file);
    };
    reader.readAsDataURL(file);
  };

  const handleEtcImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setEtcImageFile(file);
    };
    reader.readAsDataURL(file);
  };
  const handleEtcVideoChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setEtcVideoFile(file);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="mt-3">
      <div className="row">
        <div className="d-flex justify-content-between border-bottom pb-2 mt-4">
          <p className="txt-md">회사 소개</p>
        </div>

        <div className="d-flex flex-wrap p-0 mt-2">
          <table className="table basic w-100">
            <colgroup>
              <col width="18%"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th className="center">내용</th>
              <td>
                <CKEditor editor={ClassicEditor} data={intContent}
                          onReady={(editor) => {
                            editor.editing.view.change((writer) => {
                              writer.setStyle(
                                "height",
                                "200px",
                                editor.editing.view.document.getRoot()
                              );
                            });
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setIntContent(data);
                          }}
                />
              </td>
            </tr>
            <tr>
              <th className="center">이미지 등록</th>
              <td>
                {intImageFid && intImageFid.length > 0 &&
                  <div
                    style={{background: "#f9edf8", color: "#ae6faf"}}
                    className="box_round_4_paddingNo px-2 p-1 m-2"
                  >
                    <span style={{paddingLeft: "0.5rem"}}>{maker?.int_image_attach?.filename}</span>{" "}
                    <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('int_image', intImageFid)}>삭제</button>
                  </div>
                }
                <div className="input-group input-group-sm">
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleIntImageChange}
                    ref={intImageFileRef}
                  />
                </div>
              </td>
            </tr>
            {/*<tr>*/}
            {/*  <th className="center">동영상 링크</th>*/}
            {/*  <td>*/}
            {/*    <div className="input-group input-group-sm">*/}
            {/*      <input*/}
            {/*        type="input"*/}
            {/*        className="form-control"*/}
            {/*        onChange={async (e) => await setIntVideoUrl(e.target.value)}*/}
            {/*        value={intVideoUrl}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <th className="center">동영상 등록</th>*/}
            {/*  <td>*/}
            {/*    {intVideoFid && intVideoFid.length > 0 &&*/}
            {/*      <div*/}
            {/*        style={{background: "#f9edf8", color: "#ae6faf"}}*/}
            {/*        className="box_round_4_paddingNo px-2 p-1 m-2"*/}
            {/*      >*/}
            {/*        <span style={{paddingLeft: "0.5rem"}}>{maker?.int_video_attach?.filename}</span>{" "}*/}
            {/*        <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('int_video', intVideoFid)}>삭제</button>*/}
            {/*      </div>*/}
            {/*    }*/}
            {/*    <div className="input-group input-group-sm">*/}
            {/*      <input*/}
            {/*        type="file"*/}
            {/*        className="form-control"*/}
            {/*        onChange={handleIntVideoChange}*/}
            {/*        ref={intVideoFileRef}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </td>*/}
            {/*</tr>*/}
          </table>
        </div>

        <br/>
        <br/>

        <div className="d-flex justify-content-between border-bottom pb-2 mt-4">
          <p className="txt-md">사업 분야</p>
        </div>

        <div className="d-flex flex-wrap p-0 mt-2">
          <table className="table basic w-100">
            <colgroup>
              <col width="18%"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th className="center">내용</th>
              <td>
                <CKEditor editor={ClassicEditor} data={bizContent}
                          onReady={(editor) => {
                            editor.editing.view.change((writer) => {
                              writer.setStyle(
                                "height",
                                "200px",
                                editor.editing.view.document.getRoot()
                              );
                            });
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setBizContent(data);
                          }}
                />
              </td>
            </tr>
            <tr>
              <th className="center">이미지 등록</th>
              <td>
                {bizImageFid && bizImageFid.length > 0 &&
                  <div
                    style={{background: "#f9edf8", color: "#ae6faf"}}
                    className="box_round_4_paddingNo px-2 p-1 m-2"
                  >
                    <span style={{paddingLeft: "0.5rem"}}>{maker?.biz_image_attach?.filename}</span>{" "}
                    <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('biz_image', bizImageFid)}>삭제</button>
                  </div>
                }
                <div className="input-group input-group-sm">
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleBizImageChange}
                    ref={bizImageFileRef}
                  />
                </div>
              </td>
            </tr>
            {/*<tr>*/}
            {/*  <th className="center">동영상 링크</th>*/}
            {/*  <td>*/}
            {/*    <div className="input-group input-group-sm">*/}
            {/*      <input*/}
            {/*        type="input"*/}
            {/*        className="form-control"*/}
            {/*        onChange={async (e) => await setBizVideoUrl(e.target.value)}*/}
            {/*        value={bizVideoUrl}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <th className="center">동영상 등록</th>*/}
            {/*  <td>*/}
            {/*    {bizVideoFid && bizVideoFid.length > 0 &&*/}
            {/*      <div*/}
            {/*        style={{background: "#f9edf8", color: "#ae6faf"}}*/}
            {/*        className="box_round_4_paddingNo px-2 p-1 m-2"*/}
            {/*      >*/}
            {/*        <span style={{paddingLeft: "0.5rem"}}>{maker?.biz_video_attach?.filename}</span>{" "}*/}
            {/*        <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('biz_video', bizVideoFid)}>삭제</button>*/}
            {/*      </div>*/}
            {/*    }*/}
            {/*    <div className="input-group input-group-sm">*/}
            {/*      <input*/}
            {/*        type="file"*/}
            {/*        className="form-control"*/}
            {/*        onChange={handleBizVideoChange}*/}
            {/*        ref={bizVideoFileRef}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </td>*/}
            {/*</tr>*/}
          </table>
        </div>

        <br/>
        <br/>

        <div className="d-flex justify-content-between border-bottom pb-2 mt-4">
          <p className="txt-md">연구 및 생산 품목</p>
        </div>

        <div className="d-flex flex-wrap p-0 mt-2">
          <table className="table basic w-100">
            <colgroup>
              <col width="18%"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th className="center">내용</th>
              <td>
                <CKEditor editor={ClassicEditor} data={mafContent}
                          onReady={(editor) => {
                            editor.editing.view.change((writer) => {
                              writer.setStyle(
                                "height",
                                "200px",
                                editor.editing.view.document.getRoot()
                              );
                            });
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setMafContent(data);
                          }}
                />
              </td>
            </tr>
            <tr>
              <th className="center">이미지 등록</th>
              <td>
                {mafImageFid && mafImageFid.length > 0 &&
                  <div
                    style={{background: "#f9edf8", color: "#ae6faf"}}
                    className="box_round_4_paddingNo px-2 p-1 m-2"
                  >
                    <span style={{paddingLeft: "0.5rem"}}>{maker?.maf_image_attach?.filename}</span>{" "}
                    <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('maf_image', mafImageFid)}>삭제</button>
                  </div>
                }
                <div className="input-group input-group-sm">
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleMafImageChange}
                    ref={mafImageFileRef}
                  />
                </div>
              </td>
            </tr>
            {/*<tr>*/}
            {/*  <th className="center">동영상 링크</th>*/}
            {/*  <td>*/}
            {/*    <div className="input-group input-group-sm">*/}
            {/*      <input*/}
            {/*        type="input"*/}
            {/*        className="form-control"*/}
            {/*        onChange={async (e) => await setMafVideoUrl(e.target.value)}*/}
            {/*        value={mafVideoUrl}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <th className="center">동영상 등록</th>*/}
            {/*  <td>*/}
            {/*    {mafVideoFid && mafVideoFid.length > 0 &&*/}
            {/*      <div*/}
            {/*        style={{background: "#f9edf8", color: "#ae6faf"}}*/}
            {/*        className="box_round_4_paddingNo px-2 p-1 m-2"*/}
            {/*      >*/}
            {/*        <span style={{paddingLeft: "0.5rem"}}>{maker?.maf_video_attach?.filename}</span>{" "}*/}
            {/*        <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('maf_video', mafVideoFid)}>삭제</button>*/}
            {/*      </div>*/}
            {/*    }*/}
            {/*    <div className="input-group input-group-sm">*/}
            {/*      <input*/}
            {/*        type="file"*/}
            {/*        className="form-control"*/}
            {/*        onChange={handleMafVideoChange}*/}
            {/*        ref={mafVideoFileRef}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </td>*/}
            {/*</tr>*/}
          </table>
        </div>

        <br/>
        <br/>

        <div className="d-flex justify-content-between border-bottom pb-2 mt-4">
          <p className="txt-md">인증서 및 특허</p>
        </div>

        <div className="d-flex flex-wrap p-0 mt-2">
          <table className="table basic w-100">
            <colgroup>
              <col width="18%"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th className="center">내용</th>
              <td>
                <CKEditor editor={ClassicEditor} data={qalContent}
                          onReady={(editor) => {
                            editor.editing.view.change((writer) => {
                              writer.setStyle(
                                "height",
                                "200px",
                                editor.editing.view.document.getRoot()
                              );
                            });
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setQalContent(data);
                          }}
                />
              </td>
            </tr>
            <tr>
              <th className="center">이미지 등록</th>
              <td>
                {qalImageFid && qalImageFid.length > 0 &&
                  <div
                    style={{background: "#f9edf8", color: "#ae6faf"}}
                    className="box_round_4_paddingNo px-2 p-1 m-2"
                  >
                    <span style={{paddingLeft: "0.5rem"}}>{maker?.qal_image_attach?.filename}</span>{" "}
                    <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('qal_image', qalImageFid)}>삭제</button>
                  </div>
                }
                <div className="input-group input-group-sm">
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleQalImageChange}
                    ref={qalImageFileRef}
                  />
                </div>
              </td>
            </tr>
            {/*<tr>*/}
            {/*  <th className="center">동영상 링크</th>*/}
            {/*  <td>*/}
            {/*    <div className="input-group input-group-sm">*/}
            {/*      <input*/}
            {/*        type="input"*/}
            {/*        className="form-control"*/}
            {/*        onChange={async (e) => await setQalVideoUrl(e.target.value)}*/}
            {/*        value={qalVideoUrl}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <th className="center">동영상 등록</th>*/}
            {/*  <td>*/}
            {/*    {qalVideoFid && qalVideoFid.length > 0 &&*/}
            {/*      <div*/}
            {/*        style={{background: "#f9edf8", color: "#ae6faf"}}*/}
            {/*        className="box_round_4_paddingNo px-2 p-1 m-2"*/}
            {/*      >*/}
            {/*        <span style={{paddingLeft: "0.5rem"}}>{maker?.qal_video_attach?.filename}</span>{" "}*/}
            {/*        <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('qal_video', qalVideoFid)}>삭제</button>*/}
            {/*      </div>*/}
            {/*    }*/}
            {/*    <div className="input-group input-group-sm">*/}
            {/*      <input*/}
            {/*        type="file"*/}
            {/*        className="form-control"*/}
            {/*        onChange={handleQalVideoChange}*/}
            {/*        ref={qalVideoFileRef}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </td>*/}
            {/*</tr>*/}
          </table>
        </div>

        <br/>
        <br/>

        <div className="d-flex justify-content-between border-bottom pb-2 mt-4">
          <p className="txt-md">기타</p>
          <p>
            <button
              type="button"
              className="btn btn-sm btn_outline_puple bold px-2"
            >
              제목 변경
            </button>
          </p>
        </div>

        <div className="d-flex flex-wrap p-0 mt-2">
          <table className="table basic w-100">
            <colgroup>
              <col width="18%"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th className="center">내용</th>
              <td>
                <CKEditor editor={ClassicEditor} data={etcContent}
                          onReady={(editor) => {
                            editor.editing.view.change((writer) => {
                              writer.setStyle(
                                "height",
                                "200px",
                                editor.editing.view.document.getRoot()
                              );
                            });
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setEtcContent(data);
                          }}
                />
              </td>
            </tr>
            <tr>
              <th className="center">이미지 등록</th>
              <td>
                {etcImageFid && etcImageFid.length > 0 &&
                  <div
                    style={{background: "#f9edf8", color: "#ae6faf"}}
                    className="box_round_4_paddingNo px-2 p-1 m-2"
                  >
                    <span style={{paddingLeft: "0.5rem"}}>{maker?.etc_image_attach?.filename}</span>{" "}
                    <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('etc_image', etcImageFid)}>삭제</button>
                  </div>
                }
                <div className="input-group input-group-sm">
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleEtcImageChange}
                    ref={etcImageFileRef}
                  />
                </div>
              </td>
            </tr>
            {/*<tr>*/}
            {/*  <th className="center">동영상 링크</th>*/}
            {/*  <td>*/}
            {/*    <div className="input-group input-group-sm">*/}
            {/*      <input*/}
            {/*        type="input"*/}
            {/*        className="form-control"*/}
            {/*        onChange={async (e) => await setEtcVideoUrl(e.target.value)}*/}
            {/*        value={etcVideoUrl}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <th className="center">동영상 등록</th>*/}
            {/*  <td>*/}
            {/*    {etcVideoFid && etcVideoFid.length > 0 &&*/}
            {/*      <div*/}
            {/*        style={{background: "#f9edf8", color: "#ae6faf"}}*/}
            {/*        className="box_round_4_paddingNo px-2 p-1 m-2"*/}
            {/*      >*/}
            {/*        <span style={{paddingLeft: "0.5rem"}}>{maker?.etc_video_attach?.filename}</span>{" "}*/}
            {/*        <button type="button" className="btn btn-sm p-0" style={{backgroundColor: "mediumpurple", color: "white", width: "50px", marginLeft: "10px"}} onClick={() => onDeleteAttach('etc_video', etcVideoFid)}>삭제</button>*/}
            {/*      </div>*/}
            {/*    }*/}
            {/*    <div className="input-group input-group-sm">*/}
            {/*      <input*/}
            {/*        type="file"*/}
            {/*        className="form-control"*/}
            {/*        onChange={handleEtcVideoChange}*/}
            {/*        ref={etcVideoFileRef}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </td>*/}
            {/*</tr>*/}
          </table>
        </div>

        <br/>

        <p className="text-center">
          <button type="button" className="btn btn_puple px-5 my-3" onClick={submit}>
            저장하기
          </button>
        </p>
      </div>
    </div>
  );
};

export default AdminMakerCompany;
