import React from "react";
import {Link} from "react-router-dom";
import CsCenterCard from "./CsCenterCard";
import first_page_black_48dp from "../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../image/chevron_left_black_24dp.svg";
import navigate_next_black_48dp from "../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../image/last_page_black_48dp.svg";

import "../../sass/main.scss";
import ServicePolicy from "./ServicePolicy";

const Privacy = (props) => {

  return (
    <ServicePolicy>
      <div className="box_round min_h cnt_area">
        <div className="mx-5 my-4">
          <p className="h3 bold border-bottom py-3">이용약관</p>
          <br/>
          <div className="basic my-3 px-3" dangerouslySetInnerHTML={{
            __html: `
<p style="text-align: left;"><span style="font-size: 13px; line-height: 19.9733px; font-family: 함초롬바탕, serif;">달리바바 개인정보처리방침</span></p>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">&lt;(주)빅파이씨앤티&gt;(&lsquo;www.darlibaba.com&rsquo;이하&nbsp;&lsquo;달리바바&rsquo;)는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.</span></p>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">&lt;(주)빅파이씨앤티&gt;(&lsquo;달리바바&rsquo;)는 회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.</span></p>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">○&nbsp;본 방침은부터&nbsp;2022년&nbsp;4월&nbsp;14일부터 시행됩니다.</span></p>
<h5 style='margin-top: 0cm; margin-right: 0cm; margin-bottom: 3.75pt; text-align: left; text-indent: -19.65pt; line-height: 14.2667px; font-size: 13px; font-family: "맑은 고딕"; font-weight: normal; color: rgb(0, 0, 0); font-style: normal; background: white;'><span style="font-size: 13px;"><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">1.&nbsp;</span></strong><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">개인정보의 처리 목적</span></strong></span></h5>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">&lt;(주)빅파이씨앤티&gt;(&lsquo;www.darlibaba.com&rsquo;이하&nbsp;&lsquo;달리바바&rsquo;)는 개인정보를 다음의 목적을 위해 처리합니다.&nbsp;처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.</span></p>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">가.&nbsp;홈페이지 회원가입 및 관리</span></p>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">회원제 서비스 제공에 따른 본인 식별&middot;인증,&nbsp;회원자격 유지&middot;관리,&nbsp;서비스 부정이용 방지,&nbsp;만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인,&nbsp;분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.</span></p>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">나.&nbsp;민원사무 처리</span></p>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">민원인의 신원 확인,&nbsp;민원사항 확인,&nbsp;사실조사를 위한 연락&middot;통지,&nbsp;처리결과 통보 등을 목적으로 개인정보를 처리합니다.</span></p>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">다.&nbsp;재화 또는 서비스 제공</span></p>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">콘텐츠 제공,&nbsp;맞춤 서비스 제공,&nbsp;본인인증,&nbsp;요금결제&middot;정산 등을 목적으로 개인정보를 처리합니다.</span></p>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">라.&nbsp;마케팅 및 광고에의 활용</span></p>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">신규 서비스(제품)&nbsp;개발 및 맞춤 서비스 제공,&nbsp;이벤트 및 광고성 정보 제공 및 참여기회 제공,&nbsp;인구통계학적 특성에 따른 서비스 제공 및 광고 게재,&nbsp;서비스의 유효성 확인 등을 목적으로 개인정보를 처리합니다.</span></p>
<h5 style='margin-top: 0cm; margin-right: 0cm; margin-bottom: 3.75pt; text-align: left; text-indent: -19.65pt; line-height: 14.2667px; font-size: 13px; font-family: "맑은 고딕"; font-weight: normal; color: rgb(0, 0, 0); font-style: normal; background: white;'><span style="font-size: 13px;"><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: black;">2.&nbsp;</span></strong><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: black;">개인정보 파일 현황</span></strong></span></h5>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; font-size: 13px;">1.&nbsp;개인정보</span><span style="line-height: 14.2667px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">&nbsp;파일명&nbsp;:&nbsp;달리바바_개인정보파일</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-&nbsp;개인정보&nbsp;항목&nbsp;:&nbsp;이메일,&nbsp;전화번호,&nbsp;비밀번호,&nbsp;이름,&nbsp;회사명 등 이외에 서비스 상품별 추가적인 정보 요구</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-&nbsp;수집방법&nbsp;:&nbsp;홈페이지,&nbsp;서면양식,&nbsp;전화/팩스,&nbsp;생성정보 수집 툴을 통한 수집</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-&nbsp;보유근거&nbsp;:&nbsp;관련법령</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-&nbsp;보유기간&nbsp;:&nbsp;3년</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-&nbsp;관련법령&nbsp;:&nbsp;소비자의 불만 또는 분쟁처리에 관한 기록&nbsp;: 3년,&nbsp;대금결제 및 재화 등의 공급에 관한 기록&nbsp;: 5년,&nbsp;계약 또는 청약철회 등에 관한 기록&nbsp;: 5년</span></p>
<h5 style='margin-top: 0cm; margin-right: 0cm; margin-bottom: 3.75pt; text-align: left; text-indent: -19.65pt; line-height: 14.2667px; font-size: 13px; font-family: "맑은 고딕"; font-weight: normal; color: rgb(0, 0, 0); font-style: normal; background: white;'><span style="font-size: 13px;"><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">3.&nbsp;</span></strong><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">개인정보의 처리 및 보유 기간</span></strong></span></h5>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; font-size: 13px;">①&nbsp; &nbsp;&nbsp;&lt;㈜빅파이씨앤티&gt;(&lsquo;www.darlibaba.com&rsquo;이하&nbsp;&lsquo;달리바바&rsquo;)는 법령에 따른 개인정보 보유 이용 기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,&nbsp;이용기간 내에서 개인 정보를 처리,&nbsp;보유합니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">②&nbsp; &nbsp;&nbsp;&nbsp;각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">1.&nbsp; &nbsp;&nbsp;&lt;홈페이지 회원가입 및 관리&gt;</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">&lt;홈페이지 회원가입 및 관리&gt;와 관련한 개인정보는&nbsp;수집.이용에&nbsp;관한 동의일로부터&lt;3년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-보유근거&nbsp;:&nbsp;관련법령</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-관련법령&nbsp;:&nbsp;1)소비자의 불만 또는 분쟁처리에 관한 기록&nbsp;: 3년</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">2)&nbsp;대금결제 및 재화 등의 공급에 관한&nbsp;기록&nbsp;:&nbsp;5년</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">3)&nbsp;계약 또는 청약철회 등에 관한&nbsp;기록&nbsp;:&nbsp;5년</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">4)&nbsp;표시/광고에 관한&nbsp;기록&nbsp;:&nbsp;6개월</span></p>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">-예외사유&nbsp;:&nbsp;고객센터로 문의주시면 안내해드립니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">2.&nbsp; &nbsp;&nbsp;&lt;민원사무 처리&gt;</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">&lt;민원사무 처리&gt;와 관련한 개인정보는&nbsp;수집.이용에&nbsp;관한 동의일로부터&lt;3년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-보유근거&nbsp;:&nbsp;관련법령</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-관련법령&nbsp;:&nbsp;소비자의 불만 또는 분쟁처리에 관한 기록&nbsp;: 3년</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-예외사유&nbsp;:&nbsp;고객센터로 문의주시면 안내해드립니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">3.&nbsp; &nbsp;&nbsp;&lt;제화 또는 서비스 제공&gt;</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">&lt;제화 또는 서비스 제공&gt;와 관련한 개인정보는 수집.&nbsp;이용에 관한 동의일로부터&lt;3년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-보유근거&nbsp;:&nbsp;관련법령</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-관련법령&nbsp;:&nbsp;1)소비자의 불만 또는 분쟁처리에 관한 기록&nbsp;: 3년</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">2)&nbsp;대금결제 및 재화 등의 공급에 관한&nbsp;기록&nbsp;:&nbsp;5년</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">3)&nbsp;계약 또는 청약철회 등에 관한&nbsp;기록&nbsp;:&nbsp;5년</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">4)&nbsp;표시/광고에 관한&nbsp;기록&nbsp;:&nbsp;6개월</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">1)소비자의 불만 또는 분쟁처리에 관한&nbsp;기록&nbsp;:&nbsp;3년</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">2)&nbsp;표시/광고에 관한&nbsp;기록&nbsp;:&nbsp;6개월</span></p>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">-예외사유&nbsp;:&nbsp;고객센터로 문의주시면 안내해드립니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">4.&nbsp; &nbsp;&nbsp;&lt;마케팅 및 광고에의 활용&gt;</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">&lt;마케팅 및 광고에의 활용&gt;와 관련한 개인정보는&nbsp;수집.이용에&nbsp;관한 동의일로부터&lt;3년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-보유근거&nbsp;:&nbsp;관련법령</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-관련법령&nbsp;:&nbsp;1)소비자의 불만 또는 분쟁처리에 관한 기록&nbsp;: 3년</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">2)&nbsp;대금결제 및 재화 등의 공급에 관한&nbsp;기록&nbsp;:&nbsp;5년</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">3)&nbsp;계약 또는 청약철회 등에 관한&nbsp;기록&nbsp;:&nbsp;5년</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">4)&nbsp;표시/광고에 관한&nbsp;기록&nbsp;:&nbsp;6개월</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">1)소비자의 불만 또는 분쟁처리에 관한&nbsp;기록&nbsp;:&nbsp;3년</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">2)&nbsp;표시/광고에 관한&nbsp;기록&nbsp;:&nbsp;6개월</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">1)신용정보의 수집/처리 및 이용 등에 관한&nbsp;기록&nbsp;:&nbsp;3년</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">2)&nbsp;소비자의 불만 또는 분쟁처리에 관한&nbsp;기록&nbsp;:&nbsp;3년</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">3)&nbsp;계약 또는 청약철회 등에 관한&nbsp;기록&nbsp;:&nbsp;5년</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">4)&nbsp;표시/광고에 관한&nbsp;기록&nbsp;:&nbsp;6개월</span></p>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">-예외사유&nbsp;:&nbsp;고객센터로 문의주시면 안내해드립니다.</span></p>
<h5 style='margin-top: 0cm; margin-right: 0cm; margin-bottom: 3.75pt; text-align: left; text-indent: -19.65pt; line-height: 14.2667px; font-size: 13px; font-family: "맑은 고딕"; font-weight: normal; color: rgb(0, 0, 0); font-style: normal; background: white;'><span style="font-size: 13px;"><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">4.&nbsp;</span></strong><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">개인정보의 제3자 제공에 관한 사항</span></strong></span></h5>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">①&nbsp;&lt;(주)빅파이씨앤티&gt;(&lsquo;www.darlibaba.com&rsquo;이하&nbsp;&lsquo;달리바바&rsquo;)는 정보주체의 동의,&nbsp;법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">②&nbsp;&lt;(주)빅파이씨앤티&gt;(&lsquo;www.darlibaba.com&rsquo;이하&nbsp;&lsquo;달리바바&rsquo;)는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">1.&nbsp; &nbsp; &nbsp;&lt;개인정보처리 담당자(진영웅)&gt;</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-&nbsp;개인정보를 제공받는&nbsp;자&nbsp;:&nbsp;개인정보처리 담당자(진영웅)</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-&nbsp;제공받는 자의 개인정보&nbsp;이용목적&nbsp;:&nbsp;개인정보 항목&nbsp;:&nbsp;이메일,&nbsp;전화번호,&nbsp;비밀번호,&nbsp;이름,&nbsp;회사명 등 이외에 서비스 상품별 추가적인 정보 요구</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-&nbsp;제공받는 자의&nbsp;보유.이용기간: 3년</span></p>
<h5 style='margin-top: 0cm; margin-right: 0cm; margin-bottom: 3.75pt; text-align: left; text-indent: -19.65pt; line-height: 14.2667px; font-size: 13px; font-family: "맑은 고딕"; font-weight: normal; color: rgb(0, 0, 0); font-style: normal; background: white;'><span style="font-size: 13px;"><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">5.&nbsp;</span></strong><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">정보주체와 법정대리인의 권리&middot;의무 및 그 행사방법</span></strong></span></h5>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">① 정보주체는&nbsp;(주)빅파이씨앤티에&nbsp;대해 언제든지 개인정보 열람,&nbsp;정정,&nbsp;삭제,&nbsp;처리정지 요구 등의 권리를 행사할 수 있습니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">② 제1항에 따른 권리 행사는&nbsp;(주)빅파이씨앤티에&nbsp;대해 개인정보 보호법 시행령 제41조제1항에&nbsp;따라 서면,&nbsp;전자우편,&nbsp;모사전송(FAX)&nbsp;등을 통하여 하실 수 있으며&nbsp;(주)빅파이씨앤티는&nbsp;이에 대해 지체 없이 조치하겠습니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.&nbsp;이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항,&nbsp;제37조 제2항에 의하여 정보주체의 권리가&nbsp;제한 될&nbsp;수 있습니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">⑥&nbsp;(주)빅파이씨앤티는&nbsp;정보주체 권리에 따른 열람의 요구,&nbsp;정정&middot;삭제의 요구,&nbsp;처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</span></p>
<h5 style='margin-top: 0cm; margin-right: 0cm; margin-bottom: 3.75pt; text-align: left; text-indent: -19.65pt; line-height: 14.2667px; font-size: 13px; font-family: "맑은 고딕"; font-weight: normal; color: rgb(0, 0, 0); font-style: normal; background: white;'><span style="font-size: 13px;"><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">6.&nbsp;</span></strong><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">처리하는 개인정보의 항목 작성</span></strong></span></h5>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">①&nbsp;&lt;(주)빅파이씨앤티&gt;(&lsquo;www.darlibaba.com&rsquo;이하&nbsp;&lsquo;달리바바&rsquo;)는 다음의 개인정보 항목을 처리하고 있습니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">1.&nbsp; &nbsp;&nbsp;&lt;홈페이지 회원가입 및 관리&gt;</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">&ndash;&nbsp;필수항목(제조사) :&nbsp;이메일,&nbsp;비밀번호,&nbsp;비밀번호 확인,&nbsp;이름,&nbsp;제조사 타입,&nbsp;회사명 혹은 브랜드 명</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">필수항목(바이어) :&nbsp;이메일,&nbsp;비밀번호,&nbsp;비밀번호 확인,&nbsp;이름</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">&ndash;&nbsp;선택항목(제조사) :&nbsp;제품,&nbsp;회사소개,&nbsp;회사명 등</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">&nbsp;선택항목(바이어) :&nbsp;회사명,&nbsp;한 줄 소개,&nbsp;국가선택,&nbsp;언어선택 등</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">2.&nbsp; &nbsp;&nbsp;&lt;민원사무 처리&gt;</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">&ndash;&nbsp;필수항목(제조사) :&nbsp;이메일,&nbsp;비밀번호,&nbsp;비밀번호 확인,&nbsp;이름,&nbsp;제조사 타입,&nbsp;회사명 혹은 브랜드 명</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">필수항목(바이어) :&nbsp;이메일,&nbsp;비밀번호,&nbsp;비밀번호 확인,&nbsp;이름</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">&ndash;&nbsp;선택항목(제조사) :&nbsp;제품,&nbsp;회사소개,&nbsp;회사명 등</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">&nbsp;선택항목(바이어) :&nbsp;회사명,&nbsp;한 줄 소개,&nbsp;국가선택,&nbsp;언어선택 등</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">3.&nbsp; &nbsp;&nbsp;&lt;홈페이지 회원가입 및 관리&gt;</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">&ndash;&nbsp;필수항목(제조사) :&nbsp;이메일,&nbsp;비밀번호,&nbsp;비밀번호 확인,&nbsp;이름,&nbsp;제조사 타입,&nbsp;회사명 혹은 브랜드 명</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">필수항목(바이어) :&nbsp;이메일,&nbsp;비밀번호,&nbsp;비밀번호 확인,&nbsp;이름</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">&ndash;&nbsp;선택항목(제조사) :&nbsp;제품,&nbsp;회사소개,&nbsp;회사명 등</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">&nbsp;선택항목(바이어) :&nbsp;회사명,&nbsp;한 줄 소개,&nbsp;국가선택,&nbsp;언어선택 등</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">4.&nbsp; &nbsp;&nbsp;&lt;홈페이지 회원가입 및 관리&gt;</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">&ndash;&nbsp;필수항목(제조사) :&nbsp;이메일,&nbsp;비밀번호,&nbsp;비밀번호 확인,&nbsp;이름,&nbsp;제조사 타입,&nbsp;회사명 혹은 브랜드 명</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">필수항목(바이어) :&nbsp;이메일,&nbsp;비밀번호,&nbsp;비밀번호 확인,&nbsp;이름</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">&ndash;&nbsp;선택항목(제조사) :&nbsp;제품,&nbsp;회사소개,&nbsp;회사명 등</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">&nbsp;선택항목(바이어) :&nbsp;회사명,&nbsp;한 줄 소개,&nbsp;국가선택,&nbsp;언어선택 등</span></p>
<h5 style='margin-top: 0cm; margin-right: 0cm; margin-bottom: 3.75pt; text-align: left; text-indent: -19.65pt; line-height: 14.2667px; font-size: 13px; font-family: "맑은 고딕"; font-weight: normal; color: rgb(0, 0, 0); font-style: normal; background: white;'><span style="font-size: 13px;"><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">7.&nbsp;</span></strong><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">개인정보의 파기</span></strong></span></h5>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">&lt;(주)빅파이씨앤티&gt;(&lsquo;www.darlibaba.com&rsquo;이하&nbsp;&lsquo;달리바바&rsquo;)는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다.&nbsp;파기의 절차,&nbsp;기한 및 방법은 다음과 같습니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-파기절차</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">이용자가 입력한 정보는 목적 달성 후 별도의&nbsp;DB에 옮겨져(종이의 경우 별도의 서류)&nbsp;내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다.&nbsp;이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가&nbsp;아니고서는&nbsp;다른 목적으로 이용되지 않습니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-파기기한</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터&nbsp;5일 이내에,&nbsp;개인정보의 처리 목적 달성,&nbsp;해당 서비스의 폐지,&nbsp;사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터&nbsp;5일 이내에 그 개인정보를 파기합니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">-파기방법</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.</span></p>
<h5 style='margin-top: 0cm; margin-right: 0cm; margin-bottom: 3.75pt; text-align: left; text-indent: -19.65pt; line-height: 14.2667px; font-size: 13px; font-family: "맑은 고딕"; font-weight: normal; color: rgb(0, 0, 0); font-style: normal; background: white;'><span style="font-size: 13px;"><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">8.&nbsp;</span></strong><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">개인정보 자동 수집 장치의 설치&bull;운영 및 거부에 관한 사항</span></strong></span></h5>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">①&nbsp;(주)빅파이씨앤티는&nbsp;개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는&nbsp;&lsquo;쿠기(cookie)&rsquo;를 사용합니다.&nbsp;② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의&nbsp;PC&nbsp;컴퓨터 내의 하드디스크에 저장되기도 합니다.&nbsp;가.&nbsp;쿠키의 사용&nbsp;목적&nbsp;:&nbsp;이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태,&nbsp;인기 검색어,&nbsp;보안접속 여부,&nbsp;등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.&nbsp;나.&nbsp;쿠키의 설치&bull;운영 및&nbsp;거부&nbsp;:&nbsp;웹브라우저&nbsp;상단의 도구&gt;인터넷 옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.&nbsp;다.&nbsp;쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</span></p>
<h5 style='margin-top: 0cm; margin-right: 0cm; margin-bottom: 3.75pt; text-align: left; text-indent: -19.65pt; line-height: 14.2667px; font-size: 13px; font-family: "맑은 고딕"; font-weight: normal; color: rgb(0, 0, 0); font-style: normal; background: white;'><span style="font-size: 13px;"><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">9.&nbsp;</span></strong><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">개인정보 보호책임자 작성</span></strong></span></h5>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">①&nbsp;(주)빅파이씨앤티(&lsquo;www.darlibaba.com&rsquo;이하&nbsp;&lsquo;달리바바&rsquo;)는 개인정보 처리에 관한 업무를 총괄해서 책임지고,&nbsp;개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br><br></span></p>
<p style="text-align: left;"><span style="font-family: Symbol; color: rgb(33, 37, 41); font-size: 13px;">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">▶&nbsp;개인정보 보호책임자</span></p>
<p style="text-align: left;"><span style="font-family: Symbol; color: rgb(33, 37, 41); font-size: 13px;">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">성명&nbsp;:&nbsp;진영웅</span></p>
<p style="text-align: left;"><span style="font-family: Symbol; color: rgb(33, 37, 41); font-size: 13px;">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">직책&nbsp;:&nbsp;정보관리 담당자</span></p>
<p style="text-align: left;"><span style="font-family: Symbol; color: rgb(33, 37, 41); font-size: 13px;">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">직급&nbsp;:&nbsp;주임</span></p>
<p style="text-align: left;"><span style="font-family: Symbol; color: rgb(33, 37, 41); font-size: 13px;">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">연락처&nbsp;:&nbsp;010-7362-0229, 0woong1004@naver.com</span></p>
<p style="text-align: left;"><span style="font-family: Symbol; color: rgb(33, 37, 41); font-size: 13px;">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">※&nbsp;개인정보 보호 담당부서로 연결됩니다.</span></p>
<p style="text-align: left;"><span style="line-height: 14.2667px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;"><br><br></span><span style="font-family: Symbol; color: rgb(33, 37, 41); font-size: 13px;">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">▶&nbsp;개인정보 보호 담당부서</span></p>
<p style="text-align: left;"><span style="font-family: Symbol; color: rgb(33, 37, 41); font-size: 13px;">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">부서명&nbsp;:&nbsp;개인정보 보호 담당부서</span></p>
<p style="text-align: left;"><span style="font-family: Symbol; color: rgb(33, 37, 41); font-size: 13px;">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">담당자&nbsp;:&nbsp;진영웅</span></p>
<p style="text-align: left;"><span style="font-family: Symbol; color: rgb(33, 37, 41); font-size: 13px;">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">연락처&nbsp;:&nbsp;010-7362-0229, 0woong1004@naver.com</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">②&nbsp;정보주체께서는&nbsp;(주)빅파이씨앤티(&lsquo;www.darlibaba.com&rsquo;이하&nbsp;&lsquo;달리바바&rsquo;)의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의,&nbsp;불만처리,&nbsp;피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. (주)빅파이씨앤티(&lsquo;www.darlibaba.com&rsquo;이하&nbsp;&lsquo;달리바바&rsquo;)는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</span></p>
<h5 style='margin-top: 0cm; margin-right: 0cm; margin-bottom: 3.75pt; text-align: left; text-indent: -19.65pt; line-height: 14.2667px; font-size: 13px; font-family: "맑은 고딕"; font-weight: normal; color: rgb(0, 0, 0); font-style: normal; background: white;'><span style="font-size: 13px;"><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">10.&nbsp;</span></strong><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">개인정보 처리방침 변경</span></strong></span></h5>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">①이 개인정보처리방침은 시행일로부터 적용되며,&nbsp;법령 및 방침에 따른 변경내용의 추가,&nbsp;삭제 및 정정이 있는 경우에는 변경사항의 시행&nbsp;7일 전부터 공지사항을 통하여 고지할 것입니다.</span></p>
<h5 style='margin-top: 0cm; margin-right: 0cm; margin-bottom: 3.75pt; text-align: left; text-indent: -19.65pt; line-height: 14.2667px; font-size: 13px; font-family: "맑은 고딕"; font-weight: normal; color: rgb(0, 0, 0); font-style: normal; background: white;'><span style="font-size: 13px;"><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">11.&nbsp;</span></strong><strong><span style="line-height: 14.2667px;font-family: 함초롬바탕, serif;color: rgb(33, 37, 41);">개인정보의 안전성 확보 조치</span></strong></span></h5>
<p style="text-align: left;"><span style="font-size: 13px; font-family: 함초롬바탕, serif; color: rgb(33, 37, 41);">&lt;(주)빅파이씨앤티&gt;(&lsquo;www.darlibaba.com&rsquo;이하&nbsp;&lsquo;달리바바&rsquo;)는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">1.&nbsp; &nbsp;&nbsp;정기적인 자체 감사 실시</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">개인정보 취급 관련 안정성 확보를 위해 정기적(분기&nbsp;1회)으로 자체 감사를 실시하고 있습니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">2.&nbsp; &nbsp;&nbsp;&nbsp;내부관리계획의 수립 및 시행</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">3.&nbsp; &nbsp;&nbsp;접속기록의 보관 및&nbsp;위변조&nbsp;방지</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">개인정보처리시스템에 접속한 기록을 최소&nbsp;6개월 이상 보관,&nbsp;관리하고 있으며,&nbsp;접속 기록이&nbsp;위변조&nbsp;및 도난,&nbsp;분실되지 않도록 보안기능 사용하고 있습니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">4.&nbsp; &nbsp;&nbsp;개인정보에 대한 접근 제한</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,&nbsp;변경,&nbsp;말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">5.&nbsp; &nbsp;&nbsp;비인가자에 대한 출입 통제</span></p>
<p style="text-align: left;"><span style="font-family: 함초롬바탕, serif; color: rgb(33, 37, 41); font-size: 13px;">개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립,&nbsp;운영하고 있습니다.</span></p>
<p style="text-align: left;"><span style="line-height: 14.2667px; font-family: 함초롬바탕, serif; font-size: 13px;">&nbsp;</span></p>
          `
          }}/>
        </div>
      </div>
    </ServicePolicy>
  );
};

export default Privacy;
