import React, {useEffect, useState} from "react";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import MakerList from "../common/MakerList";
import APIHelper from "../../../helpers/APIHelper";
import Constants from "../../../constants/constants";
import Paging from "../../common/Paging";
import CommonLib from "../../../libraries/CommonLib";
import BuyerList from "../common/BuyerList";

const AdminMakerFavorite = (props) => {
  const [me, setMe] = useState({});
  const [favorites, setFavorites] = useState();
  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      await getFavorites(me);
    }
    fetch();
  }, [me?.id, currPage]);

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "바이어관리 > 바이어별 관심내역",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const buyerClick = async (e) => {
    setMe(e);
    setCurrPage(1);
  };

  // 관심목록(제조사) 가져오기
  const getFavorites = (item) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("user/maker/gets", {
        targetUserId: item.user_id,
        searchFavorite: 1,
        articlesPerPage: 3,
        paging: 1,
        currPage
      });
      if (pageInfo) setPageInfo(pageInfo);
      setFavorites(result);
      window.scrollTo(0, 0);
      return resolve(true);
    });
  };

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <section className="col-4" style={{height: "98%"}}>
          <BuyerList addbtn={true} click={buyerClick} favorite={true}/>
        </section>
        <section className="col-8" style={{height: "98%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "100%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold p-2">관심 제조사 (총 {pageInfo?.totalArticles || 0}건)</p>
              <p className="m-1 mb-2"></p>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll px-2 mt-3"
              style={{height: "calc(100% - 180px)"}}
            >
              {favorites && favorites?.map((maker, i) => (
                <div
                  className="border-bottom d-flex p-4 p_rative"
                  style={{clear: "both", cursor: "pointer"}}
                >
                  <p>
                    {maker.rep_image_fid && maker.rep_image_fid.length > 0 ?
                      <img
                        src={`${Constants.API_HOST}/common/download?fid=${maker.rep_image_fid}&cd=inline&thumb=400`}
                        style={{width: "315px", height: "175px", objectFit: "cover"}}
                      />
                      :
                      <img
                        src={process.env.PUBLIC_URL + "/images/no_image.png"}
                        style={{width: "315px", height: "175px", objectFit: "cover"}}
                      />
                    }
                  </p>
                  <div className="px-4">
                    <h5 className="txt-point">
                      <strong>{maker.company_name}</strong>
                    </h5>
                    <p>
                      {maker?.introduce.length > 0 ? <span>{maker.introduce}</span> : <span>등록된 한줄 소개가 없습니다.</span>}
                      <br/>
                      {maker?.functionals && maker?.functionals.length > 0 ? maker?.functionals.map((item, key) => (
                        <span>
                      {key > 0 ? <span>,&nbsp;</span> : <span></span>}<span>{" "}{item.name_kr}</span>
                    </span>
                      )) : <span>등록된 기능성 제품 분류가 없습니다.</span>}
                    </p>
                    <ul className="d-flex txt-gray p-0 pt-4">
                      <li className="txt-sm">
                        <span>인증서{" "}:{" "}</span>
                        {maker?.certs && maker?.certs.length > 0 ? maker?.certs.map((item, key) => (
                          <span>
                        {key > 0 ? <span>{" "}/{" "}</span> : <span></span>}<span>{" "}{item.name_en}</span>
                      </span>
                        )) : <span>{" "}없음</span>}
                      </li>
                      <li className="txt-sm px-4"><span>MOQ{" "}:{" "}</span>{maker?.moq?.name_kr?.length > 0 ? <span>{maker?.moq?.name_kr}</span> : <span>정보없음</span>}</li>
                      <li className="txt-sm"><span>거래 횟수{" "}:{" "}0회</span></li>
                    </ul>
                    <div className="pt-2 mb-0 float-left">
                      {maker.mf_items && maker.mf_items.map((item, key) => (
                        <span className="Hash txt-point">{item.name_kr}</span>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
              {favorites?.length === 0 &&
                <div className="col w-100">
                  <div className="box_round_5 border d-flex justify-content-center bg-white" style={{height: "145px", lineHeight: "145px"}}>
                    해당 유저의 관심 제조사가 없습니다.
                  </div>
                </div>
              }
              <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminMakerFavorite;
