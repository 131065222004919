import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";
import Paging from "../../common/Paging";

const AdminCsFaqList = (props) => {
  const history = useHistory();

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  const [rets, setRets] = useState([]);
  const [gubunFilter, setGubunFilter] = useState('');
  const [gubunCds, setGubunCds] = useState([]);

  const getGubunCds = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '160000'});
      if (code === 200) setGubunCds(result);
      return resolve(true);
    });
  };

  const gets = async () => {
    const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("cs/faq/gets", {gubunFilter, userTypeCd: localStorage.getItem("admin.faq.user_type_cd"), paging: 1, currPage});
    if (pageInfo) setPageInfo(pageInfo);
    setRets(result);
    document.getElementById('scroll_div').scrollTop = 0;
  };

  useEffect(() => {
    const fetch = async () => {
      await getGubunCds();
      await gets();
    }
    fetch();
  }, [currPage, gubunFilter, localStorage.getItem("admin.faq.user_type_cd")]);

  return (
    <section className="col-4" style={{height: "100%"}}>
      <div
        className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
        style={{height: "98%"}}
      >
        <div className="d-flex justify-content-between border-bottom">
          <p className="h5 bold px-2">FAQ List</p>
          <p className="m-1 mb-2">
            <Link to="/admin/cs/faq/add">
              <button type="button" className="btn btn-purple txt-sm py-1 px-3">
                추가
              </button>
            </Link>
          </p>
        </div>

        <div
          className="wid_scroll scroll_y custom_scroll mt-2"
          id="scroll_div"
          style={{height: "calc(100% - 60px)"}}
        >
          <div className="wid_inline">
            <article className="m-2 bg-lpurple box_round p-3">
              <div className="input-group">
                <label className="input-group-text" for="inputGroupSelect01">
                  분류
                </label>
                <select
                  className="form-select form-select-sm w-50"
                  aria-label="Default select example"
                  onChange={async (e) => await setGubunFilter(e.target.value)}
                  value={gubunFilter}
                >
                  <option value="" selected={'' === gubunFilter}>전체</option>
                  {gubunCds.map((item, k) => <option key={k} value={item.cd} selected={item.cd === gubunFilter}>{item.name_kr}</option>)}
                </select>
              </div>
            </article>

            {rets.map((ret, i) => (
              <div
                className="box_round_5 border my-2 p-3"
                onClick={() => history.push(`/admin/cs/faq/detail/${ret.id}`)}
              >
                <div className="d-flex">
                  <p style={{padding: "0px 15px 0px 5px"}}>{(pageInfo?.totalArticles - i).toString().padStart(2, '0')}</p>
                  <div style={{width: "calc(100% - 50px)"}}>
                    <p className="h6 mb-2 text-start">{ret.question}</p>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <p>
                          노출유무 :{" "}
                          <span className="bold">
                            {ret.exposure_yn ? "Y" : "N"}
                          </span>
                        </p>
                        <p className="px-3 txt-sm ">|</p>
                        <p>
                          View : <span className="bold">{CommonLib.parseInt(ret.view_hits)}</span>
                        </p>
                      </div>
                      <p className="txt-sm txt-gray">{ret.date}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {rets?.length === 0 &&
              <div className="col w-100">
                <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-white" style={{marginLeft: "8px", marginRight: "8px"}}>
                  등록된 FAQ 가 없습니다.
                </div>
              </div>
            }
            <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminCsFaqList;
