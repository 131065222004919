import React, {useEffect, useState} from "react";
import BuyerProjectCard from "./BuyerProjectCard";
import {Link, useHistory} from "react-router-dom";
import arrow_move from "../../../image/arrow_move.svg";
import icon_visibility from "../../../image/icon_visibility.svg";
import thum_manager from "../../../image/thum_manager.png";

import "../../../sass/main.scss";
import ChannelService from "../../../helpers/ChannelService";
import APIHelper from "../../../helpers/APIHelper";
import moment from "moment";
import Constants from "../../../constants/constants";
import {useDispatch} from "react-redux";
import openRequestFormulation from "../../../actions/openRequestFormulation";
import openRequestDesign from "../../../actions/openRequestDesign";
import openRequestBottle from "../../../actions/openRequestBottle";
import openRequestBox from "../../../actions/openRequestBox";
import CommonLib from "../../../libraries/CommonLib";

const BuyerProjectSide = (props) => {
  const dv = props?.dv;
  const id = props?.id;
  const path = window.location.pathname;

  // modal액션으로 카테고리(단상자, 디자인...) 보내기
  const dispatch = useDispatch();
  const history = useHistory();

  const openRequestForm = (projectId, projectProductId, itemCd) => {
    const obj = {projectId, projectProductId, renew: new Date().getTime(), readOnly: true};
    if (itemCd === 'MF') dispatch(openRequestFormulation("open", obj));
    else if (itemCd === 'DS') dispatch(openRequestDesign("open", obj));
    else if (itemCd === 'BT') dispatch(openRequestBottle("open", obj));
    else if (itemCd === 'BX') dispatch(openRequestBox("open", obj));
  }

  const [project, setProject] = useState({});

  useEffect(() => {
    const fetch = async () => {
      await getRequest();
    }
    fetch();
  }, []);

  const getRequest = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("project/get", {id});
      if (code === 200) {
        setProject(result);
      }
      return resolve(true);
    });
  }

  const openBot = () => {
    ChannelService.openBot();
  }

  const [inquiryResult, setInquiryResult] = useState();

  const checkUnread = async () => {
    const {data: {code, result}} = await APIHelper.getForm("user/inquiry/unread/check", {projectId: project?.id});
    setInquiryResult(result);
  };

  useEffect(() => {
    const fetch = async () => {
      await checkUnread();
    }
    fetch();
  }, [props.renew, project?.id]);

  return (
    <BuyerProjectCard>
      <div className="box_round min_h cnt_area pb-0">
        <div className="p_absolute project_dash" style={{left: "-280px", top: "unset", bottom: "15px", borderBottomLeftRadius: "0px", borderTopLeftRadius: "0px", height: "auto"}}>
          <div className="bg-lpurple p-3 py-4">
            <div className="txt-sm txt-gray">
              프로젝트 No : {project?.prj_no}
              <p className="h4 bold txt-point py-2">
                {project?.name?.length > 0 ? project?.name : "프로젝트명 미정"}
              </p>
              <p style={{marginTop: "5px"}}>일자 : {moment(project.created_at).format("YYYY.MM.DD")} ~ {window.location.pathname.includes("/progress") ? "진행중" : moment(project.finished_at).format("YYYY.MM.DD")}</p>
              <p style={{marginTop: "5px"}}>제품 : {CommonLib.parseInt(project?.total_formed_products?.length)}건</p>
              <p style={{marginTop: "5px"}}>판매국가 : {project?.sales_countries}</p>
            </div>
          </div>

          <div className="p-3 border-bottom p_rative" style={{minWidth: "208px"}}>
            <div className="m-1 p_rative">
              <div className="thum_buyer_info p_absolute p-3">
                <p
                  className="box_round_30 bg-white px-1 txt-sm mt-1"
                  style={{width: "53px"}}
                >
                  매니저
                </p>
                <p className="h6 bold my-2">{project?.rep_manager?.id > 0 ? project?.rep_manager?.nickname?.substring(0, 4) + "***" : '미배정'}</p>
              </div>
              <div className="thum_buyer">
                {project?.rep_manager?.id > 0 && project?.rep_manager.image_fid && project?.rep_manager.image_fid.length > 0 ?
                  <img
                    src={`${Constants.API_HOST}/common/download?fid=${project?.rep_manager.image_fid}&cd=inline&thumb=400`}
                    style={{width: "208px", height: "120px", objectFit: "cover"}}
                  />
                  :
                  <div style={{width: "208px", height: "120px", objectFit: "cover", backgroundColor: "#efefef"}}></div>
                }
              </div>
            </div>
            <div className="w-100 d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-outline-dark txt-sm hov_pink px-1 w-48 mt-2"
                onClick={() => history.push(`/project/buyer/inquiry/${props.dv}/${props.id}`)}
              >
                1:1 문의
              </button>
              <button
                type="button"
                className="btn btn-outline-dark txt-sm hov_pink px-1 w-48 mt-2"
                onClick={() => openBot()}
              >
                채팅 문의
              </button>
            </div>
          </div>
        </div>
        <div className="mx-5 my-4 pb-0">
          <div
            className="p_absolute_tr d-flex"
            style={{top: "20px", right: "35px"}}
          >
            <button type="button" className="btn btn-sm p-0 txt-gray" onClick={() => history.push('/guide/about')}>
              <img src={icon_visibility} style={{width: "20px"}}/>
              이용가이드
            </button>
          </div>
          <div className="TAB my-5 pt-3">
            <li className={`${path.includes("home") ? "ON" : ""}`}>
              <Link to={`/project/buyer/task/home/${props.dv}/${props.id}`} style={{fontSize: "0.95rem"}}>작업(Task)</Link>
            </li>
            <li className={`${path.includes("form") ? "ON" : ""}`}>
              <Link to={`/project/buyer/task/form/${props.dv}/${props.id}`} style={{fontSize: "0.95rem"}}>구성</Link>
            </li>
            <li className={`${path.includes("timeline") ? "ON" : ""}`}>
              <Link to={`/project/buyer/task/timeline/${props.dv}/${props.id}`} style={{fontSize: "0.95rem"}}>타임라인</Link>
            </li>
            <li className={`${path.includes("task/detail") ? "ON" : ""}`}>
              <Link to={`/project/buyer/task/detail/${props.dv}/${props.id}`} style={{fontSize: "0.95rem"}}>제작 의뢰서</Link>
            </li>
            {/*<li*/}
            {/*  className={`${*/}
            {/*    path.includes("project/buyer/request") ? "ON" : ""*/}
            {/*  }`}*/}
            {/*>*/}
            {/*  <Link to="/project/buyer/request">상담의뢰서</Link>*/}
            {/*</li>*/}
            <li className={`${path.includes("inquiry") ? "ON" : ""}`}>
              <Link to={`/project/buyer/inquiry/${props.dv}/${props.id}`} style={{fontSize: "0.95rem"}}>1:1 문의</Link>
              {CommonLib.parseInt(inquiryResult) > 0 && <div className="new-icon2" style={{marginLeft: "118px"}}>N</div>}
            </li>
          </div>
          {props.children}
        </div>
      </div>
    </BuyerProjectCard>
  );
};

export default BuyerProjectSide;
