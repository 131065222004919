import React, {useState} from "react";

import arrow_down from "../../image/arrow-down.svg";
import arrow_up from "../../image/arrow-up.svg";

import "../../sass/main.scss";
import CommonLib from "../../libraries/CommonLib";

const FaqItem = (props) => {
	// 아코디언
	const [isOpen, setIsOpen] = useState(false);
	const clickHandler = () => {
		setIsOpen(!isOpen);
	};

	return (
		<>
			<tr onClick={clickHandler} style={{cursor: "pointer"}}>
				<td>{props.no}</td>
				<td>{props.category}</td>
				<td className="left">{props.title}</td>
				<td>{props.date}</td>
				<td>
					<a className="left">
						<img
							src={`${isOpen === true ? `${arrow_up}` : `${arrow_down}`}`}
							style={{width: "15px"}}
						/>
					</a>
				</td>
			</tr>
			<tr className={`${isOpen === true ? "" : "displayNone"}`}>
				<td colSpan="5" className="view">
					<p className="bold">
						<span className="h6 mb-0">{props.title}</span>
						<span className="txt-gray" style={{fontWeight: "normal"}}>
              {props.no}
            </span>
					</p>
					<p>{CommonLib.escapedNewLineToLineBreakTag(props.text)}</p>
				</td>
			</tr>
		</>
	);
};

export default FaqItem;
