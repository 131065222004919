import React, {useEffect} from "react";

import icon_foot from "../image/icon_foot.svg";
import "../sass/main.scss";
import {useHistory} from "react-router-dom";
import ChannelService from "../helpers/ChannelService";
import {useTranslation} from "react-i18next";
import CommonLib from "../libraries/CommonLib";
import {changeLanguage} from "i18next";
import $ from "jquery";

const ChannelTalk = () => {
  // useEffect(() => {
  //   const path = window.location.pathname;
  //   if (path.includes("/cscenter/inquiry")) return "";
  //
  //   ChannelService.boot({
  //     "pluginKey": "0f530fd7-6842-46d9-bb6c-0fac9e4c849e",
  //     "memberId": localStorage.getItem("user.session.email"),
  //     "profile": {
  //       "name": localStorage.getItem("user.session.name")
  //     },
  //   });
  // }, []);

  return (
    <a href="https://wa.me/821057668668" target="_blank">
      <div className="whatsapp"></div>
    </a>
  )
}

const Footer = (props) => {
  const {t} = useTranslation();
  const history = useHistory();

  // 2023.08.09 수정
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement({pageLanguage: 'ko', includedLanguages: 'ko,en', autoDisplay: false, layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE}, 'google_translate_element')
  }

  useEffect(() => {
    const addScript = document.createElement('script');
    addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;

    changeLanguage("en");

    // 2023.08.08 수정
    setTimeout(() => {
      {
        const myframe = $("iframe[title='언어 번역 위젯']");
        myframe.addClass('noshow');
        try {
          myframe.contents().find('span.text:contains(' + "영어" + ')').get(0).click();
        } catch (err) {
          console.log(err);
        }

        const lang = '영어';
        const frame = $('.goog-te-menu-frame:first');

        try {
          frame.contents().find('.goog-te-menu2-item span.text:contains(' + lang + ')').get(0).click();
        } catch (err) {
        }
        try {
          frame.contents().find('.goog-te-menu2-item span.text:contains(' + lang + ')').get(0).click();
        } catch (err) {
        }
      }
    }, 500);

    // 2023.08.08 수정
    setTimeout(() => {
      {
        const myframe = $("iframe[title='언어 번역 위젯']");
        myframe.addClass('noshow');
        try {
          myframe.contents().find('span.text:contains(' + "영어" + ')').get(0).click();
        } catch (err) {
          console.log(err);
        }

        const lang = '영어';
        const frame = $('.goog-te-menu-frame:first');

        try {
          frame.contents().find('.goog-te-menu2-item span.text:contains(' + lang + ')').get(0).click();
        } catch (err) {
        }
        try {
          frame.contents().find('.goog-te-menu2-item span.text:contains(' + lang + ')').get(0).click();
        } catch (err) {
        }
      }
    }, 1000);

    // 2023.08.08 수정
    setTimeout(() => {
      {
        const myframe = $("iframe[title='언어 번역 위젯']");
        myframe.addClass('noshow');
        try {
          myframe.contents().find('span.text:contains(' + "영어" + ')').get(0).click();
        } catch (err) {
          console.log(err);
        }

        const lang = '영어';
        const frame = $('.goog-te-menu-frame:first');

        try {
          frame.contents().find('.goog-te-menu2-item span.text:contains(' + lang + ')').get(0).click();
        } catch (err) {
        }
        try {
          frame.contents().find('.goog-te-menu2-item span.text:contains(' + lang + ')').get(0).click();
        } catch (err) {
        }
      }
    }, 1500);
  }, [])

  // const openBot = () => {
  //   ChannelService.openBot();
  // }

  const path = window.location.pathname;
  if (path.includes("admin")) {
    return "";
  }

  const onFaq = () => {
    if (localStorage.getItem("user.session.user_type") === "MAKER") history.push("/cscenter/faq");
    else history.push("/cscenter/faq");
  };

  const inquiry = async () => {
    if (!localStorage.getItem("user.session.email")) {
      await CommonLib.jalert('로그인이 필요합니다.');
      return;
    }
    history.push({
      pathname: '/cscenter/inquiry'
    })
  }

  return (
    <div id="FOOTER_AREA" className="w-100 p-0">
      <ChannelTalk/>
      <div className="MAX d-flex justify-content-between">
        <div className="py-3" style={{maxWidth: "750px"}}>
          <p className="foot_comname">{t('footer.company_name')}</p>
          <ul className="d-flex p-0 flex-wrap foot_cominfo">
            <li>
              {t('footer.company_addr')}
            </li>
            <li style={{borderRightWidth: 0}}>{t('footer.company_biz_no')}</li>
            <br/>
            <li>{t('footer.company_mos_no')}</li>
            <li>{t('footer.company_ceo')}</li>
            <li>{t('footer.company_email')}</li>
          </ul>
          <p className="foot_copy" style={{paddingTop: 0, marginTop: 0}}>
            {t('footer.copyright')}
          </p>
        </div>
        <div className="py-3">
          <ul className="d-flex mt-3 flex-wrap foot_menu">
            <li>
              <a style={{cursor: 'pointer'}} onClick={() => history.push('/')}>{t('footer.home')}</a>
            </li>
            {/*<li>*/}
            {/*  <a style={{cursor: 'pointer'}} onClick={() => history.push('/guide/about')}>{t('footer.guide')}</a>*/}
            {/*</li>*/}
            <li>
              <a style={{cursor: 'pointer'}} onClick={() => history.push('/servicepolicy/agreement')}>{t('footer.terms')}</a>
            </li>
            <li>
              <a style={{cursor: 'pointer'}} onClick={() => history.push('/servicepolicy/privacy')}>{t('footer.privacy')}</a>
            </li>
            {/*<li>*/}
            {/*  <a style={{cursor: 'pointer'}} onClick={() => history.push('/cscenter/notice')}>{t('footer.cscenter')}</a>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <a style={{cursor: "pointer"}} onClick={onFaq}>자주 찾는 질문</a>*/}
            {/*</li>*/}
          </ul>
          {/*<div className="foot_chat" style={{float: "right", cursor: "pointer"}} onClick={() => openBot()}>*/}
          {/*  <p className="p-2">*/}
          {/*    <img src={icon_foot}/>*/}
          {/*  </p>*/}
          {/*  <div className="txt px-2" style={{marginTop: "4px"}}>*/}
          {/*    {t('footer.chatting.title')}*/}
          {/*    <p>{t('footer.chatting.sub_title')}</p>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default Footer;
