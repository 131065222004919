import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import icon_q from "../../image/Icon_q.svg";
import favorite from "../../image/favorite.svg";
import first_page_black_48dp from "../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../image/chevron_left_black_24dp.svg";
import navigate_next_black_48dp from "../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../image/last_page_black_48dp.svg";
import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import Constants from "../../constants/constants";
import CommonLib from "../../libraries/CommonLib";
import favorite_border from "../../image/favorite_border.svg";
import Paging from "../common/Paging";
import {useTranslation} from "react-i18next";

const BuyerFind = (props) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const [buyers, setBuyers] = useState(false);
  const [searchTags, setSearchTags] = useState([]);
  const [searchCountries, setSearchCountries] = useState([]);
  const [searchTagData, setSearchTagData] = useState([]);
  const [searchCountryData, setSearchCountryData] = useState([]);
  const [moreSearchTag, setMoreSearchTag] = useState(true);
  const [moreSearchCountry, setMoreSearchCountry] = useState(false);
  const [searchFavorite, setSearchFavorite] = useState(false);

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  const history = useHistory();
  const {t} = useTranslation();

  const favoriteHandler = () => {
    setIsFavorite(!isFavorite);
  };

  const [keyword, setKeyword] = useState('');
  const search = async () => {
    await setCurrPage(1);
    await getBuyers();
  }
  const handleSearchKeyDown = async (e) => {
    if (e.key === 'Enter') {
      await search();
    }
  }

  useEffect(() => {
    const fetch = async () => {
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '110000'});
        if (code === 200) setSearchTags(result);
      }
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '106000'});
        if (code === 200) setSearchCountries(result);
      }
    }
    fetch();
  }, []);

  const addSearchTagData = (checked, cd) => {
    const arr = [...searchTagData];
    if (checked && !arr.includes(cd))
      arr.push(cd);
    else if (!checked && arr.includes(cd))
      arr.splice(arr.indexOf(cd), 1);
    setSearchTagData(arr);
  }

  const addSearchCountryData = (checked, cd) => {
    const arr = [...searchCountryData];
    if (checked && !arr.includes(cd))
      arr.push(cd);
    else if (!checked && arr.includes(cd))
      arr.splice(arr.indexOf(cd), 1);
    setSearchCountryData(arr);
  }

  useEffect(() => {
    const fetch = async () => {
      await getBuyers();
    }
    fetch();
  }, [currPage, searchTagData, searchCountryData, searchFavorite]);

  // 바이어 리스트 가져오기
  const getBuyers = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("user/buyer/gets", {
        searchTagData: searchTagData.join(","),
        searchCountryData: searchCountryData.join(","),
        searchFavorite: searchFavorite ? 1 : 0,
        keyword,
        paging: 1,
        currPage
      });
      if (pageInfo) setPageInfo(pageInfo);
      setBuyers(result);
      window.scrollTo(0, 0);
      return resolve(true);
    });
  };

  return (
    <>
      <div className="BODY MAX my-4">
        <div
          className="box_round d-flex justify-content-between p-3"
          style={{height: "110px"}}
        >
          <div className="h5 px-5 py-3" style={{wordBreak: "break-all", maxWidth: "480px", verticalAlign: "middle"}}>
            <span className="h5">키워드 검색을 통해</span> <span><br/></span>
            <span className="h4 bold txt-point">원하시는 바이어</span>를 찾아보세요.
          </div>
          <div className="px-5 py-2">
            <div className="input-group input-group-lg">
              <input
                type="text"
                className="form-control search"
                placeholder="바이어명 또는 키워드를 입력하세요"
                style={{width: "410px"}}
                onChange={async (e) => await setKeyword(e.target.value)}
                value={keyword}
                onKeyDown={handleSearchKeyDown}
              />
              <button
                className="btn btn_puple px-5"
                type="button"
                id="button-addon2"
                onClick={() => search()}
              >
                검색
              </button>
            </div>
            {/*<ul className="d-flex py-3 px-0">*/}
            {/*  <li className="px-1">*/}
            {/*    <a href="#" className="Hash txt-point txt-sm">*/}
            {/*      Mask*/}
            {/*    </a>*/}
            {/*  </li>*/}
            {/*  <li className="px-1">*/}
            {/*    <a href="#" className="Hash txt-point txt-sm">*/}
            {/*      Hair*/}
            {/*    </a>*/}
            {/*  </li>*/}
            {/*  <li className="px-1">*/}
            {/*    <a href="#" className="Hash txt-point txt-sm">*/}
            {/*      Skincare*/}
            {/*    </a>*/}
            {/*  </li>*/}
            {/*  <li className="px-1">*/}
            {/*    <a href="#" className="Hash txt-point txt-sm">*/}
            {/*      Face Oil*/}
            {/*    </a>*/}
            {/*  </li>*/}
            {/*</ul>*/}
          </div>
        </div>

        <div
          className="mt-3 d-flex justify-content-between w-100"
          style={{minHeight: "calc(100% - 125px)"}}
        >
          <div className="box_round min_h left_menu">
            <div className="left_menu_title">바이어 찾기 필터</div>
            <div className="mx-2 pt-3 px-2 border-bottom">
              <p className="d-flex justify-content-between ">
                <span className="txt-point h6" style={{cursor: "pointer"}} onClick={() => setSearchTagData([])}>관심분야</span>
                <span className="txt-sm txt-gray">
                  중복선택 가능 <img src={icon_q} style={{width: "16px"}}/>
                </span>
              </p>
              <ul className="p-0 py-2 d-flex flex-wrap m-0 txt-gray" style={{maxHeight: moreSearchTag ? "9999px" : "100px", overflow: "hidden"}}>
                {searchTags && searchTags.length && searchTags.map((item, k) => (
                  <li className="w-50 mb-1">
                    <div className="form-check">
                      <input
                        className="form-check-input mt-1"
                        type="checkbox"
                        id={`defaultCheck10${k}`}
                        value={item.cd}
                        onChange={(e) => addSearchTagData(e.target.checked, item.cd)}
                        checked={searchTagData.includes(item.cd)}
                      />
                      <label className="form-check-label" for={`defaultCheck10${k}`} style={{fontSize: "0.8rem"}}>
                        {" "}
                        {item.name_kr}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="w-100 mt-0 mb-1 bold" style={{textAlign: "right", cursor: "pointer"}} onClick={() => setMoreSearchTag(!moreSearchTag)}>
                {moreSearchTag ? "- hide" : "+ more"}
              </div>
            </div>

            <div className="mx-2 pt-3 px-2 border-bottom">
              <p className="d-flex justify-content-between ">
                <span className="txt-point h6" style={{cursor: "pointer"}} onClick={() => setSearchCountryData([])}>국가선택</span>
                <span className="txt-sm txt-gray">
                  중복선택 가능 <img src={icon_q} style={{width: "16px"}}/>
                </span>
              </p>
              <ul className="p-0 py-2 d-flex flex-wrap m-0 txt-gray" style={{maxHeight: moreSearchCountry ? "9999px" : "100px", overflow: "hidden"}}>
                {searchCountries && searchCountries.length && searchCountries.map((item, k) => (
                  <li className="w-50 mb-1">
                    <div className="form-check">
                      <input
                        className="form-check-input mt-1"
                        type="checkbox"
                        id={`defaultCheck20${k}`}
                        value={item.cd}
                        onChange={(e) => addSearchCountryData(e.target.checked, item.cd)}
                        checked={searchCountryData.includes(item.cd)}
                      />
                      <label className="form-check-label" for={`defaultCheck20${k}`} style={{fontSize: "0.8rem"}}>
                        {" "}
                        {item.name_kr}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="w-100 mt-0 mb-1 bold" style={{textAlign: "right", cursor: "pointer"}} onClick={() => setMoreSearchCountry(!moreSearchCountry)}>
                {moreSearchCountry ? "- hide" : "+ more"}
              </div>
            </div>

          </div>
          <div className="box_round min_h cnt_area">
            <div className="d-flex justify-content-between p-3 border-bottom">
              <div/>
              <div className="form-check mt-1 p-1" style={{backgroundColor: "#7c6daf", color: "#ffffff", borderRadius: 20}}>
                <input
                  className="form-check-input mt-1"
                  type="checkbox"
                  id="defaultCheck1"
                  onChange={async (e) => await setSearchFavorite(!searchFavorite)}
                  style={{marginLeft: "-5px"}}
                />
                <label className="form-check-label notranslate" htmlFor="defaultCheck1" style={{marginLeft: "8px", marginTop: "1px", paddingRight: 20}}>
                  {" "}
                  {t('find.favorite.buyer')}{" "}
                </label>
              </div>
            </div>

            {buyers && buyers.map((buyer, key) => (
              <div
                className="border-bottom d-flex p-4 p_rative"
                style={{clear: "both", cursor: "pointer"}}
                onClick={async () => {
                  if ((localStorage.getItem("user.session.user_type") || '') !== 'MAKER') {
                    await CommonLib.jalert('제조사 회원만 이용 가능합니다.');
                    return;
                  }
                  history.push({
                    pathname: `/buyer/home`,
                    state: {user_id: buyer.user_id}
                  });
                }}>
                <p className="p_absolute_tr">
                  <img
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      await APIHelper.postForm("user/favorite/toggle", {favorite_user_id: buyer?.user_id});
                      await getBuyers();
                    }}
                    src={`${parseInt(buyer?.favorite, 10) === 1 ? favorite : favorite_border}`}
                    className="mx-1"
                    style={{width: "31px", cursor: "pointer"}}
                  />
                </p>
                <p>
                  {buyer.rep_image_fid && buyer.rep_image_fid.length > 0 ?
                    <img
                      src={`${Constants.API_HOST}/common/download?fid=${buyer.rep_image_fid}&cd=inline&thumb=400`}
                      style={{width: "315px", height: "175px", objectFit: "cover"}}
                    />
                    :
                    <img
                      src={process.env.PUBLIC_URL + "/images/no_image.png"}
                      style={{width: "315px", height: "175px", objectFit: "cover"}}
                    />
                  }
                </p>
                <div className="px-4">
                  <h5 className="txt-point">
                    <strong>
                      <span>
                      {
                        buyer && (buyer?.company?.length > 0 ? <span style={{fontSize: "1.25rem"}}>{buyer.company}</span> :
                          <span style={{fontSize: "1.25rem"}}>{(buyer?.first_name + " " + buyer?.middle_name + " " + buyer?.last_name)}</span>)
                      }
                      </span>
                    </strong>
                  </h5>
                  <p className="mt-3"><span><span style={{fontWeight: "bold"}}>국적</span><span>{" "}</span><span>:</span><span>{" "}</span><span>{buyer.country?.name_kr}</span></span></p>
                  <p className="mt-2">
                    {buyer?.introduce.length > 0 ? <span>{buyer.introduce}</span> : <span>등록된 한줄 소개가 없습니다.</span>}
                  </p>
                  <p className="mt-3 bold"><span>선호하는 상품 분야</span></p>
                  <div className="pt-2 mb-0 float-left">
                    {buyer.favorites.length > 0 ? buyer.favorites.map((item, key) =>
                      <span className="Hash txt-point">{item.name_kr}</span>
                    ) : <span>선호하는 상품 분야가 없습니다.</span>}
                  </div>
                </div>
              </div>
            ))}

            <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          </div>
        </div>
      </div>
    </>
  )
    ;
};

export default BuyerFind;
