import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import openReview from "../../actions/openReview";

import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import Constants from "../../constants/constants";
import CommonLib from "../../libraries/CommonLib";

const ReviewModal = (props) => {
  const closeModal = () => {
    props.openReview("closed", "");
  };

  const {projectId, renew, readOnly} = props.reviewPop?.content;

  let modalInlineStyle;

  if (props.reviewPop.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }

  const [result, setResult] = useState();
  const [currFormedProduct, setCurrFormedProduct] = useState();
  const [currIdx, setCurrIdx] = useState(-1);
  const [category, setCategory] = useState();
  const [product, setProduct] = useState();

  const [mfScore, setMfScore] = useState(3);
  const [dsScore, setDsScore] = useState(3);
  const [btScore, setBtScore] = useState(3);
  const [bxScore, setBxScore] = useState(3);

  const [managerScore1, setManagerScore1] = useState(3);
  const [managerScore2, setManagerScore2] = useState(3);
  const [managerScore3, setManagerScore3] = useState(3);

  const [mfContent, setMfContent] = useState('');
  const [btContent, setBtContent] = useState('');
  const [bxContent, setBxContent] = useState('');
  const [dsContent, setDsContent] = useState('');
  const [managerContent, setManagerContent] = useState('');

  const [progress, setProgress] = useState(20);

  useEffect(() => {
    (async () => {
      setCurrIdx(-1);

      const {data: {code, result, message}} = await APIHelper.getForm("project/review/form", {projectId});
      if (code === 200) {
        if (!readOnly && result?.formed_products?.[0]?.mf_review?.content?.length > 0) {
          await CommonLib.jalert('이미 리뷰를 작성하셨습니다.');
          closeModal();
          return;
        }
        setResult(result);
        setCurrIdx(0);
      }
    })();
  }, [renew]);

  useEffect(() => {
    (async () => {
      const d = result?.formed_products?.[currIdx];

      setMfContent(d?.mf_review?.content || '');
      setBtContent(d?.bt_review?.content || '');
      setBxContent(d?.bx_review?.content || '');
      setDsContent(d?.ds_review?.content || '');

      setMfScore(d?.mf_review?.review_score || 3);
      setDsScore(d?.ds_review?.review_score || 3);
      setBtScore(d?.bt_review?.review_score || 3);
      setBxScore(d?.bx_review?.review_score || 3);

      setManagerScore1(result?.manager_review?.review_score_t1 || 3);
      setManagerScore2(result?.manager_review?.review_score_t2 || 3);
      setManagerScore3(result?.manager_review?.review_score_t3 || 3);

      setManagerContent(result?.manager_review?.content || '');

      const p = d?.project_product;
      setCurrFormedProduct(d);
      setCategory(await getCode(p?.category_cd));
      setProduct(await getCode(p?.product_cd));

      const total = result?.formed_products?.length + (result?.rep_manager?.id > 0 ? 1 : 0);
      setProgress(Math.round((currIdx + 1) / total * 100));
    })();
  }, [currIdx]);

  const getCode = (cd) => {
    return new Promise(async (resolve, reject) => {
      const {
        data: {code, result, message},
      } = await APIHelper.getForm("code/get", {cd});
      return resolve(result);
    });
  };

  const onNext = async (isManagerReview = false) => {
    if (isManagerReview) {
      if (!readOnly && result?.rep_manager?.id > 0) {
        const {data: {code, result, message}} = await APIHelper.postForm("project/review/manager", {
          projectId,
          reviewScoreT1: managerScore1,
          reviewScoreT2: managerScore2,
          reviewScoreT3: managerScore3,
          content: managerContent,
        });
      }
      closeModal();
    } else {
      if (!readOnly && currFormedProduct?.mf_request_id > 0) {
        const {data: {code, result, message}} = await APIHelper.postForm("project/review/maker", {
          projectMakerRequestId: currFormedProduct?.mf_request_id,
          makerType: 'MF',
          reviewScore: mfScore,
          content: mfContent,
        });
      }
      if (!readOnly && currFormedProduct?.bt_request_id > 0) {
        const {data: {code, result, message}} = await APIHelper.postForm("project/review/maker", {
          projectMakerRequestId: currFormedProduct?.bt_request_id,
          makerType: 'BT',
          reviewScore: btScore,
          content: btContent,
        });
      }
      if (!readOnly && currFormedProduct?.bx_request_id > 0) {
        const {data: {code, result, message}} = await APIHelper.postForm("project/review/maker", {
          projectMakerRequestId: currFormedProduct?.bx_request_id,
          makerType: 'BX',
          reviewScore: bxScore,
          content: bxContent,
        });
      }
      if (!readOnly && currFormedProduct?.ds_request_id > 0) {
        const {data: {code, result, message}} = await APIHelper.postForm("project/review/maker", {
          projectMakerRequestId: currFormedProduct?.ds_request_id,
          makerType: 'DS',
          reviewScore: dsScore,
          content: dsContent,
        });
      }

      setCurrIdx(currIdx + 1);
      if (!result?.rep_manager?.id) closeModal();
    }
  }

  const submit = async () => {
    closeModal();
  }

  const Stars = (props) => {
    const [score, setScore] = useState(props.defaultScore);
    return (
      <>
        <img src={require(score >= 1 ? `../../image/star_r.svg` : `../../image/star_g.svg`).default} style={{width: props.large ? '21px' : '18px'}} onClick={() => {
          setScore(1);
          props.callback(1);
        }}/>
        <img src={require(score >= 2 ? `../../image/star_r.svg` : `../../image/star_g.svg`).default} style={{width: props.large ? '21px' : '18px'}} onClick={() => {
          setScore(2);
          props.callback(2);
        }}/>
        <img src={require(score >= 3 ? `../../image/star_r.svg` : `../../image/star_g.svg`).default} style={{width: props.large ? '21px' : '18px'}} onClick={() => {
          setScore(3);
          props.callback(3);
        }}/>
        <img src={require(score >= 4 ? `../../image/star_r.svg` : `../../image/star_g.svg`).default} style={{width: props.large ? '21px' : '18px'}} onClick={() => {
          setScore(4);
          props.callback(4);
        }}/>
        <img src={require(score >= 5 ? `../../image/star_r.svg` : `../../image/star_g.svg`).default} style={{width: props.large ? '21px' : '18px'}} onClick={() => {
          setScore(5);
          props.callback(5);
        }}/>
      </>
    )
  }

  return (
    <div style={{display: modalInlineStyle, zIndex: 9999999}}>
      <div className="pop_box02" style={{width: "850px", minHeight: "500px"}}>
        <div className="POP_title pb-3">
          <h4><strong>후기 작성</strong></h4>
          <p className="closed">
            <button type="button" className="btn" onClick={() => closeModal()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </button>
          </p>
        </div>


        {currFormedProduct && currIdx < result?.formed_products?.length &&
          <>
            <div className="POP_cnt_box_lg" style={{marginBottom: "90px"}}>

              <div className="d-flex justify-content-between border-bottom border-top pb-2 pt-3">
                <div>
                  <h5 className="txt-point"><strong>{result?.project?.name}</strong></h5>
                  <span className="txt-gray txt-sm">(프로젝트 No:{result?.project?.prj_no})</span>
                </div>
                <div className="d-flex">
                  <p className="p-2">
                    {product?.image_uri &&
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          product?.image_uri.substring(
                            0,
                            product?.image_uri.indexOf(".")
                          ) +
                          "1." +
                          product?.image_uri.substring(
                            product?.image_uri.indexOf(
                              "."
                            ) + 1
                          )
                        }
                        style={{width: "15px", marginBottom: "5px"}}
                      />
                    }
                  </p>
                  <p className="pt-2"><span className="h6 bold">{currFormedProduct?.name}</span><br/>
                    <span className="txt-sm">{category?.name_kr} | {product?.name_kr}</span></p>
                </div>
              </div>

              <table className="w-100 border-bottom" style={{backgroundColor: "#efefef"}}>
                <tr>
                  <td style={{width: "50px", height: "30px", textAlign: "center", fontSize: "13px"}}>{progress}%</td>
                  <td style={{height: "5px", paddingRight: "10px"}}>
                    <div style={{width: `100%`, height: "5px", backgroundColor: "darkgray"}}>
                      <div style={{width: `${progress}%`, height: "5px", backgroundColor: "mediumpurple"}}/>
                    </div>
                  </td>
                </tr>
              </table>

              <div style={{visibility: currFormedProduct?.mf_request_id > 0 ? "visible" : "hidden"}}>
                <div className="d-flex py-1">
                  <div className="p-1 d-flex"><p className="Oval_dot_pup m-1 ms-auto "></p><h6 className="bold px-2">내용물은 어떠셨나요?</h6></div>
                  <div className="px-3">
                    <li className="">
                      <Stars defaultScore={mfScore} callback={(score) => setMfScore(score)}/>
                    </li>
                  </div>
                </div>
                <table className="table basic_sm w-100">
                  <colgroup>
                    <col width="20%"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th className="center">입력</th>
                    <td>
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
                                onChange={async (e) => await setMfContent(e.target.value)}
                                value={mfContent}
                      ></textarea>
                    </td>
                  </tr>
                </table>
              </div>
              <div style={{visibility: currFormedProduct?.bt_request_id > 0 ? "visible" : "hidden"}}>
                <div className="d-flex py-1">
                  <div className="p-1 d-flex"><p className="Oval_dot_pup m-1 ms-auto "></p><h6 className="bold px-2">용기는 어떠셨나요?</h6></div>
                  <div className="px-3">
                    <li className="">
                      <Stars defaultScore={btScore} callback={(score) => setBtScore(score)}/>
                    </li>
                  </div>
                </div>
                <table className="table basic_sm w-100">
                  <colgroup>
                    <col width="20%"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th className="center">입력</th>
                    <td>
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
                                onChange={async (e) => await setBtContent(e.target.value)}
                                value={btContent}
                      ></textarea>
                    </td>
                  </tr>
                </table>
              </div>
              <div style={{visibility: currFormedProduct?.bx_request_id > 0 ? "visible" : "hidden"}}>
                <div className="d-flex py-1">
                  <div className="p-1 d-flex"><p className="Oval_dot_pup m-1 ms-auto "></p><h6 className="bold px-2">단상자는 어떠셨나요?</h6></div>
                  <div className="px-3">
                    <li className="">
                      <Stars defaultScore={bxScore} callback={(score) => setBxScore(score)}/>
                    </li>
                  </div>
                </div>
                <table className="table basic_sm w-100">
                  <colgroup>
                    <col width="20%"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th className="center">입력</th>
                    <td>
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
                                onChange={async (e) => await setBxContent(e.target.value)}
                                value={bxContent}
                      ></textarea>
                    </td>
                  </tr>
                </table>
              </div>
              <div style={{visibility: currFormedProduct?.ds_request_id > 0 ? "visible" : "hidden"}}>
                <div className="d-flex py-1">
                  <div className="p-1 d-flex"><p className="Oval_dot_pup m-1 ms-auto "></p><h6 className="bold px-2">디자인은 어떠셨나요?</h6></div>
                  <div className="px-3">
                    <li className="">
                      <Stars defaultScore={dsScore} callback={(score) => setDsScore(score)}/>
                    </li>
                  </div>
                </div>
                <table className="table basic_sm w-100">
                  <colgroup>
                    <col width="20%"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th className="center">입력</th>
                    <td>
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
                                onChange={async (e) => await setDsContent(e.target.value)}
                                value={dsContent}
                      ></textarea>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="POP_foot">
              <button className="btn px-4 btn_puple" type="button" onClick={() => onNext()}>다음</button>
            </div>
          </>
        }
        {result?.formed_products && currIdx >= result?.formed_products?.length &&
          <>
            <div className="POP_cnt_box_lg" style={{marginBottom: "90px"}}>

              <div className="d-flex justify-content-between border-top border-bottom pb-2 pt-3">
                <div>
                  <h5 className="txt-point"><strong>{result?.project?.name}</strong></h5>
                  <span className="txt-gray txt-sm">(프로젝트 No:{result?.project?.prj_no})</span>
                </div>
                <div className="d-flex">
                  <p className="p-2">
                    {result?.rep_manager?.id > 0 && result?.rep_manager.image_fid && result?.rep_manager.image_fid.length > 0 ?
                      <img
                        src={`${Constants.API_HOST}/common/download?fid=${result?.rep_manager.image_fid}&cd=inline&thumb=400`}
                        style={{width: "44px", height: "44px", objectFit: "cover", marginBottom: "5px", borderRadius: "22px"}}
                      />
                      :
                      <div style={{width: "44px", height: "44px", objectFit: "cover", backgroundColor: "#efefef", marginBottom: "5px", borderRadius: "22px"}}></div>
                    }
                  </p>
                  <p className="pt-2"><span className="h6 bold">{result?.rep_manager?.name}</span><br/>
                    <span className="txt-sm">{result?.rep_manager?.position} | {result?.rep_manager?.task}</span></p>
                </div>
              </div>

              <table className="w-100 border-bottom" style={{backgroundColor: "#efefef"}}>
                <tr>
                  <td style={{width: "50px", height: "30px", textAlign: "center", fontSize: "13px"}}>{progress}%</td>
                  <td style={{height: "5px", paddingRight: "10px"}}>
                    <div style={{width: `100%`, height: "5px", backgroundColor: "darkgray"}}>
                      <div style={{width: `${progress}%`, height: "5px", backgroundColor: "mediumpurple"}}/>
                    </div>
                  </td>
                </tr>
              </table>

              <div>
                <div className="d-flex py-1">
                  <div className="p-1 d-flex"><p className="Oval_dot_pup m-1 ms-auto "></p><h6 className="bold px-2">매니저는 어떠셨나요?</h6></div>
                </div>
                <div style={{paddingLeft: "10px", paddingBottom: "10px"}}>
                  <table>
                    <tr>
                      <td style={{verticalAlign: "middle", paddingTop: "10px", paddingRight: "10px"}}><h6>신뢰도</h6></td>
                      <td><Stars defaultScore={managerScore1} callback={(score) => setManagerScore1(score)} large={true}/></td>
                    </tr>
                    <tr>
                      <td style={{verticalAlign: "middle", paddingTop: "10px", paddingRight: "10px"}}><h6>신속도</h6></td>
                      <td><Stars defaultScore={managerScore2} callback={(score) => setManagerScore2(score)} large={true}/></td>
                    </tr>
                    <tr>
                      <td style={{verticalAlign: "middle", paddingTop: "10px", paddingRight: "10px"}}><h6>친절도</h6></td>
                      <td><Stars defaultScore={managerScore3} callback={(score) => setManagerScore3(score)} large={true}/></td>
                    </tr>
                  </table>
                </div>
                <table className="table basic_sm w-100">
                  <colgroup>
                    <col width="20%"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th className="center">입력</th>
                    <td>
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
                                onChange={async (e) => await setManagerContent(e.target.value)}
                                value={managerContent}
                      ></textarea>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="POP_foot">
              <button className="btn px-4 btn_puple" type="button" onClick={() => onNext(true)}>완료</button>
            </div>
          </>
        }

      </div>
      <div className="pop_bak"></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    reviewPop: state.reviewPop,
  };
}

function mapDispatchToProps(dispatcher) {
  return bindActionCreators(
    {
      openReview: openReview,
    },
    dispatcher
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewModal);
