import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import $ from 'jquery';
import loading_icon from "../image/loading_icon.gif";
import loading_text from "../image/loading_text.png";

function Loading({mounted, renew}) {
  const cont = $('.content-container');

  useEffect(() => {
    setTimeout(() => $('.loading-component').css('visibility', 'visible'), 250);
  }, [renew])

  return cont?.length > 0 && mounted === false ? (
    <div className="d-flex justify-content-center align-items-center loading-component"
         style={{visibility: "hidden", position: "absolute", top: cont.offset()?.top, left: cont.offset()?.left, width: cont.width(), height: cont.height(), flex: 1, flexDirection: "column", backgroundColor: "white", zIndex: 999999999}}>
      <div><img src={loading_icon} style={{height: "60px", width: "auto"}}/></div>
      <div><img src={loading_text} style={{height: "100px", width: "auto"}}/></div>
    </div>
  ) : null;
}

export default withRouter(Loading);
