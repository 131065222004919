import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Redirect, Route} from "react-router-dom";
import AuthContext from "./components/auth-context";
import {GlobalContextProvider} from "./components/global-context";

// NavBar, Home and Footer
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Footer from "./components/Footer";
// 로그인, 회원가입, 아이디&비밀번호 찾기
import JoinIntro from "./components/join/JoinIntro";
import JoinBuyer from "./components/join/JoinBuyer";
import JoinMaker from "./components/join/JoinMaker";
import Login from "./components/login/Login";
import FindId from "./components/login/FindId";
import FindPassword from "./components/login/FindPassword";
// 바이어&제조사 찾기, 상세페이지
import BuyerFind from "./components/buyer/BuyerFind";
import BuyerDetail from "./components/buyer/BuyerHome";
import MakerFind from "./components/maker/MakerFind";
import MakerHome from "./components/maker/MakerHome";
// 공지사항, FAQ, 이용약관, 개인정보보호정책
import Notice from "./components/cscenter/Notice";
import NoticeDetail from "./components/cscenter/NoticeDetail";
import Agreement from "./components/cscenter/Agreement";
import Privacy from "./components/cscenter/Privacy";
// 마이페이지
import MypageHome from "./components/mypage/MypageHome";
import MypageOem from "./components/mypage/MypageOem";
import MypageOemDetail from "./components/mypage/MypageOemDetail";
import Inquiry from "./components/cscenter/Inquiry";
import InquiryDetail from "./components/cscenter/InquiryDetail";
import MypageChangePasswd from "./components/mypage/MypageChangePasswd";
import MypageChangeEmail from "./components/mypage/MypageChangeEmail";
import MypageWithdraw from "./components/mypage/MypageWithdraw";
import MypagePreference from "./components/mypage/MypagePreference";
import MypageAlarm from "./components/mypage/MypageAlarm";
// 프로필
import ProfileMakerDetail from "./components/profile/makerProfile/ProfileMakerDetail";
import ProfileMakerCompany from "./components/profile/makerProfile/ProfileMakerCompany";
import ProfileMakerProduct from "./components/profile/makerProfile/ProfileMakerProduct";
import ProfileMakerProductAdd from "./components/profile/makerProfile/ProfileMakerProductAdd";
import ProfileMakerHome from "./components/profile/makerProfile/ProfileMakerHome";
// 프로젝트
import BuyerProjectHome from "./components/project/buyerProject/BuyerProjectHome";
import BuyerProjectList from "./components/project/buyerProject/BuyerProjectList";
import BuyerProjectProgressHome from "./components/project/buyerProject/BuyerProjectProgressHome";
import BuyerProjectRequest from "./components/project/buyerProject/BuyerProjectRequest";
import BuyerProjectRequestForm from "./components/project/buyerProject/BuyerProjectRequestForm";
import BuyerTimeline from "./components/project/buyerProject/BuyerTimeline";
import MakerProjectHome from "./components/project/makerProject/MakerProjectHome";
import MakerProjectRequestList from "./components/project/makerProject/MakerProjectRequestList";
import MakerProjectRequestDetail from "./components/project/makerProject/MakerProjectRequestDetail";
import MakerProjectProgressList from "./components/project/makerProject/MakerProjectProgressList";
import MakerProjectProgressHome from "./components/project/makerProject/MakerProjectProgressHome";
import MakerProjectProgressRequest from "./components/project/makerProject/MakerProjectProgressRequest";
import MakerProjectInquiry from "./components/project/makerProject/MakerProjectInquiry";
import MakerTimeline from "./components/project/makerProject/MakerTimeline";
import BuyerProjectInquiry from "./components/project/buyerProject/BuyerProjectInquiry";
// 모달
import InquiryModal from "./components/modal/InquiryModal";
import SimpleEstimateModal from "./components/modal/SimpleEstimateModal";
import EstimateModal from "./components/modal/EstimateModal";
import EstimateFormModal from "./components/modal/EstimateFormModal";
import RequestFormulationModal from "./components/modal/RequestFormulationModal";
import RequestDesignModal from "./components/modal/RequestDesignModal";
import RequestBottleModal from "./components/modal/RequestBottleModal";
import DesignResultModal from "./components/modal/DesignResultModal";
import Manufacture from "./components/manufacture/Manufacture";
import ManufactureDetail from "./components/manufacture/ManufactureDetail";
import OemHome from "./components/oem/OemHome";
import OemDetail from "./components/oem/OemDetail";
import SimulatorBottle from "./components/simulator/SimulatorBottle";
import SimulatorBox from "./components/simulator/SimulatorBox";
// 성분 검색
import Search from "./components/search/Search";
import SearchModal from "./components/modal/SearchModal";
// 관리자 페이지
import AdminProjectHome from "./components/admin/project/AdminProjectHome";
import AdminProjectDetail from "./components/admin/project/AdminProjectDetail";
import AdminProjectRequestForm from "./components/admin/project/AdminProjectRequestForm";
import AdminProjectManager from "./components/admin/project/AdminProjectManager";
import AdminProjectConnect from "./components/admin/project/AdminProjectConnect";
import AdminProjectFormedProduct from "./components/admin/project/AdminProjectFormedProduct";
import AdminProjectEstimate from "./components/admin/project/AdminProjectEstimate";
import AdminBuyerMain from "./components/admin/buyer/AdminBuyerMain";
import AdminBuyer from "./components/admin/buyer/AdminBuyer";
import AdminBuyerFavorite from "./components/admin/buyer/AdminBuyerFavorite";
import AdminBuyerVisitLog from "./components/admin/buyer/AdminBuyerVisitLog";
import AdminBuyerActionLog from "./components/admin/buyer/AdminBuyerActionLog";
import AdminMakerMain from "./components/admin/maker/AdminMakerMain";
import AdminMaker from "./components/admin/maker/AdminMaker";
import AdminMakerVisitLog from "./components/admin/maker/AdminMakerVisitLog";
import AdminMakerActionLog from "./components/admin/maker/AdminMakerActionLog";
import AdminMakerFavorite from "./components/admin/maker/AdminMakerFavorite";
import AdminMakerProduct from "./components/admin/maker/AdminMakerProduct";
import ManagerList from "./components/admin/ops/AdminManager";
import AdminManagerVisitLog from "./components/admin/ops/AdminManagerVisitLog";
import AdminManagerReview from "./components/admin/ops/AdminManagerReview";
import AdminManagerActionLog from "./components/admin/ops/AdminManagerActionLog";
import AdminManagerNotice from "./components/admin/ops/AdminManagerNotice";
import AdminConductorVisitLog from "./components/admin/conductor/AdminConductorVisitLog";
import AdminConductorActionLog from "./components/admin/conductor/AdminConductorActionLog";
import AdminTagCode from "./components/admin/ops/AdminTagCode";
import AdminCommonCode from "./components/admin/ops/AdminCommonCode";
import AdminLogin from "./components/admin/login/AdminLogin";
import AdminCsNotice from "./components/admin/cs/AdminCsNotice";
import AdminCsNoticeEdit from "./components/admin/cs/AdminCsNoticeEdit";
import AdminCsNoticeAdd from "./components/admin/cs/AdminCsNoticeAdd";
import AdminCsFaq from "./components/admin/cs/AdminCsFaq";
import AdminCsFaqEdit from "./components/admin/cs/AdminCsFaqEdit";
import AdminCsQna from "./components/admin/cs/AdminCsQna";
import RequestModal from "./components/modal/RequestModal";
import MakerProductView from "./components/maker/MakerProductView";
import RequestBoxModal from "./components/modal/RequestBoxModal";
import ScrollToTop from "./hooks/ScrollToTop";
import AdminDataOemStory from "./components/admin/data/AdminDataOemStory";
import AdminProjectTimeline from "./components/admin/project/AdminProjectTimeline";
import AdminMakerTimeline from "./components/admin/maker/AdminMakerTimeline";
import TimelineAttachModal from "./components/modal/TimelineAttachModal";
import TTTransferModal from "./components/modal/TTTransferModal";
import TimelineTextModal from "./components/modal/TimelineTextModal";
import DeliveryVendorModal from "./components/modal/DeliveryVendorModal";
import TimelineRequestModal from "./components/modal/TimelineRequestModal";
import FixedFooter from "./hooks/FixedFooter";
import ProfileBuyerHome from "./components/profile/buyerProfile/ProfileBuyerHome";
import ProfileBuyerDetail from "./components/profile/buyerProfile/ProfileBuyerDetail";
import ProfileBuyerPhoto from "./components/profile/buyerProfile/ProfileBuyerPhoto";
import Faq from "./components/cscenter/Faq";
import PrivacyModal from "./components/modal/PrivacyModal";
import TermsModal from "./components/modal/TermsModal";
import AdminProjectEstimates from "./components/admin/project/AdminProjectEstimates";
import AdminRequestOem from "./components/admin/operate/AdminRequestOem";
import AdminCsFaqAdd from "./components/admin/cs/AdminCsFaqAdd";
import Guide from "./components/guide/Guide";
import SearchIngredient from "./components/search/SearchIngredient";
import IngredientDetail from "./components/search/IngredientDetail";
import AdminHomeRedirect from "./components/admin/AdminHomeRedirect";
import MyAdminInfo from "./components/admin/mypage/MyAdminInfo";
import BuyerProjectDetail from "./components/project/buyerProject/BuyerProjectDetail";
import BuyerProjectForm from "./components/project/buyerProject/BuyerProjectForm";
import GoogleTranslate from "./components/GoogleTranslate";
import TokenRegister from "./hooks/TokenRegister";
import RouteChangeTracker from "./components/RouteChangeTracker"
import ReviewModal from "./components/modal/ReviewModal";
import AdminSearchIngredient from "./components/admin/data/AdminSearchIngredient";
import AdminSearchIngredientEdit from "./components/admin/data/AdminSearchIngredientEdit";
import AdminSearchIngredientAdd from "./components/admin/data/AdminSearchIngredientAdd";
import AdminManagerProject from "./components/admin/operate/AdminManagerProject";
import AdminSearchItem from "./components/admin/data/AdminSearchItem";
import AdminRecommendItem from "./components/admin/data/AdminRecommendItem";
import ReviewManagerModal from "./components/modal/ReviewManagerModal";
import AdminOperateManagerReview from "./components/admin/operate/AdminOperateManagerReview";
import ReviewMakerModal from "./components/modal/ReviewMakerModal";
import AdminBuyerReview from "./components/admin/buyer/AdminBuyerReview";
import AdminMakerReview from "./components/admin/maker/AdminMakerReview";
import AdminMyReview from "./components/admin/mypage/AdminMyReview";
import AdminMyProjectAlarm from "./components/admin/mypage/AdminMyProjectAlarm";
import AdminHomeBanner from "./components/admin/data/AdminHomeBanner";
import AdminMakerProductAdd from "./components/admin/maker/AdminMakerProductAdd";
import Constants from "./constants/constants";
import Renewal from "./components/Renewal";

function App() {

    const [isUserLoggedIn, setUserLoggedIn] = useState(false);
    const [isAdminLoggedIn, setAdminLoggedIn] = useState(false);

    // 자동로그인 되어있는지 확인
    useEffect(() => {
        const storedUserLoggedInInformation = localStorage.getItem("isUserLoggedIn");
        if (storedUserLoggedInInformation === "yes") {
            setUserLoggedIn(true);
        }
    }, [isUserLoggedIn]);
    useEffect(() => {
        const storedAdminLoggedInInformation = localStorage.getItem("isAdminLoggedIn");
        if (storedAdminLoggedInInformation === "yes") {
            setAdminLoggedIn(true);
        }
    }, [isAdminLoggedIn]);

    // 사용자 : 로그인 핸들러
    const userLoginHandler = (email, token, name, user_type, agree) => {
        if (agree === true) {
            localStorage.setItem("user.session.token", token);
            localStorage.setItem("user.session.email", email);
            localStorage.setItem("user.session.name", name);
            localStorage.setItem("user.session.user_type", user_type);
            localStorage.setItem("isUserLoggedIn", "yes");
            setUserLoggedIn(true);
        } else {
            sessionStorage.setItem("user.session.token", token);
            sessionStorage.setItem("user.session.email", email);
            sessionStorage.setItem("user.session.name", name);
            sessionStorage.setItem("user.session.user_type", user_type);
            sessionStorage.setItem("isUserLoggedIn", "yes");
            setUserLoggedIn(true);
        }
    };

    // 관리자 : 로그인 핸들러
    const adminLoginHandler = (email, token, name, user_type, agree) => {
        if (agree === true) {
            localStorage.setItem("admin.session.token", token);
            localStorage.setItem("admin.session.email", email);
            localStorage.setItem("admin.session.name", name);
            localStorage.setItem("admin.session.user_type", user_type);
            localStorage.setItem("isAdminLoggedIn", "yes");
            setAdminLoggedIn(true);
        } else {
            sessionStorage.setItem("admin.session.token", token);
            sessionStorage.setItem("admin.session.email", email);
            sessionStorage.setItem("admin.session.name", name);
            sessionStorage.setItem("admin.session.user_type", user_type);
            sessionStorage.setItem("isAdminLoggedIn", "yes");
            setAdminLoggedIn(true);
        }
    };

    // 사용자 : 로그아웃 Storage에서 삭제
    const userLogoutHandler = () => {
        localStorage.removeItem("user.session.token");
        localStorage.removeItem("user.session.email");
        localStorage.removeItem("user.session.name");
        localStorage.removeItem("user.session.user_type");
        localStorage.removeItem("isUserLoggedIn");

        sessionStorage.removeItem("user.session.token");
        sessionStorage.removeItem("user.session.email");
        sessionStorage.removeItem("user.session.name");
        sessionStorage.removeItem("user.session.user_type");
        sessionStorage.removeItem("isUserLoggedIn");

        setUserLoggedIn(false);
    };

    // 관리자 : 로그아웃 Storage에서 삭제
    const adminLogoutHandler = () => {
        localStorage.removeItem("admin.session.token");
        localStorage.removeItem("admin.session.email");
        localStorage.removeItem("admin.session.name");
        localStorage.removeItem("admin.session.user_type");
        localStorage.removeItem("isAdminLoggedIn");

        sessionStorage.removeItem("admin.session.token");
        sessionStorage.removeItem("admin.session.email");
        sessionStorage.removeItem("admin.session.name");
        sessionStorage.removeItem("admin.session.user_type");
        sessionStorage.removeItem("isAdminLoggedIn");

        setAdminLoggedIn(false);
    };

    if (Constants.DEV_MODE) {
        return (
            <Router>
                <RouteChangeTracker/>
                <GoogleTranslate/>
                <ScrollToTop/>
                <FixedFooter/>
                <TokenRegister/>
                <GlobalContextProvider>
                    <AuthContext.Provider
                        value={{
                            isUserLoggedIn,
                            isAdminLoggedIn,
                            onUserLogout: userLogoutHandler,
                            onAdminLogout: adminLogoutHandler,
                        }}
                    >
                        {/* 관리자 페이지 */}
                        <Route
                            exact
                            path="/admin/login"
                            render={() => <AdminLogin onLogin={adminLoginHandler}/>}
                        />
                        <Route exact path="/admin" render={() => !localStorage.getItem("admin.session.email") ? <Redirect to="/admin/login"/> : <AdminHomeRedirect/>}/>
                        {/* 관리자 페이지 > 프로젝트 관리 */}
                        <Route exact path="/admin/project/status/:status" render={(props) => !localStorage.getItem("admin.session.email") ? <Redirect to="/admin/login"/> : <AdminProjectHome {...props} key={Date.now()}/>}/>
                        <Route
                          exact
                          path="/admin/project/detail/:id"
                          component={AdminProjectDetail}
                        />
                        <Route
                            exact
                            path="/admin/project/request/form/:id"
                            component={AdminProjectRequestForm}
                        />
                        <Route
                            exact
                            path="/admin/project/request/form/:id/:productId"
                            component={AdminProjectRequestForm}
                        />
                        <Route
                            exact
                            path="/admin/project/manager/:id"
                            component={AdminProjectManager}
                        />
                        <Route path="/admin/project/connect/:id" component={AdminProjectConnect}/>
                        <Route path="/admin/project/timeline/:id" component={AdminProjectTimeline}/>
                        <Route path="/admin/maker/timeline/:id" component={AdminMakerTimeline}/>
                        <Route
                            exact
                            path="/admin/project/estimate/:projectId/:productId"
                            component={AdminProjectEstimate}
                        />
                        <Route
                            exact
                            path="/admin/project/estimates/:projectId/:productId"
                            component={AdminProjectEstimates}
                        />
                        <Route
                            exact
                            path="/admin/project/formed/product/:id"
                            component={AdminProjectFormedProduct}
                        />
                        {/* 관리자 페이지 > CS */}
                        <Route exact path="/admin/cs/notice" render={() => !localStorage.getItem("admin.session.email") ? <Redirect to="/admin/login"/> : <AdminCsNotice/>}/>
                        <Route exact path="/admin/cs/notice/detail/:id" component={AdminCsNoticeEdit}/>
                        <Route exact path="/admin/cs/notice/add" component={AdminCsNoticeAdd}/>
                        <Route exact path="/admin/cs/faq" component={AdminCsFaq}/>
                        <Route exact path="/admin/cs/faq/add" component={AdminCsFaqAdd}/>
                        <Route path="/admin/cs/faq/detail/:id" component={AdminCsFaqEdit}/>
                        <Route exact path="/admin/cs/qna" component={AdminCsQna}/>

                        {/* 관리자 페이지 > 홈노출/검색관리 */}
                        <Route path="/admin/data/oem/story" render={() => !localStorage.getItem("admin.session.email") ? <Redirect to="/admin/login"/> : <AdminDataOemStory/>}/>
                        <Route exact path="/admin/data/search/ingredient" component={AdminSearchIngredient}/>
                        <Route exact path="/admin/data/search/ingredient/detail/:id" component={AdminSearchIngredientEdit}/>
                        <Route exact path="/admin/data/search/ingredient/add" component={AdminSearchIngredientAdd}/>
                        <Route exact path="/admin/data/search/item/:type" component={AdminSearchItem}/>
                        <Route exact path="/admin/data/recommend/item" component={AdminRecommendItem}/>
                        <Route exact path="/admin/data/home/banner" component={AdminHomeBanner}/>

                        {/* 관리자 페이지 > 운영관리 */}
                        <Route exact path="/admin/operate/oem" component={AdminRequestOem}/>
                        <Route exact path="/admin/operate/manager/project" component={AdminManagerProject}/>
                        <Route path="/admin/operate/manager/review" component={AdminOperateManagerReview}/>

                        {/* 관리자 페이지 > 바이어 관리 */}
                        <Route exact path="/admin/buyer" render={() => !localStorage.getItem("admin.session.email") ? <Redirect to="/admin/login"/> : <AdminBuyerMain/>}/>
                        <Route exact path="/admin/buyer/list" component={AdminBuyer}/>
                        <Route exact path="/admin/buyer/list/:id" component={AdminBuyer}/>
                        <Route path="/admin/buyer/log/visit" component={AdminBuyerVisitLog}/>
                        <Route path="/admin/buyer/log/action" component={AdminBuyerActionLog}/>
                        <Route path="/admin/buyer/favorite" component={AdminBuyerFavorite}/>
                        <Route path="/admin/buyer/review" component={AdminBuyerReview}/>

                        {/* Modal : 연결이 안되어 있음 -> "project/product/add"에서 제조의뢰서의 [의뢰하기], [제품생성] 이외에는 태그/코드 관리에서 [추가] Modal */}
                        {/* 관리자 페이지 > 제조사 관리 */}
                        <Route exact path="/admin/maker" render={() => !localStorage.getItem("admin.session.email") ? <Redirect to="/admin/login"/> : <AdminMakerMain/>}/>
                        <Route exact path="/admin/maker/list" component={AdminMaker}/>
                        <Route eact path="/admin/maker/list/:id" component={AdminMaker}/>
                        <Route path="/admin/maker/log/visit" component={AdminMakerVisitLog}/>
                        <Route path="/admin/maker/log/action" component={AdminMakerActionLog}/>
                        <Route path="/admin/maker/favorite" component={AdminMakerFavorite}/>
                        <Route exact path="/admin/maker/product" component={AdminMakerProduct}/>
                        <Route exact path="/admin/maker/product/add" component={AdminMakerProductAdd}/>
                        <Route exact path="/admin/maker/product/add/:id" component={AdminMakerProductAdd}/>
                        <Route path="/admin/maker/review" component={AdminMakerReview}/>

                        {/* 링크 연결이 안되어 있음 -> "제조사 제품 관리"에 링크 추가, list 카드 클릭 후 제품클릭하면 제품상세로 이동 */}
                        {/*<Route path="/admin/maker/product/:id" component={AdminMakerProductDetail}/>*/}

                        {/* 관리자 페이지 > Admin 관리 */}
                        <Route exact path="/admin/ops/home" component={ManagerList}/>
                        <Route path="/admin/ops/log/visit" component={AdminManagerVisitLog}/>
                        <Route path="/admin/ops/manager/review" component={AdminManagerReview}/>
                        <Route path="/admin/ops/log/action" component={AdminManagerActionLog}/>
                        <Route path="/admin/ops/notice" component={AdminManagerNotice}/>
                        <Route path="/admin/ops/tag/code" component={AdminTagCode}/>
                        <Route exact path="/admin/ops/common/code" render={() => !localStorage.getItem("admin.session.email") ? <Redirect to="/admin/login"/> : <AdminCommonCode/>}/>
                        <Route path="/admin/ops/common/code/:topCd" component={AdminCommonCode}/>

                        {/* 관리자 페이지 > 내 정보 */}
                        <Route path="/admin/mypage/myinfo" component={MyAdminInfo}/>
                        <Route path="/admin/mypage/review" component={AdminMyReview}/>
                        <Route path="/admin/mypage/alarm/project" component={AdminMyProjectAlarm}/>

                        {/* 관리자 페이지 > 운영 관리 */}
                        <Route path="/admin/ops/conductor/log/visit" component={AdminConductorVisitLog}/>
                        <Route path="/admin/ops/conductor/log/action" component={AdminConductorActionLog}/>

                        {/* 모달 */}
                        <Route path="/" component={TimelineTextModal}/>
                        <Route path="/" component={TimelineAttachModal}/>
                        <Route path="/" component={TimelineRequestModal}/>
                        <Route path="/" component={TTTransferModal}/>
                        <Route path="/" component={ReviewModal}/>
                        <Route path="/" component={ReviewManagerModal}/>
                        <Route path="/" component={ReviewMakerModal}/>
                        <Route path="/" component={RequestModal}/>
                        <Route path="/" component={EstimateModal}/>
                        <Route path="/" component={InquiryModal}/>
                        <Route path="/" component={DeliveryVendorModal}/>
                        <Route path="/" component={SimpleEstimateModal}/>
                        <Route path="/" component={EstimateFormModal}/>
                        <Route path="/" component={RequestFormulationModal}/>
                        <Route path="/" component={RequestDesignModal}/>
                        <Route path="/" component={RequestBottleModal}/>
                        <Route path="/" component={RequestBoxModal}/>
                        <Route path="/" component={DesignResultModal}/>
                        <Route path="/" component={SearchModal}/>
                        <Route path="/" component={TermsModal}/>
                        <Route path="/" component={PrivacyModal}/>
                        <Route path="/" component={NavBar} onLogout={userLogoutHandler}/>
                        {/* {isUserLoggedIn && (
                          <Route
                            exact
                            path="/"
                            render={() => <Home onUserLogout={logoutHandler} />}
                          />
                        )} */}
                        <Route exact path="/" component={Home}/>
                        <Route
                            exact
                            path="/login"
                            render={() => <Login onLogin={userLoginHandler}/>}
                        />
                        <Route path="/join/intro" component={JoinIntro}/>
                        <Route exact path="/join/buyer" component={JoinBuyer}/>
                        <Route exact path="/join/maker" component={JoinMaker}/>
                        <Route path="/find/id" component={FindId}/>
                        <Route path="/find/password" component={FindPassword}/>
                        <Route path="/buyer/find" component={BuyerFind}/>
                        <Route exact path="/buyer/home" component={BuyerDetail}/>
                        <Route exact path="/buyer/home/:id" component={BuyerDetail}/>
                        <Route path="/maker/find" component={MakerFind}/>
                        <Route exact path="/maker/home" component={MakerHome}/>
                        <Route exact path="/maker/home/:user_id" component={MakerHome}/>
                        <Route path="/maker/product/view" component={MakerProductView}/>
                        {/* 고객센터 */}
                        <Route exact path="/servicepolicy/agreement" component={Agreement}/>
                        <Route exact path="/servicepolicy/privacy" component={Privacy}/>
                        <Route exact path="/cscenter/notice" component={Notice}/>
                        <Route path="/cscenter/notice/:no" component={NoticeDetail}/>
                        <Route path="/cscenter/faq/buyer" component={Faq}/>
                        <Route path="/cscenter/faq/maker" component={Faq}/>
                        <Route exact path="/cscenter/inquiry" component={Inquiry}/>
                        <Route path="/cscenter/inquiry/:no" component={InquiryDetail}/>

                        {/* 마이페이지 */}
                        <Route path="/mypage/home" component={MypageHome}/>
                        <Route exact path="/mypage/oem" component={MypageOem}/>
                        <Route path="/mypage/oem/:no" component={MypageOemDetail}/>
                        <Route path="/mypage/change/passwd" component={MypageChangePasswd}/>
                        <Route path="/mypage/change/email" component={MypageChangeEmail}/>
                        <Route path="/mypage/withdraw" component={MypageWithdraw}/>
                        <Route path="/mypage/preference" component={MypagePreference}/>
                        <Route path="/mypage/alarm" component={MypageAlarm}/>
                        {/* 프로필 */}
                        <Route exact path="/profile/buyer" component={ProfileBuyerHome}/>
                        <Route exact path="/profile/buyer/home" component={ProfileBuyerHome}/>
                        <Route exact path="/profile/buyer/detail" component={ProfileBuyerDetail}/>
                        <Route exact path="/profile/buyer/photo" component={ProfileBuyerPhoto}/>

                        <Route exact path="/profile/maker" component={ProfileMakerHome}/>
                        <Route path="/profile/maker/detail" component={ProfileMakerDetail}/>
                        <Route path="/profile/maker/company" component={ProfileMakerCompany}/>
                        <Route
                            exact
                            path="/profile/maker/product"
                            component={ProfileMakerProduct}
                        />
                        <Route
                            exact
                            path="/profile/maker/product/add"
                            component={ProfileMakerProductAdd}
                        />
                        <Route
                            exact
                            path="/profile/maker/product/add/:id"
                            component={ProfileMakerProductAdd}
                        />
                        {/* 프로젝트 */}
                        <Route exact path="/project/buyer/home" component={BuyerProjectHome}/>
                        <Route
                            exact
                            path="/project/buyer/task/progress"
                            component={BuyerProjectList}
                        />
                        <Route
                            exact
                            path="/project/buyer/task/done"
                            component={BuyerProjectList}
                        />
                        <Route
                            exact
                            path="/project/buyer/task/home/:dv/:id"
                            component={BuyerProjectProgressHome}
                        />
                        {/*<Route*/}
                        {/*  path="/project/buyer/task/detail/:dv/:id"*/}
                        {/*  component={BuyerProjectProgressDetail}*/}
                        {/*/>*/}
                        <Route exact path="/project/buyer/task/timeline/:dv/:id" component={BuyerTimeline}/>
                        <Route exact path="/project/buyer/task/detail/:dv/:id" component={BuyerProjectDetail}/>
                        <Route exact path="/project/buyer/task/form/:dv/:id" component={BuyerProjectForm}/>
                        <Route
                            exact
                            path="/project/buyer/request"
                            component={BuyerProjectRequest}
                        />
                        <Route
                            path="/project/buyer/request/form"
                            component={BuyerProjectRequestForm}
                        />
                        <Route
                            path="/project/buyer/inquiry/:dv/:id"
                            component={BuyerProjectInquiry}
                        />
                        <Route path="/project/maker/home" component={MakerProjectHome}/>
                        <Route
                            exact
                            path="/project/maker/request"
                            component={MakerProjectRequestList}
                        />
                        <Route
                            exact
                            path="/project/maker/request/detail/:id"
                            component={MakerProjectRequestDetail}
                        />
                        <Route
                            exact
                            path="/project/maker/task/progress"
                            component={MakerProjectProgressList}
                        />
                        <Route
                            exact
                            path="/project/maker/task/done"
                            component={MakerProjectProgressList}
                        />
                        <Route
                            path="/project/maker/task/home/:dv/:id"
                            component={MakerProjectProgressHome}
                        />
                        <Route
                            path="/project/maker/task/request/:dv/:id"
                            component={MakerProjectProgressRequest}
                        />
                        <Route
                            path="/project/maker/task/inquiry/:dv/:id"
                            component={MakerProjectInquiry}
                        />
                        <Route path="/project/maker/task/timeline/:dv/:id" component={MakerTimeline}/>
                        {/* 제작 문의 */}
                        <Route exact path="/manufacture" component={Manufacture}/>
                        <Route path="/manufacture/detail" component={ManufactureDetail}/>
                        {/* OEM */}
                        <Route exact path="/oem" component={OemHome}/>
                        <Route exact path="/oem/detail/:id" component={OemDetail}/>
                        {/* 3D 시뮬레이터 */}
                        <Route path="/simulator/bottle" component={SimulatorBottle}/>
                        <Route path="/simulator/box" component={SimulatorBox}/>
                        {/* 성분 제품 검색 */}
                        <Route exact path="/search" component={Search}/>
                        <Route exact path="/search/ingredient" component={SearchIngredient}/>
                        <Route exact path="/search/ingredient/detail" component={IngredientDetail}/>
                        <Route path="/search/:dv" component={Search}/>
                        {/* 이용가이드 */}
                        <Route exact path="/guide/about" component={Guide}/>
                        <Route path="/guide/buyer/:no" component={Guide}/>
                        <Route path="/guide/maker/:no" component={Guide}/>

                        <Route path="/" component={Footer}/>
                    </AuthContext.Provider>
                </GlobalContextProvider>
            </Router>
        );
    } else {
        return (
            <Router>
                <RouteChangeTracker/>
                <GoogleTranslate/>
                <ScrollToTop/>
                <FixedFooter/>
                <TokenRegister/>
                <GlobalContextProvider>
                    <AuthContext.Provider
                        value={{
                            isUserLoggedIn,
                            isAdminLoggedIn,
                            onUserLogout: userLogoutHandler,
                            onAdminLogout: adminLogoutHandler,
                        }}
                    >
                        <Route component={Renewal}/>
                    </AuthContext.Provider>
                </GlobalContextProvider>
            </Router>
        );
    }
}

export default App;
