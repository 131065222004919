import React, {useEffect, useState} from "react";
import thum_comp_01 from "../../image/thum_comp_01.png";
import thum_comp_02 from "../../image/thum_comp_02.png";
import thum_comp_03 from "../../image/thum_comp_03.png";
import last_page_black_48dp from "../../image/last_page_black_48dp.svg";
import navigate_next_black_48dp from "../../image/navigate_next_black_48dp.svg";
import chevron_left_black_24dp from "../../image/chevron_left_black_24dp.svg";
import first_page_black_48dp from "../../image/first_page_black_48dp.svg";
import MypageOemItems from "./MypageOemItems";

import "../../sass/main.scss";
import MypageCard from "./MypageCard";
import APIHelper from "../../helpers/APIHelper";

const MypageOem = (props) => {
  const [rets, setRets] = useState([]);
  const mode = localStorage.getItem("user.session.user_type") || '';

  const gets = async () => {
    const {data: {code, result, message}} = await APIHelper.getForm("data/oem/proposal/gets", {mode});
    setRets(result);
  };

  useEffect(() => {
    const fetch = async () => {
      await gets();
    }
    fetch();
  }, []);

  return (
    <MypageCard>
      <div className="box_round min_h cnt_area pb-5">
        <div className="mx-5 my-4">
          <p className="h3 bold border-bottom py-3">OEM 제작 제안</p>
          <br />
          <MypageOemItems items={rets} mode={mode}/>
          {rets?.length === 0 &&
            <div className="col w-100">
              <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-white">
                등록된 OEM 제작 제안이 없습니다.
              </div>
            </div>
          }
        </div>

        {/*<div className="d-flex justify-content-center py-4 d-none">*/}
        {/*  <ul className="d-flex my-3" style={{ margin: "0 auto" }}>*/}
        {/*    <li className="PAGE">*/}
        {/*      <a href="#" aria-label="Previous">*/}
        {/*        <img src={first_page_black_48dp} style={{ width: "25px" }} />*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*    <li className="PAGE">*/}
        {/*      <a href="#" aria-label="Previous">*/}
        {/*        <img src={chevron_left_black_24dp} style={{ width: "25px" }} />*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*    <li className="PAGE">*/}
        {/*      <a href="#" className="ON">*/}
        {/*        1*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*    <li className="PAGE">*/}
        {/*      <a href="#">2</a>*/}
        {/*    </li>*/}
        {/*    <li className="PAGE">*/}
        {/*      <a href="#">3</a>*/}
        {/*    </li>*/}
        {/*    <li className="PAGE">*/}
        {/*      <a href="#" aria-label="Previous">*/}
        {/*        <img src={navigate_next_black_48dp} style={{ width: "25px" }} />*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*    <li className="PAGE">*/}
        {/*      <a href="#" aria-label="Previous">*/}
        {/*        <img src={last_page_black_48dp} style={{ width: "25px" }} />*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</div>*/}
      </div>
    </MypageCard>
  );
};

export default MypageOem;
