import React, {useState} from "react";
import first_page_black_48dp from "../../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../../image/chevron_left_black_24dp.svg";
import navigate_next_black_48dp from "../../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../../image/last_page_black_48dp.svg";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";

const AdminTagCode = (props) => {
  // 모달 state
  const [open, setOpen] = useState(false);
  let modalInlineStyle;
  if (open) {
    modalInlineStyle = "block";
  } else {
    modalInlineStyle = "none";
  }
  return (
    <AdminCard isOpen={open} click={setOpen}>
      {/* 모달 */}
      <div style={{display: modalInlineStyle}}>
        <div
          className="pop_box02"
          style={{width: "900px", minHeight: "500px"}}
        >
          <div className="POP_title pb-3 border-bottom">
            <h4>
              <strong>Tag 코드 수정</strong>
            </h4>
            <p className="closed">
              <button
                type="button"
                className="btn"
                onClick={() => setOpen(!open)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box_lg" style={{marginBottom: "90px"}}>
            <table className="table basic w-100 my-3">
              <colgroup>
                <col width="15%"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th className="center txt-sm">Code</th>
                <td className="border-top">13101</td>
              </tr>
              <tr>
                <th className="center txt-sm">태그명(한글)</th>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="제주도"
                  />
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">태그명(영문)</th>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Jeju"
                  />
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">사용 유무</th>
                <td className="border-top">
                  <select
                    className="form-select form-select-sm"
                    aria-label="Default select example"
                  >
                    <option selected>사용함</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">순서</th>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="2"
                  />
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">설명</th>
                <td>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                  ></textarea>
                </td>
              </tr>
            </table>
          </div>
          <div className="POP_foot">
            <button
              className="btn btn-sm px-3 btn_gray"
              type="button"
              onClick={() => setOpen(!open)}
            >
              취소
            </button>
            <button className="btn btn-sm px-3 btn-purple" type="button">
              저장
            </button>
          </div>
        </div>
      </div>
      {/* 모달 끝 */}
      <div
        id="body"
        className="row row-cols-2 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <section className="col-4" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">Tag Code List</p>
              <p className="m-1 mb-2"></p>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll mt-2"
              style={{height: "calc(100% - 60px)"}}
            >
              <div className="wid_inline">
                <div className="box_round_5 border my-2  p-3">
                  <div className="d-flex justify-content-between">
                    <p className="h6 mb-2 ">지역 태그 코드 (시군구)</p>
                    <p className="txt-md">13000</p>
                  </div>
                  <p className="text-start">태그 개수 : 사용개수 / 전체개수</p>
                </div>

                <div className="box_round_5 border_purple my-2 p-3">
                  <div className="d-flex justify-content-between">
                    <p className="h6 mb-2 ">바이어 태그 코드 </p>
                    <p className="txt-md">14000</p>
                  </div>
                  <p className="text-start">태그 개수 : 사용개수 / 전체개수</p>
                </div>

                <div className="box_round_5 border my-2 p-3">
                  <div className="d-flex justify-content-between">
                    <p className="h6 mb-2 ">제조사 태그 코드</p>
                    <p className="txt-md">부모코드</p>
                  </div>
                  <p className="text-start">태그 개수 : 18 / 30</p>
                </div>

                <div className="box_round_5 border my-2 p-3">
                  <div className="d-flex justify-content-between">
                    <p className="h6 mb-2 ">지역 태그 코드 (시군구)</p>
                    <p className="txt-md">13000</p>
                  </div>
                  <p className="text-start">태그 개수 : 18 / 30</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="col-8" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">바이어 태그 내역</p>
              <p className="m-1 mb-2">
                <button
                  onClick={() => setOpen(!open)}
                  type="button"
                  className="btn btn-purple txt-sm py-1 px-3"
                >
                  추가
                </button>
              </p>
            </div>

            {/*<div className="d-flex justify-content-between m-3 mx-4">*/}
            {/*  <p></p>*/}
            {/*  <div className="input-group input-group-sm w-22">*/}
            {/*    <label className="input-group-text" for="inputGroupSelect01">*/}
            {/*      조회구분*/}
            {/*    </label>*/}
            {/*    <select className="form-select" id="inputGroupSelect01">*/}
            {/*      <option selected>전체</option>*/}
            {/*      <option value="1">One</option>*/}
            {/*      <option value="2">Two</option>*/}
            {/*      <option value="3">Three</option>*/}
            {/*    </select>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div
              className="px-4 wid_scroll scroll_y custom_scroll mt-4"
              style={{height: "calc(100% - 190px)"}}
            >
              <article className="wid_inline">
                <table className="table basic_list2 w-100">
                  <colgroup>
                    <col width="55px"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="18%"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>No</th>
                    <th>Code</th>
                    <th>태그명(한글)</th>
                    <th>태그명(영문)</th>
                    <th>설명</th>
                    <th>사용 유무</th>
                    <th>순서</th>
                    <th>비고</th>
                  </tr>
                  <tr>
                    <td>19</td>
                    <td>13101</td>
                    <td>서울/경기</td>
                    <td>Seoul/Ky</td>
                    <td>서울 경기 묶어서 표현 이렇게 표현.</td>
                    <td>
                      <div className="">
                        <input
                          className="form-check-input mt-1 text-center"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault2"
                        />
                        <label
                          className="form-check-label  px-1"
                          for="flexCheckDefault2"
                        >
                          사용함
                        </label>
                      </div>
                    </td>
                    <td>1</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn_outline_puple btn-sm"
                      >
                        수정
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>19</td>
                    <td>13102</td>
                    <td>제주도</td>
                    <td>Jeju</td>
                    <td>제주도는 법 개정으로 사용 안함</td>
                    <td>
                      <div className="">
                        <input
                          className="form-check-input mt-1"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault4"
                        />
                        <label
                          className="form-check-label  px-1"
                          for="flexCheckDefault4"
                        >
                          사용함
                        </label>
                      </div>
                    </td>
                    <td>1</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn_outline_puple btn-sm"
                      >
                        수정
                      </button>
                    </td>
                  </tr>
                </table>
              </article>
            </div>

            <div className="d-flex justify-content-center py-4 d-none">
              <ul className="d-flex my-3" style={{margin: "0 auto"}}>
                <li className="PAGE">
                  <a href="src/components/admin/ops/AdminTagCode#" aria-label="Previous">
                    <img
                      src={first_page_black_48dp}
                      style={{width: "25px"}}
                    />
                  </a>
                </li>
                <li className="PAGE">
                  <a href="src/components/admin/ops/AdminTagCode#" aria-label="Previous">
                    <img
                      src={chevron_left_black_24dp}
                      style={{width: "25px"}}
                    />
                  </a>
                </li>
                <li className="PAGE">
                  <a href="src/components/admin/ops/AdminTagCode#" className="ON">
                    1
                  </a>
                </li>
                <li className="PAGE">
                  <a href="src/components/admin/ops/AdminTagCode#">2</a>
                </li>
                <li className="PAGE">
                  <a href="src/components/admin/ops/AdminTagCode#">3</a>
                </li>
                <li className="PAGE">
                  <a href="src/components/admin/ops/AdminTagCode#" aria-label="Previous">
                    <img
                      src={navigate_next_black_48dp}
                      style={{width: "25px"}}
                    />
                  </a>
                </li>
                <li className="PAGE">
                  <a href="src/components/admin/ops/AdminTagCode#" aria-label="Previous">
                    <img src={last_page_black_48dp} style={{width: "25px"}}/>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminTagCode;
