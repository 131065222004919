import React, {useState} from "react";
import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import CommonLib from "../../libraries/CommonLib";
const MakerPw = (props) => {
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [name, setName] = useState('');

  const submit = async () => {
    const {data: {code, result, message}} = await APIHelper.getForm("user/find/passwd", {type: "MAKER", email, name, companyName});
    await CommonLib.jalert(message);
  }

  return (
    <>
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          onChange={async (e) => await setEmail(e.target.value)}
          value={email}
          placeholder="이메일(ID)"
        />
      </div>
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          onChange={async (e) => await setCompanyName(e.target.value)}
          value={companyName}
          placeholder="회사명"
        />
      </div>
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          onChange={async (e) => await setName(e.target.value)}
          value={name}
          placeholder="이름"
        />
      </div>

      <div className="mb-3">
        <button type="button" className="btn btn_puple w-100 py-2 bold" onClick={() => submit()}>
          비밀번호 찾기
        </button>
      </div>
    </>
  );
};

export default MakerPw;
