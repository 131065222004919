import React, {useEffect, useState} from "react";
import open_in_new from "../../../image/open_in_new.svg";
import close_b from "../../../image/close_b.svg";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import {Link, useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import openRequestFormulation from "../../../actions/openRequestFormulation";
import openRequestDesign from "../../../actions/openRequestDesign";
import openRequestBottle from "../../../actions/openRequestBottle";
import openRequestBox from "../../../actions/openRequestBox";
import openTimelineAttach from "../../../actions/openTimelineAttach";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";
import Constants from "../../../constants/constants";
import CurrencyInput from "react-currency-input-field";

const AdminProjectEstimate = (props) => {

  const projectId = props?.match?.params?.projectId;
  const productId = props?.match?.params?.productId;

  const history = useHistory();

  // modal액션으로 카테고리(단상자, 디자인...) 보내기
  const dispatch = useDispatch();

  const openRequestForm = (projectId, projectProductId, itemCd) => {
    const obj = {projectId, projectProductId, renew: new Date().getTime()};
    if (itemCd === 'MF') dispatch(openRequestFormulation("open", obj));
    else if (itemCd === 'DS') dispatch(openRequestDesign("open", obj));
    else if (itemCd === 'BT') dispatch(openRequestBottle("open", obj));
    else if (itemCd === 'BX') dispatch(openRequestBox("open", obj));
  }

  const downloadTimelineAttachModal = (project_id, project_product_id, project_maker_request_id, title = '간단 견적서', formType = '간단 견적서 작성') => {
    const obj = {
      projectId: project_id,
      projectProductId: project_product_id,
      projectMakerRequestId: project_maker_request_id,
      title,
      renew: new Date().getTime(),
      readOnly: true,
      formType
    };
    dispatch(openTimelineAttach("open", obj));
  }

  useEffect(() => {
    const fetch = async () => {
      await loadFormedProducts();
    }
    fetch();
  }, []);

  const [formedProducts, setFormedProducts] = useState([]);

  const loadFormedProducts = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("project/formed/product/gets", {projectId: projectId, projectProductId: productId});
      setFormedProducts(result);

      const fps = [];
      result.map(formedProduct => {
        if (fps.filter(_fp => _fp.id === formedProduct.id).length === 0) fps.push({id: formedProduct.id, name: formedProduct.name, product_name: formedProduct.name, types: []});
        const fp = fps.filter(_fp => _fp.id === formedProduct.id)[0];

        if (formedProduct?.mf_request_id > 0) fp.types.push({type: 'MF', item_name: '내용물', cnt: 0, unit_cost: 0});
        if (formedProduct?.ds_request_id > 0) fp.types.push({type: 'DS', item_name: '디자인', cnt: 0, unit_cost: 0});
        if (formedProduct?.bt_request_id > 0) fp.types.push({type: 'BT', item_name: '용기', cnt: 0, unit_cost: 0});
        if (formedProduct?.bx_request_id > 0) fp.types.push({type: 'BX', item_name: '단상자', cnt: 0, unit_cost: 0});
      });
      setSelectedFormProducts(fps);

      return resolve(true);
    });
  }


  const [selectedFormedProducts, setSelectedFormProducts] = useState([]);

  const [estimateNo, setEstimateNo] = useState('');
  const [id, setId] = useState(props?.match?.params?.id || 0);
  const [estimateName, setEstimateName] = useState('');
  const [shippingCost, setShippingCost] = useState(0);
  const [tax, setTax] = useState(0);
  const [supplyCost, setSupplyCost] = useState(0);
  const [vat, setVat] = useState(0);
  const [sumCost, setSumCost] = useState(0);
  const [etc, setEtc] = useState('');

  const [tcShipment, setTcShipment] = useState('');
  const [tcPayment, setTcPayment] = useState('100% T/T in advance');
  const [tcIncoterms, setTcIncoterms] = useState('FOB INCHEON');
  const [tcOrigin, setTcOrigin] = useState('Republic of Korea');
  const [tcPacking, setTcPacking] = useState('Standard Export Packing');
  const [tcLeadTime, setTcLeadTime] = useState('Within 10 business days from your official order confirmation');
  const [tcOfferValidity, setTcOfferValidity] = useState('14 days from the date of offer');

  const sortBy = (a, b) => {
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;
    return 0;
  }

  const toggleFormedProducts = (formedProduct, type) => {
    const fps = [...selectedFormedProducts];

    if (fps.filter(_fp => _fp.id === formedProduct.id).length === 0) fps.push({id: formedProduct.id, name: formedProduct.name, product_name: formedProduct.name, types: []});
    const fp = fps.filter(_fp => _fp.id === formedProduct.id)[0];

    const name = type === 'MF' ? '내용물' : type === 'DS' ? '디자인' : type === 'BT' ? '용기' : type === 'BX' ? '단상자' : '';
    if (fp.types.filter(i => i.type === type).length === 0) fp.types.push({type, name, item_name: name, cnt: 0, unit_cost: 0});
    else fp.types.splice(fp.types.indexOf(fp.types.filter(i => i.type === type)[0]), 1);

    if (fp.types.length === 0) fps.splice(fps.indexOf(fps.filter(i => i.id === fp.id)[0]), 1);
    fps.sort(sortBy);

    setSelectedFormProducts(fps);
    calculate(fps);
  }

  const toggleFormedProduct = (formedProduct) => {
    const fps = [...selectedFormedProducts];

    if (fps.filter(_fp => _fp.id === formedProduct.id).length > 0) fps.splice(fps.indexOf(fps.filter(_fp => _fp.id === formedProduct.id)[0]), 1);
    fps.sort(sortBy);

    setSelectedFormProducts(fps);
    calculate(fps);
  }

  const calculate = (fps, _shippingCost, _tax) => {
    if (_shippingCost === undefined) _shippingCost = shippingCost;
    if (_tax === undefined) _tax = tax;

    let supplyCost = CommonLib.parseFloat(_shippingCost) + CommonLib.parseFloat(_tax);
    fps.map(fp => {
      fp.types.map(item => {
        supplyCost += CommonLib.parseFloat(item.cnt) * CommonLib.parseFloat(item.unit_cost);
      });
    });
    setSupplyCost(supplyCost);
    setVat(supplyCost * 0.1);
    setSumCost(supplyCost * 1.1);
  }

  const loadEstimate = async (id = 0) => {
    if (id === 0) return;
    try {
      const {data: {code: retCode, result, message}} = await APIHelper.getForm("project/estimate/get", {id});

      setEstimateName(result.subject || '');
      setShippingCost(result.shipping_cost || 0);
      setTax(result.tax || 0);
      setSupplyCost(result.supply_cost || 0);
      setVat(result.vat || 0);
      setSumCost(result.sum_cost || 0);
      setTcShipment(result.tc_shipment || '');
      setTcPayment(result.tc_payment || '');
      setTcIncoterms(result.tc_incoterms || '');
      setTcOrigin(result.tc_origin || '');
      setTcPacking(result.tc_packing || '');
      setTcLeadTime(result.tc_lead_time || '');
      setTcOfferValidity(result.tc_offer_validity || '');
      setEtc(result.etc || '');
      setEstimateNo(result.estimate_no || '');

      const fps = [];
      for (const [key, product] of Object.entries(result.products)) {
        const fp = {id: product.formed_product_id, name: product.formed_product.name, product_name: product.product_name, types: []};
        for (const [key, item] of Object.entries(product.items)) {
          fp.types.push({type: item.item_cd, item_name: item.item_name, cnt: item.cnt, unit_cost: item.unit_cost});
        }
        fps.push(fp);
      }
      setSelectedFormProducts(fps);
    } catch (err) {
      console.log(err);
    }
  }

  const saveEstimate = async () => {
    if (estimateName === '') {
      await CommonLib.jalert('견적서 제목을 입력해 주세요.');
      return;
    }
    try {
      const {data: {code: retCode, result, message}} = await APIHelper.postForm("project/estimate/save", {
        id,
        projectId,
        productId,
        estimateName,
        shippingCost,
        tax,
        supplyCost,
        vat,
        sumCost,
        tcShipment,
        tcPayment,
        tcIncoterms,
        tcOrigin,
        tcPacking,
        tcLeadTime,
        tcOfferValidity,
        etc,
        selectedFormedProducts: JSON.stringify(selectedFormedProducts)
      });
      await loadEstimate(result);
      await CommonLib.jalert(message);
      history.push(`/admin/project/estimates/${projectId}/${productId}`);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <AdminCard>
      <div
        className="row row-cols-2 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <section className="col" style={{height: "98%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">제품선택</p>
              <p className="m-1 mb-2">
                <button
                  type="button"
                  className="btn btn_gray txt-sm py-1 px-3"
                  style={{marginRight: "5px"}}
                  onClick={async () => history.push(`/admin/project/formed/product/${projectId}`)}
                >
                  돌아가기
                </button>
              </p>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll mt-2"
              style={{height: "calc(100% - 60px)"}}
            >
              <div className="row row-cols-2 wid_inline gx-2 ">
                {formedProducts && formedProducts.map((formedProduct, key) => {
                  return (
                    <div className="col">
                      <article key={key} className="border my-2">
                        <div style={{borderStyle: "solid", borderWidth: selectedFormedProducts.filter(fp => fp.id === formedProduct.id).length > 0 ? "3px" : "0px", borderColor: "lightblue"}}>
                          <div className="bg-lgray d-flex border-bottom p-2">
                            <p>
                              <img
                                src={open_in_new}
                                style={{width: "25px", marginBottom: "2px"}}
                              />
                            </p>
                            <p className="px-3 bold">{formedProduct.name}</p>
                            <p className="txt-sm txt-gray ms-auto">
                            </p>
                          </div>

                          {formedProduct.mf_request_id > 0 &&
                            <div style={{borderStyle: "solid", borderWidth: selectedFormedProducts.filter(fp => fp.id === formedProduct.id && fp.types.filter(i => i.type === 'MF').length > 0).length > 0 ? "3px" : "0px", borderColor: "cadetblue"}}
                                 onClick={() => toggleFormedProducts(formedProduct, "MF")}
                            >
                              <div className="p-2 border-bottom">
                                <div className="d-flex">
                                  <p className="btn_oval_sm mb-1 mx-1">
                                    {formedProduct.mf_maker.rep_image_fid && formedProduct.mf_maker.rep_image_fid.length > 0 ?
                                      <img
                                        src={`${Constants.API_HOST}/common/download?fid=${formedProduct.mf_maker.rep_image_fid}&cd=inline&thumb=400`}
                                        style={{width: "45px", height: "45px", objectFit: "cover"}}
                                      />
                                      :
                                      <div style={{width: "45px", height: "45px", backgroundColor: "#efefef", borderRadius: "65px"}}></div>}
                                  </p>
                                  <p className="h6 m-1">
                                    {formedProduct.mf_maker.company_name}
                                    <br/>
                                    <span className="txt-sm">위치 : {formedProduct.mf_maker.company_addr.length > 0 ? formedProduct.mf_maker.company_addr.split(' ')[0] : ''}</span>
                                  </p>
                                </div>
                                <div className="text-start mx-2">
                                  <p className="txt-sm">
                                    요청번호 : {formedProduct?.mf_request_form?.request_no}
                                    <br/>
                                    의뢰타입 :{" "}
                                    <span className="txt-point txt-sm">
                                      내용물
                                    </span>
                                    <br/>
                                  </p>
                                </div>

                                <p className="text-center">
                                  <button
                                    type="button"
                                    className="btn w-48 btn-sm btn-ligray p-1 my-1"
                                    style={{minWidth: "82px"}}
                                    onClick={(e) => {
                                      downloadTimelineAttachModal(formedProduct?.project_id, formedProduct?.project_product_id, formedProduct?.mf_request_id);
                                      e.stopPropagation();
                                    }}
                                  >
                                    견적서
                                  </button>
                                  <button
                                    type="button"
                                    className="btn w-48 btn-sm btn-purple p-1 my-1"
                                    style={{minWidth: "82px"}}
                                    onClick={(e) => {
                                      openRequestForm(formedProduct?.project_id, formedProduct?.project_product_id, 'MF');
                                      e.stopPropagation();
                                    }}
                                  >
                                    의뢰서확인
                                  </button>
                                </p>
                              </div>
                            </div>
                          }

                          {formedProduct.ds_request_id > 0 &&
                            <div style={{borderStyle: "solid", borderWidth: selectedFormedProducts.filter(fp => fp.id === formedProduct.id && fp.types.filter(i => i.type === 'DS').length > 0).length > 0 ? "3px" : "0px", borderColor: "cadetblue"}}
                                 onClick={(e) => {
                                   toggleFormedProducts(formedProduct, "DS");
                                   e.stopPropagation();
                                 }}
                            >
                              <div className="p-2 border-bottom">
                                <div className="d-flex">
                                  <p className="btn_oval_sm mb-1 mx-1">
                                    {formedProduct.ds_maker.rep_image_fid && formedProduct.ds_maker.rep_image_fid.length > 0 ?
                                      <img
                                        src={`${Constants.API_HOST}/common/download?fid=${formedProduct.ds_maker.rep_image_fid}&cd=inline&thumb=400`}
                                        style={{width: "45px", height: "45px", objectFit: "cover"}}
                                      />
                                      :
                                      <div style={{width: "45px", height: "45px", backgroundColor: "#efefef", borderRadius: "65px"}}></div>}
                                  </p>
                                  <p className="h6 m-1">
                                    {formedProduct.ds_maker.company_name}
                                    <br/>
                                    <span className="txt-sm">위치 : {formedProduct.ds_maker.company_addr.length > 0 ? formedProduct.ds_maker.company_addr.split(' ')[0] : ''}</span>
                                  </p>
                                </div>
                                <div className="text-start mx-2">
                                  <p className="txt-sm">
                                    요청번호 : {formedProduct?.ds_request_form?.request_no}
                                    <br/>
                                    의뢰타입 :{" "}
                                    <span className="txt-point txt-sm">
                                      디자인
                                    </span>
                                    <br/>
                                  </p>
                                </div>

                                <p className="text-center">
                                  <button
                                    type="button"
                                    className="btn w-48 btn-sm btn-ligray p-1 my-1"
                                    style={{minWidth: "82px"}}
                                    onClick={(e) => {
                                      downloadTimelineAttachModal(formedProduct?.project_id, formedProduct?.project_product_id, formedProduct?.ds_request_id);
                                      e.stopPropagation();
                                    }}
                                  >
                                    견적서
                                  </button>
                                  <button
                                    type="button"
                                    className="btn w-48 btn-sm btn-purple p-1 my-1"
                                    style={{minWidth: "82px"}}
                                    onClick={(e) => {
                                      openRequestForm(formedProduct?.project_id, formedProduct?.project_product_id, 'DS');
                                      e.stopPropagation();
                                    }}
                                  >
                                    의뢰서확인
                                  </button>
                                </p>
                              </div>
                            </div>
                          }

                          {formedProduct.bt_request_id > 0 &&
                            <div style={{borderStyle: "solid", borderWidth: selectedFormedProducts.filter(fp => fp.id === formedProduct.id && fp.types.filter(i => i.type === 'BT').length > 0).length > 0 ? "3px" : "0px", borderColor: "cadetblue"}}
                                 onClick={(e) => {
                                   toggleFormedProducts(formedProduct, "BT");
                                   e.stopPropagation();
                                 }}
                            >
                              <div className="p-2 border-bottom">
                                <div className="d-flex">
                                  <p className="btn_oval_sm mb-1 mx-1">
                                    {formedProduct.bt_maker.rep_image_fid && formedProduct.bt_maker.rep_image_fid.length > 0 ?
                                      <img
                                        src={`${Constants.API_HOST}/common/download?fid=${formedProduct.bt_maker.rep_image_fid}&cd=inline&thumb=400`}
                                        style={{width: "45px", height: "45px", objectFit: "cover"}}
                                      />
                                      :
                                      <div style={{width: "45px", height: "45px", backgroundColor: "#efefef", borderRadius: "65px"}}></div>}
                                  </p>
                                  <p className="h6 m-1">
                                    {formedProduct.bt_maker.company_name}
                                    <br/>
                                    <span className="txt-sm">위치 : {formedProduct.bt_maker.company_addr.length > 0 ? formedProduct.bt_maker.company_addr.split(' ')[0] : ''}</span>
                                  </p>
                                </div>
                                <div className="text-start mx-2">
                                  <p className="txt-sm">
                                    요청번호 : {formedProduct?.bt_request_form?.request_no}
                                    <br/>
                                    의뢰타입 :{" "}
                                    <span className="txt-point txt-sm">
                                      용기
                                    </span>
                                    <br/>
                                  </p>
                                </div>

                                <p className="text-center">
                                  <button
                                    type="button"
                                    className="btn w-48 btn-sm btn-ligray p-1 my-1"
                                    style={{minWidth: "82px"}}
                                    onClick={(e) => {
                                      downloadTimelineAttachModal(formedProduct?.project_id, formedProduct?.project_product_id, formedProduct?.bt_request_id);
                                      e.stopPropagation();
                                    }}
                                  >
                                    견적서
                                  </button>
                                  <button
                                    type="button"
                                    className="btn w-48 btn-sm btn-purple p-1 my-1"
                                    style={{minWidth: "82px"}}
                                    onClick={(e) => {
                                      openRequestForm(formedProduct?.project_id, formedProduct?.project_product_id, 'BT');
                                      e.stopPropagation();
                                    }}
                                  >
                                    의뢰서확인
                                  </button>
                                </p>
                              </div>
                            </div>
                          }

                          {formedProduct.bx_request_id > 0 &&
                            <div style={{borderStyle: "solid", borderWidth: selectedFormedProducts.filter(fp => fp.id === formedProduct.id && fp.types.filter(i => i.type === 'BX').length > 0).length > 0 ? "3px" : "0px", borderColor: "cadetblue"}}
                                 onClick={(e) => {
                                   toggleFormedProducts(formedProduct, "BX");
                                   e.stopPropagation();
                                 }}
                            >
                              <div className="p-2 border-bottom">
                                <div className="d-flex">
                                  <p className="btn_oval_sm mb-1 mx-1">
                                    {formedProduct.bx_maker.rep_image_fid && formedProduct.bx_maker.rep_image_fid.length > 0 ?
                                      <img
                                        src={`${Constants.API_HOST}/common/download?fid=${formedProduct.bx_maker.rep_image_fid}&cd=inline&thumb=400`}
                                        style={{width: "45px", height: "45px", objectFit: "cover"}}
                                      />
                                      :
                                      <div style={{width: "45px", height: "45px", backgroundColor: "#efefef", borderRadius: "65px"}}></div>}
                                  </p>
                                  <p className="h6 m-1">
                                    {formedProduct.bx_maker.company_name}
                                    <br/>
                                    <span className="txt-sm">위치 : {formedProduct.bx_maker.company_addr.length > 0 ? formedProduct.bx_maker.company_addr.split(' ')[0] : ''}</span>
                                  </p>
                                </div>
                                <div className="text-start mx-2">
                                  <p className="txt-sm">
                                    요청번호 : {formedProduct?.bx_request_form?.request_no}
                                    <br/>
                                    의뢰타입 :{" "}
                                    <span className="txt-point txt-sm">
                                      단상자
                                    </span>
                                    <br/>
                                  </p>
                                </div>

                                <p className="text-center">
                                  <button
                                    type="button"
                                    className="btn w-48 btn-sm btn-ligray p-1 my-1"
                                    style={{minWidth: "82px"}}
                                    onClick={(e) => {
                                      downloadTimelineAttachModal(formedProduct?.project_id, formedProduct?.project_product_id, formedProduct?.bx_request_id);
                                      e.stopPropagation();
                                    }}
                                  >
                                    견적서
                                  </button>
                                  <button
                                    type="button"
                                    className="btn w-48 btn-sm btn-purple p-1 my-1"
                                    style={{minWidth: "82px"}}
                                    onClick={(e) => {
                                      openRequestForm(formedProduct?.project_id, formedProduct?.project_product_id, 'BX');
                                      e.stopPropagation();
                                    }}
                                  >
                                    의뢰서확인
                                  </button>
                                </p>
                              </div>
                            </div>
                          }
                        </div>
                      </article>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <section className="col" style={{height: "98%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">견적서 작성</p>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll mt-4"
              style={{height: "calc(100% - 60px)"}}
            >
              <div className="wid_inline">
                <div className="pb-3">
                  <p className="bold py-2"> 1. 견적서 코드</p>
                  <div className="d-flex flex-wrap bg-lpurple border-top border-bottom p-2">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="E1-2021-00001 (자동생성)"
                      readOnly={true}
                      value={estimateNo}
                    />
                  </div>
                </div>

                <div className="pb-3">
                  <p className="bold py-2">
                    2. 견적서 제목 (※ 내부 관리를 위한 제목 입니다.)
                  </p>
                  <div className="d-flex flex-wrap bg-lpurple border-top border-bottom p-2">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder=" "
                      onChange={async (e) => await setEstimateName(e.target.value)}
                      value={estimateName}
                    />
                  </div>
                </div>

                <div className="pb-2">
                  <p className="bold">3. 제품 </p>
                </div>

                <table className="table estimate w-100">
                  <colgroup>
                    <col width="16%"/>
                    <col width="*"/>
                    <col width="12%"/>
                    <col width="12%"/>
                    <col width="16%"/>
                    <col width="25px"/>
                  </colgroup>
                  <tr>
                    <th className="center">제품</th>
                    <th className="center">항목</th>
                    <th className="center">수량</th>
                    <th className="center">단가</th>
                    <th className="center">금액</th>
                    <th className="center"></th>
                  </tr>
                  {selectedFormedProducts.map(fp => {
                    return (
                      <>
                        <tr>
                          <td className="p-1">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder={fp.product_name}
                              onChange={async (e) => {
                                const fps = [...selectedFormedProducts];
                                const __fp = fps.filter(_fp => _fp.id === fp.id)[0];
                                __fp.product_name = e.target.value;
                                await setSelectedFormProducts(fps);
                              }}
                              value={fp.product_name}
                            />
                          </td>
                          <td className="p-1">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder=" "
                            />
                          </td>
                          <td className="p-1">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder=" "
                            />
                          </td>
                          <td className="p-1">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder=" "
                            />
                          </td>
                          <td className="p-1">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder=" "
                            />
                          </td>
                          <td className="p-0">
                            <button type="button" className="btn p-0 mb-1" onClick={() => toggleFormedProduct(fp)}>
                              <img src={close_b} style={{width: "20px"}}/>
                            </button>
                          </td>
                        </tr>
                        {fp.types.map(item => {
                          const type = item.type;

                          const fps = [...selectedFormedProducts];
                          const __fp = fps.filter(_fp => _fp.id === fp.id)[0];
                          const __item = __fp.types.filter(__item => __item.type === item.type)[0];

                          return (
                            <tr>
                              <td className="p-1">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  placeholder=" "
                                />
                              </td>
                              <td className="p-1">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  placeholder={item.item_name}
                                  onChange={async (e) => {
                                    __item.item_name = e.target.value;
                                    await setSelectedFormProducts(fps);
                                  }}
                                  value={__item.item_name}
                                />
                              </td>
                              <td className="p-1">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  placeholder=""
                                  onChange={async (e) => {
                                    __item.cnt = e.target.value;
                                    await setSelectedFormProducts(fps);
                                    calculate(fps);
                                  }}
                                  value={__item.cnt}
                                />
                              </td>
                              <td className="p-1">
                                <CurrencyInput
                                  decimalsLimit={4}
                                  value={__item.unit_cost}
                                  onValueChange={async (value, name) => {
                                    __item.unit_cost = value;
                                    await setSelectedFormProducts(fps);
                                    calculate(fps);
                                  }}
                                  className="form-control form-control-sm"
                                />
                              </td>
                              <td className="p-1">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  placeholder=" "
                                  value={CommonLib.floatWithCommas(CommonLib.parseFloat(item.cnt) * CommonLib.parseFloat(item.unit_cost))}
                                  readOnly={true}
                                />
                              </td>
                              <td className="p-0">
                                <button type="button" className="btn p-0 mb-1" onClick={() => toggleFormedProducts(fp, type)}>
                                  <img src={close_b} style={{width: "20px"}}/>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )
                  })}
                </table>
                {selectedFormedProducts.length === 0 &&
                  <div className="col w-100 mb-3">
                    <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-lblue bold">
                      제품이 없습니다.
                    </div>
                  </div>
                }

                <div className="pb-2 my-2">
                  <p className="bold">4. 기타 (매니저 직접 입력) </p>
                </div>

                <table className="table estimate w-100">
                  <colgroup>
                    <col width="16%"/>
                    <col width="*"/>
                    <col width="12%"/>
                    <col width="12%"/>
                    <col width="16%"/>
                    <col width="25px"/>
                  </colgroup>

                  <tr>
                    <th className="center">운송비</th>
                    <td className="p-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder=" "
                      />
                    </td>
                    <td className="p-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder=" "
                      />
                    </td>
                    <td className="p-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder=" "
                      />
                    </td>
                    <td className="p-1">
                      <CurrencyInput
                        decimalsLimit={4}
                        className="form-control form-control-sm"
                        onValueChange={async (value, name) => {
                          await setShippingCost(value);
                          calculate(selectedFormedProducts, value, undefined);
                        }}
                        value={shippingCost}
                      />
                    </td>
                    <td className="p-1">
                    </td>
                  </tr>
                  <tr>
                    <th className="center">통관비</th>
                    <td className="p-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder=" "
                      />
                    </td>
                    <td className="p-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder=" "
                      />
                    </td>
                    <td className="p-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder=" "
                      />
                    </td>
                    <td className="p-1">
                      <CurrencyInput
                        decimalsLimit={4}
                        className="form-control form-control-sm"
                        onValueChange={async (value, name) => {
                          await setTax(value);
                          calculate(selectedFormedProducts, undefined, value);
                        }}
                        value={tax}
                      />
                    </td>
                    <td className="p-1">
                    </td>
                  </tr>
                </table>

                <div className="d-flex  justify-content-between" style={{width: "calc(100% - 25px)"}}>
                  <p></p>
                  <div>
                    <dl className="box_price d-flex p-0 m-1">
                      <dt>공급가액($)</dt>
                      <dd>${CommonLib.floatWithCommas(supplyCost)}</dd>
                    </dl>
                    <dl className="box_price d-flex p-0 m-1">
                      <dt>VAT($)</dt>
                      <dd>${CommonLib.floatWithCommas(vat)}</dd>
                    </dl>
                    <dl className="box_price d-flex p-0 m-1">
                      <dt>합계($)</dt>
                      <dd>${CommonLib.floatWithCommas(sumCost)}</dd>
                    </dl>
                  </div>
                </div>

                <div className="pb-2 my-2">
                  <p className="bold">5. TERMS & CONDITION </p>
                </div>

                <table className="table estimate w-100">
                  <colgroup>
                    <col width="16%"/>
                    <col width="*"/>
                  </colgroup>

                  <tr>
                    <th className="center">Shipment</th>
                    <td className="p-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder=" "
                        onChange={async (e) => await setTcShipment(e.target.value)}
                        value={tcShipment}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Payment</th>
                    <td className="p-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder=" "
                        onChange={async (e) => await setTcPayment(e.target.value)}
                        value={tcPayment}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Incoterms</th>
                    <td className="p-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder=" "
                        onChange={async (e) => await setTcIncoterms(e.target.value)}
                        value={tcIncoterms}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Origin</th>
                    <td className="p-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder=" "
                        onChange={async (e) => await setTcOrigin(e.target.value)}
                        value={tcOrigin}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Packing</th>
                    <td className="p-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder=" "
                        onChange={async (e) => await setTcPacking(e.target.value)}
                        value={tcPacking}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Lead Time</th>
                    <td className="p-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder=" "
                        onChange={async (e) => await setTcLeadTime(e.target.value)}
                        value={tcLeadTime}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Offer Validity</th>
                    <td className="p-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder=" "
                        onChange={async (e) => await setTcOfferValidity(e.target.value)}
                        value={tcOfferValidity}
                      />
                    </td>
                  </tr>
                </table>

                <div>
                  <div className="border-bottom pb-2">
                    <p className="bold">6. 기타 </p>
                  </div>

                  <div className="d-flex flex-wrap bg-lpurple border-bottom p-2">
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      onChange={async (e) => await setEtc(e.target.value)}
                      value={etc}
                    ></textarea>
                  </div>
                </div>

                <div className="text-center py-2">
                  <Link to={`/admin/project/estimates/${projectId}/${productId}`}>
                    <button
                      type="button"
                      className="btn btn_gray"
                    >
                      견적서 리스트
                    </button>
                  </Link>
                  &nbsp;
                  <button className="btn btn-purple" type="button" onClick={() => saveEstimate()}>
                    견적서 작성 완료
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminProjectEstimate;
