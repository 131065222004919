import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import ManagerList from "../common/ManagerList";

const AdminManagerNotice = (props) => {
  // 탭 state
  const [tabState, setTabState] = useState({
    home: true,
    detail: false,
    etc: false,
  });

  // tab 클릭 핸들러
  const tabClick = (e) => {
    const tab = { ...tabState };
    const target = e.currentTarget.id;
    for (let key in tab) {
      target === key ? (tab[key] = true) : (tab[key] = false);
    }
    setTabState(tab);
  };

  // 현재 선택한 바이어 state
  const [buyerState, setBuyerState] = useState();
  const buyerClick = (e) => {
    setBuyerState(e);
  };

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{ height: "calc(100% - 30px)" }}
      >
        <section className="col-4" style={{ height: "100%" }}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{ height: "98%" }}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">Manager Notice List</p>
              <p className="m-1 mb-2">
                <button
                  type="button"
                  className="btn btn-purple txt-sm py-1 px-3"
                >
                  추가
                </button>
              </p>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll mt-2"
              style={{ height: "calc(100% - 60px)" }}
            >
              <div className="box_round_5 border my-2  p-3">
                <p className="h6 mb-2 text-start">
                  공지사항 제목 표현 한다. 최대 2줄까지 표현 된다 이렇게 ..
                </p>
                <div className="d-flex justify-content-between">
                  <p>게시 예정 | 사용함</p>
                  <p className="txt-sm txt-gray">2022.11.11 11:50</p>
                </div>
              </div>

              <div className="box_round_5 border_purple my-2 p-3">
                <p className="h6 mb-2 text-start">
                  공지사항 제목 표현 한다. 최대 2줄까지 표현 된다 이렇게 ..
                </p>
                <div className="d-flex justify-content-between">
                  <p>게시 예정 | 사용함</p>
                  <p className="txt-sm txt-gray">2022.11.11 11:50</p>
                </div>
              </div>

              <div className="box_round_5 border my-2 p-3">
                <p className="h6 mb-2 text-start">
                  공지사항 제목 표현 한다. 최대 2줄까지 표현 된다 이렇게 ..
                </p>
                <div className="d-flex justify-content-between">
                  <p>게시 예정 | 사용함</p>
                  <p className="txt-sm txt-gray">2022.11.11 11:50</p>
                </div>
              </div>

              <div className="box_round_5 border my-2 p-3">
                <p className="h6 mb-2 text-start">
                  공지사항 제목 표현 한다. 최대 2줄까지 표현 된다 이렇게 ..
                </p>
                <div className="d-flex justify-content-between">
                  <p>게시 예정 | 사용함</p>
                  <p className="txt-sm txt-gray">2022.11.11 11:50</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="col-8" style={{ height: "100%" }}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{ height: "98%" }}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">매니저 공지 상세</p>
              <p className="m-1 mb-2">
                <button
                  type="button"
                  className="btn btn_gray txt-sm py-1 px-3"
                  style={{ marginRight: "5px" }}
                >
                  삭제
                </button>
                <button
                  type="button"
                  className="btn btn-purple txt-sm py-1 px-3"
                >
                  추가
                </button>
              </p>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll px-2 mt-4"
              style={{ height: "calc(100% - 70px)" }}
            >
              <article className="wid_inline">
                <table className="table basic w-100">
                  <colgroup>
                    <col width="20%" />
                    <col width="*" />
                    <col width="20%" />
                    <col width="*" />
                  </colgroup>
                  <tr>
                    <th className="center">제목</th>
                    <td colspan="3" className="border-top">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="최대 100자까지 가능합니다."
                        aria-describedby="button-addon2"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">게시 시작일</th>
                    <td>
                      <select
                        className="form-select form-select-sm w-85"
                        aria-label="Default select example"
                      >
                        <option selected>2021.10.30</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </td>

                    <th className="center">게시 종료일</th>
                    <td>
                      <select
                        className="form-select form-select-sm w-85"
                        aria-label="Default select example"
                      >
                        <option selected>2021.12.30</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">사용유무</th>
                    <td>
                      <select
                        className="form-select form-select-sm w-85"
                        aria-label="Default select example"
                      >
                        <option selected>사용</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </td>
                    <th className="center">등록일</th>
                    <td>2022.01.04 11:10</td>
                  </tr>

                  <tr>
                    <th className="center">내용</th>
                    <td colspan="3">
                      <CKEditor editor={ClassicEditor} data="" />
                    </td>
                  </tr>
                </table>
              </article>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminManagerNotice;
