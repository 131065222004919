import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import APIHelper from "../../../helpers/APIHelper";
import Constants from "../../../constants/constants";
import moment from "moment";
import Paging from "../../common/Paging";

const AdminMakerMain = (props) => {
  const [makers, setMakers] = useState(false);

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      await getMakers();
    }
    fetch();
  }, [currPage]);

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "제조사관리 > 제조사 현황",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const [keyword, setKeyword] = useState('');
  const search = async (_keyword) => {
    await setCurrPage(1);
    await getMakers(_keyword);
  }
  const handleSearchKeyDown = async (e) => {
    if (e.key === 'Enter') {
      await search();
    }
  }

  // 제조사 리스트 가져오기
  const getMakers = (_keyword) => {
    return new Promise(async (resolve, reject) => {
      if (_keyword === undefined) _keyword = keyword;
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("user/maker/gets", {keyword: _keyword, paging: 1, currPage});
      if (pageInfo) setPageInfo(pageInfo);
      setMakers(result);
      document.getElementById('scroll_div').scrollTop = 0;
      return resolve(true);
    });
  };

  return (
    <AdminCard>
      <section style={{height: "97%"}}>
        <div
          className="bg-white my-4 shadow2 box_round_4_paddingNo p-3"
          style={{height: "98%"}}
        >
          <div className="d-flex justify-content-between border-bottom">
            <p className="h4 bold p-2">제조사 현황</p>
            <p className="m-1 mb-2"></p>
          </div>
          <div className="p-4">
            <article className="d-flex w-100 border">
              <ul className="w-25 p_rative p-3 pt-4 bg-lpurple border-right mb-0">
                <p className="bold mb-1">
                  검색{" "}
                  <span className="txt-sm normal">
                    - 회사명(제조사명) 또는 키워드
                  </span>
                </p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="회사명(제조사명) 또는 키워드"
                  onChange={async (e) => await setKeyword(e.target.value)}
                  value={keyword}
                  onKeyDown={handleSearchKeyDown}
                />
              </ul>
              <dl className="w-25 p-2 py-3 mb-0">
                <dd className="text-left">
                  <button
                    type="button"
                    className="btn btn-sm btn-purple m-1"
                    style={{width: "100px"}}
                    onClick={() => search()}
                  >
                    조회
                  </button>
                  <br/>
                  <button
                    type="button"
                    className="btn btn-sm btn-dark m-1"
                    style={{width: "100px"}}
                    onClick={async () => {
                      setKeyword('');
                      await search('');
                    }}
                  >
                    검색 초기화
                  </button>
                </dd>
              </dl>
            </article>
          </div>

          <div className="d-flex justify-content-between border-bottom py-3 mx-3">
            <p className="bold">검색 : 전체 </p>
            <p className="bold h6">총 {pageInfo?.totalArticles}건</p>
          </div>
          <div
            className="px-2 wid_scroll scroll_y custom_scroll mt-3"
            id="scroll_div"
            style={{height: "calc(100% - 360px)"}}
          >
            {makers && makers.map((maker, key) => (
              <article className="border d-flex justify-content-between my-1">
                <dl className="p_rative p-3 mb-0 border-right" style={{width: "26%"}}>
                  <dd className="d-flex justify-content-center mt-2 mb-2">
                    <p
                      className="btn_oval_lg mb-1"
                      style={{marginRight: "15px"}}
                    >
                      {maker.rep_image_fid && maker.rep_image_fid.length > 0 ?
                        <img
                          src={`${Constants.API_HOST}/common/download?fid=${maker.rep_image_fid}&cd=inline&thumb=400`}
                          style={{width: "65px", height: "65px", objectFit: "cover"}}
                        />
                        :
                        <div style={{width: "65px", height: "65px", backgroundColor: "#efefef", borderRadius: "65px"}}></div>
                      }
                    </p>
                    <div className="text-start " style={{maxWidth: "200px"}}>
                      <p className="h6">{maker.company_name}</p>
                      <p className="txt-sm">
                        국적 : {maker.country?.name_kr}
                        <br/>
                        <p style={{width: "200px"}}>{maker?.introduce.length > 0 ? maker.introduce : "등록된 한줄 소개가 없습니다."}</p>
                        <br/>
                        MOQ : {maker?.moq?.name_kr || '미입력'}
                      </p>
                    </div>
                  </dd>
                </dl>
                <dl className="p-3 mb-0 border-right" style={{width: "18.5%"}}>
                  <dd>
                    <p>
                      회원구분 : <span className="h6">{maker?.user?.grade?.name_kr}</span>
                    </p>
                    {/*<p>*/}
                    {/*  사업자 인증 : <span className="h6">인증됨</span>*/}
                    {/*</p>*/}
                    {/*<p>*/}
                    {/*  등급 : <span className="h6">1 등급</span>*/}
                    {/*</p>*/}
                    <p>
                      가입일 : <span className="h6">{moment(maker.created_at).format("YYYY.MM.DD")}</span>
                    </p>
                  </dd>
                </dl>

                <dl
                  className="p_rative px-3 py-2 mb-0 border-right"
                  style={{width: "18.5%"}}
                >
                  <dt className="mb-2 mx-2">
                    <p className="">생산 가능 품목</p>
                  </dt>
                  <dd className=" d-flex flex-wrap">
                    {maker.mf_items.length > 0 ? maker.mf_items.map((item, key) => (
                      <div className="Hash_sm m-1" style={{height: "30px"}}>
                        <p className="p-1 txt-sm">{item.name_kr}</p>
                      </div>
                    )) : "생산 가능 품목이 없습니다."}
                  </dd>
                </dl>
                <dl className="p_rative px-3 py-2 mb-0 border-right" style={{width: "18.5%"}}>
                  <dt className="mb-2 mx-2">
                    <p className="">기능성 제품 생산</p>
                  </dt>

                  <dd className=" d-flex flex-wrap">
                    {maker.functionals && maker.functionals.length > 0 ? maker.functionals.map((item, key) => (
                      <div className="Hash_sm m-1" style={{height: "30px"}}>
                        <p className="p-1 txt-sm">{item.name_kr}</p>
                      </div>
                    )) : "생산 가능 품목이 없습니다."}
                  </dd>
                </dl>
                <dl className="p-2 py-3 mb-0 bg-lblue text-center" style={{width: "18.5%"}}>
                  <br/>
                  <br/>
                  <Link
                    key={key}
                    to={{
                      pathname: `/admin/maker/list`,
                      state: {
                        user_id: maker.user_id
                      }
                    }}>
                    <button
                      type="button"
                      className="w-48 btn btn-sm btn-dark m-1"
                      style={{minWidth: "115px"}}
                    >
                      상세 확인
                    </button>
                  </Link>
                </dl>
              </article>
            ))}
            <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          </div>
        </div>
      </section>
    </AdminCard>
  );
};

export default AdminMakerMain;
