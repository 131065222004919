import React, {useState} from "react";
import "../../sass/main.scss";
import img01 from "../../image/login_img01.png";
import {Link, useHistory} from "react-router-dom";

// import Swiper core and required modules
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";

import login_banner_01 from "../../image/login_banner_01.png";
import login_banner_02 from "../../image/login_banner_02.png";
import login_banner_03 from "../../image/login_banner_03.png";
import login_banner_04 from "../../image/login_banner_04.png";

SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y]);

const LoginCard = (props) => {
  const history = useHistory();

  return (
    <>
      <div className="BODY container-fluid p_rative" style={{minHeight: "760px"}}>
        <div
          className="login_box d-flex p_absolute_mid"
          style={{width: "1000px", height: "670px"}}
        >
          <div className="p-4 login_intro">
            <Swiper
              autoplay={{delay: 3000}}
              loop={true}
              pagination={{clickable: true}}
            >
              <SwiperSlide>
                <div
                  onClick={() => history.push('/guide/about')}
                  className="w-100"
                  style={{
                    background: `url(${login_banner_01}) center center/cover`,
                    backgroundSize: "cover",
                    width: "550px",
                    height: "600px",
                    cursor: "pointer"
                  }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <div
                  onClick={() => history.push('/guide/about')}
                  className="w-100"
                  style={{
                    background: `url(${login_banner_02}) center center/cover`,
                    backgroundSize: "cover",
                    width: "550px",
                    height: "600px",
                    cursor: "pointer"
                  }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <div
                  onClick={() => history.push('/guide/about')}
                  className="w-100"
                  style={{
                    background: `url(${login_banner_03}) center center/cover`,
                    backgroundSize: "cover",
                    width: "550px",
                    height: "600px",
                    cursor: "pointer"
                  }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <div
                  onClick={() => history.push('/guide/about')}
                  className="w-100"
                  style={{
                    background: `url(${login_banner_04}) center center/cover`,
                    backgroundSize: "cover",
                    width: "550px",
                    height: "600px",
                    cursor: "pointer"
                  }}
                />
              </SwiperSlide>
              <div className="swiper-pagination swiper-pagination-timeline-page"/>
            </Swiper>
          </div>
          {props.children}
        </div>
      </div>
    </>
  );
};

export default LoginCard;
