import React, {useEffect, useState} from "react";
import first_page_black_48dp from "../../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../../image/chevron_left_black_24dp.svg";
import navigate_next_black_48dp from "../../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../../image/last_page_black_48dp.svg";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";

const AdminCommonCode = (props) => {
  const topCd = props?.match?.params?.topCd || '';

  // 모달 state
  const [open, setOpen] = useState(false);
  let modalInlineStyle;
  if (open) {
    modalInlineStyle = "block";
  } else {
    modalInlineStyle = "none";
  }

  const [selectedParentCode, setSelectedParentCode] = useState(null);
  const [parentCodes, setParentCodes] = useState([]);
  const [codes, setCodes] = useState([]);

  const [id, setId] = useState("");
  const [cd, setCd] = useState("");
  const [nameKr, setNameKr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [etc1, setEtc1] = useState("");
  const [etc2, setEtc2] = useState("");
  const [imageUri, setImageUri] = useState("");
  const [useYn, setUseYn] = useState(1);
  const [sort, setSort] = useState(0);
  const [description, setDescription] = useState("");

  // 모달에서 수정인지 추가인지 구별하기 위한 state
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      await getParentCodes();
    }
    fetch();
  }, []);

   useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: topCd ? "어드민 관리 > 재퓸 뷴류 코드 관리" : "어드민 관리 > 공통 코드 관리",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  // GET 부모코드 가져오기
  const getParentCodes = () => {
    return new Promise(async (resolve, reject) => {
      const {
        data: {code, result, message},
      } = await APIHelper.getForm("code/parents", {topCd});
      if (code === 200) setParentCodes(result);
      else await CommonLib.jalert(message);
      return resolve(true);
    });
  };

  // GET 자식코드 가져오기
  const selectParentCode = (parentCode) => {
    return new Promise(async (resolve, reject) => {
      setSelectedParentCode(parentCode);
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: parentCode.cd});
      if (code === 200) setCodes(result);
      else await CommonLib.jalert(message);
      return resolve(true);
    });
  };

  // POST 자식 코드추가
  const saveSubmit = () => {
    setIsEdit(false);
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: {code: retCode, result, message},
        } = await APIHelper.postForm("code/add", {
          cd,
          parentCd: selectedParentCode.cd,
          nameKr,
          nameEn,
          etc1,
          etc2,
          imageUri,
          useYn,
          sort,
          description,
        });
        if (retCode !== 200) {
          await CommonLib.jalert(message);
        }
        else {
          await selectParentCode(selectedParentCode);

          try {
            await APIHelper.postForm("admin/action/log", {
              content: topCd ? "어드민 관리 > 재퓸 뷴류 코드 관리 > 추가" : "어드민 관리 > 공통 코드 관리 > 추가",
              param1 : cd,
              param2 : selectedParentCode.cd,
              param3 : nameKr,
              param4 : nameEn,
              param5 : etc1,
              param6 : imageUri,
              param7 : useYn,
              param8 : sort,
              param9 : description,
            });
          } catch (err) {
            console.log(err);
          }
        }
      } catch (err) {
      }
      setOpen(!open);
      return resolve(true);
    });
  };

  const initFormState = () => {
    setCd("");
    setNameKr("");
    setNameEn("");
    setEtc1("");
    setEtc2("");
    setImageUri("");
    setUseYn(1);
    setSort(0);
    setDescription("");
  };

  const setFormState = (id) => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/get", {id});
      setCd(result?.cd);
      setNameKr(result?.name_kr);
      setNameEn(result?.name_en);
      setEtc1(result?.etc1);
      setEtc2(result?.etc2);
      setImageUri(result?.image_uri || '');
      setUseYn(result?.use_yn);
      setSort(result?.sort);
      setDescription(result?.description);
      return resolve(true);
    });
  };

  // DELETE 코드삭제
  const deleteClick = async (id) => {
    let result = await CommonLib.jconfirm("해당 코드를 삭제하시겠습니까?");
    if (result) {
      return new Promise(async (resolve, reject) => {
        try {
          const {
            data: {code: retCode, result, message},
          } = await APIHelper.deleteForm(`code/delete?id=${id}`);
          if (retCode !== 200) {
            await CommonLib.jalert(message);
          }
          else {
            await selectParentCode(selectedParentCode);

            try {
              await APIHelper.postForm("admin/action/log", {
                content: topCd ? "어드민 관리 > 재퓸 뷴류 코드 관리 > 삭제" : "어드민 관리 > 공통 코드 관리 > 삭제",
                param1 : id,
              });
            } catch (err) {
              console.log(err);
            }

          }
        } catch (err) {
        }
        return resolve(true);
      });
    }
  };

  // PUT 코드수정
  const editSubmit = (e) => {
    return new Promise(async (resolve, reject) => {
      setOpen(true);
      setIsEdit(true);
      try {
        const {
          data: {code: retCode, result, message},
        } = await APIHelper.putForm(`code/update`, {
          id,
          cd,
          parentCd: selectedParentCode.cd,
          nameKr,
          nameEn,
          etc1,
          etc2,
          imageUri,
          useYn,
          sort,
          description,
        });
        if (retCode !== 200) {
          await CommonLib.jalert(message);
        }
        else {
          await selectParentCode(selectedParentCode);

          try {
            await APIHelper.postForm("admin/action/log", {
              content: topCd ? "어드민 관리 > 재퓸 뷴류 코드 관리 > 수정" : "어드민 관리 > 공통 코드 관리 > 수정",
              param1 : cd,
              param2 : selectedParentCode.cd,
              param3 : nameKr,
              param4 : nameEn,
              param5 : etc1,
              param6 : imageUri,
              param7 : useYn,
              param8 : sort,
              param9 : description,
            });
          } catch (err) {
            console.log(err);
          }

        }
      } catch (err) {
      }
      setOpen(!open);
      setIsEdit(false);
      return resolve(true);
    });
  };

  return (
    <AdminCard isOpen={open} click={setOpen}>
      {/* 모달 */}
      <div style={{display: modalInlineStyle}}>
        <div
          className="pop_box02"
          style={{width: "900px", minHeight: "500px"}}
        >
          <div className="POP_title pb-3 border-bottom">
            <h4>
              <strong>공통 코드</strong>
            </h4>
            <p className="closed">
              <button
                type="button"
                className="btn"
                onClick={() => setOpen(!open)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box_lg" style={{marginBottom: "90px"}}>
            <table className="table basic w-100 my-3">
              <colgroup>
                <col width="15%"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th className="center txt-sm">Code</th>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="13101"
                    onChange={async (e) => {
                      await setCd(e.target.value);
                    }}
                    value={cd}
                  />
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">태그명(한글)</th>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="제주도"
                    onChange={async (e) => {
                      await setNameKr(e.target.value);
                    }}
                    value={nameKr}
                  />
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">태그명(영문)</th>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Jeju"
                    onChange={async (e) => {
                      await setNameEn(e.target.value);
                    }}
                    value={nameEn}
                  />
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">기타1</th>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="기타1"
                    onChange={async (e) => {
                      await setEtc1(e.target.value);
                    }}
                    value={etc1}
                  />
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">기타2</th>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="기타2"
                    onChange={async (e) => {
                      await setEtc2(e.target.value);
                    }}
                    value={etc2}
                  />
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">이미지 URI</th>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="이미지 URI"
                    onChange={async (e) => {
                      await setImageUri(e.target.value);
                    }}
                    value={imageUri}
                  />
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">사용 유무</th>
                <td className="border-top">
                  <select
                    className="form-select form-select-sm"
                    aria-label="Default select example"
                    onChange={async (e) => {
                      await setUseYn(e.target.value);
                    }}
                  >
                    <option selected={parseInt(useYn) === 1} value="1">
                      사용함
                    </option>
                    <option selected={parseInt(useYn) === 0} value="0">
                      사용안함
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">순서</th>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="2"
                    onChange={async (e) => {
                      await setSort(parseInt(e.target.value));
                    }}
                    value={sort}
                  />
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">설명</th>
                <td>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    onChange={async (e) => {
                      await setDescription(e.target.value);
                    }}
                    value={description}
                  />
                </td>
              </tr>
            </table>
          </div>
          <div className="POP_foot">
            <button
              className="btn btn-sm px-3 btn_gray"
              type="button"
              onClick={() => setOpen(!open)}
            >
              취소
            </button>
            {isEdit ? (
              <button
                className="btn btn-sm px-3 btn-purple"
                type="button"
                onClick={async () => {
                  await editSubmit();
                }}
              >
                수정
              </button>
            ) : (
              <button
                className="btn btn-sm px-3 btn-purple"
                type="button"
                onClick={async () => {
                  await saveSubmit();
                }}
              >
                저장
              </button>
            )}
          </div>
        </div>
      </div>
      {/* 모달 끝 */}
      <div
        id="body"
        className="row row-cols-2 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <section className="col-3" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">코드 List</p>
              <p className="m-1 mb-2"></p>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll mt-2"
              style={{height: "calc(100% - 60px)"}}
            >
              <div className="wid_inline">
                {parentCodes.map((item, i) => (
                  <div
                    key={i}
                    className={`box_round_5 ${
                      item.cd === selectedParentCode?.cd
                        ? "border_purple"
                        : "border"
                    } my-2 p-3`}
                    onClick={() => selectParentCode(item)}
                  >
                    <div className="d-flex justify-content-between">
                      <p className="h6 mb-2 ">{item.name_kr}</p>
                      <p className="txt-md">{item.cd}</p>
                    </div>
                    <p className="text-start">
                      태그 개수 : {item.cnt} / {item.total}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="col-9" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">{selectedParentCode?.name_kr} 카테고리</p>
              <p className="m-1 mb-2">
                <button
                  onClick={async () => {
                    if (selectedParentCode <= 0) {
                      await CommonLib.jalert("부모 코드를 선택해 주세요.");
                      return;
                    }
                    initFormState();
                    setOpen(!open);
                    setIsEdit(false);
                  }}
                  type="button"
                  className="btn btn-purple txt-sm py-1 px-3"
                >
                  추가
                </button>
              </p>
            </div>

            {/*<div className="d-flex justify-content-between m-3 mx-4">*/}
            {/*  <p></p>*/}
            {/*  <div className="input-group input-group-sm w-22">*/}
            {/*    <label className="input-group-text" for="inputGroupSelect01">*/}
            {/*      조회구분*/}
            {/*    </label>*/}
            {/*    <select className="form-select" id="inputGroupSelect01">*/}
            {/*      <option selected>전체</option>*/}
            {/*      <option value="1">One</option>*/}
            {/*      <option value="2">Two</option>*/}
            {/*      <option value="3">Three</option>*/}
            {/*    </select>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div
              className="px-4 wid_scroll scroll_y custom_scroll mt-4"
              style={{height: "calc(100% - 190px)"}}
            >
              <article className="wid_inline">
                <table className="table basic_list2 w-100">
                  <colgroup>
                    <col width="55px"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="13%"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>No</th>
                    <th>Code</th>
                    <th>태그명(한글)</th>
                    <th>태그명(영문)</th>
                    <th>기타1</th>
                    <th>기타2</th>
                    <th>설명</th>
                    <th>사용 유무</th>
                    <th>순서</th>
                    <th>비고</th>
                    <th>삭제</th>
                  </tr>
                  {codes.map((item, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item.cd}</td>
                      <td>{item.name_kr}</td>
                      <td>{item.name_en}</td>
                      <td>{item.etc1}</td>
                      <td>{item.etc2}</td>
                      <td>{item.description}</td>
                      <td>
                        <div>
                          <input
                            className="form-check-input mt-1 text-center"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault2"
                            checked={parseInt(item.use_yn) === 1 ? true : false}
                          />
                          <label
                            className="form-check-label px-1"
                            for="flexCheckDefault2"
                          >
                            사용{item.use_yn === "1" ? "" : "안"}함
                          </label>
                        </div>
                      </td>
                      <td>{item.sort}</td>
                      <td>
                        <button
                          onClick={async () => {
                            setOpen(true);
                            setIsEdit(true);
                            setId(item.id);
                            await setFormState(item.id);
                          }}
                          type="button"
                          className="btn btn_outline_puple btn-sm"
                        >
                          수정
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => deleteClick(item.id)}
                          type="button"
                          className="btn btn_outline_puple btn-sm"
                        >
                          삭제
                        </button>
                      </td>
                    </tr>
                  ))}
                </table>
              </article>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminCommonCode;
