import React, { useEffect, useState } from "react";
import close_w from "../../../image/close_w.svg";
import "../../../sass/main.scss";
import ImageUploading from "react-images-uploading";
import APIHelper from "../../../helpers/APIHelper";
import FormData from "form-data";
import Constants from "../../../constants/constants";
import ProfileBuyer from "./ProfileBuyer";
import CommonLib from "../../../libraries/CommonLib";

const ProfileReviewBuyer = (props) => {
  const [uploadImages, setUploadImages] = useState([]);
  const [images, setImages] = useState([]);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      const {
        data: { code, result, message },
      } = await APIHelper.getForm("user/buyer/get/photos");
      if (code === 200) setUploadImages(result);
    };
    fetch();
  }, [renew]);

  const onDeleteUploadImage = async (photoFid) => {
    const {
      data: { code, result, message },
    } = await APIHelper.deleteForm(
      `user/buyer/delete/photo?photoFid=${photoFid}`
    );
    if (code === 200) setRenew(new Date().getTime());
  };

  const onChangeImageUploading = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  const submit = async () => {
    try {
      const formData = new FormData();
      for (const [key, value] of Object.entries(images)) {
        formData.append("files[]", value.file);
      }

      const response = await APIHelper.putRawForm(
        "user/buyer/update/photo",
        formData
      );
      const {
        data: { code, result, message },
      } = response;
      if (code === 200) setRenew(new Date().getTime());
      setImages([]);
      await CommonLib.jalert(message);
    } catch (err) {
      console.log(err);
    }
  };



  return (
    <ProfileBuyer>
      <ImageUploading
        multiple
        value={images}
        onChange={onChangeImageUploading}
        maxNumber={6}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <div className="upload__image-wrapper">
            <div className="d-flex justify-content-between pb-4 mt-4">
              <p className="h5">
                회사 사진 업로드
                <br />
                {/*<span className="txt-gray normal h7">*/}
                {/*최대 6개 까지 등록이 가능하며, 꽉 찼을 경우 사진을 지우고 업로드하셔야 합니다.*/}
                {/*</span>*/}
              </p>
              <p>
                <button
                  type="button"
                  className="btn btn-sm btn_puple px-2"
                  onClick={onImageUpload}
                >
                  사진 업로드
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn_puple px-2"
                  onClick={submit}
                >
                  저장하기
                </button>
              </p>
            </div>

            <div className="d-flex flex-wrap">
              {uploadImages &&
                uploadImages.map((image, index) => (
                  <p key={index} className="comp_thum_add p-0">
                    <button
                      type="button"
                      className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                      onClick={() => onDeleteUploadImage(image.photo_fid)}
                    >
                      <img src={close_w} className="comp_thum_add_del" />
                    </button>
                    <img
                      src={
                        image?.photo_fid.length > 0
                          ? `${Constants.API_HOST}/common/download?fid=${image.photo_fid}&cd=inline&thumb=400`
                          : ""
                      }
                      style={{
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#cfcfcf",
                      }}
                    />
                  </p>
                ))}
              {imageList.map((image, index) => (
                <p key={index} className="comp_thum_add p-0">
                  <button
                    type="button"
                    className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                    onClick={() => onImageRemove(index)}
                  >
                    <img src={close_w} className="comp_thum_add_del" />
                  </button>
                  <img
                    src={image["data_url"]}
                    onClick={() => onImageUpdate(index)}
                    style={{
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: "#cfcfcf",
                    }}
                  />
                </p>
              ))}
            </div>
          </div>
        )}
      </ImageUploading>
    </ProfileBuyer>
  );
};

export default ProfileReviewBuyer;
