import React from "react";
import MakerProjectSide from "./MakerProjectSide";
import Icon_message_p from "../../../image/Icon_message_p.svg";
import arrowright from "../../../image/arrow-right.svg";
import "../../../sass/main.scss";

const MakerTimelineCard = (props) => {
  return (
    <MakerProjectSide dv={props.dv} id={props.id}>
      {/*<div className="d-flex justify-content-end mt-5 mb-4 pb-2">*/}
      {/*  <div className="p_rative mx-3">*/}
      {/*    <p*/}
      {/*      className="p_absolute bg-red btn_oval_15 txt-sm bold"*/}
      {/*      style={{ right: "-8px", top: "10px" }}*/}
      {/*    >*/}
      {/*      2*/}
      {/*    </p>*/}
      {/*    <img src={Icon_message_p} />*/}
      {/*  </div>*/}
      {/*  <ul className="d-flex btn_toggle p-0" style={{ width: "180px" }}>*/}
      {/*    <li className=" w-50">*/}
      {/*      <a href="#">간편보기</a>*/}
      {/*    </li>*/}
      {/*    <li className="ON w-50">*/}
      {/*      <a href="#">상세보기</a>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</div>*/}

      <div className="Step_box mt-4">
        <div className={`Step_${props.step === 1 ? "on" : "off"}`}>
          <span className="txt-ssm bold">STEP01</span>
          <br />
          <div style={{paddingTop: "5px"}}>단가조사</div>
        </div>

        <div className="py-3">
          <img src={arrowright} style={{ width: "10px" }} />
        </div>

        <div className={`Step_${props.step === 2 ? "on" : "off"}`}>
          <span className="txt-ssm bold">STEP02</span>
          <br />
          <div style={{paddingTop: "5px"}}>샘플 디자인</div>
        </div>
        <div className="py-3">
          <img src={arrowright} style={{ width: "10px" }} />
        </div>
        <div className={`Step_${props.step === 3 ? "on" : "off"}`}>
          <span className="txt-ssm bold">STEP03</span>
          <br />
          <div style={{paddingTop: "5px"}}>견적 및 계약</div>
        </div>
        <div className="py-3">
          <img src={arrowright} style={{ width: "10px" }} />
        </div>
        <div className={`Step_${props.step === 4 ? "on" : "off"}`}>
          <span className="txt-ssm bold">STEP04</span>
          <br />
          <div style={{paddingTop: "5px"}}>제품 제작</div>
        </div>
        <div className="py-3">
          <img src={arrowright} style={{ width: "10px" }} />
        </div>
        <div className={`Step_${props.step === 5 ? "on" : "off"}`}>
          <span className="txt-ssm bold">STEP05</span>
          <br />
          <div style={{paddingTop: "5px"}}>통관/선적</div>
        </div>
        <div className="py-3">
          <img src={arrowright} style={{ width: "10px" }} />
        </div>
        <div className={`Step_${props.step === 6 ? "on" : "off"}`}>
          <span className="txt-ssm bold">STEP06</span>
          <br />
          <div style={{paddingTop: "5px"}}>완료</div>
        </div>
      </div>
      {props.children}
    </MakerProjectSide>
  );
};

export default MakerTimelineCard;
