import React from "react";
import MypageOemItem from "./MypageOemItem";
import "../../sass/main.scss";

const MypageOemItems = (props) => {
  return (
    <>
      {props.items.map((item, k) => (
        <MypageOemItem
          no={props.items - k}
          item={item}
          mode={props.mode}
        />
      ))}
    </>
  );
};

export default MypageOemItems;
