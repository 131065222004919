import React from "react";
import {useHistory} from "react-router-dom";
import CsCenterCard from "./CsCenterCard";
import "../../sass/main.scss";

const NoticeDetail = ({location}) => {
  const history = useHistory();
  const state = location.state;
  const current = location.pathname;

  return (
    <CsCenterCard>
      <div className="box_round min_h cnt_area">
        <div className="mx-5 my-4">
          <p className="h3 bold border-bottom py-3">공지사항</p>
          <br/>
          <table className="table basic_view my-3">
            <tr>
              <th>
                <p className="h6 mb-0">{state.title}</p>
                <p className="txt-sm normal">{state.date}</p>
              </th>
            </tr>
            <tr>
              <td>
                <p className="p_absolute_br2 txt-sm txt-gray">
                  hit:{state.hits}
                </p>
                <div className="my-4" dangerouslySetInnerHTML={{__html: state.text}}/>
              </td>
            </tr>
          </table>

          <div className="text-right">
            <div onClick={() => history.goBack()} className="btn btn_puple">
              목록보기
            </div>
          </div>
        </div>
      </div>
    </CsCenterCard>
  );
};

export default NoticeDetail;
