import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import CommonLib from "../../libraries/CommonLib";
import {useHistory} from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
import Constants from "../../constants/constants";
import $ from "jquery";

const BuyerPw = (props) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [countryCd, setCountryCd] = useState('');
  const [countries, setCountries] = useState([]);

  const history = useHistory();

  const [auth, setAuth] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [passwd, setPasswd] = useState('');
  const [passwdRe, setPasswdRe] = useState('');
  const [validPassword, setValidPassword] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: "106000"});
        if (code === 200) {
          setCountries(result);
          setCountryCd(result[0]?.cd);
        }
      }
    };
    fetch();
  }, []);

  const submit1 = async () => {
    if (!email?.trim()) {
      await CommonLib.jalert('이메일을 입력해 주세요.');
      return;
    }

    if (true) {
      $.ajax({
        type: "get",
        url: Constants.API_URL + "user/find/passwd/proc1?email=" + email,
        success: function (data) {
          let code = data?.code;
          let message = data?.message?.trim();

          CommonLib.jalert('인증 코드가 발송되었습니다.\n이메일을 확인해 주세요.');
        }
      });
    } else {
      const {data: {code, result, message}} = await APIHelper.getForm("user/find/passwd/proc1", {email});
      await CommonLib.jalert('인증 코드가 발송되었습니다.\n이메일을 확인해 주세요.');
    }
  }

  const submit2 = async () => {
    if (!verificationCode?.trim()) {
      await CommonLib.jalert('인증 코드를 입력해 주세요.');
      return;
    }

    if (true) {
      $.ajax({
        type: "get",
        url: Constants.API_URL + "user/find/passwd/proc2?email=" + email + "&verificationCode=" + verificationCode,
        success: function (data) {
          let code = data?.code;
          if (code === 200)
            setAuth(true);
          else {
            CommonLib.jalert('인증 코드가 올바르지 않습니다.');
            setAuth(false);
          }
        }
      });
    } else {
      const {data: {code, result, message}} = await APIHelper.getForm("user/find/passwd/proc2", {verificationCode, email});
      if (code === 200)
        setAuth(true);
      else {
        await CommonLib.jalert('인증 코드가 올바르지 않습니다.');
        setAuth(false);
      }
    }
  }

  const submit3 = async () => {
    if (!validPassword) {
      await CommonLib.jalert('패스워드 규칙에 맞게 다시 입력해 주세요.');
    } else {
      const {data: {code, result, message}} = await APIHelper.getForm("user/find/passwd/proc3", {email, passwd, verificationCode});
      if (code === 200) {
        await CommonLib.jalert('패스워드가 재설정되었습니다.\n로그인해 주세요.');
        history.push("/login");
      } else {
        await CommonLib.jalert('인증이 올바르지 않습니다.\n다시 시도해 주세요.');
      }
    }
  }

  return (
    <>
      {!auth ?
        <>
          <div className="input-group input-group-md mb-3">
            <input
              type="text"
              className="form-control"
              onChange={async (e) => await setEmail(e.target.value)}
              value={email}
              placeholder="E-Mail"
            />
            <button className="btn btn_puplelight" type="button" id="button-addon2" style={{width: "80px"}} onClick={() => submit1()}>Verify</button>
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              onChange={async (e) => await setVerificationCode(e.target.value)}
              value={verificationCode}
              placeholder="Verification Code"
            />
          </div>

          {/*<div className="mb-3">*/}
          {/*  <input*/}
          {/*    type="text"*/}
          {/*    className="form-control"*/}
          {/*    onChange={async (e) => await setEmail(e.target.value)}*/}
          {/*    value={email}*/}
          {/*    placeholder="이메일(ID)"*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className="mb-3">*/}
          {/*  <select className="form-select"*/}
          {/*          onChange={async (e) => await setCountryCd(e.target.value)}*/}
          {/*          value={countryCd}*/}
          {/*  >*/}
          {/*    {countries && countries.map((item, i) => {*/}
          {/*      return (*/}
          {/*        <option key={i} selected={countryCd === item.cd} value={item.cd}>{item.name_en}</option>*/}
          {/*      );*/}
          {/*    })}*/}
          {/*  </select>*/}
          {/*</div>*/}
          {/*<div className="mb-3">*/}
          {/*  <input*/}
          {/*    type="text"*/}
          {/*    className="form-control"*/}
          {/*    onChange={async (e) => await setFirstName(e.target.value)}*/}
          {/*    value={firstName}*/}
          {/*    placeholder="First Name"*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className="mb-3">*/}
          {/*  <input*/}
          {/*    type="text"*/}
          {/*    className="form-control"*/}
          {/*    onChange={async (e) => await setMiddleName(e.target.value)}*/}
          {/*    value={middleName}*/}
          {/*    placeholder="Middle Name"*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className="mb-5">*/}
          {/*  <input*/}
          {/*    type="text"*/}
          {/*    className="form-control"*/}
          {/*    onChange={async (e) => await setLastName(e.target.value)}*/}
          {/*    value={lastName}*/}
          {/*    placeholder="Last Name"*/}
          {/*  />*/}
          {/*</div>*/}

          <div className="mb-3">
            <button type="button" className="btn btn_puple w-100 py-2 bold" onClick={() => submit2()}>
              {/*비밀번호 찾기*/}
              Continue
            </button>
          </div>
        </>
        :
        <>
          <div className="mb-3">
            <input
              type="password"
              className="form-control"
              placeholder="New Password"
              aria-describedby="button-addon2"
              onChange={async (e) => await setPasswd(e.target.value)}
              value={passwd}
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              className="form-control"
              placeholder="New Password confirm"
              aria-describedby="button-addon2"
              onChange={async (e) => await setPasswdRe(e.target.value)}
              value={passwdRe}
            />
          </div>
          <div className="mb-3">
            {passwd?.length > 0 &&
              <PasswordChecklist
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "match"
                  // "capital",
                ]}
                minLength={7}
                value={passwd}
                valueAgain={passwdRe}
                messages={{
                  minLength: "비밀번호는 최소 7자 이상이어야 합니다.",
                  specialChar: "비밀번호는 특수문자를 포함해야 합니다.",
                  number: "비밀번호는 숫자를 포함해야 합니다.",
                  capital: "비밀번호는 대문자를 포함해야 합니다.",
                  match: "비밀번호가 일치합니다.",
                }}
                onChange={(e) => setValidPassword(e)}
              />
            }
          </div>

          <div className="mb-3">
            <button type="button" className="btn btn_puple w-100 py-2 bold" onClick={() => submit3()}>
              Confirm
            </button>
          </div>
        </>
      }
    </>
  );
};

export default BuyerPw;
