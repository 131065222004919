import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import AdminSearchIngredientList from "./AdminSearchIngredientList";
import APIHelper from "../../../helpers/APIHelper";

const AdminSearchIngredient = (e) => {
  const history = useHistory();

  const itemClick = (id, keyword, currPage) => {
    history.push(`/admin/data/search/ingredient/detail/${id}`);
  };

   useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "홈노출/검색관리 > 검색 - 성분",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{ height: "calc(100% - 30px)" }}
      >
        <AdminSearchIngredientList click={itemClick} />

        <section className="col-8" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">성분제품사전을 선택해 주세요</p>
              <p className="m-1 mb-2">
                <button
                  className="btn btn_gray txt-sm py-1 px-3"
                  type="button"
                  style={{marginRight: "5px", visibility: "hidden"}}
                >
                  INVISIBLE
                </button>
              </p>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminSearchIngredient;
