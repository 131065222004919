import React, {useEffect, useState} from "react";
import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import Constants from "../../../constants/constants";
import Paging from "../../common/Paging";

const BuyerList = (props) => {
  const [clickStyle, setClickStyle] = useState();
  const userId = props?.userId || (props?.location?.state?.id || props?.match?.params?.id);

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  const [buyers, setBuyers] = useState(false);
  useEffect(() => {
    const fetch = async () => {
      await getBuyers();
    }
    fetch();
  }, [currPage, props.userId]);

  // 제조사 리스트 가져오기
  const getBuyers = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("user/buyer/gets", {userId, paging: 1, currPage});
      if (pageInfo) setPageInfo(pageInfo);
      setBuyers(result);
      try {
        props.click(result[0]);
        setClickStyle(result[0]);
      } catch (err) {
      }
      try {
        document.getElementById('scroll_div').scrollTop = 0;
      } catch (err) {
      }
      return resolve(true);
    });
  };

  return (
    <div
      className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
      style={{height: "100%"}}
    >
      <div className="d-flex justify-content-between border-bottom">
        <p className="h5 bold p-2">바이어 List</p>
        <p className="m-1 mb-2">
          {/*{props.addbtn && (*/}
          {/*  <button type="button" className="btn btn-purple">*/}
          {/*    추가*/}
          {/*  </button>*/}
          {/*)}*/}
        </p>
      </div>
      <div
        className="wid_scroll scroll_y custom_scroll_p mt-3"
        id="scroll_div"
        style={{height: "calc(100% - 60px)"}}
      >
        {/*<article className="m-2 bg-lpurple box_round p-3">*/}
        {/*  <div className="input-group">*/}
        {/*<input*/}
        {/*  type="text"*/}
        {/*  className="form-control"*/}
        {/*  placeholder="회사명( 바이어 명) 또는 바이어 태그"*/}
        {/*/>*/}
        {/*<select*/}
        {/*  className="form-select"*/}
        {/*  style={{maxWidth: "180px"}}*/}
        {/*  aria-label="Default select example"*/}
        {/*>*/}
        {/*  <option selected>국가 전체</option>*/}
        {/*  <option value="1">One</option>*/}
        {/*  <option value="2">Two</option>*/}
        {/*  <option value="3">Three</option>*/}
        {/*</select>*/}
        {/*<button*/}
        {/*  className="btn btn-purple px-3"*/}
        {/*  type="button"*/}
        {/*  id="button-addon2"*/}
        {/*>*/}
        {/*  검색*/}
        {/*</button>*/}
        {/*  </div>*/}
        {/*</article>*/}

        {buyers && buyers.map((buyer, key) => (
          <article
            onClick={() => {
              props.click(buyer);
              setClickStyle(buyer);
            }}
            className={`m-2 border${
              clickStyle && clickStyle === buyer
                ? "_purple"
                : ""
            } p-3`}
          >
            <div className="d-flex mt-2 mb-2">
              <p className="btn_oval_xl mb-1" style={{marginRight: "15px"}}>
                {buyer.rep_image_fid && buyer.rep_image_fid.length > 0 ?
                  <img
                    src={`${Constants.API_HOST}/common/download?fid=${buyer.rep_image_fid}&cd=inline&thumb=400`}
                    style={{width: "65px", height: "65px", objectFit: "cover"}}
                  />
                  :
                  <div style={{width: "65px", height: "65px", backgroundColor: "#efefef", borderRadius: "65px"}}></div>
                }
              </p>
              <div className="text-start " style={{maxWidth: "200px"}}>
                <p className="h6">{buyer && (buyer?.company?.length > 0 ? buyer.company : (buyer?.first_name + " " + buyer?.middle_name + " " + buyer?.last_name))}</p>
                <p>국적 : {buyer.country?.name_kr}</p>
                <p style={{width: "200px"}}>{buyer?.introduce.length > 0 ? buyer.introduce : "등록된 한줄 소개가 없습니다."}</p>
                {/*TODO*/}
                {/*<p className="mt-2">*/}
                {/*  {item.membership}회원 | 사업자 인증*/}
                {/*  {`${item.business_certification ? "" : "안"}`}됨 | {item.rank}*/}
                {/*</p>*/}
              </div>
              {/*TODO*/}
              {/*{props.visitant && (*/}
              {/*  <div className="ms-auto text-center p-3">*/}
              {/*    <p className="mb-2">방문 현황</p>*/}
              {/*    <p className="h3">{item.visit.length}건</p>*/}
              {/*  </div>*/}
              {/*)}*/}
            </div>
          </article>
        ))}
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </div>
    </div>
  );
};

export default BuyerList;
