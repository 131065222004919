import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import CommonLib from "../../libraries/CommonLib";

const MypageMenu = (props) => {
  const current = window.location.pathname;

  const [messageResult, setMessageResult] = useState(0);
  const [oemProposalResult, setOemProposalResult] = useState(0);

  const gets = async () => {
    {
      const {data: {code, result}} = await APIHelper.getForm("user/message/unread/check");
      setMessageResult(result);
    }
    {
      const {data: {code, result}} = await APIHelper.getForm("user/oem/proposal/unread/check");
      setOemProposalResult(result);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await gets();
    }
    fetch();
  }, [props.renew]);

  return (
    <div className="box_round left_menu">
      <div className="left_menu_title">마이페이지</div>
      <ul className="left_menu_list p-0">
        <li className={`${current === "/mypage/home" ? "on" : ""}`}>
          <Link to="/mypage/home">My Home</Link>
        </li>
        {/*2023.08.08*/}
        {/*<li className={`${current.includes("oem") === true ? "on" : ""}`}>*/}
        {/*  <Link to="/mypage/oem">*/}
        {/*    OEM 제작 제안*/}
        {/*    {CommonLib.parseInt(oemProposalResult) > 0 && <div className="new-icon" style={{marginLeft: "113px"}}>N</div>}*/}
        {/*  </Link>*/}
        {/*</li>*/}
        <li className={`${current.includes("alarm") === true ? "on" : ""}`}>
          <Link to="/mypage/alarm">
            알림
            {CommonLib.parseInt(messageResult) > 0 && <div className="new-icon" style={{marginLeft: "39px"}}>N</div>}
          </Link>
        </li>
        <li className={`${current.includes("/profile/") === true ? "on" : ""}`}>
          <Link
            to={
              localStorage.getItem(
                "user.session.user_type"
              ) === "BUYER"
                ? "/profile/buyer"
                : "/profile/maker"
            }
          >
            내 프로필
          </Link>
        </li>
        {/*<li className={`${tabState.review ? "on" : ""}`}>*/}
        {/*  <a href="#" onClick={tabClick} id="review">*/}
        {/*    후기 및 평점*/}
        {/*  </a>*/}
        {/*</li>*/}
        <li className={`${current.includes("change/passwd") === true ? "on" : ""}`}>
          <Link to="/mypage/change/passwd">패스워드 변경</Link>
        </li>
        {/*<li className={`${current.includes("change/email") === true ? "on" : ""}`}>*/}
        {/*  <Link to="/mypage/change/email">이메일(ID) 변경</Link>*/}
        {/*</li>*/}
        <li className={`${current.includes("withdraw") === true ? "on" : ""}`}>
          <Link to="/mypage/withdraw">회원 탈퇴</Link>
        </li>
        <li className={`${current.includes("preference") === true ? "on" : ""}`}>
          <Link to="/mypage/preference">설정</Link>
        </li>
      </ul>
    </div>
  );
};

export default MypageMenu;
