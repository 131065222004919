import React, {useEffect, useState} from "react";
import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import Constants from "../../../constants/constants";
import CommonLib from "../../../libraries/CommonLib";
import Paging from "../../common/Paging";

const MakerList = (props) => {
  const [clickStyle, setClickStyle] = useState();
  const userId = props?.userId || (props?.location?.state?.id || props?.match?.params?.id);
  const renew = props?.renew || (props?.location?.state?.renew || props?.location?.state?.renew || props?.match?.params?.renew);

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  const [makers, setMakers] = useState(false);

  useEffect(() => {
    setCurrPage(1);
  }, [renew]);

  useEffect(() => {
    const fetch = async () => {
      await getMakers();
    }
    fetch();
  }, [props.userId, currPage]);

  // 제조사 리스트 가져오기
  const getMakers = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("user/maker/gets", {userId, paging: 1, currPage});
      if (pageInfo) setPageInfo(pageInfo);
      setMakers(result);
      try {
        props.click(result[0]);
        setClickStyle(result[0]);
      } catch (err) {
      }
      try {
        document.getElementById('scroll_div').scrollTop = 0;
      } catch (err) {
      }
      return resolve(true);
    });
  };

  return (
    <div
      className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
      style={{height: "100%"}}
    >
      <div className="d-flex justify-content-between border-bottom">
        <p className="h5 bold p-2">제조사 List</p>
        <p className="m-1 mb-2">
          {/*{props.addbtn && (*/}
          {/*  <button type="button" className="btn btn-purple">*/}
          {/*    추가*/}
          {/*  </button>*/}
          {/*)}*/}
        </p>
      </div>
      <div
        className="wid_scroll scroll_y custom_scroll_p mt-3"
        id="scroll_div"
        style={{height: "calc(100% - 60px)"}}
      >
        {/*<article className="m-2 bg-lpurple box_round p-3">*/}
        {/*  <div className="input-group">*/}
        {/*    <input*/}
        {/*      type="text"*/}
        {/*      className="form-control"*/}
        {/*      placeholder="회사명(제조사명) 또는 바이어 태그"*/}
        {/*    />*/}
        {/*    <select*/}
        {/*      className="form-select"*/}
        {/*      style={{maxWidth: "180px"}}*/}
        {/*      aria-label="Default select example"*/}
        {/*    >*/}
        {/*      <option selected>국가 전체</option>*/}
        {/*      <option value="1">One</option>*/}
        {/*      <option value="2">Two</option>*/}
        {/*      <option value="3">Three</option>*/}
        {/*    </select>*/}
        {/*    <button*/}
        {/*      className="btn btn-purple px-3"*/}
        {/*      type="button"*/}
        {/*      id="button-addon2"*/}
        {/*    >*/}
        {/*      검색*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*</article>*/}

        {makers && makers.map((maker, key) => (
          <article
            onClick={() => {
              props.click(maker);
              setClickStyle(maker);
            }}
            className={`m-2 border${
              clickStyle && clickStyle === maker
                ? "_purple"
                : ""
            } p-3`}
          >
            <div className="d-flex mt-2 mb-2">
              <p className="btn_oval_xl mb-1" style={{marginRight: "15px"}}>
                {maker.rep_image_fid && maker.rep_image_fid.length > 0 ?
                  <img
                    src={`${Constants.API_HOST}/common/download?fid=${maker.rep_image_fid}&cd=inline&thumb=400`}
                    style={{width: "65px", height: "65px", objectFit: "cover"}}
                  />
                  :
                  <div style={{width: "65px", height: "65px", backgroundColor: "#efefef", borderRadius: "65px"}}></div>
                }
              </p>
              <div className="text-start " style={{maxWidth: "200px"}}>
                <p className="h6">{maker.company_name}</p>
                <p>국적 : {maker.country?.name_kr}</p>
                <p style={{width: "200px"}}>{maker?.introduce.length > 0 ? maker.introduce : "등록된 한줄 소개가 없습니다."}</p>
                {/*TODO*/}
                {/*<p className="mt-2">*/}
                {/*  {item.membership}회원 | 사업자 인증*/}
                {/*  {`${item.business_certification ? "" : "안"}`}됨 | {item.rank}*/}
                {/*</p>*/}
              </div>
              {/*TODO*/}
              {/*{props.visitant && (*/}
              {/*  <div className="ms-auto text-center p-3">*/}
              {/*    <p className="mb-2">방문 현황</p>*/}
              {/*    <p className="h3">{item.visit.length}건</p>*/}
              {/*  </div>*/}
              {/*)}*/}
              {/*{props.favorite && (*/}
              {/*  <div className="ms-auto text-center p-3">*/}
              {/*    <p className="mb-2">관심 현황</p>*/}
              {/*    <p className="h3">{item.favorite.length}건</p>*/}
              {/*  </div>*/}
              {/*)}*/}
            </div>
          </article>
        ))}
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </div>
    </div>
  );
};

export default MakerList;
