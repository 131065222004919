import React from "react";
import MakerProjectSide from "./MakerProjectSide";
import { Link } from "react-router-dom";
import file_download_w from "../../../image/file_download_w.svg";
import thum_comp_01 from "../../../image/thum_comp_01.png";

import "../../../sass/main.scss";

const MakerProjectProgressRequest = (props) => {
  const dv = props?.location?.state?.dv || props?.match?.params?.dv;
  const id = props?.location?.state?.id || props?.match?.params?.id;

  return (
    <MakerProjectSide dv={dv} id={id}>
      <div className="my-4" style={{ minHeight: "700px" }}>
        <table className="table basic w-100">
          <colgroup>
            <col width="28%" />
            <col width="*" />
          </colgroup>

          <tr>
            <th className="center ">의뢰서 제목 및 의뢰번호</th>
            <td>
              <p style={{ padding: "0" }} className="h5 bold txt-point">
                의뢰서 제목 의뢰서 제목
              </p>
              <p style={{ padding: "0" }}>제작 의뢰서 번호 : B1-2021-00001</p>

              <p style={{ padding: "0" }}>용기배송 / 단상자 배송</p>
            </td>
          </tr>
          <tr>
            <th className="center">브랜드명 (Brand name)</th>
            <td>브랜드명</td>
          </tr>
          <tr>
            <th className="center">제품명 (Product name)</th>
            <td>제품명</td>
          </tr>
          <tr>
            <th className="center">용량 (Volum)</th>
            <td>30ml</td>
          </tr>
          <tr>
            <th className="center">제형/타겟 제품 (Target item)</th>
            <td>제형/타겟 제품 (Target item)</td>
          </tr>
          <tr>
            <th className="center">향/색 (Scent/Color)</th>
            <td>향무관 / 색무관</td>
          </tr>
          <tr>
            <th className="center">연관 이미지 첨부</th>
            <td>
              <div className="d-flex">
                <p className="thum_sm">
                  <button
                    type="button"
                    className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                  >
                    <img src={file_download_w} className="thum_sm_del" />
                  </button>
                  <img src={thum_comp_01} />
                </p>
                <p className="thum_sm">
                  <button
                    type="button"
                    className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                  >
                    <img src={file_download_w} className="thum_sm_del" />
                  </button>
                  <img src={thum_comp_01} />
                </p>
                <p className="thum_sm">
                  <button
                    type="button"
                    className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                  >
                    <img src={file_download_w} className="thum_sm_del" />
                  </button>
                  <img src={thum_comp_01} />
                </p>
                <p className="thum_sm">
                  <button
                    type="button"
                    className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                  >
                    <img src={file_download_w} className="thum_sm_del" />
                  </button>
                  <img src={thum_comp_01} />
                </p>
                <p className="thum_sm">
                  <button
                    type="button"
                    className="btn btn-dark btn_oval_25 p_absolute_tr2 p-0"
                  >
                    <img src={file_download_w} className="thum_sm_del" />
                  </button>
                  <img src={thum_comp_01} />
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <th className="center">제품 컨셉 (Concept of Line)</th>
            <td>컨셉 내용 들어감...</td>
          </tr>
        </table>
      </div>
    </MakerProjectSide>
  );
};

export default MakerProjectProgressRequest;
