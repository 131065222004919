import React, {useEffect, useState} from "react";
import BuyerProjectSide from "./BuyerProjectSide";
import {Link} from "react-router-dom";

import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import moment from "moment";
import CommonLib from "../../../libraries/CommonLib";
import $ from "jquery";
import openRequestFormulation from "../../../actions/openRequestFormulation";
import openRequestDesign from "../../../actions/openRequestDesign";
import openRequestBottle from "../../../actions/openRequestBottle";
import openRequestBox from "../../../actions/openRequestBox";
import {useDispatch} from "react-redux";
import openEstimate from "../../../actions/openEstimate";

const BuyerProjectForm = (props) => {
  const dv = props?.location?.state?.dv || props?.match?.params?.dv;
  const id = props?.location?.state?.id || props?.match?.params?.id;

  const downloadEstimateModal = (projectId, projectProductId, projectMakerRequestId, formType, timelineId) => {
    const obj = {
      projectId,
      projectProductId,
      projectMakerRequestId,
      title: formType.replace(' 작성', ''),
      renew: new Date().getTime(),
      readOnly: true,
      formType,
      timelineId

    };
    dispatch(openEstimate("open", obj));
  }

  const path = window.location.pathname;

  const [info, setInfo] = useState({
    country: '',
    target: '',
    cert: '',
    wish_volume: '',
    wish_quantity: '',
  });

  const [project, setProject] = useState({});
  const [stepTitle, setStepTitle] = useState();
  const [estimateTimeline, setEstimateTimeline] = useState({});

  useEffect(() => {
    const fetch = async () => {
      await getProject();
    }
    fetch();
  }, []);

  const [tabState, setTabState] = useState({
    tree: true,
    list: false,
  });

  const clickTab = (e) => {
    const tab = {...tabState};
    const target = e.currentTarget.id;
    for (let key in tab) {
      target === key ? (tab[key] = true) : (tab[key] = false);
    }
    setTabState(tab);
  };

  const getProject = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("project/get", {id, extended: 1});
      if (code === 200) {
        setProject(result);

        try {
          const {data: {result: _result}} = await APIHelper.getForm("project/timeline/get", {projectId: result?.id, projectProductId: result?.product?.[0]?.id, status: '바이어용 본 견적서 확인 및 진행 유무 판단 #1'});
          if (CommonLib.parseInt(_result?.ref_id_5) > 0) {
            setEstimateTimeline(_result);
          }
        } catch (err) {
        }

        setInfo({
          country: result.products?.[0]?.country?.name_kr,
          target: result.products?.[0]?.target?.name_kr,
          cert: result.products?.[0]?.cert?.name_kr,
          unit_price: result.products?.[0]?.unit_price,
          unit: result.products?.[0]?.unit,
          wish_volume: result.products?.[0]?.wish_volume,
          wish_quantity: result.products?.[0]?.wish_quantity,
        });

        if (CommonLib.parseInt(result.step) === 1) result?.products?.[0].request_forms?.[0]?.request_no?.length > 0 ? setStepTitle('STEP1 : 단가조사') : setStepTitle('STEP1 : 제작문의');
        else if (CommonLib.parseInt(result.step) === 2) setStepTitle('STEP2 : 샘플 디자인');
        else if (CommonLib.parseInt(result.step) === 3) setStepTitle('STEP3 : 견적 및 계약');
        else if (CommonLib.parseInt(result.step) === 4) setStepTitle('STEP4 : 제품 제작');
        else if (CommonLib.parseInt(result.step) === 5) setStepTitle('STEP5 : 통관/선적');
        else if (CommonLib.parseInt(result.step) === 6) setStepTitle('STEP6 : 완료');
      }
      return resolve(true);
    });
  }

  const dispatch = useDispatch();

  $('.btn-request-form').click(function () {
    openRequestForm($(this).attr('projectId'), $(this).attr('projectProductId'), $(this).attr('requestType'));
  });

  const openRequestForm = (projectId, projectProductId, itemCd) => {
    const obj = {projectId, projectProductId, renew: new Date().getTime(), readOnly: true, hidePrice: true};
    if (itemCd === 'MF') dispatch(openRequestFormulation("open", obj));
    else if (itemCd === 'DS') dispatch(openRequestDesign("open", obj));
    else if (itemCd === 'BT') dispatch(openRequestBottle("open", obj));
    else if (itemCd === 'BX') dispatch(openRequestBox("open", obj));
  }

  const itemArr = [{cd: "MF", name: "내용물"}, {cd: "BT", name: "용기"}, {cd: "BX", name: "단상자"}, {cd: "DS", name: "디자인"}]

  return (
    <BuyerProjectSide dv={dv} id={id}>
      <div className="d-flex justify-content-between mt-5 mb-4 pb-2 border-bottom">
        <p></p>
        <ul className="d-flex btn_toggle p-0" style={{width: "180px"}}>
          <li className={`w-50 ${tabState.tree ? "ON" : ""}`}>
            <a id="tree" onClick={clickTab} href="#">
              Tree View
            </a>
          </li>
          <li className={`w-50 ${tabState.tree ? "" : "ON"}`}>
            <a id="list" onClick={clickTab} href="#">
              List View
            </a>
          </li>
        </ul>
      </div>
      <div className="row border-bottom pb-3">
        <div className="col col-10">
          <div className="txt-purple2 col-12" style={{marginTop: "0px", textAlign: "center"}}>
            <h3 style={{fontWeight: "bold"}}>{project?.name !== '' ? project?.name : "프로젝트명 미정"}</h3>
          </div>
          <div className="txt-gray col col-5">
            <p>프로젝트 No : {project?.prj_no}</p>
            <p>일자 : {moment(project.created_at).format("YYYY.MM.DD")} ~ {window.location.pathname.includes("/progress") ? "진행중" : moment(project.finished_at).format("YYYY.MM.DD")}</p>
            <p>제품 : {project?.total_formed_products?.length}건</p>
          </div>
          <div className="my-3">
            <table className="table basic_ssm w-100">
              <colgroup>
                <col width="20%"/>
                <col width="*"/>
                <col width="20%"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th className="center">판매국가</th>
                <td>{info?.country}</td>
                <th className="center">타겟층</th>
                <td>{info?.target}</td>
              </tr>
              <tr>
                <th className="center">인증서기준</th>
                <td>{info?.cert}</td>
                <th className="center">원하는 단가 및 수량</th>
                <td>{CommonLib.numberWithCommas(info?.unit_price)}{info?.unit || '원'} | {info?.wish_volume || 'N/A'} | {info?.wish_quantity || 'N/A'} (MOQ)</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="col col-2 p_rative">
          <div className="step_info p_absolute" style={{bottom: "60px"}}>
            <p className="level txt-darkyellow">{stepTitle?.split(':')?.[0]?.trim()}</p>
            <p className="py-2">{stepTitle?.split(':')?.[1]?.trim()}</p>
          </div>
          {estimateTimeline?.id > 0 &&
            <div className="col">
              <button type="button" className="btn btn-sm p-0 p_absolute" style={{backgroundColor: "mediumpurple", color: "white", width: "100px", bottom: "20px", margin: 0, height: "25px", fontWeight: "bold"}}
                      onClick={() => downloadEstimateModal(estimateTimeline?.project_id, estimateTimeline?.project_product_id, estimateTimeline?.project_maker_request, '본 견적서 확인', estimateTimeline?.id)}>본 견적서 확인
              </button>
            </div>
          }
        </div>
      </div>

      {tabState.tree ?
        <div className="row row-cols-3 g-3 py-3">
          {project.products && project.products.map((product, key) => {
            return (
              <div className="col">
                <div className="box_item">
                  <div className="item_Title d-flex w-100" style={{cursor: "pointer"}} onClick={() => {
                    setInfo({
                      country: product?.country?.name_kr,
                      target: product?.target?.name_kr,
                      cert: product?.cert?.name_kr,
                      unit_price: product?.unit_price,
                      unit: product?.unit,
                      wish_volume: product?.wish_volume,
                      wish_quantity: product?.wish_quantity,
                    });
                  }}>
                    <div className="triangle_puple">
                      <p className="txt-white bold txt-sm"></p>
                    </div>
                    <p className="p-1">{product?.category?.name_kr} - {product?.product?.name_kr}</p>
                    <p className="Oval_dot_red m-2 ms-auto " style={{backgroundColor: product?.form_chk === 1 ? "#ce7575" : "#555555"}}></p>
                  </div>
                  <div className="row row-cols-1 g-2 p-2">
                    {
                      itemArr.map(item =>
                        product?.items.filter(i => i.item_cd === item.cd).length > 0 &&
                        <div className="col btn-request-form" projectId={project.id} projectProductId={product.id} requestType={item.cd} style={{cursor: "pointer"}}>
                          <div className="list p-2">
                            <p className="border-bottom p-2 pt-0 h6">{item.name}</p>
                            <p className="px-2">
                              {product?.request_forms.filter(f => f.request_type === item.cd)?.[0]?.request_name}
                              <br/>
                              {product?.request_forms.filter(f => f.request_type === item.cd)?.[0]?.request_no}
                            </p>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        :
        <div className="row row-cols-1 g-3 py-3">
          {project.products && project.products.map((product, key) => {
            return (
              <div className="col">
                <div className="box_item">
                  <div className="item_Title d-flex w-100" style={{cursor: "pointer"}} onClick={() => {
                    setInfo({
                      country: product?.country?.name_kr,
                      target: product?.target?.name_kr,
                      cert: product?.cert?.name_kr,
                      unit_price: product?.unit_price,
                      unit: product?.unit,
                      wish_volume: product?.wish_volume,
                      wish_quantity: product?.wish_quantity,
                    });
                  }}>
                    <div className="triangle_puple">
                      <p className="txt-white bold txt-sm"></p>
                    </div>
                    <p className="p-1">{product?.category?.name_kr} - {product?.product?.name_kr}</p>
                    <p className="Oval_dot_red m-2 ms-auto " style={{backgroundColor: product?.form_chk === 1 ? "#ce7575" : "#555555"}}></p>
                  </div>
                  <div className="row row-cols-4 g-2 p-2">
                    {
                      itemArr.map(item =>
                        product?.items.filter(i => i.item_cd === item.cd).length > 0 &&
                        <div className="col btn-request-form" projectId={project.id} projectProductId={product.id} requestType={item.cd} style={{cursor: "pointer"}}>
                          <div className="list p-2">
                            <p className="border-bottom p-2 pt-0 h6">{item.name}</p>
                            <p className="px-2">
                              {product?.request_forms.filter(f => f.request_type === item.cd)?.[0]?.request_name}
                              <br/>
                              No : {product?.request_forms.filter(f => f.request_type === item.cd)?.[0]?.request_no}
                            </p>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      }
    </BuyerProjectSide>
  );
};

export default BuyerProjectForm;
