import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import openRequestFormulation from "../../actions/openRequestFormulation";

import "../../sass/main.scss";
import RequestFormulationComponent from "../admin/project/RequestFormulationComponent";
import APIHelper from "../../helpers/APIHelper";

const RequestFormulationModal = (props) => {
  const {projectId, projectProductId, requestId, renew, readOnly, hidePrice = false, closeCallback} = props.requestFormulationPop?.content;

  const [requestList, setrequestList] = useState([]);
  const [changeRequestId, setChangeRequestId] = useState(0);
  const [renew2, setRenew2] = useState(renew);

  const closeModal = () => {
    if (closeCallback) closeCallback();
    props.openRequestFormulation("closed", "");
  };

  let modalInlineStyle;

  if (props.requestFormulationPop.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }

  // 제작 의뢰서 COPY LIST 가져오기

  useEffect(() => {
    const fetch = async () => {
      await getRequest();
      requestId && await selectReset();
      await setChangeRequestId(0);
    }
    fetch();
  }, [requestId]);


  const selectReset = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.postForm("project/request/select", {
        requestId: requestId,
        requestSelect: ''
      });
    });
  }

  const getRequest = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("project/request/copylist/get", {
        projectId : projectId,
        requestId : requestId,
        projectProductId : projectProductId,
        requestType : "MF"
      });
      setrequestList(result);
      return resolve(true);
    });
  };

  return (
    <div style={{display: modalInlineStyle}}>
      <div className="pop_box02" style={{width: "750px", minHeight: "500px"}}>
        <div className="POP_title pb-3">
          <h4>
            <strong>제작 의뢰서 (제형)</strong>
          </h4>
          <p className="closed">
            <button onClick={closeModal} type="button" className="btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </button>
          </p>
        </div>
        <div style={{float: "right", paddingBottom: "10px"}}>
          {requestList.length > 1 &&
          <select
            className="form-select w-auto"
            aria-label="Default select example"
            onChange={async (e) => {
              setChangeRequestId(e.target.value);
              setRenew2(new Date().getTime());
              //requestId.set(e.target.value);
              //getRequest(requestId);
            }}
            value={changeRequestId}
          >
            {requestList.map((item, i) => <option value={item?.id}>{item?.request_name}</option>)}
          </select>
          }
        </div>
        <div className="POP_cnt_box_lg" style={{marginBottom: "90px"}}>
          <RequestFormulationComponent projectId={projectId} projectProductId={projectProductId} requestId={changeRequestId > 0 ? changeRequestId : requestId} renew={renew2 ? renew2 : renew} key={renew2 ? renew2 : renew}  readOnly={readOnly} hidePrice={hidePrice} requestList={requestList}/>
        </div>
      </div>
      <div className="pop_bak" onClick={closeModal}></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    requestFormulationPop: state.requestFormulationPop,
  };
}

function mapDispatchToProps(dispatcher) {
  return bindActionCreators(
    {
      openRequestFormulation: openRequestFormulation,
    },
    dispatcher
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestFormulationModal);
