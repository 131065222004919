import React from "react";
import {Link} from "react-router-dom";
import MypageCard from "../mypage/MypageCard";
import "../../sass/main.scss";
import CsCenterCard from "./CsCenterCard";
import CommonLib from "../../libraries/CommonLib";

const InquiryDetail = (props) => {
  const {location} = props;
  const state = location.state;
  const current = props.location.pathname;

  return (
    <CsCenterCard>
      <div className="box_round min_h cnt_area pb-5">
        <div className="mx-5 my-4">
          <p className="h3 bold border-bottom py-3">1:1문의</p>
          <br/>

          <table className="table basic_view my-3">
            <tr>
              <th>
                <p className="h6 mb-0">{state.title}</p>
                <p className="txt-sm normal">{state.date}</p>
              </th>
            </tr>
            <tr>
              <td>
                <div className="d-flex justify-content-between">
                  <p className="px-2">{state.category}</p>
                  <p className={`box_round_4_paddingNo bg-${state.status === '답변대기' ? 'darkgray' : 'red'} txt-sm px-3`}>
                    {state.status === '답변대기' ? '대기' : '답변'}
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p className="txt-sm txt-gray">문의 내용</p>
                <div className="my-3">{CommonLib.escapedNewLineToLineBreakTag(state.text)}</div>
              </td>
            </tr>
            {state.status === '답변완료' &&
              <tr>
                <td className="bg-lgray2">
                  <p className="txt-sm txt-orange">답변 내용</p>
                  <div className="my-2 border box_round_4_paddingNo bg-white p-4">
                    {CommonLib.escapedNewLineToLineBreakTag(state.response)}
                  </div>
                </td>
              </tr>
            }
          </table>

          <div className="d-flex justify-content-between">
            <Link to="/cscenter/inquiry">
              <button type="button" className="btn btn_puple">
                목록보기
              </button>
            </Link>
          </div>
        </div>
      </div>
    </CsCenterCard>
  );
};

export default InquiryDetail;
