import React, {useEffect, useRef, useState} from "react";
import cancel_p from "../../../image/cancel_p.svg";
import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import Constants from "../../../constants/constants";
import CommonLib from "../../../libraries/CommonLib";

const AdminBuyerHome = (props) => {
  const {buyer} = props;

  const [companyImageFile, setCompanyImageFile] = useState(null);
  const [companyImagePreviewUrl, setCompanyImagePreviewUrl] = useState(null);
  const companyImageFileRef = useRef();

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState('');
  const [countryCd, setCountryCd] = useState('');
  const [marketCountry, setMarketCountry] = useState('');
  const [introduce, setIntroduce] = useState('');
  const [summary, setSummary] = useState('');
  const [repImageFid, setRepImageFid] = useState(null);

  // open&close 버튼
  const [showAdd, setShowAdd] = useState(false);

  // TAG 종류
  const [tagData, setTagData] = useState([]);

  // 사용자가 선택한 Tag State
  const [tagState, setTagState] = useState([]);

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '110000'});
        if (code === 200) setTagData(result);
      }
      {
        const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '106000'});
        if (code === 200) setCountries(result);
      }
      await getBuyerProfile();
    }
    fetch();
  }, [props.renew]);

  // 바이어 프로필 가져오기
  const getBuyerProfile = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("user/buyer/get", {id: buyer?.user_id});
      if (code === 200) {
        setFirstName(result?.first_name);
        setMiddleName(result?.middle_name);
        setLastName(result?.last_name);
        setCompany(result.company);
        setMarketCountry(result.market_country);
        setIntroduce(result.introduce);
        setSummary(result.summary);
        setRepImageFid(result.rep_image_fid);
        setTagState(result.favorites || []);
        setCountryCd(result.country_cd);
      }
      return resolve(true);
    });
  };

  // Tag 핸들러
  const clickTag = (e) => {
    if (tagState.filter((element) => element.cd === e.cd).length > 0) {
      setTagState(tagState.filter((element) => element.cd !== e.cd));
    } else setTagState([...tagState, e]);
  };

  // 사용자가 선택한 Tag 보여주기
  const tage = tagState.map((item, i) => {
    return (
      <p className="box_round_4_paddingNo bg-lpurple px-2 p-1 m-2">
        <span className="px-2">{item.name_en}</span>{" "}
        <button
          onClick={() => clickTag(item)}
          type="button"
          className="btn btn-sm p-0"
        >
          <img src={cancel_p} style={{paddingBottom: "2px"}}/>
        </button>
      </p>
    );
  });

  // 사용자가 선택하지 않은 Tag들
  const canTagAdd = tagData.filter((item) => tagState.filter((element) => element.cd === item.cd).length === 0);

  // 선택 가능한 Tag 보여주기
  const addTag = canTagAdd.map((item, i) => {
    return (
      <p
        onClick={() => clickTag(item)}
        style={{background: "#f9edf8", color: "#ae6faf", cursor: "pointer"}}
        className="box_round_4_paddingNo px-2 p-1 m-2"
      >
        <span style={{paddingLeft: "0.5rem"}}>{item.name_en}</span>{" "}
        <button type="button" className="btn btn-sm p-0"></button>
      </p>
    );
  });

  const handleCompanyImageChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setCompanyImageFile(file);
      setCompanyImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const updateSubmit = (e) => {
    return new Promise(async (resolve, reject) => {
      let favoriteProductCds = [];
      for (let key in tagState) {
        favoriteProductCds.push(tagState[key].cd);
      }

      const files = companyImageFileRef?.current?.files;

      try {
        const {data: {code: retCode, result, message}} = await APIHelper.putForm(`user/buyer/update/basic`, {
          id: buyer?.user_id,
          firstName,
          middleName,
          lastName,
          company,
          countryCd: countryCd,
          marketCountry,
          introduce,
          summary,
          companyImage: files ? files[0] : null,
          favoriteProductCds: favoriteProductCds.join(",")
        });
        await CommonLib.jalert(message);

        try {
          await APIHelper.postForm("admin/action/log", {
            content: "바이어관리 > 바이어 관리 > 기본정보 저장",
            param1 : buyer?.user_id,
            param2 : firstName,
            param3 : middleName,
            param4 : lastName,
            param5 : company,
            param6 : countryCd,
            param7 : marketCountry,
            param8 : introduce,
            param9 : summary,
          });
        } catch (err) {
          console.log(err);
        }

      } catch (err) {
        console.error(err);
      }
      return resolve(true);
    });
  };

  return (
    <article className="wid_inline mt-4">
      <div className="row">
        <p className="col col-3" style={{width: "178px", height: "266px", backgroundColor: "#efefef", padding: 0, margin: 0, marginLeft: "12px"}} onClick={() => companyImageFileRef.current.click()}>
          {(repImageFid || companyImagePreviewUrl) &&
            <img
              src={repImageFid.length > 0 ? `${Constants.API_HOST}/common/download?fid=${repImageFid}&cd=inline&thumb=400` : companyImagePreviewUrl}
              style={{
                width: "178px",
                height: "266px",
                objectFit: "cover",
              }}
            />
          }
          <input
            type="file"
            ref={companyImageFileRef}
            style={{display: "none"}}
            onChange={handleCompanyImageChange}
          />
        </p>
        <div className="col col-9">
          <table className="table basic w-100">
            <colgroup>
              <col width="22%"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th className="center">이메일</th>
              <td>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  aria-describedby="button-addon2"
                  onChange={async (e) => await setCompany(e.target.value)}
                  value={buyer?.user?.email}
                  readOnly={true}
                />
              </td>
            </tr>
            <tr>
              <th className="center">이름</th>
              <td>
                <input
                  type="text"
                  className="form-control2"
                  style={{width: "33%"}}
                  placeholder="First Name"
                  aria-describedby="button-addon2"
                  onChange={async (e) => {
                    await setFirstName(e.target.value);
                  }}
                  value={firstName}
                />
                <input
                  type="text"
                  className="form-control2"
                  style={{width: "33%"}}
                  placeholder="Middle Name"
                  aria-describedby="button-addon2"
                  onChange={async (e) => await setMiddleName(e.target.value)}
                  value={middleName}
                />
                <input
                  type="text"
                  className="form-control2"
                  style={{width: "34%"}}
                  placeholder="Last Name"
                  aria-describedby="button-addon2"
                  onChange={async (e) => {
                    await setLastName(e.target.value);
                  }}
                  value={lastName}
                />
              </td>
            </tr>
            <tr>
              <th className="center">회사명</th>
              <td>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="회사명 입력"
                  aria-describedby="button-addon2"
                  onChange={async (e) => await setCompany(e.target.value)}
                  value={company}
                />
              </td>
            </tr>
            {/*TODO*/}
            <tr>
              <th className="center">국적</th>
              <td>
                <select
                  className="form-select form-select-sm w-50"
                  aria-label="Default select example"
                  onChange={async (e) => await setCountryCd(e.target.value)}
                  value={countryCd}
                >
                  {countries && countries.map((item, i) => {
                    return (
                      <option key={i} selected={countryCd === item.cd} value={item.cd}>{item.name_kr}</option>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <th className="center">타겟 국가</th>
              <td>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="타겟국가 입력"
                  aria-describedby="button-addon2"
                  onChange={async (e) => await setMarketCountry(e.target.value)}
                  value={marketCountry}
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-between border-bottom pb-2 mt-4">
        <p className="txt-md">
          선호하는 상품 분류 TAG{" "}
          <span className="txt-gray normal h7">
            {" "}
            - 최대 20개 까지 등록이 가능합니다.
          </span>
        </p>
        <p>
          <button
            onClick={() => setShowAdd(!showAdd)}
            type="button"
            className="btn btn-sm btn_outline_puple px-3"
          >
            {showAdd ? "Close" : "Add TAG"}
          </button>
        </p>
      </div>
      {" "}
      {showAdd && (
        <div className="d-flex flex-wrap border-bottom p-3">{addTag}</div>
      )}
      <div className="d-flex flex-wrap border-bottom p-3">
        {tagState.length > 0 ? tage : <p>TAG를 추가해주세요</p>}
      </div>
      <div className="d-flex justify-content-between border-bottom pb-2 mt-4">
        <p className="txt-md">소개</p>
      </div>
      <table className="table basic w-100">
        <colgroup>
          <col width="20%"/>
          <col width="*"/>
        </colgroup>
        <tr>
          <th className="center">한줄 소개</th>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="한줄 소개 입력"
              aria-describedby="button-addon2"
              onChange={async (e) => await setIntroduce(e.target.value)}
              value={introduce}
            />
          </td>
        </tr>
        <tr>
          <th className="center">회사소개 입력</th>
          <td>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="9"
              onChange={async (e) => await setSummary(e.target.value)}
              value={summary}
            />
          </td>
        </tr>
      </table>
      <p className="text-center">
        <button type="button" className="btn btn_puple px-5 my-3" onClick={async (e) => await updateSubmit(e)}>
          저장하기
        </button>
      </p>
    </article>
  );
};

export default AdminBuyerHome;
