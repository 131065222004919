import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import cancel_g from "../../image/cancel_g.svg";
import ManufactureDetail from "./ManufactureDetail";
import APIHelper from "../../helpers/APIHelper";
import CommonLib from "../../libraries/CommonLib";
import {useHistory} from "react-router-dom";
import {useBeforeunload} from "react-beforeunload";
import $ from "jquery";

const Manufacture = (props) => {
  const history = useHistory();
  const maker_user_id = props?.location?.state?.maker_user_id || props?.match?.params?.maker_user_id;

  const [mode, setMode] = useState("MAIN");
  const [resultIdx, setResultIdx] = useState(0);

  const [arrProducts, setArrProducts] = useState([]);
  const [arrTypes, setArrTypes] = useState([]);

  const [lang, setLang] = useState(localStorage.getItem("lang") || "kr");

  const _setResult = (result) => {
    setResult(result);
    localStorage.setItem("manufacture.result", JSON.stringify(result));
  };

  // 03. 항목
  const arrItems = [
    {name_kr: "내용물", cd: "MF", width: 35, num: 1},
    {name_kr: "용기", cd: "BT", width: 45, num: 2},
    {name_kr: "단상자", cd: "BX", width: 40, num: 4},
    {name_kr: "디자인", cd: "DS", width: 40, num: 3},
  ];

  useEffect(() => {
    const fetch = async () => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: "110000"});
      if (code === 200) {
        setArrProducts(result);
        if (result.length > 0) {
          setSelectProduct(result[0]);
          await getArrTypes(result[0].cd);
        }
      }
      try {
        if (JSON.parse(localStorage.getItem("manufacture.result")))
          detailCallback(JSON.parse(localStorage.getItem("manufacture.result")));
      } catch (err) {
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    $(document).bind("DOMSubtreeModified", function () {
      try {
        if (localStorage.getItem("lang") === "kr") setLang("kr");
        else setLang("en");
      } catch (err) {
      }
    });

    return () => {
      $(document).unbind("DOMSubtreeModified");
    }
  }, []);

  const getArrTypes = (parentCd) => {
    return new Promise(async (resolve, reject) => {
      const {
        data: {code, result, message},
      } = await APIHelper.getForm("code/gets", {parentCd});
      if (code === 200) setArrTypes(result);
      return resolve(true);
    });
  };

  // select된 것들
  const [selectProduct, setSelectProduct] = useState();
  const [selectType, setSelectType] = useState(null);
  const [selectItem, setSelectItem] = useState([]);

  const submit = async () => {
    if (result?.length <= 0) {
      await CommonLib.jalert("최소 1개 이상의 제품 생성 후, 의뢰서 보내기가 가능합니다.");
      return;
    }

    for (let i = 0; i < result?.length; i++) {
      if (result[i].done !== true) {
        await CommonLib.jalert("생성된 제품의 상세 정보를 등록해 주세요.");
        return;
      }
    }

    const {data: {message}} = await APIHelper.postForm("project/add/json", {rep_maker_user_id: maker_user_id || 0, param: JSON.stringify(result)});
    _setResult([]);
    await CommonLib.jalert('제품 제작을 의뢰하였습니다.');
    history.push('/project/buyer/task/progress');
  };

  const exceedsLine = (str) => {
    let len = 0;
    for (let i = 0; i < str.length; i++) {
      if (escape(str.charAt(i)).length === 6)
        len++;
      len++;
    }

    if (lang === "kr" && len >= 17)
      return true;
    if (lang === "en" && len > 14)
      return true;
    return false;
  }

  // 제품분류 핸들러
  const productsList = arrProducts.map((item, i) => (
    <div className="col">
      <button
        type="button"
        key={i}
        onClick={async () => {
          setSelectProduct(item);
          setSelectType(null);
          setSelectItem([]);
          await getArrTypes(item.cd);
        }}
        className={`notranslate no-transform btn btn-sm  ${
          selectProduct && selectProduct.cd === item.cd
            ? "category1_on"
            : "category1"
        }`}
      >
        {item?.image_uri && item?.image_uri.length > 0 ? (
          <img
            src={
              process.env.PUBLIC_URL +
              item?.image_uri.substring(0, item?.image_uri.indexOf(".")) +
              (selectProduct && selectProduct.cd === item.cd ? "1" : "") +
              "." +
              item?.image_uri.substring(item?.image_uri.indexOf(".") + 1)
            }
            style={{height: `36px`, marginBottom: "5px", objectFit: "contain"}}
          />
        ) : (
          <div
            style={{
              width: "36px",
              height: "36px",
              borderRadius: "18px",
              backgroundColor: "#efefef",
              marginLeft: "18px",
            }}
          ></div>
        )}
        <br/>
        {/*{lang === "kr" ? item.name_kr : item.name_en}*/}
        {item.name_en}
      </button>
    </div>
  ));

  // 제품종류 핸들러
  const typesList = arrTypes.map((item, i) => (
    <div className="col">
      <button
        style={{}}
        type="button"
        key={i}
        onClick={() => {
          setSelectType(item);
          setSelectItem([]);
        }}
        className={` btn btn-sm  ${
          selectType && selectType.cd === item.cd ? "category1_on" : "category1"
        }`}
      >
        {item?.image_uri && item?.image_uri.length > 0 ? (
          <img
            src={
              process.env.PUBLIC_URL +
              item?.image_uri.substring(0, item?.image_uri.indexOf(".")) +
              (selectType && selectType.cd === item.cd ? "1" : "") +
              "." +
              item?.image_uri.substring(item?.image_uri.indexOf(".") + 1)
            }
            style={{height: `36px`, marginBottom: "5px", objectFit: "contain"}}
          />
        ) : (
          <div
            className="notranslate no-transform"
            style={{
              width: "36px",
              height: "36px",
              borderRadius: "18px",
              backgroundColor: "#efefef",
              marginLeft: "18px",
            }}
          ></div>
        )}
        <br/>
        <li style={{fontSize: "10px", wordBreak: "keep-all", marginBottom: !exceedsLine(lang === "kr" ? item.name_kr : item.name_en) ? "0px" : "-15px"}}>{lang === "kr" ? item.name_kr : item.name_en}</li>
      </button>
    </div>
  ));

  // 항목 핸들러
  const itemsList = arrItems.map((item, i) => (
    <div className="col">
      <button
        type="button"
        key={i}
        onClick={() => {
          selectItem.filter((select) => select.cd === item.cd).length === 0
            ? setSelectItem((select) => [...selectItem, item])
            : setSelectItem(
              selectItem.filter((select) => select.cd !== item.cd)
            );
        }}
        className={` btn btn-sm  ${
          selectItem.filter((select) => select.cd === item.cd).length > 0
            ? "category1_on"
            : "category1"
        }`}
      >
        <img
          src={require(`../../image/icon_prod_0${item.num}${
            selectItem.filter((select) => select.cd === item.cd).length > 0 ? "1" : ""
          }.svg`)}
          style={{width: `${item.width}px`, marginBottom: "5px", objectFit: "contain"}}
        />
        <br/>
        {item.name_kr}
      </button>
    </div>
  ));

  // 최종 선택
  const [result, setResult] = useState([]);
  const clickHandler = async (e) => {
    if (selectProduct === null || selectProduct?.length === 0) {
      await CommonLib.jalert("제품 분류를 선택해 주세요.");
      return;
    }
    if (selectType === null || selectType?.length === 0) {
      await CommonLib.jalert("제품 종류를 선택해 주세요.");
      return;
    }
    if (selectItem === null || selectItem?.length === 0) {
      await CommonLib.jalert("제작 항목을 선택해 주세요.");
      return;
    }

    _setResult([...result, {selectProduct, selectType, selectItem}]);
    await CommonLib.jalert("제품 생성을 완료하였습니다. 상세정보를 등록해주세요.");
  };

  // 제품 리스트에서 제외하기
  const cancelClick = (item) => {
    _setResult(result.filter((select) => select !== item));
  };

  const onDetail = (idx) => {
    setResultIdx(idx);
    // setMode("DETAIL");
    history.push({
      pathname: "/manufacture/detail",
      state: {result, idx},
    });
  };

  // 새로고침시 제품리스트가 하나라도 있으면 알림창 띄우기
  useBeforeunload((event) => {
    if (result.length > 0) {
      event.preventDefault();
    }
  });

  // 다른 페이지 이동시 제품리스트가 하나라도 있으면 알림창 띄우기
  useEffect((e) => {
      if (result?.length > 0) {
        let unlisten = history.listen(async (location) => {
          if (location.pathname !== '/manufacture/detail') {
            if (await CommonLib.jconfirm("제품 리스트의 내용들이 저장되지 않았습니다.")) {
            } else {
              unlisten();
              history.push("/manufacture");
            }
          }
        });

        return () => {
          unlisten();
        };
      }
    },
    [history && result?.length > 0]
  );

  const detailCallback = (result) => {
    if (result) _setResult(result);
    setMode("MAIN");
    window.scrollTo(0, 0);
  };

  if (mode === "DETAIL")
    return (
      <ManufactureDetail
        result={result}
        idx={resultIdx}
        callback={detailCallback}
      />
    );
  else
    return (
      <div className="BODY MAX my-4">
        <div
          className="mt-3 d-flex justify-content-between w-100"
          style={{height: "100%"}}
        >
          <div className="box_round pb-5">
            <div className="mx-5 my-4 pb-5">
              <div className="text-center border-bottom py-4 pb-5">
                <p className="h4 bold txt-point">
                  어떤 종류의 제품을 만들고 싶으신가요?
                </p>
                <p className="h6">
                  원하는 제품이 여러 개인가요? 제품 추가를 누르시면 됩니다.
                </p>
              </div>

              <div className="row row-cols-2 gx-3 my-3">
                <div className="col">
                  <div
                    className="border p_rative"
                    style={{minHeight: "100%", marginBottom: "90px"}}
                  >
                    <div className="p-3 h6 txt-gray border-bottom bg-lpurple">
                      <span className="h6 bold">01.제품 분류</span>를 선택하세요
                      (필수)
                    </div>

                    <div className="row row-cols-5 g-3 text-center px-3 my-4">
                      {productsList}
                    </div>

                    <div className="p-3 h6 txt-gray border-bottom bg-lpurple">
                      <span className="h6 bold">02.제품 종류</span>를 선택하세요
                      (필수)
                    </div>

                    <div className="row row-cols-5 g-3 text-center px-3 my-4">
                      {typesList}
                    </div>

                    <div className="p-3 h6 txt-gray border-bottom bg-lpurple">
                      <span className="h6 bold">03.제작을 원하시는 항목</span>을
                      선택하세요
                    </div>

                    <div className="row row-cols-5 g-3 text-center px-3 my-4">
                      {itemsList}
                    </div>

                    <div
                      className="border-top p-4 text-center w-100"
                      style={{position: "absolute", bottom: "0"}}
                    >
                      <button
                        onClick={clickHandler}
                        type="button"
                        className="btn btn_outline_puple hov_pup p-2 px-4 bold"
                      >
                        제품 생성 +
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="border p_rative"
                    style={{minHeight: "100%", marginBottom: "90px"}}
                  >
                    <div className="p-3 h6 txt-gray border-bottom bg-lpurple bold">
                      제품 리스트
                    </div>
                    {result.map((item, idx) => {
                      return (
                        <div className="d-flex px-3 my-4">
                          <div className="border p-3 w-100 p_rative">
                            <p
                              className="p_absolute_tr3"
                              style={{top: "5px"}}
                            >
                              <button
                                onClick={() => cancelClick(item)}
                                type="button"
                                className="btn btn-sm txt-sm p-0"
                              >
                                <img src={cancel_g} style={{width: "20px"}}/>
                              </button>
                            </p>
                            <div className="d-flex border-bottom pb-2">
                              <p className="p-2">
                                {item?.selectType?.image_uri &&
                                item?.selectType?.image_uri.length > 0 ? (
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      item?.selectType?.image_uri.substring(
                                        0,
                                        item?.selectType?.image_uri.indexOf(".")
                                      ) +
                                      "1." +
                                      item?.selectType?.image_uri.substring(
                                        item?.selectType?.image_uri.indexOf(
                                          "."
                                        ) + 1
                                      )
                                    }
                                    style={{
                                      width: `36px`,
                                      maxHeight: `36px`,
                                      marginBottom: "5px",
                                    }}
                                  />
                                ) : (
                                  <div
                                    style={{
                                      width: "36px",
                                      height: "36px",
                                      borderRadius: "18px",
                                      backgroundColor: "#efefef",
                                      marginLeft: "18px",
                                    }}
                                  ></div>
                                )}
                              </p>
                              <div className="mt-1 m-2">
                                <p className="mx-1 txt-point bold mb-2">
                                  {item.selectProduct.name_en} |{" "}
                                  {item.selectType.name_en}
                                </p>
                                <div className="d-flex">
                                  {item.selectItem.map((_item, i) => (
                                    <p className="border box_round_4 bg-lgray txt-sm px-2 mx-1">
                                      {_item.name_en}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>

                            <div className="pt-2">
                              <ul className="p-0 d-flex flex-wrap mb-2">
                                <li className="px-2 txt-sm txt-gray">
                                  <span className="bold">인증서 :</span>&nbsp;
                                  {item?.data?.selectedCertObj?.name_kr ||
                                    "선택안함"}
                                </li>
                                <li className="px-3 txt-sm txt-gray">
                                  <span className="bold">판매희망국가 :</span>
                                  &nbsp;
                                  {item?.data?.selectedCountryObj?.name_kr ||
                                    "선택안함"}
                                </li>
                                <li className="px-2 txt-sm txt-gray">
                                  <span className="bold">주요기능 :</span>
                                  &nbsp;
                                  {item?.data?.selectedFunctional?.name_kr ||
                                    "선택안함"}
                                </li>
                                <li className="px-2 txt-sm txt-gray">
                                  <span className="bold">예상단가 :</span>
                                  &nbsp;
                                  {CommonLib.numberWithCommas(
                                    item?.data?.unitPrice
                                  ) || "0"}
                                  {item?.data?.unit || "원"}
                                </li>
                              </ul>
                              {/* <p style={{ textAlign: "right" }}>
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-light"
                              style={{ minWidth: "95px" }}
                            >
                              상세등록 완료
                            </button>
                          </p> */}
                              {result?.[idx]?.done ? (
                                ""
                              ) : (
                                <p style={{paddingLeft: "7px", color: "red"}}>
                                  상세정보를 등록해주세요.
                                </p>
                              )}
                              <p style={{textAlign: "right"}}>
                                <button
                                  type="button"
                                  className="btn btn-sm btn_puple"
                                  style={{minWidth: "95px"}}
                                  onClick={() => onDetail(idx)}
                                >
                                  상세정보 등록{result?.[idx]?.done ? "완료" : ""}
                                </button>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div
                      className="border-top p-4 text-center w-100"
                      style={{position: "absolute", bottom: "0"}}
                    >
                      <button
                        type="button"
                        className="btn btn_puple p-2 px-4 bold"
                        onClick={async () => {
                          if ((localStorage.getItem("user.session.user_type") || '') !== 'BUYER') {
                            await CommonLib.jalert('바이어 회원만 이용 가능합니다.');
                            return;
                          }
                          await submit();
                        }}
                      >
                        의뢰서 보내기
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Manufacture;
