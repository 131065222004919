import React, {useEffect, useState} from "react";
import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import Constants from "../../../constants/constants";

const ManagerList = (props) => {
  const [admins, setAdmins] = useState([]);
  const [type, setType] = useState(props.type || "auth");

  const gets = async () => {
    const {data: {code, result, message}} = await APIHelper.get("admin/gets");
    if (code === 200) setAdmins(result);
  }

  const [selectAdmin, setSelectAdmin] = useState({});
  const select = (item) => {
    setSelectAdmin(item);
    props.click(item);
  }

  useEffect(() => {
    gets();
  }, [props.renew]);

  return (
    <div
      className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
      style={{height: "98%"}}
    >
      <div className="d-flex justify-content-between border-bottom">
        <p className="h5 bold px-2">Manager List</p>
        <p className="m-1 mb-2">
          {type === "auth" &&
            <button type="button" className="btn btn-purple txt-sm py-1 px-3" onClick={() => props.click({})}>
              추가
            </button>
          }
        </p>
      </div>
      <div
        className="wid_scroll scroll_y custom_scroll mt-2"
        style={{height: "calc(100% - 60px)"}}
      >
        {admins && admins.map((item, i) => {
          let accessCnt = 0;
          if (type === "auth") {
            if (item.auth1 === 'Y') accessCnt++;
            if (item.auth2 === 'Y') accessCnt++;
            if (item.auth3 === 'Y') accessCnt++;
            if (item.auth4 === 'Y') accessCnt++;
            if (item.auth5 === 'Y') accessCnt++;
            if (item.auth6 === 'Y') accessCnt++;
            if (item.auth7 === 'Y') accessCnt++;
            if (item.auth8 === 'Y') accessCnt++;
          }

          return (
            <div
              className={`box_round_5 ${item.id === selectAdmin.id ? "border_purple" : "border"} my-2 d-flex p-3`}
              onClick={() => {
                select(item);
              }}
            >
              <p className="btn_oval_lg mb-1 mx-1">
                {item.image_fid && item.image_fid.length > 0 ?
                  <img
                    src={`${Constants.API_HOST}/common/download?fid=${item.image_fid}&cd=inline&thumb=400`}
                    style={{width: "65px", height: "65px", objectFit: "cover"}}
                  />
                  :
                  <div style={{width: "65px", height: "65px", backgroundColor: "#efefef", borderRadius: "32.5px"}}></div>
                }
              </p>
              <div className="text-start mx-3">
                <p className="h6 mb-1">{item.name}</p>
                <p className="txt-sm">
                  {item.position} | {item.task}
                  <br/>
                  사용유무 : {item.use_yn} | 로그인 제한 :{" "} {item.block_yn}
                </p>
              </div>
              {type === "auth" &&
                <p className="ms-auto">
                  접근권한
                  <br/>
                  <span className="h3">{accessCnt}/8</span>
                </p>
              }
              {type === "review" &&
                <p className="ms-auto">
                  평점
                  <br/>
                  <span className="h3">{item.reviewScore.toFixed(1)}/5</span>
                </p>
              }
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default ManagerList;
