import React, {useState} from "react";
import "../../../sass/main.scss";
import ProfileBuyerReview from "./ProfileBuyerReview";
import {Link} from "react-router-dom";
import MypageMenu from "../../mypage/MypageMenu";

const ProfileBuyer = (props) => {
  const path = window.location.pathname;
  const [tabState, setTabState] = useState({
    home: true,
    detail: false,
    photo: false,
    review: false,
  });

  const tabClick = (e) => {
    const tab = {...tabState};
    const target = e.currentTarget.id;
    for (let key in tab) {
      target === key ? (tab[key] = true) : (tab[key] = false);
    }
    setTabState(tab);
  };

  return (
    <div
      className="BODY MAX my-4"
    >
      <div
        className="mt-3 d-flex justify-content-between w-100"
        style={{height: "100%"}}
      >
        <MypageMenu/>
        {tabState.review ? (
          <ProfileBuyerReview/>
        ) : (
          <div className="box_round cnt_area pb-5">
            <div className="mx-5 my-4 pb-5">
              {/*<div*/}
              {/*  className="p_absolute_tr d-flex"*/}
              {/*  style={{ top: "50px", right: "60px" }}*/}
              {/*>*/}
              {/*  <button type="button" className="btn btn-sm p-0 txt-gray">*/}
              {/*    <img src={icon_visibility} style={{ width: "20px" }} /> 내 프로필 미리보기{" "}*/}
              {/*  </button>*/}
              {/*</div>*/}
              <div className="TAB my-5">
                <li className={`${path === "/profile/buyer" || path === "/profile/buyer/home" ? "ON" : ""}`}>
                  <Link to="/profile/buyer/home" id="home">
                    기본정보
                  </Link>
                </li>
                <li className={`${path === "/profile/buyer/detail" ? "ON" : ""}`}>
                  <Link to="/profile/buyer/detail" id="detail">
                    상세정보
                  </Link>
                </li>
                <li className={`${path === "/profile/buyer/photo" ? "ON" : ""}`}>
                  <Link to="/profile/buyer/photo" id="photo">
                    사진
                  </Link>
                </li>
              </div>
              {props.children}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileBuyer;
