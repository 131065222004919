import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import openTTTransfer from "../../actions/openTTTransfer";

import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import Constants from "../../constants/constants";
import moment from "moment";
import CommonLib from "../../libraries/CommonLib";

const TTTransferModal = (props) => {
  const closeModal = () => {
    props.openTTTransfer("closed", "");
  };

  const {formType, title, projectId, projectProductId, projectMakerRequestId, timelineId, refCtnt1: _refCtnt1, refCtnt2: _refCtnt2, step, renew, status} = props.ttTransferPop?.content;

  const [refCtnt1, setRefCtnt1] = useState('');
  const [refCtnt2, setRefCtnt2] = useState('');

  const [tcShipment, setTcShipment] = useState('');
  const [tcPayment, setTcPayment] = useState('100% T/T in advance');
  const [tcIncoterms, setTcIncoterms] = useState('FOB INCHEON');
  const [tcOrigin, setTcOrigin] = useState('Republic of Korea');
  const [tcPacking, setTcPacking] = useState('Standard Export Packing');
  const [tcLeadTime, setTcLeadTime] = useState('Within 10 business days from your official order confirmation');
  const [tcOfferValidity, setTcOfferValidity] = useState('14 days from the date of offer');

  let modalInlineStyle;

  if (props.ttTransferPop.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }

  useEffect(() => {
    (async () => {
      if (!status) {
        if (_refCtnt1) {
          setRefCtnt1(_refCtnt1);

          if (_refCtnt1 === 'USD') {
            setRefCtnt2(_refCtnt2);
          } else {
            const {data: {code, result, message}} = await APIHelper.getForm("data/exchange/rates");
            setRefCtnt2(Math.round(CommonLib.parseFloat(JSON.parse(result).filter(item => item.cur_unit === 'USD')[0].tts.replace(',', '')) * CommonLib.parseFloat((_refCtnt2))));
          }
        }
      } else {
        {
          let _status = ''
          if (status.includes('샘플')) _status = '샘플 비용 결제 요청';
          else if (status.includes('제작')) _status = '제작 비용 결제 요청';
          else if (status.includes('잔금')) _status = '잔금 결제 요청';

          const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/get", {
            projectId,
            projectProductId,
            projectMakerRequestId,
            type: 'BUYER',
            status: _status + " - T.T송금",
          });
          setRefCtnt1(result?.ref_ctnt_1);
          setRefCtnt2(result?.ref_ctnt_2);
        }
      }
      let estimateId = 0;
      {
        let _status = ''
        if ((status && status.includes('샘플')) || (title && title.includes('샘플')) || (formType && formType.includes('샘플'))) _status = '샘플 견적서 확인 및 진행 유무 판단';
        else if ((status && status.includes('제작')) || (title && title.includes('제작')) || (formType && formType.includes('제작'))) _status = '바이어용 본 견적서 확인 및 진행 유무 판단 #1';
        else if ((status && status.includes('잔금')) || (title && title.includes('잔금')) || (formType && formType.includes('잔금'))) _status = '바이어용 본 견적서 확인 및 진행 유무 판단 #1';

        const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/get", {
          projectId,
          projectProductId,
          id: timelineId,
          type: 'BUYER',
          status: _status,
        });
        estimateId = result.ref_id_5;
      }
      {
        const {data: {code: retCode, result, message}} = await APIHelper.getForm("project/estimate/get", {id: estimateId});

        setTcShipment(result?.tc_shipment || '');
        setTcPayment(result?.tc_payment || '');
        setTcIncoterms(result?.tc_incoterms || '');
        setTcOrigin(result?.tc_origin || '');
        setTcPacking(result?.tc_packing || '');
        setTcLeadTime(result?.tc_lead_time || '');
        setTcOfferValidity(result?.tc_offer_validity || '');
      }
    })();
  }, [renew, status])

  const submit = async () => {
    {
      const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
        projectId,
        projectProductId,
        projectMakerRequestId,
        type: 'BUYER',
        step,
        status: formType + ' - T.T송금',
        refCtnt1,
        refCtnt2,
      });
    }
    {
      const {data: {code, result, message}} = await APIHelper.putForm("project/change/status", {
        projectId,
        projectProductId,
        projectMakerRequestId,
        type: 'MANAGER',
        step,
        status: formType.replace(' 요청', ' 확인 및 결정'),
        refCtnt1,
        refCtnt2,
      });
    }

    closeModal();
  }

  return (
    <div style={{display: modalInlineStyle, zIndex: 9999999}}>
      <div className="pop_box02" style={{width: "850px", minHeight: "500px"}}>
        <div className="POP_title pb-3">
          <h4><strong>T.T 송금</strong></h4>
          <p className="closed">
            <button type="button" className="btn" onClick={() => closeModal()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </button>
          </p>
        </div>

        <h5 className="border-top pt-3"><strong>TERMS & CONDITION</strong></h5>

        <div className="POP_cnt_box_lg" style={{marginBottom: "90px"}}>
          <div className="row">
            <div className="col-9">
              <table className="table basic_sm w-100">
                <colgroup>
                  <col width="25%"></col>
                  <col width="*"></col>
                </colgroup>
                <tr>
                  <th className="center txt-sm">Shipment</th>
                  <td>{tcShipment}</td>
                </tr>
                <tr>
                  <th className="center txt-sm">Payment</th>
                  <td>{tcPayment}</td>
                </tr>
                <tr>
                  <th className="center txt-sm">Incoterms</th>
                  <td>{tcIncoterms}</td>
                </tr>
                <tr>
                  <th className="center txt-sm">Origin</th>
                  <td>{tcOrigin}</td>
                </tr>
                <tr>
                  <th className="center txt-sm">Packing</th>
                  <td>{tcPacking}</td>
                </tr>
                <tr>
                  <th className="center txt-sm">Lead Time</th>
                  <td>{tcLeadTime}</td>
                </tr>
                <tr>
                  <th className="center txt-sm">Offer Validity</th>
                  <td>{tcOfferValidity}</td>
                </tr>
              </table>
            </div>
            <div className="col-3">
              <div className="border_purple2 rounded p-4">
                <h5 className="txt-dark bold">CURRENCY SELECTED :</h5>
                <h4 className="txt-purple3 bold">{refCtnt1}</h4>
              </div>
            </div>
          </div>

          <br/>
          <h5><strong>BANK INFORMATION</strong></h5>
          {refCtnt1 === 'USD' ?
            <table className="table basic_ssm border_HC w-100">
              <colgroup>
                <col width="30%"></col>
                <col width="*"></col>
              </colgroup>
              <tr>
                <th className="center txt-sm">Name of Bank</th>
                <td>SHINHAN BANK (신한은행)</td>
              </tr>
              <tr>
                <th className="center txt-sm">Branch Address</th>
                <td>
                  20, SEJONG-DAERO 9-GIL, JUNG-GU, SEOUL, SOUTH KOREA (04513)
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">SWIFT Code</th>
                <td>
                  SHBKKRSE
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">Recipient's Name</th>
                <td>BIGPIE C&T CO.,LTD</td>
              </tr>
              <tr>
                <th className="center txt-sm">Recipient's Account Number</th>
                <td>180-008-428825</td>
              </tr>
              <tr>
                <th className="center txt-sm">Contact Phone Number</th>
                <td>+82 10-7228-8828</td>
              </tr>
            </table>
            :
            <table className="table basic_ssm border_HC w-100">
              <colgroup>
                <col width="30%"></col>
                <col width="*"></col>
              </colgroup>
              <tr>
                <th className="center txt-sm">Name of Bank</th>
                <td>Wooribank(우리은행)</td>
              </tr>
              <tr>
                <th className="center txt-sm">Branch Address</th>
                <td>
                  1585 Sangam-dong, Mapo-gu, Seoul, Republic of Korea
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">SWIFT Code</th>
                <td>
                  HVBKKRSEXXX
                </td>
              </tr>
              <tr>
                <th className="center txt-sm">Recipient's Name</th>
                <td>BIGPIE C&T CO.,LTD</td>
              </tr>
              <tr>
                <th className="center txt-sm">Recipient's Account Number</th>
                <td>1005-003-178314</td>
              </tr>
              <tr>
                <th className="center txt-sm">Contact Phone Number</th>
                <td>+82 10-7228-8828</td>
              </tr>
            </table>
          }
          <div className="text-center border_purple2 rounded pt-4 pb-3"><h4 className="txt-dark">Please deposit <span
            className="h4 bold txt-red">{refCtnt1 === 'USD' ? ('$' + CommonLib.floatWithCommas(refCtnt2)) : (CommonLib.numberWithCommas(refCtnt2) + '원')}</span> to the bank address</h4></div>
        </div>
        <div className="POP_foot">
          <button className="btn px-4 btn_puplelight" type="button" onClick={() => closeModal()} style={{marginRight: "15px"}}>닫기</button>
          {!status && <button className="btn px-4 btn_puple" type="button" onClick={() => submit()}>확인</button>}
        </div>
      </div>
      <div className="pop_bak"></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    ttTransferPop: state.ttTransferPop,
  };
}

function mapDispatchToProps(dispatcher) {
  return bindActionCreators(
    {
      openTTTransfer: openTTTransfer,
    },
    dispatcher
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TTTransferModal);
