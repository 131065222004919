import React, {useEffect, useRef, useState} from "react";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import ManagerList from "./../common/ManagerList";
import APIHelper from "../../../helpers/APIHelper";
import Constants from "../../../constants/constants";
import CommonLib from "../../../libraries/CommonLib";
import PasswordChecklist from "react-password-checklist";

const AdminManager = (props) => {
  const [imageFile, setImageFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const imageFileRef = useRef();

  const handleImageChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setImageFile(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const [renew, setRenew] = useState(0);

  const [id, setId] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validPassword, setValidPassword] = useState(true);
  const [name, setName] = useState('');
  const [nickname, setNickname] = useState('');
  const [imageFid, setImageFid] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [phone3, setPhone3] = useState('');
  const [position, setPosition] = useState('');
  const [task, setTask] = useState('');
  const [useYn, setUseYn] = useState('Y');
  const [blockYn, setBlockYn] = useState('N');
  const [remark, setRemark] = useState('');
  const [grade, setGrade] = useState(50);
  const [auth1, setAuth1] = useState('Y');
  const [auth2, setAuth2] = useState('Y');
  const [auth3, setAuth3] = useState('Y');
  const [auth4, setAuth4] = useState('Y');
  const [auth5, setAuth5] = useState('Y');
  const [auth6, setAuth6] = useState('Y');
  const [auth7, setAuth7] = useState('Y');
  const [auth8, setAuth8] = useState('Y');

  useEffect(() => {
    setValidPassword(true);
  }, [id])

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "어드민 관리 > 매니저 현황",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const select = (admin) => {
    setId(admin?.id || 0)
    setEmail(admin?.email || '');
    setPassword('');
    setName(admin?.name || '');
    setNickname(admin?.nickname || '');
    setImageFid(admin?.image_fid || '');
    if (admin?.phone?.length > 0 && admin?.phone.includes('-')) {
      setPhone1(admin?.phone.split('-')[0]);
      setPhone2(admin?.phone.split('-')[1]);
      setPhone3(admin?.phone.split('-')[2]);
    } else {
      setPhone1('');
      setPhone2('');
      setPhone3('');
    }
    setPosition(admin?.position || '');
    setTask(admin?.task || '');
    setUseYn(admin?.use_yn || 'Y');
    setBlockYn(admin?.block_yn || 'N');
    setRemark(admin?.remark || '');
    setGrade(admin?.grade || 50);
    setAuth1(admin?.auth1 || '');
    setAuth2(admin?.auth2 || '');
    setAuth3(admin?.auth3 || '');
    setAuth4(admin?.auth4 || '');
    setAuth5(admin?.auth5 || '');
    setAuth6(admin?.auth6 || '');
    setAuth7(admin?.auth7 || '');
    setAuth8(admin?.auth8 || '');

    if (admin?.grade == 99) setAuth1('Y');
    else setAuth1('N');
  }

  const register = async () => {
    try {
      const files = imageFileRef?.current?.files;
      const response = await APIHelper.postForm("admin/register", {
        id,
        email,
        password,
        name,
        nickname,
        image: files ? files[0] : null,
        phone: phone1 + '-' + phone2 + '-' + phone3,
        position,
        task,
        useYn,
        blockYn,
        remark,
        grade,
        auth1,
        auth2,
        auth3,
        auth4,
        auth5,
        auth6,
        auth7,
        auth8
      });
      const {data: {code, result, message}} = response;
      if (code === 200) {

        try {
          await APIHelper.postForm("admin/action/log", {
            content: id ? "어드민 관리 > 매니저 현황 > 수정" : "어드민 관리 > 매니저 현황 > 저장",
            param1 : id,
            param2 : email,
          });
        } catch (err) {
          console.log(err);
        }
        setRenew(new Date().getTime());
      }
      await CommonLib.jalert(message);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <section className="col-4" style={{height: "100%"}}>
          <ManagerList click={select} renew={renew}/>
        </section>

        <section className="col-8" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">Manager Detail</p>
              <p className="m-1 mb-2">
                <button
                  type="button"
                  className="btn btn-purple txt-sm py-1 px-3"
                  onClick={register}
                  disabled={(password?.length > 0 && !validPassword) || (id === 0 && password?.length === 0)}
                >
                  저장
                </button>
              </p>
            </div>

            <div
              className="wid_scroll scroll_y custom_scroll mt-2"
              style={{height: "calc(100% - 60px)"}}
            >
              <article className="wid_inline mt-4">
                <div className="d-flex ">
                  <p className="text-center" style={{marginRight: "15px"}}>
                    {(imageFid || imagePreviewUrl) ?
                      <img
                        src={imageFid.length > 0 ? `${Constants.API_HOST}/common/download?fid=${imageFid}&cd=inline&thumb=400` : imagePreviewUrl}
                        style={{
                          width: "190px",
                          height: "190px",
                          objectFit: "cover",
                        }}
                        onClick={() => imageFileRef.current.click()}
                      />
                      :
                      <div style={{width: "190px", height: "190px", backgroundColor: "#efefef"}}></div>
                    }
                    <input
                      type="file"
                      ref={imageFileRef}
                      style={{display: "none"}}
                      onChange={handleImageChange}
                    />
                    <button
                      type="button"
                      className="btn btn-sm btn-purple w-100 my-2"
                      style={{marginLeft: "0px"}}
                      onClick={() => imageFileRef.current.click()}
                    >
                      이미지 등록
                    </button>
                  </p>
                  <div className="">
                    <table className="table basic">
                      <colgroup>
                        <col width="19%"/>
                        <col width="25%"/>
                        <col width="19%"/>
                        <col width="*"/>
                      </colgroup>
                      <tr>
                        <th className="center">아이디(이메일)</th>
                        <td colSpan="3">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="이메일 입력"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setEmail(e.target.value)}
                            value={email}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">비밀번호 설정</th>
                        <td colSpan="3">
                          <input
                            type="password"
                            className="form-control form-control-sm"
                            placeholder="비밀번호 입력"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setPassword(e.target.value)}
                            value={password}
                          />
                          {password?.length > 0 &&
                            <PasswordChecklist
                              rules={[
                                "minLength",
                                "specialChar",
                                "number",
                                // "match"
                                // "capital",
                              ]}
                              minLength={7}
                              value={password}
                              // valueAgain={passwordRe}
                              messages={{
                                minLength: "비밀번호는 최소 7자 이상이어야 합니다.",
                                specialChar: "비밀번호는 특수문자를 포함해야 합니다.",
                                number: "비밀번호는 숫자를 포함해야 합니다.",
                                capital: "비밀번호는 대문자를 포함해야 합니다.",
                                match: "비밀번호가 일치합니다.",
                              }}
                              onChange={(e) => setValidPassword(e)}
                            />
                          }
                        </td>
                      </tr>
                      <tr>
                        <th className="center">이름</th>
                        <td className="border-top">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="이름 입력"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setName(e.target.value)}
                            value={name}
                          />
                        </td>
                        <th className="center">닉네임</th>
                        <td>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="닉네임 입력"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setNickname(e.target.value)}
                            value={nickname}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">전화번호</th>
                        <td colspan="3">
                          <div className="input-group w-65">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              onChange={async (e) => await setPhone1(e.target.value)}
                              value={phone1}
                            />
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              onChange={async (e) => await setPhone2(e.target.value)}
                              value={phone2}
                            />
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              onChange={async (e) => await setPhone3(e.target.value)}
                              value={phone3}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="center">직급</th>
                        <td>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="직급 입력"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setPosition(e.target.value)}
                            value={position}
                          />
                        </td>
                        <th className="center">담당업무</th>
                        <td>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="담당업무 입력"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setTask(e.target.value)}
                            value={task}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">사용유무</th>
                        <td>
                          <select className="form-select form-select-sm"
                                  onChange={async (e) => await setUseYn(e.target.value)}
                          >
                            <option selected={useYn === 'Y'} value="Y">사용함</option>
                            <option selected={useYn === 'N'} value="N">사용안함</option>
                          </select>
                        </td>

                        <th className="center">로그인 제한</th>
                        <td>
                          <select className="form-select form-select-sm"
                                  onChange={async (e) => await setBlockYn(e.target.value)}
                          >
                            <option selected={blockYn === 'N'} value="N">로그인 가능</option>
                            <option selected={blockYn === 'Y'} value="Y">로그인 차단</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th className="center">운영 등급</th>
                        <td>
                          <select className="form-select form-select-sm"
                                  onChange={async (e) => {
                                    await setGrade(e.target.value);
                                    console.log(e.target.value);
                                    if (e.target.value == 99) setAuth1('Y');
                                    else setAuth1('N');
                                  }}
                          >
                            <option value="99" selected={grade === 99}>관리자</option>
                            <option value="50" selected={grade === 50}>매니저</option>
                          </select>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="d-flex justify-content-between border-bottom pb-2 mt-4">
                  <p className="txt-md">비고</p>
                  <p></p>
                </div>

                <table className="table basic w-100">
                  <tr>
                    <th className="center">
                      <textarea
                        style={{background: "#fff"}}
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        onChange={async (e) => await setRemark(e.target.value)}
                        value={remark}
                      ></textarea>
                    </th>
                  </tr>
                </table>
                {/*<div className="text-end txt-sm txt-gray">*/}
                {/*  <p>등록일 : 2022.01.04 11:10</p>*/}
                {/*</div>*/}

                <div>
                  <p className="py-3 border-bottom txt-md">
                    메뉴 접근 권한 (그룹별)
                  </p>
                  <div className="border-bottom d-flex flex-wrap p-3">
                    <div className="form-check w-48 m-2">
                      <input
                        className="form-check-input mt-1"
                        type="checkbox"
                        id="flexCheckDefault1"
                        // onChange={(e) => (e.currentTarget.checked || grade === 99) ? setAuth1('Y') : setAuth1('N')}
                        checked={auth1 === 'Y'}
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault1"
                      >
                        어드민관리
                      </label>
                    </div>

                    <div className="form-check w-48 m-2">
                      <input
                        className="form-check-input mt-1"
                        type="checkbox"
                        onChange={(e) => e.currentTarget.checked ? setAuth2('Y') : setAuth2('N')}
                        checked={auth2 === 'Y'}
                        id="flexCheckDefault2"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault2"
                      >
                        홈노출/검색관리
                      </label>
                    </div>
                    <div className="form-check w-48 m-2">
                      <input
                        className="form-check-input mt-1"
                        type="checkbox"
                        onChange={(e) => e.currentTarget.checked ? setAuth3('Y') : setAuth3('N')}
                        checked={auth3 === 'Y'}
                        id="flexCheckDefault3"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault3"
                      >
                        운영관리
                      </label>
                    </div>
                    <div className="form-check w-48 m-2">
                      <input
                        className="form-check-input mt-1"
                        type="checkbox"
                        onChange={(e) => e.currentTarget.checked ? setAuth4('Y') : setAuth4('N')}
                        checked={auth4 === 'Y'}
                        id="flexCheckDefault4"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault4"
                      >
                        바이어관리
                      </label>
                    </div>
                    <div className="form-check w-48 m-2">
                      <input
                        className="form-check-input mt-1"
                        type="checkbox"
                        onChange={(e) => e.currentTarget.checked ? setAuth5('Y') : setAuth5('N')}
                        checked={auth5 === 'Y'}
                        id="flexCheckDefault5"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault5"
                      >
                        제조사관리
                      </label>
                    </div>

                    <div className="form-check w-48 m-2">
                      <input
                        className="form-check-input mt-1"
                        type="checkbox"
                        onChange={(e) => e.currentTarget.checked ? setAuth6('Y') : setAuth6('N')}
                        checked={auth6 === 'Y'}
                        id="flexCheckDefault6"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault6"
                      >
                        프로젝트관리
                      </label>
                    </div>
                    <div className="form-check w-48 m-2">
                      <input
                        className="form-check-input mt-1"
                        type="checkbox"
                        onChange={(e) => e.currentTarget.checked ? setAuth7('Y') : setAuth7('N')}
                        checked={auth7 === 'Y'}
                        id="flexCheckDefault7"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault7"
                      >
                        고객서비스
                      </label>
                    </div>
                    <div className="form-check w-48 m-2">
                      <input
                        className="form-check-input mt-1"
                        type="checkbox"
                        onChange={(e) => e.currentTarget.checked ? setAuth8('Y') : setAuth8('N')}
                        checked={auth8 === 'Y'}
                        id="flexCheckDefault8"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault8"
                      >
                        매니저 개인홈
                      </label>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminManager;
