import React, {useEffect, useState} from "react";
import MakerProjectCard from "./MakerProjectCard";
import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";
import {useHistory} from "react-router-dom";

const MakerProjectRequestDetail = (props) => {
  const id = props?.location?.state?.id || props?.match?.params?.id;
  const [request, setRequest] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const fetch = async () => {
      await getRequest();
    }
    fetch();
  }, []);

  const getRequest = () => {
    return new Promise(async (resolve, reject) => {
      if (id) {
        const {data: {code, result, message}} = await APIHelper.getForm("project/maker/request/get", {id});
        if (code === 200) {
          setRequest(result);
        }
      }
      return resolve(true);
    });
  }

  const accept = async () => {
    if (!await CommonLib.jconfirm('해당 의뢰를 수락하시겠습니까?')) return;
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.putForm("project/maker/request/status", {projectId: request.project_id, id, status_cd: 'Y'});
      await getRequest();
      return resolve(true);
    });
  }

  const deny = async () => {
    if (!await CommonLib.jconfirm('해당 의뢰를 거절하시겠습니까?')) return;
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.putForm("project/maker/request/status", {projectId: request.project_id, id, status_cd: 'N'});
      await getRequest();
      return resolve(true);
    });
  }

  return (
    <MakerProjectCard id={id}>
      <div className="box_round min_h cnt_area pb-5">
        <div className="mx-5 my-4 pb-5">
          <p className="h3 bold py-3">제작 의뢰 상세</p>

          <table className="table basic w-100">
            <colgroup>
              <col width="28%"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th className="center ">제품 분류</th>
              <td>
                <div className="d-flex">
                  <p className="box_round_4_paddingNo border_purple1 px-3">
                    {request?.category?.name_kr}
                  </p>
                  <p className="box_round_4_paddingNo border_purple1 px-3 mx-1">
                    {request?.product?.name_kr}
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <th className="center ">의뢰서 제목 및 의뢰 번호</th>
              <td>
                <p style={{padding: "0"}} className="bold txt-point">
                  {request?.request_form?.request_name} ({request?.request_form?.request_no})
                </p>
              </td>
            </tr>

            {request?.request_form && request?.request_form?.request_type === 'MF' &&
              <>
                <tr>
                  <th className="center">용기 배송 여부</th>
                  <td>{request?.request_form.mf_container_yn === 1 ? '배송' : '배송안함'}</td>
                </tr>
                <tr>
                  <th className="center">단상자 배송 여부</th>
                  <td>{request?.request_form.mf_box_yn === 1 ? '배송' : '배송안함'}</td>
                </tr>
                <tr>
                  <th className="center">브랜드명</th>
                  <td>{request?.request_form.mf_brand_name}</td>
                </tr>
                <tr>
                  <th className="center">제품명(가제)</th>
                  <td>{request?.request_form.mf_product_name}</td>
                </tr>
                <tr>
                  <th className="center">용량</th>
                  <td>{request?.request_form.mf_volume}</td>
                </tr>
                <tr>
                  <th className="center">제형/타겟 제품</th>
                  <td>{request?.request_form.mf_target_item}</td>
                </tr>
                <tr>
                  <th className="center">향/색</th>
                  <td>{request?.request_form.mf_scent}</td>
                </tr>
                <tr>
                  <th className="center">제품 컨셉</th>
                  <td>{CommonLib.escapedNewLineToLineBreakTag(request?.request_form.mf_concept)}</td>
                </tr>
              </>
            }
            {request?.request_form && request?.request_form?.request_type === 'DS' &&
              <>
                <tr>
                  <th className="center">용기디자인 여부</th>
                  <td>{request?.request_form.ds_design_bottle_yn === 1 ? '디자인' : '디자인 안함'}</td>
                </tr>
                <tr>
                  <th className="center">단상자디자인 여부</th>
                  <td>{request?.request_form.ds_design_box_yn === 1 ? '디자인' : '디자인 안함'}</td>
                </tr>
                <tr>
                  <th className="center">브랜드디자인 여부</th>
                  <td>{request?.request_form.ds_design_brand_yn === 1 ? '디자인' : '디자인 안함'}</td>
                </tr>
                <tr>
                  <th className="center">디자인 고려사항</th>
                  <td>{CommonLib.escapedNewLineToLineBreakTag(request?.request_form.ds_concept)}</td>
                </tr>
              </>
            }
            {request?.request_form && (request?.request_form?.request_type === 'BT' || request?.request_form?.request_type === 'BX') &&
              <>
                <tr>
                  <th className="center">제품컨셉</th>
                  <td>{CommonLib.escapedNewLineToLineBreakTag(request?.request_form.bx_concept)}</td>
                </tr>
                <tr>
                  <th className="center">그 외 개발유의사항</th>
                  <td>{CommonLib.escapedNewLineToLineBreakTag(request?.request_form.bx_ex_requests)}</td>
                </tr>
                <tr>
                  <th className="center">희망공급가</th>
                  <td>{request?.request_form.bx_desired_supply_price}</td>
                </tr>
                <tr>
                  <th className="center">소비자가격</th>
                  <td>{request?.request_form.bx_retail_price}</td>
                </tr>
                <tr>
                  <th className="center">MOQ</th>
                  <td>{request?.request_form.bx_moq}</td>
                </tr>
              </>
            }
            {/*<tr>*/}
            {/*  <th className="center">피드백</th>*/}
            {/*  <td>*/}
            {/*    <textarea*/}
            {/*      className="form-control"*/}
            {/*      id="exampleFormControlTextarea1"*/}
            {/*      rows="5"*/}
            {/*    />*/}
            {/*  </td>*/}
            {/*</tr>*/}
          </table>

          <div className="text-center">
            {/*<button*/}
            {/*  className="btn btn_puple px-4"*/}
            {/*  type="button"*/}
            {/*  style={{marginRight: "5px"}}*/}
            {/*>*/}
            {/*  의견 접수*/}
            {/*</button>*/}
            {request && request?.status_cd === 'R' &&
              <>
                <button
                  className="btn btn_puple px-4"
                  type="button"
                  style={{marginRight: "5px"}}
                  onClick={() => accept()}
                >
                  제작 의뢰 수락
                </button>
                <button className="btn btn_puple px-4" type="button" style={{marginRight: "5px"}} onClick={() => deny()}>
                  제작 의뢰 거부
                </button>
              </>
            }
            <button className="btn btn_puplelight px-4" type="button" onClick={() => history.goBack()}>
              돌아가기
            </button>
          </div>
        </div>
      </div>
    </MakerProjectCard>
  );
};

export default MakerProjectRequestDetail;
