import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import $ from 'jquery';
import CommonLib from "../libraries/CommonLib";
import {changeLanguage} from "i18next";

function FixedFooter({history}) {
  const _handleResize = () => {
    if (window.location.pathname.includes('/admin')) return;

    function isHidden(el) {
      const style = window.getComputedStyle(el);
      return ((style.display === 'none') || (style.visibility === 'hidden'));
    }

    let diff = 320;
    const pathname = window.location.pathname;
    if (pathname.includes('/login') || pathname.includes('/find/id') || pathname.includes('/find/password'))
      diff = 272;

    let all = document.querySelectorAll('.BODY');
    for (let i = 0, max = all.length; i < max; i++) {
      if (!isHidden(all[i])) {
        const el = all[i];
        el.style.height = "auto";
        const contHeight = window.innerHeight < document.body.scrollHeight ? document.body.scrollHeight : window.innerHeight;
        if (contHeight - diff < window.innerHeight)
          el.style.height = (contHeight - diff) + "px";
      }
    }
  }

  useEffect(() => {
    document.onreadystatechange = () => {
      _handleResize();
    }

    const resizeObserver = new ResizeObserver(function () {
      _handleResize();
    });
    resizeObserver.observe($('BODY')[0]);

    $(document).bind("DOMSubtreeModified", function () {
      _handleResize();
    });

    CommonLib.processTranslation();

    // 2023.08.08 수정
    {
      changeLanguage("en");

      const myframe = $("iframe[title='언어 번역 위젯']");
      myframe.addClass('noshow');
      try {
        myframe.contents().find('span.text:contains(' + "영어" + ')').get(0).click();
      } catch (err) {
        console.log(err);
      }

      const lang = '영어';
      const frame = $('.goog-te-menu-frame:first');

      // frame.css({'border': 'none !important', 'background-color': 'transparent !important', 'position': 'absolute !important', 'top': '-9999px !important'})
      // myframe.css({'border': 'none !important', 'background-color': 'transparent !important', 'position': 'absolute !important', 'top': '-9999px !important'})
      // setTimeout(() => {
      //   $('.skiptranslate iframe').wrap("<div/>").addClass("aadd sj");
      //   $('body').css('opacity', 100);
      // }, 1000)

      try {
        frame.contents().find('.goog-te-menu2-item span.text:contains(' + lang + ')').get(0).click();
      } catch (err) {
      }
      try {
        frame.contents().find('.goog-te-menu2-item span.text:contains(' + lang + ')').get(0).click();
      } catch (err) {
      }
    }

    return () => {
      resizeObserver.unobserve($('BODY')[0]);
      $(document).unbind("DOMSubtreeModified");
    }
  }, [window.location.pathname]);

  return null;
}

export default withRouter(FixedFooter);
