import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import openReviewMaker from "../../actions/openReviewMaker";

import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";

const ReviewMakerModal = (props) => {
  const closeModal = () => {
    props.openReviewMaker("closed", "");
  };

  const {projectId, renew, readOnly} = props.reviewMakerPop?.content;

  let modalInlineStyle;

  if (props.reviewMakerPop.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }

  const [result, setResult] = useState();
  const [currFormedProduct, setCurrFormedProduct] = useState();
  const [currIdx, setCurrIdx] = useState(-1);
  const [category, setCategory] = useState();
  const [product, setProduct] = useState();

  const [mfScore, setMfScore] = useState(3);
  const [dsScore, setDsScore] = useState(3);
  const [btScore, setBtScore] = useState(3);
  const [bxScore, setBxScore] = useState(3);

  const [mfContent, setMfContent] = useState('');
  const [btContent, setBtContent] = useState('');
  const [bxContent, setBxContent] = useState('');
  const [dsContent, setDsContent] = useState('');

  const [progress, setProgress] = useState(20);

  useEffect(() => {
    (async () => {
      setCurrIdx(-1);

      const {data: {code, result, message}} = await APIHelper.getForm("project/review/form", {projectId});
      if (code === 200) {
        setResult(result);
        setCurrIdx(0);
      }
    })();
  }, [renew]);

  useEffect(() => {
    (async () => {
      const d = result?.formed_products?.[currIdx];

      setMfContent(d?.mf_review?.content || '');
      setBtContent(d?.bt_review?.content || '');
      setBxContent(d?.bx_review?.content || '');
      setDsContent(d?.ds_review?.content || '');

      setMfScore(d?.mf_review?.review_score || 3);
      setDsScore(d?.ds_review?.review_score || 3);
      setBtScore(d?.bt_review?.review_score || 3);
      setBxScore(d?.bx_review?.review_score || 3);

      const p = d?.project_product;
      setCurrFormedProduct(d);
      setCategory(await getCode(p?.category_cd));
      setProduct(await getCode(p?.product_cd));

      const total = result?.formed_products?.length;
      setProgress(Math.round(currIdx / total * 100));
    })();
  }, [currIdx]);

  const getCode = (cd) => {
    return new Promise(async (resolve, reject) => {
      const {
        data: {code, result, message},
      } = await APIHelper.getForm("code/get", {cd});
      return resolve(result);
    });
  };

  const onNext = async () => {
    setCurrIdx(currIdx + 1);
    if (currIdx >= result?.formed_products?.length - 1) closeModal();
  }

  const Stars = (props) => {
    const [score, setScore] = useState(props.defaultScore);
    return (
      <>
        <img src={require(score >= 1 ? `../../image/star_r.svg` : `../../image/star_g.svg`).default} style={{width: props.large ? '21px' : '18px'}} onClick={() => {
          if (readOnly) return;
          setScore(1);
          props.callback(1);
        }}/>
        <img src={require(score >= 2 ? `../../image/star_r.svg` : `../../image/star_g.svg`).default} style={{width: props.large ? '21px' : '18px'}} onClick={() => {
          if (readOnly) return;
          setScore(2);
          props.callback(2);
        }}/>
        <img src={require(score >= 3 ? `../../image/star_r.svg` : `../../image/star_g.svg`).default} style={{width: props.large ? '21px' : '18px'}} onClick={() => {
          if (readOnly) return;
          setScore(3);
          props.callback(3);
        }}/>
        <img src={require(score >= 4 ? `../../image/star_r.svg` : `../../image/star_g.svg`).default} style={{width: props.large ? '21px' : '18px'}} onClick={() => {
          if (readOnly) return;
          setScore(4);
          props.callback(4);
        }}/>
        <img src={require(score >= 5 ? `../../image/star_r.svg` : `../../image/star_g.svg`).default} style={{width: props.large ? '21px' : '18px'}} onClick={() => {
          if (readOnly) return;
          setScore(5);
          props.callback(5);
        }}/>
      </>
    )
  }

  return (
    <div style={{display: modalInlineStyle, zIndex: 9999999}}>
      <div className="pop_box02" style={{width: "850px", minHeight: "500px"}}>
        <div className="POP_title pb-3">
          <h4><strong>후기 보기</strong></h4>
          <p className="closed">
            <button type="button" className="btn" onClick={() => closeModal()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </button>
          </p>
        </div>

        {currFormedProduct && currIdx < result?.formed_products?.length &&
          <>
            <div className="POP_cnt_box_lg" style={{marginBottom: "90px"}}>

              <div className="d-flex justify-content-between border-bottom border-top pb-2 pt-3">
                <div>
                  <h5 className="txt-point"><strong>{result?.project?.name}</strong></h5>
                  <span className="txt-gray txt-sm">(프로젝트 No:{result?.project?.prj_no})</span>
                </div>
                <div className="d-flex">
                  <p className="p-2">
                    {product?.image_uri &&
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          product?.image_uri.substring(
                            0,
                            product?.image_uri.indexOf(".")
                          ) +
                          "1." +
                          product?.image_uri.substring(
                            product?.image_uri.indexOf(
                              "."
                            ) + 1
                          )
                        }
                        style={{width: "15px", marginBottom: "5px"}}
                      />
                    }
                  </p>
                  <p className="pt-2">
                    <span className="h6 bold">{currFormedProduct?.name}</span><br/>
                    <span className="txt-sm">{category?.name_kr} | {product?.name_kr}</span>
                  </p>
                </div>
              </div>
              {/*<div className="pt-2">*/}
              {/*  <span className="h6 bold">바이어 : {}</span><br/>*/}
              {/*</div>*/}

              <table className="w-100 border-bottom mt-3 mb-3" style={{backgroundColor: "#efefef"}}>
                <tr>
                  <td style={{width: "50px", height: "30px", textAlign: "center", fontSize: "13px"}}>{progress}%</td>
                  <td style={{height: "5px", paddingRight: "10px"}}>
                    <div style={{width: `100%`, height: "5px", backgroundColor: "darkgray"}}>
                      <div style={{width: `${progress}%`, height: "5px", backgroundColor: "mediumpurple"}}/>
                    </div>
                  </td>
                </tr>
              </table>

              <div style={{display: currFormedProduct?.mf_request_id > 0 ? "block" : "none"}}>
                <div className="d-flex py-1">
                  <div className="p-1 d-flex"><p className="Oval_dot_pup m-1 ms-auto "></p><h6 className="bold px-2">내용물은 어떠셨나요?</h6></div>
                  <div className="px-3">
                    <li className="">
                      <Stars defaultScore={mfScore} callback={(score) => setMfScore(score)}/>
                    </li>
                  </div>
                </div>
                <table className="table basic_sm w-100">
                  <colgroup>
                    <col width="20%"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th className="center">후기</th>
                    <td>
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
                                onChange={async (e) => await setMfContent(e.target.value)}
                                readOnly={!!readOnly}
                                value={mfContent}
                      ></textarea>
                    </td>
                  </tr>
                </table>
              </div>
              <div style={{display: currFormedProduct?.bt_request_id > 0 ? "block" : "none"}}>
                <div className="d-flex py-1">
                  <div className="p-1 d-flex"><p className="Oval_dot_pup m-1 ms-auto "></p><h6 className="bold px-2">용기는 어떠셨나요?</h6></div>
                  <div className="px-3">
                    <li className="">
                      <Stars defaultScore={btScore} callback={(score) => setBtScore(score)}/>
                    </li>
                  </div>
                </div>
                <table className="table basic_sm w-100">
                  <colgroup>
                    <col width="20%"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th className="center">후기</th>
                    <td>
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
                                onChange={async (e) => await setBtContent(e.target.value)}
                                readOnly={!!readOnly}
                                value={btContent}
                      ></textarea>
                    </td>
                  </tr>
                </table>
              </div>
              <div style={{display: currFormedProduct?.bx_request_id > 0 ? "block" : "none"}}>
                <div className="d-flex py-1">
                  <div className="p-1 d-flex"><p className="Oval_dot_pup m-1 ms-auto "></p><h6 className="bold px-2">단상자는 어떠셨나요?</h6></div>
                  <div className="px-3">
                    <li className="">
                      <Stars defaultScore={bxScore} callback={(score) => setBxScore(score)}/>
                    </li>
                  </div>
                </div>
                <table className="table basic_sm w-100">
                  <colgroup>
                    <col width="20%"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th className="center">후기</th>
                    <td>
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
                                onChange={async (e) => await setBxContent(e.target.value)}
                                readOnly={!!readOnly}
                                value={bxContent}
                      ></textarea>
                    </td>
                  </tr>
                </table>
              </div>
              <div style={{display: currFormedProduct?.ds_request_id > 0 ? "block" : "none"}}>
                <div className="d-flex py-1">
                  <div className="p-1 d-flex"><p className="Oval_dot_pup m-1 ms-auto "></p><h6 className="bold px-2">디자인은 어떠셨나요?</h6></div>
                  <div className="px-3">
                    <li className="">
                      <Stars defaultScore={dsScore} callback={(score) => setDsScore(score)}/>
                    </li>
                  </div>
                </div>
                <table className="table basic_sm w-100">
                  <colgroup>
                    <col width="20%"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th className="center">후기</th>
                    <td>
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
                                onChange={async (e) => await setDsContent(e.target.value)}
                                readOnly={!!readOnly}
                                value={dsContent}
                      ></textarea>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="POP_foot">
              <button className="btn px-4 btn_puple" type="button" onClick={() => onNext()}>다음</button>
            </div>
          </>
        }
      </div>
      <div className="pop_bak"></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    reviewMakerPop: state.reviewMakerPop,
  };
}

function mapDispatchToProps(dispatcher) {
  return bindActionCreators(
    {
      openReviewMaker: openReviewMaker,
    },
    dispatcher
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewMakerModal);
