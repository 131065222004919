import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";
import Paging from "../../common/Paging";

const AdminSearchIngredientList = (props) => {
  const [keyword, setKeyword] = useState(props.searchKeyword || '');
  const [items, setItems] = useState([]);
  const history = useHistory();

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      await load();
    }
  }

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(props.page || 1);

  const load = (page) => {
    return new Promise(async (resolve, reject) => {
      if (props.setProps) props.setProps(keyword);
      const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("data/ingredient/gets", {keyword, paging: 1, currPage: page || 1, articlesPerPage: 6});
      if (code === 200) {
        if (pageInfo) setPageInfo(pageInfo);
        setItems(result);
      }
      window.scrollTo(0, 0);
      return resolve(true);
    });
  }

  useEffect(() => {
    const fetch = async () => {
      await load(currPage);
    }
    fetch();
  }, [currPage]);

  return (
    <section className="col-4" style={{height: "100%"}}>
      <div
        className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
        style={{height: "98%"}}
      >
        <div className="d-flex justify-content-between border-bottom">
          <p className="h5 bold px-2">성분제품사전 List</p>
          <p className="m-1 mb-2">
            <Link to="/admin/data/search/ingredient/add">
              <button type="button" className="btn btn-purple txt-sm py-1 px-3">
                추가
              </button>
            </Link>
          </p>
        </div>

        <div className="input-group input-group-lg mt-2">
          <input
            type="text"
            className="form-control bg-white border_none"
            placeholder="검색어를 입력하세요"
            style={{width: "510px", fontSize: "14px"}}
            onChange={async (e) => {
              await setKeyword(e.target.value)
            }}
            value={keyword}
            onKeyDown={handleKeyDown}
          />
          <button
            className="btn btn_puple px-5"
            type="button"
            id="button-addon2"
            onClick={() => load(currPage)}
          >
            검색
          </button>
        </div>

        <div
          className="wid_scroll scroll_y custom_scroll mt-2"
          id="scroll_div"
          style={{height: "calc(100% - 60px)"}}
        >
          <div className="wid_inline">
            {items && items.length > 0 && items.map((item, key) => (
              <div className="border-bottom p-3 txt-gray" onClick={() => props.click(item?.id, keyword, currPage)} style={{cursor: "pointer"}}>
                <p className="bold h6">
                  {item.name}
                </p>
                {item.name_en?.length > 0 &&
                  <>
                    {item.name_en}
                    <br/>
                  </>
                }
                {item.definition}
                <br/>
              </div>
            ))}
            <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminSearchIngredientList;
