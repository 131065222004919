import React, {useEffect, useState} from "react";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import AdminCsFaqList from "./AdminCsFaqList";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";

const AdminCsFaqEdit = (props) => {
  const id = props?.location?.state?.id || props?.match?.params?.id;

  // 현재 선택한 FAQ state
  const [faqState, setFaqState] = useState();
  const faqClick = (e) => {
    setFaqState(e);
  };
  const path = window.location.pathname;

  const [gubunCds, setGubunCds] = useState([]);
  const [gubunCd, setGubunCd] = useState();
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [exposureYn, setExposureYn] = useState('Y');

  const getGubunCds = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '160000'});
      if (code === 200) {
        setGubunCds(result);
        setGubunCd(result?.[0]);
      }
      return resolve(true);
    });
  }

  const submit = async () => {
    const {data: {code, result, message}} = await APIHelper.putForm("cs/faq/update", {id, gubunCd, question, answer, exposureYn});
    if (code === 200) {

      try {
        await APIHelper.postForm("admin/action/log", {
          content: localStorage.getItem("admin.faq.user_type_cd")=="BUYER" ? "고객서비스 > FAQ - 바이어 > 수정 저장" : "고객서비스 > FAQ - 제조사 > 수정 저장",
          param1: id,
          param2: gubunCd,
          param3: question,
          param4: answer,
          param5: exposureYn,
        });
      } catch (err) {
        console.log(err);
      }

      await CommonLib.jalert('저장하였습니다.');
    }
  }

  const remove = async () => {
    if (!await CommonLib.jconfirm('삭제하시겠습니까?')) return;

    const {data: {code, result, message}} = await APIHelper.deleteForm(`cs/faq/delete?id=${id}`);
    if (code === 200) {

      try {
        await APIHelper.postForm("admin/action/log", {
          content: localStorage.getItem("admin.faq.user_type_cd")=="BUYER" ? "고객서비스 > FAQ - 바이어 > 삭제" : "고객서비스 > FAQ - 제조사 > 삭제",
          param1: id,
        });
      } catch (err) {
        console.log(err);
      }

      window.location.href = '/admin/cs/faq'
    }
  }

  const get = async () => {
    const {data: {code, result, message}} = await APIHelper.getForm("cs/faq/get", {id});
    if (code === 200) {
      setQuestion(result?.question);
      setAnswer(result?.answer);
      setGubunCd(result?.gubun_cd);
      setExposureYn(result?.exposure_yn);
    }
  }

  useEffect(() => {
    const fetch = async () => {
      await getGubunCds();
      await get();
    }
    fetch();
  }, [id]);

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{height: "calc(100% - 30px)"}}
      >
        <AdminCsFaqList click={faqClick}/>

        <section className="col-8" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">FAQ 상세/수정</p>
              <p className="m-1 mb-2">
                <button
                  className="btn btn_gray txt-sm py-1 px-3"
                  type="button"
                  style={{marginRight: "5px"}}
                  onClick={remove}
                >
                  삭제
                </button>
                <button
                  className="btn btn-purple txt-sm py-1 px-3"
                  type="button"
                  onClick={submit}
                >
                  저장
                </button>
              </p>
            </div>

            <div
              className="wid_scroll scroll_y coustom_scroll px-2 mt-4"
              style={{height: "calc(100% - 70px)"}}
            >
              <article className="wid_inline">
                <table className="table basic w-100">
                  <colgroup>
                    <col width="18%"/>
                    <col width="*"/>
                    <col width="18%"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th className="center">제목</th>
                    <td className="border-top" colSpan="3">
                      <input
                        className="form-control form-control-sm"
                        placeholder="최대 100자까지 가능합니다."
                        type="text"
                        aria-describedby="button-addon2"
                        onChange={async (e) => await setQuestion(e.target.value)}
                        value={question}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="center">노출유무</th>
                    <td>
                      <select
                        className="form-select form-select-sm w-100"
                        aria-label="Default select example"
                      >
                        <option selected={exposureYn === 'Y'} value="Y">노출함</option>
                        <option selected={exposureYn === 'N'} value="N">노출안함</option>
                      </select>
                    </td>
                    <th className="center">분류</th>
                    <td>
                      <select
                        className="form-select form-select-sm w-100"
                        aria-label="Default select example"
                        onChange={async (e) => await setGubunCd(e.target.value)}
                      >
                        {gubunCds.map((item, k) => <option key={k} value={item.cd} selected={item.cd === gubunCd}>{item.name_kr}</option>)}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">내용</th>
                    <td className="border-bottom" colSpan="3">
                      <CKEditor editor={ClassicEditor} data={answer || ''}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setAnswer(data);
                                }}
                      />
                    </td>
                  </tr>
                </table>
              </article>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminCsFaqEdit;
