export default (openClose, question, response, date, type) => {
  return {
    type: "OPEN_DESIGN_RESULT",
    payload: {
      openClose,
      content: {
        question,
        response,
        date,
        type,
      },
    },
  };
};
