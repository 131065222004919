import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import CsCenterCard from "./CsCenterCard";
import NoticeItems from "./NoticeItems";
import first_page_black_48dp from "../../image/first_page_black_48dp.svg";
import chevron_left_black_24dp from "../../image/chevron_left_black_24dp.svg";
import navigate_next_black_48dp from "../../image/navigate_next_black_48dp.svg";
import last_page_black_48dp from "../../image/last_page_black_48dp.svg";

import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";

const Notice = (props) => {
  const history = useHistory();

  const [rets, setRets] = useState([]);

  const gets = async () => {
    const {data: {code, result, message}} = await APIHelper.getForm("cs/notice/gets", {mode: "USER"});
    setRets(result);
  };

  useEffect(() => {
    const fetch = async () => {
      await gets();
    }
    fetch();
  }, []);

  return (
    <CsCenterCard>
      <div className="box_round min_h cnt_area">
        <div className="mx-5 my-4">
          <p className="h3 bold border-bottom py-3">공지사항</p>
          <br/>
          <table className="table basic my-3">
            <colgroup>
              <col width="75px"/>
              <col width="*"/>
              <col width="150px"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>제목</th>
              <th>작성일</th>
            </tr>
            <NoticeItems items={rets}/>
          </table>
          {rets?.length === 0 &&
            <div className="col w-100">
              <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-white">
                등록된 공지사항이 없습니다.
              </div>
            </div>
          }

          {/*<div className="d-flex justify-content-center py-4 d-none">*/}
          {/*  <ul className="d-flex my-3" style={{ margin: "0 auto" }}>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" aria-label="Previous">*/}
          {/*        <img src={first_page_black_48dp} style={{ width: "25px" }} />*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" aria-label="Previous">*/}
          {/*        <img*/}
          {/*          src={chevron_left_black_24dp}*/}
          {/*          style={{ width: "25px" }}*/}
          {/*        />*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" className="ON">*/}
          {/*        1*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#">2</a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#">3</a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" aria-label="Previous">*/}
          {/*        <img*/}
          {/*          src={navigate_next_black_48dp}*/}
          {/*          style={{ width: "25px" }}*/}
          {/*        />*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="PAGE">*/}
          {/*      <a href="#" aria-label="Previous">*/}
          {/*        <img src={last_page_black_48dp} style={{ width: "25px" }} />*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*</div>*/}
        </div>
      </div>
    </CsCenterCard>
  );
};

export default Notice;
