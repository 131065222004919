import React, {useState} from "react";
import icon_prod_021 from "../../../image/category/icon_prod_021.svg";
import icon_prod_031 from "../../../image/category/icon_prod_031.svg";
import icon_prod_041 from "../../../image/category/icon_prod_041.svg";
import icon_prod_011 from "../../../image/category/icon_prod_011.svg";
import modalStyle from "../../../actions/openModalStyle";
import "../../../sass/main.scss";
import {useDispatch} from "react-redux";
import openRequestFormulation from "../../../actions/openRequestFormulation";
import openRequestDesign from "../../../actions/openRequestDesign";
import openRequestBottle from "../../../actions/openRequestBottle";
import openRequestBox from "../../../actions/openRequestBox";

// 제품 리스트 카드 안의 카테고리
const ProductCategoryCard = (props) => {
  const arrItems = [
    {name_kr: "내용물", cd: "MF", width: 35, num: 1},
    {name_kr: "용기", cd: "BT", width: 45, num: 2},
    {name_kr: "디자인", cd: "DS", width: 40, num: 3},
    {name_kr: "단상자", cd: "BX", width: 45, num: 4},
  ];

  // modal액션으로 카테고리(단상자, 디자인...) 보내기
  const dispatch = useDispatch();

  const openRequestForm = (item, projectId, projectProductId, requestId, itemCd) => {
    const obj = {projectId, projectProductId, requestId, renew: new Date().getTime(), closeCallback: props.closeCallback};
    if (itemCd === 'MF') dispatch(openRequestFormulation("open", obj));
    else if (itemCd === 'DS') dispatch(openRequestDesign("open", obj));
    else if (itemCd === 'BT') dispatch(openRequestBottle("open", obj));
    else if (itemCd === 'BX') dispatch(openRequestBox("open", obj));
  }

  const submitMakerRequest = (item, projectId, projectProductId, requestId, item_cd) => {
    const obj = {projectId, projectProductId, item_cd, requestId, item, renew: new Date().getTime()};
    dispatch(modalStyle("open", obj))
  }

  return (
    <>
      {props.items && props.items.map((item, i) => {
        const _item = arrItems.filter(e => e.cd === item.item_cd)[0];
        const name = _item.name_kr;
        const item_cd = item?.item_cd;
        const requestId = item?.request_form?.id;

        return (
          <div className="col">
            <div className="box_round_5 border bg-lgray2">
              <div className="d-flex justify-content-between p-2 pb-1">
                <p className="txt-sm text-start">
                  <span className="h6 bold">{name}</span>
                  <br/>
                  {item?.request_form?.request_name}
                  <br/>
                  {props.product?.category?.name_kr} - {props.product?.product?.name_kr}
                </p>
                <p className="py-1">
                  <img
                    src={`${name === "내용물" ? icon_prod_011 : ""} ${
                      name === "용기" ? icon_prod_021 : ""
                    } ${name === "단상자" ? icon_prod_031 : ""} ${
                      name === "디자인" ? icon_prod_041 : ""
                    }`}
                    style={{width: "30px"}}
                  />
                </p>
              </div>

              <p className="mb-2">
                <button
                  type="button"
                  onClick={() => openRequestForm(_item, props?.product?.project_id, props?.product?.id, requestId, item_cd)}
                  className="btn w-48 btn-sm btn-dark p-1 my-1"
                  style={{minWidth: "85px"}}
                >
                  제작의뢰서
                </button>
                <button
                  type="button"
                  onClick={() => submitMakerRequest(_item, props?.product?.project_id, props?.product?.id, requestId, item_cd)}
                  className="btn w-48 btn-sm btn-blue p-1 my-1"
                  style={{minWidth: "85px"}}
                >
                  의뢰하기
                </button>
              </p>
            </div>
          </div>
        )
      })}
    </>
  );
};

export default ProductCategoryCard;
