import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import AdminCsNoticeList from "./AdminCsNoticeList";
import APIHelper from "../../../helpers/APIHelper";

const AdminCsNotice = (e) => {
  const history = useHistory();

  const noticeClick = (e) => {
    history.push(`/admin/cs/notice/detail/${e.id}`);
  };

  useEffect(() => {
    (async () => {
      try {
        await APIHelper.postForm("admin/action/log", {
          content: "고객서비스 > 공지사항",
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <AdminCard>
      <div
        id="body"
        className="row row-cols-3 gx-3 mt-4"
        style={{ height: "calc(100% - 30px)" }}
      >
        <AdminCsNoticeList click={noticeClick} />

        <section className="col-8" style={{height: "100%"}}>
          <div
            className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
            style={{height: "98%"}}
          >
            <div className="d-flex justify-content-between border-bottom">
              <p className="h5 bold px-2">공지사항을 선택해 주세요</p>
              <p className="m-1 mb-2">
                <button
                  className="btn btn_gray txt-sm py-1 px-3"
                  type="button"
                  style={{marginRight: "5px", visibility: "hidden"}}
                >
                  INVISIBLE
                </button>
              </p>
            </div>
          </div>
        </section>
      </div>
    </AdminCard>
  );
};

export default AdminCsNotice;
