import React, {useEffect, useState} from "react";
import "../../../sass/main.scss";
import AdminCard from "../AdminCard";
import AdminCsNoticeList from "./AdminCsNoticeList";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import APIHelper from "../../../helpers/APIHelper";
import CommonLib from "../../../libraries/CommonLib";

const AdminCsNoticeAdd = (e) => {
  // 현재 선택한 공지사항 state
  const [noticeState, setNoticeState] = useState();
  const noticeClick = (e) => {
    setNoticeState(e);
  };
  const path = window.location.pathname;

  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [exposureYn, setExposureYn] = useState('Y');

  const submit = async () => {
    const {data: {code, result, message}} = await APIHelper.postForm("cs/notice/register", {subject, content, exposureYn});
    if (code === 200) {
      await CommonLib.jalert('저장하였습니다.');

      try {
        await APIHelper.postForm("admin/action/log", {
          content: "고객서비스 > 공지사항 > 추가 저장",
          param1: subject,
          param2: content,
          param3: exposureYn,
        });
      } catch (err) {
        console.log(err);
      }

      window.location.href = '/admin/cs/notice';
    }
  }

  return (
    <>
      {path.includes("add") ? (
        <AdminCard>
          <div
            id="body"
            className="row row-cols-3 gx-3 mt-4"
            style={{height: "calc(100% - 30px)"}}
          >
            <AdminCsNoticeList click={noticeClick}/>

            <section className="col-8" style={{height: "100%"}}>
              <div
                className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
                style={{height: "98%"}}
              >
                <div className="d-flex justify-content-between border-bottom">
                  <p className="h5 bold px-2">공지사항 등록</p>
                  <p className="m-1 mb-2">
                    <button
                      className="btn btn_gray txt-sm py-1 px-3"
                      type="button"
                      style={{marginRight: "5px"}}
                      onClick={() => window.history.go(-1)}
                    >
                      취소
                    </button>
                    <button
                      className="btn btn-purple txt-sm py-1 px-3"
                      type="button"
                      onClick={submit}
                    >
                      저장
                    </button>
                  </p>
                </div>

                <div
                  className="wid_scroll scroll_y coustom_scroll px-2 mt-4"
                  style={{height: "calc(100% - 70px)"}}
                >
                  <article className="wid_inline">
                    <table className="table basic w-100">
                      <colgroup>
                        <col width="18%"/>
                        <col width="*"/>
                        <col width="18%"/>
                        <col width="*"/>
                      </colgroup>
                      <tr>
                        <th className="center">제목</th>
                        <td className="border-top" colSpan="3">
                          <input
                            className="form-control form-control-sm"
                            placeholder="최대 100자까지 가능합니다."
                            type="text"
                            aria-describedby="button-addon2"
                            onChange={async (e) => await setSubject(e.target.value)}
                            value={subject}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="center">노출유무</th>
                        <td colSpan="3">
                          <select
                            className="form-select form-select-sm w-100"
                            aria-label="Default select example"
                            onChange={async (e) => await setExposureYn(e.target.value)}
                            style={{maxWidth: "200px"}}
                          >
                            <option selected={exposureYn === 'Y'} value="Y">노출함</option>
                            <option selected={exposureYn === 'N'} value="N">노출안함</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th className="center">내용</th>
                        <td className="border-bottom" colSpan="3">
                          <CKEditor editor={ClassicEditor} data=""
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      setContent(data);
                                    }}
                          />
                        </td>
                      </tr>
                    </table>
                  </article>
                </div>
              </div>
            </section>
          </div>
        </AdminCard>
      ) : (
        ""
      )}
    </>
  );
};

export default AdminCsNoticeAdd;
