import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "../../../sass/main.scss";
import APIHelper from "../../../helpers/APIHelper";
import Paging from "../../common/Paging";

const AdminCsQnaList = (props) => {

  const [rets, setRets] = useState([]);
  const [gubunFilter, setGubunFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [gubunCds, setGubunCds] = useState([]);
  const [statusCds, setStatusCds] = useState(['답변대기', '답변완료']);
  const [selected, setSelected] = useState({});

  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);

  const getGubunCds = () => {
    return new Promise(async (resolve, reject) => {
      const {data: {code, result, message}} = await APIHelper.getForm("code/gets", {parentCd: '140000'});
      if (code === 200) setGubunCds(result);
      return resolve(true);
    });
  };

  const gets = async () => {
    const {data: {code, result, pageInfo, message}} = await APIHelper.getForm("cs/inquiry/gets", {gubunFilter, statusFilter, paging: 1, currPage});
    if (pageInfo) setPageInfo(pageInfo);
    setRets(result);
    if (result && result.length > 0) {
      if (parseInt(selected?.id || 0, 10) > 0) props.click(result.filter(r => parseInt(r.id, 10) === parseInt(selected?.id, 10))?.[0]);
    } else {
      props.click({});
    }
    document.getElementById('scroll_div').scrollTop = 0;
  };

  useEffect(() => {
    const fetch = async () => {
      await getGubunCds();
      await gets();
    }
    fetch();
  }, [currPage, props.renew, gubunFilter, statusFilter]);

  const openProject = (ret) => {
    window.event.stopPropagation();
    const win = window.open(`/admin/project/detail/${ret?.project_id}`, "_blank");
    win.focus();
  }

  const openUserDetail = (ret) => {
    window.event.stopPropagation();
    let win;
    if (ret.qn_user.user_type === 'BUYER') win = window.open(`/admin/buyer/list/${ret.qn_user?.id}`, "_blank");
    else win = window.open(`/admin/maker/list/${ret.qn_user?.id}`, "_blank");

    win.focus();
  }

  return (
    <section className="col-5" style={{height: "100%"}}>
      <div
        className="bg-white shadow2 my-3 box_round_4_paddingNo p-3"
        style={{height: "98%"}}
      >
        <div className="d-flex justify-content-between border-bottom">
          <p className="h5 bold px-2">Q&A List</p>
          {/*<p className="m-1 mb-2">*/}
          {/*  <button type="button" className="btn btn-purple txt-sm py-1 px-3">*/}
          {/*    추가*/}
          {/*  </button>*/}
          {/*</p>*/}
        </div>

        <div
          className="wid_scroll scroll_y custom_scroll mt-2"
          id="scroll_div"
          style={{height: "calc(100% - 60px)"}}
        >
          <div className="wid_inline d-flex justify-content-between">
            <div className="input-group w-45 my-3 text-end">
              <label className="input-group-text" htmlFor="inputGroupSelect01">
                구분필터
              </label>
              <select
                className="form-select form-select-sm w-50"
                aria-label="Default select example"
                onChange={async (e) => await setGubunFilter(e.target.value)}
                value={gubunFilter}
              >
                <option value="" selected={'' === gubunFilter}>전체</option>
                {gubunCds.map((item, k) => <option key={k} value={item.cd} selected={item.cd === gubunFilter}>{item.name_kr}</option>)}
              </select>
            </div>
            <div className="input-group w-45 my-3 text-end">
              <label className="input-group-text" htmlFor="inputGroupSelect01">
                상태필터
              </label>
              <select
                className="form-select form-select-sm w-50"
                aria-label="Default select example"
                onChange={async (e) => await setStatusFilter(e.target.value)}
                value={statusFilter}
              >
                <option value="" selected={'' === statusFilter}>전체</option>
                {statusCds.map((item, k) => <option key={k} value={item} selected={item === statusFilter}>{item}</option>)}
              </select>
            </div>
          </div>

          <div className="wid_inline">
            {rets.map((ret, i) => (
              <div
                className="box_round_5 border my-2 p-3"
                onClick={() => {
                  setSelected(ret);
                  props.click(ret)
                }}
              >
                <div className="d-flex">
                  <p style={{padding: "0px 15px 0px 5px"}}>{(pageInfo?.totalArticles - i).toString().padStart(2, '0')}</p>
                  <div style={{width: "calc(100% - 50px)"}}>
                    <p className="h6 mb-2 text-start">
                      <span className="txt-sm txt-gray">
                        <span style={{fontSize: "0.8rem", cursor: "pointer", color: "blueviolet"}} onClick={() => openUserDetail(ret)}>이름 : {ret.qn_user.name} / 이메일 : {ret.qn_user.email}</span>
                        <br/>
                        구분 : {ret.gubun.name_kr}
                        <span style={{fontSize: "0.8rem", cursor: "pointer", color: "palevioletred"}}
                              onClick={() => openProject(ret)}>{parseInt(ret?.project?.id, 10) > 0 && ` / ${ret?.project?.name?.length > 0 ? ret?.project?.name : "프로젝트명 미정"}(${ret?.project?.prj_no})`}</span>
                        <br/>
                      </span>
                      {ret.subject}
                    </p>
                    <div className="d-flex justify-content-between">
                      <p>
                        구분 :{" "}
                        <span className="bold">
                          {ret.status_cd}
                        </span>
                      </p>
                      <p className="txt-sm txt-gray">{ret.date}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {rets?.length === 0 &&
              <div className="col w-100">
                <div className="box_round_5 border my-2 d-flex p-5 justify-content-center bg-white">
                  등록된 1:1 문의가 없습니다.
                </div>
              </div>
            }
            <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminCsQnaList;
