import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import openTimelineAttach from "../../actions/openTimelineAttach";

import "../../sass/main.scss";
import APIHelper from "../../helpers/APIHelper";
import $ from "jquery";
import CommonLib from "../../libraries/CommonLib";
import Constants from "../../constants/constants";

const TimelineAttachModal = (props) => {
  const closeModal = () => {
    props.openTimelineAttach("closed", "");
  };

  const {id, formType, title, projectId, projectProductId, projectMakerRequestId, renew, readOnly: _readOnly} = props.timelineAttachPop?.content;
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    if (_readOnly !== undefined) setReadOnly(_readOnly);
    if (formType && formType.endsWith('확인')) setReadOnly(true);
  });

  let modalInlineStyle;

  if (props.timelineAttachPop.openClose === "closed") {
    modalInlineStyle = "none";
  } else {
    modalInlineStyle = "block";
  }

  const [refCtnt1, setRefCtnt1] = useState('');
  const [refFidInf1, setRefFidInf1] = useState({});
  const refFile1 = useRef();

  const [refCtnt2, setRefCtnt2] = useState('');
  const [refFidInf2, setRefFidInf2] = useState({});
  const refFile2 = useRef();

  const [refCtnt3, setRefCtnt3] = useState('');
  const [refFidInf3, setRefFidInf3] = useState({});
  const refFile3 = useRef();

  const [refCtnt4, setRefCtnt4] = useState('');
  const [refFidInf4, setRefFidInf4] = useState({});
  const refFile4 = useRef();

  const [refCtnt5, setRefCtnt5] = useState('');
  const [refFidInf5, setRefFidInf5] = useState({});
  const refFile5 = useRef();

  const load = () => {
    return new Promise(async (resolve, reject) => {
      setReadOnly(false);
      setRefCtnt1('');
      setRefFidInf1({});
      setRefCtnt2('');
      setRefFidInf2({});
      setRefCtnt3('');
      setRefFidInf3({});
      setRefCtnt4('');
      setRefFidInf4({});
      setRefCtnt5('');
      setRefFidInf5({});

      if (CommonLib.parseInt(id) > 0) {
        if (formType === '패킹 리스트') {
          const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/get", {id});
          if (CommonLib.parseInt(result?.id) > 0 && CommonLib.parseInt(result?.ref_fid_inf_1?.id) > 0) {
            setReadOnly(true);
            setRefFidInf1(result?.ref_fid_inf_1 || {});
          }
        } else if (formType === '인보이스') {
          const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/get", {id});
          if (CommonLib.parseInt(result?.id) > 0 && CommonLib.parseInt(result?.ref_fid_inf_2?.id) > 0) {
            setReadOnly(true);
            setRefFidInf1(result?.ref_fid_inf_2 || {});
          }
        } else if (formType === '면장') {
          const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/get", {id});
          if (CommonLib.parseInt(result?.id) > 0 && CommonLib.parseInt(result?.ref_fid_inf_3?.id) > 0) {
            setReadOnly(true);
            setRefFidInf1(result?.ref_fid_inf_3 || {});
          }
        } else {
          const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/get", {id, status: formType});
          if (CommonLib.parseInt(result?.id) > 0 && CommonLib.parseInt(result?.ref_fid_inf_1?.id) > 0) {
            setReadOnly(true);
            setRefCtnt1(result?.ref_ctnt_1 || '');
            setRefFidInf1(result?.ref_fid_inf_1 || {});
            setRefCtnt2(result?.ref_ctnt_2 || '');
            setRefFidInf2(result?.ref_fid_inf_2 || {});
            setRefCtnt3(result?.ref_ctnt_3 || '');
            setRefFidInf3(result?.ref_fid_inf_3 || {});
            setRefCtnt4(result?.ref_ctnt_4 || '');
            setRefFidInf4(result?.ref_fid_inf_4 || {});
            setRefCtnt5(result?.ref_ctnt_5 || '');
            setRefFidInf5(result?.ref_fid_inf_5 || {});
          }
        }
      } else {
        if (formType?.includes('견적서') && !formType?.includes('샘플 견적서 피드백')) {
          const {data: {code, result, message}} = await APIHelper.getForm("project/file/estimate/get", {projectId, projectProductId, projectMakerRequestId, status: formType.replace(' 작성', '')});
          if (CommonLib.parseInt(result?.id) > 0 && CommonLib.parseInt(result?.ref_fid_inf_1?.id) > 0) {
            setReadOnly(true);
            setRefCtnt1(result?.ref_ctnt_1 || '');
            setRefFidInf1(result?.ref_fid_inf_1 || {});
          }
        } else if (formType?.includes('샘플 배송 피드백') || formType?.includes('샘플 제작 완료 및 배송') || formType?.includes('샘플 배송 수령 처리')) {
          const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/attach/get", {projectId, projectProductId, projectMakerRequestId, status: formType.replace(' 작성', '')});
          if (CommonLib.parseInt(result?.id) > 0 && CommonLib.parseInt(result?.ref_fid_inf_1?.id) > 0) {
            setReadOnly(false);
            setRefCtnt1(result?.ref_ctnt_1 || '');
            setRefFidInf1(result?.ref_fid_inf_1 || {});
            setRefCtnt2(result?.ref_ctnt_2 || '');
            setRefFidInf2(result?.ref_fid_inf_2 || {});
            setRefCtnt3(result?.ref_ctnt_3 || '');
            setRefFidInf3(result?.ref_fid_inf_3 || {});
            setRefCtnt4(result?.ref_ctnt_4 || '');
            setRefFidInf4(result?.ref_fid_inf_4 || {});
            setRefCtnt5(result?.ref_ctnt_5 || '');
            setRefFidInf5(result?.ref_fid_inf_5 || {});
          }
        } else if (formType?.includes('계약서')) {
          const {data: {code, result, message}} = await APIHelper.getForm("project/contract/get", {projectId, projectProductId, projectMakerRequestId, status: formType.replace(' 작성', '')});
          if (CommonLib.parseInt(result?.id) > 0 && CommonLib.parseInt(result?.ref_fid_inf_1?.id) > 0) {
            setReadOnly(true);
            setRefCtnt1(result?.ref_ctnt_1 || '');
            setRefFidInf1(result?.ref_fid_inf_1 || {});
          }
        } else if (formType?.includes('제품 검수')) {
          const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/attach/get", {projectId, projectProductId, projectMakerRequestId, status: formType.replace(' 작성', '')});
          if (CommonLib.parseInt(result?.id) > 0 && CommonLib.parseInt(result?.ref_fid_inf_1?.id) > 0) {
            setReadOnly(true);
            setRefCtnt1(result?.ref_ctnt_1 || '');
            setRefFidInf1(result?.ref_fid_inf_1 || {});
          }
        } else if (formType?.includes('품목 및 수량 확인서')) {
          const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/attach/get", {projectId, projectProductId, projectMakerRequestId, status: formType.replace(' 작성', '')});
          if (CommonLib.parseInt(result?.id) > 0 && CommonLib.parseInt(result?.ref_fid_inf_1?.id) > 0) {
            setReadOnly(true);
            setRefFidInf1(result?.ref_fid_inf_1 || {});
          }
        } else if (formType?.includes('MSDS')) {
          const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/attach/get", {projectId, projectProductId, projectMakerRequestId, status: formType.replace(' 작성', '')});
          if (CommonLib.parseInt(result?.id) > 0 && CommonLib.parseInt(result?.ref_fid_inf_1?.id) > 0) {
            setReadOnly(true);
            setRefFidInf1(result?.ref_fid_inf_1 || {});
          }
        } else if (formType?.includes('통관')) {
          const {data: {code, result, message}} = await APIHelper.getForm("project/timeline/attach/get", {projectId, projectProductId, projectMakerRequestId, status: formType.replace(' 작성', '')});
          if (CommonLib.parseInt(result?.id) > 0 && CommonLib.parseInt(result?.ref_fid_inf_1?.id) > 0) {
            setReadOnly(true);
            setRefFidInf1(result?.ref_fid_inf_1 || {});
          }
        }
      }
      return resolve(true);
    });
  }

  useEffect(() => {
    const fetch = async () => {
      setReadOnly(false);
      setRefCtnt1('');
      $('#refFile1').val('');
      setRefCtnt2('');
      $('#refFile2').val('');
      setRefCtnt3('');
      $('#refFile3').val('');
      setRefCtnt4('');
      $('#refFile4').val('');
      setRefCtnt5('');
      $('#refFile5').val('');
      $('#refCtnt1').focus();
      await load();
    }
    fetch();
  }, [renew])

  const submit = async () => {
    if (formType.includes('패킹 리스트')) {
      const {data: {code, result, message}} = await APIHelper.putForm("project/timeline/update", {
        id,
        projectId,
        projectProductId,
        projectMakerRequestId,
        refFile1: refFile1?.current?.files?.[0] || null
      });
    } else if (formType.includes('인보이스')) {
      const {data: {code, result, message}} = await APIHelper.putForm("project/timeline/update", {
        id,
        projectId,
        projectProductId,
        projectMakerRequestId,
        refFile2: refFile1?.current?.files?.[0] || null
      });
    } else if (formType.includes('면장')) {
      const {data: {code, result, message}} = await APIHelper.putForm("project/timeline/update", {
        id,
        projectId,
        projectProductId,
        projectMakerRequestId,
        refFile3: refFile1?.current?.files?.[0] || null
      });
    } else {
      const {data: {code, result, message}} = await APIHelper.putForm("project/timeline/update", {
        id,
        projectId,
        projectProductId,
        projectMakerRequestId,
        status: formType,
        refCtnt1,
        refFile1: refFile1?.current?.files?.[0] || null
      });
    }
    await load();
    await CommonLib.jalert('서류 및 자료가 등록되었습니다.');
    closeModal();
  }

  const submit2 = async () => {
    const {data: {code, result, message}} = await APIHelper.putForm("project/timeline/update", {
      id,
      projectId,
      projectProductId,
      projectMakerRequestId,
      status: formType,
      refFile1: refFile1?.current?.files?.[0] || null,
      refFile2: refFile2?.current?.files?.[0] || null
    });
    await load();
    await CommonLib.jalert('서류 및 자료가 등록되었습니다.');
    closeModal();
  }

  const submit3 = async () => {
    const {data: {code, result, message}} = await APIHelper.putForm("project/timeline/update", {
      id,
      projectId,
      projectProductId,
      projectMakerRequestId,
      status: formType,
      refFile1: refFile1?.current?.files?.[0] || null,
      refFile2: refFile2?.current?.files?.[0] || null,
      refFile3: refFile3?.current?.files?.[0] || null,
      refFile4: refFile4?.current?.files?.[0] || null,
      refFile5: refFile5?.current?.files?.[0] || null
    });
    await load();
    await CommonLib.jalert('자료가 등록되었습니다.');
    closeModal();
  }

  return (
    <div style={{display: modalInlineStyle, zIndex: 9999999}}>
      <div className="pop_box02" style={{width: "650px", minHeight: "320px"}}>
        <div className="POP_title pb-3">
          <h4>
            <strong><span style={{fontSize: "1.5rem"}}>{title}</span></strong>
          </h4>
          <p className="closed">
            <button type="button" className="btn" onClick={closeModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </button>
          </p>
        </div>

        <div className="POP_cnt_box_lg" style={{marginBottom: "0px"}}>
          <table className="table basic_sm w-100">
            <colgroup>
              <col width="25%"/>
              <col width="*"/>
            </colgroup>
            {formType && !formType?.includes('품목 및 수량 확인서, MSDS 등록') && !formType?.includes('샘플 제작 완료 및 배송') && !formType?.includes('샘플 배송 수령 처리') && !formType?.includes('샘플 배송 피드백') && refCtnt1?.length > 0 &&
              <tr>
                <th className="center txt-sm">
                  {title?.length > 0 && title?.includes("견적서") ? "견적서명" : title?.includes("계약서") ? "계약서명" : "첨부명"}
                </th>
                <td>
                  {readOnly ?
                    <span id="loadRefCtnt1">{refCtnt1}</span>
                    :
                    <input
                      type="text"
                      id="refCtnt1"
                      name="refCtnt1"
                      className="form-control"
                      onChange={async (e) => await setRefCtnt1(e.target.value)}
                      value={refCtnt1}
                    />
                  }
                </td>
              </tr>
            }
            {formType && !formType?.includes('품목 및 수량 확인서, MSDS 등록') && !formType?.includes('샘플 제작 완료 및 배송') && !formType?.includes('샘플 배송 수령 처리') && !formType?.includes('샘플 배송 피드백') ?
              <tr>
                <th className="center txt-sm">
                  첨부파일
                </th>
                <td>
                  {refFidInf1?.id > 0 &&
                    <span><a href={`${Constants.API_HOST}/common/download?fid=${refFidInf1?.fid}`}>{refFidInf1?.filename}</a></span>
                  }
                  {!readOnly &&
                    <input
                      type="file"
                      id="refFile1"
                      name="refFile1"
                      className="form-control"
                      aria-label="Upload"
                      ref={refFile1}
                    />
                  }
                </td>
              </tr>
              :
              formType?.includes('품목 및 수량 확인서, MSDS 등록') ?
                <>
                  <tr>
                    <th className="center txt-sm">
                      품목/수량 확인서
                    </th>
                    <td>
                      {refFidInf1?.id > 0 &&
                        <span><a href={`${Constants.API_HOST}/common/download?fid=${refFidInf1?.fid}`}>{refFidInf1?.filename}</a></span>
                      }
                      {!readOnly &&
                        <input
                          type="file"
                          id="refFile1"
                          name="refFile1"
                          className="form-control"
                          aria-label="Upload"
                          ref={refFile1}
                        />
                      }
                    </td>
                  </tr>
                  <tr>
                    <th className="center txt-sm">
                      MSDS
                    </th>
                    <td>
                      {refFidInf2?.id > 0 &&
                        <span><a href={`${Constants.API_HOST}/common/download?fid=${refFidInf2?.fid}`}>{refFidInf2?.filename}</a></span>
                      }
                      {!readOnly &&
                        <input
                          type="file"
                          id="refFile2"
                          name="refFile2"
                          className="form-control"
                          aria-label="Upload"
                          ref={refFile2}
                        />
                      }
                    </td>
                  </tr>
                </>
                :
                <>
                  <tr>
                    <th className="center txt-sm">
                      샘플 시안 첨부
                    </th>
                    <td>
                      {refFidInf1?.id > 0 &&
                        <div><a href={`${Constants.API_HOST}/common/download?fid=${refFidInf1?.fid}`}>{refFidInf1?.filename}</a></div>
                      }
                      {!readOnly &&
                        <input
                          type="file"
                          id="refFile1"
                          name="refFile1"
                          className="form-control"
                          aria-label="Upload"
                          ref={refFile1}
                        />
                      }
                      {refFidInf2?.id > 0 &&
                        <div><a href={`${Constants.API_HOST}/common/download?fid=${refFidInf2?.fid}`}>{refFidInf2?.filename}</a></div>
                      }
                      {!readOnly &&
                        <input
                          type="file"
                          id="refFile2"
                          name="refFile2"
                          className="form-control"
                          aria-label="Upload"
                          ref={refFile2}
                        />
                      }
                      {refFidInf3?.id > 0 &&
                        <div><a href={`${Constants.API_HOST}/common/download?fid=${refFidInf3?.fid}`}>{refFidInf3?.filename}</a></div>
                      }
                      {!readOnly &&
                        <input
                          type="file"
                          id="refFile3"
                          name="refFile3"
                          className="form-control"
                          aria-label="Upload"
                          ref={refFile3}
                        />
                      }
                      {refFidInf4?.id > 0 &&
                        <div><a href={`${Constants.API_HOST}/common/download?fid=${refFidInf4?.fid}`}>{refFidInf4?.filename}</a></div>
                      }
                      {!readOnly &&
                        <input
                          type="file"
                          id="refFile4"
                          name="refFile4"
                          className="form-control"
                          aria-label="Upload"
                          ref={refFile4}
                        />
                      }
                      {refFidInf5?.id > 0 &&
                        <div><a href={`${Constants.API_HOST}/common/download?fid=${refFidInf5?.fid}`}>{refFidInf5?.filename}</a></div>
                      }
                      {!readOnly &&
                        <input
                          type="file"
                          id="refFile5"
                          name="refFile5"
                          className="form-control"
                          aria-label="Upload"
                          ref={refFile5}
                        />
                      }
                    </td>
                  </tr>
                </>
            }
          </table>
          <div style={{
            height: "55px",
            textAlign: "center",
            marginTop: "14px",
            padding: "0.6em",
            backgroundColor: "#f7f7f7",
          }}>
            {!readOnly &&
              <button className="btn btn_puple" type="button"
                      onClick={() => formType && !formType?.includes('품목 및 수량 확인서, MSDS 등록') && !formType?.includes('샘플 제작 완료 및 배송') && !formType?.includes('샘플 배송 수령 처리') && !formType?.includes('샘플 배송 피드백') ? submit() : formType?.includes('품목 및 수량 확인서, MSDS 등록') ? submit2() : submit3()}>
                저장하기
              </button>
            }
            <button className="btn btn_outline_puple" type="button" style={{marginLeft: "7px"}} onClick={() => closeModal()}>
              닫기
            </button>
          </div>
        </div>
      </div>
      <div className="pop_bak" onClick={closeModal}></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    timelineAttachPop: state.timelineAttachPop,
  };
}

function mapDispatchToProps(dispatcher) {
  return bindActionCreators(
    {
      openTimelineAttach: openTimelineAttach,
    },
    dispatcher
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TimelineAttachModal);
